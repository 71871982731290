<template>
  <div class="ricercaElementoField">
    <div>
      <div class="van-cell van-cell--large">
        <i v-if="item.icon" :class="item.icon" class="van-icon van-icon-user-o van-cell__left-icon"></i>
        <div class="van-cell__title" @click="onClickCell">
          <span>{{ config.descrizione(item) }}</span>
          <!-- <div class="van-cell__label clienti-lr-label"></div> -->
        </div>
        <!-- <div class="van-cell__value clienti-lr-value">
          <span>12345678901</span>
        </div> -->
        <van-button v-for="(b, index) in config.bottoni" :key="index" @click="onClickBtn(b)"
            type="default" class="btnRicercaElemento">{{ b.testo }}</van-button>
      </div>

      <!-- <input class="modalFormInput" :placeholder="item.placeHolder || 'Inserire testo..'"  v-model="value"
        :v-upper="item.forceUpper ? true : false" @change="onChange" ref="input" @focus="onFocus" @blur="onBlur" :readonly="item.readonly"  /> -->
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: Object,
    config: Object
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    onClickBtn(btn) {
      var method = btn.onClick
      method && method(this.item)
    },
    onClickCell() {
      const btn = this.config.bottoni.find(x => x.testo === 'Seleziona')
      if (btn) {
        this.onClickBtn(btn)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .van-cell__title{
    font-size: 2vh;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .van-cell--large {
    border-bottom: 1px solid #ececec;
  }

</style>

<style lang="scss">

  .ricercaElementoField .btnRicercaElemento {
    font-size: 2vh;
    height: 5vh;
    margin-left: 5px;
    background: #ececec;
  }

</style>

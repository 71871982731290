<template>
  <div class="inputSwitch" :class="!isValid ? 'notValid' : ''">
    <div>
      <div class="labelForm">{{item.label || 'Testo Label'}}</div>
      <van-switch class="modalSwitch" v-model="value" :active-color="'#4caf50'" @click="checkClick" :disabled="item.readonly"/>
    </div>
    <span v-if="!isValid" class="labelErrore">{{ messaggioErrore || 'Valore non valido' }}</span>
    <div v-if="item.hint" class="labelHint">
      {{ item.hint }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: Object
  },
  data() {
    return {
      isValid: true,
      messaggioErrore: '',
      value: false
    }
  },
  watch: {
    value(value) {
      this.$forceUpdate()
      this.$emit('update-value', this.getDati())
    }
  },
  created() {
  },
  mounted() {
    if (this.item.valore) {
      this.value = this.item.valore
    }
  },
  methods: {
    onFocus(event) {
      this.hasFocus = true
      this.$emit('on-focus')
    },
    onBlur(event) {
      this.$emit('on-blur')
    },
    setFocus() {
      this.hasFocus = true
    },
    removeFocus() {
      this.hasFocus = false
      this.$refs.multi.$el.blur()
      this.$refs.multi.deactivate()
    },
    getDati() {
      return this.value
    },
    valida() {
      if (!this.item.validations || !this.item.validations.length) {
        return []
      }
      var req = this.item.validations.find((obj) => obj.nome === 'required')
      var err = []
      if (req && !this.value) {
        this.isValid = false
        this.messaggioErrore = req.msgErrore || 'Spuntare questa voce'
        err.push(this.messaggioErrore)
      }
      return err
    },
    checkClick() {

    }
  }
}
</script>

<style scoped>
  .inputSwitch > div  {
    display: flex;
    align-items: center;
  }

  .modalSwitch {
    margin-left: 3vh;
    font-size: 3vh;
  }
</style>

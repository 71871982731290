<template>
  <div class="inputMultiselect" :class="!isValid ? '' : ''">
    <div class="labelForm">{{item.label || 'Testo Label'}}</div>
    <span v-for="(s, i) in servizi" :key="i">
      <!-- <div class="descServizio">{{ s.descrizione }} - Durata: {{ s.durata }} {{ s.attesa ? ' minuti, di cui ' + s.attesa + ' di attesa' : '' }}</div> -->
      <div class="descServizio">{{ s.descrizione }}</div>
      <div class="descServizioDurata">Durata: {{ s.durata }} {{ s.attesa ? ' minuti, di cui ' + s.attesa + ' di attesa' : '' }}</div>
      <span><button class="btnCancellaServizio" @click="cancellaServizio(i)">Cancella</button></span>
      <select-field :item="s.datiAutocomplete" ref="multi" class="selectServizio" @on-focus="onFocus" @on-blur="onBlur" @update-value="onUpdateOperatore(i)" ></select-field>
    </span>
    <span v-if="!isValid && messaggioErrore" class="labelErrore">{{ messaggioErrore || '' }}</span>
    <div v-if="item.hint" class="labelHint">
      {{ item.hint }}
    </div>
  </div>
</template>

<script>
import { cloneObject } from '@/components/helpers'
// import configurazioni from '@/services/configurazioniService.js'
import SelectField from './AutocompleteField'
// import _ from 'lodash'

export default {
  components: {
    SelectField
  },
  props: {
    item: Object
  },
  data() {
    return {
      hasFocus: false,
      isValid: true,
      messaggioErrore: '',
      value: null,
      dataSource: [],
      servizi: [], // array con nome servizio e oggetto da passare alla multiselect con lista operatori, valore inziale, validazione required, saveformat
      itemAutocomplete: {}
    }
  },
  watch: {
    servizi(value) {
      var self = this
      setTimeout(() => { // metto timeout per dar tempo alla select di essere inizializzata
        self.$emit('update-value', self.getDati())
        self.$forceUpdate()
      }, 200)
    }
  },
  async created() {
    this.itemAutocomplete = {
      label: 'Operatore per il servizio',
      dataSource: this.item.dataSource,
      placeHolder: 'Digitare per filtrare..',
      saveFormat: 'codice',
      validations: [{ nome: 'required' }]
    }

    if (this.item.valore && this.item.valore.length) {
      for (let i = 0; i < this.item.valore.length; i++) {
        this.addServizio(this.item.valore[i])
      }
    }
  },
  mounted() {
    /* var self = this
    var currDeactivate = this.$refs.multi.deactivate

    this.$refs.multi.deactivate = function() {
      if (!self.hasFocus) {
        currDeactivate()
      }
    }
    this.$refs.multi.$el.children[0].onmousedown = function() {
      self.hasFocus = !self.hasFocus
      self.$refs.multi.toggle()
      self.onBlur()
    } */
  },
  methods: {
    onFocus(event) {
      this.hasFocus = true
      this.$emit('on-focus')
    },
    onClose(e) {
    },
    onBlur(event) {
      this.$emit('on-blur')
    },
    setFocus() {
      this.hasFocus = true
    },
    removeFocus() {
      this.hasFocus = false
      if (this.$refs.multi && this.$refs.multi.length) {
        for (let i = 0; i < this.$refs.multi.length; i++) {
          this.$refs.multi[i].removeFocus()
        }
      }
    },
    onKeyInput(value) {
      var multi = this.$refs.multi
      if (this.hasFocus && multi && multi.length) {
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].hasFocus) {
            multi[i].onKeyInput(value)
          }
        }
      }
    },
    onSelect(obj) {
      if (this.item.cascadeOn) {
        this.$emit('cascade-filter', { source: this.item, valore: obj.codice })
      }
      this.onBlur()
      var self = this
      setTimeout(() => {
        self.valida()
      }, 50)
    },
    valida() {
      if (!this.item.validations || !this.item.validations.length) {
        return []
      }
      var err = []
      if (this.$refs.multi && this.$refs.multi.length) {
        for (let i = 0; i < this.$refs.multi.length; i++) {
          err = err.concat((this.$refs.multi[i].valida()))
        }
      }

      if (err.length) {
        this.isValid = false
        this.messaggioErrore = ''
        return err
      }

      var req = this.item.validations.find((obj) => obj.nome === 'required')
      if (req && !this.servizi.length) {
        this.isValid = false
        this.messaggioErrore = req.msgErrore || 'Selezionare una voce'
        err.push(this.messaggioErrore)
      } else {
        this.isValid = true
        this.messaggioErrore = ''
      }
      return err
    },
    getDati() {
      var dati = []
      for (let i = 0; i < this.servizi.length; i++) {
        this.servizi[i].operatore = this.$refs.multi[i].getDati()
        /* dati.push({
          servizio: this.servizi[i],
          operatore: this.$refs.multi[i].getDati()
        }) */
        dati.push(this.servizi[i])
      }
      return dati
    },
    filtraElementi(campo, valore) {
      var datiFiltrati = []
      for (var i = 0; i < this.dataSource.length; i++) {
        var d = this.dataSource[i]
        if (d[campo] === valore) {
          datiFiltrati.push(d)
        }
      }
      this.dataSourceView = datiFiltrati
      var currVal = this.value
      if (currVal && !datiFiltrati.find((obj) => { return obj.codice === currVal.codice })) {
        this.value = null
      }
    },
    addServizio(servizio) { // da richiamare da esterno, quando si seleziona un servizio sul display
      var datiAutocomplete = cloneObject(this.itemAutocomplete)
      datiAutocomplete.valore = servizio.operatore || null
      servizio.datiAutocomplete = datiAutocomplete
      this.servizi.push(servizio)
    },
    cancellaServizio(index) {
      this.servizi.splice(index, 1)
    },
    onUpdateOperatore(index) {
      this.$refs.multi[index].valida()
    },
    updateOperatore(value) {
      for (let i = 0; i < this.servizi.length; i++) {
        var currOp = this.$refs.multi[i].getDati()
        if (!currOp && value) {
          this.$refs.multi[i].$refs.multi.select(value)
        }
      }
    },
    customOffset() {
      var multi = this.$refs.multi
      if (this.hasFocus && multi && multi.length) {
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].hasFocus) {
            // debugger
            return multi[i].$el.getBoundingClientRect().top
          }
        }
      }
      return 0
    }
  }
}
</script>

<style scoped>
.btnCancellaServizio {
  float: right;
  margin-right: 86px;
  margin-top: -11px;
  background: #cc0000;
  color: #ffffff;
}
.labelForm {
    color: #339900;
    text-align: center;
    text-transform: uppercase;
    margin-right: 10px;
}
.selectServizio {
  margin-bottom: 20px;
}

.selectServizio .labelForm {
  font-weight: normal;
  font-size: 1.8vh;
}

.descServizio {
  color: #339900;
  font-weight: bold;
  margin-left: 7px;
  font-size: 23px;
  margin-bottom: 2px;
}

.descServizioDurata {
  margin-left: 7px;
  font-size: 12px;
  margin-bottom: 2px;
}

.selectServizio .multiselect__tags {
    height: 6vh;
}

</style>

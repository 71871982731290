<template>
  <div class="led-circle" :class="color" :title="status.modulo"><div v-html="text" class="inner-text"/></div>
</template>

<script>
export default {
  props: {
    status: Object
  },
  data() {
    return {

    }
  },
  computed: {
    color() {
      let colore = 'led-gray'
      if (this.status && this.status.health === '---') {
        colore = 'led-gray'
      } else if (this.status && this.status.health) {
        colore = 'led-green'
      } else if (this.status && !this.status.health) {
        colore = 'led-red'
      }
      return colore
    },
    text() {
      const testo = (this.status && this.status.modulo && this.status.modulo.substring(0, 2)) || ''
      return testo.toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
  .led-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    float:left;
    margin-left:15px;
    & .inner-text {
      margin-top: 4px;
      text-align: center;
      width: 100%;
    }

    &.led-gray {
      background: gray;
    }

    &.led-green {
      background: green;
    }

    &.led-red {
      background: red;
    }
  }

</style>

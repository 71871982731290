<template>
  <div class="inputMultiselect" :class="!isValid ? 'notValid' : ''">
    <div class="labelForm">{{item.label || 'Testo Label'}}</div>
    <div>
      <multiselect ref="multi" v-model="value" :options="dataSourceView" track-by="codice" label="descrizione"
        :placeholder="item.placeHolder || 'Inserire testo..'" :multiple="true"
        :tag-placeholder="item.tagPlaceHolder || 'Aggiungi questo nuovo elemento'"
        :taggable="item.taggable" @tag="addTag"
        :selectLabel="'Seleziona'" :selectedLabel="'Selezionato'" :deselectLabel="'Rimuovi'"
        :noResult="'Nessun risultato'" @open="onFocus" @close="onClose" @select="onSelect" @remove="onRemoveSelection"
        :close-on-select="false" :disabled="item.readonly"
        >
        <span slot="noResult">Nessun risultato</span>
        <span slot="noOptions">Non ci sono voci</span>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <div class="optionSelectedNotCheck optionMultiselect" ></div>
            <div class="optionSelectedCheck optionMultiselect"></div>
            <span class="option__title">{{ props.option.descrizione || props.search }}</span>
          </div>
        </template>
      </multiselect>
    </div>
    <span v-if="!isValid" class="labelErrore">{{ item.messaggioErrore || 'Messaggio di errore validazione o suggerimento' }}</span>
    <div v-if="item.hint" class="labelHint">
      {{ item.hint }}
    </div>
  </div>
</template>

<script>
import { cloneObject } from '@/components/helpers'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import configurazioni from '@/services/configurazioniService.js'

export default {
  props: {
    item: Object
  },
  data() {
    return {
      hasFocus: false,
      isValid: true,
      messaggioErrore: '',
      value: [],
      dataSource: [],
      dataSourceView: []
    }
  },
  watch: {
    value(value) {
      this.$forceUpdate()
      this.$emit('update-value', this.getDati())
    }
  },
  async created() {
    if (Array.isArray(this.item.dataSource)) {
      this.dataSource = this.item.dataSource
    } else {
      var configName = this.item.dataSource.split('.')
      this.dataSource = await configurazioni.getConfigurazione(configName[0], configName[1])
    }
    this.dataSourceView = cloneObject(this.dataSource)
    if (this.item.valore) {
      if (this.item.saveFormat === 'comma') {
        var valori = this.item.valore.split(',')
        for (var i = 0; i < valori.length; i++) {
          this.value.push(this.dataSource.find((obj) => { return obj.codice === valori[i] }))
        }
      } else {
        this.value = this.item.valore
      }
    }
  },
  mounted() {
    var self = this
    var currDeactivate = this.$refs.multi.deactivate

    this.$refs.multi.deactivate = function() {
      if (!self.hasFocus) {
        currDeactivate()
      }
    }
    this.$refs.multi.$el.children[0].onmousedown = function() {
      self.hasFocus = !self.hasFocus
      self.$refs.multi.toggle()
      self.onBlur()
    }
  },
  methods: {
    onFocus(event) {
      console.log('on focus multiselect')
      this.hasFocus = true
      this.$emit('on-focus')
    },
    onClose(e) {
    },
    onBlur(event) {
      this.$emit('on-blur')
    },
    setFocus() {
      this.hasFocus = true
    },
    removeFocus() {
      this.hasFocus = false
      this.$refs.multi.$el.blur()
      this.$refs.multi.deactivate()
    },
    onKeyInput(value) {
      if (this.item.readonly) {
        return
      }
      if (value === '{space}') {
        value = ' '
      }
      var multi = this.$refs.multi
      if (value && value.length === 1) {
        multi.search += value
      } else if (value === '{backspace}' || value === '{bksp}') {
        if (multi.search.length > 0) {
          multi.search = multi.search.substring(0, multi.search.length - 1)
        }
      }
    },
    onSelect(obj) {
      var self = this
      setTimeout(() => {
        self.valida()
      }, 50)
    },
    onRemoveSelection() {
      var self = this
      setTimeout(() => {
        self.valida()
      }, 50)
    },
    addTag (newTag) {
      if (this.item.tagType === 'int' && isNaN(newTag)) {
        return
      }
      const tag = {
        codice: newTag,
        descrizione: newTag
      }
      this.dataSourceView.push(tag)
      if (!this.value) {
        // this.$set(this, 'value', [])
      }
      this.value.push(tag)
    },
    valida() {
      if (!this.item.validations || !this.item.validations.length) {
        return []
      }
      var req = this.item.validations.find((obj) => obj.nome === 'required')
      var err = []
      if (req && !this.value) {
        this.isValid = false
        this.messaggioErrore = req.msgErrore || 'Selezionare una voce'
        err.push(this.messaggioErrore)
      }
      return err
    },
    getDati() {
      if (!this.item.saveFormat || this.item.saveFormat === 'array') { // array di oggetti
        return this.value
      } else if (this.item.saveFormat === 'comma') { // stringa unica con separatore
        return this.value.map((obj) => obj.codice).join(',')
      }
      return []
    }
  }
}
</script>

<style scoped>
  .inputMultiselect >>> .multiselect {
    width: 90% !important;
  }

  .inputMultiselect >>> .multiselect__tags {
    height: 7vh;
    border-radius: 7px !important;
    padding: 10px;
    border: 2px solid #cccccc !important;
  }

   .inputMultiselect >>> .multiselect__content-wrapper{
     max-height: 200px !important;
   }

  .inputMultiselect >>> .multiselect__content-wrapper::-webkit-scrollbar{
    display: unset !important;
    width: 2vw;
  }

  .inputMultiselect >>> .multiselect__content-wrapper::-webkit-scrollbar-track{
    border-radius: 10px;
  }

  .inputMultiselect >>> .multiselect__content-wrapper::-webkit-scrollbar-thumb{
    background: #666666;
    border-radius: 10px;
  }

  .inputMultiselect >>> .multiselect__content-wrapper::-webkit-scrollbar-thumb:hover{
    background: #666666;
  }

  .inputMultiselect >>> .multiselect__placeholder,
  .inputMultiselect >>> .multiselect__input,
  .inputMultiselect >>> .multiselect__single
  {
     font-size: 3vh;
     font-weight: bold;
  }
  .inputMultiselect >>> .multiselect__tag
  {
     font-size: 2vh;
     font-weight: bold;
  }

  .inputMultiselect >>>  .multiselect__option--selected.multiselect__option--highlight{
    background: unset;
    color: unset;
  }

  .inputMultiselect >>> .multiselect__option--highlight {
    background: unset;
    outline: unset;
    color: unset;
  }

  .inputMultiselect >>> :not(.multiselect__option--selected) > .option__desc > .optionSelectedCheck {
    display: none;
  }

  .inputMultiselect >>> .multiselect__option--selected > .option__desc > .optionSelectedNotCheck {
    display: none;
  }

  .inputMultiselect >>> .multiselect__option:after{
    content: unset;
  }

  .inputMultiselect >>> .multiselect__content-wrapper{
     box-shadow: 12px 12px 10px #9c9c9c;
  }

  .inputMultiselect >>> .multiselect__placeholder,
  .inputMultiselect >>> .multiselect__input,
  .inputMultiselect >>> .multiselect__single
  {
    margin-top:1vh;
    font-size: 3vh;
    font-weight: bold;
  }

  .inputMultiselect >>> .option__desc {
    display: flex;
    align-items: center;
  }

  .inputMultiselect >>> .optionMultiselect {
    width: 2vh;
    height: 2vh;
    margin-right: 5px;
    background-color: #fff;
    border-radius: 100%;
    border: 3px solid #949494;
    box-shadow: 0 3px 1px 0 rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 10%), 0 3px 3px 0 rgb(0 0 0 / 5%);
  }

  .inputMultiselect >>> .optionSelectedCheck {
    background-color: orange;
  }

  .inputMultiselect >>> .optionSelectedCheck {
    background-color: orange;
  }

  .inputMultiselect >>> .option__title {
    font-size: 2vh;
  }

  .inputMultiselect >>> .multiselect__placeholder {
    color: #757575;
  }

  .inputMultiselect >>> .multiselect__select {
    z-index:9;
  }

</style>

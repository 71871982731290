<template>
  <van-cell-group title="Raccolta punti">
    <van-cell center title="Attiva">
      <template #right-icon>
        <van-switch v-model="raccoltaAttiva" size="24" disabled />
      </template>
    </van-cell>
    <van-cell title="Dalla data" :value="dataDa"/>
    <van-cell title="Alla data" :value="dataA"/>
    <van-cell title="Punti totali" :value="puntiTotali"/>
    <van-cell title="Data azzeramento" :value="dataAzzeramento"/>
    <van-cell-group title="Regole">
      <van-list>
        <van-cell
          v-for="(el, index) in regole"
          :key="index"
          :border="true"
          :title="titoloRegola(el)"
          :label="labelRegola(el)"
          :value="valueRegola(el)"
          icon="label-o"
          size="large"
        />
      </van-list>
    </van-cell-group>
    <van-cell-group title="Punti raccolti">
      <van-list>
        <van-cell
          v-for="(el, index) in raccolta"
          :key="index"
          :border="true"
          :title="titolo(el)"
          :label="label(el)"
          :value="value(el)"
          icon="cart-o"
          size="large"
        />
      </van-list>
    </van-cell-group>
    <van-cell-group title="Azzeramenti">
      <van-list>
        <van-cell
          v-for="(el, index) in azzeramenti"
          :key="index"
          :border="true"
          :title="titoloAzzeramento(el)"
          :label="labelAzzeramento(el)"
          :value="valueAzzeramento(el)"
          icon="passed"
          size="large"
        />
      </van-list>
    </van-cell-group>
  </van-cell-group>
</template>
<script>
import { dynamicSortMultiple } from '@/components/helpers.js'

export default {
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
    }
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    raccoltaAttiva() {
      return this.card && this.card.raccoltaPunti && this.card.raccoltaPunti.abilita
    },
    raccolta() {
      const rc = this.card.raccoltaPunti ? this.card.raccoltaPunti.raccolta || [] : []
      return rc.sort(dynamicSortMultiple('-_id')) //  .sort((a, b) => -(new Date(b._id) - new Date(a._id)))
    },
    regole() {
      return this.card.raccoltaPunti ? this.card.raccoltaPunti.regole || [] : []
    },
    dataDa() {
      return this.raccoltaAttiva && this.card.raccoltaPunti.dallaData ? this.$options.filters.date(this.card.raccoltaPunti.dallaData) : ''
    },
    dataA() {
      return this.raccoltaAttiva && this.card.raccoltaPunti.allaData ? this.$options.filters.date(this.card.raccoltaPunti.allaData) : ''
    },
    puntiTotali() {
      return this.raccoltaAttiva && this.card.raccoltaPunti.totale
    },
    dataAzzeramento() {
      return this.raccoltaAttiva && this.card.raccoltaPunti.dataAzzeramento ? this.$options.filters.date(this.card.raccoltaPunti.dataAzzeramento) : ''
    },
    azzeramenti() {
      const az = this.card.raccoltaPunti ? this.card.raccoltaPunti.azzeramenti || [] : []
      return az.sort(dynamicSortMultiple('-data')) // .sort((a, b) => -(new Date(b.data) - new Date(a.data)))
    }
  },
  methods: {
    titolo(item) {
      return this.$options.filters.dateTime(item.fiscale.data)
    },
    label(item) {
      return item.fiscale && item.fiscale.ricerca ? `Scontrino: ${item.fiscale.ricerca.documento}` : ''
    },
    value(item) {
      return item.puntiScontrino
    },
    titoloRegola(item) {
      return item.descrizione || item.tipo
    },
    labelRegola(item) {
      return ''
    },
    valueRegola(item) {
      return item.valore
    },
    titoloAzzeramento(item) {
      return this.$options.filters.dateTime(item.data)
    },
    labelAzzeramento(item) {
      return item.descrizione
    },
    valueAzzeramento(item) {
      return `${item.punti} -- > ${item.puntiResidui}`
    }
  }
}
</script>

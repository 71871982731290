<template>
  <div>
    <component v-for="(el, index) in popups" :key="index"
      :is="'Popup' + el.type"
      :name="'Popup' + el.type"
      :context="context"
      :show="el.show"
      @close="popupClose(el.type)"
      @send-event="sendEventToMachine"
    >
    </component>
  </div>
</template>

<script>
import PopupCercaScontrino from './PopupCercaScontrino'
import PopupCercaOperatore from './PopupCercaOperatore'
import PopupCercaCliente from './PopupCercaCliente'
import PopupCercaBuono from './PopupCercaBuono'
import PopupFidelity from './PopupFidelity'
import PopupFunzioni from './PopupFunzioni'
import PopupAppuntamento from './PopupAppuntamento'
import PopupCercaProdotto from './PopupCercaProdotto'
import PopupReport from './PopupReport'
import PopupUsaSelezione from './PopupUsaSelezione'
import PopupInserimentoTesto from './PopupInserimentoTesto'
import PopupMovimenti from './PopupMovimenti'
import PopupTastiera from './PopupTastiera'
import PopupTastieraNote from './PopupTastieraNote'
import PopupTastieraGenerica from './PopupTastieraGenerica'
import PopupCercaDocumento from './PopupCercaDocumento'
import PopupBackoffice from './PopupBackoffice'

export default {
  components: {
    PopupCercaScontrino,
    PopupCercaOperatore,
    PopupCercaCliente,
    PopupCercaBuono,
    PopupFidelity,
    PopupFunzioni,
    PopupAppuntamento,
    PopupCercaProdotto,
    PopupReport,
    PopupUsaSelezione,
    PopupInserimentoTesto,
    PopupMovimenti,
    PopupTastiera,
    PopupTastieraNote,
    PopupTastieraGenerica,
    PopupCercaDocumento,
    PopupBackoffice
  },
  props: {
    context: Object
  },
  data() {
    return {
    }
  },
  computed: {
    popups() {
      return this.context.popups
    }
  },
  methods: {
    showPopup(item) {
      return this.context.popups.includes(item)
    },
    popupClose(item) {
      const event = {
        type: 'CHIUDI_POPUP',
        value: item
      }
      this.$emit('send-event', event)
    },
    sendEventToMachine(event) {
      this.$emit('send-event', event)
    }
  }
}
</script>

'use strict'
import configurazioni from '@/services/configurazioniService.js'
// const service = process.env.VUE_APP_service_fidelity || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_fidelity || process.env.VUE_APP_service_fidelity
})()

async function cicleSync() {
  try {
    const response = await fetch(`${service}/fidelity/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getRunMode() {
  try {
    const response = await fetch(`${service}/fidelity/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'FIDELITY',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function getFidelity(barcode) {
  if (!barcode) {
    return
  }
  const response = await fetch(`${service}/fidelity/${barcode}`)
  return await response.json()
}

async function creaBuonoMultiuso(idCassa, importo) {
  var barcode = await configurazioni.getBarcodeInfo('buonomultiuso')
  const data = {
    prefisso: `${barcode.prefisso}${String(idCassa).padStart(2, '0')}`,
    ultimoNumero: barcode.ultimoNumero,
    tipo: barcode.tipoCodice,
    importo
  }
  const response = await fetch(`${service}/fidelity/create/buono`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  const buonoCreato = await response.json()
  await configurazioni.setBarcodeInfo('buonomultiuso', 'write', buonoCreato.ultimoNumero)
  return buonoCreato
}

async function aggiornaBuonoMultiuso(card) { // solo il contenuto di buono, non tutta la card ?!
  const response = await fetch(`${service}/fidelity/${card._id}/update/buono`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(card.buono)
  })
  return await response.json()
}

async function cancellaBuono(idBuono) {
  const response = await fetch(`${service}/fidelity/buono/${idBuono}/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function creaCoupon(idCassa, importo, applicaA) {
  var barcode = await configurazioni.getBarcodeInfo('coupon')
  const data = {
    prefisso: `${barcode.prefisso}${String(idCassa).padStart(2, '0')}`,
    ultimoNumero: barcode.ultimoNumero,
    tipo: barcode.tipoCodice,
    importo,
    applicaA
  }
  const response = await fetch(`${service}/fidelity/create/coupon`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  const couponCreato = await response.json()
  await configurazioni.setBarcodeInfo('coupon', 'write', couponCreato.ultimoNumero)
  return couponCreato
}

async function aggiornaCoupon(card) { // solo il contenuto di coupon, non tutta la card ?!
  const response = await fetch(`${service}/fidelity/${card._id}/update/coupon`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(card.coupon)
  })
  return await response.json()
}

async function cancellaCoupon(idCoupon) {
  const response = await fetch(`${service}/fidelity/coupon/${idCoupon}/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function creaBuonoMonouso(idCassa, importo) {
  var barcode = await configurazioni.getBarcodeInfo('buonomonouso')
  const data = {
    prefisso: `${barcode.prefisso}${String(idCassa).padStart(2, '0')}`,
    ultimoNumero: barcode.ultimoNumero,
    tipo: barcode.tipoCodice,
    importo
  }
  const response = await fetch(`${service}/fidelity/create/monouso`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  const buonoCreato = await response.json()
  await configurazioni.setBarcodeInfo('buonomonouso', 'write', buonoCreato.ultimoNumero)
  return buonoCreato
}

async function aggiornaBuonoMonouso(card) { // solo il contenuto di buono, non tutta la card ?!
  const response = await fetch(`${service}/fidelity/${card._id}/update/monouso`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(card.buono)
  })
  return await response.json()
}

async function aggiornaPrepagata(card) { // solo il contenuto di buono, non tutta la card ?!
  const response = await fetch(`${service}/fidelity/${card._id}/update/prepagata`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(card.buono)
  })
  return await response.json()
}

async function aggiornaRaccoltaPunti(card, scontrino) {
  const data = {
    scontrino
  }
  const response = await fetch(`${service}/fidelity/${card._id}/update/raccolta`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function infoRaccoltaPunti(cardId, importoPagato) {
  const data = {
    importoPagato
  }
  const response = await fetch(`${service}/fidelity/${cardId}/info/raccolta`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function annullamentoScontrino(scontrinoId, cardId, punti) {
  const data = {
    scontrinoId,
    punti
  }
  const response = await fetch(`${service}/fidelity/${cardId}/update/annullascontrino`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function aggiornaInfoAcquistoCard(codice, infoAcquisto, importo, scadenza) {
  var data = {
    infoAcquisto,
    importo,
    scadenza
  }
  const response = await fetch(`${service}/fidelity/${codice}/update/acquisto`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function creaFidelityCard(idCassa) {
  var barcode = await configurazioni.getBarcodeInfo('fidelitycard')
  const data = {
    prefisso: `${barcode.prefisso}${String(idCassa).padStart(2, '0')}`,
    ultimoNumero: barcode.ultimoNumero,
    tipo: barcode.tipoCodice
  }
  const response = await fetch(`${service}/fidelity/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  const fidelityCreata = await response.json()
  await configurazioni.setBarcodeInfo('fidelitycard', 'write', fidelityCreata.ultimoNumero)
  return fidelityCreata.card
}

async function creaFidelityCardEsterna(codice, payload) {
  const data = {
    codice,
    payload
  }
  const response = await fetch(`${service}/fidelity/create/extern`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  const fidelityCreata = await response.json()
  return fidelityCreata
}

async function creaFidelityCardCliente(idCassa, dati, cliente) {
  var barcode = await configurazioni.getBarcodeInfo('fidelitycard')
  const data = {
    prefisso: `${barcode.prefisso}${String(idCassa).padStart(2, '0')}`,
    ultimoNumero: barcode.ultimoNumero,
    tipo: barcode.tipoCodice,
    dati: dati,
    cliente: cliente
  }
  const response = await fetch(`${service}/fidelity/create/customer`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  const fidelityCreata = await response.json()
  await configurazioni.setBarcodeInfo('fidelitycard', 'write', fidelityCreata.ultimoNumero)
  return fidelityCreata.card
}

async function aggiornaFidelity(codiceFidelity, dati) {
  const response = await fetch(`${service}/fidelity/${codiceFidelity}/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dati)
  })
  return await response.json()
}

async function associaFidelity(codiceFidelity, codiceCliente, ragioneSociale) {
  var data = {
    codice: codiceCliente,
    ragioneSociale
  }
  const response = await fetch(`${service}/fidelity/${codiceFidelity}/update/associa`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function deleteFidelity(code, utente, operatore) { // inserisce il record di annullamento
  var data = {
    code,
    utente,
    operatore
  }
  const response = await fetch(`${service}/fidelity/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function cercaFidelityByCode(code, len, associata = true, type = 'multiuso') {
  const response = await fetch(`${service}/fidelity/search?code=${code}&len=${len}&associata=${associata}&type=${type}`)
  return await response.json()
}

async function cercaFidelityById(id, len, associata = true, type = 'multiuso') {
  const response = await fetch(`${service}/fidelity/search?id=${id}&len=${len}&associata=${associata}&type=${type}`)
  return await response.json()
}

async function cercaFidelityByTicket(ticket, len, associata = true, type = 'multiuso') {
  const response = await fetch(`${service}/fidelity/search?ticket=${ticket}&len=${len}&associata=${associata}&type=${type}`)
  return await response.json()
}

async function cercaFidelityByPurchaseTicket(ticket, len, associata = true, type = 'multiuso') {
  const response = await fetch(`${service}/fidelity/search?purchase=${ticket}&len=${len}&associata=${associata}&type=${type}`)
  return await response.json()
}

async function cercaFidelityByCliente(type = 'multiuso', codiceCliente) {
  const response = await fetch(`${service}/fidelity/search?customer=${codiceCliente}&type=${type}`)
  return await response.json()
}

async function elencoFidelityPerData(dataDa, dataA, type = 'multiuso') {
  const data = {
    tipo: type,
    dataDa,
    dataA
  }
  const response = await fetch(`${service}/fidelity/data`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data)
  })
  return await response.json()
}

export default {
  cicleSync,
  getRunMode,
  getFidelity,
  creaBuonoMultiuso,
  aggiornaBuonoMultiuso,
  aggiornaInfoAcquistoCard,
  creaFidelityCard,
  creaFidelityCardEsterna,
  creaFidelityCardCliente,
  aggiornaFidelity,
  aggiornaPrepagata,
  aggiornaRaccoltaPunti,
  infoRaccoltaPunti,
  annullamentoScontrino,
  associaFidelity,
  deleteFidelity,
  cercaFidelityByCode,
  cercaFidelityById,
  cercaFidelityByTicket,
  cercaFidelityByPurchaseTicket,
  cercaFidelityByCliente,
  creaBuonoMonouso,
  aggiornaBuonoMonouso,
  elencoFidelityPerData,
  cancellaBuono,
  creaCoupon,
  aggiornaCoupon,
  cancellaCoupon
}

<template>
  <keypress key-event="keyup" @any="onKeyup" :preventDefault="true" :multiple-keys="multiple" @success="multipleKeys"/>
</template>
<script>
const clipboardy = require('clipboardy')
export default {
  components: {
    Keypress: () => import('vue-keypress')
  },
  props: {
    resetBuffer: Number,
    displayValue: String,
    context: Object
  },
  data() {
    return {
      bufferLocale: [],
      timerBufferLocale: null,
      multiple: [
        {
          keyCode: 86, // V
          modifiers: ['ctrlKey'],
          preventDefault: true
        }
      ],
      attesaCtrlV: false
    }
  },
  watch: {
    reset() {
      console.log('reset buffer', JSON.stringify(this.bufferLocale))
      this.bufferLocale = []
    }
  },
  computed: {
    reset() {
      return this.resetBuffer
    }
  },
  methods: {
    async multipleKeys(response) {
      // console.log('multiple ingresso')
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      const testo = await clipboardy.read()
      // console.log('multiple uscita:', this.attesaCtrlV, testo)
      if (this.attesaCtrlV) {
        this.$store.commit('SET_KEY_PRESS_POPUP_KEYBOARD', testo)
        this.$store.commit('ADD_INPUT_POPUP_KEYBOARD', testo)
        const event = {
          type: 'NUMBER',
          value: testo
        }
        this.$emit('send-event', event)
      }
    },
    onKeyup(e) {
      if ((this.context.chiusuraPossibile && this.context.vinciteCaricate === 0) || this.context.modali.indexOf('Cashmatic') >= 0) {
        console.log('BARCODE CON CHIUSURA POSSIBILE !!')
        return
      }

      let event = {}
      // if (e.event.ctrlKey && (e.event.key === 'v' || e.event.key === 'V')) {
      if (e.event.ctrlKey || e.event.keyCode === 17) {
        if (e.event.key === 'v' || e.event.key === 'V') {
          console.log('CTRL + v')
          this.attesaCtrlV = true
        }
        return
      }
      this.sendPopupKeyboard(e.event.keyCode, e.event.key)
      if ((e.event.keyCode >= 48 && e.event.keyCode <= 90) || (e.event.keyCode === 190)) {
        event = {
          type: 'NUMBER',
          value: e.event.key
        }
        this.bufferLocale.push(e.event.key)
        this.timerBufferLocale = setTimeout(() => {
          this.bufferLocale = []
        }, 400)
      }
      if (e.event.keyCode === 8 || e.event.keyCode === 46) {
        event = {
          type: 'CLEAR'
        }
        this.bufferLocale.pop()
        console.log('bufferLocale', this.bufferLocale.length)
      }
      if (e.event.keyCode === 13) {
        console.log('onKeyup 0', this.bufferLocale)
        var testo = this.bufferLocale.join('') || (this.displayValue !== '0' ? this.displayValue : '')

        // TODO: Fare per tutti i codici particolari (es lotteria, codice fiscale, partita iva che possono confondersi con un codice prodotto)
        //       Su formati_barcode inserisco come prefisso il valore riportato sotto (es. LOT_) e correggo la lunghezza di conseguenza (es 8 + LOT_ --> 12)
        console.log('keyevent attesaCodiceLotteria:', this.context.attesaCodiceLotteria)
        if (this.context.attesaCodiceLotteria) {
          testo = 'LOT_' + testo
        }
        event = {
          // type: 'ENTER'
          type: 'ADD_BARCODE',
          code: testo
        }
        console.log('onKeyup 1', testo)
        this.bufferLocale = []
        // /// this.$store.commit('ADD_INPUT_POPUP_KEYBOARD', testo)
        // this.$store.commit('SET_KEY_PRESS_POPUP_KEYBOARD', testo) // TODO: verificare
        // this.$store.commit('SET_POPUP_KEYBOARD_INPUT', testo) // TODO: verificare
      }
      if (event.type) {
        this.$emit('send-event', event)
      }
      // https://github.com/lupas/vue-keypress
      // console.log( 'key ' + event.key + ' (' + event.keyCode + ')')
    },
    sendPopupKeyboard(keyCode, code) {
      console.log('key ' + code + ' (' + keyCode + ')')
      let key = code
      /*       if (keyCode === 8) key = '{bksp}'
      if (keyCode === 13) key = '{esci}'
      if (keyCode === 32) key = '{space}'
      if (keyCode === 17) key = '{ctrl}'
      if (keyCode === 16) key = '{shift}'
       */
      if (keyCode === 91) key = '{meta}'
      if (keyCode === 46) key = '{delete}'
      if (keyCode === 93) key = '{contextmenu}'
      if (keyCode <= 40 || (keyCode > 110 && keyCode <= 122)) {
        key = '{' + key.toLowerCase() + '}'
      }
      if (keyCode === 32) key = '{space}'
      this.$store.commit('SET_KEY_PRESS_POPUP_KEYBOARD', key)
      this.$store.commit('ADD_INPUT_POPUP_KEYBOARD', key)
    }
  }
}
</script>

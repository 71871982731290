<template>
  <van-cell-group>
    <van-cell center title="Card associata" >
      <template #right-icon>
        <van-switch v-model="card.associato" size="24" disabled />
      </template>
    </van-cell>
    <van-cell :value="card.codice" :label="'Secure Code: ' + card.secureCode" >
      <template #title>
        <span class="buono-title">Codice</span>
        <van-tag type="danger" v-if="card.annullamento">Annullato</van-tag>
      </template>
    </van-cell>
    <van-cell title="Data associazione" :value="dataAssociazione" />
<!--     <van-cell title="Scontrino acquisto" :value="scontrinoAcquisto" />
    <van-cell title="Data acquisto" :value="dataAcquisto" /> -->
    <generale  :context="context" />
    <prepagata :context="context" v-if="isPrepagata"/>
    <raccolta-punti :context="context" v-if="isRaccoltaPunti" />
    <accumulo-credito :context="context" v-if="isAccumuloCredito" />
    <abbonamenti :context="context" v-if="isAbbonamenti" />

<!--     <van-cell
      v-if="fidelityCaricata"
      :border="true"
      title="Annulla selezione card"
      icon="coupon"
      size="large"
      @click="noCard"
    ></van-cell> -->
  </van-cell-group>
</template>

<script>
import Generale from './elementi/generale'
import Prepagata from './elementi/prepagata'
import RaccoltaPunti from './elementi/raccoltaPunti'
import AccumuloCredito from './elementi/accumuloCredito'
import Abbonamenti from './elementi/abbonamenti'
import { testCardType } from './cardTypes'

export default {
  mixins: [testCardType],
  components: {
    Generale,
    Prepagata,
    RaccoltaPunti,
    AccumuloCredito,
    Abbonamenti
  },
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
      // fidelityCaricata: true // solo se associata ad un cliente ?
    }
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    dataAcquisto() {
      return this.card.dataAcquisto ? this.$options.filters.date(this.card.dataAcquisto) : ''
    },
    scontrinoAcquisto() {
      return this.card.infoAcquisto && this.card.infoAcquisto.barcode ? this.card.infoAcquisto.barcode.documento : ''
    },
    dataAssociazione() {
      return this.card.dataAssociazione ? this.$options.filters.date(this.card.dataAssociazione) : ''
    }
  },
  methods: {
    noCard() {
      this.$emit('no-card')
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }

</style>

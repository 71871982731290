<template>
  <div class="dettaglio-wrapper">
    <van-row v-if="header" type="flex" justify="space-between" class="dettaglio-header">
      <van-col span="3">Modulo</van-col>
      <van-col span="3">DB locale</van-col>
      <van-col span="5">DB remoto</van-col>
      <van-col span="3" class="dettaglio-cc">Versione</van-col>
      <van-col span="2" class="dettaglio-cc">Remoto</van-col>
      <van-col span="2" class="dettaglio-cc">Debug</van-col>
      <van-col span="2" class="dettaglio-cc">Stato</van-col>
    </van-row>
    <van-row type="flex" justify="space-between" class="dettaglio-righe">
      <van-col span="3">{{nomeModulo}}</van-col>
      <van-col span="3">{{status.locale}}</van-col>
      <van-col span="5">{{status.remote}}</van-col>
      <van-col span="3" class="dettaglio-cc">{{status.version}}</van-col>
      <van-col span="2" class="dettaglio-cc">{{status.onlyRemote ? 'SI' : 'NO'}}</van-col>
      <van-col span="2" class="dettaglio-cc">{{status.debugMode ? 'SI' : 'NO'}}</van-col>
      <van-col span="2" class="dettaglio-cc">{{status.health && status.health !== '---' ? 'OK' : 'KO'}}</van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  props: {
    status: Object,
    header: Boolean
  },
  data() {
    return {

    }
  },
  computed: {
    nomeModulo() {
      return (this.status.modulo && this.status.modulo.toLowerCase()) || ''
    }
  }
}
</script>

<style lang="scss">
  .dettaglio-header {
    font-weight: bold;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .dettaglio-righe {
    font-size: 12px;
  }
  .dettaglio-wrapper {
    margin-top: 5px;
    margin-left: 5px;
  }
  .dettaglio-cc {
    text-align: center;
  }
</style>

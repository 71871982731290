<template>
  <van-cell-group>
    <van-cell center title="Prepagata">
      <template #right-icon>
        <van-switch :value="isLocPrepagata" size="24" @change="switchChange"/>
      </template>
    </van-cell>
    <van-cell-group v-if="isLocPrepagata">
      <van-cell @touchstart.native.stop="showTastiera = (card.codice ? true : false)" title="Importo €">
        <van-field :value="importoDesc" size="large" :placeholder="card.codice ? 'Importo' : 'E\' prima necessario salvare la card '" readonly input-align="right">
        </van-field>
      </van-cell>
      <van-number-keyboard
        v-model="importo"
        :show="showTastiera"
        theme="custom"
        extra-key="00"
        close-button-text="Carica"
        @blur="showTastiera = false"
        @close="inviaRicarica"
      />
      <van-cell title="Importo acquistato" :value="importoAcquistato"/>
      <van-cell title="Importo utilizzato" :value="importoUsato"/>
      <van-cell title="Importo residuo" :value="importoResiduo"/>
    </van-cell-group>
  </van-cell-group>
</template>

<script>
import modello from './modelli/prepagata/modello.json'
import { testCardType } from '../cardTypes'

export default {
  mixins: [testCardType],
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
      importo: '',
      isLocPrepagata: this.isPrepagata,
      showTastiera: false,
      prepagata: null
    }
  },
  mounted() {
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    buono() {
      return this.card.buono || { importo: 0, dataAcquisto: null }
    },
    carichi() {
      return this.card.buono ? this.card.buono.carichi || [] : []
    },
    utilizzi() {
      return this.card.buono ? this.card.buono.utilizzi || [] : []
    },
    importoAcquistato() {
      return this.card.buono ? (this.card.buono.importo).toFixed(2) : 0
    },
    importoResiduo() {
      return this.card.buono ? this.card.buono.importoResiduo.toFixed(2) : 0
    },
    importoUsato() {
      return this.card.buono ? this.card.buono.importoUsato.toFixed(2) : 0
    },
    importoDesc() {
      return this.importo ? parseFloat(this.importo / 100).toFixed(2) : ''
    }
  },
  methods: {
    titolo(item) {
      return this.$options.filters.date(item.data)
    },
    label(item) {
      return item.fiscale && item.fiscale.barcode ? `Scontrino: ${item.fiscale.barcode.documento}` : ''
    },
    value(item) {
      return item.importo
    },
    switchChange(value) {
      this.prepagata = this.prepagata || {}
      this.prepagata.abilita = value
      this.isLocPrepagata = value
    },
    onOpen() {
      this.isLocPrepagata = this.isPrepagata
      this.prepagata = this.isPrepagata ? this.card.buono : modello
    },
    getValori() { // TODO: Il carico di una prepagata potrebbe richiamare una funzione diversa per evitare di salvare sempre la fidelity anche se non serve
      if (!this.isPrepagata) {
        return {}
      }
      const valori = {
        data: {
          buono: this.prepagata
        }
      }
      // Messo su evento separato
      /*       if (this.importo) {
        valori.comandi = {
          caricaReparto: this.importo
        }
      } */
      return valori
    },
    inviaRicarica() {
      const event = {
        type: 'RICARICA_FIDELITY_CARD',
        value: {
          comandi: {
            caricaReparto: this.importo
          }
        }
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<template>
  <div class="inputText" >
    <div class="labelForm">{{item.label || 'Testo Label'}}</div>
    <hr>
  </div>
</template>

<script>

export default {
  props: {
    item: Object
  },
  data() {
    return {
      value: null
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    onFocus(event) {
      this.$emit('on-focus')
    },
    onBlur(event) {
      this.$emit('on-blur')
    },
    setFocus() {
    },
    removeFocus() {
    }
  }
}
</script>

<style lang="scss">

</style>

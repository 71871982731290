import benetton from '@/services/custom/custom_benetton'
import { confermaOperazione } from './conferma_operazione_mixin'

export const customBenetton = {
  mixins: [confermaOperazione],
  computed: {
  },
  methods: {
    async importaOrdini() {
      if (await this.confermaOperazione('Benetton Ordini', 'Confermi avvio import Ordini ?')) {
        await benetton.importaOrdini()
      }
    },
    async importaPacking() {
      if (await this.confermaOperazione('Benetton Packing', 'Confermi avvio import Packing List ?')) {
        await benetton.importaPacking()
      }
    },
    async importaNegozi() {
      if (await this.confermaOperazione('Benetton Negozi', 'Confermi avvio import Negozi ? \n(è necessario associare il magazzino Ubisell)')) {
        await benetton.importaNegozi()
      }
    },
    async generaCategorie() {
      if (await this.confermaOperazione('Benetton Categorie', 'Confermi avvio generazione Categorie ?')) {
        await benetton.generaCategorie()
      }
    }
  }
}

<template>
  <van-popup
    :value="showDialog"
    position="left"
    closeable
    class="main-popup-backoffice"
    :style="stileDialog"
    :close-on-click-overlay="true"
    @opened="openPopup"
    @click-close-icon="closeDialog"
    @click-overlay="closeDialog"
  >
    <iframe :src="urlBackoffice" scrolling="no" :style="stileFrame" id="backIframe"></iframe>
    <van-button type="info" plain style="position:fixed; left: auto; right:10px; top: auto; bottom:10px;" @click="closeDialog">OK</van-button>
  </van-popup>
</template>

<script>
import configurazioni from '@/services/configurazioniService'
export default {
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      source: '',
      showDialog: false,
      xDialog: 100,
      yDialog: 100,
      xFrame: 100,
      yFrame: 100
    }
  },
  watch: {
    async show(value) {
      if (value) {
        await this.apriBackoffice()
        this.showDialog = true
      }
    }
  },
  mounted() {
  },
  computed: {
    baseUrl() {
      return this.impostazioni.baseUrl || 'http://localhost:8081'
    },
    backoffice() {
      return this.context.backoffice
    },
    chiave() {
      return this.backoffice.chiave || 'cassa01'
    },
    impostazioni() {
      return this.context.impostazioni.backoffice || {}
    },
    urlBackoffice() {
      console.log(`${this.baseUrl}/${this.source}`)
      return this.source ? `${this.baseUrl}/${this.source}` : ''
    },
    stileDialog() {
      return `width:${this.xDialog}%; height:${this.yDialog}%`
    },
    stileFrame() {
      return `width:${this.xFrame}vw; height:${this.yFrame}vh`
    }
  },
  methods: {
    openPopup() {

    },
    async apriBackoffice() {
      const contesto = this.backoffice.contesto
      // const comando = contesto.comando
      // let urlPagina = ''
      switch (contesto) {
        case 'fattureElettroniche':
          this.source = 'cassa/fatture-elettroniche'
          /*           this.xDialog = 50
          this.yDialog = 90
          this.xFrame = 50
          this.yFrame = 90 */
          // urlPagina // `${this.baseUrl}/${urlPagina}`
          break
        case 'menuComposto':
          var chiave = `menuTotali_${this.chiave}`
          var payload = {
            menuTotali: this.context.menuTotali
          }
          await configurazioni.setMemoriaCondivisa(chiave, payload)
          this.source = `cassa/menu-composti/${this.backoffice.menu}/${this.chiave}`
          /*           this.xDialog = 50
          this.yDialog = 90
          this.xFrame = 50
          this.yFrame = 90 */
          // urlPagina // `${this.baseUrl}/${urlPagina}`
          break
        case 'dettaglioCliente':
          this.xDialog = 50
          this.yDialog = 90
          this.xFrame = 50
          this.yFrame = 90
          this.source = `cassa/clienti/dettaglio/${this.backoffice.idCliente}`
          break
        default:
          break
      }
    },
    async closeDialog() {
      this.showDialog = false
      this.source = '' // TODO: Verificare senza (riapre più velocemente la stessa. Cosa succede se diversa ?)

      // TODO: Verificare quale lasciare
      const payload = await configurazioni.takeMemoriaCondivisa(this.chiave)
      const chiave = `menuTotali_${this.chiave}`
      const totali = await configurazioni.takeMemoriaCondivisa(chiave)
      // console.log('condivisa', condivisa)
      // this.$emit('close', condivisa)
      const event = {
        type: 'BACKOFFICE_RETURN',
        backoffice: this.backoffice,
        payload: payload,
        menuTotali: totali
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../popup.scss";
  /*
    Invece della classe fissa utilizzo stile calcolato, in modo da poter cambiare per ogni finestra
  */
  .main-popup-backoffice{
    width: 100%;
    height: 100%;
  }

  iframe {
    display: block;       /* iframes are inline by default */
    background: white;
    border: none;         /* Reset default border */
    /* height: 100vh; */       /* Viewport-relative units */
    /* width: 100vw; */
}
</style>


export const confermaOperazione = {
  methods: {
    async confermaOperazione(title, message) {
      try {
        await this.$dialog.confirm({ title, message, cancelButtonText: 'NO', confirmButtonText: 'SI' })
        return true
      } catch {
        return false
      }
    }
  }
}

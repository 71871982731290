<template>
  <van-popup
    :value="show"
    position="right"
    :close-on-click-overlay="false"
    :closeable="true"
    close-icon="close"
    close-icon-position="top-left"
    :class="'PopupCercaOperatore'"
    @click-close-icon="closePopup"
    @open="refresh"
    >
   <van-list>
      <!-- <van-swipe-cell :before-close="beforeClose" v-for="(el, index) in operatori" :key="index"> -->
      <van-cell-group :title="titoloOperatori">
        <van-cell
          v-for="(el, index) in operatori"
          :key="index"
          :border="true"
          :title="titolo(el)"
          :label="label(el)"
          :value="value(el)"
          icon="manager-o"
          size="large"
          @click="clickSeleziona(el)"
        />
      <!-- </van-swipe-cell> -->
      </van-cell-group>
      <van-cell-group>
        <van-cell
        :border="true"
          :title="'No ' + titoloOperatori"
          icon="manager"
          size="large"
          @click="noOperatore"
        ></van-cell>
      </van-cell-group>
    </van-list>
  </van-popup>
</template>

<script>
import configurazioni from '@/services/configurazioniService.js'

export default {
  props: {
    show: Boolean,
    context: Object
    // tipoOperatore: { type: String, default: 'servizio' }
  },
  data() {
    return {
      operatori: [],
      tipoOperatore: '',
      impostazioni: {}
    }
  },
  async mounted() {
    this.impostazioni = await configurazioni.getConfigurazione('display_generale', 'operatori')
    await this.refresh()
  },
  computed: {
    titoloOperatori() {
      switch (this.tipoOperatore) {
        case 'servizio':
          return 'Operatori'
        case 'accesso':
          return 'Utenti'
        case 'planning':
          return 'Operatori'
        default:
          return 'Operatori'
      }
    }
  },
  methods: {
    async refresh() {
      this.tipoOperatore = this.context.tipoOperatore || 'servizio'
      await this.cercaOperatori()
    },
    closePopup() {
      this.$emit('close')
    },
    async cercaOperatori() {
      // var result = await configurazioni.getConfigurazione('operatori', 'operatori')
      var result = await configurazioni.getOperatori()
      this.operatori = result ? result.filter(x => x[this.tipoOperatore]) : []
    },
    titolo(item) {
      return item.nome
    },
    label(item) {
      return ''
    },
    value(item) {
      return ''
    },
    clickSeleziona(item) {
      console.log(this.impostazioni.obbligatorio, this.impostazioni.vincoloPin)
      if (this.impostazioni.obbligatorio) {
        this.noOperatore()
      } else if (this.impostazioni.vincoloPin) {
        const event = {
          type: 'RICHIEDI_PIN',
          value: null
        }
        this.$emit('send-event', event)
      } else {
        const event = {
          type: 'SELEZIONA_OPERATORE',
          value: {
            tipo: this.tipoOperatore,
            item
          }
        }
        this.$emit('send-event', event)
      }
    },
    noOperatore() {
      const event = {
        type: 'SELEZIONA_OPERATORE',
        value: {
          tipo: this.tipoOperatore,
          item: null
        }
      }
      this.$emit('send-event', event)
    },
    beforeClose({ position, instance }) {
      switch (position) {
        case 'left':
        case 'cell':
        case 'outside':
          instance.close()
          break
        case 'right':
          instance.close()
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../popup.scss";
  @import "./style.scss";

  .swipe-cell-button {
    height: 100%;
  }

  [name = PopupCercaOperatore] .van-list {
    margin-top: 60px;
    margin-left: 10px;
  }
  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .PopupCercaOperatore {
      width: 35%;
      height: 90%;
    }
  }
  @media all and (min-width:1024px) {
    .PopupCercaOperatore {
      width: 35%;
      height: 90%;
    }
  }
  @media all and (min-width:1400px) {
    .PopupCercaOperatore {
      width: 25%;
      height: 90%;
    }
  }
</style>

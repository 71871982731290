export const tornaAllaCassa = {
  methods: {
    tornaAllaCassa() {
      // this.$store.commit('HIDE_POPUP_KEYBOARD')
      // this.$store.commit('HIDE_POPUP_LIST_RESULT')
      const event = {
        type: 'CHIUDI_POPUP',
        value: 'Funzioni'
      }
      this.$emit('send-event', event)
    }
  }
}

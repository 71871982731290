export default {
  render() {
    return ''
  },
  props: {
    errore: Object
  },
  watch: {
    errore(error) {
      if (error && !error.scontrino) {
        this.$dialog.confirm({
          title: 'Errore stampante',
          message: this.errorMessage(error.message),
          cancelButtonText: 'Cancella',
          confirmButtonText: 'Riprova'
        }).then(() => {
          // on confirm
          console.log('Riprova: evento PRINTER_RESUME')
          const event = {
            type: 'PRINTER_RESUME',
            data: error.code,
            error: error
          }
          this.$emit('send-event', event)
        }).catch(() => {
          // on cancel
          const event = {
            type: 'PRINTER_ABORT',
            data: error.code
          }
          this.$emit('send-event', event)
        })
      }
      if (error && error.scontrino) {
        this.$dialog.confirm({
          title: 'Errore stampa scontrino',
          message: 'Vuoi salvare ugualmente lo scontrino ?<br/><br/><strong>Ti ricordiamo che non è fiscalmente valido<br/>fino al momento della stampa !</strong>',
          cancelButtonText: 'Annullare',
          confirmButtonText: 'Salvare',
          width: '500px'
        }).then(() => {
          // on confirm
          const event = {
            type: 'PRINTER_SAVE_RECEIPT',
            data: error.code
          }
          this.$emit('send-event', event)
        }).catch(() => {
          // on cancel
          const event = {
            type: 'PRINTER_DELETE_RECEIPT',
            data: error.code
          }
          this.$emit('send-event', event)
        })
      }
    }
  },
  methods: {
    errorMessage(message) {
      if (typeof message === 'object') {
        return message.description || message.error || message
      } else {
        return message
      }
    }
  }
}

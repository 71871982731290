<template>
  <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-body">
                <slot name="body">
                  <div v-for="ag in agende" :key="ag.nome" @click="selectAgenda(ag.nome)" class="rigaAgenda">
                    {{ag.nome}}: {{ ag.descrizione }}
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
</template>

<script>
export default {
  props: {
    context: Object
  },
  data() {
    return {
      agende: []
    }
  },
  mounted() {
    // Leggere agende da servizio appuntamenti
    this.agende = [
      { nome: 'Agenda_1', descrizione: 'taglio e piega' },
      { nome: 'Agenda_2', descrizione: 'lampade e lettini' },
      { nome: 'Agenda_3', descrizione: 'podologo' },
      { nome: 'Agenda_4', descrizione: 'solo lunedi' }
    ]
  },
  methods: {
    selectAgenda(agenda) {
      const event = {
        type: 'SELEZIONA_AGENDA',
        value: agenda
      }
      this.$emit('send-event', event)
      this.$emit('send-event', 'CHIUDI_MODALE')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../modale.scss";
  @import "./style.scss";

  .rigaAgenda {
    border: 1x dotted blue;
  }
</style>

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupCercaCliente main-popup-cerca-cliente"
      @open="onOpen"
      @click-overlay="closePopup"
      >
      <van-form @submit="onSubmit">
        <van-search
          v-model="cliente"
          show-action
          name="cliente"
          ref="cliente"
          label="Cliente"
          placeholder="Cliente"
          @search="onSearchCliente"
          @clear="onClearCliente"
          @focus="onFocus"
          class="mostra-descrizione"
        >
        <template #action>
          <div @click="onSearchCliente">Cerca</div>
        </template>
        </van-search>
      </van-form>
      <popup-list-result class="result-popup-cerca-cliente"/>
      <simple-popup-keyboard class="keyboard-popup-cerca-cliente" enterText="Torna alla Cassa" :keyboardClass="'kb_cerca_cliente'" />
    </van-popup>
</template>

<script>
import _ from 'lodash'
import clienti from '@/services/clientiService.js'
import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'
import PopupListResult from '@/components/popup/ListResult'

export default {
  components: {
    SimplePopupKeyboard,
    PopupListResult
  },
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      cliente: '',
      clienteItem: null,
      activeTab: 'popup',
      activeParent: 'clienti',
      activeField: 'cliente'
    }
  },
  created() {
    this.debounceInputClienti = _.debounce(this.getElencoClienti, 500)
  },
  async mounted() {
    await this.onOpen()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    listResult() {
      return this.$store.getters.listResult
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    kbKeyPress(value) {
      if (this.show) {
        if (value === '{esci}') {
          this.closePopup()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          this.closePopup()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    listResult(payload) {
      // console.log('cl', payload.parent, this.activeParent, payload.tab, this.activeTab)
      if (payload.parent !== this.activeParent || payload.tab !== this.activeTab || !payload.result) return
      const item = payload.result
      this.clienteItem = item
      this.setClienteContext()
      this.cliente = item.codice
      // this.descrizioneCliente = item.descrizione
      this.$store.commit('clienti/SET_CLIENTE', this.clienteItem)
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      // this.closePopup()
    }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.tab-popup-cerca-cliente')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    handleKey(valore) {
      this.debounceInputClienti(valore)
    },
    onSearchCliente() {
      this.getElencoClienti(this.cliente)
    },
    onClearCliente() {
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
    },
    async getElencoClienti(value) {
      /*       if (!value) {
        this.elencoClienti = []
      } else { */
      const result = await clienti.searchClienti(value)
      if (result && result.docs && result.docs.length > 0) {
        const lista = result.docs.map(item => {
          let label = item.recapiti && item.recapiti.length > 0 ? item.recapiti[0].indirizzo : ''
          if (item.amministrativi.cliente) {
            label = item.contatti && item.contatti.length > 0 ? (item.contatti.nome || '') + ' ' + (item.contatti.cognome || '') : ''
          }
          return {
            codice: item.codice,
            descrizione: item.ragioneSociale,
            titolo: `${item.codice} - ${item.ragioneSociale}`,
            label: label, // `${item.recapiti[0].indirizzo}`,
            value: `${item.amministrativi.partitaIva || ''}`
          }
        })
        this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
          list: lista,
          icon: 'user-o',
          classes: {
            titleClass: 'clienti-lr-title',
            labelClass: 'clienti-lr-label',
            valueClass: 'clienti-lr-value'
          }
        })
      } else {
        console.log('getElencoClienti noresult')
        this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      }
      // }
    },
    onFocus(event) {
      console.log('onfocus')
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
    },
    async onSubmit(values) {
      this.clearFields()
    },
    async onOpen() {
      this.clearSearchInput()
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      this.$store.commit('SET_EXTERNAL_TAB', this.activeParent)
      this.$store.commit('SET_INTERNAL_TAB', this.activeTab)
      await this.getElencoClienti('')
    },
    clearSearchInput() {
      this.cliente = ''
    },
    clearFields() {
      this.clearSearchInput()
    },
    closePopup() {
      this.$emit('close')
      this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '')
    },
    setClienteContext() {
      const event = {
        type: 'SELEZIONA_CLIENTE',
        value: this.clienteItem
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .result-popup-cerca-cliente {
    margin-top: 20px;
  }

  .swipe-cell-button {
    height: 100%;
  }
  .van-search .van-cell {
    padding: 10px 16px;
  }

  .van-search--show-action {
      padding-right: 12px;
  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupCercaCliente, .main-popup-cerca-cliente {
      width: 85%;
      height: 100%;
    }
  }
  @media all and (min-width:1024px) {
    .popupCercaCliente, .main-popup-cerca-cliente {
      width: 65%;
      height: 100%;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-cerca-cliente {
      width: 40%;
      height: 100%;
    }
  }
</style>

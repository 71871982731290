'use strict'
import moment from 'moment'

const infoStampante = function(risposta) {
  if (!risposta || risposta.errori) {
    return {
      matricola: '--',
      data: '--',
      firmware: '--',
      liberoDGFE: '--',
      modo: '--',
      stato: '--',
      lastZ: '--',
      lastDocF: '--',
      errore: risposta.errori ? `${risposta.errori.message.error}: ${risposta.errori.message.description}` : 'Errore',
      errCode: risposta.errori ? risposta.errori.message.code : '',
      filePendenti: '--',
      oldFile: '',
      lowDGFE: false
    }
  } else {
    return {
      matricola: risposta.serial.matricola,
      data: moment(risposta.getDate.isoDate).format('DD/MM/yyyy HH:mm'),
      firmware: 'FW v.' + risposta.printerStatus.cpu,
      liberoDGFE: `${100 - risposta.dgfeStatus.usePerc}%`,
      modo: risposta.printerStatus.status.operative,
      stato: risposta.printerStatus.mf_stat,
      lastZ: risposta.daily.lastZ,
      lastDocF: risposta.document.lastDocument,
      errore: '',
      errCode: '',
      filePendenti: `${risposta.rtStatus.filesToSend} / ${'---'}`,
      oldFile: '',
      lowDGFE: risposta.dgfeStatus.usePerc > 90
    }
  }
}

const mostraAggiornaOra = function(risposta) {
  if (risposta && risposta.getDate) {
    const diff = moment().diff(moment(risposta.getDate.isoDate), 'm')
    return Math.abs(diff) > 2
  }
  return false
}

const mostraCambioStato = function(risposta) {
  // Verificare: devve segnalare se non è stata effettuata la chiusura
  return false
}

const mostraCambioModo = function(risposta) {
  // verificare: Deve segnalare se non è in stato registrazione
  return risposta && risposta.printerStatus && risposta.printerStatus.status.operative !== 'Stato Registrazione'
}

const passaModoReg = function() {

}

const aggiornaOraPossibile = function(infoStampante) {
  return infoStampante.rtStatus.dayOpened === 0
}

export default {
  infoStampante,
  mostraAggiornaOra,
  mostraCambioStato,
  mostraCambioModo,
  passaModoReg,
  aggiornaOraPossibile
}

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupReport main-popup-movimenti"
      @open="onOpen"
      @click-overlay="closePopup"
      >
      <van-tabs v-model="activeName"  >
        <van-tab name="filtri" title="Filtri">
          <van-cell-group>
            <van-cell title="Elenco movimenti" label="Seleziona i filtri per la visualizzazione" title-class="titleClass" :border="false"/>
            <component v-for="(el, index) in filtriApplicabili" :key="index"
              :is="'Par' + el.type"
              :name="'Par' + el.type + String(index)"
              :elemento="el"
              :minDate="minDate"
              @change="componentValueChange"
            >
            </component>
          </van-cell-group>
          <van-cell title="Usa quantità anagrafica">
            <van-switch v-model="usaQtaAnagrafica" size="24" active-color="blu"/>
          </van-cell>
          <van-button type="primary" block @click="cercaMovimenti" class="buttonStampa">Ricerca</van-button>
        </van-tab>
        <van-tab name="movimenti">
          <template #title>Movimenti <van-badge :content="movimenti.length > 0 ? movimenti.length : ''" /></template>
          <van-cell-group>
            <van-cell
              v-for="el in elementi"
              :key=el.prodotto.codice
              :title="el.prodotto.codice + (el.prodotto.codice === '*** TUTTI ***' ? '' : ' ' + el.variante1 + ' ' + el.variante2)"
              :label="el.prodotto.descrizione"
              @click="el.prodotto.codice === '*** TUTTI ***' ? tuttiClick() : cellClick(el.prodotto.codice)"
            >
              <template #right-icon>
                <van-switch v-if="el.prodotto.codice !== '*** TUTTI ***'" v-model="elSel[el.prodotto.codice]" size="24" active-color="#ee0a24" @click="checkClick"/>
                <van-switch v-else v-model="elTutti" size="24" active-color="#960213" @click="tuttiCheckClick"/>
              </template>
            </van-cell>
              <h5 class="altriRisultati-container" @click="altriRisultati"><span class="altriRisultati">mostra altri risultati...</span></h5>
          </van-cell-group>
          <van-cell-group>
            <!--  <van-dropdown-menu>
                        <van-dropdown-item :title-class="'ddTitleClass'" :options="moduliStampa" />
                      </van-dropdown-menu>
            -->
            <van-cell center>
              <template #title>
                <van-badge :content="totaleEtichette > 0 ? totaleEtichette : ''">
                  <van-button type="primary" @click="stampaEtichette">Stampa Etichette</van-button>
                </van-badge>
              </template>
              <template #right-icon>
                Salta <van-stepper v-model="salta" min="0" style="margin-left: 5px;"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-tab>
      </van-tabs>
    </van-popup>
</template>

<script>
import moment from 'moment'
import magazzino from '@/services/magazzinoService.js'
import etichetteService from '@/services/etichetteService.js'
import ParData from './elementi/data'
import ParMultiselect from './elementi/multiselect'
import ParSwitch from './elementi/switch'
import ParTesto from './elementi/testo'
import lodash from 'lodash'
// import { dynamicSortMultiple } from '@/components/helpers.js'

var modelloFiltri = require('./filtri.json')

export default {
  props: {
    show: Boolean,
    context: Object
  },
  components: { ParData, ParMultiselect, ParSwitch, ParTesto },
  data() {
    return {
      activeName: 'filtri',
      minDate: new Date(2021, 0, 1),
      // report: modelloFiltri,
      filtri: {},
      movimenti: [],
      elementi: [],
      filtroElementi: null,
      elSel: {},
      elRag: false,
      elTutti: false,
      numberToShow: 10,
      pathEtichettePrefix: '',
      salta: 0,
      usaQtaAnagrafica: false
    }
  },
  created() {
    this.pathEtichettePrefix = process.env.VUE_APP_service_etichette || ''
  },
  async mounted() {
    this.onOpen()
  },
  computed: {
    report() {
      const modello = this.context.impostazioni.custom && this.context.impostazioni.custom.benetton ? 'benetton' : 'base' // TODO : gestire in base ai componenti custom caricati
      return modelloFiltri[modello]
    },
    filtriApplicabili() {
      return this.report.filtri ? this.report.filtri.filter(x => x.type) : []
    },
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    listResult() {
      return this.$store.getters.listResult
    },
    totaleEtichette() {
      // let totale = this.usaQtaAnagrafica ? lodash.uniq(this.movimenti.map(x => x.prodotto.codice)).length : lodash.sum(this.movimenti.map(x => x.quantita))
      const raw = this.elSel
      const selezionati = Object.keys(raw).filter(key => raw[key] === true)
      if (selezionati.includes('*** TUTTI ***')) {
        const totale = this.usaQtaAnagrafica ? lodash.uniq(this.movimenti.map(x => x.prodotto.codice)).length : lodash.sum(this.movimenti.map(x => x.quantita))
        return totale
      } else {
        const movimenti = this.movimenti.filter(x => selezionati.includes(x.prodotto.codice))
        const totale = this.usaQtaAnagrafica ? lodash.uniq(movimenti.map(x => x.prodotto.codice)).length : lodash.sum(movimenti.map(x => x.quantita))
        return totale
      }
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    kbKeyPress(value) {
      if (this.show) {
        if (value === '{esci}') {
          this.closePopup()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          this.closePopup()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.main-popup-movimenti')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    handleKey(valore) {
    },
    componentValueChange(model, value) {
      this.filtri[model] = value
      console.log(model, value)
    },
    async cercaMovimenti() {
      let nonCompleti = false
      this.report.filtri.forEach(el => {
        if (el.required && !this.filtri[el.model]) {
          nonCompleti = true
        }
      })
      if (nonCompleti) {
        this.$toast('Dati non completi, verificare...', { type: 'fail', duration: 20000 })
      } else {
        const start = this.filtri.dataDa ? moment(this.filtri.dataDa, 'DD/MM/YYYY').format('YYYYMMDD') : 'ddd' // nel caso il report non li prevedesse
        const stop = this.filtri.dataA ? moment(this.filtri.dataA, 'DD/MM/YYYY').format('YYYYMMDD') : 'aaa'

        const filtriAggiuntivi = []
        this.report.filtri.filter(x => !['dataDa', 'dataA'].includes(x.model) && (x.model && !x.model.startsWith('rag-') && x.model !== 'mostraArticoli')).forEach(el => {
          const ff = lodash.pickBy(this.filtri[el.model])
          if (this.filtri[el.model] && ff) {
            const valFiltro = this.filtri[el.model]
            if (typeof valFiltro === 'object') {
              filtriAggiuntivi.push({ chiave: el.model, valori: Object.keys(ff), applicazione: el.applicazione })
            } else {
              filtriAggiuntivi.push({ chiave: el.model, testo: valFiltro, applicazione: el.applicazione })
            }
          } else {
            filtriAggiuntivi.push({ chiave: el.model, valori: [], applicazione: el.applicazione }) // serve il tipo applicazione per eventuale raggruppamento
          }
        })
        const articoli = true
        const parametri = {
          articoli: articoli,
          filtri: filtriAggiuntivi,
          gruppi: [],
          tipoQuantita: this.usaQtaAnagrafica ? 'anagrafica' : 'movimento' // non effettua la unique dei prodotti
        }
        const filtro = {
          start: start,
          end: stop
        }
        const datiMovimenti = await magazzino.getMovimentiParametri(filtro, parametri)
        this.filtroElementi = { filtro, parametri }
        this.activeName = 'movimenti'
        // this.movimenti = datiMovimenti.sort(dynamicSortMultiple('sort'))  // TODO ordinamento ?
        this.movimenti = datiMovimenti
        this.elementi = lodash.take(this.movimenti, this.numberToShow)
        // console.log(this.elementi)
        const tutto = { prodotto: { codice: '*** TUTTI ***', descrizione: '' } }
        this.elementi = [tutto, ...this.elementi]
      }
    },
    altriRisultati() {
      this.numberToShow += 20
      this.elementi = lodash.take(this.movimenti, this.numberToShow)
      const tutto = { prodotto: { codice: '*** TUTTI ***', descrizione: '' } }
      this.elementi = [tutto, ...this.elementi]
    },
    onOpen() {
    },
    closePopup() {
      this.$emit('close')
    },
    tuttiClick() {
      this.elTutti = !this.elTutti
      for (const el of this.elementi) {
        this.$set(this.elSel, el.codice, this.elTutti)
      }
      // this.$emit('change', this.elemento.model, this.elSel)
      setTimeout(() => {
        const itm = this.$refs.item
        if (itm) {
          itm.toggle()
        }
      }, 500)
    },
    cellClick(codice) {
      if (this.elSel[codice] === undefined) {
        this.$set(this.elSel, codice, true)
      } else {
        this.elSel[codice] = !this.elSel[codice]
      }
      // this.$emit('change', this.elemento.model, this.elSel)
    },
    checkClick(e) {
      e.stopPropagation()
      // this.$emit('change', this.elemento.model, this.elSel)
    },
    tuttiCheckClick(e) {
      e.stopPropagation()
      for (const el of this.elementi) {
        this.$set(this.elSel, el.prodotto.codice, !this.elTutti)
      }
      // this.$emit('change', this.elemento.model, this.elSel)
      setTimeout(() => {
        const itm = this.$refs.item
        if (itm) {
          itm.toggle()
        }
      }, 500)
    },
    async stampaEtichette() {
      try {
        const raw = this.elSel
        let selezionati = Object.keys(raw).filter(key => raw[key] === true)
        if (selezionati.length === 0) {
          this.$toast('E\' necessario selezionare i movimenti', { type: 'fail' })
          return
        }
        await this.$dialog.confirm({ title: 'Stampa etichette', message: 'Confermi la stampa delle etichette per i prodotti selezionati?', cancelButtonText: 'NO', confirmButtonText: 'SI' })
        // const raw = this.elSel
        // let selezionati = Object.keys(raw).filter(key => raw[key] === true) // .reduce((obj, key) => { obj[key] = raw[key]; return obj }, {}) //  Object.keys(this.elSel)
        var prodotti
        const tipoQuantita = this.usaQtaAnagrafica ? 'anagrafica' : 'movimento'
        if (selezionati.includes('*** TUTTI ***')) {
          // non può passare l'elenco perchè potrebbe essere esteso --> filtri per la query
          const tipoStampa = this.context.impostazioni.etichette.tipo
          const report = this.context.impostazioni.etichette.report
          const src = `${this.pathEtichettePrefix}/etichette/${tipoStampa}/${report}?elm=${btoa(JSON.stringify(this.filtroElementi))}${this.salta ? '&sk=' + this.salta : ''}${tipoQuantita ? '&tpq=' + tipoQuantita : ''}`
          await etichetteService.stampaDiretta(tipoStampa, src) // passare flag tipo quantità
        } else {
          selezionati = selezionati.filter(x => !['*** TUTTI ***', 'undefined'].includes(x))
          prodotti = this.movimenti.filter(x => selezionati.includes(x.prodotto.codice))
          // TODO: Se necessario richiamare prodotti da ids come su etichette da selezione
          const etichette = prodotti.map(el => {
            return {
              codice: el.prodotto.codiceProdotto,
              descrizione: el.prodotto.descrizione,
              taglia: el.prodotto.variante1.codice,
              colore: el.prodotto.variante2.codice,
              prezzo: el.prodotto.prezzo,
              acquisto: el.prodotto.acquisto,
              barcode: el.prodotto.barcode,
              prezzo2: el.prodotto.prezzo2,
              prezzo2Sconto: el.prodotto.prezzo2Sconto,
              quantita: el.quantita // verificare
            }
          })
          const str = JSON.stringify(etichette)
          const tipoStampa = this.context.impostazioni.etichette.tipo
          const report = this.context.impostazioni.etichette.report
          await etichetteService.stampaEtichetta(tipoStampa, report, str, this.salta, tipoQuantita) // passare flag tipo quantità
        }
      } catch {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .van-field__control {
    width: auto;
  }

  .ddTitleClass {
    justify-content: left;
  }

  .titleClass {
    text-align: center;
    color: red;
  }

  .buttonStampa {
    margin-top: 20px;
  }

  .swipe-cell-button {
    height: 100%;
  }
  .van-search .van-cell {
    padding: 10px 16px;
  }

  .van-search--show-action {
      padding-right: 12px;
  }

  .altriRisultati-container {
    width: 100%;
    text-align: center;
  }
  .altriRisultati {
    background-color: aliceblue;
    border-radius: 5px;
    padding: 5px;
  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupReport, .main-popup-movimenti {
      width: 75%;
      height: 100%;
    }
  }
  @media all and (min-width:1024px) {
    .popupReport, .main-popup-movimenti {
      width: 55%;
      height: 100%;
    }
    .van-cell {
      line-height: 13px;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-movimenti {
      width: 30%;
      height: 100%;
    }
  }
</style>

'use strict'

// const service = process.env.VUE_APP_service_custom_benetton || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)

var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_custom_benetton || process.env.VUE_APP_service_custom_benetton
})()

async function importaOrdini() {
  const response = await fetch(`${service}/custom_benetton/elabora/ordini`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function importaPacking() {
  const response = await fetch(`${service}/custom_benetton/elabora/packing`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function importaNegozi() {
  const response = await fetch(`${service}/custom_benetton/elabora/negozi`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function generaCategorie() {
  const response = await fetch(`${service}/custom_benetton/genera/categorie`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

export default {
  importaOrdini,
  importaPacking,
  importaNegozi,
  generaCategorie
}

<template>
  <van-cell-group>
    <van-cell  title="Cerca cliente" center>
      <van-field v-model="input" placeholder="codice o ragione sociale" autofocus="true" @touchstart.native.stop="showTastiera = true">
<!--         <template #button>
          <van-button size="small" type="info" @click="showNuovoCliente = !showNuovoCliente">Nuovo</van-button>
        </template> -->
        <template #right-icon>
          <van-button v-if="loading" size="small" loading plain loading-type="spinner"></van-button>
        </template>
      </van-field>
    </van-cell>
<!--     <van-cell-group v-if="showNuovoCliente">
      <nuovo-cliente @created="onNuovoCliente" ref="nuovoCliente"/>
    </van-cell-group > -->
    <van-cell-group >
      <simple-popup-keyboard v-if="showTastiera" @onChange="onChange" @onKeyPress="onKeyPress" :input="input" enterText="CERCA" :keyboardClass="'kb_fidelity'"  />
      <!-- <simple-keyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input" enterText="CERCA" :keyboardClass="'kb_fidelity'" /> -->
      <van-list>
        <van-swipe-cell v-for="(el, index) in elencoClienti" :key="index">
          <template #left>
            <van-button square color="#5badfa" text="Seleziona" class="swipe-cell-button" @click="clickSeleziona(el)"/>
          </template>
          <van-cell
            :border="true"
            :title="titolo(el)"
            :label="label(el)"
            :value="value(el)"
            icon="user-o"
            size="large"
            @click="clickSeleziona(el)"
          />
        </van-swipe-cell>
      </van-list>
    </van-cell-group>
    <!-- <van-cell-group title="Associazione cliente" v-if="!showTastiera && !associata && codice"> -->
    <van-cell-group title="Associazione cliente" v-if="!associata && codice">
      <van-cell title="Codice" :value="codice"/>
      <van-cell title="Ragione sociale" :value="ragioneSociale"/>
      <van-button type="warning" block @click="associaCliente" v-if="fidelityCaricata && !associata">Associa cliente</van-button>
    </van-cell-group>
  </van-cell-group>
</template>

<script>
import _ from 'lodash'
import clienti from '@/services/clientiService.js'
/* import SimpleKeyboard from './elementi/SimpleKeyboard' */
/* import NuovoCliente from './elementi/nuovoCliente' */
import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'

export default {
  components: {
    SimplePopupKeyboard
    /*     NuovoCliente */
    /* SimpleKeyboard */
  },
  props: {
    context: Object
  },
  data() {
    return {
      showTastiera: false,
      input: '',
      cliente: null,
      elencoClienti: [],
      loading: false,
      showNuovoCliente: false
    }
  },
  created() {
    this.debounceInput = _.debounce(this.getElencoClienti, 500)
  },
  watch: {
    input(newMessage, oldMessage) {
      // this.debounceInput()
    }
  },
  computed: {
    card() {
      return this.context.fidelityCard
    },
    fidelityCaricata() {
      return this.card && this.card.codice
    },
    associata() {
      return this.fidelityCaricata && this.card.associato
    },
    codice() {
      return this.cliente ? this.cliente.codice : ''
    },
    ragioneSociale() {
      return this.cliente ? this.cliente.ragioneSociale : ''
    }
  },
  methods: {
    titolo(item) {
      if (item.amministrativi.cliente) {
        return item.ragioneSociale
      } else {
        return `${item.codice} - ${item.ragioneSociale}`
      }
    },
    label(item) {
      if (item.amministrativi.cliente) {
        const contatto = item.contatti && item.contatti.length > 0 ? (item.contatti.nome || '') + ' ' + (item.contatti.cognome || '') : ''
        return contatto
      } else {
        const recapito = item.recapiti && item.recapiti.length > 0 ? item.recapiti[0].indirizzo : ''
        return recapito
      }
    },
    value(item) {
      return `${item.amministrativi.partitaIva || ''}`
    },
    async onChange(input) {
      this.input = input
    },
    async onKeyPress(button) {
      if (button === '{esci}') {
        this.showTastiera = false
        await this.getElencoClienti()
      }
      /*       this.button = button
      if (this.scegliCliente) {
        if (!this.insertNuovoCliente && !this.modificaDatiCliente && button === '{esci}') {
          this.aggiungiCliente(this.clienteGriglia)
        }
      }
      if (this.scegliProdotto) {
        if (button === '{esci}') { // verificare se evento long press
          this.aggiungiProdotto(this.prodotto)
        }
      } */
    },
    async getElencoClienti() {
      this.cliente = null
      this.loading = true
      if (!this.input) {
        this.elencoClienti = []
      } else {
        const result = await clienti.searchClienti(this.input)
        this.elencoClienti = result.docs
      }
      this.loading = false
    },
    clickSeleziona(item) {
      this.cliente = item
      this.seleziona()
    },
    seleziona() {
      const event = {
        type: 'SELEZIONA_CLIENTE',
        value: this.cliente
      }
      this.$emit('send-event', event)
      this.showTastiera = false
      this.elencoClienti = []
    },
    associaCliente() {
      this.$dialog.confirm({
        message: 'Confermi associazione a cliente ?',
        cancelButtonText: 'NO',
        confirmButtonText: 'SI'
      }).then(() => {
        const event = {
          type: 'FIDELITY_ASSOCIA',
          value: {
            codice: this.cliente.codice
          }
        }
        this.$emit('send-event', event)
      })
    },
    onNuovoCliente(cliente) {
      this.clickSeleziona(cliente)
      this.showNuovoCliente = false
    },
    onOpen() {
      this.showNuovoCliente = false
      this.showTastiera = false
      this.$refs.nuovoCliente.onOpen()
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }

</style>

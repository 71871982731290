<template>
  <van-tabs v-model="activeName" @change="onChangeTab" :lazy-render="false">
    <van-tab title="Cerca" name="ricerca">
      <ricerca :context="context" @send-event="sendEventToParent" ref="ricerca"/>
    </van-tab>
    <van-tab title="Modifica" name="modifica">
      <modifica @send-event="sendEventToParent" ref="modifica"/>
    </van-tab>
    <van-tab title="Nuovo" name="nuovo">
      <nuovo :context="context" @send-event="sendEventToParent" ref="nuovo"/>
    </van-tab>
    <van-tab title="Statistiche" name="statistiche">
    </van-tab>
    <van-tab title="Elimina" name="elimina">
    </van-tab>
  </van-tabs>
</template>

<script>
import Ricerca from './ricerca'
import Nuovo from './nuovo'
import Modifica from './modifica'

export default {
  components: {
    Ricerca,
    Nuovo,
    Modifica
  },
  props: {
    context: Object
  },
  data() {
    return {
      activeName: 'nuovo',
      moduli: ['ricerca', 'nuovo', 'modifica']
    }
  },
  mounted() {
    setTimeout(() => {
      this.activeName = 'modifica'
    }, 3000)
  },
  /*   computed: {
    activeName: {
      get() {
        return 'modifica' // this.$store.getters['appuntamenti/nuovoAppuntamento'] ? 'nuovo' : 'modifica'
      },
      set (newName) {
        return newName
      }
    }
  }, */
  methods: {
    onChangeTab(name) {
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      this.$store.commit('SET_INTERNAL_TAB', name)
      this.childOpen(name)
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    },
    onOpen() {
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      this.callOpenChilds()
    },
    callOpenChilds() {
      this.moduli.forEach(modulo => {
        this.childOpen(modulo)
      })
    },
    childOpen(nome) {
      console.log('childOpen clienti:', nome)
      const refM = this.$refs[nome]
      if (refM && refM.onOpen) {
        refM.onOpen()
      }
    }
  }
}
</script>

/* eslint-disable */
// https://mobcup.net/browse/ringtones/mp3/0/downloads
const audioFile = {
  beeps: require ('@/assets/mp3/beeps.mp3'),
  games: require ('@/assets/mp3/games.mp3'),
  ring3: require ('@/assets/mp3/ring3.mp3'),
  win1: require ('@/assets/mp3/win1.mp3'),
  win2: require ('@/assets/mp3/win2.mp3')
}
/* eslint-enable */

export default {
  render() {
    return ''
  },
  props: {
    type: { type: String, default: 'fail' },
    message: String,
    confirmObj: { type: Object },
    confirm: String
    // confirmEvent: String
  },
  watch: {
    message(msg) {
      if (msg) {
        this.$toast(msg, { type: this.type, duration: 3000 })
        const event = {
          type: 'TOAST_RESET'
        }
        this.$emit('send-event', event)
      }
    },
    confirm(msg) {
      if (msg) {
        if (this.confirmObj.beepSound && audioFile[this.confirmObj.beepSound]) {
          const sound = new Audio(audioFile[this.confirmObj.beepSound])
          sound.play()
        }

        this.$dialog.confirm({
          title: this.confirmObj.title || '',
          message: msg,
          cancelButtonText: this.confirmObj.cancelText, // 'NO',
          confirmButtonText: this.confirmObj.confirmText // 'SI'
        }).then(() => {
          if (this.confirmObj.event) {
            const event = {
              type: 'TOAST_CONFIRM',
              value: {
                code: this.confirmObj.event // this.confirmEvent
              }
            }
            this.$emit('send-event', event)
          }
        }).catch(() => {
          // on cancel
          const event = {
            type: 'TOAST_RESET'
          }
          this.$emit('send-event', event)
        })
      }
    }
  }
}

<template>
  <transition name="modal">
    <div class="modal-mask" :class="{ small: modalitaSmall, 'modal-right': !modaleLeft, hidden: modalitaNascosta }">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div class="row">
                <div class="c-left">
                  <div class="modal-title" v-if="!modalitaSmall">Gestione Appuntamenti</div>
                  <select v-else :value="currAgendaIndex" class="selectAgenda" @change="onSelectAgenda">
                    <option v-for="(p, index) in agende" :key="index" :value="index">{{ p.nome + ': ' + p.descrizione }}</option>
                  </select>
                </div>

                <div v-if="!modalitaSmall">
                  <button class="left tasto-giorni" @click="cambiaGiorno(-7)">-7gg</button>
                  <button class="left tasto-giorni" @click="cambiaGiorno(-1)">-1gg</button>
                  <span class="bg-white testo-data" @click="cambiaGiorno(0)">{{ giorno.format('DD/MM/YYYY (dddd)') }}</span>
                  <button class="right tasto-giorni" @click="cambiaGiorno(+7)">+7gg</button>
                  <button class="right tasto-giorni" @click="cambiaGiorno(+1)">+1gg</button>
                </div>
                <div v-else style="display:flex; flex-direction: column">
                  <div style="text-align: center; margin-bottom: 3px">
                    <span class="bg-white testo-data" @click="cambiaGiorno(0)">{{ giorno.format('DD/MM/YYYY (dddd)') }}</span>
                  </div>
                  <div>
                    <button class="left tasto-giorni" @click="cambiaGiorno(-7)">-7gg</button>
                    <button class="left tasto-giorni" @click="cambiaGiorno(-1)">-1gg</button>
                    <button class="right tasto-giorni" @click="cambiaGiorno(+7)">+7gg</button>
                    <button class="right tasto-giorni" @click="cambiaGiorno(+1)">+1gg</button>
                  </div>
                </div>

                <div class="c-right">
                  <button class="modal-default-button bg-white" @click="annulla()">CHIUDI</button>
                  <!-- <button class="modal-default-button" @click="showAzioni=true">Azioni</button> -->
                  <!-- <button class="modal-default-button" @click="riduciModale()">Riduci</button> -->
                  <button class="modal-default-button" @click="changeModalita(!modalitaSmall)">Small</button>
                  <button class="modal-default-button" v-if="modalitaSmall" @click="spostaModale()">Sposta</button>
                  <button class="modal-default-button" v-if="modalitaSelezione" @click="deselezionaApt()">DESELEZIONA</button>
                  <button class="modal-default-button" v-if="modalitaSelezione" @click="eliminaApt()">ELIMINA APT</button>
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div class="mainPlanning">
                <planning-slot v-for="(gr, index) in planning" :key="'p' + index"
                  :testo="gr.testo"
                  :apt="gr.apt"
                  :agenda="gr.agenda"
                  :orario="gr.orario"
                  :aspect="gr.aspect"
                  :zIndex="gr.zIndex"
                  :row="gr.row"
                  :row_span="gr.row_span"
                  :col_span="gr.col_span"
                  :col="gr.col"
                  :btnScorriSinistra="gr.btnScorriSinistra"
                  :btnScorriDestra="gr.btnScorriDestra"
                  :context="context"
                  :modalitaSelezione="modalitaSelezione"
                  :aptSelezionato="aptSelezionato"
                  @send-event="sendEvent"
                  @apri-appuntamento="apriAppuntamento"
                  @scorri-agenda="scorriAgenda"
                  @seleziona-apt="onSelezionaApt"
                  @sposta-apt="onSpostaApt"
                />
              </div>
              <van-popup v-model="showAzioni" position="right" round :style="{ height: '100%', width: '20%' }" :get-container="getContainer">
                <div class="popup-content">
                  <button class="" @click="creaAppuntamento">Nuovo Appuntamento</button>
                </div>
              </van-popup>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint-disable no-unmodified-loop-condition */
import moment from 'moment'
import PlanningSlot from './planningSlot'
import appuntamentiService from '@/services/appuntamentiService.js'
import configurazioni from '@/services/configurazioniService.js'

// https://vuetifyjs.com/en/components/calendars/#drag-and-drop
export default {
  props: {
    context: Object
  },
  components: {
    PlanningSlot
  },
  data() {
    return {
      agende: [], // elenco delle agende presenti
      agenda: [], // appuntamenti in agenda per il giorno selezionato
      planning: [], // calendario generale
      appuntamenti: [], // elenco appuntamenti da db
      giorno: moment(), // giorno selezionato
      slotContemporanei: 2, // posso mettere due clienti su uno slot se attesa/lavoro
      showAzioni: false,
      modalitaSmall: false,
      modalitaNascosta: false,
      modalitaSelezione: false,
      aptSelezionato: null,
      modaleLeft: true,
      currAgendaIndex: 0
    }
  },
  async mounted() {
    console.log(this.context)
    const plannings = await configurazioni.getConfigurazione('agende')
    this.agende = plannings ? plannings.agende : []
    // this.agende.push(this.agende[0])
    // this.agende.push(this.agende[1])

    var ev = { // aggiungo il riferimento a questa modale nel context
      type: 'EDIT_CONTEXT',
      payload: {
        modalePlanning: this
      }
    }
    this.$emit('send-event', ev)

    // Per considerare orari diversi in base ai giorni, aggiungere oggetto OrarioGiornaliero nelle agende : se presente considera giorni separati

    // per gli appuntamenti creo una casella che fa lo span su tutti gli orari + aggiungo i prodotti su div + colore di sfondo se lavoro o attesa
    // ------------------------ FINE impostazioni di debug ----------------------------------------------
    await this.refreshContent()
  },
  created() {
    this.addEventListener()
  },
  beforeDestroy() {
    this.removeEventListener()
  },
  methods: {
    annulla() {
      const event = {
        type: 'EDIT_CONTEXT',
        payload: {
          modalePlanning: null
        }
      }

      this.$emit('send-event', event)
      this.$emit('close')
    },
    getContainer() {
      return document.querySelector('.modal-body')
    },
    addEventListener: function () {
      this.$root.$on('refresh-appointment-content', async () => {
        await this.refreshContent()
      })
    },
    removeEventListener: function () {
      this.$root.$off('refresh-appointment-content')
    },
    async refreshContent() {
      await this.getElencoAppuntamenti(this.giorno)
      this.aggiornaAgende()
    },
    async getElencoAppuntamenti(data) {
      this.appuntamenti = await appuntamentiService.getAppuntamenti(data)
      // this.appuntamenti = result.docs
      // console.log(this.appuntamenti)
    },
    async cambiaGiorno(numGiorni) {
      if (numGiorni === 0) {
        this.giorno = moment()
      } else {
        this.giorno.add(numGiorni, 'days')
      }
      await this.refreshContent()
    },
    sendEvent(event) {
      this.$emit('send-event', event)
    },
    apriAppuntamento(objApt) {
      this.riduciModale()
      var ev = {
        type: 'EDIT_CONTEXT',
        payload: {
          nuovoAppuntamento: {
            agende: this.agende,
            dati: objApt,
            modalePlanning: this,
            modaleConfig: {
              apertoDaPlanning: true,
              showBtnSposta: true
            }
          }
        }
      }
      this.$emit('send-event', ev)

      const event = {
        type: 'APRI_MODALE',
        value: 'Appuntamento'
      }
      this.$emit('send-event', event)
    },
    changeModalita(small) {
      this.modalitaSmall = small || false
      if (!this.modalitaSmall && this.currAgendaIndex >= this.agende.length - 4) {
        this.currAgendaIndex = this.agende.length - 4
      }
      this.aggiornaAgende()
    },
    riduciModale() {
      this.modalitaNascosta = true
    },
    espandiModale() {
      this.modalitaNascosta = false
    },
    spostaModale() {
      this.modaleLeft = !this.modaleLeft
    },
    aggiornaAgende() {
      const that = this
      that.planning = []
      that.agenda = []

      for (var i = 0; i < that.agende.length; i++) {
        that.agende[i].orarioCalcolato = that.getOrariAgenda(that.agende[i]) || null
      }

      // const agendeOk = that.agende.filter(x => x.nome !== '-' && x.orarioDefault && x.orarioDefault.giorniSettimana.includes(moment(this.giorno).day()))
      // const agendeOk = that.agende.filter(x => x.orarioCalcolato && x.orarioCalcolato.orari && x.orarioCalcolato.orari.length) // quelli senza orari non sono disponibili in questo giorno

      // determinazione intervallo minimo e limiti dalle alle massimi -------
      let oraDalle = '23:59' // generico per Planning (tutte le agende)
      let oraAlle = '00:00'
      let intervalloMinuti = 0

      // agendeOk.forEach(el => {
      this.agende.forEach(el => { // le mostro tutte, se non ho orari in quella giornata allora le salto
        // Calcola il valore minimo e massimo su tutte le agende per il disegno del planning
        // Va ripetuto per ogni giorno perchè potrebbe essere diverso
        if (!el.orarioCalcolato) {
          return
        }
        var oraTest = el.orarioCalcolato.orari[0].dalle || oraDalle
        if (moment().startOf('day').add(oraTest).isBefore(moment().startOf('day').add(oraDalle))) {
          oraDalle = oraTest
        }

        oraTest = el.orarioCalcolato.orari[el.orarioCalcolato.orari.length - 1].alle || oraDalle
        if (moment().startOf('day').add(oraTest).isAfter(moment().startOf('day').add(oraAlle))) {
          oraAlle = oraTest
        }

        const intervalloTest = el.orarioCalcolato.intervalloMinuti
        intervalloMinuti = this.gcd(intervalloTest, intervalloMinuti)
        intervalloMinuti = 5

        /* OLD
        let oraTest = el.orarioDefault.oraDalle1 // gestire eventuali orari separati per giorno
        if (moment().startOf('day').add(oraTest).isBefore(moment().startOf('day').add(oraDalle))) {
          oraDalle = oraTest
        }
        oraTest = el.orarioDefault.oraAlle1
        if (moment().startOf('day').add(oraTest).isAfter(moment().startOf('day').add(oraAlle))) {
          oraAlle = oraTest
        }
        oraTest = el.orarioDefault.oraAlle2
        if (oraTest && moment().startOf('day').add(oraTest).isAfter(moment().startOf('day').add(oraAlle))) {
          oraAlle = oraTest
        }  */

        // posso calcolare il massimo comun divisore tra gli intervalli di tutte le agende o fissare 5 come minimo multiplo
        // inserire su impostazioni ?
        // const intervalloTest = el.orarioDefault.intervalloMinuti
        // intervalloMinuti = this.gcd(intervalloTest, intervalloMinuti)
        // intervalloMinuti = 5
      })
      const startD = moment(this.giorno).startOf('day').add(oraDalle)
      const stopD = moment(this.giorno).startOf('day').add(oraAlle)
      // Gestione intervalli non multipli
      // const numeroIntervalli = moment.duration(stopD.diff(startD)).as('minutes') / intervalloMinuti
      // const maxIntervalli = 36
      const passoIntervalli = 3 // Math.round(numeroIntervalli / maxIntervalli + 0.5)
      // ---------------------------------------------------------------------
      let col = 2

      var numAgendeDaMostrare = this.modalitaSmall ? 1 : 4
      var agendeDaMostrare = this.agende.slice(this.currAgendaIndex, this.currAgendaIndex + numAgendeDaMostrare)

      agendeDaMostrare.forEach(el => {
        // const orarioDefault = moment(this.giorno).startOf('day').add(el.orarioDefault.oraDalle1).format('DD/MM/YYYY HH:mm')
        const orarioDefault = el.orarioCalcolato ? moment(this.giorno).startOf('day').add(el.orarioCalcolato.orari[0].dalle).format('DD/MM/YYYY HH:mm') : startD.format('DD/MM/YYYY HH:mm')
        that.planning.push({ testo: `${el.nome}: ${el.descrizione || ''}`, agenda: el.nome, orario: orarioDefault, row: 1, col, col_span: that.slotContemporanei, aspect: 'intestazione' })
        col += that.slotContemporanei
      })

      that.planning[0].btnScorriSinistra = true
      that.planning[that.planning.length - 1].btnScorriDestra = true

      this.planning.push({ testo: 'Orario', row: 1, col: 1, aspect: 'orario' })
      // Verifica appuntamenti presenti
      var apts = this.appuntamenti // filtro lato server da db
      // console.log('apts', apts)
      const arIndentazione = []
      if (apts.length > 0) {
        apts.forEach(el => {
          const mmtInizio = moment(el.orarioInizio)
          const mmtFine = moment(el.orarioFine)
          const diffInizio = mmtInizio.diff(startD)
          const diffFine = mmtFine.diff(startD)
          const i = moment.duration(diffInizio).as('minutes') / intervalloMinuti
          const f = moment.duration(diffFine).as('minutes') / intervalloMinuti
          const attesa = el.attesa / intervalloMinuti
          that.agenda[el.agenda] = that.agenda[el.agenda] || []
          that.agenda[el.agenda][i] = that.agenda[el.agenda][i] || []
          arIndentazione[el.agenda] = arIndentazione[el.agenda] || []
          arIndentazione[el.agenda][i] = arIndentazione[el.agenda][i] || 0
          if (arIndentazione[el.agenda][i] > 3) {
            arIndentazione[el.agenda][i] = 0
          }
          const valoreInizio = arIndentazione[el.agenda][i] + 1
          for (let k = i; k < f; k++) {
            arIndentazione[el.agenda] = arIndentazione[el.agenda] || []
            arIndentazione[el.agenda][k] = arIndentazione[el.agenda][k] || []
            arIndentazione[el.agenda][k] = valoreInizio
          }
          that.agenda[el.agenda][i].push({ item: el, span: f - i, stato: 'occupato', lavoro: f - i - attesa, attesa, indentazione: arIndentazione[el.agenda][i], cliente: el.cliente, prodotti: el.prodotti })
        })
      }
      // Inizio costruzione Planner
      col = 2
      // agendeOk.forEach(el => {
      agendeDaMostrare.forEach(el => {
        // const oraDalle1 = el.orarioDefault.oraDalle1 // gestire eventuali orari separati per giorno
        // const oraAlle1 = el.orarioDefault.oraAlle1
        // const oraDalle2 = el.orarioDefault.oraDalle2
        // const oraAlle2 = el.orarioDefault.oraAlle2
        // const intervalloAgenda = el.orarioDefault.intervalloMinuti

        const oraDalle1 = el.orarioCalcolato ? el.orarioCalcolato.orari[0].dalle : startD
        const intervalloAgenda = el.orarioCalcolato ? el.orarioCalcolato.intervalloMinuti : intervalloMinuti

        const d = moment(this.giorno).startOf('day').add(oraDalle1)
        // const startD1 = moment(this.giorno).startOf('day').add(oraDalle1)
        // const stopD1 = moment(this.giorno).startOf('day').add(oraAlle1)
        // const startD2 = oraDalle2 ? moment(this.giorno).startOf('day').add(oraDalle2) : null
        // const stopD2 = oraAlle2 ? moment(this.giorno).startOf('day').add(oraAlle2) : null
        let slotOccupato = 0
        let zIndex = 0
        while (d.isSameOrAfter(startD) && d.isSameOrBefore(stopD)) {
          const i = moment.duration(d.diff(startD)).as('minutes') / intervalloMinuti
          const iAgenda = moment.duration(d.diff(startD)).as('minutes') / intervalloAgenda

          // const iAppuntamento = moment.duration(d.diff(startD)).as('minutes') / intervalloAgenda
          // Orario
          if (!that.planning.some(x => x.testo === d.format('HH:mm') && x.aspect === 'orario') && i % passoIntervalli === 0) {
            that.planning.push({ testo: d.format('HH:mm'), orario: d.format('DD/MM/YYYY HH:mm'), row: 2 + i, row_span: passoIntervalli, col: 1, aspect: 'orario' })
          }
          // Agende
          that.agenda[el.nome] = that.agenda[el.nome] || []
          // if ((d.isSameOrAfter(startD1) && d.isSameOrBefore(stopD1)) || (startD2 && d.isSameOrAfter(startD2) && d.isSameOrBefore(stopD2))) {
          if (!el.orarioCalcolato || that.isOrarioCompreso(d, el.orarioCalcolato.orari || [])) {
            if (that.agenda[el.nome][i] && that.agenda[el.nome][i].length > 0) {
              const len = that.agenda[el.nome][i].length
              if (len === 1) {
                const apt = that.agenda[el.nome][i][0]
                /*                 const aspect = 'planning ' + apt.stato + (slotOccupato > 0 ? '-2' : '')
                that.planning.push({ apt, agenda: el.nome, orario: d.format('DD/MM/YYYY HH:mm'), row: 2 + i, row_span: apt.span, col: col, col_span: 2, aspect }) */

                slotOccupato = apt.span
                // const aspect = 'planning ' + apt.stato + (slotOccupato > 0 ? '-' + String(apt.indentazione) : '')
                const aspect = 'planning ' + apt.stato + '-' + String(apt.indentazione)
                that.planning.push({ apt, agenda: el.nome, orario: d.format('DD/MM/YYYY HH:mm'), row: 2 + i, row_span: apt.span, col: col, col_span: 2, aspect, zIndex: zIndex++ })
              } else {
                let apt = that.agenda[el.nome][i][0]
                const nth = slotOccupato > 0 ? 2 + 1 : 2 // seconda o terza posizione
                const aspect1 = 'planning ' + apt.stato + (slotOccupato > 0 ? '-' + String(nth) : '')
                const aspect2 = 'planning ' + apt.stato + (slotOccupato > 0 ? '-' + String(nth + 1) : '')
                // const aspect = 'planning ' + apt.stato + (slotOccupato > 0 ? '-2' : '')
                that.planning.push({ apt, agenda: el.nome, orario: d.format('DD/MM/YYYY HH:mm'), row: 2 + i, row_span: apt.span, col: col, col_span: 1, aspect: aspect1 })
                slotOccupato = apt.span
                apt = that.agenda[el.nome][i][1] // attenzione ammetto solo 2 appuntmaneti sullo stesso slot (contemporanei su lavoro). Se sono più va gestito il ciclo
                // if (usaSeparazioneAttesaLavoro) {
                that.planning.push({ apt, agenda: el.nome, orario: d.format('DD/MM/YYYY HH:mm'), row: 2 + i, row_span: apt.span, col: col + 1, col_span: 1, aspect: aspect2 })
                /*                 } else {
                  const nth = slotOccupato > 0 ? 2 + 1 : 2 // seconda o terza posizione
                  aspect = 'planning-' + apt.stato + (slotOccupato > 0 ? '-' + String(nth) : '')
                  that.planning.push({ apt, agenda: el.nome, orario: d.format('DD/MM/YYYY HH:mm'), row: 2 + i, row_span: apt.span, col: col, col_span: 1, aspect: 'planning ' + apt.stato })
                } */
                if (apt.span > slotOccupato) {
                  slotOccupato = apt.span
                }
              }
              /*               that.agenda[el.nome][i].forEach(apt => {
                that.planning.push({ apt, row: 2 + i, row_span: apt.span, col: col + (apt.stato === 'attesa' ? 1 : 0), aspect: 'planning-' + apt.stato })
                slotOccupato = apt.span
              }) */
            } else {
            /*             if (that.agenda[el.nome][i]) {
              const apt = that.agenda[el.nome][i]
              that.planning.push({ apt, row: 2 + i, row_span: apt.span, col: col + (apt.stato === 'attesa' ? 1 : 0), aspect: 'planning-' + apt.stato })
              slotOccupato = apt.span
            } else { */
              // const dummy = `${el.nome}: ${i + 1}`
              slotOccupato--
              if (iAgenda === Math.round(iAgenda) && slotOccupato <= 0) {
                that.planning.push({ testo: '', agenda: el.nome, orario: d.format('DD/MM/YYYY HH:mm'), row: 2 + i, col: col, col_span: that.slotContemporanei, row_span: intervalloAgenda / intervalloMinuti, aspect: 'planning-libero' })
              }
            }
          } else {
            // riempe eventuali spazi vuoti --> provare con due agende uguali con la attesa
            // this.planning.push({ testo: '---', row: 2 + i, col: 1, aspect: 'orario' })
          }
          // ciclo
          d.add(intervalloMinuti, 'm')
        }
        col += that.slotContemporanei
      })
    },
    gcd(a, b) { // Massimo Comun Divisore
      if (b) {
        return this.gcd(b, a % b)
      } else {
        return Math.abs(a)
      }
    },
    getOrariAgenda(datiAgenda) { // ritorna gli intervalli di orari per il giorno selezionato
      // cercare prima data fissa, poi date ricorrenti di ogni anno ( giorno/mese ), poi giorni del mese ( intervalli, es 1-15), poi della settimana
      // se non c'è nulla, allora verificare negli oraridefault, prima nei singoli giorni e poi, se non c'è, nel default della settimana
      var oGiornaliero = datiAgenda.orarioGiornaliero || null
      var i
      if (oGiornaliero) {
        if (oGiornaliero.dateFisse) { // date fisse
          for (i = 0; i < oGiornaliero.dateFisse.length; i++) {
            if (oGiornaliero.dateFisse[i].giorno === this.giorno.format('DD/MM/YYYY')) {
              return oGiornaliero.dateFisse[i]
            }
          }
        }

        if (oGiornaliero.giorniRicorrenti) { // giorno e mese ricorrente
          for (i = 0; i < oGiornaliero.giorniRicorrenti.length; i++) {
            if (oGiornaliero.giorniRicorrenti[i].giorno === this.giorno.date() && oGiornaliero.giorniRicorrenti[i].mese === this.giorno.month() + 1) {
              return oGiornaliero.giorniRicorrenti[i]
            }
          }
        }

        if (oGiornaliero.giorniDelMese) { // giorno di ogni mese ( negativo o 0 per partire dal fondo )
          for (i = 0; i < oGiornaliero.giorniDelMese.length; i++) {
            if ((oGiornaliero.giorniDelMese[i].giorno > 0 && this.giorno.date() === oGiornaliero.giorniDelMese[i].giorno) ||
                 (oGiornaliero.giorniDelMese[i].giorno <= 0 && this.giorno.date() === moment(this.giorno).endOf('month').add(oGiornaliero.giorniDelMese[i].giorno, 'days').date())) {
              return oGiornaliero.giorniDelMese[i]
            }
          }
        }

        if (oGiornaliero.giorniDellaSettimana) { // giorno di ogni settimana ( negativo o 0 per partire dal fondo )
          for (i = 0; i < oGiornaliero.giorniDellaSettimana.length; i++) {
            if (oGiornaliero.giorniDellaSettimana[i].giorno === this.giorno.day()) {
              return oGiornaliero.giorniDellaSettimana[i]
            }
          }
        }
      }

      if (datiAgenda.orarioDefault.giorniSettimana.includes(moment(this.giorno).day())) {
        if (datiAgenda.orarioDefault.orari) { // nuova versione
          return datiAgenda.orarioDefault
        } else {
          var newOrari = [] // se vecchia versione ( con oraDalle1 oraAlle1 etc, allora creo un nuovo oggetto)
          newOrari.push({ dalle: datiAgenda.orarioDefault.oraDalle1, alle: datiAgenda.orarioDefault.oraAlle1 })
          datiAgenda.orarioDefault.oraDalle2 && newOrari.push({ dalle: datiAgenda.orarioDefault.oraDalle2, alle: datiAgenda.orarioDefault.oraAlle2 })
          return {
            giorniSettimana: datiAgenda.orarioDefault.giorniSettimana,
            intervalloMinuti: datiAgenda.orarioDefault.intervalloMinuti,
            orari: newOrari
          }
        }
      }

      return null
    },
    isOrarioCompreso(orarioCheck, listaOrari) {
      for (var i = 0; i < listaOrari.length; i++) {
        if (orarioCheck.isSameOrAfter(moment(this.giorno).startOf('day').add(listaOrari[i].dalle)) && orarioCheck.isSameOrBefore(moment(this.giorno).startOf('day').add(listaOrari[i].alle))) {
          return true
        }
      }
      return false
    },
    creaAppuntamento() {
      this.showAzioni = false
      const appuntamento = {
        orarioInizio: moment(this.giorno, 'DD/MM/YYYY HH:mm').toISOString()
      }
      this.$store.commit('appuntamenti/SET_APPUNTAMENTO', { payload: appuntamento, nuovo: true })
      /*       let event = {
        type: 'SELEZIONA_APPUNTAMENTO',
        value: appuntamento
      }
      this.sendEvent(event) */
      /*       event = {
        type: 'APRI_MODALE',
        value: 'Appuntamento'
      } */
      const event = {
        type: 'APRI_POPUP',
        value: 'Appuntamento'
      }
      this.sendEvent(event)
    },
    scorriAgenda(count) {
      var nextIndex = this.currAgendaIndex + count
      if (nextIndex < 0) {
        return
      }

      var agendeCount = this.modalitaSmall ? 1 : 4
      if (nextIndex > this.agende.length - agendeCount) {
        nextIndex = this.agende.length - agendeCount
      }

      if (nextIndex !== this.currAgendaIndex) {
        this.currAgendaIndex = nextIndex
        this.aggiornaAgende()
      }
    },
    onSelectAgenda(e) {
      var index = parseInt(e.target.value)
      this.currAgendaIndex = index
      this.aggiornaAgende()
    },
    onSelezionaApt(apt) {
      if (this.modalitaSelezione && this.aptSelezionato === apt) {
        this.deselezionaApt()
        return
      }
      this.modalitaSelezione = true
      this.aptSelezionato = apt
    },
    deselezionaApt() {
      this.aptSelezionato = null
      this.modalitaSelezione = false
    },
    async onSpostaApt(data) {
      try {
        var apt = this.aptSelezionato.item
        var dataApt = moment(data.orario, 'DD/MM/YYYY HH:mm')
        await this.$dialog.confirm({ title: 'Sposta appuntamento', message: `Spostare l'appuntamento per il giorno ${dataApt.format('DD/MM/YYYY')} alle ${dataApt.format('HH:mm')}?` })

        apt.agenda = data.agenda
        apt.data = dataApt.format('DD/MM/YYYY')
        apt.orarioInizio = moment(data.orario, 'DD/MM/YYYY HH : mm').toISOString()
        apt.orarioFine = moment(apt.orarioInizio).add(apt.durata, 'minutes').toISOString()

        await appuntamentiService.modificaAppuntamento(apt._id, apt)
        this.$toast('Appuntamento spostato', { type: 'success' })
        this.refreshContent()
        this.deselezionaApt()
      } catch {
      }
    },
    async eliminaApt() {
      try {
        await this.$dialog.confirm({ title: 'Elimina appuntamento', message: 'Confermi di voler eliminare questo appuntamento?' })
        await appuntamentiService.cancellaAppuntamento(this.aptSelezionato.item._id)
        this.$toast('Appuntamento eliminato', { type: 'success' })
        this.deselezionaApt()
        this.refreshContent()
      } catch {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../modale.scss";
  @import "./style.scss";
  @import "./layout.scss";

  .c-left {
    justify-self: left;
  }
  .c-right {
    justify-self: right;
  }
  .popup-content {
    padding: 10px;
    & button {
      background: #eeeeee;
      padding: 6px 16px;
      margin-left: 10px;
      font-size: 11px;
      text-transform: uppercase;
      outline: none;
      box-shadow: 0 0 2px 1px rgb(0 0 0 / 20%);
      cursor: pointer;
    }
  }

  .modal-mask.small {
    width: 40%;
    & .modal-body {
      margin: 15px 0;
    }
  }

  .modal-mask.modal-right.small {
    margin-left: 60vw;
  }

  .modal-mask.hidden {
    display: none;
  }

  .selectAgenda {
    width: 184px;
    height: 5vh;
    white-space: break-spaces;
    background: #eeeeee;
    font-size: 13px;
    border-color: #cccccc;
    border-radius: 6px;
    padding: 5px;
  }

</style>

'use strict'

var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_cashmatic || process.env.VUE_APP_service_cashmatic
})()

async function avviaTransazione(importo, causale, riferimento) {
  const data = {
    importo,
    causale,
    riferimento
  }
  const response = await fetch(`${service}/cashmatic/transazione/avvia`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function annullaTransazione() {
  const response = await fetch(`${service}/cashmatic/transazione/annulla`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function concludiTransazione() {
  const response = await fetch(`${service}/cashmatic/transazione/concludi`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function statoTransazione() {
  const response = await fetch(`${service}/cashmatic/transazione/stato`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function infoUltimaTransazione() {
  const response = await fetch(`${service}/cashmatic/transazione/ultima`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

// TODO: Inserire gestione cashmatic multiple
async function infoDispositivo() {
  const response = await fetch(`${service}/cashmatic/dispositivo/info`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function avviaPrelievo(importo, causale, riferimento) {
  const data = {
    importo,
    causale,
    riferimento
  }
  const response = await fetch(`${service}/cashmatic/transazione/prelievo`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

export default {
  avviaTransazione,
  annullaTransazione,
  concludiTransazione,
  statoTransazione,
  infoUltimaTransazione,
  infoDispositivo,
  avviaPrelievo
}

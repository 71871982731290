<template>
  <van-cell-group>
    <generale :context="context" @send-event="sendEventToParent" ref="generale"/>
    <prepagata :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="prepagata"/>
    <!-- <abbonamenti :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="abbonamenti"/> -->
    <raccolta-punti :context="context" @send-event="sendEventToParent" @close-popup="closePopup" @forza-salvataggio="salvaModifiche" ref="raccoltaPunti"/>
    <van-button type="primary" block @click="salvaModifiche">Salva le modifiche</van-button>
   </van-cell-group>
</template>

<script>
import _ from 'lodash'
import Generale from './elementiModifica/generale'
import Prepagata from './elementiModifica/prepagata'
/* import Abbonamenti from './elementiModifica/abbonamenti' */
import RaccoltaPunti from './elementiModifica/raccoltaPunti'
/*
import RaccoltaPunti from './elementiModifica/raccoltaPunti'
import AccumuloCredito from './elementiModifica/accumuloCredito'
import Abbonamenti from './elementiModifica/abbonamenti' */

export default {
  components: {
    Generale,
    Prepagata,
    /* Abbonamenti, */
    RaccoltaPunti
    /*,
    RaccoltaPunti,
    AccumuloCredito,
    Abbonamenti */
  },
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
      moduli: ['generale', 'prepagata', /* 'abbonamenti', */ 'raccoltaPunti']
    }
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    }
  },
  watch: {
    'context.fidelityCard'() {
      this.onOpen()
    }
  },
  methods: {
    salvaModifiche() {
      const data = {}
      let azzeramento = null
      const comandi = {}
      this.moduli.forEach(modulo => {
        const refM = this.$refs[modulo]
        const modData = refM.getValori()
        if (modData.data) {
          _.merge(data, modData.data)
        }
        if (modData.azzeramento) {
          azzeramento = modData.azzeramento
        }
        if (modData.comandi) {
          _.merge(comandi, modData.comandi)
        }
      })
      const event = {
        type: 'AGGIORNA_FIDELITY_CARD',
        value: {
          data,
          comandi,
          azzeramento
        }
      }
      this.$emit('send-event', event)
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    },
    closePopup() {
      this.$emit('close-popup')
    },
    onOpen() {
      this.moduli.forEach(modulo => {
        const refM = this.$refs[modulo]
        if (refM.onOpen) {
          refM.onOpen()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }

</style>

<template>
  <van-cell-group>
    <van-cell-group title="Generale">
      <van-cell title="Ricarica pagina" is-link @click="ricaricaPagina"/>
      <van-cell title="Import dati (generico)" is-link @click="importDati"/>
    </van-cell-group>
    <van-cell-group title="Stampante">
      <van-cell title="Ristampa scontrino" is-link @click="ristampaScontrino"/>
      <van-cell title="Lettura giornaliera" is-link @click="letturaGiornaliera"/>
      <van-cell title="Chiusura giornaliera" is-link @click="chiusuraGiornaliera"/>
      <van-cell title="Stato stampante" is-link @click="statoStampante"/>
      <van-field v-model="comandoStampante" center clearable label="Invia comando stampante" placeholder="Comando da inviare">
        <template #button>
          <van-button size="small" type="primary" @click="inviaComandoStampante">Invia</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <van-cell-group title="Custom Benetton" v-if="isBenettonVisible">
      <van-cell title="Importa ordini" is-link @click="importaOrdini"/>
      <van-cell title="Importa packing" is-link @click="importaPacking"/>
      <van-cell title="Genera categorie" is-link @click="generaCategorie"/>
      <van-cell title="Importa negozi" is-link @click="importaNegozi"/>
    </van-cell-group>
  </van-cell-group>
</template>

<script>
import { customBenetton } from './custom_benetton_mixin'
import { confermaOperazione } from './conferma_operazione_mixin'

export default {
  mixins: [customBenetton, confermaOperazione],
  props: {
    context: Object
  },
  data() {
    return {
      comandoStampante: ''
    }
  },
  computed: {
    isBenettonVisible() { // TODO: rendere più generico
      return this.context.impostazioni.custom.benetton
    }
  },
  methods: {
    /*
      Lettura DGFE da data a data, solo documenti commerciali / scontrini
      =C460/$1/&010120/[100120 Stampa documenti commerciali da data (/&ddmmyy) a data (/[ddmmyy)
    */
    async ristampaScontrino() {
      if (await this.confermaOperazione('Ristampa scontrino', 'Confermi ristampa fiscale ?')) {
        this.sendToMachine('PRINTER', { commands: ['=C453/$1'] })
      }
    },
    async letturaGiornaliera() {
      if (await this.confermaOperazione('Lettura giornaliera', 'Confermi stampa lettura giornaliera ?')) {
        this.sendToMachine('PRINTER', { commands: ['=C2', '=C10', '=C1'] })
      }
    },
    async chiusuraGiornaliera() {
      if (await this.confermaOperazione('Chiusura giornaliera', 'Confermi esecuzione chiusura fiscale ?')) {
        this.sendToMachine('PRINTER', { commands: ['=C3', '=C10', '=C1'] })
      }
    },
    statoStampante() {
      this.sendToMachine('PRINTER', { commands: ['<</?i/*4'] })
    },
    inviaComandoStampante() {
      this.sendToMachine('PRINTER', { commands: [this.comandoStampante] })
    },
    ricaricaPagina() {
      this.$router.go(0)
    },
    importDati() {
      this.$router.push('/import')
    },
    sendToMachine(actionType, payload) {
      const event = {
        type: actionType,
        value: payload
      }
      this.$emit('send-event', event)
    }
    /*     async confermaOperazione(title, message) {
      try {
        await this.$dialog.confirm({ title, message, cancelButtonText: 'NO', confirmButtonText: 'SI' })
        return true
      } catch {
        return false
      }
    } */
  }
}
</script>

<template>
  <van-cell-group>
    <!--     <van-cell center title="Generale" >
      <template #right-icon>
        <van-switch :value="isGenerale" size="24" @change="switchChange"/>
      </template>
    </van-cell> -->
    <van-cell-group title="Generale">
      <!--     <van-cell title="Codice cliente" :value="codiceCliente"/>
      <van-cell title="Ragione sociale" :value="ragioneSociale"/> -->
      <van-field
        readonly
        clickable
        name="listino"
        :value="listinoAssociato"
        label="Listino"
        placeholder="listino associato"
        input-align="right"
        @click="showListinoPicker = true"
      />
      <van-popup v-model="showListinoPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="descListini"
          @confirm="onConfirm"
          @cancel="showListinoPicker = false"
          @change="onChange"
        />
      </van-popup>

      <van-cell @touchstart.native.stop="showTastiera = true" title="Sconto %">
        <van-field :value="sconto" size="large" placeholder="Sconto" readonly input-align="right">
        </van-field>
      </van-cell>
      <van-number-keyboard
        v-model="sconto"
        :show="showTastiera"
        theme="custom"
        extra-key="00"
        close-button-text="OK"
        @blur="showTastiera = false"
      />
      <van-field
        readonly
        clickable
        :value="tipoCliente"
        name="tipoCliente"
        ref="tipoCliente"
        label="Tipologia cliente"
        input-align="right"
        placeholder="Tipologia cliente"
        @click="showTipoCliente = true"
      />
      <van-popup v-model="showTipoCliente" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar title="Tipologia cliente" :columns="columnsTipoCliente" @confirm="onConfirmTipoCliente" @cancel="showTipoCliente = false"/>
      </van-popup>
    </van-cell-group>
  </van-cell-group>
</template>

<script>
import configurazioni from '@/services/configurazioniService.js'
import { testCardType } from '../cardTypes'

export default {
  mixins: [testCardType],
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
      listini: [],
      showListinoPicker: false,
      showTastiera: false,
      listino: '',
      sconto: '',
      showTipoCliente: false,
      tipiCliente: [],
      tipoCliente: ''
    }
  },
  async mounted() {
    // var result = await configurazioni.getConfigurazione('listini', 'listini')
    this.listini = await configurazioni.getConfigurazione('listini', 'listini') // result.listini || []
    this.tipiCliente = await configurazioni.getConfigurazione('fidelity_tipiCliente', 'tipiCliente')
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    generale() {
      return this.card.generale || {}
    },
    listinoAssociato() {
      const codListino = this.listino
      if (codListino && this.listini.length > 0) {
        const descListino = this.listini.find(x => x.codice === codListino).descrizione
        return codListino + ': ' + descListino
      } else {
        return ''
      }
    },
    codiceCliente() {
      return this.card.cliente ? this.card.cliente.codice || '' : ''
    },
    ragioneSociale() {
      return this.card.cliente ? this.card.cliente.ragioneSociale || '' : ''
    },
    descListini() {
      return this.listini.map(x => x.descrizione)
    },
    columnsTipoCliente() {
      const v = this.tipiCliente || []
      return v.sort()
      // return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    }
  },
  methods: {
    getStilePopup() {
      return 'height: "50%";'
    },
    onChange(picker, value, index) {
      this.onConfirm(value, index)
    },
    onConfirm(value, index) {
      if (this.listini.length === 0) {
        return
      }
      this.listino = this.listini[index].codice
      this.showListinoPicker = false
    },
    onOpen() {
      this.listino = this.generale && this.generale.listino ? this.generale.listino : ''
      this.sconto = this.generale && this.generale.sconto ? String(this.generale.sconto) : ''
      this.tipoCliente = (this.card && this.card.tipoCliente) || ''
    },
    getValori() {
      return {
        data: {
          generale: {
            listino: this.listino,
            sconto: this.sconto
          }
        }
      }
    },
    onConfirmTipoCliente(value) {
      this.showTipoCliente = false
      this.tipoCliente = value
    }
  }
}
</script>

<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" @salva="salva" :campi="campi" :config="config"></modale-form>
</template>

<script>
import _ from 'lodash'
import ModaleForm from '../ModaleForm'
import clienti from '@/services/clientiService.js'
import configurazioni from '@/services/configurazioniService.js'
import { cloneObject } from '@/components/helpers'
import appuntamenti from '@/services/appuntamentiService.js'
import moment from 'moment'
// import _ from 'lodash'
// import * as dot from 'dot-object'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      appuntamentoInModifica: null,
      appuntamentoPagato: false,
      campi: [],
      config: {
        disableEsciOnClick: true,
        clickableBackground: true,
        showBtnSposta: true,
        appuntamentoDefault: {
          agenda: 'Agenda_2',
          attesa: 40,
          cliente: { codice: '', ragioneSociale: '' },
          data: '04/08/2022',
          durata: 100,
          operatore: '',
          orarioFine: '2022-08-04T09:40:00.000Z',
          orarioInizio: '2022-08-04T08:00:00.000Z',
          prodotti: [
            { codice: 'taglio01', descrizione: 'Taglio Femminile', quantita: 1, servizio: { attesa: 20, durata: 50 } }
          ],
          stato: 'aperto',
          statoPagamento: 'dapagare'
        }
      },
      durataDefault: 15 // TODO: Leggere da impostazioni
    }
  },
  async mounted() {
    var datiApt = this.context.nuovoAppuntamento.dati.appuntamento
    // console.log(this.context.nuovoAppuntamento)

    var elencoClienti = await clienti.searchClienti('')
    elencoClienti = elencoClienti && elencoClienti.docs ? elencoClienti.docs.map(obj => { return { codice: obj.codice, descrizione: obj.ragioneSociale } }) : []
    var clienteSelezionato = this.context.cliente ? this.context.cliente.codice : null
    this.elencoClienti = elencoClienti

    var elencoAgende = this.context.nuovoAppuntamento.agende || []
    elencoAgende = elencoAgende.map(obj => { return { codice: obj.nome, descrizione: obj.nome + ': ' + obj.descrizione } })
    var agendaSelezionata = datiApt.agenda || null

    // var elencoOperatori = await configurazioni.getConfigurazione('operatori', 'planning') || {}
    var elencoOperatori = await configurazioni.getConfigurazione('operatori') || []
    elencoOperatori = elencoOperatori.filter(obj => obj.planning).map(obj => { return { codice: obj.codice, descrizione: obj.nome } })
    this.elencoOperatori = elencoOperatori
    var operatoreSelezionato = (this.context.operatore && this.context.operatore.servizio) || null // -> vedere come prendere i dati degli operatori
    operatoreSelezionato = operatoreSelezionato ? operatoreSelezionato.codice : '' // ATTENZIONE IL CODICE DELL OPERATORE SELEZIONATO E' OP_002, MENTRE L ELENCO DEGLI OPERATORI HANNO CODICE CHE INIZIA CON UT

    var dataApt = new Date()
    if (this.context.nuovoAppuntamento.dati) {
      dataApt = moment(datiApt.data, 'DD/MM/YYYY').toDate()
    }
    // console.log(this.context.nuovoAppuntamento.dati)

    var orarioInizio = '09:00'

    if (this.context.nuovoAppuntamento.dati) {
      orarioInizio = moment(datiApt.orarioInizio)
      orarioInizio = orarioInizio.format('HH:mm')
    }
    // console.log(orarioInizio)

    var righeCarrello = (this.context.righeCarrello || []).filter(obj => { return obj.servizio })
    righeCarrello = righeCarrello.map(obj => { return { codice: obj.codice, descrizione: obj.descrizione, durata: obj.servizio.durata || this.durataDefault, attesa: obj.servizio.attesa || 0, operatore: operatoreSelezionato } })

    if (!this.context.nuovoAppuntamento.dati.nuovo) {
      this.appuntamentoInModifica = datiApt
      this.appuntamentoPagato = datiApt && datiApt.statoPagamento === 'pagato'
      clienteSelezionato = datiApt.cliente ? datiApt.cliente.codice || clienteSelezionato : clienteSelezionato
      operatoreSelezionato = datiApt.operatore || operatoreSelezionato
      righeCarrello = []
      for (let i = 0; i < datiApt.prodotti.length; i++) {
        const p = datiApt.prodotti[i]
        righeCarrello.push({ codice: p.codice, descrizione: p.descrizione, durata: p.servizio.durata || this.durataDefault, attesa: p.servizio.attesa || 0, operatore: p.operatore || operatoreSelezionato })
      }
    }

    this.campi = [
      { campo: 'btnElimina', tipo: 'button', label: 'Elimina Appuntamento', classe: 'btnDelete', onUpdateValue: this.onEliminaAppuntamento, hidden: !this.appuntamentoInModifica },
      { campo: 'cliente', model: 'cliente', tipo: 'select', label: 'Cliente', dataSource: elencoClienti, placeHolder: 'Digitare per filtrare..', saveFormat: 'codice', validations: [{ nome: 'required' }], valore: clienteSelezionato },
      { campo: 'agenda', model: 'agenda', tipo: 'select', label: 'Agenda', dataSource: elencoAgende, placeHolder: 'Digitare per filtrare..', saveFormat: 'codice', validations: [{ nome: 'required' }], valore: agendaSelezionata },
      { campo: 'operatore', model: 'operatore', tipo: 'select', label: 'Operatore', dataSource: elencoOperatori, placeHolder: 'Digitare per filtrare..', saveFormat: 'codice', validations: [{ nome: 'required' }], valore: operatoreSelezionato, onUpdateValue: this.onUpdateOperatore },
      { campo: 'dataAppuntamento', model: 'dataAppuntamento', tipo: 'calendar', label: 'Data', placeHolder: 'GG / MM / AAAA', validations: [{ nome: 'required' }], valore: dataApt },
      { campo: 'orario', model: 'orario', tipo: 'timePicker', label: 'Orario', placeHolder: 'HH : mm', validations: [{ nome: 'required' }, { nome: 'intervallo', minuti: 15 }], onUpdateValue: this.onUpdateOrario, valore: orarioInizio },
      { campo: 'prodotti', model: 'prodotti', tipo: 'serviziAgenda', label: 'Servizi Selezionati', placeHolder: '', dataSource: elencoOperatori, valore: righeCarrello, onUpdateValue: this.onUpdateServizi, validations: [{ nome: 'required', msgErrore: 'Inserire almeno un servizio' }] },
      // { campo: 'prodotti', model: 'prodotti', tipo: 'serviziAgenda', label: 'Servizi Selezionati', placeHolder: '', dataSource: elencoOperatori, valore: righeCarrello, onUpdateValue: this.onUpdateServizi },
      { campo: 'btnPaga', tipo: 'button', label: 'Vai al Pagamento', classe: 'vaiAlPagamento', onUpdateValue: this.pagaAppuntamento, hidden: !this.appuntamentoInModifica || this.appuntamentoPagato }
    ]

    var ev = { // aggiungo il riferimento a questa modale nel context
      type: 'EDIT_CONTEXT',
      reference: {
        source: 'modaleAppuntamento',
        dest: 'nuovoAppuntamento.modaleAppuntamento'
      },
      payload: {
        modaleAppuntamento: this
      }
    }
    this.$emit('send-event', ev)
  },
  computed: {
    titoloModale() {
      if (this.appuntamentoPagato) {
        return 'Appuntamento chiuso'
      }
      return this.appuntamentoInModifica ? 'Modifica appuntamento:' : 'Nuovo appuntamento'
    }
  },
  methods: {
    onKeyPress(button) {
      this.button = button
      if (!this.insertNuovoCliente && !this.modificaDatiCliente && button === '{esci}') {
        // this.seleziona()
      }
    },
    annulla(chiudiPlanning) {
      var modPlanning = this.context.nuovoAppuntamento ? this.context.nuovoAppuntamento.modalePlanning || null : null
      const event = {
        type: 'EDIT_CONTEXT',
        payload: {
          nuovoAppuntamento: null
        }
      }

      this.$emit('send-event', event)
      this.$emit('close')

      if (modPlanning) {
        if (chiudiPlanning) {
          modPlanning.annulla()
        } else {
          modPlanning.espandiModale()
        }
      }
    },
    async salva() {
      var form = this.$refs.form
      var err = form.valida()
      if (err && err.length) {
        return
      }
      var dati = form.getDatiDotNotation()
      // TODO: Verificare come gestire. Non posso mettere un reparto altrimenti si blocca nella cassa al momento del pagamento
      /*       if (dati.prodotti.length === 0) {
        dati.prodotti.push(this.getServizioDefault())
      } */
      var nuovoApt = this.buildAppuntamento(dati)
      if (this.appuntamentoInModifica) { // modifica appuntamento
        await appuntamenti.modificaAppuntamento(nuovoApt._id, nuovoApt)
        this.$toast('Appuntamento modificato con successo', { type: 'success' })
      } else { // nuovo cliente
        await appuntamenti.creaAppuntamento(nuovoApt)
        this.$toast('Appuntamento creato con successo', { type: 'success' })
      }
      if (this.context.nuovoAppuntamento && this.context.nuovoAppuntamento.modalePlanning) {
        this.context.nuovoAppuntamento.modalePlanning.refreshContent()
      }
      var self = this
      setTimeout(() => {
        self.annulla()
      }, 200)
    },
    async pagaAppuntamento() {
      var form = this.$refs.form
      var err = form.valida()
      if (err && err.length) {
        return
      }
      var dati = form.getDatiDotNotation()

      // Verificare se passare per lo store
      var apt = this.buildAppuntamento(dati)
      // appuntamento.pagamentoInCorso = true --> messo nella macchina a stati
      const cliente = await clienti.getCliente(dati.cliente)
      const payload = {
        appuntamento: apt,
        cliente
      }
      const event = {
        type: 'PAGAMENTO_APPUNTAMENTO',
        value: payload
      }

      this.$emit('send-event', event)

      var self = this
      setTimeout(() => {
        self.annulla(true)
      }, 200)
    },
    async onEliminaAppuntamento() {
      try {
        await this.$dialog.confirm({ title: 'Elimina appuntamento', message: 'Confermi di voler eliminare questo appuntamento?' })
        // dopo aver eliminato aggiorna planning ed esci
        await appuntamenti.cancellaAppuntamento(this.appuntamentoInModifica._id)
        this.$toast('Appuntamento eliminato', { type: 'success' })
        if (this.context.nuovoAppuntamento && this.context.nuovoAppuntamento.modalePlanning) {
          this.context.nuovoAppuntamento.modalePlanning.refreshContent()
        }
        this.annulla()
      } catch {
      }
    },
    buildAppuntamento(dati) {
      var attesa = 0
      var durata = 0
      var prodotti = []
      var elClienti = this.elencoClienti
      var cliente = elClienti.map(obj => { return { codice: obj.codice, ragioneSociale: obj.descrizione } }).find(obj => { return obj.codice === dati.cliente })
      for (let i = 0; i < dati.prodotti.length; i++) {
        var p = dati.prodotti[i]
        prodotti.push({ codice: p.codice, descrizione: p.descrizione, quantita: 1, operatore: p.operatore, servizio: { attesa: p.attesa || 0, durata: p.durata || this.durataDefault } })
        attesa += parseInt(dati.prodotti[i].attesa || 0)
        durata += parseInt(dati.prodotti[i].durata || this.durataDefault)
      }

      var nuovoApt = this.appuntamentoInModifica ? cloneObject(this.appuntamentoInModifica) : cloneObject(this.config.appuntamentoDefault)

      nuovoApt.agenda = dati.agenda
      nuovoApt.operatore = dati.operatore
      nuovoApt.data = moment(dati.dataAppuntamento).format('DD/MM/YYYY')
      nuovoApt.attesa = attesa
      nuovoApt.durata = durata
      nuovoApt.orarioInizio = moment(nuovoApt.data + ' ' + dati.orario, 'DD/MM/YYYY HH : mm').toISOString()
      // nuovoApt.orarioInizio = moment(nuovoApt.data + ' ' + dati.orario, 'DD/MM/YYYY HH : mm').format('YYYY-MM-DDTHH:mm') + ':00.000Z'
      nuovoApt.orarioFine = moment(nuovoApt.data + ' ' + dati.orario, 'DD/MM/YYYY HH : mm').add(durata, 'minutes').toISOString()
      nuovoApt.prodotti = prodotti
      nuovoApt.cliente = cliente

      return nuovoApt
    },
    onUpdateOperatore(value) {
      if (!value) {
        return
      }
      var op = this.elencoOperatori.find(obj => obj.codice === value)
      var form = this.$refs.form
      var comp = form.getComponentByName('prodotti')
      comp && comp.callMethod('updateOperatore', op)
    },
    onUpdateServizi(value) {
      var form = this.$refs.form
      var comp = form.getComponentByName('orario')
      if (comp && comp.callMethod('isOrarioValido')) {
        this.onUpdateOrario(comp.getDati())
      }
    },
    onUpdateOrario(value) {
      var hint = ''
      var form = this.$refs.form
      var comp = form.getComponentByName('prodotti')
      if (!comp) {
        return
      }
      var servizi = comp.getDati()
      var durata = 0
      var attesa = 0
      for (let i = 0; i < servizi.length; i++) {
        durata += parseInt(servizi[i].durata || this.durataDefault)
        attesa += parseInt(servizi[i].attesa || 0)
      }
      var campoOrario = this.campi.find(obj => obj.campo === 'orario')
      if (value) {
        var ora = moment().startOf('day').add(value.split(' ').join(''))
        ora.add(durata, 'minutes').add(attesa, 'minutes')
        hint = 'Orario fine: ' + ora.format('HH : mm') + ', durata: ' + durata + ' minuti, di cui ' + attesa + ' di attesa'
      }
      this.$set(campoOrario, 'hint', hint)
    },
    onSelezionaServizioDaDisplay(servizio) {
      var form = this.$refs.form
      var comp = form.getComponentByName('prodotti')
      var op = form.getComponentByName('operatore').getDati() || null
      servizio = {
        codice: servizio.codice,
        descrizione: servizio.descrizione,
        durata: _.get(servizio, 'servizio.durata') || this.durataDefault,
        attesa: _.get(servizio, 'servizio.attesa') || 0,
        operatore: op
      }
      console.log(servizio)
      comp.callMethod('addServizio', servizio)
    }
    // TODO: Verificare come gestire.
    /*     getServizioDefault() {
      // In caso di chiusura senza specificare servizio, ne aggiunge uno di durata predefinita (da impostazioni) ma con prezzo da specificare
      var form = this.$refs.form
      var op = form.getComponentByName('operatore').getDati() || null
      const servizio = {
        codice: 'servizio_generico',
        descrizione: 'Servizio Generico',
        durata: 30,
        attesa: 0,
        operatore: op
      }
      return servizio
    } */

  }
}
</script>

<style lang="scss" scoped>

</style>

<style>
/* non può essere scoped */
  .btnModale.vaiAlPagamento {
    color: white;
    background-color: #339900;
    padding: 10px 20px 10px 20px;
    margin-left: 10px;
  }
</style>

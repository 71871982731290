<template>
  <van-popup :value="true" :closeable="false" :close-on-click-overlay="true" class="popup-stampante" @open="onOpen" @close="onClose" @click-overlay="onClose">
    <div v-if="!showDettaglio">
      <h3 class="info-center">Stampante Fiscale</h3>
      <h4 class="info-center red blink" v-if="infoStampante.errore">{{infoStampante.errore}}</h4>
      <h4 class="info-center orange blink" v-if="infoStampante.lowDGFE">Attenzione spazio DGFE minore del 10%. <br/>Contattare l'assistenza </h4>
      <van-row type="flex" justify="space-around">
        <van-col span="10">
          <van-cell-group>
            <van-cell title="Matricola" :value="infoStampante.matricola" />
            <van-cell title="Firmware" :value="infoStampante.firmware" />
            <van-cell title="Spazio libero DGFE" :value="infoStampante.liberoDGFE" />
            <van-cell title="Data/ora" :value="infoStampante.data"/>
            <van-button v-if="mostraTutto || (mostraAggiornaOra && !mostraCambioModo)" type="info" plain size="large" @click="aggiornaOra">Aggiorna ora Stampante</van-button>
            <van-cell title="Ultimo azzeramento" :value="infoStampante.lastZ" />
            <van-cell title="Ultimo documento" :value="infoStampante.lastDocF" />
          </van-cell-group>
        </van-col>
        <van-col span="10">
          <van-cell-group>
            <van-cell title="File pendenti" :value="infoStampante.filePendenti"/>
            <van-cell v-if="mostraTutto || infoStampante.oldFile" title="File più vecchio" :value="infoStampante.oldFile"/>
            <van-button v-if="mostraTutto || mostraInvioPendenti" type="primary" plain size="large" @click="invioPendenti">Invia file pendenti</van-button>
            <van-cell title="Modo" :value="infoStampante.modo" />
            <van-button v-if="mostraTutto || mostraCambioModo" type="primary" plain size="large" @click="modoReg">Passa a modo REG</van-button>
            <van-cell title="Stato" :value="infoStampante.stato" :value-class="classeInattiva" :title-class="classeInattiva"  size="large"/>
            <van-button v-if="mostraTutto || (mostraCambioStato && !mostraCambioModo)" type="warning" plain size="large" @click="eseguiChiusura">Chiusura giornaliera</van-button>
          </van-cell-group>
        </van-col>
      </van-row>
    </div>
    <stato-servizi @dettaglio="showDettaglio = !showDettaglio"/>
  </van-popup>
</template>

<script>
// import moment from 'moment'
import StatoServizi from './statoServizi'
import stampante from '@/services/stampanteService'
import { confermaOperazione } from '@/mixins/confermaOperazione'
import { insomnia } from '@/mixins/insomnia.js'
// Verificare uso plugin per nuove stampanti !!!
import rch from './stampanti/rch'
import epson from './stampanti/epson'

export default {
  components: {
    StatoServizi
  },
  mixins: [confermaOperazione, insomnia],
  props: {
    context: Object
  },
  data() {
    return {
      printerStatus: null,
      infoStampante: {},
      testInterval: null,
      mostraTutto: false,
      showDettaglio: false,
      tipoDriver: 'RCH-PF', // da impostazioni
      printers: {
        'RCH-PF': rch,
        EPSON: epson
      },
      richiestaStato: false
    }
  },
  mounted() {
    this.onOpen()
    this.testInterval = setInterval(async () => {
      await this.getInfoStampante()
      const status = await this.getProcessStatus(this.infoStampante)
      this.$store.commit('SET_SERVICE_STATUS', status)
    }, 4000)
  },
  /*   beforeDestroy() {
    clearInterval(this.testInterval)
    if (!this.infoStampante.errore) {
      this.modoReg()
    }
  }, */
  computed: {
    /*     stato() {
      return this.printerStatus ? this.printerStatus.filePendenti.inattiva ? 'Inattiva' : 'Attiva' : ''
    }, */
    /*     request() {
      return this.printerStatus.response
    },
    dataStampante() {
      return moment(this.printerStatus.data).format('DD/MM/yyyy HH:mm')
    },
    modo() {
      return this.request.ECRStatus ? this.request.ECRStatus.mode : 'REG'
    }, */
    mostraCambioModo() {
      const hnd = this.printers[this.tipoDriver]
      return hnd.mostraCambioModo(this.infoStampante)
    },
    mostraCambioStato() {
      const hnd = this.printers[this.tipoDriver]
      return hnd.mostraCambioStato(this.infoStampante)
    },
    mostraAggiornaOra() {
      const hnd = this.printers[this.tipoDriver]
      return hnd.mostraAggiornaOra(this.printerStatus)
    },
    mostraInvioPendenti() {
      return false
    },
    classeInattiva() {
      return this.mostraCambioStato ? 'testo-inattiva blink' : ''
    },
    hnd() {
      return this.printers[this.tipoDriver]
    }
  },
  methods: {
    async getInfoStampante() {
      if (this.context.chiusuraGiornalieraInCorso) return
      if (this.richiestaStato) return
      this.richiestaStato = true
      this.printerStatus = await stampante.printerStatus()
      this.richiestaStato = false
      this.tipoDriver = this.printerStatus.tipoDriver || 'RCH-PF' // per compatibilità
      // Verificare uso plugin per nuove stampanti !!!
      const hnd = this.printers[this.tipoDriver]
      this.infoStampante = hnd.infoStampante(this.printerStatus)
    },
    async onOpen() {
      await this.getInfoStampante()
    },
    eseguiChiusura() {
      const event = {
        type: 'FUNZIONE_RAPIDA_CONFIRM',
        value: {
          funzione: 'CHIUSURA_GIORNALIERA',
          confirm: true
        }
      }
      this.$emit('send-event', event)
    },
    async aggiornaOra() {
      if (!this.hnd.aggiornaOraPossibile(this.printerStatus)) {
      // if (this.request.lastDocF !== '0') {
        await this.$dialog.alert({
          title: 'Aggiornamento ora stampante',
          message: 'Prima di poter aggiornare l\'ora è necessario effettuare manualmente una chiusura fiscale'
        })
        return
      }
      await stampante.setPrinterDate()
    },
    modoReg() {
      // const hnd = this.printers[this.tipoDriver]
      // hnd.passaModoReg()
      const event = {
        type: 'PRINTER',
        value: {
          // commands: ['=C1'] // PASSA_MODO_REG
          commands: ['PASSA_MODO_REG'] // PASSA_MODO_REG
        }
      }
      this.$emit('send-event', event)
    },
    invioPendenti() {

    },
    onClose() {
      clearInterval(this.testInterval)
      if (!this.infoStampante.errore || this.infoStampante.errCode === 'STATUS_ERROR') {
        this.modoReg()
        setTimeout(() => { this.context.inviaBenvenutoAMF = true }, 500)
      }
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

  .popup-stampante {
    width: 40vw;
    height: 60vh; /* 47 Attenzione lasciare spazio per pulsanti aggiornamento */
  }

  @media all and (max-width: 1800px) {
    .popup-stampante {
      width: 50vw;
      height: 67vh;
    }
  }

  @media all and (max-width: 1400px) {
    .popup-stampante {
      width: 55vw;
      height: 72vh; /* 52 Attenzione lasciare spazio per pulsanti aggiornamento */
    }
  }

  @media all and (max-width: 1024px) {
    .popup-stampante {
      width: 80vw;
      height: 72vh; /* 52 Attenzione lasciare spazio per pulsanti aggiornamento */
    }
  }

  .info-center {
    text-align: center;
    &.red {
      color: red;
    }
    &.orange {
      color: #ff8800;
    }
  }
  .testo-inattiva {
    color: red;
    font-weight: bold;
  }
  .popup-stampante .van-cell {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .blink {
    animation: blinker 1s step-start infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
</style>

<template>
  <van-cell-group title="Generale">
    <van-cell title="Codice cliente" :value="codiceCliente"/>
    <van-cell title="Ragione sociale" :value="ragioneSociale"/>
    <van-cell title="Tipologia cliente" :value="tipoCliente"/>
    <van-cell title="Listino" :value="listinoAssociato"/>
    <van-cell title="Sconto %" :value="scontoAssociato"/>
  </van-cell-group>
</template>
<script>
export default {
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
    }
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    listinoAssociato() {
      return this.card.generale ? this.card.generale.listino : ''
    },
    scontoAssociato() {
      return this.card.generale ? this.card.generale.sconto : ''
    },
    codiceCliente() {
      return this.card.cliente ? this.card.cliente.codice || '' : ''
    },
    ragioneSociale() {
      return this.card.cliente ? this.card.cliente.ragioneSociale || '' : ''
    },
    tipoCliente() {
      return this.card ? this.card.tipoCliente : ''
    }
  }
}
</script>

<template>
  <div>
    <template v-if="isSeparatore">
      <hr/>
    </template>
    <div v-else
      class="wrapper" :class="{selected: isSelected, reso: isReso}"
      @click="onClick"
      @dblclick="onDblClick"
      v-longclick="onLongClick"
    >
      <div class="quantita-reso"><span v-if="riga.quantitaReso">{{-riga.quantitaReso}}</span></div>
      <div class="quantita"><span v-if="riga.quantita && !isVariazione">{{quantita}}</span></div>
      <!-- <div class="unitario"><span v-if="riga.prezzoAcquisto && mostraPrezzo">{{riga.prezzoAcquisto}}</span></div> -->
      <div class="unitario"><span v-if="mostraPrezzo">{{prezzoSuRiga}}</span></div>
      <div class="descrizione" :class="{ bold: isTotale, riepilogo: isRiepilogo || isVariazione, sconto: isSconto, abbuono: isAbbuono }">{{ isCodiceFiscale ? descrizioneFiscale : riga.descrizione }}<div class="nota" v-if="riga.nota">{{riga.nota}}</div></div>
      <div class="iva"><span v-if="riga.aliquota">{{riga.aliquota + '%'}}</span></div>
      <div class="unitario"><span v-if="riga.prezzoUnitario">{{riga.prezzoUnitario}}</span></div>
      <div class="prezzo" :class="{ bold: isTotale, riepilogo: isRiepilogo || isVariazione }"><span>{{prezzo}}</span></div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    riga: Object,
    rigaId: Number,
    rigaSelezionata: Number,
    scontrinoTick: Number,
    reso: Boolean,
    rigaCodice: String, // definito su scontrino, per identificare la riga indipendentemente da note aggiunte (es sconti, resi, etc.)
    rigaSelezionataCodice: String,
    isSubTotaleCalcolato: Boolean,
    mostraPrezzo: Boolean,
    tipoPrezzo: String
  },
  data() {
    return {
      isSelected: false
    }
  },
  computed: {
    prezzoSuRiga() {
      switch (this.tipoPrezzo) {
        case 'giacenza':
          return _.get(this.riga, 'magazzino.giacenza')
        case 'acquisto':
          return _.get(this.riga, 'prezzoAcquisto')
        default: // TODO: eventualmente gestire prezzo cliente o prezzo tipocliente
        {
          const prezzo = _.get(this.riga, `listini.${this.tipoPrezzo}.base`)
          return prezzo ? prezzo.toFixed(2) : ''
        }
      }
    },
    isSeparatore() {
      return this.riga.tipo === 'separatore'
    },
    isTotale() {
      return ['totale', 'resto', 'totalecomplessivo'].includes(this.riga.tipo)
    },
    isCodiceFiscale() {
      return this.riga.tipo === 'codicefiscale'
    },
    descrizioneFiscale() {
      if (this.riga.payload && this.riga.payload.codice && this.riga.payload.codice.length === 11) {
        return 'Partita iva: ' + this.riga.payload.codice
      } else {
        return this.riga.descrizione
      }
    },
    isRiepilogo() {
      return [
        'contanti',
        'cartadicredito',
        'buonipasto',
        'nonriscosso',
        'nonriscossobeni',
        'nonriscossoservizi',
        'scontoapagare',
        'buonimultiuso',
        'buonimonouso',
        'fidelitycard',
        'descrizione'
      ].includes(this.riga.tipo)
    },
    isReso() {
      return this.riga.quantitaReso || this.riga.quantita < 0
    },
    isVariazione() {
      return !!this.riga.isVariazione
    },
    quantita() {
      let qta = this.riga.quantita
      if (this.riga.quantitaReso) {
        // qta = `-${this.riga.quantitaReso} .. ${this.riga.quantita}`
        qta = ` ... ${this.riga.quantita}`
      }
      return qta
    },
    isSconto() {
      const variazione = ['abbuono', 'sconto', 'abbuonoSub', 'scontoSub'].includes(this.riga.tipo)
      return variazione && this.riga.descrizione.indexOf('%') > 0
    },
    isAbbuono() {
      const variazione = ['abbuono', 'sconto', 'abbuonoSub', 'scontoSub'].includes(this.riga.tipo)
      return variazione && this.riga.descrizione.indexOf('%') < 0
    },
    prezzo() {
      const pz = this.riga.prezzo
      return !isNaN(pz) && !!pz && pz !== '' && pz !== null ? parseFloat(pz).toFixed(2) : ''
    }
  },
  watch: {
    /*     rigaSelezionataCodice(riga) {
      if (riga !== this.riga.rigaCodice) {
        this.isSelected = false
      }
    } */
    rigaSelezionata(riga) {
      if (riga !== this.rigaId) {
        this.isSelected = false
      }
    }
  },
  methods: {
    onClick(e) {
      e.preventDefault()
      console.log(this.riga.tipo)
      if (!['prodotto', 'reparto', 'subtotale', 'lotteria', 'codicefiscale', 'cortesia', 'abbuono', 'scontoSub', 'abbuonoSub', 'descrizione'].includes(this.riga.tipo)) {
        return
      }
      if (this.isSubTotaleCalcolato && !['subtotale', 'scontoSub', 'abbuonoSub'].includes(this.riga.tipo)) {
        return
      }
      this.isSelected = !this.isSelected
      this.sendToMachine(this.isSelected ? 'SELECT_RIGA_SCONTRINO' : 'RELEASE_RIGA_SCONTRINO', {
        // rigaId: this.rigaId, // da definire
        rigaCodice: this.riga.rigaCodice
      })
      this.$emit('selected', this.isSelected ? this.rigaId : -1) // Devo togliere l'eventuale selezione delle altre righe
      // this.$emit('selected', this.isSelected ? this.riga.rigaCodice : -1) // Devo togliere l'eventuale selezione delle altre righe
    },
    onDblClick(e) {
      // this.action.typedbl && this.sendToMachine(this.action.typedbl)
    },
    onLongClick(e) {
      // this.action.type2th && this.sendToMachine(this.action.type2th)
    },
    sendToMachine(actionType, payload) {
      // let event = {}
      const event = {
        type: actionType,
        value: payload
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    display: grid;
    /* grid-template-columns: 3% 6% 6% 56% 5% 18%; */
    grid-template-columns: 3% 6% 6% 54% 5% 7% 13%;
    grid-column-gap: 5px;
    grid-row-gap: 2px;
    grid-auto-rows: minmax(24px, auto);

    &.selected {
      background-color: #00000033;
    }

    &.reso div {
      color: #ff0000;
    }

    & div {
      &.quantita,
/*       &.quantita-reso, */
      &.unitario,
      &.prezzo {
        width:100%;
        text-align: right;
      }

      &.quantita span {
        padding-right: 10px;
      }

/*       &.quantita span.reso-di {
        font-size:1.1em;
        text-transform: lowercase;
      } */

      &.unitario {
        @media all and (min-width:768px) {
          font-size:0.7em;
        }
        @media all and (min-width:1024px) {
          font-size:0.9em;
        }
        @media all and (min-width:1400px) {
          font-size:1.1em;
        }
        align-self: stretch;
        margin-top: 5px;
        & span {
          padding-right: 2px;
        }
      }

      &.iva {
        font-size:1.4em;
        align-self: stretch;
        margin-top: 5px;
      }

      & .nota {
        font-size:0.8em;
        font-style: italic;
      }

      &.bold {
        font-weight: bolder;
      }

      &.riepilogo {
        font-size:1.4em;
        font-style: italic; /* TODO: Verificare */
      }

      &.sconto {
        color: #e400ff
      }
      &.abbuono {
        color: #007eff
      }
    }
  }

</style>

<script>
import Semaphore from 'semaphore-async-await'
import stampante from '@/services/stampanteService.js'
import { getScontiScontrino } from '@/machines/dashboardMachine.js'

const FIFO = require('fast-fifo')

const qd = new FIFO() // coda FIFO per display AMF
const lockd = new Semaphore(1)

async function dequeueDisplay() {
  /*   while (!qd.isEmpty()) {
    const righe = await qd.shift()ss
    await stampante.displayMessage(righe)
  } */
  let righe = []
  // Se le righe vengono aggiunte troppo velocemente, non ha senso farle vedere tutte a display. Prendo solo l'ultima

  // TODO: Se lo scontrino viene stampato si può interrompere immediatamente la coda dei messaggi verso la stampante
  while (!qd.isEmpty()) {
    righe = await qd.shift()
  }
  if (righe.length > 0) {
    await stampante.displayMessage(righe)
  }
}

// TODO: modificare ciclo per richiamarlo una sola volta invece che per ogni messaggio aggiunto
async function dequeueDisplaySynchronous(context) {
  await lockd.acquire()
  // context.invioDisplayInCorso = true --> problema se la stampante non risponde !!
  await dequeueDisplay()
  // context.invioDisplayInCorso = false
  lockd.release()
}

function inviaDisplay(context) {
  setTimeout(async () => {
    await dequeueDisplaySynchronous(context)
  }, 1)
}

export default {
  render() {
    return {}
  },
  props: {
    context: Object
  },
  data() {
    return {
      righeScontrino: []
    }
  },
  mounted() {},
  computed: {
    numeroRighe() {
      return this.context.righeCarrello.length
    },
    righe() {
      return this.context.righeCarrello
    },
    scontrinoStampato() {
      return this.context.scontrinoStampato
    },
    inviaBenvenutoAMF() {
      return this.context.inviaBenvenutoAMF
    }
  },
  watch: {
    scontrinoStampato(sc) {
      if (sc === null) {
        // anche se il display non è abilitato, lo cancella quando ha finito un conto a meno che non sia forzatamente bloccato
        if (!this.context.impostazioni.display.amf.disableReset) {
          this.displayAMF([])
        }
      }
    },
    numeroRighe(nr) {
      if (this.context.impostazioni.display.amf.enabled) {
        this.displayAMF(this.righe)
      }
      if (this.context.impostazioni.display.web.enabled) {
        this.displayWEB(this.righe)
      }
    },
    /*     righe(rc) {
    }, */
    async inviaBenvenutoAMF (nv, ov) {
      if (nv) {
        const riga1Default = this.context.impostazioni.display.amf.riga1_default
        const riga2Default = this.context.impostazioni.display.amf.riga2_default
        const toDisplay = [riga1Default || ' ', riga2Default || ' ']
        qd.push(toDisplay)
        inviaDisplay(this.context)
        this.context.inviaBenvenutoAMF = false
      }
    }
  },
  methods: {
    displayAMF(rc) {
      if (rc.length > 0) {
        const ultimaRiga = rc[rc.length - 1]
        if (['prodotto', 'reparto'].includes(ultimaRiga.tipo)) {
          let valoreSconto = ''
          if (ultimaRiga.sconto) {
            valoreSconto = 'sc:' + ultimaRiga.sconto.prezzo
          }
          if (ultimaRiga.abbuono) {
            valoreSconto = 'sc:' + ultimaRiga.abbuono.prezzo
          }
          const rigaPrezzo = `E:${ultimaRiga.prezzo} ${valoreSconto}`.padEnd(20, ' ')
          const toDisplay = [
            ultimaRiga.descrizione,
            rigaPrezzo
          ]
          // TODO: mettere coda
          qd.push(toDisplay)
          inviaDisplay(this.context)
          // await stampante.displayMessage(toDisplay)
        } else if (['subtotale', 'totale'].includes(ultimaRiga.tipo)) {
          const isSubtotale = rc.find(x => x.tipo === 'subtotale')
          const isTotale = rc.find(x => x.tipo === 'totale')
          if (isSubtotale && !isTotale) {
            const { scontoSub, /* maggiorazioniSub , abbuonoPerEuro, */ subtotale } = getScontiScontrino(rc)
            const rigaPrezzo = `E:${(subtotale - scontoSub).toFixed(2)}`.padEnd(20, ' ')
            const toDisplay = [
              'Totale',
              rigaPrezzo
            ]
            // TODO: mettere coda
            qd.push(toDisplay)
            inviaDisplay(this.context)
          }
        }
      } else {
        // const riga1Default = this.context.impostazioni.display.amf.riga1_default
        // const riga2Default = this.context.impostazioni.display.amf.riga2_default

        // const toDisplay = [riga1Default || ' ', riga2Default || ' ']
        // qd.push(toDisplay)
        // // inviaDisplay(this.context)
        // await stampante.displayMessage([riga1Default || ' ', riga2Default || ' '])
      }
    },
    displayWEB(rc) {
      this.$store.commit('scontrino/SET_CARRELLO', rc) // verificare se mettere timeout dopo chiusura scontrino in modo da nascondere l'elenco anche se non si avvia un nuovo scontrino
    }
  }
}
</script>

<template>
  <van-cell-group>
    <van-form @submit="onSubmit">
      <van-search
        v-model="$v.cliente.$model"
        show-action
        name="cliente"
        ref="cliente"
        label="Cliente"
        placeholder="Cliente"
        :error-message="cliente ? clienteDescrizione : ''"
        @search="onSearchCliente"
        @clear="onClearCliente"
        @focus="onFocus"
        :disabled="!modificabile"
      >
        <template #action>
          <div @click="onSearchCliente">Cerca</div>
        </template>
      </van-search>

      <van-field
        readonly
        clickable
        :value="$v.agenda.$model"
        name="agenda"
        ref="agenda"
        label="Agenda"
        label-align="left"
        placeholder="Agenda"
        :error-message="$v.agenda.$error ? 'Valore mancante' : agendaDescrizione"
        :class="{ 'mostra-descrizione': !$v.agenda.$error}"
        @click="showAgenda = modificabile"
      />
      <van-popup v-model="showAgenda" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar title="Agenda" :columns="columnsAgenda" @confirm="onConfirmAgenda" @cancel="showAgenda = false"/>
      </van-popup>

      <van-field
        readonly
        clickable
        :value="$v.operatore.$model"
        name="operatore"
        ref="operatore"
        label="Operatore"
        placeholder="Operatore"
        :error-message="$v.operatore.$error ? 'Valore mancante' : operatoreDescrizione"
        :class="{ 'mostra-descrizione': !$v.operatore.$error}"
        @click="showOperatore = modificabile"
      />
      <van-popup v-model="showOperatore" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar title="Operatore" :columns="columnsOperatore" @confirm="onConfirmOperatore" @cancel="showOperatore = false"/>
      </van-popup>

      <van-field
        readonly
        clickable
        name="dataApt"
        :value="$v.dataApt.$model"
        label="Data"
        placeholder="Data appuntamento"
        @click="showCalendar = modificabile"
      />
      <van-calendar v-model="showCalendar" :formatter="calendarFormatter" @confirm="onConfirmData" :style="getStilePopup()" :default-date="dataDefault"/>

      <van-field
        readonly
        clickable
        name="datetimePicker"
        :value="$v.oraApt.$model"
        label="Orario inizio"
        placeholder="Scegli l'orario"
        @click="showPickerOrario = modificabile"
      />
      <van-popup v-model="showPickerOrario" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-datetime-picker
          v-model="$v.oraApt.$model"
          type="time"
          @confirm="onConfirmOrarioPicker"
          @cancel="showPickerOrario = false"
          :filter="dateTimeFilter"
          :min-hour="minHour"
          :max-hour="maxHour"
        />
      </van-popup>
      <van-search
        v-model="$v.prodotto.$model"
        show-action
        name="prodotto"
        ref="prodotto"
        label="Prodotto"
        placeholder="Prodotto"
        :error-message="prodotto ? prodottoDescrizione : ''"
        @search="onSearchProdotto"
        @clear="onClearProdotto"
        @focus="onFocus"
        :disabled="!modificabile"
      >
        <template #action>
          <div @click="onSearchProdotto">Cerca</div>
        </template>
      </van-search>
      <van-cell-group title="Servizi selezionati" class="apt-tag-container">
        <span v-for="(pr, index) in prodotti" :key="index" class="apt-span-tag-prodotto">
          <van-tag type="success" :closeable="modificabile" size="large" @close="cancellaProdotto(index)" class="apt-tag-prodotto">{{pr.descrizione}}</van-tag>
        </span>
      </van-cell-group>
      <van-field
        readonly
        :value="oraFineApt"
        label="Orario fine"
        placeholder="---"
        :error-message="informazioniFine"
        class="mostra-descrizione"
      />
      <div style="margin: 16px;">
        <van-button v-if="modificabile" block type="info" native-type="submit">Salva appuntamento</van-button>
      </div>
      <div style="margin: 16px;">
        <van-button v-if="modificabile" block type="primary" @click.prevent="pagaAppuntamento">Vai al pagamento</van-button>
      </div>

      <van-cell-group v-if="!modificabile" title="L'appuntamento risulta chiuso" class="apt-tag-container">
        <van-cell :value="infoChiusura" />
      </van-cell-group>
    </van-form>
  </van-cell-group>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import errorService from '@/services/errorService'
import { validationMixin } from 'vuelidate'
// import { helpers, required, numeric, email, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { required } from 'vuelidate/lib/validators'

import clienti from '@/services/clientiService.js'
import prodotti from '@/services/prodottiService.js'
import appuntamenti from '@/services/appuntamentiService.js'
// import tabelle from '@/services/tabelleService.js'
import configurazioni from '@/services/configurazioniService.js'

export default {
  mixins: [validationMixin],
  props: {
    context: Object
  },
  data() {
    return {
      showAgenda: false,
      showCalendar: false,
      showOperatore: false,
      showPickerOrario: false,
      elencoAgende: [],
      elencoOperatori: [],
      elencoProdotti: [],
      showUm: false,
      mostraCliente: false,
      mostraFornitore: false,
      mostraPrezzoAcquisto: false,
      mostraPrezzoVendita: false,
      agenda: '',
      agendaDescrizione: '',
      agendaItem: null,
      operatore: '',
      operatoreDescrizione: '',
      dataApt: null,
      oraApt: '',
      minHour: 0, // calcolate in base all'agenda scelta
      maxHour: 23,
      prodotto: '',
      prodottoDescrizione: '',
      prodottoItem: null,
      prodotti: [],
      cliente: '',
      clienteDescrizione: '',
      submitStatus: null,
      appuntamentoItem: null,
      activeField: 'cliente',
      activeTab: 'nuovo',
      activeParent: 'appuntamenti'
    }
  },
  validations: {
    agenda: { required },
    operatore: {},
    dataApt: { required },
    oraApt: { required }, // deve rientrare nei limiti dell'agenda + durata apt ? Verificare come gestire
    prodotto: {},
    cliente: { required }
  },
  directives: {
    uppercase: {
      update (el) {
        el.value = el.value.toUpperCase()
      }
    }
  },
  created() {
    this.debounceInputProdotti = _.debounce(this.getElencoProdotti, 500)
    this.debounceInputClienti = _.debounce(this.getElencoClienti, 500)
    this.debounceInputFornitori = _.debounce(this.getElencoFornitori, 500)
  },
  async mounted() {
    try {
      // this.elencoOperatori = await configurazioni.getConfigurazione('operatori', 'planning')
      var result = await configurazioni.getOperatori()
      this.elencoOperatori = result ? result.filter(x => x.planning).map(op => { return { descrizione: op.nome, ...op } }) : []
      const plannings = await configurazioni.getConfigurazione('agende')
      this.elencoAgende = plannings ? plannings.agende : []
    } catch {
      errorService.onError('Tabelle di base non trovate', 'mounted nuovo movimento')
    }
    this.onOpen()
    this.$refs.agenda.focus()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.count : 0
    },
    columnsAgenda() {
      return this.elencoAgende.map(el => el.descrizione).sort()
    },
    columnsOperatore() {
      return this.elencoOperatori.map(el => el.descrizione).sort()
    },
    listResult() {
      return this.$store.getters.listResult
    },
    durataApt() {
      let durata = 0
      this.prodotti.forEach(el => {
        if (el.servizio) {
          durata += (el.servizio.durata || 0)
        }
      })
      return durata > 0 ? durata : '---'
    },
    attesaApt() {
      let attesa = 0
      this.prodotti.forEach(el => {
        if (el.servizio) {
          attesa += (el.servizio.attesa || 0)
        }
      })
      return attesa > 0 ? attesa : '---'
    },
    orarioFine() {
      return moment(this.dataApt + ' ' + this.oraApt, 'DD/MM/YYYY HH:mm').add(this.durataApt, 'minutes')
    },
    oraFineApt() {
      if (this.dataApt && this.oraApt) {
        return this.orarioFine.format('HH:mm')
      } else {
        return ''
      }
    },
    informazioniFine() {
      if (this.dataApt && this.oraApt) {
        return `Durata: ${this.durataApt} minuti, di cui ${this.attesaApt} di attesa`
      } else {
        return ''
      }
    },
    dataDefault() {
      return moment(this.dataApt, 'DD/MM/YYYY').utcOffset(0, true).format()
    },
    modificabile() {
      if (this.appuntamentoItem) {
        return this.appuntamentoItem.stato !== 'chiuso' // TODO: Verificare per altri valori o comportamenti
      } else {
        return true
      }
    },
    infoChiusura() {
      const numero = this.appuntamentoItem.pagamento.barcode.documento
      const data = this.appuntamentoItem.pagamento.idScontrino.split('.')[0]
      return `Scontrino numero: ${numero} del ${moment(data).format('DD/MM/YYYY')}`
    }
  },
  watch: {
    kbInput(value) {
      const variabile = this.activeField
      const valore = String(value)
      this[variabile] = valore
      this.handleKey(valore)
    },
    kbKeyPress(value) {
      if (value === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    },
    kbCounter(value) {
      if (this.kbKeyPress === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    },
    listResult(payload) {
      if (payload.parent !== this.activeParent || payload.tab !== this.activeTab || !payload.result) return
      const item = payload.result
      // console.log('listResult appuntamenti', this.activeField, item)
      switch (this.activeField) {
        case 'prodotto':
          this.prodotti.push(item)
          this.$store.commit('CLEAR_POPUP_LIST_RESULT') // Cancella il contenuto della selezione, altrimenti non prende più volte lo stesso servizio
          // this.prezzoVendita = item.prezzoVendita
          break
        case 'cliente':
          this.cliente = item.codice
          this.clienteDescrizione = item.descrizione
          this.$store.commit('clienti/SET_CLIENTE', { payload: item, nuovo: false })
          this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
          break
        default:
          break
      }
      // this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
    },
    'context.pagaAppuntamentoInCorso'(value) {
      // Attenzione: gestione abbastanza delicata. Forse andrebbe ripensato diversamente o con più macchine a stati
      const indice = value - 1
      if (indice < this.prodotti.length) {
        const codice = this.prodotti[indice].codice
        this.aggiungiProdottoAlCarrello(codice)
      } else {
        this.chiudiPagamento()
      }
    }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.tab-popup-appuntamenti')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    dateTimeFilter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 5 === 0) // va calcolato in base alla impostazione dell'agenda
      }
      return options
    },
    handleKey(valore) {
      switch (this.activeField) {
        case 'prodotto':
          this.debounceInputProdotti(valore)
          break
        case 'cliente':
          this.debounceInputClienti(valore)
          break
        default:
          break
      }
    },
    calendarFormatter(day) {
      if (this.agendaItem) {
        const giorni = this.agendaItem.orarioDefault.giorniSettimana
        // TODO: Considerare anche orarioGiornaliero
        if (!giorni.includes(day.date.getDay())) {
          day.type = 'disabled'
        }
      }
      return day
    },
    onConfirmData(value) {
      this.dataApt = moment(value).format('DD/MM/YYYY')
      this.showCalendar = false
    },
    onConfirmAgenda(value, index) {
      this.showAgenda = false
      const agenda = this.elencoAgende.find(x => x.descrizione === value)
      this.impostaLimitiOreAgenda(agenda)
      // TODO: Bloccare i giorni non ammessi per l'agenda
      this.agenda = agenda.nome
      this.agendaDescrizione = agenda.descrizione
      this.agendaItem = agenda
    },
    impostaLimitiOreAgenda(agenda) {
      // imposta ora minima e massima
      const oraDalle = agenda.orarioDefault.oraDalle1
      const oraAlle = agenda.orarioDefault.oraAlle2 || agenda.orarioDefault.oraAlle1
      this.minHour = moment(oraDalle, 'HH:mm').hour()
      this.maxHour = moment(oraAlle, 'HH:mm').hour()
    },
    onConfirmOperatore(value, index) {
      this.showOperatore = false
      const operatore = this.elencoOperatori.find(x => x.descrizione === value)
      this.operatore = operatore.codice
      this.operatoreDescrizione = operatore.descrizione
    },
    onConfirmOrarioPicker(value) {
      this.showPickerOrario = false
      this.oraApt = value
    },
    cancellaProdotto(index) {
      if (this.modificabile) {
        this.prodotti.splice(index, 1)
      }
    },
    onSearchProdotto() {
      if (this.modificabile) {
        this.getElencoProdotti(this.prodotto)
      }
    },
    onSearchCliente() {
      if (this.modificabile) {
        this.getElencoClienti(this.cliente)
      }
    },
    onClearProdotto() {
      this.prodottoDescrizione = ''
    },
    onClearCliente() {
      this.clienteDescrizione = ''
    },
    async getElencoProdotti(value) {
      /*       if (!value) {
        this.elencoProdotti = []
      } else { */
      const result = await prodotti.getServizi(value)
      if (result && result.docs && result.docs.length > 0) {
        const lista = result.docs.map(item => {
          const label = []
          if (item.nome) label.push(item.nome)
          if (item.servizio && item.servizio.durata) label.push('durata: ' + item.servizio.durata)
          if (item.servizio && item.servizio.attesa) label.push('attesa: ' + item.servizio.attesa)
          // const fornitore = item.ultimoAcquisto && item.ultimoAcquisto.fornitore ? item.ultimoAcquisto.fornitore : ''
          // let fornitoreDescrizione = item.ultimoAcquisto && item.ultimoAcquisto.fornitoreDescrizione ? item.ultimoAcquisto.fornitoreDescrizione : ''
          return {
            codice: item.codice,
            descrizione: item.descrizione,
            prezzoVendita: item.prezzo && item.prezzo.L1 ? item.prezzo.L1.base : '', // TODO: gestire un prezzo unitario oppure nome listino base su configurazioni ??
            servizio: item.servizio,
            /* fornitore,
            fornitoreDescrizione, */
            titolo: `${item.codice} - ${item.descrizione}`,
            label: label.join(' - '),
            value: ''
          }
        })
        this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
          list: lista,
          icon: 'records',
          classes: {
            titleClass: 'prodotti-lr-title',
            labelClass: 'prodotti-lr-label',
            valueClass: 'prodotti-lr-value'
          }
        })
      } else {
        console.log('getElencoProdotti noresult')
        this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      }
      // }
    },
    async getElencoClienti(value) {
      if (!value) {
        this.elencoClienti = []
      } else {
        const result = await clienti.searchClienti(value)
        if (result && result.docs && result.docs.length > 0) {
          const lista = result.docs.map(item => {
            return {
              codice: item.codice,
              descrizione: item.ragioneSociale,
              titolo: `${item.codice} - ${item.ragioneSociale}`,
              label: `${item.recapiti[0].indirizzo} - email: ${item.recapiti[0].email}`,
              value: `tel: ${(item.contatti && item.contatti.length > 0 && item.contatti[0].cellulare) || item.recapiti[0].telefono}`
            }
          })
          this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
            list: lista,
            icon: 'user-o',
            classes: {
              titleClass: 'clienti-lr-title',
              labelClass: 'clienti-lr-label',
              valueClass: 'clienti-lr-value'
            }
          })
        } else {
          console.log('getElencoClienti noresult')
          this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
        }
      }
    },
    onFocus(event) {
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      // this.$store.commit('SHOW_POPUP_KEYBOARD')
      this.$v.$touch()
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
      this.clienteDescrizione = this.cliente ? this.clienteDescrizione : ''
    },
    async onSubmit(values) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast('Sono presenti alcuni errori, verificare i dati inseriti', { type: 'fail', duration: 20000 })
      } else {
        const appuntamento = await this.buildAppuntamento()
        const stored = this.$store.getters['appuntamenti/appuntamento']
        if (stored && stored._id) {
          appuntamenti.modificaAppuntamento(stored._id, appuntamento)
        } else {
          appuntamenti.creaAppuntamento(appuntamento)
        }
        this.$root.$emit('refresh-appointment-content')
        this.$toast('Appuntamento salvato con successo', { type: 'success' })
        // TODO: chiude popup
        this.chiudiPopup()
        this.clearFields()
      }
    },
    async onOpen() {
      this.clearSearchInput()
      await this.storeToLocal()
    },
    clearSearchInput() {
      // this.prodotto = ''
      this.cliente = ''
      // this.prodottoDescrizione = ''
      this.clienteDescrizione = ''
      // this.prodotti = []
    },
    clearFields() {
      this.clearSearchInput()
      this.cliente = ''
      this.clienteDescrizione = ''
      this.agenda = ''
      this.agendaDescrizione = ''
      this.agendaItem = null
      this.operatore = ''
      this.operatoreDescrizione = ''
      this.dataApt = null
      this.oraApt = null
      this.prodotti = []
      this.appuntamentoItem = null
      this.$v.$reset()
    },
    async storeToLocal() {
      this.clearFields()
      const appuntamento = this.$store.getters['appuntamenti/appuntamento']
      if (appuntamento) {
        this.appuntamentoItem = appuntamento
        if (appuntamento.agenda) {
          this.agenda = appuntamento.agenda
          const agenda = this.elencoAgende.find(x => x.nome === this.agenda)
          if (agenda) {
            this.agendaDescrizione = agenda.descrizione
          }
        }

        this.dataApt = appuntamento.data
        if (appuntamento.cliente) {
          this.cliente = appuntamento.cliente.codice

          this.clienteDescrizione = appuntamento.cliente.ragioneSociale
          const clienteItem = await clienti.getCliente(this.cliente)
          this.$store.commit('clienti/SET_CLIENTE', { payload: clienteItem, nuovo: false })
        }
        if (appuntamento.operatore) {
          this.operatore = appuntamento.operatore
          this.operatoreDescrizione = this.elencoOperatori && this.elencoOperatori.find(x => x.codice === this.operatore).descrizione
        }
        if (appuntamento.orarioInizio) {
          this.oraApt = moment(appuntamento.orarioInizio).format('HH:mm')
        }
        if (appuntamento.prodotti) {
          appuntamento.prodotti.forEach(item => {
            this.prodotti.push(item)
          })
        }
      }
    },
    async buildAppuntamento() {
      if (this.cliente === null || this.agenda == null || this.prodotti.length === 0) {
        return
      }
      const prodotti = []
      this.prodotti.forEach(el => {
        prodotti.push({
          codice: el.codice,
          descrizione: el.descrizione,
          quantita: 1, // verificare
          servizio: {
            durata: el.servizio.durata,
            attesa: el.servizio.attesa
          }
        })
      })
      const orarioInizio = moment(this.dataApt + ' ' + this.oraApt, 'DD/MM/YYYY HH:mm')
      const appuntamento = {
        _id: this.appuntamentoItem._id,
        cliente: { codice: this.cliente, ragioneSociale: this.clienteDescrizione },
        prodotti: prodotti,
        agenda: this.agenda,
        operatore: this.operatore,
        data: this.dataApt,
        orarioInizio: orarioInizio.toISOString(),
        orarioFine: this.orarioFine.toISOString(),
        durata: this.durataApt,
        attesa: this.attesaApt
      }
      return appuntamento
    },
    chiudiPopup() {
      const event = {
        type: 'CHIUDI_POPUP',
        value: 'Appuntamento'
      }
      this.$emit('send-event', event)
    },
    async pagaAppuntamento() {
      // Verificare se passare per lo store
      const appuntamento = await this.buildAppuntamento()
      // appuntamento.pagamentoInCorso = true --> messo nella macchina a stati
      const cliente = await clienti.getCliente(this.cliente)
      const payload = {
        appuntamento,
        cliente
      }
      const event = {
        type: 'PAGAMENTO_APPUNTAMENTO',
        value: payload
      }
      this.$emit('send-event', event)
    },
    aggiungiProdottoAlCarrello(codice) {
      const event = {
        type: 'PRODOTTO',
        value: {
          code: codice,
          tipoAliquota: 'banco'
        }
      }
      this.$emit('send-event', event)
    },
    chiudiPagamento() {
      const event = {
        type: 'CHIUDI_PAGA_APPUNTAMENTO'
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }

  .mostra-descrizione .van-field__error-message { // TODO: Verificare. Non lo prende
    color: #323232;
  }

  .apt-tag-container {
    padding-left: 10px;
    padding-bottom:20px;
    padding-top:5px;
  }
  .apt-span-tag-prodotto {
    padding: 5px;
  }
  .apt-tag-prodotto {
    margin-top:10px;
    min-height: 30px;;
  }
</style>

<template>
  <van-list class="list-result">
    <van-swipe-cell v-for="(el, index) in elenco" :key="index" :stop-propagation="true">
      <template #left>
        <van-button square color="#5badfa" text="Seleziona" class="swipe-cell-button" @click="seleziona(el)"/>
      </template>
      <van-cell
        :border="true"
        :title="el.titolo"
        :label="el.label"
        :value="el.value"
        :title-class="titleClass"
        :label-class="labelClass"
        :value-class="valueClass"
        :icon="cellIcon"
        size="large"
        @click="seleziona(el)"
      />
      <template #right v-if="rightAction">
        <van-button square color="#5badfa" :text="rightActionLabel" class="swipe-cell-button" @click="rightActionSelect(el)"/>
      </template>
    </van-swipe-cell>
  </van-list>
</template>

<script>
export default {
  props: {
    rightAction: {
      type: Boolean,
      default: false
    },
    rightActionLabel: {
      type: String
    }
  },
  data() {
    return {
    }
  },
  computed: {
    elenco() {
      return this.$store.getters.listResultSource
    },
    altezzaLista() {
      var ht = this.$store.getters.keyboardHeight || 300
      console.log('altezzaLista', ht)
      return ht
    },
    cellIcon() {
      return this.$store.getters.listResultItemIcon
    },
    titleClass() {
      return this.$store.getters.listResultItemClasses.titleClass
    },
    labelClass() {
      return this.$store.getters.listResultItemClasses.labelClass
    },
    valueClass() {
      return this.$store.getters.listResultItemClasses.valueClass
    }
  },
  methods: {
    seleziona(item) {
      this.$store.commit('SET_POPUP_LIST_RESULT', item)
    },
    /*     beforeClose({ position, instance }) {
      console.log('position', position)
    }, */
    rightActionSelect(el) {
      this.$emit('click-right', el)
    }
  }
}
</script>

<style lang="scss" scoped>
  .swipe-cell-button {
    height: 100%;
  }

/*   .prodotti-lr-value {
  }

  .prodotti-lr-title {
  }
  .prodotti-lr-label {
  } */
</style>

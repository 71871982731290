<template>
  <van-cell-group>
    <van-dropdown-menu :name="name">
      <van-dropdown-item  ref="item" :title-class="'ddTitleClass'">
        <template #title>
          <span class="titoloSx">{{ddTitle1}}{{ddTitle2}}</span>
          <span class="titoloDx"><span class="titoloRag">Raggruppa</span><van-switch v-model="elRag" size="24" :active-color="elemento.activeColor || '#4caf50'" @click="ragCheckClick" class="ragSwitch"/></span>
        </template>
        <van-cell v-for="el in elementiSorted" :key=el.codice :title="el.descrizione" @click="el.codice === 'tutti' ? tuttiClick() : cellClick(el.codice)">
          <template #right-icon>
            <van-switch v-if="el.codice !== 'tutti'" v-model="elSel[el.codice]" size="24" active-color="#ee0a24" @click="checkClick"/>
            <van-switch v-else v-model="elTutti" size="24" active-color="#960213" @click="tuttiCheckClick"/>
          </template>
        </van-cell>
      </van-dropdown-item>
    </van-dropdown-menu>
  </van-cell-group>
</template>

<script>
import { dynamicSortMultiple } from '@/components/helpers.js'
import configurazioni from '@/services/configurazioniService.js'

export default {
  props: {
    elemento: Object,
    minDate: Date,
    name: String
  },
  data() {
    return {
      sourceArray: [],
      elementi: [],
      elSel: {},
      elRag: false,
      elTutti: false
    }
  },
  validations: {
  },
  async mounted() {
    if (typeof this.elemento.source === 'string') {
      const config = this.elemento.source.split('.')
      const configId = config[0]
      const subId = config[1] || '' // TODO verificare
      const sub2 = config[2] || ''
      this.sourceArray = await configurazioni.getConfigurazione(configId, subId)
      if (sub2) {
        // Es. Gestione Categorie o varianti: prevedere un terzo parametro per la source  "source": "prodotti_categorie.categorie.categoria1"
        this.elementi = this.sourceArray ? this.sourceArray.filter(x => x.tipo === sub2).map(x => { return { codice: x.codice, descrizione: x.descrizione } }) : []
      } else {
        this.elementi = this.sourceArray ? this.sourceArray.map(x => { return { codice: x.codice, descrizione: x.descrizione } }) : []
      }
    } else if (Array.isArray(this.elemento.source)) {
      // TODO: Verificare con caso reale
      this.elementi = [...this.elemento.source]
    }
  },
  computed: {
    ddTitle1() {
      return this.elemento.label
    },
    ddTitle2() {
      const numSel = Object.values(this.elSel).filter(x => x === true).length
      let strSel = ': nessun filtro'
      if (numSel === 1) {
        strSel = ': 1 filtro selezionato'
      } else if (numSel > 1) {
        strSel = `: ${numSel} filtri selezionati`
      }
      return strSel
    },
    elementiSorted() {
      const els = this.elementi
      const elSorted = els.sort(dynamicSortMultiple('descrizione'))
      if (elSorted.length > 2) {
        const tutto = { codice: 'tutti', descrizione: '*** TUTTI ***' }
        return [tutto, ...elSorted]
      } else {
        return elSorted
      }
    }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.main-popup-report')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    checkClick(e) {
      e.stopPropagation()
      this.$emit('change', this.elemento.model, this.elSel)
    },
    tuttiCheckClick(e) {
      e.stopPropagation()
      for (const el of this.elementi) {
        this.$set(this.elSel, el.codice, !this.elTutti)
      }
      this.$emit('change', this.elemento.model, this.elSel)
      setTimeout(() => {
        const itm = this.$refs.item
        if (itm) {
          itm.toggle()
        }
      }, 500)
    },
    ragCheckClick(e) {
      e.stopPropagation()
      this.$emit('change', 'rag-' + this.elemento.model, !this.elRag) // verificare come passare il model
    },
    tuttiClick() {
      this.elTutti = !this.elTutti
      for (const el of this.elementi) {
        this.$set(this.elSel, el.codice, this.elTutti)
      }
      this.$emit('change', this.elemento.model, this.elSel)
      setTimeout(() => {
        const itm = this.$refs.item
        if (itm) {
          itm.toggle()
        }
      }, 500)
    },
    cellClick(codice) {
      if (this.elSel[codice] === undefined) {
        this.$set(this.elSel, codice, true)
      } else {
        this.elSel[codice] = !this.elSel[codice]
      }
      this.$emit('change', this.elemento.model, this.elSel)
    }
  }
}
</script>

<style lang="scss">

  .ddTitleClass {
    padding-top: 10px;
    width: 100%;
  }

  .titoloRag {
    position: relative;
    top: -10px; /* compensa il padding 10 su ddTitleClass */
  }

  .titoloSx {
    margin-right:auto;
    margin-left:10px;
  }

  .titoloDx {
    padding-right: 8px;
    float:right;
  }

  .ragSwitch {
    margin-left: 16px;
  }

  .van-dropdown-menu__title::after {
    display:none;
  }
</style>

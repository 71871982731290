<template>
  <van-cell-group>
    <van-cell center title="Raccolta Punti">
      <template #right-icon>
        <van-switch :value="isLocRaccolta" size="24" @change="switchChange"/>
      </template>
    </van-cell>
    <van-cell-group v-if="isLocRaccolta">
      <van-field
        readonly
        clickable
        name="data"
        :value="dallaDataDesc"
        label="Dalla data"
        placeholder="Abilitata dalla data"
        input-align="right"
        @click="showCalendarDalla = true"
      />
      <van-calendar v-model="showCalendarDalla" @confirm="onConfirmDallaData" :show-confirm="false" :default-date="dallaData" :style="getStilePopup()"/>
      <van-field
        readonly
        clickable
        name="data"
        :value="allaDataDesc"
        label="Alla data"
        placeholder="Abilitata alla data"
        input-align="right"
        @click="showCalendarAlla = true"
      />
      <van-calendar v-model="showCalendarAlla" @confirm="onConfirmAllaData" :show-confirm="false" :default-date="allaData" :style="getStilePopup()"/>
      <van-cell @touchstart.native.stop="showTastiera = true" title="Punti totali">
        <van-field :value="punti" size="large" placeholder="Punti" readonly input-align="right">
        </van-field>
      </van-cell>
      <van-number-keyboard
        v-model="punti"
        :show="showTastiera"
        theme="custom"
        extra-key="00"
        close-button-text="OK"
        @blur="showTastiera = false"
      />
<!--       <van-cell title="Importo acquistato" :value="importoAcquistato"/>
      <van-cell title="Importo utilizzato" :value="importoUsato"/>
      <van-cell title="Importo residuo" :value="importoResiduo"/> -->
      <van-cell-group center title="Azzeramento / Ricarica">
        <van-field
          v-model="descAzzeramento"
          name="azzeramento"
          ref="azzeramento"
          label="Descrizione"
          placeholder="Descrizione azzeramento"
        />
        <van-cell @touchstart.native.stop="showTastieraAzzeramento = true" title="Punti residui">
          <van-field :value="puntiResidui" size="large" placeholder="Punti" readonly input-align="right">
          </van-field>
        </van-cell>
        <van-number-keyboard
          v-model="puntiResidui"
          :show="showTastieraAzzeramento"
          theme="custom"
          extra-key="00"
          close-button-text="OK"
          @blur="showTastieraAzzeramento = false"
        />
        <div style="margin: 16px;">
          <van-button type="info" :disabled="eseguiAzzeramentoDisabled" @click="onAzzeramento">Effettua Azzeramento / Ricarica</van-button>
        </div>
        <van-cell v-if="dataAzzeramentoVisible" title="Data operazione" :value="dataAzzeramentoDesc"/>
      </van-cell-group>
    </van-cell-group>
  </van-cell-group>
</template>

<script>
import moment from 'moment'
import modello from './modelli/raccoltaPunti/modello.json'
import { testCardType } from '../cardTypes'

export default {
  mixins: [testCardType],
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
      importo: '',
      isLocRaccolta: this.isRaccoltaPunti,
      showTastiera: false,
      punti: '0',
      raccoltaPunti: null,
      showCalendarDalla: false,
      showCalendarAlla: false,
      dallaData: null,
      allaData: moment().add(5, 'year').utcOffset(0, true).format(),
      showTastieraAzzeramento: false,
      descAzzeramento: '',
      puntiResidui: '',
      azzeramento: null,
      dataAzzeramento: '',
      dataAzzeramentoVisible: false,
      updateTrigger: 1
    }
  },
  mounted() {
    this.allaData = moment().add(5, 'year').utcOffset(0, true).format()
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    buono() {
      return this.card.buono || { importo: 0, dataAcquisto: null }
    },
    carichi() {
      return this.card.buono ? this.card.buono.carichi || [] : []
    },
    utilizzi() {
      return this.card.buono ? this.card.buono.utilizzi || [] : []
    },
    importoAcquistato() {
      return this.card.buono ? (this.card.buono.importo).toFixed(2) : 0
    },
    importoResiduo() {
      return this.card.buono ? this.card.buono.importoResiduo.toFixed(2) : 0
    },
    importoUsato() {
      return this.card.buono ? this.card.buono.importoUsato.toFixed(2) : 0
    },
    importoDesc() {
      return this.importo ? parseFloat(this.importo / 100).toFixed(2) : ''
    },
    dallaDataDesc() {
      if (this.updateTrigger > 0 && this.raccoltaPunti) {
        return this.raccoltaPunti && this.raccoltaPunti.dallaData ? moment(this.raccoltaPunti.dallaData).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
      } else {
        return this.card.raccoltaPunti && this.card.raccoltaPunti.dallaData && moment(this.card.raccoltaPunti.dallaData).isValid() ? moment(this.card.raccoltaPunti.dallaData).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
      }
    },
    allaDataDesc() {
      if (this.updateTrigger > 0 && this.raccoltaPunti) {
        return this.raccoltaPunti && this.raccoltaPunti.allaData ? moment(this.raccoltaPunti.allaData).format('DD/MM/YYYY') : moment().add(5, 'year').format('DD/MM/YYYY')
      } else {
        return this.card.raccoltaPunti && this.card.raccoltaPunti.allaData && moment(this.card.raccoltaPunti.allaData).isValid() ? moment(this.card.raccoltaPunti.allaData).format('DD/MM/YYYY') : moment().add(5, 'year').format('DD/MM/YYYY')
      }
      // return this.card.raccoltaPunti && moment(this.card.raccoltaPunti.allaData).isValid() ? moment(this.card.raccoltaPunti.allaData).format('DD/MM/YYYY') : ''
    },
    dataAzzeramentoDesc() {
      return moment(this.dataAzzeramento).isValid() ? moment(this.dataAzzeramento).format('DD/MM/YYYY') : ''
    },
    eseguiAzzeramentoDisabled() {
      return !this.descAzzeramento || !this.puntiResidui
    }
  },
  methods: {
    getStilePopup() {
      return 'height: "50%";'
    },
    titolo(item) {
      return this.$options.filters.date(item.data)
    },
    label(item) {
      return item.fiscale && item.fiscale.barcode ? `Scontrino: ${item.fiscale.barcode.documento}` : ''
    },
    value(item) {
      return item.importo
    },
    switchChange(value) {
      this.raccoltaPunti = this.raccoltaPunti || {}
      this.raccoltaPunti.abilita = value
      this.isLocRaccolta = value
    },
    onConfirmDallaData(value) {
      this.dallaData = value //  moment(value).format('DD/MM/YYYY')
      this.raccoltaPunti.dallaData = value
      this.showCalendarDalla = false
      this.updateTrigger++
    },
    onConfirmAllaData(value) {
      this.allaData = value // moment(value).format('DD/MM/YYYY')
      this.raccoltaPunti.allaData = value
      this.showCalendarAlla = false
      this.updateTrigger++
    },
    async onAzzeramento() {
      try {
        const pt = parseInt(this.card.raccoltaPunti.totale)
        const pr = parseInt(this.puntiResidui)
        const title = pt > pr ? 'Azzeramento fidelity card' : 'Ricarica fidelity card'
        const message = pt > pr ? 'Confermi azzeramento Fidelity Card ?' : 'Confermi ricarica Fidelity Card ?'
        await this.$dialog.confirm({ title, message })
        this.azzeramento = {
          data: new Date(),
          punti: pt,
          puntiResidui: pr,
          puntiUtilizzati: pt - pr,
          descrizione: this.descAzzeramento
        }
        this.dataAzzeramento = new Date()
        this.dataAzzeramentoVisible = true
        this.punti = String(pr)
        this.$emit('forza-salvataggio')
        // ...
      } catch {}
    },
    onOpen() {
      this.isLocRaccolta = this.isRaccoltaPunti
      this.raccoltaPunti = this.isRaccoltaPunti ? this.card.raccoltaPunti : modello

      this.dallaData = this.isRaccoltaPunti && moment(this.card.raccoltaPunti.dallaData).isValid() ? moment(this.card.raccoltaPunti.dallaData).utcOffset(0, true).toDate() : new Date()
      this.allaData = this.isRaccoltaPunti && moment(this.card.raccoltaPunti.allaData).isValid() ? moment(this.card.raccoltaPunti.allaData).utcOffset(0, true).toDate() : moment().add(5, 'year').utcOffset(0, true).toDate()
      this.punti = this.isRaccoltaPunti && this.card.raccoltaPunti.totale ? String(this.card.raccoltaPunti.totale) : ''
      this.updateTrigger++
    },
    getValori() { // TODO: Il carico di una raccoltaPunti potrebbe richiamare una funzione diversa per evitare di salvare sempre la fidelity anche se non serve
      if (!this.isRaccoltaPunti && !this.isLocRaccolta) {
        return {}
      }
      const valori = {
        data: {
          raccoltaPunti: {
            abilita: true,
            dallaData: this.dallaData,
            allaData: this.allaData,
            totale: parseInt(this.punti || 0),
            dataAzzeramento: this.dataAzzeramento,
            azzeramenti: [] // verificare se _.merge gestisce gli array
          }
        }
      }
      if (this.azzeramento) {
        valori.azzeramento = this.azzeramento
      }
      return valori
    }
    /*     inviaRicarica() {
      const event = {
        type: 'RICARICA_FIDELITY_CARD',
        value: {
          comandi: {
            caricaReparto: this.importo
          }
        }
      }
      this.$emit('send-event', event)
    } */
  }
}
</script>

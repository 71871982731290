'use strict'
import _ from 'lodash'
import moment from 'moment'
import stampante from '@/services/stampanteService.js'
import contabili from '@/services/contabiliService.js'

const cassaMovimenti = async (context, event, operatore, postazione, valore) => {
  if (valore || (context.displayValue !== '0' && context.displayValue !== '')) {
    const importo = valore && valore > 0 ? valore : +(parseFloat(context.displayValue) / 100).toFixed(2)
    const descrizione = context.inputDescrizione || ''
    const operazione = context.cassaOperazione || event.value
    context.cassaOperazione = ''
    switch (operazione) {
      case 'FONDOCASSA':
        await fondocassa({ importo, operatore, postazione })
        break
      case 'PRELIEVO':
        await prelievo({ importo, operatore, descrizione, postazione })
        break
      case 'VERSAMENTO':
        await versamento({ importo, operatore, descrizione, postazione })
        break
      case 'VINCITA':
        await prelievo({ importo, operatore, descrizione, postazione })
        break
      default:
        throw new Error('Comando non riconosciuto')
    }
    if (_.get(context, 'impostazioni.cassa.contabili.ricevuta.stampa')) {
      const layout = _.get(context, 'impostazioni.cassa.contabili.ricevuta.layout', {})
      const scontrinoInfo = []
      if (layout.apertura) {
        scontrinoInfo.push(layout.apertura)
      }

      let info = `Operazione: ${operazione}`
      scontrinoInfo.push(info)
      info = `Data: ${moment().format('DD/MM/YYYY HH:MM')}`
      scontrinoInfo.push(info)
      info = `Importo: ${importo.toFixed(2)} €`
      scontrinoInfo.push(info)
      if (descrizione) {
        info = `Causale: ${descrizione}`
        scontrinoInfo.push(info)
      }
      if (layout.chiusura) {
        scontrinoInfo.push(layout.chiusura)
      }
      const barcode = null
      try {
        await stampante.stampaScontrinoGestionale(scontrinoInfo, barcode)
      } catch {}
    }
  } else {
    throw new Error('Nessun importo selezionato')
  }
}

const fondocassa = async function(parametri) {
  await creaOperazione('fondocassa', parametri)
}

const versamento = async function(parametri) {
  await creaOperazione('versamento', parametri)
}

const prelievo = async function(parametri) {
  await creaOperazione('prelievo', parametri)
}

const creaOperazione = async function(tipo, parametri) {
  const operazione = {
    tipo,
    data: new Date(),
    ...parametri
  }
  // TODO: Separare su modulo contabilità (nuovo). In effetti i movimenti sono contabili e non di magazzino
  await contabili.creaOperazioniCassa(operazione)
}

const getMovimentiContabili = async function(periodo) {
  const elenco = await contabili.getMovimentiContabili(periodo)
  const fc = elenco.filter(x => x.tipo === 'fondocassa')
  const fondocassa = _.last(_.sortBy(fc, '_id'))
  const prelievi = _.sumBy(elenco.filter(x => x.tipo === 'prelievo'), 'importo')
  const versamenti = _.sumBy(elenco.filter(x => x.tipo === 'versamento'), 'importo')
  const movimenti = {
    fondocassa: (fondocassa && fondocassa.importo) || 0.0,
    prelievi: prelievi || 0.0,
    versamenti: versamenti || 0.0
  }
  return movimenti
}

export default {
  cassaMovimenti,
  fondocassa,
  versamento,
  prelievo,
  getMovimentiContabili
}

<template>
  <van-popup
    :value="show"
    position="right"
    :close-on-click-overlay="true"
    :closeable="true"
    close-icon="close"
    close-icon-position="top-left"
    :class="'PopupCercaBuono'"
    @click-close-icon="closePopup"
    @click-overlay="closePopup"
    @open="onOpen"
    >
    <van-tabs v-model="activeName" sticky>
      <van-tab title="Dettaglio" name="dettaglio" v-if="buonoCaricato">
        <van-cell-group>
          <van-cell title="Tipo" :value="tipoBuono === 'buonomultiuso' ? 'Multiuso' : 'Monouso'"/>
          <van-cell :value="card.codice" :label="'Secure Code: ' + card.secureCode" >
            <template #title>
              <span class="buono-title">Codice</span>
              <van-tag type="danger" v-if="card.annullamento">Annullato</van-tag>
            </template>
          </van-cell>
          <van-cell title="Importo acquistato" :value="importo"/>
          <van-cell title="Importo utilizzato" :value="importoUsato"/>
          <van-cell title="Importo residuo" :value="importoResiduo"/>
          <van-cell title="Scontrino acquisto" :value="scontrinoAcquisto"/>
          <van-cell title="Data acquisto" :value="dataAcquisto"/>
          <van-cell title="Data annullamento" :value="dataAnnullo" v-if="dataAnnullo"/>
          <van-cell-group title="Utilizzi buono">
            <van-list>
              <van-cell
                v-for="(el, index) in utilizzi"
                :key="index"
                :border="true"
                :title="titoloUtilizzi(el)"
                :label="labelUtilizzi(el)"
                :value="valueUtilizzi(el)"
                icon="cart-o"
                size="large"
              />
            </van-list>
          </van-cell-group>
        </van-cell-group>
        <van-cell
          v-if="buonoCaricato"
          :border="true"
          title="Annulla selezione buono"
          icon="coupon"
          size="large"
          @click="noBuono"
        ></van-cell>
      </van-tab>
      <van-tab title="Cerca" name="cerca">
        <van-cell-group>
          <van-cell @touchstart.native.stop="showTastiera = true; maxLen = 9; tipoRicerca = 0" title="Cerca per scontrino acquisto" label="dove il buono è stato acquistato" maxlength="9"><van-field  v-model="numberValue" v-show="tipoRicerca == 0" placeholder="numero documento fiscale" autofocus="true" clearable @keydown.enter="cercaBuoni"/></van-cell>
          <van-cell @touchstart.native.stop="showTastiera = true; maxLen = 9; tipoRicerca = 1" title="Cerca per scontrino vendita" label="dove il buono è stato utilizzato" maxlength="9"><van-field  v-model="numberValue" v-show="tipoRicerca == 1" placeholder="numero documento fiscale" autofocus="true" clearable @keydown.enter="cercaBuoni"/></van-cell>
          <van-cell @touchstart.native.stop="showTastiera = true; maxLen = 13; tipoRicerca = 2" title="Cerca per numero" label="barcode buono" maxlength="13"><van-field v-model="numberValue" size="large" v-show="tipoRicerca == 2" placeholder="numero buono" autofocus="true" clearable @keydown.enter="cercaBuoni"/></van-cell>
          <van-cell @touchstart.native.stop="showTastiera = true; maxLen = 4; tipoRicerca = 3" title="Cerca per secure code" maxlength="4"><van-field v-model="numberValue" size="large" error v-show="tipoRicerca == 3" placeholder="secure code" autofocus="true" clearable/></van-cell>
          <van-cell @touchstart.native.stop="showTastiera = false; tipoRicerca = -1; cercaBuoni()" title="Elenco per data" label="Richiama gli ultimi 50 buoni emessi">
              <template #right-icon><van-icon name="search" class="search-icon" /></template>
          </van-cell>
          <van-number-keyboard
            v-model="numberValue"
            :maxlength="maxLen"
            :show="showTastiera"
            theme="custom"
            extra-key="-"
            close-button-text="Cerca"
            @blur="showTastiera = false"
            @close="cercaBuoni"
          />
          <van-swipe-cell :before-close="beforeClose" v-for="(el, index) in buoni" :key="index">
            <template #left>
              <van-button square color="#5badfa" text="Seleziona" class="swipe-cell-button testo-nero" @click="clickSeleziona(el)"/>
              <van-button square color="#00ee00" text="Ristampa" class="swipe-cell-button testo-nero" @click="clickStampa(el)"/>
            </template>
            <van-cell
              :border="true"
              :label="label(el)"
              icon="coupon-o"
              size="large"
            >
              <template #title>
                <span class="buono-title">{{titolo(el)}}</span>
                <van-tag type="danger" v-if="el.annullamento">Annullato</van-tag>
              </template>
            </van-cell>
            <template #right v-if="!el.annullamento">
              <van-button square color="#ff4949" text="Cancella" class="swipe-cell-button" @click="clickAnnulla(el)"/>
            </template>
          </van-swipe-cell>
        </van-cell-group>
      </van-tab>
    </van-tabs>
  </van-popup>
</template>

<script>
import _ from 'lodash'
import fidelity from '@/services/fidelityService.js'
import scontrini from '@/services/scontriniService.js'
import configurazioni from '@/services/configurazioniService.js'

export default {
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      tipoRicerca: 0,
      activeName: '',
      ricerca: false,
      showTastiera: false,
      numberValue: '',
      maxLen: 0,
      buoni: [],
      locBuono: null
      // prefisso: ''
    }
  },
  async mounted() {
    // var barcode = await configurazioni.getBarcodeInfo('buonomultiuso')
    /*     var barcode = this.tipoBuono ? await configurazioni.getBarcodeInfo(this.tipoBuono) : {}
    this.prefisso = barcode.prefisso */
  },
  computed: {
    tipoBuono() {
      if (this.context.buonoMultiuso) return 'buonomultiuso'
      if (this.context.buonoMonouso) return 'buonomonouso'
      return null
    },
    card() {
      return this.context.buonoMultiuso || this.context.buonoMonouso || this.locBuono || {}
    },
    buono() {
      return this.card.buono || { importo: 0, dataAcquisto: null }
    },
    utilizzi() {
      return this.card.buono ? this.card.buono.utilizzi || [] : []
    },
    buonoCaricato() {
      return this.card.buono
    },
    importo() {
      return this.card.buono ? (this.card.buono.importo || 0).toFixed(2) : 0
    },
    importoResiduo() {
      return this.card.buono ? (this.card.buono.importoResiduo || 0).toFixed(2) : 0
    },
    importoUsato() {
      return this.card.buono ? (this.card.buono.importoUsato || 0).toFixed(2) : 0
    },
    dataAcquisto() {
      // return this.card.buono ? this.$options.filters.date(this.card.buono.dataAcquisto) : ''
      return this.card.buono && this.card.buono.carichi && this.card.buono.carichi.length > 0 ? this.$options.filters.date(this.card.buono.carichi[0].data) : ''
    },
    scontrinoAcquisto() {
      // return this.card.infoAcquisto ? this.card.infoAcquisto.barcodeScontrino.documento : ''
      // this.card.buono && this.card.buono.carichi && this.card.buono.carichi[0].fiscale && this.card.buono.carichi[0].fiscale.barcode ? this.card.buono.carichi[0].fiscale.barcode.documento : ''
      return _.get(this.card, 'buono.carichi[0].fiscale.barcode.documento', '')
    },
    dataAnnullo() {
      return this.card.annullamento && this.card.annullamento.data ? this.$options.filters.date(this.card.annullamento.data) : ''
    }
  },
  methods: {
    async prefisso() {
      var barcode = this.tipoBuono ? await configurazioni.getBarcodeInfo(this.tipoBuono) : {}
      return barcode.prefisso || ''
    },
    async ricercaIniziale() {
      this.tipoRicerca = 99
      await this.cercaBuoni()
      this.tipoRicerca = 0
    },
    async onOpen() {
      if (!this.context.buonoMultiuso && !this.context.buonoMonouso) {
        this.activeName = 'cerca'
        await this.ricercaIniziale()
      } else {
        this.activeName = 'dettaglio'
        await this.refresh()
      }
    },
    async refresh() {
      this.maxLen = 13
      this.tipoRicerca = 2
      await this.cercaBuoni()
    },
    closePopup() {
      this.$emit('close')
    },
    async cercaBuoni() {
      let result = []
      switch (this.tipoRicerca) {
        case 0:
          result = await fidelity.cercaFidelityByPurchaseTicket(this.numberValue, this.maxLen)
          break
        case 1:
          result = await fidelity.cercaFidelityByTicket(this.numberValue, this.maxLen)
          break
        case 2:
          result = await fidelity.cercaFidelityById(this.numberValue || this.prefisso, this.maxLen)
          break
        case 3:
          result = await fidelity.cercaFidelityByCode(this.numberValue, this.maxLen)
          break
        default:
          result = await fidelity.elencoFidelityPerData(null, new Date())
          break
      }
      this.buoni = result
    },
    titoloUtilizzi(item) {
      return this.$options.filters.date(item.data)
    },
    labelUtilizzi(item) {
      return item.fiscale ? `Scontrino: ${_.get(item.fiscale, 'barcode.documento', '')}` : ''
      //  item.fiscale && item.fiscale.barcode && item.fiscale.barcode.documento || ''
    },
    valueUtilizzi(item) {
      return item.importo
    },
    titolo(item) {
      return item.codice + '  :  ' + this.$options.filters.euro(item.buono.importoResiduo || 0)
    },
    label(item) {
      const importo = this.$options.filters.euro(item.buono.importo)
      const data = this.$options.filters.date(item.buono.dataAcquisto)
      return `Importo: ${importo} - acquisto: ${data} - code: ${item.secureCode}`
    },
    value(item) {
      return this.$options.filters.euro(item.buono.importoResiduo)
    },
    clickAnnulla(item) {
      this.$dialog.confirm({
        message: 'Confermi cancellazione buono ?',
        cancelButtonText: 'NO',
        confirmButtonText: 'SI'
      }).then(() => {
        const event = {
          type: 'ANNULLA_BUONO_MULTIUSO',
          value: {
            code: item.codice
          }
        }
        this.$emit('send-event', event)
      })
    },
    clickSeleziona(item) {
      if (!item.annullamento) {
        const event = {
          type: 'BUONO_MULTIUSO',
          value: {
            code: item.codice
          }
        }
        this.$emit('send-event', event)
        this.locBuono = null
      } else {
        this.locBuono = item
      }
      this.activeName = 'dettaglio'
    },
    async clickStampa(item) {
      if (!item.annullamento) {
        const idScontrino = item.buono.carichi[0].fiscale.idScontrino
        const scontrino = await scontrini.getScontrinoById(idScontrino)
        try {
          const event = {
            type: 'RISTAMPA_BUONO',
            value: { scontrino }
          }
          this.$emit('send-event', event)
          this.locBuono = null
        } catch {}
      }
    },
    noBuono() {
      const event = {
        type: 'BUONO_MULTIUSO',
        value: {
          item: null
        }
      }
      this.$emit('send-event', event)
      this.locBuono = null
      this.activeName = 'cerca'
    },
    beforeClose({ position, instance }) {
      switch (position) {
        case 'left':
        case 'cell':
        case 'outside':
          instance.close()
          break
        case 'right':
          instance.close()
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../popup.scss";
  @import "./style.scss";

  .swipe-cell-button {
    height: 100%;
  }

  [name = PopupCercaBuono] {
    padding-top: 60px;
  }

  .buono-title {
    margin-right: 10px;;
  }
  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .PopupCercaBuono {
      width: 45%;
      height: 70%;
    }
  }
  @media all and (min-width:1024px) {
    .PopupCercaBuono {
      width: 45%;
      height: 90%;
    }
  }
  @media all and (min-width:1400px) {
    .PopupCercaBuono {
      width: 25%;
      height: 90%;
    }
  }

  .search-icon {
    font-size: 16px;
    line-height: inherit;
  }

  .testo-nero {
    color: black!important;
  }

</style>

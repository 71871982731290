<template>
  <van-cell-group title="Prepagata">
    <van-cell title="Importo acquistato" :value="importo"/>
    <van-cell title="Importo utilizzato" :value="importoUsato"/>
    <van-cell title="Importo residuo" :value="importoResiduo"/>
    <van-cell-group title="Carichi prepagata">
      <van-list>
        <van-cell
          v-for="(el, index) in carichi"
          :key="index"
          :border="true"
          :title="titolo(el)"
          :label="label(el)"
          :value="value(el)"
          icon="paid"
          size="large"
        />
      </van-list>
    </van-cell-group>
    <van-cell-group title="Utilizzi prepagata">
      <van-list>
        <van-cell
          v-for="(el, index) in utilizzi"
          :key="index"
          :border="true"
          :title="titolo(el)"
          :label="label(el)"
          :value="value(el)"
          icon="cart-o"
          size="large"
        />
      </van-list>
    </van-cell-group>
  </van-cell-group>
</template>

<script>
export default {
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
    }
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    buono() {
      return this.card.buono || { importo: 0, dataAcquisto: null }
    },
    carichi() {
      return this.card.buono ? this.card.buono.carichi || [] : []
    },
    utilizzi() {
      return this.card.buono ? this.card.buono.utilizzi || [] : []
    },
    importo() {
      return this.card.buono ? (this.card.buono.importo).toFixed(2) : 0
    },
    importoResiduo() {
      return this.card.buono ? this.card.buono.importoResiduo.toFixed(2) : 0
    },
    importoUsato() {
      return this.card.buono ? this.card.buono.importoUsato.toFixed(2) : 0
    }
  },
  methods: {
    titolo(item) {
      return this.$options.filters.date(item.data)
    },
    label(item) {
      return item.fiscale && item.fiscale.barcode ? `Scontrino: ${item.fiscale.barcode.documento}` : ''
    },
    value(item) {
      return item.importo
    }
  }
}
</script>

<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" @salva="salva" :campi="campi" :config="config"></modale-form>
</template>

<script>
import ModaleForm from '../ModaleForm'
import prodotti from '@/services/prodottiService.js'
import configurazioni from '@/services/configurazioniService.js'
import errorService from '@/services/errorService'
import clienti from '@/services/clientiService.js'
import { cloneObject, dynamicSortMultiple, creaCampoRicercaTC } from '@/components/helpers'
import * as dot from 'dot-object'
import _ from 'lodash'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      prodottoInModifica: null,
      campi: [],
      config: {
        disableEsciOnClick: true,
        prodottoDefault: {
          codice: '',
          descrizione: '',
          marchio: '',
          ricerca: '',
          ricercaBarcode: '',
          ricercaTC: '',
          tipoBene: '',
          tipoItem: '',
          resoConsentito: true,
          barcode: [],
          fornitori: [],
          note: [],
          provvigioni: [],
          tracciabilita: [],
          datiFiscali: {
            aliquoteIva: [],
            reparto: {}
          },
          immagine: {},
          magazzino: {},
          prezzo: {},
          statistici: {},
          ultimoAcquisto: {},
          varianti: {}
        }
      }
    }
  },
  async mounted() {
    try {
      this.aliquoteIva = await configurazioni.getConfigurazione('prodotti_aliquote_iva', 'aliquote')
      this.repartiFiscali = await configurazioni.getConfigurazione('prodotti_reparti_fiscali', 'reparti')
      this.categorie = await configurazioni.getConfigurazione('prodotti_categorie', 'categorie')
      this.labelCategorie = await configurazioni.getConfigurazione('prodotti_categorie', 'labelCategorie')
      this.varianti = await configurazioni.getConfigurazione('prodotti_varianti', 'varianti')
      this.labelVarianti = await configurazioni.getConfigurazione('prodotti_varianti', 'labelVarianti')
      this.listini = await configurazioni.getConfigurazione('listini', 'listini')
      this.fornitori = await clienti.searchFornitori('')
      // AGGIUNGERE FORNITORI
    } catch {
      errorService.onError('Tabelle di base non trovate', 'mounted nuovo prodotto')
    }

    console.log(this.fornitori)
    this.fornitori = this.fornitori.docs.map(el => { return { codice: el.codice, descrizione: el.ragioneSociale } })

    var campi = [
      { campo: 'codice', model: 'codice', tipo: 'text', label: 'Codice', placeHolder: 'Inserire Codice..', validations: [{ nome: 'required' }] },
      { campo: 'descrizione', model: 'descrizione', tipo: 'text', label: 'Descrizione', placeHolder: 'Inserire Descrizione..', validations: [{ nome: 'required' }] },
      { campo: 'marchio', model: 'marchio', tipo: 'text', label: 'Marchio', placeHolder: 'Inserire Marchio..', validations: [{ nome: 'required' }] },
      { campo: 'barcode1', model: 'nuovoBarcode.barcode1', tipo: 'text', label: 'Barcode 1', placeHolder: 'Inserire Barcode 1..' },
      { campo: 'btnBarcode1', tipo: 'button', label: 'Genera Barcode', onUpdateValue: this.creaBarcode1 },
      { campo: 'qtaBarcode1', model: 'nuovoBarcode.qta1', tipo: 'numeric', label: 'Qta Barcode 1', numeric: { float: true }, placeHolder: 'Inserire Qta 1..' },
      { campo: 'barcode2', model: 'nuovoBarcode.barcode2', tipo: 'numeric', label: 'Barcode 2', placeHolder: 'Inserire Barcode 2..' },
      { campo: 'btnBarcode2', tipo: 'button', label: 'Genera Barcode', onUpdateValue: this.creaBarcode2 },
      { campo: 'qtaBarcode2', model: 'nuovoBarcode.qta2', tipo: 'numeric', label: 'Qta Barcode 2', numeric: { float: true }, placeHolder: 'Inserire Qta 2..' },
      { campo: 'tipoItem', model: 'tipoItem', tipo: 'select', label: 'Tipo Item', dataSource: 'prodotti_tipi_item.tipi', placeHolder: 'Digitare per filtrare..', saveFormat: 'codice', validations: [{ nome: 'required' }] },
      { campo: 'tipoBene', model: 'tipoBene', tipo: 'select', label: 'Tipo Bene', dataSource: 'prodotti_tipi_bene.tipi', placeHolder: 'Digitare per filtrare..', saveFormat: 'codice', validations: [{ nome: 'required' }] },
      { campo: 'repartoFiscale', model: 'nuovoRepartoFiscale', tipo: 'select', label: 'Reparto Fiscale', dataSource: this.repartiFiscali, placeHolder: 'Digitare per filtrare..', saveFormat: 'codice', validations: [{ nome: 'required' }] },
      // { campo: 'repartoFiscale', model: 'datiFiscali.reparto', tipo: 'select', label: 'Reparto Fiscale', dataSource: this.repartiFiscali, placeHolder: 'Digitare per filtrare..', saveFormat: 'codice', validations: [{ nome: 'required' }], valore: repartoFiscaleSelezionato },
      { campo: 'fornitore', model: 'nuovoFornitore', tipo: 'select', label: 'Fornitore', dataSource: this.fornitori, placeHolder: 'Digitare per filtrare..', saveFormat: 'object', validations: [{ nome: 'required' }] },
      { campo: 'unitaMisura', model: 'magazzino.unitaMisura', tipo: 'select', label: 'Unità di Misura', dataSource: 'prodotti_unita_misura.um', placeHolder: 'Digitare per filtrare..', saveFormat: 'object', validations: [{ nome: 'required' }] },
      { campo: 'peso', model: 'magazzino.peso', tipo: 'numeric', label: 'Peso', numeric: { float: true }, placeHolder: 'Peso', validations: [] },
      { campo: 'categoria1', model: 'statistici.categoria1', tipo: 'select', label: this.labelCategoria('categoria1'), dataSource: this.columnsCategoria('categoria1'), placeHolder: 'Digitare per filtrare..', saveFormat: 'object', hidden: !this.categoriaVisibile('categoria1') },
      { campo: 'categoria2', model: 'statistici.categoria2', tipo: 'select', label: this.labelCategoria('categoria2'), dataSource: this.columnsCategoria('categoria2'), placeHolder: 'Digitare per filtrare..', saveFormat: 'object', hidden: !this.categoriaVisibile('categoria2') },
      { campo: 'categoria3', model: 'statistici.categoria3', tipo: 'select', label: this.labelCategoria('categoria3'), dataSource: this.columnsCategoria('categoria3'), placeHolder: 'Digitare per filtrare..', saveFormat: 'object', hidden: !this.categoriaVisibile('categoria3') },
      { campo: 'categoria4', model: 'statistici.categoria4', tipo: 'select', label: this.labelCategoria('categoria4'), dataSource: this.columnsCategoria('categoria4'), placeHolder: 'Digitare per filtrare..', saveFormat: 'object', hidden: !this.categoriaVisibile('categoria4') },
      { campo: 'variante1', model: 'varianti.variante1', tipo: 'select', label: this.labelVariante('variante1'), dataSource: this.columnsVariante('variante1'), placeHolder: 'Digitare per filtrare..', saveFormat: 'object', hidden: !this.varianteVisibile('variante1') },
      { campo: 'variante2', model: 'varianti.variante2', tipo: 'select', label: this.labelVariante('variante2'), dataSource: this.columnsVariante('variante2'), placeHolder: 'Digitare per filtrare..', saveFormat: 'object', hidden: !this.varianteVisibile('variante2') },
      { campo: 'variante3', model: 'varianti.variante3', tipo: 'select', label: this.labelVariante('variante3'), dataSource: this.columnsVariante('variante3'), placeHolder: 'Digitare per filtrare..', saveFormat: 'object', hidden: !this.varianteVisibile('variante3') },
      { campo: 'variante4', model: 'varianti.variante4', tipo: 'select', label: this.labelVariante('variante4'), dataSource: this.columnsVariante('variante4'), placeHolder: 'Digitare per filtrare..', saveFormat: 'object', hidden: !this.varianteVisibile('variante4') },
      { campo: 'intestazionePrezzi', tipo: 'intestazione', label: 'Prezzi', readonly: true }
    ]

    for (let i = 0; i < this.listini.length; i++) {
      const list = this.listini[i]
      campi.push({ campo: list.codice, model: 'prezzo.' + list.codice + '.base', tipo: 'numeric', label: list.codice, numeric: { float: true }, placeHolder: list.descrizione, validations: [] })
    }

    const configModale = await configurazioni.getConfigurazione('display_elementi', 'elementi')

    if (configModale && configModale.prodotto) {
      if (this.context.nuovoProdotto && this.context.nuovoProdotto.prodottoInModifica) {
        // const campi = configModale.clienti.modifica ? configModale.clienti.modifica.campi || [] : []
        this.config = configModale.prodotto.modifica ? configModale.prodotto.modifica.modale || {} : this.config

        // this.prodottoInModifica = await prodotto.getCliente(this.context.nuovoProdotto.prodottoInModifica.codice)
        this.prodottoInModifica = this.context.nuovoProdotto.prodottoInModifica
        // prepara dati iniziali
        for (var i = 0; i < campi.length; i++) {
          var c = campi[i]
          if (c.model) {
            var val = dot.pick(c.model, this.prodottoInModifica)
            c.valore = val
          }
        }

        this.prodottoInModifica.datiFiscali && this.prodottoInModifica.datiFiscali.reparto &&
          (campi.find(obj => obj.campo === 'repartoFiscale').valore = this.prodottoInModifica.datiFiscali.reparto.codice || null)

        if (this.prodottoInModifica.fornitori && this.prodottoInModifica.fornitori.length) {
          campi.find(obj => obj.campo === 'fornitore').valore = this.prodottoInModifica.fornitori[0]
        }

        if (this.prodottoInModifica.barcode && this.prodottoInModifica.barcode.length) {
          var bAttivi = this.prodottoInModifica.barcode.filter(x => x.attivo)

          if (bAttivi[0]) {
            campi.find(obj => obj.campo === 'barcode1').valore = bAttivi[0].codice
            campi.find(obj => obj.campo === 'qtaBarcode1').valore = bAttivi[0].quantita || 1
          }

          if (bAttivi[1]) {
            campi.find(obj => obj.campo === 'barcode2').valore = bAttivi[1].codice
            campi.find(obj => obj.campo === 'qtaBarcode2').valore = bAttivi[1].quantita || 1
          }
        }
      } else {
        // this.campi = configModale.prodotto.nuovo ? configModale.prodotto.nuovo.campi || [] : []
        this.config = configModale.prodotto.nuovo ? configModale.prodotto.nuovo.modale || {} : this.config
        cloneObject(this.config.prodottoDefault)
      }
    }

    this.campi = campi
  },
  computed: {
    titoloModale() {
      return this.prodottoInModifica ? 'Modifica prodotto: ' + this.prodottoInModifica.descrizione : 'Nuovo prodotto'
    }
  },
  methods: {
    onKeyPress(button) {
      this.button = button
    },
    annulla() {
      const event = {
        type: 'EDIT_CONTEXT',
        payload: {
          nuovoProdotto: null
        }
      }
      this.$emit('send-event', event)
      this.$emit('close')
    },
    async salva() {
      var form = this.$refs.form
      var err = form.valida()
      var result
      if (err && err.length) {
        return
      }
      var dati = form.getDatiDotNotation()
      var prodotto
      var datiProdotto
      if (this.prodottoInModifica) { // modifica prodotto
        datiProdotto = cloneObject(this.prodottoInModifica)
        _.merge(datiProdotto, dati)
        console.log(datiProdotto)
        prodotto = this.buildProdotto(datiProdotto)
        // this.$store.commit('prodotti/SET_PRODOTTO', prodotto)
        result = await prodotti.aggiornaProdotto(prodotto)
        console.log(result)
        this.$toast('Prodotto modificato con successo', { type: 'success' })
      } else { // nuovo prodotto
        datiProdotto = cloneObject(this.config.prodottoDefault)
        _.merge(datiProdotto, dati)
        prodotto = this.buildProdotto(datiProdotto)
        // check codice esistente
        var codiceTC = creaCampoRicercaTC(prodotto)
        this.$toast('Salvataggio in corso.. attendere', { type: 'success' })
        var prodottiRicerca = await prodotti.searchProdotti(codiceTC)
        if (prodottiRicerca && prodottiRicerca.docs && prodottiRicerca.docs.length) {
          for (var i = 0; i < prodottiRicerca.docs.length; i++) {
            if (prodottiRicerca.docs[i].ricercaTC === codiceTC) {
              this.$toast('ATTENZIONE!! Prodotto con il codice "' + codiceTC + '" gia\' esistente', { type: 'danger' })
              return
            }
          }
        }
        result = await prodotti.creaProdotto(prodotto)
        this.$toast('Prodotto creato con successo', { type: 'success' })
        console.log(prodotto)
      }
      var self = this
      setTimeout(() => {
        self.annulla()
      }, 200)
    },
    buildProdotto(pr) {
      var rF
      // if (pr.datiFiscali && pr.datiFiscali.reparto && (rF = this.repartiFiscali.find(x => x.codice === pr.datiFiscali.reparto))) {
      if (pr.nuovoRepartoFiscale && (rF = this.repartiFiscali.find(x => x.codice === pr.nuovoRepartoFiscale))) {
        // pr.datiFiscali.reparto = rF.codice
        pr.datiFiscali.reparto = {
          codice: rF.codice,
          descrizione: rF.descrizione,
          stampante: rF.stampante.numero
        }
        // Teoricamente l'iva nel prodotto non serve perchè è legata al reparto fiscale, però nel programma viene utilizzata --> la inserisco.
        // TODO: Verificare se togliere (attenzione se, nel tempo, cambio associazione tra reparto fiscale e iva, si perde lo storico)
        const aliquotaIva = this.aliquoteIva.find(x => x.codice === rF.codiceIva)
        if (aliquotaIva) {
          pr.datiFiscali.aliquoteIva = pr.datiFiscali.aliquoteIva.filter(x => x.codice !== aliquotaIva.codice)
          const iva = {
            codice: aliquotaIva.codice,
            valore: aliquotaIva.aliquota,
            descrizione: aliquotaIva.descrizione,
            tipoApplicazione: 'banco' // TODO: verificare come gestire i vari tipi applicazione
          }
          pr.datiFiscali.aliquoteIva.push(iva)
        }
      }
      if (pr.nuovoFornitore && pr.nuovoFornitore.codice) { // prendo sempre il primo fornitore come modifica
        if (pr.fornitori[0]) {
          pr.fornitori[0] = pr.nuovoFornitore
        } else {
          pr.fornitori.push(pr.nuovoFornitore)
        }
        // pr.fornitori = pr.fornitori.filter(x => x.codice !== pr.fornitore)
        // const fornitore = {
        //   codice: this.fornitore,
        //   descrizione: this.fornitoreDescrizione
        // }
        // pr.fornitori.push(pr.fornitore) // pr.fornitore e' gia un oggetto codice e descr
        delete pr.nuovoFornitore
      }

      if (pr.nuovoBarcode) {
        var currBarcodeAttivi = pr.barcode.filter(x => x.attivo)

        if (pr.nuovoBarcode.barcode1) {
          if (currBarcodeAttivi[0]) {
            currBarcodeAttivi[0].codice = pr.nuovoBarcode.barcode1
            currBarcodeAttivi[0].quantita = pr.nuovoBarcode.qta1 || 1
          } else {
            pr.barcode.push({ codice: pr.nuovoBarcode.barcode1, attivo: true, da: new Date(), quantita: pr.nuovoBarcode.qta1 || 1 })
          }
        } else if (currBarcodeAttivi[0]) { // se avevo un elemento ma il barcode1 e' stato cancellato, lo rimuovo dall'array
          currBarcodeAttivi.splice(0, 1)
        }

        if (pr.nuovoBarcode.barcode2) {
          if (currBarcodeAttivi[1]) {
            currBarcodeAttivi[1].codice = pr.nuovoBarcode.barcode2
            currBarcodeAttivi[1].quantita = pr.nuovoBarcode.qta2 || 1
          } else {
            pr.barcode.push({ codice: pr.nuovoBarcode.barcode2, attivo: true, da: new Date(), quantita: pr.nuovoBarcode.qta2 || 1 })
          }
        } else if (currBarcodeAttivi[1]) { // se avevo un elemento ma il barcode2 e' stato cancellato, lo rimuovo dall'array
          currBarcodeAttivi.splice(1, 1)
        }

        delete pr.nuovoBarcode
      }

      var n
      for (n in pr.statistici) {
        if (!pr.statistici[n]) {
          delete pr.statistici[n]
        }
      }

      for (n in pr.varianti) {
        if (!pr.varianti[n]) {
          delete pr.varianti[n]
        }
      }

      return pr
    },
    columnsCategoria(codice) {
      const v = this.categorie.filter(x => x.tipo === codice)
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => { return { codice: el.codice, descrizione: el.descrizione } })
    },
    labelCategoria(codice) {
      const lbl = this.labelCategorie.find(x => x.codice === codice)
      return (lbl && lbl.descrizione) || ''
    },
    categoriaVisibile(codice) {
      const lbl = this.labelCategorie.find(x => x.codice === codice)
      return lbl && lbl.visible
    },
    columnsVariante(codice) {
      const v = this.varianti.filter(x => x.tipo === codice)
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => { return { codice: el.codice, descrizione: el.descrizione } })
    },
    labelVariante(codice) {
      const lbl = this.labelVarianti.find(x => x.codice === codice)
      return (lbl && lbl.descrizione) || ''
    },
    varianteVisibile(codice) {
      const lbl = this.labelVarianti.find(x => x.codice === codice)
      return lbl && lbl.visible
    },
    async creaBarcode(comp) {
      const result = await prodotti.creaBarcodeProdotto()
      if (result && comp) {
        comp.setValore(result.barcode)
      }
    },
    creaBarcode1() {
      this.creaBarcode(this.$refs.form.getComponentByName('barcode1'))
    },
    creaBarcode2() {
      this.creaBarcode(this.$refs.form.getComponentByName('barcode2'))
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

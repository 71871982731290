'use strict'
// const service = process.env.VUE_APP_service_reparti || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_reparti || process.env.VUE_APP_service_reparti
})()

async function getAllReparti() {
  const response = await fetch(`${service}/reparti`)
  return await response.json()
}

// Il reparto è memorizzato nella tabella dei prodotti con tipoItem="REPARTO"

// Ritorna il documento completo del reparto
async function getDettaglioReparto(code) {
  const response = await fetch(`${service}/reparti/${code}`)
  return await response.json()
}

// Ritorna solo alcuni campi del documento utili per la visualizzazione
async function getInfoReparto(code, aliquota) {
  const url = `${service}/reparti/info/scontrino`
  const data = {
    code,
    aliquota
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data })
  })

  return await response.json()
}

export default {
  getAllReparti,
  getInfoReparto,
  getDettaglioReparto
}

<template>
  <div class="inputText" :class="!isValid ? 'notValid' : ''">
    <div class="labelForm">{{item.label || 'Testo Label'}}</div>
    <!-- <div @click="onFocus">
      <input class="modalFormInput" :placeholder="item.placeHolder || 'HH : mm'"  v-model="value"
        ref="input" @focus="onFocus" @input="onKeyboardInput" v-mask="'## : ##'" :readonly="item.readonly" :disabled="item.readonly" />
    </div> -->
    <div>
      <input class="modalFormInput inputOrario" :placeholder="'HH'"  v-model="oraText"
        ref="inputOre" @focus="onFocusOre" @input="onKeyboardInput" v-mask="'##'" :readonly="item.readonly" :disabled="item.readonly" />

      <span class="labelForm"> : </span>
      <input class="modalFormInput inputOrario" :placeholder="'mm'"  v-model="minutiText"
        ref="inputMinuti" @focus="onFocusMinuti" @input="onKeyboardInput" v-mask="'##'" :readonly="item.readonly" :disabled="item.readonly" />
    </div>
    <span v-if="!isValid" class="labelErrore">{{ msgErrore || 'Valore non valido' }}</span>
    <div v-if="item.hint" class="labelHint">
      {{ item.hint }}
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  props: {
    item: Object
  },
  data() {
    return {
      isValid: true,
      msgErrore: '',
      oraText: '',
      minutiText: '',
      value: null,
      msgValidazioni: [],
      showCalendar: false,
      hasFocus: ''
    }
  },
  directives: { mask },
  watch: {
    value(value) {
      this.$forceUpdate()
      this.$emit('update-value', this.isOrarioValido() ? this.getDati() : '')
    },
    oraText() {
      this.$forceUpdate()
      this.$emit('update-value', this.isOrarioValido() ? this.getDati() : '')
    },
    minutiText() {
      this.$forceUpdate()
      this.$emit('update-value', this.isOrarioValido() ? this.getDati() : '')
    }
  },
  created() {
  },
  mounted() {
    if (this.item.valore) {
      this.value = this.item.valore
      this.oraText = this.value.split(':')[0]
      this.minutiText = this.value.split(':')[1]
    }
  },
  methods: {
    onFocus(event) {
      this.$emit('on-focus')
    },
    onFocusOre() {
      this.hasFocus = 'ore'
      this.$emit('on-focus')
    },
    onFocusMinuti() {
      this.hasFocus = 'minuti'
      this.$emit('on-focus')
    },
    onBlur(event) {
      this.hasFocus = ''
      this.$emit('on-blur')
    },
    onInput(event) {
    },
    onKeyboardInput(event) {
      if (this.item.readonly) {
        return
      }
      if (!event.inputType) { // inputType viene solo dalla tastiera fisica
        // return
      }
      // if (event.inputType === 'deleteContentBackward') {
      //   this.checkBackwardsKeyPress()
      // }
    },
    setFocus() {
      if (!this.hasFocus) {
        this.hasFocus = 'ore'
        this.$refs.inputOre.focus({ preventScroll: true })
      }
    },
    removeFocus() {
      this.hasFocus = ''
      this.$refs.inputOre.blur()
      this.$refs.inputMinuti.blur()
      this.validaDataText()
    },
    validaDataText() {
      if (!this.isOrarioValido(true)) {
        // this.value = ''
        return
      }
      this.valida()
    },
    // isOrarioValidoOld() {
    //   var r = new RegExp(/(\d\d : \d\d)/g)
    //   if (!this.value.match(r)) {
    //     return false
    //   }
    //   var hh = this.value.split(' : ')[0]
    //   var mm = this.value.split(' : ')[1]

    //   if (parseInt(hh) < 0 || parseInt(hh) > 23) {
    //     return false
    //   }

    //   if (parseInt(mm) < 0 || parseInt(mm) > 59) {
    //     return false
    //   }
    //   return true
    // },
    isOrarioValido(azzeraValori) {
      var r = new RegExp(/(\d\d)/g)
      if (!this.oraText.match(r) || !this.minutiText.match(r)) {
        return false
      }

      var valido = true
      if (parseInt(this.oraText) < 0 || parseInt(this.oraText) > 23) {
        azzeraValori && (this.oraText = '')
        valido = false
      }

      if (parseInt(this.minutiText) < 0 || parseInt(this.minutiText) > 59) {
        azzeraValori && (this.minutiText = '')
        valido = false
      }
      return valido
    },
    // onKeyInput(value, daTastieraFisica) {
    //   if (this.item.readonly) {
    //     return
    //   }
    //   if (value === '{space}') {
    //     value = ''
    //   }
    //   if (!daTastieraFisica && value && value.length === 1) {
    //     this.value += value
    //     this.onInput()
    //   } else if (value === '{backspace}' || value === '{bksp}') {
    //     if (this.value.length > 0) {
    //       this.value = this.value.substring(0, this.value.length - 1)
    //       this.checkBackwardsKeyPress()
    //       this.onInput()
    //     }
    //   }
    //   var self = this
    //   setTimeout(() => {
    //     self.$refs.input.focus()
    //   }, 100)
    // },
    onKeyInput(value, daTastieraFisica) {
      if (this.item.readonly) {
        return
      }
      if (value === '{space}') {
        value = ''
      }
      if (daTastieraFisica) {
        return
      } else if (!daTastieraFisica && value && value.length === 1) {
        if (this.hasFocus === 'ore') {
          this.oraText += value
        } else if (this.hasFocus === 'minuti') {
          this.minutiText += value
        }
        // this.value += value
        this.onInput()
      } else if (value === '{backspace}' || value === '{bksp}') {
        if (this.hasFocus === 'ore' && this.oraText.length > 0) {
          this.oraText = this.oraText.substring(0, this.oraText.length - 1)
        } else if (this.hasFocus === 'minuti' && this.minutiText.length > 0) {
          this.minutiText = this.minutiText.substring(0, this.minutiText.length - 1)
        }
        this.checkBackwardsKeyPress()
        this.onInput()
      }
      var self = this
      setTimeout(() => {
        self.$refs[this.hasFocus === 'ore' ? 'inputOre' : 'inputMinuti'].focus()
      }, 100)
    },
    // checkBackwardsKeyPress() {
    //   if (this.value.length > 0) {
    //     var char = this.value[this.value.length - 1]
    //     while (char && isNaN(parseInt(char))) {
    //       this.value = this.value.substring(0, this.value.length - 1)
    //       char = this.value[this.value.length - 1]
    //     }
    //   }
    // },
    checkBackwardsKeyPress() {
      var char
      if (this.hasFocus === 'ore' && this.oraText.length > 0) {
        char = this.oraText[this.oraText.length - 1]
        while (char && isNaN(parseInt(char))) {
          this.oraText = this.oraText.substring(0, this.oraText.length - 1)
          char = this.oraText[this.oraText.length - 1]
        }
      } else if (this.hasFocus === 'minuti' && this.minutiText.length > 0) {
        char = this.minutiText[this.minutiText.length - 1]
        while (char && isNaN(parseInt(char))) {
          this.minutiText = this.minutiText.substring(0, this.minutiText.length - 1)
          char = this.minutiText[this.minutiText.length - 1]
        }
      }
    },
    valida() {
      if (!this.item.validations || !this.item.validations.length) {
        return []
      }
      var value = this.getDati()
      var req = this.item.validations.find((obj) => obj.nome === 'required')
      var validazioneIntervallo = this.item.validations.find((obj) => obj.nome === 'intervallo')
      var minutiInt = validazioneIntervallo ? validazioneIntervallo.minuti : 0
      var err = []

      if (req && !value) {
        this.isValid = false
        this.msgErrore = req.msgErrore || 'Selezionare un\'orario'
        err.push(this.msgErrore)
      } else if (minutiInt > 0 && parseInt(this.minutiText || 0) % minutiInt !== 0) {
        this.isValid = false
        this.msgErrore = req.msgErrore || 'Gli orari devono essere a intervalli di ' + minutiInt + ' minuti'
        err.push(this.msgErrore)
      } else {
        this.isValid = true
        this.msgErrore = ''
      }
      return err
    },
    getDati() {
      return this.oraText + ':' + this.minutiText
      // return this.value
    }
  }
}
</script>

<style lang="scss">

.inputOrario {
  width: 10% !important;
  text-align: center !important;
}
</style>

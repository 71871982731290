<template>
  <van-cell-group title="Accumulo credito">
    <van-cell center title="Attivo">
      <template #right-icon>
        <van-switch v-model="accumuloAttivo" size="24" disabled />
      </template>
    </van-cell>
    <van-cell title="Dalla data" :value="dataDa"/>
    <van-cell title="Alla data" :value="dataA"/>
    <van-cell title="Credito totale" :value="creditoTotale"/>
    <van-cell title="Data azzeramento" :value="dataAzzeramento"/>
    <van-cell-group title="Regole"/>
    <van-cell-group title="Accumuli">
      <van-list>
        <van-cell
          v-for="(el, index) in creditoAccumulato"
          :key="index"
          :border="true"
          :title="titolo(el)"
          :label="label(el)"
          :value="value(el)"
          icon="cart-o"
          size="large"
        />
      </van-list>
    </van-cell-group>
  </van-cell-group>
</template>

<script>
export default {
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
    }
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    accumuloAttivo() {
      return this.card && this.card.accumuloCredito.abilita
    },
    creditoAccumulato() {
      return this.card.accumuloCredito ? this.card.accumuloCredito.accumulo || [] : []
    },
    dataDa() {
      return this.card && this.card.accumuloCredito.dallaData ? this.$options.filters.date(this.card.accumuloCredito.dallaData) : ''
    },
    dataA() {
      return this.card && this.card.accumuloCredito.allaData ? this.$options.filters.date(this.card.accumuloCredito.allaData) : ''
    },
    creditoTotale() {
      return this.card && this.card.accumuloCredito.totale ? (this.card.accumuloCredito.totale).toFixed(2) : ''
    },
    dataAzzeramento() {
      return this.card && this.card.accumuloCredito.dataAzzeramento ? this.$options.filters.date(this.card.accumuloCredito.dataAzzeramento) : ''
    }
  },
  methods: {
    titolo(item) {
      return this.$options.filters.date(item.data)
    },
    label(item) {
      return item.fiscale && item.fiscale.barcode ? `Scontrino: ${item.fiscale.barcode.documento}` : ''
    },
    value(item) {
      return item.importo ? item.importo.toFixed(2) : ''
    }
  }
}
</script>

<template>
  <van-cell-group>
    <van-cell center title="Ricerca card associate" >
      <template #right-icon>
        <van-switch v-model="ricercaAssociate" size="24" :loading="loading" @change="ricercaAssociateChange"/>
      </template>
    </van-cell>
    <van-cell center title="Solo card del cliente" v-if="ricercaAssociate && context.cliente">
      <template #right-icon>
        <van-switch v-model="soloCardDelCliente" size="24" :loading="loading" @change="soloClienteChange"/>
      </template>
    </van-cell>
    <van-cell-group v-if="!soloCardDelCliente">
      <van-cell @touchstart.native.stop="showTastiera = true; maxLen = 9; tipoRicerca = 0" title="Cerca per scontrino acquisto" maxlength="9">
        <van-field v-model="numberValue" v-show="tipoRicerca == 0" placeholder="numero documento fiscale" autofocus="true" />
      </van-cell>
      <van-cell @touchstart.native.stop="showTastiera = true; maxLen = 9; tipoRicerca = 1" title="Cerca per scontrino vendita" maxlength="9">
        <van-field  v-model="numberValue" v-show="tipoRicerca == 1" placeholder="numero documento fiscale" autofocus="true" />
      </van-cell>
      <van-cell @touchstart.native.stop="showTastiera = true; maxLen = 13; tipoRicerca = 2" title="Cerca per numero" maxlength="13">
        <van-field v-model="numberValue" v-show="tipoRicerca == 2" placeholder="numero card" autofocus="true"/>
      </van-cell>
      <van-cell @touchstart.native.stop="showTastiera = true; maxLen = 4; tipoRicerca = 3" title="Cerca per secure code" maxlength="4">
        <van-field v-model="numberValue" error v-show="tipoRicerca == 3" placeholder="secure code" autofocus="true"/>
      </van-cell>
      <van-number-keyboard
        v-model="numberValue"
        :show="showTastiera"
        theme="custom"
        extra-key="-"
        close-button-text="Cerca"
        @blur="showTastiera = false"
        @close="cercaFidelity"
      />
    </van-cell-group>
    <van-swipe-cell :before-close="beforeClose" v-for="(el, index) in cards" :key="index">
      <template #left>
        <van-button square color="#5badfa" text="Seleziona" class="swipe-cell-button" @click="clickSeleziona(el)"/>
      </template>
      <van-cell
        :border="true"
        :label="label(el)"
        icon="coupon-o"
        size="large"
      >
        <template #title>
          <span class="buono-title">{{titolo(el)}}</span>
          <van-tag type="danger" v-if="el.annullamento">Annullato</van-tag>
          <van-tag type="warning" v-if="!el.associato">free</van-tag>
        </template>
<!--         <template #default>
          <van-tag type="warning">free</van-tag>
        </template> -->
      </van-cell>
      <template #right v-if="!el.annullamento">
        <van-button square color="#ff4949" text="Cancella" class="swipe-cell-button" @click="clickAnnulla(el)"/>
      </template>
    </van-swipe-cell>
  </van-cell-group>
</template>

<script>
import configurazioni from '@/services/configurazioniService.js'
import fidelity from '@/services/fidelityService.js'
import { testCardType } from './cardTypes'

export default {
  mixins: [testCardType],
  props: {
    refresh: Number,
    context: Object
  },
  data() {
    return {
      tipoRicerca: 0,
      tipoCard: 'fidelitycard',
      ricercaAssociate: true,
      soloCardDelCliente: true,
      showTastiera: false,
      numberValue: '',
      maxLen: 0,
      cards: [],
      locCard: null,
      prefisso: '',
      loading: false
    }
  },
  async mounted() {
    console.log('mounted')
    var barcode = await configurazioni.getBarcodeInfo(this.tipoCard)
    this.prefisso = barcode.prefisso
    this.maxLen = 13
    if (this.tipoRicerca === 0) {
      this.tipoRicerca = 2
      await this.refreshLista()
    }
  },
  computed: {
    card() {
      return this.locCard || {}
    },
    buono() { // la prepagata è come un buono multiuso. Aggiungo la voce carichi[] per tracciare gli importi aggiunti
      return this.card.buono || { importo: 0, dataAcquisto: null }
    }
  },
  watch: {
    async refresh() {
      console.log('watch')
      this.tipoRicerca = 2
      this.maxLen = 13
      await this.refreshLista()
    },
    async ricercaAssociate() {
      await this.refreshLista()
    }
  },
  methods: {
    async onOpen() {
      // console.log('open', this.soloCardDelCliente)
      if (this.soloCardDelCliente && this.context.cliente) {
        this.tipoRicerca = 4
        await this.cercaFidelity()
      } else {
        this.tipoRicerca = 2
        this.soloCardDelCliente = false
        await this.cercaFidelity()
      }
    },
    async ricercaAssociateChange(value) {
      // console.log('ricercaAssociateChange', value)
      if (!value) {
        this.soloCardDelCliente = false
      }
      this.tipoRicerca = 2
      this.maxLen = 13
      await this.refreshLista()
    },
    async soloClienteChange(value) {
      // console.log('soloClienteChange', value)
      if (value) {
        this.tipoRicerca = 4
        await this.cercaFidelity()
      } else {
        this.tipoRicerca = 2
        await this.cercaFidelity()
      }
    },
    async refreshLista() {
      // console.log('refreshLista')
      this.loading = true
      await this.cercaFidelity()
      this.loading = false
    },
    async cambiaModalita(checked) {
      await this.refreshLista()
    },
    async cercaFidelity() {
      // console.log('cercaFidelity', this.tipoRicerca)
      let result = []
      switch (this.tipoRicerca) {
        case 0:
          result = await fidelity.cercaFidelityByPurchaseTicket(this.numberValue, this.maxLen, this.ricercaAssociate, this.tipoCard)
          break
        case 1:
          result = await fidelity.cercaFidelityByTicket(this.numberValue, this.maxLen, this.ricercaAssociate, this.tipoCard)
          break
        case 2:
          result = await fidelity.cercaFidelityById(this.numberValue || this.prefisso, this.maxLen, this.ricercaAssociate, this.tipoCard)
          break
        case 3:
          result = await fidelity.cercaFidelityByCode(this.numberValue, this.maxLen, this.ricercaAssociate, this.tipoCard)
          break
        case 4:
          result = await fidelity.cercaFidelityByCliente(this.tipoCard, this.context.cliente.codice)
          break
        default:
          break
      }
      this.cards = result
    },
    titolo(item) {
      if (!item.associato) {
        return item.codice
      } else {
        return item.codice + '  :  ' + this.tipologia(item)
      }
      // return item.codice + '  :  ' + this.$options.filters.euro(item.buono.importoResiduo || 0)
    },
    label(item) {
      if (!item.associato) {
        return `Code: ${item.secureCode} - non associata`
      } else {
        const data = this.$options.filters.date(item.dataAssociazione)
        return `Associazione: ${data} - code: ${item.secureCode} - ${item.cliente.ragioneSociale}`
      }
      /*       const importo = this.$options.filters.euro(item.buono.importo)
      const data = this.$options.filters.date(item.buono.dataAcquisto)
      return `Importo: ${importo} - acquisto: ${data} - code: ${item.secureCode}` */
    },
    value(item) {
      return 'value'
      // return this.$options.filters.euro(item.buono.importoResiduo)
    },
    /*     tipologia(item) {
      let tipologie = []
      if (this.isPrepagata)
      return 'tipologia'
    }, */
    clickAnnulla(item) {
      this.$dialog.confirm({
        message: 'Confermi cancellazione fidelity card ?',
        cancelButtonText: 'NO',
        confirmButtonText: 'SI'
      }).then(() => {
        const event = {
          type: 'ANNULLA_FIDELITY_CARD', // 'ANNULLA_BUONO_MULTIUSO',
          value: {
            code: item.codice
          }
        }
        this.$emit('send-event', event)
      })
    },
    clickSeleziona(item) {
      if (!item.annullamento) {
        const event = {
          type: 'FIDELITY', // 'BUONO_MULTIUSO',
          value: {
            code: item.codice
          }
        }
        this.$emit('send-event', event)
        this.$emit('change-tab', 'modifica')
        this.locCard = null
      } else {
        this.locCard = item
      }
    },
    beforeClose({ position, instance }) {
      this.tipoRicerca = 0
      switch (position) {
        case 'left':
        case 'cell':
        case 'outside':
          instance.close()
          break
        case 'right':
          instance.close()
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }

/*   .van-cell__value {
    flex: 0.1;
  } */
</style>

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupTastiera main-popup-tastiera"
      :overlay="true"
      @open="onOpen"
      @close="onClose"
      @click-overlay="onExit"
    >
      <van-search
        v-model="valore"
        left-icon=""
        show-action
        :label="labelTastiera"
        placeholder="Valore"
        @search="onInsertText"
        @clear="onClearValore"
        @focus="onFocus"
      >
      <template #action>
        <div @click="onInsertText">Conferma</div>
      </template>
      </van-search>
      <simple-popup-keyboard class="keyboard-popup-tastiera" :class="{ 'move-down': false }" enterText="Conferma" :keyboardClass="'kb_tastiera'" />
    </van-popup>
</template>

<script>
import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'

export default {
  components: {
    SimplePopupKeyboard
  },
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      valore: '',
      activeTab: 'popup',
      activeParent: 'tastiera',
      activeField: 'valore',
      partitaIva: false
    }
  },
  async mounted() {
    this.onOpen()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    labelTastiera() {
      return ''
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    kbKeyPress(value) {
      if (this.show) {
        if (value === '{esci}') {
          this.onInsertText()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          this.onInsertText()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    }
  },
  methods: {
    handleKey(valore) {
    },
    onFocus(event) {
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
    },
    onInsertText() {
      const event = {
        type: 'INSERISCI_TESTO',
        value: {
          testo: this.valore
        }
      }
      this.$emit('send-event', event)
    },
    onClearValore() {
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
    },
    onOpen() {
      this.clearSearchInput()
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      this.$store.commit('SET_EXTERNAL_TAB', this.activeParent)
      this.$store.commit('SET_INTERNAL_TAB', this.activeTab)
    },
    clearSearchInput() {
      this.valore = ''
    },
    closePopup() {
      this.$emit('close')
      this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '')
    },
    onClose() {
      this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '')
    },
    onExit() {
      this.valore = ''
      this.onInsertText()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .keyboard-popup-tastiera.move-down {
    margin-top: 50px;
  }

  .result-popup-tastiera {
    margin-top: 20px;
  }

  .swipe-cell-button {
    height: 100%;
  }

  .van-search--show-action {
      padding-right: 12px;
  }

  .main-popup-tastiera {
    top: auto;
  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupTastiera, .main-popup-tastiera {
      width: 85%;
      height: 70%;
    }
  }
  @media all and (min-width:1024px) {
    .popupTastiera, .main-popup-tastiera {
      width: 65%;
      height: 63%;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-tastiera {
      width: 40%;
      height: 50%;
      /* bottom: -200px; */

    }

  }
  .van-popup--left {
    top: 50%;
    left: 0;
  }
</style>

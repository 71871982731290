// per adesso non utilizzo un database specifico per i barcode perchè dovrei tenerlo aggiornato ad ogni modifica  dei relativi valori
// --> utilizzo una cache per la lettura dei barcode ricorrenti

import configurazioni from './configurazioniService.js'
// import prodotti from './prodottiService.js'
import clienti from './clientiService.js'
import fidelity from './fidelityService.js'

const LRU = require('lru-cache')
const options = { max: 500, maxAge: 1000 * 60 * 60, updateAgeOnGet: false } // TODO: prevedere comando di svuotamento
const cache = new LRU(options)

;(async () => {
  let fb = cache.get('configurazioni_formati_barcode')
  if (!fb) {
    console.log('letta configurazione')
    fb = await configurazioni.getConfigurazione('formati_barcode')
    cache.set('configurazioni_formati_barcode', fb)
  }
})()

async function getInfoBarcode(searchText, filter) {
  // TODO: Creare tabella con elenco barcode riconosciuti invece di calcolare tipo barcode da formato
  //       Potrebbero essere presenti barcode con lo stesso formato ma per usi diversi
  //       La tabella deve essere alimentata da qualsiasi operazione viene fatta nei prodotti (evento su moleculer ?)
  const cachedValue = cache.get(searchText)
  let codice = ''
  let tipoBarcode = ''
  let obj = null
  if (!cachedValue) {
    console.log('getInfoBarcode: nocache', searchText)
    const result = await confBarcode(searchText, filter)
    searchText = result.code // ####
    if (result && result.tipo && result.tipo.startsWith('prodotto')) {
      result.tipo = 'prodotto'
    }
    switch (result.tipo) {
      case 'prodotto':
        // TODO: Verificare. Questa situazione è incompatibile con un codice di uguale lunghezza senza caratteri da ignorare
        if (result.codaCaratteriDaIgnorare && result.codaCaratteriDaIgnorare > 0) {
          codice = result.code
        }
        // obj = await prodotti.getInfoFromBarcode(searchText)
        // codice = (obj.ricercaTC || '').toUpperCase() || obj.codice
        // non lo metto in cache, serve solo per sapere di che tipo di barcode si tratta
        break
      case 'tessera': // Verificare se e come gestire...
        obj = await clienti.getCardFromBarcode(searchText)
        codice = obj.codice
        break
      case 'fidelitycard':
      case 'buonomultiuso':
      case 'buonomonouso':
      case 'coupon':
        obj = await fidelity.getFidelity(searchText)
        codice = obj.codice || searchText
        break
      case 'grattaevinci':
        codice = result.code
        result.tipo = result.tipoDestinazione || 'prodotto'
        break
      default:
        break
    }
    if (codice) {
      const value = `${result.tipo}§§°§${codice}`
      cache.set(searchText, value)
    }
    tipoBarcode = result.tipo || ''
  } else {
    console.log('getInfoBarcode: cache', searchText)
    const values = cachedValue.split('§§°§')
    tipoBarcode = values[0]
    codice = values[1]
  }
  return { tipoBarcode, code: codice }
}

/* const confBarcodeOld = async function(srcBarcode) {
  // separare qrcode in base a lunghezza ?
  let result = {}
  let barcode = null
  let fb = cache.get('configurazioni_formati_barcode')

  if (!fb) {
    console.log('letta configurazione')
    fb = await configurazioni.getConfigurazione('formati_barcode')
    cache.set('configurazioni_formati_barcode', fb)
  }
  // const fb = await db_configurazioni.conf_get('formati_barcode');
  if (fb && fb.barcode) {
    const filtroLen = fb.barcode.filter(x => x.lunghezza === srcBarcode.length || x.lunghezza === 0)
    if (filtroLen) {
      barcode = filtroLen.find(x => srcBarcode.startsWith(x.prefisso) && x.prefisso.length > 0)
      if (!barcode) {
        barcode = filtroLen.find(x => x.prefisso === '')
      }
      if (barcode) {
        result = barcode
      }
    }
  }
  return result
} */

const confBarcode = async function(srcBarcode, filtroEsterno) {
  // separare qrcode in base a lunghezza ?
  let result = { code: srcBarcode }
  let barcode = null
  let fb = cache.get('configurazioni_formati_barcode')
  if (!fb) {
    console.log('letta configurazione')
    fb = await configurazioni.getConfigurazione('formati_barcode')
    cache.set('configurazioni_formati_barcode', fb)
  }
  // const fb = await db_configurazioni.conf_get('formati_barcode');
  if (fb && fb.barcode) {
    // const filtroLen = fb.barcode.filter(x => x.lunghezza === srcBarcode.length || x.lunghezza === 0)
    const filtroLen = fb.barcode.filter(x => (x.lunghezza - ((x.caratteriMancantiLettore && x.caratteriMancantiLettore.length) || 0)) === srcBarcode.length || x.lunghezza === 0)
    if (filtroLen) {
      // barcode = filtroLen.find(x => srcBarcode.startsWith(x.prefisso) && x.prefisso.length > 0)
      // Nel caso di barcode che comincia per 0 (e forse altri ..) il lettore toglie una cifra riportando 12 caratteri invece che 13
      // Nella configurazione dei barcode ho aggiunto caratteriMancantiLettore="0"
      barcode = ''
      for (const b of filtroLen) {
        const bbCode = `${b.caratteriMancantiLettore || ''}${srcBarcode}`
        if (bbCode.startsWith(b.prefisso) && b.prefisso.length > 0) { // TODO: considerare eventuale filtro esterno
          barcode = b
          result.code = bbCode
        }
        if (b.codaCaratteriDaIgnorare && b.codaCaratteriDaIgnorare > 0) {
          barcode = b
          result.code = bbCode.slice(0, -b.codaCaratteriDaIgnorare)
        }
      }
      if (!barcode) {
        if (filtroEsterno) {
          barcode = filtroLen.find(x => x.prefisso === '' && x.tipo === filtroEsterno)
        } else {
          const isnumeric = !isNaN(srcBarcode)
          barcode = filtroLen.find(x => x.prefisso === '' && (!x.numerico || x.numerico === isnumeric))
        }
      }
      if (barcode) {
        if (barcode.substring1) {
          result.code = result.code.substring(barcode.substring0 || 0, barcode.substring1)
        }
        // TODO: Verificare + aggiuntere tipoDestinazione su Database
        /*         if (barcode.tipoDestinazione) {
          barcode.tipo = barcode.tipoDestinazione
        } */
        result = { ...result, ...barcode }
      }
    }
  }
  return result
}

const addEANCheckDigit = (numero) => {
  const barcode = String(numero)
  const len = barcode.length
  if (len !== 7 && len !== 12) {
    return '-non valido-'
  }

  let somma = 0
  for (let i = 0; i < len; i++) {
    const peso = (len - i) % 2 === 0 ? 1 : 3
    const digit = barcode.charAt(i)
    somma += parseInt(digit) * peso
    // console.log(peso, digit, somma)
  }
  const checkDgt = Math.ceil(somma / 10) * 10 - somma
  return barcode + String(checkDgt)
}

export default {
  getInfoBarcode,
  addEANCheckDigit
}

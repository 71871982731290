import { render, staticRenderFns } from "./SimpleKeyboard.vue?vue&type=template&id=cad3ca56&scoped=true&"
import script from "./SimpleKeyboard.vue?vue&type=script&lang=js&"
export * from "./SimpleKeyboard.vue?vue&type=script&lang=js&"
import style1 from "./SimpleKeyboard.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cad3ca56",
  null
  
)

export default component.exports
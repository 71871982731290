<template>
  <van-cell-group>
    <!-- <van-button type="primary" block @click="testPdfmake">Test Pdfmake</van-button> -->
     <van-cell title="Movimenti 01" label="Stampa movimenti ...." @click="openFluentReports('movimenti_01')" is-link/>
     <van-cell title="Magazzino 01" label="Stampa magazzino ...." @click="openFluentReports('magazzino_01')" is-link/>
     <van-cell title="Magazzino 02" label="Stampa magazzino ...." @click="openFluentReports('magazzino_02')" is-link/>
      <!--     <van-button type="primary" @click="openFluentReports('movimenti_01')">Movimenti 01</van-button>
    <van-button type="primary" @click="openFluentReports('magazzino_01')">Magazzino 01</van-button>
    <van-button type="primary" @click="openFluentReports('magazzino_02')">Magazzino 02</van-button> -->
  </van-cell-group>
</template>

<script>
// import * as pdfmake from '@/reports/pdfmake.js'

export default {
  data() {
    return {
    }
  },
  methods: {
    /*     testPdfmake() {
      pdfmake.open('absolute')
    }, */
    openFluentReports(nomeReport) {
      window.open(`/reporting/pdf/${nomeReport}/20210401/20210500`)
    },
    sendToMachine(actionType, payload) {
      const event = {
        type: actionType,
        value: payload
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

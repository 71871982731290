<template>
  <div class="dettaglioModale" :class="!isValid ? 'notValid' : ''">
    <div class="labelForm">{{item.label || 'Testo Label'}}</div>
    <!-- se ho campo testo, faccio vedere quello ( testo html ), altrimenti faccio vedere una lista di elementi campo-valore -->
    <div v-if="item.testo">
      <div v-html="item.testo" ></div>
      <!-- <input class="modalFormInput" :placeholder="item.placeHolder || 'Inserire testo..'"  v-model="value"
        :v-trim="item.trim ? true : false"
        :v-uppercase="item.forceUpper ? true : false" @change="onChange" ref="input" @focus="onFocus" @blur="onBlur" :readonly="item.readonly" :disabled="item.readonly" /> -->
    </div>
    <div v-else-if="item.lista && item.lista.length">
      <div  v-for="(el, i) in item.lista" :key="i">
        <div>{{ el.campo }}: </div>
        <div>{{ el.valore }}</div>
      </div>
    </div>
    <span v-if="!isValid" class="labelErrore">{{ msgErrore || 'Messaggio di errore validazione o suggerimento' }}</span>
    <div v-if="item.hint" class="labelHint">
      {{ item.hint }}
    </div>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { helpers, required, numeric, minLength, maxLength/* , requiredIf */ } from 'vuelidate/lib/validators'
import CodiceFiscale from '../../helpers/codiceFiscale'
import PartitaIva from '../../helpers/partitaIva'
import Email from '../../helpers/email'
const valPartitaIva = (value) => !helpers.req(value) || !PartitaIva.validate(value)
const valCodiceFiscale = (value) => !helpers.req(value) || !CodiceFiscale.validate(value)
const valEmail = (value) => !helpers.req(value) || !Email.validate(value)

const validations = {
  cap: { numeric, minLength: minLength(5), maxLength: maxLength(5) },
  telefono: {},
  cellulare: {},
  email: { valEmail },
  partitaIva: {
    valPartitaIva
    // required: requiredIf((model) => false && !model.codiceFiscale)
  },
  codiceFiscale: {
    valCodiceFiscale
    // required: requiredIf((model) => false && !model.partitaIva)
  },
  pec: { valEmail }
}
// DEBUG: 02395190545
export default {
  mixins: [validationMixin],
  props: {
    item: Object,
    modelItem: Object
  },
  data() {
    return {
      isValid: true,
      msgErrore: '',
      value: '',
      msgValidazioni: [],
      trigger: 1
    }
  },
  validations() {
    var val = { value: {} }
    if (this.item.validations) {
      for (var i = 0; i < this.item.validations.length; i++) {
        var defaultVal = validations[this.item.validations[i].nome]
        if (defaultVal) {
          for (var n in defaultVal) {
            val.value[n] = defaultVal[n]
            if (this.item.validations[i].msgErrore) {
              this.msgValidazioni[n] = this.item.validations[i].msgErrore
            }
          }
        } else {
          if (this.item.validations[i].nome === 'required') {
            val.value.required = required
            this.msgValidazioni.required = 'Il campo è obbligatorio'
          }
        }
      }
    }
    return val
  },
  directives: {
    uppercase: {
      update (el) {
        el.value = el.value.toUpperCase()
      }
    },
    trim: {
      update (el) {
        el.value = el.value.trim()
      }
    }
  },
  watch: {
    value(value) {
      this.$forceUpdate()
      this.$emit('update-value', value)
    }
  },
  created() {
  },
  mounted() {
    if (this.item.valore) {
      this.value = this.item.valore
    }
    this.trigger++
  },
  methods: {
    onFocus(event) {
      this.$emit('on-focus')
    },
    onBlur(event) {
      // this.$emit('on-blur')
    },
    onChange(event) {
      this.$v.value.$touch()
      this.checkValidations()
    },
    setFocus() {
      this.$refs.input.focus({ preventScroll: true })
    },
    removeFocus() {
      this.$refs.input.blur()
    },
    onKeyInput(value) {
      if (this.item.readonly) {
        return
      }
      if (value === '{space}') {
        value = ' '
      }
      if (value && value.length === 1) {
        this.value += value
        this.onChange()
      } else if (value === '{backspace}' || value === '{bksp}') {
        if (this.value.length > 0) {
          this.value = this.value.substring(0, this.value.length - 1)
          this.onChange()
        }
      }
      var self = this
      setTimeout(() => {
        self.$refs.input.focus()
      }, 100)
    },
    checkValidations() {
      var params = this.$v.value.$params
      if (params.required && !this.$v.value.required) {
        this.msgErrore = this.msgValidazioni.required || 'Il campo è obbligatorio'
        this.isValid = false
        return
      }
      for (var n in params) {
        if (!this.$v.value[n]) {
          this.isValid = false
          this.msgErrore = this.msgValidazioni[n] || 'Correggere questo campo'
          return
        }
      }

      var validazioniItem = this.item.validations || []
      for (var i = 0; i < validazioniItem.length; i++) {
        var v = validazioniItem[i]
        if (v.nome === 'requiredIf') {
          var compilato = this.$parent.$parent.isCampoCompilato(v.campoIfNot)
          if (!compilato && !this.value) {
            this.isValid = false
            this.msgErrore = v.msgErrore
            return
          }
        }
      }
      this.isValid = true
      this.msgErrore = ''
    },
    valida() {
      this.$v.value.$touch()
      this.checkValidations()
      return this.isValid ? [] : [this.msgErrore]
    },
    getDati() {
      return this.value
    },
    setValore(valore) {
      this.value = valore
    }
  }
}
</script>

<style lang="scss">
</style>

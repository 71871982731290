'use strict'
import errorService from '@/services/errorService'
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_escpos || process.env.VUE_APP_service_escpos
})()

async function getRunMode() {
  try {
    const response = await fetch(`${service}/stampante/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'STAMPANTE',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function stampaCoupon(scontrinoInfo, barcode) {
  const comandi = []
  for (const c of scontrinoInfo) {
    comandi.push({ code: 'text', v1: c })
  }
  comandi.push({ code: 'barcode', v1: barcode.code.substring(0, 12), v2: 'EAN13' })
  await stampaCouponComandi(comandi)
}

async function stampaCouponComandi(comandi) {
/*   const comandi = []
  for (const c of scontrinoInfo) {
    comandi.push({ code: 'text', v1: c })
  }
  comandi.push({ code: 'barcode', v1: barcode.code.substring(0, 12), v2: 'EAN13' }) */
  const data = {
    coupon: {
      comandi
    }
  }
  try {
    const response = await fetch(`${service}/printer/coupon`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    return result
  } catch (error) {
    errorService.onError(error, 'stampaCoupon')
    throw error
  }
}

export default {
  getRunMode,
  stampaCoupon,
  stampaCouponComandi
}

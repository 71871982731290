<template>
  <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-body">
                <slot name="body">
                  <iframe :src="helpSource" width="100%" height="90vh" frameborder="0" style="overflow:hidden;height:90vh;width:100%;z-index:2002;"></iframe>
                </slot>
                <button class="action modal-default-button bg-white btn-help-chiudi" @click="chiudi">CHIUDI</button>
              </div>
            </div>
          </div>
        </div>
      </transition>
</template>

<script>
export default {
  props: {
    context: Object
  },
  data() {
    return {
    }
  },
  computed: {
    helpSource() {
      return this.context.helpSource
    }
  },
  methods: {
    chiudi() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../modale.scss";

  .modal-mask {
    z-index:2002 !important;
  }
  .btn-help-chiudi {
    position: relative;
    bottom: 12px;
    right: -10px;
  }
</style>

'use strict'
// import _ from 'lodash'

import configurazioni from '@/services/configurazioniService.js'
let reparti = null

const getElencoReparti = async function() {
  if (!reparti) {
    reparti = await configurazioni.getConfigurazione('prodotti_reparti_fiscali', 'reparti')
  }
  return reparti
}

const tipoStampaReparto = function(reparto) {
  const rf = reparti.find(x => x.codice === reparto)
  const stampa = rf?.stampante.stampa || ''
  return stampa
}

;(async () => await getElencoReparti())()

export default {
  getElencoReparti,
  tipoStampaReparto
}

<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" @salva="salva" :campi="campi" :config="config"></modale-form>
</template>

<script>
import _ from 'lodash'
import ModaleForm from '../ModaleForm'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      campi: [],
      config: {
        disableEsciOnClick: true,
        salvaText: 'OK'
      }
    }
  },
  async created() {
  },
  async mounted() {
    var campi = [
      { campo: 'descrizione', model: 'descrizione', tipo: 'text', label: 'Descrizione', placeHolder: 'Inserire descrizione...', validations: this.validazioni }
    ]

    this.campi = campi
  },
  computed: {
    titoloModale() {
      return 'Carica Selezione'
    },
    valoriRequired() {
      return _.get(this.context, 'impostazioni.cassa.contabili.richiediDescrizione', true)
    },
    validazioni() {
      return this.valoriRequired ? [{ nome: 'required' }] : []
    }
  },
  methods: {
    annulla() {
      this.$emit('close')
    },
    async salva() {
      var form = this.$refs.form
      var err = form.valida()
      if (err && err.length) {
        return
      }
      var dati = form.getDati()
      // Cambiando l'evento ritornato (in base a parametro ?) viene modificato il comportamento in chiusura
      const event = {
        type: 'DESCRIZIONE_CASSA',
        payload: {
          value: dati.descrizione
        }
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div class="inputText" :class="!isValid ? 'notValid' : ''">
    <div>
      <button class="btnModale" :class="item.classe || 'btnDefault'" @click="onClick">{{ item.label || ''}}</button>
    </div>
    <span v-if="!isValid" class="labelErrore">{{ msgErrore || 'Messaggio di errore validazione o suggerimento' }}</span>
    <div v-if="item.hint" class="labelHint">
      {{ item.hint }}
    </div>

  </div>
</template>

<script>

export default {
  props: {
    item: Object,
    modelItem: Object
  },
  data() {
    return {
      isValid: true,
      msgErrore: '',
      value: '',
      msgValidazioni: []
    }
  },
  watch: {
    value(value) {
      this.$forceUpdate()
    }
  },
  mounted() {
  },
  methods: {
    onFocus(event) {
      this.$emit('on-focus')
    },
    onBlur(event) {
    },
    onChange(event) {
    },
    setFocus() {
    },
    removeFocus() {
    },
    onKeyInput(value) {
    },
    onClick() {
      this.$emit('update-value', '')
    },
    valida() {
      return this.isValid ? [] : [this.msgErrore]
    },
    getDati() {
      return this.value
    },
    setValore(valore) {
      this.value = valore
    }
  }
}
</script>

<style lang="scss">

  .btnModale.btnDelete {
    color: white;
    background-color: #cc0000;
    padding: 10px 20px 10px 20px;
    margin-left: 10px;
  }

  .btnModale.btnDefault {
    color: white;
    background-color: #1a74ec;
    padding: 10px 20px 10px 20px;
    margin-left: 10px;
  }

</style>

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupCercaProdotto main-popup-cerca-prodotto"
      @open="onOpen"
      @click-overlay="closePopup"
      >
      <van-form @submit="onSubmit">
        <van-search
          v-model="prodotto"
          show-action
          name="prodotto"
          ref="prodotto"
          :label="tagliaColore ? 'Barcode' : 'Prodotto'"
          :placeholder="tagliaColore ? 'Barcode' : 'Prodotto'"
          @search="onSearchProdotto"
          @clear="onClearProdotto"
          @focus="onFocus"
          class="mostra-descrizione"
          :error-message="tagliaColore ? 'Vengono cercati tutti i prodotti che hanno codice uguale (altre taglie/colori) a quello del prodotto letto': ''"
        >
          <template #action>
            <div @click="onSearchProdotto">Cerca</div>
          </template>
        </van-search>
        <van-cell center title=" " value="Cerca per Taglia e Colore simili" v-if="cercaTagliaColore">
          <template #icon>
            Cerca testo contenuto (più lenta)
            <van-switch v-model="testoContenuto" size="24" style="margin-left:10px;" @change="onSearchProdotto"/>
          </template>
          <template #right-icon>
            <van-switch v-model="tagliaColore" size="24" style="margin-left:10px;" @change="onSearchProdotto"/>
          </template>
        </van-cell>
        <van-cell center title=" " value="">
          <template #icon>
            Cerca barcode disabilitati (più lenta)
            <van-switch v-model="barcodeDisabilitati" size="24" style="margin-left:10px;" @change="onSearchProdotto"/>
          </template>
        </van-cell>
      </van-form>
      <popup-list-result class="result-popup-cerca-prodotto" :rightAction="true" :rightActionLabel="'Etichetta'" @click-right='stampaEtichetta'/>
      <simple-popup-keyboard class="keyboard-popup-cerca-prodotto" enterText="Cerca" :keyboardClass="'kb_cerca_prodotto'" />
    </van-popup>
</template>

<script>
import _ from 'lodash'
import prodotti from '@/services/prodottiService.js'
import etichette from '@/services/etichetteService.js'
import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'
import PopupListResult from '@/components/popup/ListResult'

export default {
  components: {
    SimplePopupKeyboard,
    PopupListResult
  },
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      prodotto: '',
      prodottoItem: null,
      tagliaColore: false,
      activeTab: 'popup',
      activeParent: 'prodotti',
      activeField: 'prodotto',
      // pathEtichettePrefix: '',
      salta: 0,
      testoContenuto: false,
      barcodeDisabilitati: false
    }
  },
  created() {
    this.debounceInputProdotti = _.debounce(this.getElencoProdotti, 500)
  },
  async mounted() {
    this.onOpen()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    listResult() {
      return this.$store.getters.listResult
    },
    cercaTagliaColore() {
      return this.context && this.context.impostazioni.ricerca && this.context.impostazioni.ricerca.prodotto.cercaTagliaColore
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        console.log('kbInput', value)
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    async kbKeyPress(value) {
      if (this.show) {
        console.log('kbKeyPress', value)
        if (value === '{esci}') {
          // this.closePopup()
          await this.debounceInputProdotti(this.kbInput)
        } else {
          if (value === '{enter}') {
            console.log('kbKeyPress enter', value)
            await this.debounceInputProdotti(this.kbInput)
          }
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          // this.closePopup()
        } else {
          console.log('kbCounter', value)
          this.handleKey(this.kbInput)
        }
      }
    },
    listResult(payload) {
      // console.log('pr', payload.parent, this.activeParent, payload.tab, this.activeTab)
      if (payload.parent !== this.activeParent || payload.tab !== this.activeTab || !payload.result) return
      const item = payload.result
      console.log('payload 1', payload)
      this.prodottoItem = item
      this.$store.commit('prodotti/SET_PRODOTTO', this.prodottoItem)
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      console.log(item)
      this.aggiungiAlCarrello(item.codice || item.codiceProdotto)
    }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.tab-popup-cerca-prodotto')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    handleKey(valore) {
      // TODO: verificare se ricerca automatica o solo quando si preme "Cerca"
      // this.debounceInputProdotti(valore)
    },
    async onSearchProdotto() {
      await this.getElencoProdotti(this.prodotto)
    },
    onClearProdotto() {
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
    },
    async stampaEtichetta(item) {
      // TODO: Se necessario richiamare prodotti da ids come su etichette da selezione
      const etichetta = {
        codice: item.codiceProdotto,
        descrizione: item.descrizione,
        taglia: item.variante1,
        colore: item.variante2,
        prezzo: item.prezzoVendita,
        acquisto: item.prezzoAcquisto,
        prezzo2: item.prezzo2,
        prezzo2Sconto: item.prezzo2Sconto,
        barcode: item.barcode
      }
      const str = JSON.stringify(etichetta)
      const tipoStampa = this.context.impostazioni.etichette.tipo
      const report = this.context.impostazioni.etichette.report
      // const src = `${this.pathEtichettePrefix}/etichette/${tipoStampa}/${report}?par=${btoa(str)}${this.salta ? '&sk=' + this.salta : ''}`
      // await etichette.stampaEtichetta(tipoStampa, src)
      await etichette.stampaEtichetta(tipoStampa, report, str, this.salta)
      // window.open(`${this.pathEtichettePrefix}/etichette/${tipoStampa}/${report}?par=${btoa(str)}${this.salta ? '&sk=' + this.salta : ''}`)
    },
    async getElencoProdotti(value) {
      console.log('getElencoProdotti', value)
      var that = this
      console.log(that.testoContenuto)
      if (!value) {
        this.elencoProdotti = []
        this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      } else {
        var result
        var docs
        if (!this.tagliaColore) {
          if (this.testoContenuto) {
            result = await prodotti.searchProdotti(value)
            docs = (result && result.docs) || []
          } else {
            const filtri = {
              id: value,
              codice: value,
              descrizione: value,
              barcode: value,
              barcodeIncludeDisabled: this.barcodeDisabilitati
            }
            const opzioni = {
              itemsPerPage: 20
            }
            result = await prodotti.getFilteredProducts(filtri, opzioni)
            docs = (result && result.rows.map(x => x.doc)) || []
          }
        } else {
          result = await prodotti.searchProdottiTC(value)
          docs = (result && result.docs) || []
        }
        // if (result && result.docs && result.docs.length > 0) {
        if (docs && docs.length > 0) {
          // const lista = result.docs.map(item => {
          const lista = docs.map(item => {
            const label = []
            if (item.nome) label.push(item.nome)
            if (item.varianti && item.varianti.variante1) label.push(item.varianti.variante1.descrizione)
            if (item.varianti && item.varianti.variante2) label.push(item.varianti.variante2.descrizione)
            if (item.statistici && item.statistici.categoria1) label.push(item.statistici.categoria1.descrizione)
            if (item.statistici && item.statistici.categoria2) label.push(item.statistici.categoria2.descrizione)
            if (item.statistici && item.statistici.categoria3) label.push(item.statistici.categoria3.descrizione)
            if (item.statistici && item.statistici.categoria4) label.push(item.statistici.categoria4.descrizione)
            const fornitore = item.ultimoAcquisto && item.ultimoAcquisto.fornitore ? item.ultimoAcquisto.fornitore : ''
            let fornitoreDescrizione = ''
            if (fornitore) {
              const fr = item.fornitori.find(x => x.codice === fornitore)
              if (fr) {
                fornitoreDescrizione = fr.descrizione
              }
            }
            // TODO: Se necessario richiamare prodotti da ids come su etichette da selezione... Su questa vista non sono necessari tutti i campi ...
            return {
              // codice: item.codice,
              codice: (item.ricercaTC || ''), // .toUpperCase(), // TODO: lasciare caratteri originali...
              descrizione: item.descrizione,
              prezzoAcquisto: item.ultimoAcquisto && item.ultimoAcquisto.prezzo ? item.ultimoAcquisto.prezzo : item.magazzino && item.magazzino.prezzoAcquisto ? item.magazzino.prezzoAcquisto : '',
              prezzoVendita: item.prezzo && item.prezzo.L1 ? item.prezzo.L1.base : '', // TODO: gestire un prezzo unitario oppure nome listino base su configurazioni ??
              prezzo2: item.prezzo && item.prezzo.L2 ? item.prezzo.L2.base : '',
              prezzo2Sconto: item.prezzo && item.prezzo.L2 ? item.prezzo.L2.sconto : '',
              unitaMisura: item.magazzino && item.magazzino.unitaMisura ? item.magazzino.unitaMisura : '',
              unitaMisuraAmmesse: item.magazzino && item.magazzino.unitaMisuraAmmesse ? item.magazzino.unitaMisuraAmmesse : [],
              fornitore,
              fornitoreDescrizione,
              titolo: `${(item.ricercaTC || '').toUpperCase()} - ${item.descrizione}`,
              label: item.codice + ' - ' + label.join(' - '),
              value: `${item.magazzino ? item.magazzino.giacenza || '---' : '---'}`,
              barcode: item.ricercaBarcode || '',
              codiceProdotto: item.codice,
              variante1: item.varianti && item.varianti.variante1 && item.varianti.variante1.descrizione,
              variante2: item.varianti && item.varianti.variante2 && item.varianti.variante2.descrizione
            }
          })
          this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
            list: lista,
            icon: 'records',
            classes: {
              titleClass: 'prodotti-lr-title',
              labelClass: 'prodotti-lr-label',
              valueClass: 'prodotti-lr-value'
            }
          })
        } else {
          console.log('getElencoProdotti noresult')
          this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
        }
      }
    },
    onFocus(event) {
      console.log('onfocus')
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
    },
    async onSubmit(values) {
      this.clearFields()
    },
    onOpen() {
      this.clearSearchInput()
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      this.$store.commit('SET_EXTERNAL_TAB', this.activeParent)
      this.$store.commit('SET_INTERNAL_TAB', this.activeTab)
    },
    clearSearchInput() {
      this.prodotto = ''
    },
    clearFields() {
      this.clearSearchInput()
    },
    closePopup() {
      this.$emit('close')
      this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '')
    },
    aggiungiAlCarrello(codice) {
      const event = {
        type: 'PRODOTTO_CERCA',
        value: {
          code: codice,
          tipoAliquota: 'banco'
        }
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .result-popup-cerca-prodotto {
    margin-top: 70px;
  }

  .swipe-cell-button {
    height: 100%;
  }
  .van-search .van-cell {
    padding: 10px 16px;
  }

  .van-search--show-action {
      padding-right: 12px;
  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupCercaProdotto, .main-popup-cerca-prodotto {
      width: 85%;
      height: 100%;
    }
  }
  @media all and (min-width:1024px) {
    .popupCercaProdotto, .main-popup-cerca-prodotto {
      width: 65%;
      height: 100%;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-cerca-prodotto {
      width: 40%;
      height: 100%;
    }
  }
</style>

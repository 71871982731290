<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" @salva="salva" :campi="campi"
    :config="config"
  ></modale-form>
</template>

<script>
import ModaleForm from '../ModaleForm'
import clienti from '@/services/clientiService.js'
import fidelity from '@/services/fidelityService.js'
import configurazioni from '@/services/configurazioniService.js'
import { cloneObject } from '@/components/helpers'
import _ from 'lodash'
import * as dot from 'dot-object'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      clienteInModifica: null,
      creaFidelity: null,
      campi: [],
      config: { disableEsciOnClick: true, clienteDefault: { infoRidotte: true, recapiti: [{ tipo: { codice: 'principale', descrizione: 'Sede' } }], amministrativi: { cliente: true, fornitore: false }, contatti: [{ tipo: 'titolare' }] } }
    }
  },
  async created() {
    const impostazioni = await configurazioni.getConfigurazione('display_generale', 'fidelityCard')
    this.creaFidelity = _.get(impostazioni, 'creazioneAutomaticaConCliente', {})
    const configModale = await configurazioni.getConfigurazione('display_elementi', 'elementi')

    if (configModale && configModale.clientiFidelity) {
      if (this.context.nuovoCliente && this.context.nuovoCliente.clienteInModifica) {
        var campi = configModale.clientiFidelity.modifica ? configModale.clientiFidelity.modifica.campi || [] : []
        this.config = configModale.clientiFidelity.modifica ? configModale.clientiFidelity.modifica.modale || {} : {}

        var cliente = await clienti.getCliente(this.context.nuovoCliente.clienteInModifica.codice)
        this.clienteInModifica = cliente

        // prepara dati iniziali
        for (var i = 0; i < campi.length; i++) {
          var c = campi[i]
          if (c.model) {
            var val = dot.pick(c.model, this.clienteInModifica)
            c.valore = val
          }
        }
        this.campi = campi
      } else {
        this.campi = configModale.clientiFidelity.nuovo ? configModale.clientiFidelity.nuovo.campi || [] : []
        this.config = configModale.clientiFidelity.nuovo ? configModale.clientiFidelity.nuovo.modale || {} : {}
      }
    }
  },
  mounted() {
  },
  computed: {
    titoloModale() {
      return this.clienteInModifica ? 'Modifica cliente Fidelity ' + this.clienteInModifica.ragioneSociale : 'Nuovo cliente Fidelity'
    }
  },
  methods: {
    onKeyPress(button) {
      this.button = button
      if (!this.insertNuovoCliente && !this.modificaDatiCliente && button === '{esci}') {
        // this.seleziona()
      }
    },
    annulla() {
      const event = {
        type: 'EDIT_CONTEXT',
        payload: {
          nuovoCliente: null
        }
      }
      this.$emit('send-event', event)
      this.$emit('close')
    },
    async salva() {
      var form = this.$refs.form
      var err = form.valida()
      if (err && err.length) {
        return
      }
      var dati = form.getDatiDotNotation()
      // console.log(dati)
      if (this.clienteInModifica) { // modifica cliente
        var datiCliente = cloneObject(this.clienteInModifica)
        _.merge(datiCliente, dati)
        datiCliente.ragioneSociale = datiCliente.contatti[0].nome + ' ' + datiCliente.contatti[0].cognome // Altrimenti non modifica RagioneSociale se cambio nome o cognome
        // console.log(datiCliente)
        const result = await clienti.modificaCliente(datiCliente)
        console.log(result)
        // // verificare se errori di creazione
        this.selezionaCliente(datiCliente)
        this.$toast('Cliente modificato con successo', { type: 'success' })
      } else { // nuovo cliente
        dati.ragioneSociale = dati.contatti[0].nome + ' ' + dati.contatti[0].cognome
        var codice = await this.creaCodiceCliente(dati.ragioneSociale)
        dati.codice = codice
        _.merge(dati, this.config.clienteDefault)
        const result = await clienti.creaCliente(dati)
        console.log(result)
        // verificare se errori di creazione
        this.selezionaCliente(dati)

        let messaggio = 'Cliente creato con successo'
        if (this.creaFidelity.abilitato) {
          // TODO: Fare su servizio esterno
          const idCassa = this.context.impostazioni.stampante.progressivo_stampante
          const impCard = this.context.impostazioni.fidelityCard
          const payload = _.get(impCard, 'defaultNonPresente', {})
          _.set(payload, 'raccoltaPunti.dallaData', new Date())
          const cliente = { codice: dati.codice, ragioneSociale: dati.ragioneSociale }
          await fidelity.creaFidelityCardCliente(idCassa, payload, cliente)
          messaggio = 'Cliente e Fidelity creati con successo'
        }

        this.$toast(messaggio, { type: 'success' })
      }
      var self = this
      setTimeout(() => {
        self.annulla()
      }, 200)
    },
    selezionaCliente(dati) {
      var obj = {
        codice: dati.codice,
        descrizione: dati.ragioneSociale,
        label: ' ',
        titolo: dati.codice + ' - ' + dati.ragioneSociale,
        value: ''
      }
      const event = {
        type: 'SELEZIONA_CLIENTE',
        value: obj
      }
      this.$emit('send-event', event)
      this.$store.commit('clienti/SET_CLIENTE', obj)
    },
    async creaCodiceCliente(ragSoc) {
      const impostazioni = await configurazioni.getConfigurazione('backoffice', 'impostazioni')
      const tipoNumeratore = impostazioni.clienti.tipoNumeratore
      var numeratore
      if (tipoNumeratore === '_') {
        const pattern = /[^a-zA-Z0-9-]+/gi
        numeratore = ragSoc.trim().replace(/\s\s+/g, ' ').replace(pattern, '_').toLowerCase()
      } else {
        numeratore = await configurazioni.getNumeratore(tipoNumeratore, true)
      }
      return numeratore
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

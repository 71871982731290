<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" @salva="salva" :campi="campi" :config="config"></modale-form>
</template>

<script>
import ModaleForm from '../ModaleForm'
import usaSelezione from '@/machines/elementi/usaSelezione.js'
import _ from 'lodash'
// TODO: Va fatta generica, le operazioni devono essere fatte da dashboardmachine, le modali devono essere agnostiche
export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      clienteInModifica: null,
      campi: [],
      config: {
        disableEsciOnClick: true,
        salvaText: 'OK'
      }
    }
  },
  async created() {
  },
  async mounted() {
    var campi = [
      { campo: 'riferimento', model: 'riferimento', tipo: 'text', label: 'Riferimento', placeHolder: 'Inserire Riferimento..', validations: [{ nome: 'required' }] },
      { campo: 'dataMovimento', tipo: 'calendar', label: 'Data', placeHolder: 'GG / MM / AAAA', validations: [{ nome: 'required' }] }
    ]

    this.campi = campi
  },
  computed: {
    titoloModale() {
      return 'Carica Selezione'
    }
  },
  methods: {
    onKeyPress(button) {
    },
    annulla() {
      this.$emit('close')
    },
    async salva() {
      var form = this.$refs.form
      var err = form.valida()
      if (err && err.length) {
        return
      }
      var dati = form.getDati()
      const causaleMovimento = _.get(this.context, 'impostazioni.articoli.usaSelezione.causaleMovimento', 'acquisto')
      var self = this
      try {
        await usaSelezione.acquisto(this.context.righeCarrello, causaleMovimento, dati.riferimento, dati.dataMovimento)

        this.$toast('Movimenti creati con successo', { type: 'success' })

        setTimeout(() => {
          self.annulla()
        }, 200)
      } catch (err) {
        this.$toast('Errore generazione movimento', { type: 'error' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <van-popup
    :value="show"
    position="right"
    :close-on-click-overlay="true"
    :closeable="true"
    close-icon="close"
    class="PopupCercaScontrino"
    close-icon-position="top-left"
    @click-close-icon="closePopup"
    @click-overlay="closePopup"
    @open="refresh"
    >
    <!-- @closed="closePopup" -->
    <div class="date-container">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="Scegli data e ora"
        confirm-button-text="Cerca"
        cancel-button-text="Chiudi"
        :show-toolbar="true"
        :min-date="minDate"
        :max-date="maxDate"
        :visible-item-count="4"
        @confirm="cercaScontrini"
        @cancel="closePopup"
      />
    </div>
    <!-- <div class="swipe-cell-container"> -->
    <van-cell title="MOSTRA SCONTRINI APERTI">
      <van-switch v-model="ancheScontriniAperti" size="24" active-color="blu" @change="onApertiChange"/>
    </van-cell>
    <van-list>
      <van-swipe-cell :before-close="beforeClose" v-for="(el, index) in elencoScontrini" :key="index" :class="{'scontrino-annullato': isAnnullato(el)}">
        <!-- <template #left v-if="el.stato !== 'annullamento-fiscale'"> -->
        <template #left v-if="el.stato !== 'annullamento-fiscale' && !isAperto(el)">
          <template v-if="el.stato !== 'non stampato'">
            <van-button square color="#ff4949" text="Reso" class="swipe-cell-button" @click="clickReso(el)"/>
            <van-button square color="#5badfa" text="Recupera" class="swipe-cell-button" @click="clickRecupera(el)" v-if="!isPresenzaResi(el)"/>
            <van-button square color="#328c32" text="Regalo" class="swipe-cell-button" @click="clickRegalo(el)"/>
          </template>
          <template v-else>
            <van-button square color="#00ee00" text="Ristampa" class="swipe-cell-button" @click="clickStampa(el)"/>
          </template>
        </template>
        <template #left v-else-if="isAperto(el)">
          <van-button square color="#ee9a00" text="Apri" class="swipe-cell-button" @click="clickApre(el)"/>
        </template>
        <van-cell
          :border="true"
          :title="titolo(el)"
          :label="label(el)"
          :value="value(el)"
        />
        <template #right v-if="el.stato !== 'annullamento-fiscale' && !isPresenzaResi(el)">
          <template v-if="el.stato !== 'non stampato' && !isAperto(el)">
            <van-button square color="#5badfa" text="Annulla" class="swipe-cell-button" @click="clickAnnulla(el)"/>
          </template>
          <template v-else>
            <van-button square color="#00ee00" text="Cancella" class="swipe-cell-button" @click="clickCancella(el)"/>
          </template>
        </template>
      </van-swipe-cell>
    </van-list>
    <!-- </div> -->
  </van-popup>
</template>

<script>
import moment from 'moment'
import errorService from '@/services/errorService'
import scontrini from '@/services/scontriniService.js'

export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      maxDate: new Date(),
      currentDate: new Date(),
      elencoScontrini: [],
      ancheScontriniAperti: false
    }
  },
  async mounted() {
    // await this.refresh()
  },
  computed: {
    minDate() {
      // TODO: Verificare per Solenoide, errore : this.minDate.getTime is not a function
      try {
        const minD = moment().subtract(12, 'months').utcOffset(0, true).toDate() || new Date(2022, 0, 1)
        errorService.onInfo(`Valore minDate: ${minD}`, 'PopupCercaScontrino')
        return minD
      } catch (err) {
        errorService.onError('Errore minDate (ritorno fissa): ' + JSON.stringify(err), 'PopupCercaScontrino')
        return new Date(2022, 0, 1)
      }
    }
  },
  methods: {
    async refresh() {
      this.currentDate = new Date()
      await this.cercaScontrini()
    },
    closePopup() {
      this.$emit('close')
    },
    async cercaScontrini() {
      const data = moment(this.currentDate).add(2, 'hours').toJSON()
      const elenco = await scontrini.elencoScontriniPerData(null, data)

      if (this.ancheScontriniAperti) {
        this.elencoScontrini = elenco
      } else {
        this.elencoScontrini = elenco.filter(x => x.stato !== 'aperto')
      }
    },
    titolo(item) {
      if (item.fiscale && item.fiscale.ricerca) {
        return `Scontrino nr ${item.fiscale.ricerca.documento} del ${moment(item.fiscale.data).format('DD/MM/YYYY HH:mm')}`
      } else {
        const data = moment(item._id.slice(0, item._id.indexOf('+')))
        return `Scontrino del ${moment(data).format('DD/MM/YYYY HH:mm')}`
      }
    },
    label(item) {
      return `Stato: ${item.stato}, ${item.statoPagamento} ${this.isPresenzaResi(item) ? ', presenza resi' : ''}`
    },
    value(item) {
      return item.pagamento && item.pagamento.totale ? '€ ' + item.pagamento.totale.toFixed(2) : '---'
    },
    isAnnullato(item) {
      return item.stato === 'annullamento-fiscale'
    },
    isPresenzaResi(item) {
      return item.resi && item.resi.length > 0
    },
    isAperto(item) {
      return item.stato === 'aperto'
    },
    async onApertiChange() {
      await this.refresh()
    },
    beforeClose({ position, instance }) {
      switch (position) {
        case 'left':
        case 'cell':
        case 'outside':
          instance.close()
          break
        case 'right':
          instance.close()
          /*           this.$dialog.confirm({
            message: 'Confermi annullamento fiscale scontrino ?',
            cancelButtonText: 'NO',
            confirmButtonText: 'SI'
          }).then(() => {
            instance.close()
          }) */
          break
      }
    },
    clickReso(item) {
      const event = {
        type: 'POPUP_RESO_SCONTRINO',
        data: item
      }
      this.$emit('send-event', event)
    },
    clickRecupera(item) {
      const event = {
        type: 'POPUP_RECUPERO_SCONTRINO',
        data: item
      }
      this.$emit('send-event', event)
    },
    clickRegalo(item) {
      const event = {
        type: 'POPUP_SCONTRINO_CORTESIA',
        data: item
      }
      this.$emit('send-event', event)
    },
    clickStampa(item) {
      const event = {
        type: 'POPUP_STAMPA_SCONTRINO_NON_STAMPATO',
        data: item
      }
      this.$emit('send-event', event)
    },
    clickApre(item) {
      const event = {
        type: 'POPUP_APRI_SCONTRINO',
        data: item
      }
      this.$emit('send-event', event)
    },
    /*     clickAnnulla(item) {
      if (item.stato !== 'non stampato') {
        this.annullamentoFiscale(item)
      } else {
        this.elimina(item)
      }
    }, */
    clickAnnulla(item) {
      this.$dialog.confirm({
        message: 'Confermi annullamento fiscale scontrino ?',
        cancelButtonText: 'NO',
        confirmButtonText: 'SI'
      }).then(() => {
        const event = {
          type: 'POPUP_ANNULLAMENTO_SCONTRINO',
          data: item
        }
        this.$emit('send-event', event)
      }).catch(() => {})
    },
    clickCancella(item) {
      this.$dialog.confirm({
        message: 'Confermi eliminazione scontrino ?',
        cancelButtonText: 'NO',
        confirmButtonText: 'SI'
      }).then(() => {
        const event = {
          type: 'POPUP_ELIMINAZIONE_SCONTRINO',
          data: item
        }
        this.$emit('send-event', event)
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../popup.scss";
  @import "./style.scss";

  .date-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    width: 500px;
    height: 250px;
  }

  .swipe-cell-button {
    height: 100%;
  }

  .scontrino-annullato .van-cell {
    background-color: #eeeeee;
  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .PopupCercaScontrino {
      width: 43%;
      height: 90%;
    }
  }
  @media all and (min-width:1024px) {
    .PopupCercaScontrino {
      width: 43%;
      height: 90%;
      & .van-cell {
        font-size: 13px;
      }
    }
  }
  @media all and (min-width:1400px) {
    .PopupCercaScontrino {
      width: 35%;
      height: 90%;
    }
  }
</style>

<template>
  <div class="tasto" :style="btnPosition" v-if="visible" :class="{'help-tasto' : !!helpTasto}">
    <div v-if="infoTasti" class="debugText">{{action.type || variableToDisplay}}</div>
    <div v-if="isPage" class="flag-page"></div>
    <img v-if="image" :src="calcImage" class="button-image" :style="imgStyle" @click="onClick"/>
    <button v-if="isButton"
      style="width:100%; height: 100%;"
      @click="onClick"
      @dblclick="onDblClick"
      v-longclick="onLongClick"
      :class="[ aspectTestCondition ? '' : aspect, { disabled: disabled }, validDisplay ? aspectIfValid : '', aspectTestCondition ? aspectIfCondition : '']"
      v-html="displayedText"
      ref="btnTasto"
    >
    </button>
    <input v-else
      :value="displayedText"
      ref="displayText"
      autofocus
      style="width:100%; height: 100%; text-align:center;"
      @click="onClick"
      @dblclick="onDblClick"
      v-longclick="onLongClick"
      :class="[ aspectTestCondition ? '' : aspect, { disabled: disabled }, validDisplay ? aspectIfValid : '', aspectTestCondition ? aspectIfCondition : '']"
    />
          <!-- @focus="value=''" -->
    <!-- @keyup.prevent="onKeyUp" -->
    <div v-if="attribute" class="attribute">{{attribute}}</div>
    <div v-if="title2th" class="smallText" :class="aspect2th ? aspect2th : ''">{{title2th}}</div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    row: { type: Number, required: true }, // Inserire eventuali validatori
    col: { type: Number, required: true }, // es validator: function(value) { return value > 0 && value <= 6 }
    row_span: { type: Number, required: true },
    col_span: { type: Number, required: true },
    aspect: { type: String, default: '' },
    aspect2th: { type: String, default: '' },
    aspectIfValid: { type: String, default: '' },
    aspectIfCondition: { type: String, default: '' },
    aspectTestCondition: Boolean,
    title: String,
    title2th: { type: String, default: '' },
    action: Object,
    status: Object,
    attribute: String,
    hiddenTestCondition: Boolean,
    infoTasti: Boolean,
    disabled: { type: Boolean, default: false },
    variableToDisplay: { default: '' },
    valueToDisplay: { default: '' },
    maxChar: { type: Number, default: 0 },
    displayFocus: { type: Number, default: 0 },
    scontrinoTick: { type: Number, default: 0 },
    helpEnabled: { type: Boolean, default: false },
    image: { type: String, default: '' }
  },
  data() {
    return {
      datenow: '',
      timenow: '',
      interval: null,
      btnWidth: 0,
      btnHeight: 0,
      posImage: 5 // da 1 a 9 : 1=(sx, alto), 2=(centro, alto), 3=(dx, alto), 4=(sx, centro), ...
    }
  },
  watch: {
    dispFocus() {
      const ds = this.$refs.displayText
      if (ds) {
        this.$refs.displayText.focus()
      }
    }
  },
  mounted() {
    if (this.valueToDisplay && ['datenow', 'timenow'].includes(this.valueToDisplay)) {
      this.interval = setInterval(this.time, 1000)
    }

    if (this.image) {
      const tasto = this.$refs.btnTasto
      this.btnWidth = tasto.offsetWidth
      this.btnHeight = tasto.offsetHeight
    }
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval)
  },
  computed: {
    stick() {
      return this.scontrinoTick
    },
    dispFocus() {
      return this.displayFocus
    },
    isButton() {
      return this.aspect !== 'bigDisplay' && this.stick > -1
    },
    btnPosition() {
      return `grid-area: ${this.row} / ${this.col} / span ${this.row_span} / span ${this.col_span}; overflow: hidden;`
    },
    imgStyle() {
      const { imgWidth, imgHeight, mTop, mLeft } = this.calcTopLeft()

      return `width: ${imgWidth}px; height: ${imgHeight}px; margin-top: ${mTop}px; margin-left: ${mLeft}px;`
    },
    attributeValue() {
      return '0000'
    },
    displayedText() {
      switch (this.valueToDisplay) {
        case '':
          if (!this.maxChar) return this.title
          return this.maxChar > 0 ? this.title.slice(0, this.maxChar) : this.title.slice(this.maxChar)
        case 'datenow':
          return this.datenow
        case 'timenow':
          return this.timenow
        default:
          if (!this.maxChar) return this.valueToDisplay
          return this.maxChar > 0 ? this.valueToDisplay.slice(0, this.maxChar) : this.valueToDisplay.slice(this.maxChar)
      }
    },
    validDisplay() {
      return this.displayedText !== ''
    },
    visible() {
      return !this.hiddenTestCondition
    },
    isPage() {
      return this.action && this.action.type && ['PRODOTTI_PAGINA', 'REPARTI_PAGINA'].includes(this.action.type)
    },
    helpTasto() {
      return this.helpEnabled && this.action && !!this.action.help
    },
    calcImage() {
      return `/img/tasti/${this.image}`
    }
  },
  methods: {
    time() {
      this.datenow = moment().format('L')
      this.timenow = moment().format('HH:mm')
    },
    onClick(e) {
      !this.disabled && this.action.type && this.sendToMachine(this.action.type)
    },
    onDblClick(e) {
      // if (!e.target.classList.contains('disabled')) {
      !this.disabled && this.action.typedbl && this.sendToMachine(this.action.typedbl)
    },
    onLongClick(e) {
      // if (!e.target.classList.contains('disabled')) {
      !this.disabled && this.action.type2th && this.sendToMachine(this.action.type2th)
    },
    onKeyUp(e) {
      e.stopPropagation()
      console.log('txt keyup', e.keyCode, e.key)
      // TODO RIMETTERE
      /* if (e.keyCode >= 48 && e.keyCode <= 90) {
        const event = {
          type: 'DIGITA_SINGOLA',
          value: e.keyCode
        }
        this.$emit('send-event', event)
      }
      if (e.keyCode === 13) {
        const event = {
          type: 'CODE' // richiamare evento che accoda barcode
        }
        this.$emit('send-event', event)
      } */
      /*       if (e.keyCode === 13) {
        const event = {
          type: 'DIGITA', // richiamare evento che accoda barcode
          value: '8033379287538'
        }
        this.$emit('send-event', event)
        this.resetValue = true
      } */
      // se 13 richiamare DIGITA, altrimenti solo tasto senza return
    },
    sendToMachine(actionType) {
      let event = {
        type: actionType,
        value: this.action.payload
      }
      if (this.helpTasto && this.action.help !== 'toggleHelp') {
        event = {
          type: 'DISPLAY_CONTEXTUAL_HELP',
          value: this.action.help
        }
      }
      this.$emit('send-event', event)
    },
    calcTopLeft() {
      let mTop = 0
      let mLeft = 0
      let imgWidth = this.btnWidth / 2
      let imgHeight = this.btnHeight / 2
      let deltaW = this.btnWidth - imgWidth
      let deltaH = this.btnHeight - imgHeight
      switch (this.posImage) {
        case 1:
          mTop = 0
          mLeft = 0
          break
        case 2:
          mTop = 0
          mLeft = deltaW / 2
          break
        case 3:
          mTop = 0
          mLeft = deltaW
          break
        case 4:
          mTop = deltaH / 2
          mLeft = 0
          break
        case 5:
          mTop = deltaH / 3
          mLeft = deltaW / 3
          imgWidth = this.btnWidth * 2 / 3
          imgHeight = this.btnHeight * 2 / 3
          break
        case 6:
          mTop = deltaH / 2
          mLeft = deltaW
          break
        case 7:
          mTop = deltaH
          mLeft = 0
          break
        case 8:
          mTop = deltaH
          mLeft = deltaW / 2
          break
        case 9:
          mTop = deltaH
          mLeft = deltaW
          break
        default:
          break
      }
      deltaW = this.btnWidth - imgWidth
      deltaH = this.btnHeight - imgHeight
      return { imgWidth, imgHeight, mTop, mLeft }
    }
  }
}
</script>

<style scoped>
  .tasto {
    margin: 0 0 6px 6px;
  }

  button {
    padding: 0 10px;
  }

  button.default-hidden:not(.visible) {
    display: none;
  }

  .smallText {
    top: -1.6em;
    right: 0.8em;
    position: relative;
    float: right;
    font-weight: 400;
    font-family: Arial;
    color: #666666;
    /*  MANUEL: tolto font-family: 'Montserrat'; per uniformarlo...smallText sta anche sui css delle modali, è corretto metterlo su più parti ?! */
  }

  .debugText {
    align-self: start;
    position: absolute;
    margin-top: 4px;
    margin-left: 4px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 10px;
    color: red;
  }

  .attribute {
    top: -23px;
    text-align: center;
    position: relative;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 6px 6px;
    color: red
  }

  .disabled {
    opacity: 0.5;
/*     background-color:#00000050 ;
    color:#00000050 ; */
  }

  .align-left {
    text-align: left;
  }

  .align-right {
    text-align: right;
  }

  @media all and (min-width:768px) {
    button {padding: 0 5px;}
  }

  .flag-page{
    align-self: start;
    position: absolute;

    /* Freccia */
/*  width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f00; */

    /* Bandiera */
    background-color: red;
    height: 10px;
    margin-top: 1px;
    margin-left: 1px;
    width: 10px;
    border-radius: 3px 0 0 0;
  }

  /* Versione per icona sul tasto */
/*   .help-tasto {
    position: absolute;
    margin-top: 1 px;
    margin-left: 1 px;
  } */

  .help-tasto {
    z-index: 2001;
  }

  .button-image {
    position: absolute;
  }

  .button-image2 { /* non usato per adesso */
    /* background-color: #cccccc;  *//* Used if the image is unavailable */
    /* height: 500px; */ /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
</style>

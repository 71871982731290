<template>
  <div class="gruppoTasti" :class="[{ selected: isSelected }, zona]" @click="clickOnGrid" :style="cursorStyle">
    <tasto
      v-for="(ts, index) in tasti"
      :key="index"
      :index="index"
      :ts="ts"
      :row="ts.row"
      :col="ts.col"
      :row_span="ts.row_span"
      :col_span="ts.col_span"
      :aspect="ts.aspect"
      :aspectIfValid="ts.aspectIfValid"
      :title="getTitleVariable(ts.title)"
      :title2th="getTitleVariable(ts.title2th)"
      :action="ts.action"
      :image="ts.image"
      :attribute="getAttributeValue(ts.attribute)"
      :variableToDisplay="ts.variableToDisplay"
      :maxChar="ts.maxChar"
      :disabled="testDisable(ts.disabled)"
      :locked="testLockedByRole(ts)"
      :valueToDisplay="getContextValue(ts.variableToDisplay)"
      :aspectIfCondition="getAspectIfCondition(ts.aspectTestCondition, ts.aspectIfCondition, ts.forceAspect)"
      :aspectTestCondition="getAspectTestCondition(ts.aspectTestCondition).value"
      :infoTasti="context.debug.infoTasti"
      :hiddenTestCondition="ts.hiddenTestCondition"
      :forzaTastoHidden="ts.forzaTastoHidden"
      :zona="zona"
      :itemSelected="itemSelected"
      v-show="tastoVisibileInPagina(ts)"
      @send-event="sendEventToParent"
      @selected="tastoSelected"
      @select-tasto="onSelectTasto"
      @deselect-tasto="onDeselectTasto"
    />

  </div>
</template>

<script>
import Tasto from './TastoEdit.vue'
import _ from 'lodash'
import configurazioni from '@/services/configurazioniService'

export default {
  components: {
    Tasto
  },
  props: {
    context: Object,
    tasti: Array,
    zona: String,
    paginaVisualizzata: Number
  },
  data() {
    return {
      showEditTasto: false,
      item: null,
      // indexSelected: 0,
      isSelected: false,
      itemSelected: null,
      tastiLocali: [],
      // paginaVisualizzata: 1,
      trigger: 1,
      triggerEdit: 1,
      modello: {
        row: 1,
        col: 1,
        row_span: 1,
        col_span: 1,
        aspect: '',
        title: 'Nuovo Tasto',
        title2th: '',
        action: {},
        posClick: null
      }
    }
  },
  mounted() {
    // this.tastiLocali = this.tasti.map(x => { return { codiceTasto: uuidv4(), ...x } }) // completare
    this.tastiLocali = this.tasti
    if (this.zona === 'tasti' && this.tastiLocali.length > 0) {
      // this.tastoSelected(this.tastiLocali[0], true)
    }
    // console.log('nrGruppo', this.nrGruppo)
  },
  computed: {
    tastiVisibili() {
      return this.trigger ? this.tastiLocali.filter(x => x.status !== 'deleted' && (!x.page || x.page === this.paginaVisualizzata ||
        (Array.isArray(x.page) && x.page.indexOf(this.paginaVisualizzata) >= 0))) : []
    },
    cursorStyle() {
      return this.trigger && this.posClick ? 'cursor: nwse-resize;' : ''
      // return 'cursor: nwse-resize;'
    }
  },
  methods: {
    tastoVisibileInPagina(ts) {
      var p = ts.page
      if (!p || p === this.paginaVisualizzata || (Array.isArray(p) && p.indexOf(this.paginaVisualizzata) >= 0)) {
        return true
      }
      return false
    },
    tastoSelected(item, show, codiceTasto) {
      this.showEditTasto = show
      this.item = show ? item : null
      this.itemSelected = show ? item.codiceTasto : null
      this.triggerEdit++
    },
    select() {
      this.isSelected = true
      // add classe per outline
    },
    deselect() {
      this.isSelected = false
      // remove classe per outline
    },
    onSelectTasto(item, tastoVue) {
      this.$emit('select-tasto', tastoVue, this)
    },
    onDeselectTasto() {

    },
    getTitleVariable(title) {
      if (!title.startsWith('@')) {
        return title
      }
      const variable = title.substring(1)
      return this.getContextValue(variable)
    },
    getAttributeValue(attribute) {
      return _.get(this.context.attributes, attribute) || ''
    },
    getContextValue(variable) {
      if (!variable) return ''
      if (variable && variable.startsWith('#')) {
        return variable.substring(1)
      }
      if (variable && variable.startsWith('€')) {
        const value = _.get(this.context, variable.substring(1))
        return value ? value.toFixed(2) : ''
      }
      if (variable && variable.startsWith('@')) {
        const operands = variable.substring(1).split(' eq ')
        if (operands.length === 2) {
          if (operands[0].startsWith('pageList')) {
            return this.paginaVisualizzata === parseInt(operands[1])
          } else {
            return String(_.get(this.context, operands[0])) === operands[1]
          }
        } else {
          return false
        }
      }
      // console.log(variable, _.get(this.context, variable))
      return _.get(this.context, variable) || ''
    },
    getAspectIfCondition(itemTest, item, force) {
      if (!itemTest) return ''
      const index = this.getAspectTestCondition(itemTest, force).index
      if (index !== -1) {
        const values = item.split(',')
        return values.length > index ? values[index] : ''
      } else {
        return ''
      }
    },
    getAspectTestCondition(itemTest, force) {
      if (!itemTest) return { index: -1, value: false }
      // if (force) return { index: -1, value: true }

      const tests = itemTest.split(',')
      for (let index = 0; index < tests.length; index++) {
        let varName = tests[index]
        let neg = false
        if (varName.startsWith('!')) {
          varName = varName.substring(1)
          neg = true
        }
        let cond = this.getContextValue(varName) === true
        cond = cond || force
        if (neg ? !cond : cond) {
          return { index, value: true }
        }
      }
      return { index: -1, value: false }
    },
    testDisable(item) {
      let disabled = false
      try {
        if (item) {
          disabled = (this.context[item.variable] === item.value)
        }
      } catch (ex) {

      }
      return disabled
    },
    testLockedByRole(item) {
      const ruoloTasto = item.ruoloTasto && item.ruoloTasto.length > 0
      const ruoliDisab = item.ruoliDisab && item.ruoliDisab.length > 0
      const utentiDisab = item.utentiDisab && item.utentiDisab.length > 0
      return ruoloTasto || ruoliDisab || utentiDisab ? (ruoloTasto ? 1 : 2) : 0
    },
    onCambioPagina(pagina) {
      const event = {
        type: 'PRODOTTI_PAGINA',
        value: {
          page: pagina,
          zona: this.zona,
          toggle: false
        }
      }
      this.sendEventToParent(event)
    },
    async onComando(comando) {
      try {
        const daSalvare = this.tastiLocali.map(({ forceHidden, forceAspect, ...x }) => x)
        switch (comando) {
          case 'salva':
            // Attenzione, togliere forceHidden e forceAspect
            await this.$dialog.confirm({ title: 'Salvataggio dati', message: 'Confermi salvataggio layout attuale ?' })
            await configurazioni.salvaGruppoTasti(this.context.layoutTasti, this.zona, daSalvare)
            break
          case 'annulla':
            await this.$dialog.confirm({ title: 'Annullamento modifiche', message: 'Confermi l\'annullamento di tutte le modifiche effettuate ?' })
            this.mounted()
            break
          case 'ricarica':
            await this.$dialog.confirm({ title: 'Refresh pagina', message: 'Tutte le modifiche non salvate verranno eliminate' })
            this.$router.go(0)
            break
          default:
            break
        }
      } catch {

      }
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    },
    closePopup() {
      this.showEditTasto = false
      // this.indexSelected = 0
      this.itemSelected = null
    },
    clickOnGrid(e) {
      if (!['tasti', 'tasti1', 'tasti2'].includes(this.zona) || !e.target.className.startsWith('gruppoTasti')) return

      this.$emit('select-zona', this)
    }
  }
}
</script>

<style scoped lang="scss">

.gruppoTasti.selected {
  outline: 7px solid orange;
}
</style>

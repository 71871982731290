<template>
  <van-cell-group>
    <van-form @submit="onSubmit">
      <van-field
        readonly
        clickable
        :value="$v.magazzino.$model"
        name="magazzino"
        ref="magazzino"
        label="Magazzino"
        placeholder="Magazzino"
        :error-message="$v.magazzino.$error ? 'Valore mancante' : magazzinoDescrizione"
        :class="{ 'mostra-descrizione': !$v.magazzino.$error}"
        @click="showMagazzino = true"
      />
      <van-popup v-model="showMagazzino" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar title="Magazzino" :columns="columnsMagazzino" @confirm="onConfirmMagazzino" @cancel="showMagazzino = false"/>
      </van-popup>

      <van-field
        readonly
        clickable
        name="data"
        :value="$v.data.$model"
        label="Data"
        placeholder="Data movimento"
        @click="showCalendar = true"
      />
      <van-calendar v-model="showCalendar" @confirm="onConfirmData" :style="getStilePopup()"/>

      <van-field
        readonly
        clickable
        :value="$v.causale.$model"
        name="causale"
        ref="causale"
        label="Causale"
        placeholder="Causale"
        :error-message="$v.causale.$error ? 'Valore mancante' : causaleDescrizione"
        :class="{ 'mostra-descrizione': !$v.causale.$error}"
        @click="showCausale = true"
      />
      <van-popup v-model="showCausale" position="bottom" :style="getStilePopup()" visible-item-count="8">
        <van-picker show-toolbar title="Causale" :columns="columnsCausale" @confirm="onConfirmCausale" @cancel="showCausale = false"/>
      </van-popup>

      <van-search
        v-model="$v.prodotto.$model"
        show-action
        name="prodotto"
        ref="prodotto"
        label="Prodotto"
        placeholder="Prodotto"
        :error-message="prodotto ? prodottoDescrizione : ''"
        @search="onSearchProdotto"
        @clear="onClearProdotto"
        @focus="onFocus"
      >
      <template #action>
          <div @click="onSearchProdotto">Cerca</div>
        </template>
      </van-search>

      <van-field
        readonly
        clickable
        :value="$v.um.$model"
        name="um"
        ref="um"
        label="Unità di Misura"
        placeholder="UM"
        :error-message="$v.um.$error ? 'Valore mancante' : umDescrizione"
        :class="{ 'mostra-descrizione': !$v.um.$error}"
        @click="showUm = true"
      />
      <van-popup v-model="showUm" position="bottom" :style="getStilePopup()" visible-item-count="8">
        <van-picker show-toolbar title="Unità di Misura" :columns="columnsUm" @confirm="onConfirmUm" @cancel="showUm = false"/>
      </van-popup>

      <van-field v-model.trim="$v.quantita.$model"
        name="quantita"
        ref="quantita"
        clearable
        type="number"
        label="Quantita"
        placeholder="Quantita"
        @focus="onFocus"
        :error-message="$v.quantita.$error ? 'Valore mancante o non valido' : ''"
      />
      <van-field v-model.trim="$v.prezzoAcquisto.$model"
        v-if="mostraPrezzoAcquisto"
        name="prezzoAcquisto"
        ref="prezzoAcquisto"
        clearable
        type="number"
        label="Prezzo acquisto"
        placeholder="Prezzo"
        @focus="onFocus"
        :error-message="$v.prezzoAcquisto.$error ? 'Valore mancante o non valido' : ''"
      />
      <van-field v-model.trim="$v.prezzoVendita.$model"
        v-if="mostraPrezzoVendita"
        name="prezzoVendita"
        ref="prezzoVendita"
        clearable
        type="number"
        label="Prezzo vendita"
        placeholder="Prezzo"
        @focus="onFocus"
        :error-message="$v.prezzoVendita.$error ? 'Valore mancante o non valido' : ''"
      />
      <van-search
        v-if="mostraCliente"
        v-model="$v.cliente.$model"
        show-action
        name="cliente"
        ref="cliente"
        label="Cliente"
        placeholder="Cliente"
        :error-message="cliente ? clienteDescrizione : ''"
        @search="onSearchCliente"
        @clear="onClearCliente"
        @focus="onFocus"
      >
        <template #action>
          <div @click="onSearchCliente">Cerca</div>
        </template>
      </van-search>
      <van-search
        v-if="mostraFornitore"
        v-model="$v.fornitore.$model"
        show-action
        name="fornitore"
        ref="fornitore"
        label="Fornitore"
        placeholder="Fornitore"
        :error-message="fornitore ? fornitoreDescrizione : ''"
        @search="onSearchFornitore"
        @clear="onClearFornitore"
        @focus="onFocus"
      >
        <template #action>
          <div @click="onSearchFornitore">Cerca</div>
        </template>
      </van-search>
<!--       <van-list>
      </van-list> -->
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit">Crea movimento</van-button>
      </div>
    </van-form>
  </van-cell-group>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import errorService from '@/services/errorService'
import { validationMixin } from 'vuelidate'
// import { helpers, required, numeric, email, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { required } from 'vuelidate/lib/validators'

import movimenti from '@/services/movimentiService.js'
import magazzino from '@/services/magazzinoService.js'
import clienti from '@/services/clientiService.js'
import prodotti from '@/services/prodottiService.js'
// import tabelle from '@/services/tabelleService.js'
import configurazioni from '@/services/configurazioniService.js'
import { dynamicSortMultiple } from '@/components/helpers.js'

export default {
  mixins: [validationMixin],
  props: {
    context: Object
  },
  data() {
    return {
      showMagazzino: false,
      showCalendar: false,
      showCausale: false,
      showTipoMovimento: false,
      showUm: false,
      mostraCliente: false,
      mostraFornitore: false,
      mostraPrezzoAcquisto: false,
      mostraPrezzoVendita: false,
      magazzini: [],
      causali: [],
      unitaMisura: [],
      magazzino: '',
      magazzinoDescrizione: '',
      data: '',
      causale: '',
      causaleDescrizione: '',
      prodotto: '',
      prodottoDescrizione: '',
      prodottoItem: null,
      um: '',
      umDescrizione: '',
      quantita: '',
      prezzoAcquisto: '',
      prezzoVendita: '',
      cliente: '',
      clienteDescrizione: '',
      fornitore: '',
      fornitoreDescrizione: '',
      submitStatus: null,
      activeField: 'magazzino',
      activeTab: 'nuovo',
      activeParent: 'movimenti'
    }
  },
  validations: {
    magazzino: { required },
    data: { required },
    causale: {},
    prodotto: { required },
    um: { required },
    quantita: { required },
    prezzoAcquisto: {}, // richiesto in base alla causale
    prezzoVendita: {}, // richiesto in base alla causale
    cliente: {}, // richiesto in base alla causale
    fornitore: {} // richiesto in base alla causale
  },
  directives: {
    uppercase: {
      update (el) {
        el.value = el.value.toUpperCase()
      }
    }
  },
  created() {
    this.debounceInputProdotti = _.debounce(this.getElencoProdotti, 500)
    this.debounceInputClienti = _.debounce(this.getElencoClienti, 500)
    this.debounceInputFornitori = _.debounce(this.getElencoFornitori, 500)
  },
  async mounted() {
    try {
      // this.$store.commit('SHOW_POPUP_LIST_RESULT')
      this.magazzini = await configurazioni.getConfigurazione('magazzino_magazzini', 'magazzini')
      this.causali = await configurazioni.getConfigurazione('magazzino_causali', 'causali')
      this.unitaMisura = await configurazioni.getConfigurazione('prodotti_unita_misura', 'um')
    } catch {
      errorService.onError('Tabelle di base non trovate', 'mounted nuovo movimento')
    }
    this.onOpen()
    this.$refs.magazzino.focus()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.count : 0
    },
    columnsMagazzino() {
      const v = this.magazzini
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    columnsCausale() {
      const v = this.causali
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    columnsUm() {
      const v = this.unitaMisura
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione) // TODO: Filtrare quelle ammesse dal prodotto
    },
    listResult() {
      return this.$store.getters.listResult
    }
  },
  watch: {
    kbInput(value) {
      const variabile = this.activeField
      const valore = String(value)
      this[variabile] = valore
      this.handleKey(valore)
    },
    kbKeyPress(value) {
      if (value === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    },
    kbCounter(value) {
      if (this.kbKeyPress === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    },
    listResult(payload) {
      console.log('movimenti', payload.parent, this.activeParent, payload.tab, this.activeTab)
      if (payload.parent !== this.activeParent || payload.tab !== this.activeTab) return
      const item = payload.result
      console.log('listResult movimenti', this.activeField, item)
      let um
      switch (this.activeField) {
        case 'prodotto':
          this.prodotto = item.codice
          this.prodottoDescrizione = item.descrizione
          this.prodottoItem = item
          this.um = item.unitaMisura
          um = this.unitaMisura.find(x => x.codice === item.unitaMisura)
          this.umDescrizione = um ? um.descrizione : ''
          this.prezzoAcquisto = item.prezzoAcquisto
          this.prezzoVendita = item.prezzoVendita
          if (this.mostraFornitore) {
            this.fornitore = item.fornitore || ''
            this.fornitoreDescrizione = item.fornitoreDescrizione || ''
          }
          break
        case 'cliente':
          this.cliente = item.codice
          this.clienteDescrizione = item.descrizione
          break
        case 'fornitore':
          this.fornitore = item.codice
          this.fornitoreDescrizione = item.descrizione
          break
        default:
          break
      }
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
    }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.tab-popup-funzioni')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    handleKey(valore) {
      switch (this.activeField) {
        case 'prodotto':
          this.debounceInputProdotti(valore)
          break
        case 'cliente':
          this.debounceInputClienti(valore)
          break
        case 'fornitore':
          this.debounceInputFornitori(valore)
          break
        default:
          break
      }
    },
    onConfirmMagazzino(value) {
      this.showMagazzino = false
      const magazzino = this.magazzini.find(x => x.descrizione === value)
      this.magazzino = magazzino.codice
      this.magazzinoDescrizione = magazzino.descrizione
    },
    onConfirmData(value) {
      this.data = moment(value).format('DD/MM/YYYY')
      this.showCalendar = false
    },
    onConfirmCausale(value) {
      this.showCausale = false
      const causale = this.causali.find(x => x.descrizione === value)
      this.causale = causale.codice
      this.causaleDescrizione = causale.descrizione
      this.mostraCliente = causale.visualizzaCliente
      this.mostraFornitore = causale.visualizzaFornitore
      this.mostraPrezzoAcquisto = causale.aggiornaPrezzoAcquisto
      this.mostraPrezzoVendita = causale.aggiornaPrezzoVendita
      // TODO: Gestione prezzo netto
    },
    onConfirmUm(value) {
      this.showUm = false
      const um = this.unitaMisura.find(x => x.descrizione === value)
      this.um = um.codice
      this.umDescrizione = um.descrizione
    },
    onSearchProdotto() {
      this.getElencoProdotti(this.prodotto)
    },
    onSearchCliente() {
      this.getElencoClienti(this.cliente)
    },
    onSearchFornitore() {
      this.getElencoFornitori(this.fornitore)
    },
    onClearProdotto() {
      this.prodottoDescrizione = ''
    },
    onClearCliente() {
      this.clienteDescrizione = ''
    },
    onClearFornitore() {
      this.fornitoreDescrizione = ''
    },
    async getElencoProdotti(value) {
      if (!value) {
        this.elencoProdotti = []
      } else {
        const result = await prodotti.searchProdotti(value)
        if (result && result.docs && result.docs.length > 0) {
          const lista = result.docs.map(item => {
            const label = []
            if (item.nome) label.push(item.nome)
            if (item.varianti && item.varianti.variante1) label.push(item.varianti.variante1.descrizione)
            if (item.varianti && item.varianti.variante2) label.push(item.varianti.variante2.descrizione)
            if (item.statistici && item.statistici.categoria1) label.push(item.statistici.categoria1.descrizione)
            if (item.statistici && item.statistici.categoria2) label.push(item.statistici.categoria2.descrizione)
            if (item.statistici && item.statistici.categoria3) label.push(item.statistici.categoria3.descrizione)
            if (item.statistici && item.statistici.categoria4) label.push(item.statistici.categoria4.descrizione)
            const fornitore = item.ultimoAcquisto && item.ultimoAcquisto.fornitore ? item.ultimoAcquisto.fornitore : ''
            let fornitoreDescrizione = ''
            if (fornitore) {
              const fr = item.fornitori.find(x => x.codice === fornitore)
              if (fr) {
                fornitoreDescrizione = fr.descrizione
              }
            }
            return {
              codice: item.codice,
              descrizione: item.descrizione,
              prezzoAcquisto: item.ultimoAcquisto && item.ultimoAcquisto.prezzo ? item.ultimoAcquisto.prezzo : '',
              prezzoVendita: item.prezzo && item.prezzo.L1 ? item.prezzo.L1.base : '', // TODO: gestire un prezzo unitario oppure nome listino base su configurazioni ??
              unitaMisura: item.magazzino && item.magazzino.unitaMisura ? item.magazzino.unitaMisura : '',
              unitaMisuraAmmesse: item.magazzino && item.magazzino.unitaMisuraAmmesse ? item.magazzino.unitaMisuraAmmesse : [],
              fornitore,
              fornitoreDescrizione,
              titolo: `${item.codice} - ${item.descrizione}`,
              label: label.join(' - '),
              value: `${item.magazzino ? item.magazzino.giacenza || '---' : '---'}`
            }
          })
          this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
            list: lista,
            icon: 'records',
            classes: {
              titleClass: 'prodotti-lr-title',
              labelClass: 'prodotti-lr-label',
              valueClass: 'prodotti-lr-value'
            }
          })
        } else {
          console.log('getElencoProdotti noresult')
          this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
        }
      }
    },
    async getElencoClienti(value) {
      if (!value) {
        this.elencoClienti = []
      } else {
        const result = await clienti.searchClienti(value)
        if (result && result.docs && result.docs.length > 0) {
          const lista = result.docs.map(item => {
            return {
              codice: item.codice,
              descrizione: item.ragioneSociale,
              titolo: `${item.codice} - ${item.ragioneSociale}`,
              label: `${item.recapiti[0].indirizzo}`,
              value: `${item.amministrativi.partitaIva}`
            }
          })
          this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
            list: lista,
            icon: 'user-o',
            classes: {
              titleClass: 'clienti-lr-title',
              labelClass: 'clienti-lr-label',
              valueClass: 'clienti-lr-value'
            }
          })
        } else {
          console.log('getElencoClienti noresult')
          this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
        }
      }
    },
    async getElencoFornitori(value) {
      if (!value) {
        this.elencoFornitori = []
      } else {
        const result = await clienti.searchFornitori(value)
        if (result && result.docs && result.docs.length > 0) {
          const lista = result.docs.map(item => {
            return {
              codice: item.codice,
              descrizione: item.ragioneSociale,
              titolo: `${item.codice} - ${item.ragioneSociale}`,
              label: `${item.recapiti[0].indirizzo}`,
              value: `${item.amministrativi.partitaIva}`
            }
          })
          this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
            list: lista,
            icon: 'user-o',
            classes: {
              titleClass: 'fornitori-lr-title',
              labelClass: 'fornitori-lr-label',
              valueClass: 'fornitori-lr-value'
            }
          })
        } else {
          console.log('getElencoFornitori noresult')
          this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
        }
      }
    },
    onFocus(event) {
      console.log('onfocus')
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      // this.$store.commit('SHOW_POPUP_KEYBOARD')
      this.$v.$touch()
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
      this.prodottoDescrizione = this.prodotto ? this.prodottoDescrizione : ''
      this.clienteDescrizione = this.cliente ? this.clienteDescrizione : ''
      this.fornitoreDescrizione = this.fornitore ? this.fornitoreDescrizione : ''
    },
    async onSubmit(values) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast('Sono presenti alcuni errori, verificare i dati inseriti', { type: 'fail', duration: 20000 })
      } else {
        const movimento = await this.buildMovimento()
        await magazzino.creaMovimenti(movimento)
        this.$toast('Movimento creato con successo', { type: 'success' })

        this.clearFields()
      }
    },
    onOpen() {
      this.clearSearchInput()
      this.storeToLocal()
    },
    clearSearchInput() {
      this.prodotto = ''
      this.cliente = ''
      this.fornitore = ''
      this.prodottoDescrizione = ''
      this.clienteDescrizione = ''
      this.fornitoreDescrizione = ''
    },
    clearFields() {
      this.clearSearchInput()
      this.magazzino = ''
      this.magazzinoDescrizione = ''
      this.data = ''
      this.causale = ''
      this.causaleDescrizione = ''
      this.um = ''
      this.umDescrizione = ''
      this.quantita = ''
      this.prezzoAcquisto = ''
      this.prezzoVendita = ''
      this.mostraCliente = false
      this.mostraFornitore = false
      this.mostraPrezzoAcquisto = false
      this.mostraPrezzoVendita = false
      this.$v.$reset()
    },
    storeToLocal() {
      const movimento = this.$store.getters['movimenti/movimento']
      if (movimento) {
        this.magazzino = movimento.magazzino
        this.data = movimento.data
        this.causale = movimento.causale
        this.prodotto = movimento.prodotto.codice
        this.cliente = movimento.anagrafica.cliente
        this.fornitore = movimento.anagrafica.fornitore
      }
    },
    async buildMovimento() {
      const cl = await movimenti.getModello('movimento')
      cl.magazzino = this.magazzino
      cl.data = this.data
      cl.causale = this.causale
      cl.prodotto.codice = this.prodotto
      cl.prodotto.um = this.um
      cl.prodotto.quantita = +parseFloat(this.quantita).toFixed(2)
      cl.prodotto.prezzoUnitario = +parseFloat(this.prezzoAcquisto || this.prezzoVendita).toFixed(2) // TODO verificare
      cl.prodotto.prezzo = +(cl.prodotto.prezzoUnitario * parseFloat(this.quantita)).toFixed(2)
      cl.anagrafica.cliente = this.cliente
      cl.anagrafica.clienteDescrizione = this.clienteDescrizione
      cl.anagrafica.fornitore = this.fornitore
      cl.anagrafica.fornitoreDescrizione = this.fornitoreDescrizione
      return cl
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }

</style>

<template>
  <div class="scontrino">
    <carrello
      :righe="righe"
      :selezione="context.rigaSelezionata"
      :espanso="context.scontrinoEspanso"
      :reso="context.resoInCorso"
      :recupero="context.recuperoInCorso"
      :scontrinoTick="context.scontrinoTick"
      :tipoDocumento="context.tipoDocumentoDesc"
      :titoloScontrino="context.titoloScontrino"
      :isSubTotaleCalcolato="context.isSubTotaleCalcolato"
      :mostraPrezzo="mostraPrezzo"
      :tipoPrezzo="tipoPrezzo"
      @send-event="sendEventToParent"
    />
    <div v-if="debugMode" class="modalitaDebug shake animated infinite slow">ATTENZIONE !! Modalità debug attivata su: {{nomeProgettoDebug}}</div>
  </div>
</template>

<script>
import _ from 'lodash'
import Carrello from '@/components/scontrino/Carrello.vue'

export default {
  components: {
    Carrello
  },
  props: {
    context: Object
  },
  data() {
    return {
    }
  },
  computed: {
    mostraPrezzo() {
      // 0=mai, 1=sempre, 2=tasto toggle visualizzazione
      // valore
      if (this.context && this.context.impostazioni && this.context.impostazioni.carrello) {
        // const mostra = this.context.impostazioni.carrello.mostraPrezzoAcquisto
        const mostra = _.get(this, 'context.impostazioni.carrello.mostraPrezzo.visibile', false) || _.get(this, 'context.impostazioni.carrello.mostraPrezzoAcquisto', false)
        const toggle = this.context.togglePrezzoAcquisto
        return mostra === 1 || (mostra === 2 && toggle)
      }
      return false
    },
    tipoPrezzo() {
      return _.get(this.context, 'impostazioni.carrello.mostraPrezzo.tipo', 'acquisto')
    },
    debugMode() {
      return this.context.runMode && this.context.runMode.debugMode
    },
    nomeProgettoDebug() {
      let nome = (this.context && this.context.runMode && this.context.runMode.remote) || ''
      nome = nome.split('_').slice(0, -1).join('_')
      return nome
    },
    righe() {
      if (this.context.statistica && this.context.statistica.length > 0) {
        return this.context.statistica
      } else {
        return this.context.righeCarrello
      }
    }
  },
  methods: {
    sendEventToParent(event) {
      this.$emit('send-event', event)
    }
  }
}
</script>

<style scoped lang="scss">
  .modalitaDebug {
    width: 100%;
    text-align: center;
    color: red;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>

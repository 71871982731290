'use strict'
import errorService from '@/services/errorService'
import router from '../router'

// const service = process.env.VUE_APP_service_etichette || ''
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_etichette || process.env.VUE_APP_service_etichette
})()

async function getRunMode() {
  try {
    const response = await fetch(`${service}/etichette/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'ETICHETTE',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function stampaEtichetta(tipoStampa, report, dati, salta, tipoQuantita) {
  try {
    if (tipoStampa === 'pdf') {
      const source = `${service}/etichette/${tipoStampa}/${report}?par=${btoa(dati)}${salta ? '&sk=' + salta : ''}${tipoQuantita ? '&tpq=' + tipoQuantita : ''}`
      router.push({ name: 'Pdf', params: { source: source } })
      // window.open(`${service}/etichette/${tipoStampa}/${report}?par=${btoa(dati)}${salta ? '&sk=' + salta : ''}`)
      return ''
    } else {
      const response = await fetch(`${service}/etichette/${tipoStampa}/${report}?par=${btoa(dati)}${salta ? '&sk=' + salta : ''}${tipoQuantita ? '&tpq=' + tipoQuantita : ''}`)
      return await response.json()
    }
  } catch (error) {
    console.log('error stampaEtichetta', error)
    errorService.onError(error, 'stampaEtichetta')
    return ''
  }
}

async function stampaDiretta(tipoStampa, src) {
  try {
    if (tipoStampa === 'pdf') {
      router.push({ name: 'Pdf', params: { source: src } })
      return ''
    } else {
      const response = await fetch(src)
      return await response.json()
    }
  } catch (error) {
    console.log('error stampaDiretta', error)
    errorService.onError(error, 'stampaDiretta')
    return ''
  }
}

export default {
  getRunMode,
  stampaEtichetta,
  stampaDiretta
}

<template>
  <van-list>
    <van-form @submit="onSubmit">
      <van-field v-model.trim="$v.ragioneSociale.$model"
        name="ragioneSociale"
        ref="ragioneSociale"
        clearable
        label="Ragione sociale"
        placeholder="Ragione Sociale"
        :error-message="$v.ragioneSociale.$error ? 'Valore richiesto' : ''"
        :rules="[{trigger:'onBlur'}]"
        @focus="onFocus"
      />
      <van-field v-model.trim="$v.indirizzo.$model"
        name="indirizzo"
        ref="indirizzo"
        clearable
        label="Indirizzo"
        placeholder="Indirizzo"
        @focus="onFocus"
      />
      <van-field v-model.trim="$v.cap.$model"
        name="cap"
        ref="cap"
        clearable
        label="Cap"
        placeholder="Cap"
        :rules="[{trigger:'onBlur'}]"
        @focus="onFocus"
        :error-message="$v.cap.$error ? 'Valore non valido' : ''"
      />
      <van-field
        readonly
        clickable
        :value="$v.comune.$model"
        name="comune"
        ref="comune"
        label="Comune"
        placeholder="Comune"
        @click="showPickerComuni = true"
      />
      <van-field
        readonly
        clickable
        :value="$v.provincia.$model"
        name="provincia"
        ref="provincia"
        label="Provincia"
        placeholder="Provincia"
        @click="showPickerComuni = true"
      />
      <van-field
        readonly
        clickable
        :value="$v.regione.$model"
        name="regione"
        ref="regione"
        label="Regione"
        placeholder="Regione"
        @click="showPickerComuni = true"
      />
      <van-popup v-model="showPickerComuni" position="bottom" @opened="onOpenPopupComuni" :style="{ height: '50%' }" visible-item-count="8">
        <van-picker show-toolbar title="Comuni" ref="pickerComuni" :columns="columnsComuni" @change="onChangeComuni" @confirm="onConfirmComuni" @cancel="showPickerComuni = false"/>
      </van-popup>
      <van-field v-model.trim="$v.telefono.$model"
        name="telefono"
        ref="telefono"
        clearable
        label="Telefono"
        placeholder="Telefono"
        @focus="onFocus"
      />
      <van-field v-model.trim="$v.cellulare.$model"
        name="cellulare"
        ref="cellulare"
        clearable
        label="Cellulare"
        placeholder="Cellulare"
        @focus="onFocus"
      />
      <van-field v-model.trim="$v.email.$model"
        name="email"
        ref="email"
        clearable
        label="Email"
        placeholder="Email"
        :rules="[{trigger:'onBlur'}]"
        @focus="onFocus"
        :error-message="$v.email.$error ? 'Valore non valido' : ''"
      />
      <!--       <van-field v-model.trim="$v.naturaGiuridica.$model"
        name="naturaGiuridica"
        ref="naturaGiuridica"
        clearable
        label="Natura Giuridica"
        placeholder="Natura Giuridica"
        @focus="onFocus"
      /> -->
      <van-field
        readonly
        clickable
        :value="$v.naturaGiuridica.$model"
        name="naturaGiuridica"
        ref="naturaGiuridica"
        label="Natura Giuridica"
        placeholder="Natura Giuridica"
        @click="showNaturaGiuridica = true"
      />
      <van-popup v-model="showNaturaGiuridica" position="bottom" :style="{ height: '50%' }" visible-item-count="8">
        <van-picker show-toolbar title="Natura Giuridica" :columns="columnsNaturaGiurdica" @confirm="onConfirmNaturaGiuridica" @cancel="showNaturaGiuridica = false"/>
      </van-popup>
      <van-field v-model.trim="$v.partitaIva.$model"
        name="partitaIva"
        ref="partitaIva"
        clearable
        label="Partita Iva"
        placeholder="Partita Iva"
        :rules="[{trigger:'onBlur'}]"
        @focus="onFocus"
        :error-message="$v.partitaIva.$error ? 'Valore mancante o non valido' : ''"
      />
      <van-field v-model.trim="$v.codiceFiscale.$model"
        name="codiceFiscale"
        ref="codiceFiscale"
        clearable
        label="Codice Fiscale"
        placeholder="Codice Fiscale"
        :rules="[{trigger:'onBlur'}]"
        @focus="onFocus"
        :error-message="$v.codiceFiscale.$error ? 'Valore mancante o non valido' : ''"
      />
      <van-field v-model.trim="$v.pec.$model"
        name="pec"
        ref="pec"
        clearable
        label="Pec"
        placeholder="Pec"
        :rules="[{trigger:'onBlur'}]"
        @focus="onFocus"
        :error-message="$v.pec.$error ? 'Valore non valido' : ''"
      />
      <van-field v-model.trim="$v.codiceSdi.$model"
        name="codiceSdi"
        ref="codiceSdi"
        clearable
        label="Codice Sdi"
        placeholder="Codice Sdi"
        @focus="onFocus"
        :error-message="$v.codiceSdi.$error ? 'Valore non valido' : ''"
      />
      <van-field v-model.trim="$v.titolareNome.$model"
        name="titolareNome"
        ref="titolareNome"
        clearable
        label="Nome titolare"
        placeholder="Nome titolare"
        @focus="onFocus"
      />
      <van-field v-model.trim="$v.titolareCognome.$model"
        name="titolareCognome"
        ref="titolareCognome"
        clearable
        label="Cognome titolare"
        placeholder="Cognome titolare"
        @focus="onFocus"
      />
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit">
          Salva le modifiche
        </van-button>
      </div>
    </van-form>
  </van-list>
</template>

<script>
import _ from 'lodash'
import { validationMixin } from 'vuelidate'
// import { keyboardStoreMixin } from '../keyboardStoreMixin'
import { helpers, required, numeric, email, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import CodiceFiscale from '../helpers/codiceFiscale'
import PartitaIva from '../helpers/partitaIva'

import clienti from '@/services/clientiService.js'
import tabelle from '@/services/tabelleService.js'
import configurazioni from '@/services/configurazioniService.js'

const valPartitaIva = (value) => !helpers.req(value) || !PartitaIva.validate(value)
const valCodiceFiscale = (value) => !helpers.req(value) || !CodiceFiscale.validate(value)

export default {
  mixins: [validationMixin],
  props: {
    context: Object
  },
  data() {
    return {
      columnsComuni: [{ values: [] }, { values: [] }, { values: [] }],
      showPickerComuni: false,
      natureGiurdiche: [],
      showNaturaGiuridica: false,
      comuni: [],
      province: [],
      regioni: [],
      codice: '',
      ragioneSociale: '',
      indirizzo: '',
      cap: '',
      comune: '',
      provincia: '',
      regione: '',
      telefono: '',
      cellulare: '',
      email: '',
      naturaGiuridica: '',
      partitaIva: '',
      codiceFiscale: '',
      pec: '',
      codiceSdi: '',
      titolareNome: '',
      titolareCognome: '',
      submitStatus: null,
      activeField: 'ragioneSociale',
      activeTab: 'modifica',
      activeParent: 'clienti',
      primoAccesso: true
    }
  },
  validations: {
    codice: {},
    ragioneSociale: { required },
    indirizzo: {},
    cap: { numeric, minLength: minLength(5), maxLength: maxLength(5) },
    comune: {},
    provincia: {},
    regione: {},
    telefono: {},
    cellulare: {},
    naturaGiuridica: {},
    email: { email },
    partitaIva: { // eventualmente verificare esistenza
      valPartitaIva,
      required: requiredIf((model) => false && !model.codiceFiscale)
    },
    codiceFiscale: { // eventualmente verificare esistenza
      valCodiceFiscale,
      required: requiredIf((model) => false && !model.partitaIva)
    },
    pec: { email },
    codiceSdi: {},
    titolareNome: {},
    titolareCognome: {}
  },
  directives: {
    uppercase: {
      update (el) {
        el.value = el.value.toUpperCase()
      }
    }
  },
  async created() {

  },
  async mounted() {
    this.istat_comuni = await tabelle.getTabella('istat_comuni')
    this.regioni = (_.uniq(this.istat_comuni.map(el => el.regione))).sort()
    const nature = await configurazioni.getConfigurazione('clienti_nature_giuridiche', 'natureGiuridiche')
    this.natureGiurdiche = nature.map(el => el.descrizione).sort()
    this.$nextTick(() => this.onOpen())
    this.$refs.ragioneSociale.focus()
    // this.$refs.ragioneSociale.focus()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      console.log('kbInput res', res)
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      console.log('kbKeyPress res', res)
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      console.log('kbCounter res', res)
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.count : 0
    },
    columnsNaturaGiurdica() {
      return this.natureGiurdiche
    }
  },
  watch: {
    kbInput(value) {
      this.handleKey(value)
    },
    kbKeyPress(value) {
      if (value === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    },
    kbCounter(value) {
      if (this.kbKeyPress === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    }
  },
  methods: {
    handleKey(value) {
      console.log('handleKey', this.activeField, 'value:-' + value + '-')
      if (!this.primoAccesso) {
        const variabile = this.activeField
        this[variabile] = String(value)
      }
    },
    onChangeComuni(picker, values) {
      console.log(values)
      picker.setColumnValues(1, this.getProvince(values[0]))
      const locP = picker.getColumnValue(1)
      picker.setColumnValues(2, this.getComuni(locP))
    },
    getProvince(regione) {
      return (_.uniq(this.istat_comuni.filter(x => x.regione === regione).map(el => el.provincia))).sort()
    },
    getComuni(provincia) {
      return (_.uniq(this.istat_comuni.filter(x => x.provincia === provincia).map(el => el.comune))).sort()
    },
    onConfirmComuni(values) {
      const picker = this.$refs.pickerComuni
      this.regione = picker.getColumnValue(0)
      this.provincia = picker.getColumnValue(1)
      this.comune = picker.getColumnValue(2)
      this.showPickerComuni = false
    },
    onConfirmNaturaGiuridica(value) {
      this.naturaGiuridica = value
      this.showNaturaGiuridica = false
    },
    titolo(item) {
      return `${item.codice} - ${item.ragioneSociale}`
    },
    label(item) {
      return `${item.recapiti[0].indirizzo}`
    },
    value(item) {
      return `${item.amministrativi.partitaIva}`
    },
    onFocus(event) {
      console.log('focus', event.currentTarget.name, String(this[event.currentTarget.name]))
      // this.$store.commit('SHOW_POPUP_KEYBOARD')
      this.$v.$touch()
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
      this.primoAccesso = false
    },
    generaCampoRicerca() { // messo su clienti
      const ricerca = this.codice.toLowerCase() +
        this.ragioneSociale.toLowerCase() +
        this.telefono.toLowerCase() +
        this.indirizzo.toLowerCase() +
        this.partitaIva +
        this.titolareCognome.toLowerCase()
      return ricerca
    },
    async onSubmit(values) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast('Sono presenti alcuni errori, verificare i dati inseriti', { type: 'fail' })
      } else {
        const cliente = await this.localToContext()
        await clienti.modificaCliente(cliente)
        this.$toast('Cliente modificato', { type: 'success' })
      }
    },
    onOpen() {
      // this.$store.commit('SET_INTERNAL_TAB', this.activeTab) // active
      this.contextToLocal()
    },
    onOpenPopupComuni() {
      const istatDefault = { regione: 'Piemonte', provincia: 'Torino', comune: 'Almese' } // TODO: Leggere da configurazioni
      const record = this.comune && this.provincia ? this.istat_comuni.find(x => x.provincia === this.provincia && x.comune === this.comune) : istatDefault
      // const record = this.istat_comuni.find(x => x.provincia === this.provincia && x.comune === this.comune)
      const picker = this.$refs.pickerComuni
      picker.setColumnValues(0, this.regioni)
      picker.setColumnValue(0, record.regione)
      picker.setColumnValues(1, this.getProvince(record.regione))
      picker.setColumnValue(1, record.provincia)
      picker.setColumnValues(2, this.getComuni(record.provincia))
      picker.setColumnValue(2, record.comune)
    },
    contextToLocal() {
      console.log('contextToLocal')
      this.primoAccesso = true
      const cliente = this.context.cliente
      if (cliente) {
        this.codice = cliente.codice
        this.ragioneSociale = cliente.ragioneSociale
        const rc = cliente.recapiti.find(x => x.tipo.codice === 'principale')
        if (rc) {
          this.indirizzo = rc.indirizzo
          this.cap = rc.cap
          this.comune = rc.comune
          this.provincia = rc.provincia
          this.regione = rc.regione
          this.telefono = rc.telefono
          this.cellulare = rc.cellulare
          this.email = rc.email
        }
        const am = cliente.amministrativi
        if (am) {
          this.naturaGiuridica = am.naturaGiuridica
          this.partitaIva = am.partitaIva
          this.codiceFiscale = am.codiceFiscale
          this.pec = am.pec
          this.codiceSdi = am.codiceSdi
        }
        const ti = cliente.contatti.find(x => x.tipo === 'titolare')
        if (ti) {
          this.titolareNome = ti.nome
          this.titolareCognome = ti.cognome
        }
      }
    },
    async localToContext() {
      const cl = await clienti.getCliente(this.codice)
      cl.ragioneSociale = this.ragioneSociale
      const rc = cl.recapiti.find(x => x.tipo.codice === 'principale')
      if (rc) {
        rc.indirizzo = this.indirizzo
        rc.cap = this.cap
        rc.comune = this.comune
        rc.provincia = this.provincia
        rc.regione = this.regione
        rc.telefono = this.telefono
        rc.cellulare = this.cellulare
        rc.email = this.email
      }
      const am = cl.amministrativi
      if (am) {
        am.naturaGiuridica = this.naturaGiuridica
        am.partitaIva = this.partitaIva
        am.codiceFiscale = this.codiceFiscale
        am.pec = this.pec
        am.codiceSdi = this.codiceSdi
      }
      const ti = cl.contatti.find(x => x.tipo === 'titolare')
      if (ti) {
        ti.nome = this.titolareNome
        ti.cognome = this.titolareCognome
      }
      return cl
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }

</style>

<template>
  <modale-form ref="form" :titolo="titoloModale" :modelItem="modelItem" @close="annulla" @salva="salva" :campi="campi"
    :config="config"
  ></modale-form>
</template>

<script>
import ModaleForm from '@/components/modale/ModaleForm'
import configurazioni from '@/services/configurazioniService'
import { cloneObject, ruoloPresente, dynamicSortMultiple } from '@/components/helpers'
// import _ from 'lodash'
import * as dot from 'dot-object'
var colori = require('../modelli/colori.json')
var funzionalita = require('../modelli/funzionalita.json')
var immagini = require('../modelli/immagini.json').sort(dynamicSortMultiple('descrizione'))
// import * as dot from 'dot-object'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      modelItem: null,
      initialModel: null,
      zona: '',
      clienteInModifica: null,
      campi: [],
      config: { disableEsciOnClick: true, transparentBackground: true, modalLeft: true },
      ruoliTasti: []
    }
  },
  async created() {
    colori = colori.sort((a, b) => a.descrizione > b.descrizione ? 1 : -1)
    this.modelItem = this.context.debug.tastoEdit.modelItem || {}
    this.initialModel = cloneObject(this.modelItem)
    this.zona = this.context.debug.tastoEdit.zona
    this.config = this.context.debug.tastoEdit.modaleConfig

    /*     var elencoPagine = []
    for (var i = 0; i < this.config.elencoPagine.length; i++) {
      var p = this.config.elencoPagine[i]
      elencoPagine.push({ codice: String(p), descrizione: p })
    } */

    // console.log(this.initialModel)
    /*     var campi = [
      { campo: 'intestazioneTesti', tipo: 'intestazione', label: 'Testi', readonly: true },
      { campo: 'title', tipo: 'text', model: 'title', label: 'Testo', placeHolder: 'Inserire Testo..', validations: [{ nome: 'required' }] },
      { campo: 'title2th', tipo: 'text', model: 'title2th', label: 'Testo 2', placeHolder: 'Inserire Testo 2..', hint: 'Valore secondario: azione in caso di pressione prolungata del tasto' },
      { campo: 'intestazioneAspetto', tipo: 'intestazione', label: 'Aspetto e Visibilità', readonly: true },
      // { campo: 'hidden', tipo: 'switch', model: 'forceHidden', label: 'Hidden', hidden: true },
      { campo: 'pagine', tipo: 'multiselect', label: 'Pagine', saveFormat: 'comma', taggable: true, tagType: 'int', hint: 'Visibile alle pagine ( 0 o vuoto per tutte le pagine )', dataSource: elencoPagine, placeHolder: 'Digitare Pagine..' },
      { campo: 'hiddenTestCondition', tipo: 'multiselect', model: 'hiddenTestCondition', label: 'Hidden Test Condition', saveFormat: 'comma', dataSource: this.config.hiddenConditions, placeHolder: 'Digitare..' },
      { campo: 'colore', tipo: 'select', model: 'colore', label: 'Aspetto', saveFormat: 'codice', hint: 'Aspetto pulsante', dataSource: colori, placeHolder: 'Digitare Colore..', onUpdateValue: this.onUpdateColore, extraOptionLabel: this.coloreExtraOptionLabel },
      { campo: 'aspectBig', tipo: 'switch', model: 'aspectBig', label: 'Testo Grande', onUpdateValue: this.onUpdateAspectBig },
      { campo: 'intestazioneFunzionalita', tipo: 'intestazione', label: 'Funzionalità', readonly: true },
      { campo: 'azione1', tipo: 'select', model: 'action1Type', label: 'Azione 1', hint: 'Azione primaria', dataSource: funzionalita, placeHolder: 'Digitare Funzionalità..', onUpdateValue: this.onUpdateFunzionalita },
      { campo: 'azione1Code', tipo: 'text', model: 'action.payload.code', label: 'Codice', placeHolder: 'Inserire Codice..', hint: 'Codice', validations: [{ nome: 'required' }] },
      { campo: 'azione1Value', tipo: 'text', model: 'action.payload.value', label: 'Valore', placeHolder: 'Inserire Valore..', hint: '', validations: [{ nome: 'required' }] },
      { campo: 'azione1Page', tipo: 'numeric', model: 'action.payload.page', label: 'Pagina', numeric: { int: true }, placeHolder: 'Numero pagina..', hint: 'Valore principale', validations: [{ nome: 'required' }] },
      // { campo: 'intestazioneFunzionalita2', tipo: 'intestazione', label: 'Funzionalità secondaria', readonly: true },
      // { campo: 'azione2', tipo: 'select', model: 'action.type2th', label: 'Azione 2', hint: 'Azione secondaria', dataSource: funzionalita, placeHolder: 'Digitare Funzionalità..' }
      { campo: 'intestazioneRuoli', tipo: 'intestazione', label: 'Ruoli abilitazione', readonly: true },
      { campo: 'ruoloTasto', tipo: 'select', model: 'ruolo', label: 'Ruolo richiesto', hint: 'Ruolo utente richiesto per la selezione del pulsante (vuoto = nessun ruolo)', dataSource: this.ruoliTasti, placeHolder: 'Digitare Ruolo..', onUpdateValue: this.onUpdateRuolo }

    ]

    campi = this.initCampi(campi)

    this.campi = campi

    if (this.modelItem.hiddenTestCondition) {
      // this.getCampo('hidden').hidden = false
      // this.getCampo('hiddenTestCondition').hidden = false
    }

    if (this.modelItem.blocco !== 'azione' && !this.modelItem.action1Type) {
      this.onUpdateFunzionalita()
    } */
  },
  async mounted() {
    const impoOp = await configurazioni.getConfigurazione('display_generale', 'operatori')
    let ruoliTasti = impoOp.ruoliTasti || []
    ruoliTasti = ruoliTasti.map(x => {
      return {
        codice: x,
        descrizione: x
      }
    })
    this.ruoliTasti = ruoliTasti
    var elencoPagine = []
    for (var i = 0; i < this.config.elencoPagine.length; i++) {
      var p = this.config.elencoPagine[i]
      elencoPagine.push({ codice: String(p), descrizione: p })
    }

    // var immagini = [{ codice: 'crocchette.png', descrizione: 'Crocchette' }]
    var campi = [
      { campo: 'intestazioneTesti', tipo: 'intestazione', label: 'Testi', readonly: true },
      { campo: 'title', tipo: 'text', model: 'title', label: 'Testo', placeHolder: 'Inserire Testo..', validations: [{ nome: 'required' }] },
      { campo: 'title2th', tipo: 'text', model: 'title2th', label: 'Testo 2', placeHolder: 'Inserire Testo 2..', hint: 'Valore secondario: azione in caso di pressione prolungata del tasto' },
      { campo: 'intestazioneAspetto', tipo: 'intestazione', label: 'Aspetto e Visibilità', readonly: true },
      // { campo: 'hidden', tipo: 'switch', model: 'forceHidden', label: 'Hidden', hidden: true },
      { campo: 'pagine', tipo: 'multiselect', label: 'Pagine', saveFormat: 'comma', taggable: true, tagType: 'int', hint: 'Visibile alle pagine ( 0 o vuoto per tutte le pagine )', dataSource: elencoPagine, placeHolder: 'Digitare Pagine..' },
      { campo: 'hiddenTestCondition', tipo: 'multiselect', model: 'hiddenTestCondition', label: 'Hidden Test Condition', saveFormat: 'comma', dataSource: this.config.hiddenConditions, placeHolder: 'Digitare..' },
      { campo: 'colore', tipo: 'select', model: 'colore', label: 'Aspetto', saveFormat: 'codice', hint: 'Aspetto pulsante', dataSource: colori, placeHolder: 'Digitare Colore..', onUpdateValue: this.onUpdateColore, extraOptionLabel: this.coloreExtraOptionLabel },
      { campo: 'aspectBig', tipo: 'switch', model: 'aspectBig', label: 'Testo Grande', onUpdateValue: this.onUpdateAspectBig },
      { campo: 'image', tipo: 'select', model: 'image', label: 'Immagine', dataSource: immagini, placeHolder: 'Digitare Nome Immagine..', saveFormat: 'codice', onUpdateValue: this.onUpdateImg },
      { campo: 'help', tipo: 'text', model: 'help', label: 'Link Help', placeHolder: 'Inserire Link..' },
      { campo: 'intestazioneFunzionalita', tipo: 'intestazione', label: 'Funzionalità', readonly: true },
      { campo: 'toggle', tipo: 'switch', model: 'toggle', label: 'Toggle', hidden: this.toggleHidden },
      { campo: 'azione1', tipo: 'select', model: 'action1Type', label: 'Azione 1', hint: 'Azione primaria', dataSource: funzionalita, placeHolder: 'Digitare Funzionalità..', onUpdateValue: this.onUpdateFunzionalita },
      { campo: 'azione1Code', tipo: 'text', model: 'action.payload.code', label: 'Codice', placeHolder: 'Inserire Codice..', hint: 'Codice', validations: [{ nome: 'required' }] },
      { campo: 'azione1Value', tipo: 'text', model: 'action.payload.value', label: 'Valore', placeHolder: 'Inserire Valore..', hint: '', validations: [{ nome: 'required' }] },
      { campo: 'azione1Page', tipo: 'numeric', model: 'action.payload.page', label: 'Pagina', numeric: { int: true }, placeHolder: 'Numero pagina..', hint: 'Valore principale', validations: [{ nome: 'required' }] },
      // { campo: 'intestazioneFunzionalita2', tipo: 'intestazione', label: 'Funzionalità secondaria', readonly: true },
      // { campo: 'azione2', tipo: 'select', model: 'action.type2th', label: 'Azione 2', hint: 'Azione secondaria', dataSource: funzionalita, placeHolder: 'Digitare Funzionalità..' }
      { campo: 'intestazioneRuoli', tipo: 'intestazione', label: 'Ruoli abilitazione', readonly: true },
      { campo: 'ruoloTasto', tipo: 'select', model: 'ruolo', label: 'Ruolo richiesto', hint: 'Ruolo utente richiesto per la selezione del pulsante (vuoto = nessun ruolo)', dataSource: ruoliTasti, placeHolder: 'Digitare Ruolo..', onUpdateValue: this.onUpdateRuolo }

    ]

    campi = this.initCampi(campi)

    this.campi = campi

    if (this.modelItem.hiddenTestCondition) {
      // this.getCampo('hidden').hidden = false
      // this.getCampo('hiddenTestCondition').hidden = false
    }

    // if (this.modelItem.blocco !== 'azione' && !this.modelItem.action1Type) {
    if (!this.checkBlocchi(['bloccoAzione']) && !this.modelItem.action1Type) {
      this.onUpdateFunzionalita()
    }
  },
  computed: {
    titoloModale() {
      return 'Modifica tasto'
    }
  },
  methods: {
    annulla(nonAzzerareModifiche) {
      !nonAzzerareModifiche && this.azzeraModifiche()
      const event = {
        type: 'EDIT_CONTEXT',
        payload: {
          debug: {
            tastoEdit: null
          }
        }
      }
      this.$emit('send-event', event)
      this.$emit('close')
    },
    initCampi(campi) { // DA COMPLETARE
      if (this.modelItem.aspectIfCondition) {
        var indx = campi.findIndex(obj => obj.campo === 'colore')
        campi.splice(indx, 1)
        indx = campi.findIndex(obj => obj.campo === 'aspectBig')
        campi.splice(indx, 1)
      } else {
        var aspects = this.modelItem.aspect.split(' ')
        var i
        for (i = 0; i < aspects.length; i++) {
          if (aspects[i]) {
            if (aspects[i] === 'big') {
              this.modelItem.aspectBig = true
            } else {
              this.modelItem.colore = aspects[i]
            }
          }
        }
      }

      var action = this.modelItem.action
      // if (this.modelItem.blocco === 'azione') {
      if (this.checkBlocchi(['bloccoAzione'])) {
        var funzIndex = campi.findIndex(obj => obj.campo === 'intestazioneFunzionalita')
        campi.splice(funzIndex)
      } else {
        if (action && action.type) {
          var funz = funzionalita.find((obj) => obj.codice === action.type)
          this.modelItem.action1Type = funz || null
        }

        if (action && action.help) {
          this.modelItem.help = action.help
        }

        if (action && action.payload && action.payload.toggle) {
          this.modelItem.toggle = true
        }
      }

      var ruolo = this.modelItem.ruoloTasto
      if (ruolo) {
        var rdb = this.ruoliTasti.find(x => x.codice === ruolo)
        this.modelItem.ruolo = rdb || null
      }

      for (i = 0; i < campi.length; i++) {
        var c = campi[i]
        if (c.model) {
          var val = dot.pick(c.model, this.modelItem)
          c.valore = val
        }
      }

      if (this.modelItem.page) {
        var campoPagine = campi.find((obj) => obj.campo === 'pagine')
        var pages = []
        if (Array.isArray(this.modelItem.page)) {
          for (i = 0; i < this.modelItem.page.length; i++) {
            pages.push(String(this.modelItem.page[i]))
          }
        } else {
          pages.push(String(this.modelItem.page))
        }

        campoPagine.valore = pages.join(',')
      }

      return campi
    },
    async salva() {
      var form = this.$refs.form
      var err = form.valida()
      if (err && err.length) {
        return
      }
      delete this.modelItem.forceHidden
      delete this.modelItem.aspectBig
      delete this.modelItem.colore
      delete this.modelItem.action1Type
      if (this.modelItem.action && this.modelItem.action.type === 'DIGITA') {
        var objAction = this.modelItem.action
        objAction.payload = objAction.payload.code
        this.$set(this.modelItem, 'action', objAction)
      }
      if (this.initialModel.action && this.initialModel.action.type2th) {
        this.$set(this.modelItem.action, 'type2th', this.initialModel.action.type2th)
      }
      delete this.modelItem.ruolo

      var dati = form.getDati()

      if (dati.pagine) {
        // this.modelItem.page = dati.pagine.length > 1 ? dati.pagine.split(',').map((obj) => parseInt(obj)) : parseInt(dati.pagine[0])
        var newPages = dati.pagine.split(',')
        newPages = newPages.length > 1 ? newPages.map((obj) => parseInt(obj)) : parseInt(newPages[0])
        // console.log(newPages)
        this.$set(this.modelItem, 'page', newPages)
      } else {
        delete this.modelItem.page
      }

      if (!this.modelItem.image) {
        delete this.modelItem.image
      }
      if (!this.modelItem.help) {
        delete this.modelItem.help
      }

      if (this.modelItem.help) {
        this.$set(this.modelItem.action, 'help', this.modelItem.help)
      } else if (this.modelItem.action) {
        delete this.modelItem.action.help
      }
      if (this.modelItem.toggle && this.modelItem.action && (this.modelItem.action.type === 'PRODOTTI_PAGINA' || this.modelItem.action.type === 'REPARTI_PAGINA')) {
        this.$set(this.modelItem.action.payload, 'toggle', 1)
        this.$set(this.modelItem, 'aspectIfCondition', 'testo-rosso')
        this.$set(this.modelItem, 'aspectTestCondition', '@pageList.tasti eq ' + this.modelItem.action.payload.page)
      } else if (this.modelItem.action && this.modelItem.action.payload) {
        delete this.modelItem.action.payload.toggle

        // se in origine aveva toggle ed ora è stato disabilitato e inizialmente il modello aveva come test condition pageList, allora lo rimuovo
        if (this.initialModel.action && this.initialModel.action.payload && this.initialModel.action.payload.toggle && this.initialModel.aspectTestCondition && this.initialModel.aspectTestCondition.includes('pageList')) {
          delete this.modelItem.aspectIfCondition
          delete this.modelItem.aspectTestCondition
        }
      }

      delete this.modelItem.toggle
      delete this.modelItem.help
      delete this.modelItem.pagine

      this.$toast('Tasto modificato!', { type: 'success' })
      var self = this
      setTimeout(() => {
        self.annulla(true)
      }, 200)
    },
    onUpdateColore(value) {
      this.updateAspect()
    },
    onUpdateAspectBig(value) {
      this.updateAspect()
    },
    updateAspect() {
      var aspects = this.modelItem.aspectBig ? ['big'] : []
      this.modelItem.colore && aspects.push(this.modelItem.colore)
      this.modelItem.aspect = aspects.join(' ')
    },
    onUpdateImg(img) {
      this.modelItem.image = img || ''
      this.$set(this.modelItem, 'image', img || '')

      var aspect = this.modelItem.aspect
      this.$set(this.modelItem, 'aspect', aspect ? '' : 'test')

      setTimeout(() => {
        this.$set(this.modelItem, 'aspect', aspect || '')
      }, 100)
    },
    onUpdateFunzionalita(value) {
      if (!value) {
        this.$set(this.modelItem, 'action', {})
        this.$set(this.getCampo('azione1Code'), 'hidden', true)
        this.$set(this.getCampo('azione1Value'), 'hidden', true)
        this.$set(this.getCampo('azione1Page'), 'hidden', true)
        this.$set(this.getCampo('toggle'), 'hidden', true)
      } else {
        var obj
        if (value.codice === 'PRODOTTI_PAGINA' || value.codice === 'REPARTI_PAGINA') {
          this.$set(this.getCampo('toggle'), 'hidden', false)
        } else {
          this.$set(this.getCampo('toggle'), 'hidden', true)
        }
        if (typeof value.payload === 'object') {
          obj = { type: value.codice, payload: {} }

          if (Object.prototype.hasOwnProperty.call(value.payload, 'code')) {
            this.$set(this.getCampo('azione1Code'), 'hidden', false)
            this.$set(this.getCampo('azione1Code'), 'hint', value.payload.code ? value.payload.code.split('?')[1] || '' : '')
            obj.payload.code = (this.modelItem.action && this.modelItem.action.payload && this.modelItem.action.payload.code) || ''
          } else {
            this.$set(this.getCampo('azione1Code'), 'hidden', true)
          }

          if (Object.prototype.hasOwnProperty.call(value.payload, 'value')) {
            this.$set(this.getCampo('azione1Value'), 'hidden', false)
            this.$set(this.getCampo('azione1Value'), 'hint', value.payload.value ? value.payload.value.split('?')[1] || '' : '')
            obj.payload.value = (this.modelItem.action && this.modelItem.action.payload && this.modelItem.action.payload.value) || ''
          } else {
            this.$set(this.getCampo('azione1Value'), 'hidden', true)
          }

          if (Object.prototype.hasOwnProperty.call(value.payload, 'page')) {
            this.$set(this.getCampo('azione1Page'), 'hidden', false)
            this.$set(this.getCampo('azione1Page'), 'hint', value.payload.page ? value.payload.page.split('?')[1] || '' : '')
            obj.payload.page = (this.modelItem.action && this.modelItem.action.payload && this.modelItem.action.payload.page) || ''
          } else {
            this.$set(this.getCampo('azione1Page'), 'hidden', true)
          }

          Object.prototype.hasOwnProperty.call(value.payload, 'tipoAliquota') && (obj.payload.tipoAliquota = value.payload.tipoAliquota)
          Object.prototype.hasOwnProperty.call(value.payload, 'zona') && (obj.payload.zona = this.zona)
          if (this.modelItem.action && this.modelItem.action.payload && this.modelItem.action.payload.toggle) {
            obj.payload.toggle = this.modelItem.action.payload.toggle
          }
          this.$set(this.modelItem, 'action', obj)
        } else {
          this.$set(this.getCampo('azione1Value'), 'hidden', true)
          this.$set(this.getCampo('azione1Page'), 'hidden', true)

          this.$set(this.getCampo('azione1Code'), 'hint', value.hint || '')
          console.log(this.modelItem)
          var cod = typeof this.modelItem.action.payload === 'string' ? this.modelItem.action.payload : this.modelItem.action.payload.code || ''
          obj = { type: value.codice, payload: { code: cod } }

          this.$set(this.modelItem, 'action', obj)

          this.$refs.form.setValoreSuCampo('azione1Code', cod)
          // this.$set(this.modelItem, 'action', { type: value.codice, payload: value.payload })
        }
      }
    },
    onUpdateRuolo() {
      if (this.modelItem.ruolo) {
        this.modelItem.ruoloTasto = this.modelItem.ruolo.codice
      } else {
        delete this.modelItem.ruoloTasto
      }
    },
    coloreExtraOptionLabel(props) {
      var col = props && props.option ? props.option.codice || '' : ''
      return '<div style="width:6vh;height:2.5vh;margin-left:1vh;border-radius: 1vh;border: 2px solid black;" class="' + col + '"></div></div>'
    },
    getCampo(nome) {
      return this.campi.find((obj) => obj.campo === nome)
    },
    azzeraModifiche() {
      for (var n in this.initialModel) {
        this.$set(this.modelItem, n, this.initialModel[n])
      }

      if (!this.initialModel.image) {
        delete this.modelItem.image
        // Vue.delete(this.modelItem, 'image')
      }

      delete this.modelItem.toggle
      delete this.modelItem.help
      delete this.modelItem.forceHidden
      delete this.modelItem.aspectBig
      delete this.modelItem.colore
      delete this.modelItem.action1Type
      delete this.modelItem.pagine
    },
    checkBlocchi(blocchi) { // bloccoTotale, bloccoAggiunta, bloccoModifica, bloccoAzione
      var blocco = false
      var ruolo = this.context.operatore && this.context.operatore.accesso ? this.context.operatore.accesso.ruoloEditor || '' : '' // 'amministratore'

      var i = 0
      var b

      blocchi = !Array.isArray(blocchi) ? [(blocchi || '')] : blocchi

      // check singolo tasto
      if (this.modelItem.bloccoConfig) {
        // check se ruolo presente
        for (i = 0; i < blocchi.length; i++) {
          b = blocchi[i]
          blocco = this.modelItem.bloccoConfig[b] ? ruoloPresente(ruolo, this.modelItem.bloccoConfig[b].ruoli) : false
          if (blocco) {
            break
          }
        }
      }

      var configZona = this.context.debug.tastoEdit.configZona || {}

      // poi check su zona
      if (!blocco && configZona.bloccoConfig) {
        for (i = 0; i < blocchi.length; i++) {
          b = blocchi[i]
          blocco = configZona.bloccoConfig[b] ? ruoloPresente(ruolo, configZona.bloccoConfig[b].ruoli) : false
          if (blocco) {
            break
          }
        }
      }

      var configLayout = this.context.debug.tastoEdit.configLayout || {}

      // infine check su impostazioni esterne
      if (!blocco && configLayout.bloccoConfig) {
        for (i = 0; i < blocchi.length; i++) {
          b = blocchi[i]
          blocco = configLayout.bloccoConfig[b] ? ruoloPresente(ruolo, configLayout.bloccoConfig[b].ruoli) : false
          if (blocco) {
            break
          }
        }
      }

      return blocco
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

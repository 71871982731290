<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="false"
      class="popupTastiera main-popup-tastiera"
      :overlay="false"
      @open="onOpen"
      @close="onClose"
    >
      <van-search
        v-model="valore"
        left-icon=""
        show-action
        name="lotteria"
        ref="lotteria"
        :label="labelTastiera"
        placeholder="Codice"
        @search="onConfermaValore"
        @clear="onClearValore"
        @focus="onFocus"
      >
      <template #action>
        <div @click="onConfermaValore">Conferma</div>
      </template>
      </van-search>
      <div v-if="!context.attesaCodiceLotteria" class="switch-piva text">Partita iva</div>
      <van-switch v-if="!context.attesaCodiceLotteria" v-model="partitaIva" size="24" class="switch-piva" @change="onChangeCodiceFiscalePIva"/>
      <simple-popup-keyboard class="keyboard-popup-tastiera" :class="{ 'move-down': !context.attesaCodiceLotteria}" enterText="Conferma" :keyboardClass="'kb_tastiera'" />
    </van-popup>
</template>

<script>
import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'

export default {
  components: {
    SimplePopupKeyboard
  },
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      valore: '',
      activeTab: 'popup',
      activeParent: 'tastiera',
      activeField: 'valore',
      partitaIva: false
    }
  },
  async mounted() {
    this.onOpen()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    labelTastiera() {
      if (this.context.attesaCodiceFiscale) {
        return this.partitaIva ? 'Partita iva' : 'Codice fiscale'
      }
      if (this.context.attesaCodiceLotteria) {
        return 'Codice lotteria'
      } else {
        return ''
      }
    },
    barcode() {
      return this.context.barcodePerGenerico
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    kbKeyPress(value) {
      if (this.show) {
        if (value === '{esci}') {
          // this.closePopup()
          this.onConfermaValore()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          // this.closePopup()
          this.onConfermaValore()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    barcode(code) {
      this.testo = this.testo.split(code).join('')
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', this.testo)
      this.valore = code
      this.onConfermaValore()
    }
  },
  methods: {
    onChangeCodiceFiscalePIva() {
    },
    /*     getStilePopup() {
      var cont = document.querySelector('.tab-popup-tastiera')
      if (cont) {
        return `height: "100%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "100%";'
      }
    }, */
    handleKey(valore) {
      // TODO: verificare se ricerca automatica o solo quando si preme "Cerca"
      // this.debounceInputProdotti(valore)
    },
    /*     onClearProdotto() {
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
    }, */

    /*     onFocus(event) {
      console.log('onfocus')
      // this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
    },
    async onSubmit(values) {
      this.clearFields()
    }, */
    onFocus(event) {
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
    },
    onConfermaValore() {
      console.log('onConfermaValore')
      let codice = this.valore
      // TODO: Fare per tutti i codici particolari (es lotteria, codice fiscale, partita iva che possono confondersi con un codice prodotto)
      //       Su formati_barcode inserisco come prefisso il valore riportato sotto (es. LOT_) e correggo la lunghezza di conseguenza (es 8 + LOT_ --> 12)
      if (this.context.attesaCodiceLotteria) {
        codice = 'LOT_' + codice
      }
      const filter = this.context.attesaCodiceFiscale ? (this.partitaIva ? 'partitaiva' : 'codicefiscale') : 'lotteria'
      const event = {
        type: 'ADD_BARCODE',
        code: codice,
        filter: filter
      }
      this.$emit('send-event', event)
      this.context.popups.find(el => el.type === 'Tastiera').show = false
    },
    onClearValore() {
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
    },
    onOpen() {
      this.clearSearchInput()
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      this.$store.commit('SET_EXTERNAL_TAB', this.activeParent)
      this.$store.commit('SET_INTERNAL_TAB', this.activeTab)
    },
    clearSearchInput() {
      this.valore = ''
    },
    /*     clearSearchInput() {
      this.prodotto = ''
    },
    clearFields() {
      this.clearSearchInput()
    }, */
    closePopup() {
      this.$emit('close')
      this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '')
    },
    onClose() {
      this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '')
    }
    /*     aggiungiAlCarrello(codice) {
      const event = {
        type: 'PRODOTTO_CERCA',
        value: {
          code: codice,
          tipoAliquota: 'banco'
        }
      }
      this.$emit('send-event', event)
    } */
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .switch-piva {
    position: absolute;
    top: 80px;
    left: auto;
    right: 40px;
    &.text {
      left: auto;
      right: 100px;
      top: 85px;
    }
  }
  .keyboard-popup-tastiera.move-down {
    margin-top: 50px;
  }

  .result-popup-tastiera {
    margin-top: 20px;
  }

  .swipe-cell-button {
    height: 100%;
  }
/*   .van-search .van-cell {
    padding: 10px 16px;
  } */

  .van-search--show-action {
      padding-right: 12px;
  }

  .main-popup-tastiera {
    top: auto;
  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupTastiera, .main-popup-tastiera {
      width: 85%;
      height: 70%;
    }
  }
  @media all and (min-width:1024px) {
    .popupTastiera, .main-popup-tastiera {
      width: 65%;
      height: 63%;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-tastiera {
      width: 40%;
      height: 50%;
      /* bottom: -200px; */

    }

  }
  .van-popup--left {
    top: 50%;
    left: 0;
  }
</style>

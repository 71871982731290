'use strict'
import _ from 'lodash'
import magazzinoService from '@/services/magazzinoService.js'
import movimentiService from '@/services/movimentiService.js'
import prodottiService from '@/services/prodottiService.js'
import configurazioni from '@/services/configurazioniService.js'

const autoconsumo = async function(righeCarrello) {
  const causali = await configurazioni.getConfigurazione('magazzino_causali', 'causali')
  const causale = causali.find(el => el.codice === 'autoconsumo')
  const base = {
    magazzino: 'mag01',
    tipoCausale: causale.tipo,
    causale: causale.codice,
    data: new Date(),
    riferimento: 'autoconsumo',
    mostraCliente: causale.visualizzaCliente,
    mostraFornitore: causale.visualizzaFornitore,
    tipoPrezzo: causale.tipoPrezzo
  }
  const movimentiMultipli = await buildMovimentiMultipli(righeCarrello, base)
  await magazzinoService.creaMovimentiMultipli(movimentiMultipli)
}

const acquisto = async function(righeCarrello, causaleMovimento, riferimento, data) {
  const causali = await configurazioni.getConfigurazione('magazzino_causali', 'causali')
  const causale = causali.find(el => el.codice === causaleMovimento)
  const base = {
    magazzino: 'mag01',
    tipoCausale: causale.tipo,
    causale: causale.codice,
    data: data || new Date(),
    riferimento: riferimento || causaleMovimento,
    mostraCliente: causale.visualizzaCliente,
    mostraFornitore: causale.visualizzaFornitore,
    tipoPrezzo: causale.tipoPrezzo
  }
  const movimentiMultipli = await buildMovimentiMultipli(righeCarrello, base)
  await magazzinoService.creaMovimentiMultipli(movimentiMultipli)
}

const pc = async function(righeCarrello) {
  const base = {
    magazzino: 'pc',
    tipoCausale: 'vendita',
    causale: 'pc',
    data: new Date(),
    riferimento: 'pc',
    mostraCliente: false,
    mostraFornitore: false,
    tipoPrezzo: 'vendita'
  }
  const movimentiMultipli = await buildMovimentiMultipli(righeCarrello, base)
  await magazzinoService.creaMovimentiMultipli(movimentiMultipli, true)
}

const usaSelezione = async function(righeCarrello, base) {
  const movimentiMultipli = await buildMovimentiMultipli(righeCarrello, base)
  await magazzinoService.creaMovimentiMultipli(movimentiMultipli)
}

const buildMovimentiMultipli = async function(righeCarrello, base) {
  const prodottiInseriti = righeCarrello
  const ids = prodottiInseriti.map(x => x.codice)
  var prodotti = await prodottiService.getProdottiByIds(ids)
  const movimenti = prodotti.map(el => {
    const quantita = prodottiInseriti.find(x => x.codice === el._id).quantita
    const nM = {
      magazzino: base.magazzino,
      tipo: base.tipoCausale,
      causale: base.causale,
      data: base.data,
      prodotto: {
        codice: el._id,
        um: _.get(el, 'magazzino.unitaMisura', '')
      },
      anagrafica: {},
      riferimento: {
        tipo: 'usaSelezione',
        id: base.riferimento,
        data: base.data
      }
    }
    if (base.mostraCliente && base.nominativo) {
      nM.anagrafica.cliente = {
        codice: base.nominativo,
        descrizione: base.nominativoDescrizione
      }
    }
    if (base.mostraFornitore && base.nominativo) {
      nM.anagrafica.fornitore = {
        codice: base.nominativo,
        descrizione: base.nominativoDescrizione
      }
    }

    nM.quantita = +parseFloat(quantita).toFixed(2)
    if (base.tipoPrezzo === 'acquisto') {
      nM.valore = +parseFloat(_.get(el, 'magazzino.prezzoAcquisto', 0)).toFixed(2)
    } else {
      nM.valore = +parseFloat(_.get(el, 'prezzo.L1.base', 0)).toFixed(2)
    }

    return nM
  })
  return movimenti
}

const verificaAggiungiMovimenti = async function(documento, righe) {
  // const movimentiSalvati = await movimenti.getMovimentiPerRiferimento('documento', documento._id)
  // Verificare altri tipi di documento
  // const prodotti = documento.righe.filter(x => x.prodotto && x.prodotto.codice)
// prendere dalle righe
  const modello = await movimentiService.getModello('xxx')
  let numErrori = 0
  for (const p of righe) { // I movimenti vengono creati solo per le righe dove è presente la quantità.
  // for (const p of prodotti) {
    const prodotto = await prodottiService.getProdotto(p.codice)
    const movimento = JSON.parse(JSON.stringify(modello))

    movimento.data = new Date() // TODO: Verificare se data operazione o data documento
    movimento.magazzino = documento.magazzino || 'mag01' // TODO: Magazzino didefault da impostazioni

    movimento.prodotto = {
      codice: p.codice,
      lotti: [],
      um: _.get(prodotto, 'magazzino.unitaMisuraVendita', '') || _.get(prodotto, 'magazzino.unitaMisura', ''),
      quantita: +parseFloat(p.quantita).toFixed(2) // qtaVendita
    }
    movimento.quantita = +parseFloat(p.quantita).toFixed(2) // qtaVendita
    // const pzUn = +parseFloat((p.ordine && p.ordine.prezzoUnitario) || p.valore.acquisto).toFixed(2) // Verificare eventuali sconti
    const pzUn = +parseFloat(p.prezzoAcquisto).toFixed(2) || +parseFloat(_.get(prodotto, 'magazzino.prezzoAcquistoPezzo', 0)).toFixed(2) // Verificare eventuali sconti
    movimento.valore = +parseFloat(movimento.quantita * pzUn).toFixed(2) // qtaAnagrafica
    movimento.causale = documento.tipoDocumento.causale
    movimento.anagrafica = {
      cliente: '',
      fornitore: documento.anagrafica.fornitore
    }
    movimento.riferimento = {
      tipo: 'documento',
      id: documento.codice,
      data: documento.data,
      riga: p.rigaCodice
    }
    if (!await magazzinoService.creaMovimenti(movimento)) {
      numErrori++
    }
  }
  return numErrori
}

export default {
  autoconsumo,
  acquisto,
  pc,
  usaSelezione,
  verificaAggiungiMovimenti
}

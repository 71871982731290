<template>
  <van-cell-group>
    <van-cell :title="elemento.label" :name="name" @click="cellClick">
      <template #right-icon>
        <van-switch v-model="elSel" size="24" :active-color="elemento.activeColor ||'#4caf50'" @click="checkClick"/>
      </template>
    </van-cell>
  </van-cell-group>
</template>

<script>
export default {
  props: {
    elemento: Object,
    name: String
  },
  data() {
    return {
      elSel: false
    }
  },
  methods: {
    checkClick(e) {
      e.stopPropagation()
      this.$emit('change', this.elemento.model, !this.elSel)
    },
    cellClick() {
      this.elSel = !this.elSel
      this.$emit('change', this.elemento.model, this.elSel)
    }
  }
}
</script>

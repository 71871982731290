'use strict'
import errorService from '@/services/errorService'

var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_documenti || process.env.VUE_APP_service_documenti
})()

async function creaOperazioniCassa(operazione) {
  try {
    const data = {
      operazione
    }
    const response = await fetch(`${service}/contabili/cassa/operazioni`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    errorService.onError(error, 'creaOperazioniCassa')
  }
}

async function getMovimentiContabili(periodo) {
  try {
    const data = {
      periodo
    }
    const response = await fetch(`${service}/contabili/cassa/elenco`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    errorService.onError(error, 'getMovimentiContabili')
  }
}

export default {
  creaOperazioniCassa,
  getMovimentiContabili
}

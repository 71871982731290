<template>
  <van-popup :value="show" :closeable="false" :close-on-click-overlay="true" class="popup-listini" @open="onOpen" @click-overlay="show=false">
    <van-cell-group title="Listini">
      <van-cell v-for="ll in listini" :key="ll.codice" :value="ll.codice + ' - ' + ll.descrizione" @click="selListino(ll)"/>
    </van-cell-group>
  </van-popup>
</template>

<script>
import configurazioni from '@/services/configurazioniService'

export default {
  data() {
    return {
      show: true,
      listini: [],
      activeField: 'pinValue'
    }
  },
  async mounted() {
    var result = await configurazioni.getConfigurazione('listini')
    this.listini = result.listini || []
    this.onOpen()
  },
  methods: {
    selListino(listino) {
      const event = {
        type: 'LISTINO',
        value: {
          listino: listino.codice
        }
      }
      this.$emit('send-event', event)
    },
    onOpen() {
    }
  }
}
</script>

<style lang="scss" scoped>

  .popup-listini {
    width: 18vw;
    height: 41vh;
  }

  @media all and (max-width: 1024px) {
    .popup-listini {
      width: 35vw;
      height: 50vh;
    }
  }

</style>

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupReport main-popup-report"
      @open="onOpen"
      @click-overlay="closePopup"
      >

      <van-tabs v-model="activeName"  >
        <van-tab name="report" title="Scegli la stampa">
          <van-cell-group>
            <van-cell v-for="(stampa, index) in stampe.filter(x => x.nomeReport)"
              :border="false"
              :key="index"
              :title="stampa.titolo"
              :label="stampa.descrizione"
              :note="stampa.note"
              @click="selectReport(stampa)" is-link/>
          </van-cell-group>
        </van-tab>
        <van-tab name="filtri" title="Filtri e Gruppi">
          <van-cell-group>
            <van-cell :title="report.titolo" :label="report.descrizione" title-class="titleClass" :border="false"/>
            <component v-for="(el, index) in filtriApplicabili" :key="index"
              :is="'Par' + el.type"
              :name="'Par' + el.type + String(index)"
              :elemento="el"
              :minDate="minDate"
              @change="componentValueChange"
            >
            </component>
          </van-cell-group>
          <van-button type="primary" block @click="openFluentReports" class="buttonStampa">Stampa</van-button>
        </van-tab>
      </van-tabs>
      <div class="command-popup-report">
        <van-button @click="closePopup" type="info">Torna alla Cassa</van-button>
      </div>
    </van-popup>
</template>

<script>
import moment from 'moment'
import configurazioni from '@/services/configurazioniService.js'
import ParData from './elementi/data'
import ParMultiselect from './elementi/multiselect'
import ParSwitch from './elementi/switch'
import lodash from 'lodash'

export default {
  props: {
    show: Boolean,
    context: Object
  },
  components: { ParData, ParMultiselect, ParSwitch },
  data() {
    return {
      activeName: 'report',
      stampe: [],
      minDate: new Date(2021, 0, 1),
      report: {},
      filtri: {},
      pathReportPrefix: ''
    }
  },
  async mounted() {
    this.onOpen()
    this.stampe = await configurazioni.getConfigurazione('report', 'report')
  },
  computed: {
    filtriApplicabili() {
      return this.report.filtri ? this.report.filtri.filter(x => x.type) : []
    },
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    listResult() {
      return this.$store.getters.listResult
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    kbKeyPress(value) {
      if (this.show) {
        if (value === '{esci}') {
          this.closePopup()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          this.closePopup()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    }
  },
  created() {
    this.pathReportPrefix = process.env.VUE_APP_service_reporting || ''
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.main-popup-report')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    selectReport(stampa) {
      this.activeName = 'filtri'
      this.report = stampa
    },
    componentValueChange(model, value) {
      this.filtri[model] = value
      console.log(model, value)
    },
    openFluentReports() {
      const nomeReport = this.report.nomeReport
      console.log(nomeReport)
      let nonCompleti = false
      this.report.filtri.forEach(el => {
        if (el.required && !this.filtri[el.model]) {
          nonCompleti = true
        }
      })
      if (nonCompleti) {
        this.$toast('Dati non completi, verificare...', { type: 'fail', duration: 20000 })
      } else {
        const start = this.filtri.dataDa ? moment(this.filtri.dataDa, 'DD/MM/YYYY').format('YYYYMMDD') : 'ddd' // nel caso il report non li prevedesse
        const stop = this.filtri.dataA ? moment(this.filtri.dataA, 'DD/MM/YYYY').format('YYYYMMDD') : 'aaa'

        const filtriAggiuntivi = []
        this.report.filtri.filter(x => !['dataDa', 'dataA'].includes(x.model) && (x.model && !x.model.startsWith('rag-') && x.model !== 'mostraArticoli')).forEach(el => {
          const ff = lodash.pickBy(this.filtri[el.model])
          if (this.filtri[el.model] && ff) {
            filtriAggiuntivi.push({ chiave: el.model, valori: Object.keys(ff), applicazione: el.applicazione })
          } else {
            filtriAggiuntivi.push({ chiave: el.model, valori: [], applicazione: el.applicazione }) // serve il tipo applicazione per eventuale raggruppamento
          }
        })
        const raggruppamenti = []
        console.log(this.filtri)
        console.log(this.report.filtri)
        Object.keys(this.filtri).filter(x => x.startsWith('rag-')).forEach(el => {
          console.log('-->', el)
          const chiave = el.substring(4)
          if (chiave === 'data') {
            raggruppamenti.push({ chiave: 'data', applicazione: 'movimenti' })
          } else {
            const filtro = this.report.filtri.find(x => x.model === chiave)
            raggruppamenti.push({ chiave: filtro.model, applicazione: filtro.applicazione, stampa: filtro.modelPrint || filtro.model })
          }
        })
        const articoli = this.filtri.mostraArticoli ? this.filtri.mostraArticoli : false
        const parametri = {
          intestazione: {
            titolo: this.report.descrizione,
            titolo_01_sx: this.report.titolo_01_sx,
            titolo_02_sx: this.report.titolo_02_sx
          },
          articoli: articoli,
          filtri: filtriAggiuntivi,
          gruppi: raggruppamenti
        }
        console.log(parametri)
        const str = JSON.stringify(parametri)
        // TODO: Verificare, altrimenti correggere con path.combine
        // window.open(`${this.pathReportPrefix}/reporting/pdfm/${nomeReport}/${start}/${stop}?par=${btoa(str)}`)
        const source = `${this.pathReportPrefix}/reporting/pdfm/${nomeReport}/${start}/${stop}?par=${btoa(str)}`
        this.$router.push({ name: 'Pdf', params: { source: source } })
      }
    },
    onOpen() {
    },
    closePopup() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .van-field__control {
    width: auto;
  }

  .ddTitleClass {
    justify-content: left;
  }

  .titleClass {
    text-align: center;
    color: red;
  }

  .buttonStampa {
    margin-top: 20px;
  }

  .swipe-cell-button {
    height: 100%;
  }
  .van-search .van-cell {
    padding: 10px 16px;
  }

  .van-search--show-action {
      padding-right: 12px;
  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupReport, .main-popup-report {
      width: 75%;
      height: 100%;
    }
  }
  @media all and (min-width:1024px) {
    .popupReport, .main-popup-report {
      width: 55%;
      height: 100%;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-report {
      width: 30%;
      height: 100%;
    }
  }
</style>

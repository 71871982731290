'use strict'
import errorService from '@/services/errorService'
// const service = process.env.VUE_APP_service_magazzino || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_magazzino || process.env.VUE_APP_service_magazzino
})()

async function cicleSync() {
  try {
    const response = await fetch(`${service}/magazzino/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getRunMode() {
  try {
    const response = await fetch(`${service}/magazzino/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'MAGAZZINO',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function creaMovimentiScontrino(tipo, magazzino, causale, righe, scontrino, documento) {
  // TODO: verificare come prendere magazzino e causale

  // const infoScontrino = { ...scontrino }
  // delete infoScontrino.righe --> passo anche le righe dello scontrino prchè potrebbe esserci un abbuono sul totale o qualche info sul pagamento
  var data = {
    magazzino,
    tipo,
    causale,
    righe,
    scontrino: scontrino,
    documento: documento
  }
  try {
    const response = await fetch(`${service}/magazzino/movimenti`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    errorService.onError(error, 'creaMovimentiScontrino')
  }
}

async function creaMovimenti(movimento) { // TODO: Sistemare, probabilemente non funziona
  try {
    const response = await fetch(`${service}/magazzino/movimenti`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(movimento)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'creaMovimenti')
  }
}

async function getMovimentiParametri(filtro, parametri) {
  try {
    const data = {
      filtro,
      parametri
    }
    const response = await fetch(`${service}/magazzino/analisi/movimenti/parametri`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })

    const result = await response.json()
    return result
  } catch (error) {
    return error
  }
}

async function creaMovimentiMultipli(movimentiMultipli, tipo) {
  try {
    const response = await fetch(`${service}/magazzino/movimenti/multipli${tipo ? '?tipo=pc' : ''}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(movimentiMultipli)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'creaMovimentiMultipli')
  }
}

async function importaInventarioLetture(nomeFile, tipoFile, settore, dataLettura, note, datiRaw, dati) {
  const data = {
    nomeFile,
    tipoFile,
    settore,
    data: dataLettura,
    note,
    datiRaw,
    dati
  }
  try {
    const response = await fetch(`${service}/magazzino/inventario/importazione/letture`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch {

  }
}

export default {
  cicleSync,
  getRunMode,
  creaMovimentiScontrino,
  creaMovimenti,
  getMovimentiParametri,
  creaMovimentiMultipli,
  importaInventarioLetture
}

<template>
  <van-popup :value="true" :closeable="false" :close-on-click-overlay="false" class="popup-cashmatic" @close="onClose" @open="onOpen">
    <h3 class="info-center">{{ presenzaVincita ? 'Gestione pagamento vincita' : 'Gestione pagamento in contanti' }}</h3>
    <template v-if="sceltaPagamento">
      <div class="spacer" />
      <template v-if="presenzaVincita">
        <van-row type="flex" justify="space-around" class="mt-5">
          <van-button type="primary" @click="pagaCassa">VINCITA DALLA CASSA<div class="small-text black">consegna il denaro dalla cassa</div></van-button>
          <van-button :type="presenzaVincita ? 'danger' : 'info'" @click="vincitaCashmatic">VINCITA DA CASHMATIC<div class="small-text" :class="presenzaVincita ? '': 'black'">consegna il denaro da Cashmatic</div></van-button>
        </van-row>
      </template>
      <template v-else>
        <van-row type="flex" justify="space-around" class="mt-5">
          <van-button type="primary" @click="pagaCassa">PAGA ALLA CASSA<div class="small-text black">il cliente consegna il denaro</div></van-button>
          <van-button type="info" @click="pagaCashmatic">PAGA SU CASHMATIC<div class="small-text black">abilita gestione Cashmatic</div></van-button>
        </van-row>
      </template>
      <van-row type="flex" justify="space-around" class="annulla-pagamento">
        <van-button type="danger" plain size="small" @click="annullaPagamento">ANNULLA IL PAGAMENTO<div class="small-text">interrompe l'operazione</div></van-button>
      </van-row>
    </template>
    <template v-else>
      <h4 class="info-center red blink" v-if="erroreResto">ATTENZIONE! VERIFICARE ERRORE EROGAZIONE RESTO !!</h4>
      <van-row type="flex" justify="start">
        <van-col span="12">
          <van-cell-group>
            <van-cell title="Importo richiesto" :value="importoRichiesto | euro" value-class="cash-testo"/>
            <van-cell title="Importo inserito" :value="importoInserito | euro" value-class="cash-testo"/>
            <van-cell title="Resto erogato" :value="restoErogato | euro" value-class="cash-testo"/>
            <van-cell title="Resto non erogato" :value="restoNonErogato | euro" value-class="cash-testo cash-errore"/>
            <div style="float:right;"><van-button type="primary" v-if="abilitaChiusuraManuale" size="small" @click="chiudiManuale">OK, gestito!</van-button></div>
          </van-cell-group>
        </van-col>
      </van-row>
      <van-row type="flex" justify="space-around" class="mt-5">
        <van-button type="danger" plain size="small" @click="annullaPagamento" :disabled="erroreResto">ANNULLA IL PAGAMENTO<div class="small-text">interrompe l'operazione</div></van-button>
        <van-button type="warning" size="small" @click="chiudiPagamento" :disabled="!importoInserito || erroreResto">CHIUDE IL PAGAMENTO<div class="small-text black">conclude l'operazione con l'importo inserito</div></van-button>
      </van-row>
    </template>
  </van-popup>
  <!-- <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div class="row">
                <div class="c-left">
                  <div class="modal-title">Gestione pagamento in contanti</div>
                </div>
                <div class="c-right">
                  <button class="modal-default-button bg-white" @click="chiudi">CHIUDI</button>
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <button class="modal-default-button bg-white" @click="pagaCassa">PAGA ALLA CASSA</button>
              <button class="modal-default-button bg-white" @click="pagaCashmatic">PAGA SU CASHMATIC</button>
              <template>
                <table class="cashmatic-result">
                  <tr><td>Importo richiesto</td><td class="cashmatic-importo">{{ importoRichiesto | euro }}</td></tr>
                  <tr><td>Importo inserito</td><td class="cashmatic-importo">{{ importoInserito | euro }}</td></tr>
                  <tr><td>Resto erogato</td><td class="cashmatic-importo">{{ restoErogato | euro }}</td></tr>
                  <tr><td>Resto non erogato</td><td class="cashmatic-importo">{{ restoNonErogato | euro }}</td></tr>
                  <tr><td><span v-if="erroreResto" class="labelErrore">ATTENZIONE! VERIFICARE ERRORE EROGAZIONE RESTO !!</span></td></tr>
                  <tr></tr>
                  <tr>
                    <td><button class="modal-default-button bg-white" @click="annullaPagamento">ANNULLA IL PAGAMENTO</button></td>
                    <td><button class="modal-default-button bg-white" @click="chiudiPagamento">CHIUDI IL PAGAMENTO</button></td>
                  </tr>
                </table>
              </template>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition> -->
</template>

<script>
import _ from 'lodash'
import cashmatic from '@/services/custom/cashmatic.js'
export default {
  props: {
    context: Object
  },
  data() {
    return {
      timerStato: null,
      statoTransazione: null,
      importoRichiesto: '',
      importoInserito: '',
      restoErogato: '',
      restoNonErogato: '',
      sceltaPagamento: true,
      abilitaChiusuraManuale: false,
      cashmaticLastTransaction: null
    }
  },
  computed: {
    importoDaPagare() {
      // return (this.context.daPagareTotale || this.context.totaleScontrino) - (this.context.totaleVincite || 0) // totaleScontrino
      return (this.context.daPagareTotale || this.context.totaleScontrino)
    },
    importoResto() {
      return this.context.resto || 0
    },
    presenzaVincita() {
      return this.importoResto > 0
    },
    periodoPolling() {
      return _.get(this.context, 'cassa.cashmatic.pollingMs', 200)
    },
    erroreResto() {
      return !!this.restoNonErogato
    }
  },
  methods: {
    onOpen() {
      this.sceltaPagamento = true
    },
    onClose() {
      // verificare
      if (this.timerStato) {
        clearInterval(this.timerStato)
      }
    },
    chiudi() {
      this.$emit('close')
      if (this.timerStato) {
        clearInterval(this.timerStato)
      }
    },
    chiudiManuale() {
      this.stampaScontrino()
    },
    pagaCassa() {
      this.cashmaticLastTransaction = null
      this.stampaScontrino()
    },
    async pagaCashmatic() {
      this.sceltaPagamento = false
      const centesimi = Math.round(parseFloat(this.importoDaPagare) * 100)
      await cashmatic.avviaTransazione(centesimi, 'acquisto', 'scontrino')
      this.timerStato = setInterval(async () => {
        let st = await cashmatic.statoTransazione()
        if (st.data.operation !== 'idle') {
          this.statoTransazione = st
          this.aggiornaDisplay(st)
        } else {
          clearInterval(this.timerStato)
          st = await cashmatic.infoUltimaTransazione()
          this.aggiornaDisplay(st)
          if (!this.erroreResto) {
            // Se ho errore sul resto devo lasciare aperta la finestra per farlo capire all'utente!
            this.stampaScontrino()
          } else {
            this.abilitaChiusuraManuale = true
          }
        }
      }, this.periodoPolling) // this.periodoPolling
    },
    aggiornaDisplay(stato) {
      if (stato && stato.data) {
        this.importoRichiesto = +(stato.data.requested / 100).toFixed(2)
        this.importoInserito = +(stato.data.inserted / 100).toFixed(2)
        this.restoErogato = +(stato.data.dispensed / 100).toFixed(2)
        this.restoNonErogato = +(stato.data.notDispensed / 100).toFixed(2)

        this.cashmaticLastTransaction = stato
      }
    },
    async vincitaCashmatic() {
      this.sceltaPagamento = false
      const centesimi = Math.round(parseFloat(this.importoResto) * 100)
      await cashmatic.avviaPrelievo(centesimi, 'vincita', 'scontrino')
      this.timerStato = setInterval(async () => {
        let st = await cashmatic.statoTransazione()
        if (st.data.operation !== 'idle') {
          this.statoTransazione = st
          this.aggiornaDisplay(st)
        } else {
          clearInterval(this.timerStato)
          st = await cashmatic.infoUltimaTransazione()
          this.aggiornaDisplay(st)
          if (!this.erroreResto) {
            // Se ho errore sul resto devo lasciare aperta la finestra per farlo capire all'utente!
            this.stampaScontrino()
          } else {
            this.abilitaChiusuraManuale = true
          }
        }
      }, this.periodoPolling) // this.periodoPolling
    },
    async annullaPagamento() {
      await cashmatic.annullaTransazione()
      this.chiudi()
    },
    async chiudiPagamento() {
      await cashmatic.concludiTransazione()
      // TODO: In questo caso, l'utente decide di consentire la chiusura anche se l'importo non è completo (es. mancaso 10 centesimi...lasci...)
      // Verificare se deve essere segnato come sconto ulteriore sul subtotale
      this.stampaScontrino()
    },
    stampaScontrino() {
      // richiama evento che chiude la modale e stampa lo scontrino
      var ev = {
        type: 'CASHMATIC_PAGAMENTO_EFFETTUATO',
        payload: this.cashmaticLastTransaction
      }
      this.$emit('send-event', ev)
      this.cashmaticLastTransaction = null
      if (this.timerStato) {
        clearInterval(this.timerStato)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .popup-cashmatic {
    width: 40vw;
    height: 60vh; /* 47 Attenzione lasciare spazio per pulsanti aggiornamento */
  }

  @media all and (max-width: 1800px) {
    .popup-cashmatic {
      width: 50vw;
      height: 67vh;
    }
  }

  @media all and (max-width: 1400px) {
    .popup-cashmatic {
      width: 55vw;
      height: 72vh; /* 52 Attenzione lasciare spazio per pulsanti aggiornamento */
    }
  }

  @media all and (max-width: 1024px) {
    .popup-cashmatic {
      width: 80vw;
      height: 72vh; /* 52 Attenzione lasciare spazio per pulsanti aggiornamento */
    }
  }

  .info-center {
    text-align: center;
    &.red {
      color: red;
    }
    &.orange {
      color: #ff8800;
    }
  }

  .cash-testo {
    font-weight: bold;
    color: black;
    &.cash-errore {
      color: red;
    }
  }

  .popup-stampante .van-cell {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .blink {
    animation: blinker 1s step-start infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .small-text {
    font-size:x-small;
    &.black {
      color: black;
    }
  }
  .mt-5 {
    margin-top: 50px;
  }
  .annulla-pagamento {
    bottom: 20px;
    top: auto;
    position: absolute;
    right: 30px;
  }

  .spacer {
    height: 50px;
  }

</style>

<style lang="scss" scoped>
 /*
  @import "../modale.scss";
  @import "./style.scss";

  div[name="ModaleCashmatic"] {
    width: 40vw;
    height: 50vh;

    top: 25vh;
    left: 30vw;
  }

  .c-left {
    justify-self: left;
  }
  .c-right {
    justify-self: right;
  }

  .labelErrore {
    color: red;
    font-weight: bold;
  }

  table.cashmatic-result {
    & tr {
      height: 30px;
      & td.cashmatic-importo {
        text-align: right;
      }
    }
  }
*/
</style>

<template>
  <transition name="modal">
    <div class="modal-mask" :class="{'transparent-background' : config.transparentBackground, 'clickable-left' : config.clickableBackground && clickableLeft , 'clickable-right' : config.clickableBackground && !clickableLeft, hidden: config.hideModale, hideTastiera: !keyboardShow || config.hideTastiera }" >
      <div class="modal-wrapper" v-on="!config.disableEsciOnClick ? { click: onClickEsci } : {}"> <!--  @click="onClickEsci" -->
        <div class="modal-container" @click.stop.prevent :class="config.modalLeft ? 'modal-left' : config.modalRight ? 'modal-right' : ''">
          <div class="modal-header">
            <slot name="header">
              <div class="row">
                <div>
                  <div class="modal-title">{{ titolo || 'Titolo Modale' }}</div>
                </div>
                <!-- <div class="">
                  <div class="infoCliente">Secondo Titolo Modale ( es codice cliente )</div>
                </div> -->
                <div class="">
                  <div class="infoCliente">
                    <button v-if="config.showBtnSposta" class="btnSposta van-button van-button--primary van-button--normal" @click="onClickSposta">Sposta</button>
                    <button v-if="!config.hideBtnSalva" class="btnSalva van-button van-button--primary van-button--normal" @click="onClickSalva">{{ config.salvaText || 'Salva' }}</button>
                    <button class="btnEsci van-button van-button--danger van-button--normal" @click="onClickEsci">Esci</button>
                  </div>
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-body" :class="!config.hideScrollbar ? 'showScrollbar' : ''">
            <slot name="body">
              <componente-modale :config="config" :modelItem="modelItem"  v-for="c in campiVisibili" :key="c.campo" :componente="c" @on-focus="onComponentFocus" @cascade-filter="onCascadeFilter"
                @on-blur="onComponentBlur" ref="components"></componente-modale>
            </slot>

          </div>
          <div class="modal-footer" v-if="keyboardShow && !config.hideTastiera">
            <div class="tastiera" >
                <simple-keyboard class="keyboard-popup-tastiera" enterText="Prossimo" :keyboardClass="config.keyboardClass || 'kb_tastiera'" @onKeyPress="onKeyPress" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SimpleKeyboard from './Keyboard'
import ComponenteModale from './ComponenteModale'
import * as dot from 'dot-object'

export default {
  components: {
    SimpleKeyboard,
    ComponenteModale
  },
  props: {
    context: Object,
    modelItem: Object,
    titolo: String,
    campi: Array,
    config: Object,
    keyboardClass: {
      default: 'kb_tastiera',
      type: String
    }
  },
  data() {
    return {
      mostraCampiCliente: '',
      testoComandoEnter: 'Enter',
      input: '',
      keyboardShow: true,
      clickableLeft: true,
      activeComponent: Object
    }
  },
  created() {
  },
  computed: {
    campiVisibili() {
      // console.log(this.campi.filter((obj) => !obj.hidden))
      return this.campi.filter((obj) => !obj.hidden)
    }
  },
  mounted() {
    var self = this
    setTimeout(() => {
      var firstComp = self.getNextComponent()
      firstComp && self.setComponentFocus(firstComp)
      self.afterInit()
    }, 300)
  },
  methods: {
    afterInit() {
      var cc = this.$refs.components || []
      for (var i = 0; i < cc.length; i++) {
        cc[i].afterInit()
      }
    },
    onComponentFocus(component) {
      if (this.activeComponent !== component) {
        // console.log('on focus ')
        this.setComponentFocus(component)
      }
    },
    onComponentBlur(component) {
      if (this.activeComponent === component) {
        this.removeActiveComponentFocus()
      }
    },
    setComponentFocus(component) {
      this.removeActiveComponentFocus()
      this.activeComponent = component
      this.activeComponent.setFocus()
    },
    removeActiveComponentFocus() {
      if (this.activeComponent) {
        this.activeComponent.removeFocus && this.activeComponent.removeFocus()
        this.activeComponent = null
      }
    },
    onCascadeFilter(item) {
      var c = this.getComponentByName(item.source.cascadeOn)
      c && c.filtraElementi(item.source.cascadeField, item.valore)
    },
    async onChange(input) {
      // this.input = input
    },
    getComponentByName(nomeCampo) {
      return this.$refs.components ? this.$refs.components.find((obj) => { return obj.componente.campo === nomeCampo }) : null
    },
    onKeyPress(valore, daTastieraFisica) {
      if (valore === '{next}') {
        var nextComponent = this.getNextComponent()
        nextComponent && this.setComponentFocus(nextComponent)
      } else {
        this.activeComponent && this.activeComponent.onKeyInput(valore, daTastieraFisica)
      }
      /* this.button = button
      if (!this.insertNuovoCliente && !this.modificaDatiCliente && button === '{esci}') {
        this.seleziona()
      } */
    },
    getNextComponent() {
      if (!this.$refs.components) {
        return null
      }
      var index = -1
      var firstIndex = -1
      var trovato = false
      if (this.activeComponent) {
        index = this.$refs.components.findIndex((obj) => { return obj === this.activeComponent })
        firstIndex = index
      }
      while (!trovato) {
        index = index + 1 >= this.campiVisibili.length ? 0 : index + 1
        if (!this.campiVisibili[index].readonly) {
          trovato = true
        } else if (index === firstIndex) { // ho fatto il giro ma non ho trovato nessun campo focusable
          return null
        }
        if (firstIndex === -1) { // copro il caso in cui non ho nessun focus al momento e il primo campo è readonly ( altrimenti uscirei subito )
          firstIndex = 0
        }
      }
      return this.$refs.components[index]
    },
    async onInputChange(input) {
      // this.input = input.target.value
    },
    valida() {
      var err = []
      var cc = this.$refs.components || []
      var primoInErrore = null
      for (var i = 0; i < cc.length; i++) {
        var c = cc[i]
        var v = c.valida() || v
        if (v.length && !primoInErrore) {
          primoInErrore = c
        }
        err = err.concat(v)
      }
      if (primoInErrore) {
        this.setComponentFocus(primoInErrore)
      }
      return err
    },
    getDati() {
      var dati = {}
      var cc = this.$refs.components || []
      for (var i = 0; i < cc.length; i++) {
        var c = cc[i]
        dati[this.campiVisibili[i].campo] = (c.getDati && c.getDati()) || null
      }
      return dati
    },
    getDatiDotNotation() {
      var dati = {}
      for (var i = 0; i < this.campiVisibili.length; i++) {
        if (this.campiVisibili[i].readonly || !this.campiVisibili[i].model) {
          continue
        }
        var c = this.getComponentByName(this.campiVisibili[i].campo)
        var val = (c.getDati && c.getDati()) || null
        dati[this.campiVisibili[i].model] = val
      }

      var obj = dot.object(dati)
      return obj
    },
    setValoreSuCampo(nomeCampo, valore) {
      var c = this.getComponentByName(nomeCampo)
      c && c.setValore(valore)
    },
    isCampoCompilato(campo) {
      var c = this.$refs.components.find((obj) => { return obj.componente.campo === campo })
      return c && c.getDati()
    },
    onClickSalva() {
      this.$emit('salva')
    },
    onClickEsci() {
      this.$emit('close')
    },
    onClickSposta() {
      this.clickableLeft = !this.clickableLeft
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "../modale.scss";
  @import "./style.scss";

  .modal-container{
    height: 95%;
  }

  .modal-mask.transparent-background {
    background-color: unset;
  }

  .modal-mask.clickable-left {
    width: 50%;
    & .modal-container {
      /* margin: 15px 0; */
      width: 94%;
    }
  }

   .modal-mask.clickable-right {
    width: 50%;
    margin-left: 50vw;
    & .modal-container {
      width: 94%;
    }
  }

  .modal-container.modal-left {
    width: 50%;
    float:left;
    margin-left:10px;
  }

  .modal-container.modal-right {
    width: 50%;
    float:right;
    margin-right:10px;
  }

  .modal-body {
    /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    background-color: #ececec;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .modal-title {
    font-size: 3vh;
    padding-top: 4px;
    font-weight: bold;
    font-family: 'Montserrat';
  }

  .modal-footer {
    height: 25px;
    & button {
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 20px;
      padding-right:20px;
      &.primary {
        background-color: gray;
      }
      &.action {
        background-color: #4282fa;
      }
    }
  }

  .infoCliente {
    width: 100%;
    text-align: right;
    font-size: 16px;
    font-family: 'Montserrat';
    padding-top: 5px;
  }

  .ricerca {
    & input {
      transition: opacity 1s;
      width: 100%;
      padding: 15px 15px 15px 20px;
      font-size: 20px;
      border: none;
      box-sizing: border-box;
      background-color: #ececec;
      color:#000000;
      outline: none;
      &.campiCliente {
        transition: all 1s;
        display: none;
      }
    }

    & .creazioneCliente {
      opacity: 0;
      margin-top: 15px;
      text-align: center;
      font-weight: bold;
      font-size: 23px;
      &.campiCliente {
        transition: opacity 1s;
        opacity: 1;
      }
    }
  }

  .documento {
    & input[type='checkbox'] {
      margin-right: 5px;
    }
  }

  .tastiera-element {
    top: 0;
    transition: top 1s;
    position: relative;
    z-index: 999;
    &.campiCliente {
      top: 90%;
    }
  }
  .nuovo-cliente-cnt {
    height: 0;
  }

  .modal-body.showScrollbar
  {
    &::-webkit-scrollbar{
      display: unset !important;
      width: 2vw;
    }
    &::-webkit-scrollbar-track {
      /*box-shadow: inset 0 0 5px grey;*/
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #666666;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #666666;
    }
  }
  .btnSalva, .btnEsci, .btnSposta {font-size: 1em;border-radius: 5px;}
  .btnSalva {background: #339900;}
  .btnEsci {background: #cc0000;}
  .btnSposta {background: #666666;}
</style>

<style scoped>
  .modal-body >>> .modalFormInput,
  .modal-body >>> .modalFormRicerca {
    width: 90%;
    height: 7vh;
    border: solid 2px #cccccc;
    border-radius: 7px;
    font-size: 3vh;
    padding: 10px;
  }

  .modal-body >>> .modalFormRicerca{
    width: 82%;
  }

  .modal-body >>> .multiselect.hasFocus .modalFormInput,
  .modal-body >>> .multiselect.hasFocus .modalFormRicerca,
  .modal-body >>> .multiselect.hasFocus .multiselect__tags
  {
    border-color: #0088ff;
  }

  .modal-body >>> .notValid .modalFormInput,
  .modal-body >>> .notValid .modalFormRicerca,
  .modal-body >>> .notValid .multiselect__tags
  {
    border-color: #eb0000;
  }

  .modal-body >>> .labelForm{
    font-size:2vh;
    margin-bottom: 1px;
    margin-left: 5px;
    font-weight: bold;
  }

  .modal-body >>> .labelErrore{
    font-size: 1.5vh;
    margin-left: 10px;
    margin-bottom: 5px;
    font-weight: bold;
    color: red;
    background-color: #ffdddd;
    border: 1px solid;
    border-radius: 0px 0px 10px 10px;
    padding: 5px;
  }

  .modal-body >>> .labelHint{
    margin-top: 4px;
    font-size: 1.5vh;
    margin-left: 1vh;
    font-weight: bold;
  }

  .modal-body >>> .modalField{
    margin-bottom: 20px;
  }

  .modal-mask.hidden {
    display: none;
  }

</style>

'use strict'
import errorService from '@/services/errorService'
import configurazioni from './configurazioniService'

// const service = process.env.VUE_APP_service_magazzino || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_documenti || process.env.VUE_APP_service_documenti
})()

async function getRunMode() {
  try {
    const response = await fetch(`${service}/documenti/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'DOCUMENTI',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function cicleSync() {
  try {
    const response = await fetch(`${service}/documenti/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getFilteredDocumentHeaders(filtri, opzioni) {
  const data = {
    filtri,
    opzioni
  }
  try {
    const response = await fetch(`${service}/documenti/testate/filter`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    return []
  }
}

async function creaFatturaImmediata(tipoDocumento, cliente, tipoPagamento, righe) {
  // legge tipo documento da documenti_parametri.tipiDocumento
  // deve salvare il numero scontrino all'interno della fattura e viceversa
  const elenco = await configurazioni.getConfigurazione('documenti_parametri', 'tipiDocumento')
  const infoTipo = elenco.find(x => x.codice === tipoDocumento)
  if (!infoTipo) return null // TODO: gestire errore e tipo
  const dataDocumento = new Date()
  const annoRiferimento = dataDocumento.getFullYear()
  const numeroDocumento = await configurazioni.getNumeratore(infoTipo.numeratore, true, annoRiferimento)
  let destinazione = cliente.recapiti.find(x => x.tipo.default)
  if (!destinazione) {
    destinazione = cliente.recapiti.find(x => x.tipo.codice === 'principale')
  }
  if (!destinazione) return null // TODO: gestire errore e tipo
  const pagamento = {
    condizioni: 'TP02', // completo . TODO: Varificare se altri pagamenti
    dettaglio: {
      modalita: tipoPagamento === 'CARTA_DI_CREDITO' ? 'MP08' : 'MP01' // MP01:contanti, MP08: carte
    }
  }

  const testata = {
    _id: numeroDocumento,
    codice: numeroDocumento,
    data: dataDocumento,
    tipoDocumento: infoTipo,
    anagrafica: {
      cliente: {
        codice: cliente.codice,
        descrizione: cliente.ragioneSociale,
        amministrativi: cliente.amministrativi,
        fatturazione: cliente.fatturazione
      }
    },
    pagamento: pagamento,
    spedizione: {
      destinazione: destinazione
    },
    righe: []
  }
  for (const rr of righe) {
    // attenzione alle note valorizzate o da riportare in fattura
    const rigaDocumento = {}
    if (rr.codice && rr.codice.toLowerCase() !== 'notalibera') {
      rigaDocumento.rigaDescrittiva = false
      rigaDocumento.prodotto = {
        codice: rr.codice
      }
    } else {
      rigaDocumento.rigaDescrittiva = true
    }
    rigaDocumento.descrizione = rr.descrizione
    rigaDocumento.quantita = rr.quantita || 0
    rigaDocumento.unitaMisura = rr.unitaMisura || ''
    rigaDocumento.sconti = rr.sconti || ''
    if (rr.iva) {
      rigaDocumento.aliquotaIva = {
        codice: rr.iva.codice,
        valore: rr.iva.aliquota,
        descrizione: rr.iva.descrizione,
        tipoApplicazione: 'banco'
      }
    }
    if (rr.prezzoUnitario) {
      rigaDocumento.valore = {
        listino: rr.listino,
        // verificare come inserire gli sconti
        sconto1: '',
        sconto2: '',
        sconto3: '',
        sconto4: null,
        prezzoUnitario: rr.prezzoUnitario,
        prezzoTotale: rr.prezzoTotale,
        prezzoNettoUnitario: rr.prezzoNettoUnitario,
        prezzoNettoTotale: rr.prezzoNettoTotale,
        importoUnitario: rr.importoUnitario,
        importoTotale: rr.importoTotale,
        importoNettoUnitario: rr.importoNettoUnitario,
        importoNettoTotale: rr.importoNettoTotale
      }
    }
    testata.righe.push(rigaDocumento)
  }
  return await creaDocumento(testata)
}

async function creaDocumentoDiTrasporto(tipoDocumento, cliente, tipoPagamento, righe) {
  // per Fattura differita
  // deve salvare il numero scontrino all'interno del ddt e viceversa
  const elenco = await configurazioni.getConfigurazione('documenti_parametri', 'tipiDocumento')
  const infoTipo = elenco.find(x => x.codice === tipoDocumento)
  if (!infoTipo) return null // TODO: gestire errore e tipo
  const dataDocumento = new Date()
  const annoRiferimento = dataDocumento.getFullYear()
  const numeroDocumento = await configurazioni.getNumeratore(infoTipo.numeratore, true, annoRiferimento)
  let destinazione = cliente.recapiti.find(x => x.tipo.default)
  if (!destinazione) {
    destinazione = cliente.recapiti.find(x => x.tipo.codice === 'principale')
  }
  if (!destinazione) return null // TODO: gestire errore e tipo
  const pagamento = {
    condizioni: 'TP02', // completo . TODO: Varificare se altri pagamenti
    dettaglio: {
      modalita: tipoPagamento === 'CARTA_DI_CREDITO' ? 'MP08' : 'MP01' // MP01:contanti, MP08: carte
    }
  }
  const testata = {
    _id: numeroDocumento,
    codice: numeroDocumento,
    data: dataDocumento,
    tipoDocumento: infoTipo,
    anagrafica: {
      cliente: {
        codice: cliente.codice,
        descrizione: cliente.ragioneSociale,
        amministrativi: cliente.amministrativi,
        fatturazione: cliente.fatturazione
      }
    },
    pagamento: pagamento,
    spedizione: {
      destinazione: destinazione
    },
    righe: []
  }
  for (const rr of righe) {
    // attenzione alle note valorizzate o da riportare in fattura
    const rigaDocumento = {}
    if (rr.codice && rr.codice.toLowerCase() !== 'notalibera') {
      rigaDocumento.rigaDescrittiva = false
      rigaDocumento.prodotto = {
        codice: rr.codice
      }
    } else {
      rigaDocumento.rigaDescrittiva = true
    }
    rigaDocumento.descrizione = rr.descrizione
    rigaDocumento.quantita = rr.quantita || 0
    rigaDocumento.unitaMisura = rr.unitaMisura || ''
    rigaDocumento.sconti = rr.sconti || ''
    if (rr.iva) {
      rigaDocumento.aliquotaIva = {
        codice: rr.iva.codice,
        valore: rr.iva.aliquota,
        descrizione: rr.iva.descrizione,
        tipoApplicazione: 'banco'
      }
    }
    if (rr.prezzoUnitario) {
      rigaDocumento.valore = {
        listino: rr.listino,
        // verificare come inserire gli sconti
        sconto1: '',
        sconto2: '',
        sconto3: '',
        sconto4: null,
        prezzoUnitario: rr.prezzoUnitario,
        prezzoTotale: rr.prezzoTotale,
        prezzoNettoUnitario: rr.prezzoNettoUnitario,
        prezzoNettoTotale: rr.prezzoNettoTotale,
        importoUnitario: rr.importoUnitario,
        importoTotale: rr.importoTotale,
        importoNettoUnitario: rr.importoNettoUnitario,
        importoNettoTotale: rr.importoNettoTotale
      }
    }
    testata.righe.push(rigaDocumento)
  }
  return await creaDocumento(testata)
}

/* async function creaScontrinoSegueFattura() {
  // preparare i campi da aggiungere su XML con riferimento scontrino
}

 */
async function getModello(tipo) {
  const response = await fetch(`${service}/documenti/modello/${tipo}`)
  return await response.json()
}

async function getDocumento(id) {
  try {
    const response = await fetch(`${service}/documenti/${encodeURIComponent(id)}`)
    return await response.json()
  } catch (error) {
    return null
  }
}

async function creaDocumentiMultipli(magazzino, parametri, documenti) {
  try {
    const data = {
      magazzino,
      parametri,
      documenti
    }
    const response = await fetch(`${service}/documenti/multipli`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return response.json()
  } catch (error) {
    console.log(error)
    // winston.error(`creaDocumentiMultipli: ${error}`);
  }
  return {}
}

async function creaDocumento(documento) {
  try {
    const response = await fetch(`${service}/documenti/singolo`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(documento)
    })
    return response.json()
  } catch (error) {
    console.log(error)
    errorService.onError('creaDocumento', error)
  }
  return {}
}

async function aggiornaDocumento(documento) {
  try {
    const response = await fetch(`${service}/documenti/${encodeURIComponent(documento._id)}/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(documento)
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    errorService.onError(error, 'aggiornaDocumento')
  }
}

async function cancellaDocumento(documentoId) {
  try {
    const response = await fetch(`${service}/documenti/${encodeURIComponent(documentoId)}/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    errorService.onError(error, 'cancellaDocumento')
  }
}

async function aggiornaScontrinoDocumento(documentoId, scontrino) {
  try {
    const data = {
      scontrino
    }
    const response = await fetch(`${service}/documenti/${encodeURIComponent(documentoId)}/scontrino`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    errorService.onError(error, 'aggiornaDocumento')
  }
}

async function riscontroDocumento(documentoId, righe) {
  try {
    const data = {
      righe
    }
    const response = await fetch(`${service}/documenti/${encodeURIComponent(documentoId)}/riscontro`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    errorService.onError(error, 'riscontroDocumento')
  }
}

export default {
  getRunMode,
  cicleSync,
  getFilteredDocumentHeaders,
  getDocumento,
  getModello,
  creaDocumento,
  aggiornaDocumento,
  aggiornaScontrinoDocumento,
  creaDocumentiMultipli,
  cancellaDocumento,
  creaFatturaImmediata,
  creaDocumentoDiTrasporto,
  riscontroDocumento
}

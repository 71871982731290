<template>
  <div class="inputText" :class="!isValid ? 'notValid' : ''">
    <div class="labelForm">{{item.label || 'Testo Label'}}</div>
    <div @click="onFocus">
      <input class="modalFormInput" :placeholder="item.placeHolder || 'GG / MM / AAAA'"  v-model="dateText"
        ref="input" @focus="onFocus" @input="onKeyboardInput" v-mask="'## / ## / ####'" :readonly="item.readonly" :disabled="item.readonly" />
      <button class="modalCalendar" v-if="!item.readonly" @click="openCalendar" tabindex="-1">
        <svg  width="45" height="45" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor" class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"><path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
      </button>
    </div>
    <span v-if="!isValid" class="labelErrore">{{ msgErrore || 'Valore non valido' }}</span>
    <div v-if="item.hint" class="labelHint">
      {{ item.hint }}
    </div>
    <div class="cntCalendarModale" v-show="showCalendar" @click="showCalendar = false">
      <div class="overlayCalendar"  @click.stop>
        <v-date-picker @dayclick="dayClick" v-model="value" ref="cal"></v-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mask } from 'vue-the-mask'

export default {
  props: {
    item: Object
  },
  data() {
    return {
      isValid: true,
      msgErrore: '',
      value: null,
      msgValidazioni: [],
      showCalendar: false,
      dateText: ''
    }
  },
  directives: { mask },
  watch: {
    value(value) {
      this.$forceUpdate()
      this.$emit('update-value', this.getDati())
    }
  },
  created() {
  },
  mounted() {
    if (this.item.valore) {
      this.value = this.item.valore
      this.dateText = moment(this.value).format('DDMMYYYY')
      this.$refs.cal.move(this.value)
    }
  },
  methods: {
    onFocus(event) {
      this.$emit('on-focus')
    },
    onBlur(event) {
      this.$emit('on-blur')
    },
    onInput(event) {
      // this.$v.value.$touch()
      // this.checkValidations()
    },
    onKeyboardInput(event) {
      if (this.item.readonly) {
        return
      }
      if (!event.inputType) { // inputType viene solo dalla tastiera fisica
        return
      }
      if (event.inputType === 'deleteContentBackward') {
        this.checkBackwardsKeyPress()
      }
    },
    setFocus() {
      this.$refs.input.focus({ preventScroll: true })
    },
    removeFocus() {
      this.$refs.input.blur()
      this.validaDataText()
    },
    openCalendar() {
      this.validaDataText()
      this.showCalendar = true
    },
    closeCalendar() {
      this.showCalendar = false
    },
    validaDataText() {
      var m = moment(this.dateText, 'DD / MM / YYYY')
      if (!m || !m.isValid()) {
        this.value = null
        this.dateText = ''
      } else {
        this.value = m.toDate()
        this.dateText = m.format('DDMMYYYY')
        // this.$refs.cal.updateValue(this.value)
        this.$refs.cal.move(this.value)
      }
      this.valida()
    },
    onKeyInput(value, daTastieraFisica) {
      if (this.item.readonly) {
        return
      }
      if (value === '{space}') {
        value = ''
      }
      if (!daTastieraFisica && value && value.length === 1) {
        this.dateText += value
        this.onInput()
      } else if (value === '{backspace}' || value === '{bksp}') {
        if (this.dateText.length > 0) {
          this.dateText = this.dateText.substring(0, this.dateText.length - 1)
          this.checkBackwardsKeyPress()
          this.onInput()
        }
      }
      var self = this
      setTimeout(() => {
        self.$refs.input.focus()
      }, 100)
    },
    checkBackwardsKeyPress() {
      if (this.dateText.length > 0) {
        var char = this.dateText[this.dateText.length - 1]
        while (char && isNaN(parseInt(char))) {
          this.dateText = this.dateText.substring(0, this.dateText.length - 1)
          char = this.dateText[this.dateText.length - 1]
        }
      }
    },
    dayClick(obj) {
      this.value = obj.date
      this.dateText = moment(obj.date).format('DDMMYYYY')
      this.closeCalendar()
      this.valida()
      this.onBlur()
    },
    valida() {
      if (!this.item.validations || !this.item.validations.length) {
        return []
      }
      var req = this.item.validations.find((obj) => obj.nome === 'required')
      var err = []
      if (req && !this.value) {
        this.isValid = false
        this.messaggioErrore = req.msgErrore || 'Selezionare una data'
        err.push(this.messaggioErrore)
      } else {
        this.isValid = true
        this.messaggioErrore = ''
      }
      return err
    },
    getDati() {
      return this.value
    }
  }
}
</script>

<style lang="scss">
.cntCalendarModale{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000008c;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
}
.overlayCalendar{
    margin-top: 120px;
    transform: scale(1.5);
}
.modalCalendar{
    float: right;
    background: transparent;
}
</style>

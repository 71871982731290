'use strict'
import errorService from '@/services/errorService'
// const service = process.env.VUE_APP_service_stampante || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)
var config, service, tipoDriver
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_stampante || process.env.VUE_APP_service_stampante
})()

async function getRunMode() {
  try {
    const response = await fetch(`${service}/stampante/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'STAMPANTE',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function printRigaScontrino(idScontrino, riga) {
  console.log('printRigaScontrino se immediata', riga)
  // return await response.json()
  return { msg: 'ok' }
}

async function stampaScontrino(idScontrino, scontrino, infoChiusuraScontrino, ultimoComando) {
  const data = {
    righe: scontrino,
    info: infoChiusuraScontrino,
    ultimoComando: ultimoComando // per gestione ristampa (per adesso solo EPSON)
  }
  try {
    const response = await fetch(`${service}/stampante/scontrino/${idScontrino}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    return result
  } catch (error) {
    errorService.onError(error, 'stampaScontrino')
    throw error
  }
}

async function stampaScontrinoCortesia(barcode) {
  const data = {
    barcode
  }
  try {
    const response = await fetch(`${service}/stampante/scontrino/cortesia`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'stampaScontrinoCortesia')
    throw error
  }
}

async function stampaScontrinoSoloReso(scontrino, infoChiusuraScontrino, ultimoComando) {
  const data = {
    righe: scontrino,
    info: infoChiusuraScontrino,
    ultimoComando: ultimoComando // per gestione ristampa (per adesso solo EPSON)
  }
  try {
    const response = await fetch(`${service}/stampante/scontrino/soloreso`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    return result
  } catch (error) {
    errorService.onError(error, 'stampaScontrinoSoloReso')
    throw error
  }
}

async function stampaScontrinoGestionale(righe, barcode) {
  const data = {
    righe,
    barcode: barcode
  }
  try {
    const response = await fetch(`${service}/stampante/gestionale/generico`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'stampaScontrinoGestionale')
    throw error
  }
}

async function ristampaBuono(infoRistampa) {
  try {
    const response = await fetch(`${service}/stampante/buono/ristampa`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(infoRistampa)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'stampaScontrinoCortesia')
    throw error
  }
}

async function inviaComandi(commands, settings) {
  try {
    const data = {
      commands: commands,
      settings: settings
    }
    const response = await fetch(`${service}/stampante/comandi`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'inviaComandi')
    return { Request: { printerError: 1001, error: 'Errore stampante' } }
  }
}

async function inviaComandiByName(commands, settings) {
  try {
    const td = await getTipoDriver()
    const data = {
      commands: commands,
      settings: settings
    }
    const response = await fetch(`${service}/stampante/comandi/name`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    const result = await response.json()
    switch (td) {
      case 'RCH_PF':
        return {
          status: Object.prototype.hasOwnProperty.call(result, 'Request') ? 'ok' : 'error',
          error: result.error
        }
      case 'EPSON': // TODO: Verificare come inserire
        return {
          status: 'ok',
          error: null
        }
      default:
        return result
    }
  } catch (error) {
    errorService.onError(error, 'inviaComandi')
    return { Request: { printerError: 1001, error: 'Errore stampante' } }
  }
}

async function annullaDocumentoFiscale(fiscale) {
  const data = {
    fiscale
  }
  try {
    const response = await fetch(`${service}/stampante/scontrino/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'annullaDocumentoFiscale')
  }
}

async function displayMessage(righe) {
  const data = {
    righe
  }
  try {
    const response = await fetch(`${service}/stampante/display`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'displayMessage')
  }
}

async function setPrinterDate() {
  const data = {
    date: new Date()
  }
  try {
    const response = await fetch(`${service}/stampante/date`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'setPrinterDate')
  }
}

async function getTipoDriver() {
  if (!tipoDriver) {
    await printerStatus()
  }
  return tipoDriver // Andrebbe richiamata la funzione di lettura dello stato
}
// eventualmente passare l'id della stampante
async function printerStatus() {
  try {
    const response = await fetch(`${service}/stampante/status`)
    const result = await response.json()
    tipoDriver = result.tipoDriver
    return result
  } catch (error) {
    errorService.onError(error, 'printerStatus')
    return {
      error: {
        code: 'NETWORK_ERROR',
        message: ' Errore di comunicazione interno'
      }
    }
  }
}

async function letturaGiornaliera() {
  try {
    const response = await fetch(`${service}/stampante/giornaliero/lettura`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'letturaGiornaliera')
  }
}

async function chiusuraGiornaliera() {
  try {
    const response = await fetch(`${service}/stampante/giornaliero/chiusura`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    errorService.onError(error, 'chiusuraGiornaliera')
  }
}

export default {
  getRunMode,
  printRigaScontrino,
  stampaScontrino,
  stampaScontrinoSoloReso,
  stampaScontrinoGestionale,
  inviaComandi,
  inviaComandiByName,
  stampaScontrinoCortesia,
  annullaDocumentoFiscale,
  displayMessage,
  setPrinterDate,
  printerStatus,
  getTipoDriver,
  letturaGiornaliera,
  chiusuraGiornaliera,
  ristampaBuono
}

<template>
  <van-cell-group>
    <van-form @submit="onSubmit">
      <van-search
        v-model="prodotto"
        show-action
        name="prodotto"
        ref="prodotto"
        label="Prodotto"
        placeholder="Prodotto"
        :error-message="prodotto ? prodottoDescrizione : ''"
        @search="onSearchProdotto"
        @clear="onClearProdotto"
        @focus="onFocus"
        class="mostra-descrizione"
      >
      <template #action>
          <div @click="onSearchProdotto">Cerca</div>
        </template>
      </van-search>
      <div style="margin: 16px;" v-if="addToCartVisible">
        <van-button block type="info" native-type="submit">Aggiungi al carrello</van-button>
      </div>
    </van-form>
  </van-cell-group>
</template>

<script>
import _ from 'lodash'
import prodotti from '@/services/prodottiService.js'

export default {
  props: {
    context: Object
  },
  data() {
    return {
      prodotto: '',
      prodottoDescrizione: '',
      prodottoItem: null,
      addToCartVisible: false,
      activeTab: 'ricerca',
      activeParent: 'prodotti'
    }
  },
  created() {
    this.debounceInputProdotti = _.debounce(this.getElencoProdotti, 500)
  },
  async mounted() {
    this.onOpen()
    this.$refs.prodotto.focus()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.count : 0
    },
    listResult() {
      return this.$store.getters.listResult
    }
  },
  watch: {
    kbInput(value) {
      const variabile = this.activeField
      const valore = String(value)
      this[variabile] = valore
      this.handleKey(valore)
    },
    kbKeyPress(value) {
      if (value === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    },
    kbCounter(value) {
      if (this.kbKeyPress === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    },
    listResult(payload) {
      if (payload.parent !== this.activeParent || payload.tab !== this.activeTab) return
      const item = payload.result
      switch (this.activeField) {
        case 'prodotto':
          this.prodotto = item.codice
          this.prodottoDescrizione = item.descrizione
          this.prodottoItem = item
          this.$store.commit('prodotti/SET_PRODOTTO', this.prodottoItem)
          this.addToCartVisible = true
          break
        default:
          break
      }
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
    }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.tab-popup-funzioni')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    handleKey(valore) {
      switch (this.activeField) {
        case 'prodotto':
          this.debounceInputProdotti(valore)
          break
        default:
          break
      }
    },
    onSearchProdotto() {
      this.getElencoProdotti(this.prodotto)
    },
    onClearProdotto() {
      this.prodottoDescrizione = ''
    },
    async getElencoProdotti(value) {
      if (!value) {
        this.elencoProdotti = []
      } else {
        const result = await prodotti.searchProdotti(value)
        if (result && result.docs && result.docs.length > 0) {
          const lista = result.docs.map(item => {
            const label = []
            if (item.nome) label.push(item.nome)
            if (item.varianti && item.varianti.variante1) label.push(item.varianti.variante1.descrizione)
            if (item.varianti && item.varianti.variante2) label.push(item.varianti.variante2.descrizione)
            if (item.statistici && item.statistici.categoria1) label.push(item.statistici.categoria1.descrizione)
            if (item.statistici && item.statistici.categoria2) label.push(item.statistici.categoria2.descrizione)
            if (item.statistici && item.statistici.categoria3) label.push(item.statistici.categoria3.descrizione)
            if (item.statistici && item.statistici.categoria4) label.push(item.statistici.categoria4.descrizione)
            const fornitore = item.ultimoAcquisto && item.ultimoAcquisto.fornitore ? item.ultimoAcquisto.fornitore : ''
            let fornitoreDescrizione = ''
            if (fornitore) {
              const fr = item.fornitori.find(x => x.codice === fornitore)
              if (fr) {
                fornitoreDescrizione = fr.descrizione
              }
            }
            return {
              codice: item.codice,
              descrizione: item.descrizione,
              prezzoAcquisto: item.ultimoAcquisto && item.ultimoAcquisto.prezzo ? item.ultimoAcquisto.prezzo : '',
              prezzoVendita: item.prezzo && item.prezzo.L1 ? item.prezzo.L1.base : '', // TODO: gestire un prezzo unitario oppure nome listino base su configurazioni ??
              unitaMisura: item.magazzino && item.magazzino.unitaMisura ? item.magazzino.unitaMisura : '',
              unitaMisuraAmmesse: item.magazzino && item.magazzino.unitaMisuraAmmesse ? item.magazzino.unitaMisuraAmmesse : [],
              fornitore,
              fornitoreDescrizione,
              titolo: `${item.codice} - ${item.descrizione}`,
              label: label.join(' - '),
              value: `${item.magazzino ? item.magazzino.giacenza || '---' : '---'}`
            }
          })
          this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
            list: lista,
            icon: 'records',
            classes: {
              titleClass: 'prodotti-lr-title',
              labelClass: 'prodotti-lr-label',
              valueClass: 'prodotti-lr-value'
            }
          })
        } else {
          console.log('getElencoProdotti noresult')
          this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
        }
      }
    },
    onFocus(event) {
      console.log('onfocus')
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
      this.prodottoDescrizione = this.prodotto ? this.prodottoDescrizione : ''
    },
    async onSubmit(values) {
      this.aggiungiAlCarrello()
      this.clearFields()
    },
    onOpen() {
      this.clearSearchInput()
    },
    clearSearchInput() {
      this.prodotto = ''
      this.prodottoDescrizione = ''
      this.addToCartVisible = false
    },
    clearFields() {
      this.clearSearchInput()
    },
    aggiungiAlCarrello() {
      const event = {
        type: 'PRODOTTO_CASSA',
        value: {
          code: this.prodotto,
          tipoAliquota: 'banco'
        }
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }
  .van-search .van-cell {
    padding: 10px 16px;
  }

</style>

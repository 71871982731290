import _ from 'lodash'

const presenzaRigheDaNonStampare = (context) => {
  return context.righeCarrello.some(x => x.stampa && ['nonFiscale', 'comeNota', 'noStampa'].includes(x.stampa))
}

const calcolaArrotondamenti = (context) => {
  // TODO: Mettere abilitazione su Impostazioni

  // let scontoapagare = parseFloat(context.importoPagato.scontoapagare || 0)
  // Lo sconto a pagare è già compreso nei contanti pagati
  // ********** GESTIONE ARROTONDAMENTI FISCALI (solo se contanti) **********************
  if (context.importoPagato.contanti > 0) {
    // Verificare come gestire il caso di pagamento multiplo (contanti + carta) e sconto a pagare maggiore dei contanti
    // es. tot 77.12 , carta 30.04 , sconto a pagare 30.04 contanti 17.04

    const contantiPerArrotondamento = context.importoPagato.contanti - (context.resto || 0)

    if (contantiPerArrotondamento > 0) {
      const ics = String(Math.round(contantiPerArrotondamento * 100))
      const ic = ics.substring(ics.length - 1)
      let arrotondamento = 0
      if (ic === '1' || ic === '2' || ic === '6' || ic === '7') {
        // per difetto abbuono
        arrotondamento = -(parseFloat(ic) - ((ic === '1' || ic === '2') ? 0 : 5)) / 100
        addArrotonamento(context, {
          valore: arrotondamento,
          descrizione: 'Arrotondamento per DL. 50/2017',
          tipo: 'arrotondaDifetto'
        })
      }
      if (ic === '3' || ic === '4' || ic === '8' || ic === '9') {
        arrotondamento = (((ic === '3' || ic === '4') ? 5 : 10) - parseFloat(ic)) / 100
        addArrotonamento(context, {
          valore: arrotondamento,
          descrizione: 'Arrotondamento per DL. 50/2017',
          tipo: 'arrotondaEccesso'
        })
      }
      if (arrotondamento !== 0) {
        if (context.resto) {
          context.resto = +(context.resto - arrotondamento).toFixed(2)
        } else {
          context.importoPagato.contanti = +(context.importoPagato.contanti + arrotondamento).toFixed(2)
        }
        context.giaPagatoTotale = +(context.giaPagatoTotale + arrotondamento).toFixed(2)
        // context.importoPagato.contanti += arrotondamento
        // context.giaPagatoTotale += arrotondamento
        if (context.daPagareTotale > 0) {
          context.daPagareTotale = +(context.daPagareTotale + arrotondamento).toFixed(2)
          // context.daPagareTotale += arrotondamento
        }
        context.totaleScontrino = fnCalcolaTotaleScontrino(context)
        context.itemsScontrino = fnCalcolaNumeroItemScontrino(context)
      }
    }
  }
  // ************************************************************************************
}

const addArrotonamento = (context, { valore, descrizione, tipo }) => {
  var riga = {
    quantita: 0,
    descrizione: descrizione,
    prezzoUnitario: 0,
    prezzo: valore.toFixed(2),
    tipo: tipo,
    aliquota: '',
    valore: valore
  }
  context.righeCarrello.push(riga)
  context.rigaCarrello = riga
  context.arrotondamentoPresente = true
}

const fnCalcolaTotaleScontrino = (context) => {
  const righe = context.righeCarrello
  let st = 0
  for (var i = 0; i < righe.length; i++) {
    if (!['subtotale', 'separatore', 'lotteria', 'codicefiscale', 'cortesia', 'descrizione'].includes(righe[i].tipo)) {
      st += parseFloat(righe[i].prezzo)
      if (righe[i].sconto) {
        st += parseFloat(righe[i].sconto.prezzo || 0)
      }
      if (righe[i].abbuono) {
        st += parseFloat(righe[i].abbuono.prezzo || 0)
      }
    }
  }
  const abilitaSegnalazione = _.get(context.impostazioni, 'ssnn.abilitaSegnalazioneCfMancante', true)
  context.righeDetraibiliNoCodFis = abilitaSegnalazione && context.righeCarrello.some(x => x.descrizione.startsWith('*')) && !context.righeCarrello.some(x => x.tipo === 'codicefiscale')
  return st.toFixed(2)
}

const fnCalcolaNumeroItemScontrino = (context) => {
  const righe = context.righeCarrello
  let st = 0
  for (var i = 0; i < righe.length; i++) {
    // TODO: Verificare calcolo quantità
    if (!['subtotale', 'separatore', 'lotteria', 'codicefiscale', 'cortesia', 'abbuono', 'descrizione'].includes(righe[i].tipo) && parseInt(righe[i].quantita) > 0) {
      st += parseInt(righe[i].quantita)
    }
  }
  return String(st)
}

export default {
  calcolaArrotondamenti,
  presenzaRigheDaNonStampare,
  fnCalcolaTotaleScontrino,
  fnCalcolaNumeroItemScontrino
}

<template>
  <van-cell-group>
    <van-field
      :name="name"
      v-model="$v.testo.$model"
      :label="elemento.label"
      :placeholder="elemento.placeholder"
      clearable
      :autocomplete="false"
      @change="onChange"
    />
  </van-cell-group>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  props: {
    elemento: Object,
    name: String
  },
  data() {
    return {
      testo: ''
    }
  },
  validations: {
    testo: { required }
  },
  methods: {
    onChange() {
      this.$emit('change', this.elemento.model, this.testo)
    }
  }
}
</script>

<style lang="scss" >
  .van-field__label {
    min-height: inherit !important;
    line-height: inherit !important;
  }
</style>

<template>
  <van-cell-group>
    <van-cell-group title="Display">
      <van-cell title="Info pulsanti" is-link @click="infoTasti()" />
      <van-cell title="Configura posizione pulsanti" is-link @click="setupTasti()" />
    </van-cell-group>
    <van-cell-group title="Database">
      <database @send-event="sendEventToParent" ref="database"/>
    </van-cell-group>
    <van-cell-group title="Stampante"> <!-- TODO: Selezionare quale stampante (per array pagamenti) -->
      <van-cell title="Programma Aliquote Iva" is-link @click="programmaIva"/> <!-- TODO: Fare tab per visualizzazione e modifica -->
      <van-cell title="Programma Reparti" is-link @click="programmaReparti"/> <!-- TODO: Fare tab per visualizzazione e modifica -->
      <van-cell title="Programma Pagamenti" is-link @click="programmaPagamenti"/> <!-- TODO: Fare tab per visualizzazione e modifica -->
    </van-cell-group>
  </van-cell-group>
</template>

<script>
import configurazioni from '@/services/configurazioniService.js'
import Database from './database'

export default {
  components: {
    Database
  },
  data() {
    return {
      numeroStampante: 1
    }
  },
  methods: {
    infoTasti() {
      this.sendToMachine('INFO_TASTI', null)
    },
    setupTasti() {
      this.sendToMachine('SETUP_TASTI', null)
    },
    async programmaIva() {
      if (!await this.confermaOperazione('Programmazione Aliquote Iva')) return
      const aliquote = await configurazioni.getConfigurazione('prodotti_aliquote_iva', 'aliquote')
      if (aliquote) {
        const aliquoteStampante = aliquote.map(x => x.stampante)
        const settings = aliquoteStampante.map(x => {
          return {
            tipo: 'PROGRAMMA_IVA',
            v1: x.posizione,
            v2: x.valore,
            v3: x.tipoIva,
            desc: x.ateco || ''
          }
        })
        console.log(settings)
        this.sendToMachine('PRINTER', { commands: ['=C4'] })
        await this.sleep(1000)
        this.sendToMachine('PRINTER', { settings })
        await this.sleep(1000)
        this.sendToMachine('PRINTER', { commands: ['=C1'] })
      }
    },
    async programmaReparti() {
      if (!await this.confermaOperazione('Programmazione Reparti')) return
      const reparti = await configurazioni.getConfigurazione('prodotti_reparti_fiscali', 'reparti')
      if (reparti) {
        const repartiStampante = reparti.map(x => x.stampante)
        const settings = repartiStampante.map(x => {
          return {
            tipo: 'PROGRAMMA_REPARTO',
            nf: x.numero,
            v1: x.posizioneIva,
            v2: x.tipo,
            desc: x.descrizione || `REPARTO ${x.numero}`
          }
        })
        console.log(settings)
        this.sendToMachine('PRINTER', { commands: ['=C4'] })
        await this.sleep(1000)
        this.sendToMachine('PRINTER', { settings })
        await this.sleep(1000)
        this.sendToMachine('PRINTER', { commands: ['=C1'] })
      }
    },
    async programmaPagamenti() {
      if (!await this.confermaOperazione('Programmazione Pagamenti')) return
      const pagamenti = await configurazioni.getConfigurazione('stampanti', this.numeroStampante)
      if (pagamenti) {
        const pagamentiStampante = pagamenti.pagamenti
        const settings = pagamentiStampante.map(x => {
          return {
            tipo: 'PROGRAMMA_PAGAMENTO',
            nf: x.numero,
            v1: 'A',
            v2: x.abilitaResto,
            v3: x.sommaACassa,
            v4: x.nonRiscosso,
            v5: x.aperturaCassetto,
            v6: x.importoObbligatorio,
            desc: x.descrizione || `PAGAMENTO ${x.numero}`
          }
        })
        console.log(settings)
        this.sendToMachine('PRINTER', { commands: ['=C4'] })
        await this.sleep(1000)
        this.sendToMachine('PRINTER', { settings })
        await this.sleep(1000)
        this.sendToMachine('PRINTER', { commands: ['=C1'] })
      }
    },
    sendToMachine(actionType, payload) {
      const event = {
        type: actionType,
        value: payload
      }
      this.$emit('send-event', event)
    },
    confermaOperazione(operazione) {
      return new Promise((resolve) => {
        this.$dialog.confirm({
          message: `Confermi: ${operazione} ?`,
          cancelButtonText: 'NO',
          confirmButtonText: 'SI'
        }).then(() => resolve(true))
          .catch(() => resolve(false))
      })
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    }
  }
}
</script>

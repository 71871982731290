<template>
  <div class="modale-form-keyboard" ref="popupKeyboard">
    <div :class='keyboardClass'></div>
  </div>
</template>

<script>
// https://hodgef.com/simple-keyboard/documentation
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

export default {
  name: 'SimpleKeyboard',
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String
    },
    extInput: {
      type: String
    },
    enterText: {
      type: String,
      default: 'SCEGLI'
    }
  },
  data: () => ({
    keyboard: null
  }),
  beforeDestroy() {
    console.log('keyboard destroy')
    this.keyboard = null
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        default: [
          '\\ 1 2 3 4 5 6 7 8 9 0 \' ì {bksp}',
          'clear q w e r t y u i o p è +',
          '{lock} a s d f g h j k l ò à ù {next}',
          '{shift} < z x c v b n m , . - {shift}',
          '.com @ {space}'
        ],
        shift: [
          '| ! " £ $ % & / ( ) = ? ^ {bksp}',
          'clear Q W E R T Y U I O P é *',
          '{lock} A S D F G H J K L ç ° § {next}',
          '{shift} > Z X C V B N M ; : _ {shift}',
          '.com @ {space}'
        ],
        simple: [
          '1 2 3 4 5 6 7 8 9 0 \' {bksp}',
          'q w e r t y u i o p *',
          'a s d f g h j k l {next}',
          '{shift} z x c v b n m . , /',
          '.com @ {space}'
        ],
        simple_shift: [
          '1 2 3 4 5 6 7 8 9 0 \' {bksp}',
          'Q W E R T Y U I O P *',
          'A S D F G H J K L {next}',
          '{shift} Z X C V B N M . , /',
          '.com @ {space}'
        ]
      },
      mergeDisplay: true,
      display: {
        '{bksp}': 'CANCELLA',
        '{next}': this.enterText,
        '{space}': 'SPAZIO',
        '{simple_shift}': 'SHIFT'
      },
      layoutName: 'simple_shift',
      // layoutName: 'mobile',
      buttonTheme: [
        {
          class: 'tasto-scegli',
          buttons: '{next}'
        }
      ]
    })
    console.log('forceupdate')
    this.$forceUpdate()
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input)
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button)
      if (button === '{shift}' || button === '{lock}') this.handleShift()
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName
      const shiftToggle = currentLayout === 'simple' ? 'simple_shift' : 'simple'

      this.keyboard.setOptions({
        layoutName: shiftToggle
      })
    }
  },
  computed: {
    storeInput () {
      return this.$store.getters.popupKeyboardInput.key
    },
    changeVisibility() {
      return this.$store.getters.showPopupKeyboard
    }
  },
  watch: {
    storeInput(value) {
      this.keyboard.setInput(value)
    },
    extInput(input) {
      this.keyboard.setInput(input)
    },
    enterText(value) {
      this.keyboard.setOptions({ display: { '{next}': value } })
    },
    changeVisibility(value) {
      console.log('changeVisibility', value)
      if (value) {
        const height = this.$refs.popupKeyboard.offsetTop// vedi padding classe relativa
        this.$store.commit('SET_POPUP_KEYBOARD_HEIGHT', height)
      }
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
 .modale-form-keyboard >>> .hg-theme-default .hg-button {
    height: 5vh;
  }
</style>

<style lang="scss" scoped> /* Non deve essere scoped altrimenti non "esce" dal componente */

  $key_height: 10px;
  $pk_height: 300px; /* Non calcola su compilato. Impostare a mano in base alla mediaquery : calc( 5 * $key_height + 25px); */

  .modale-form-keyboard {
    & .hg-theme-default .hg-button {
      height: $key_height !important;
    }
  }

  .tasto-scegli {
    background-color: #339900 !important;
    color: #ffffff !important;
    font-weight: bold;
  }
</style>

'use strict'
import errorService from '@/services/errorService'

// const service = process.env.VUE_APP_service_configurazioni || ''
var config, service
(async () => {
  if (!service) {
    await getService()
  }
})()

async function getService() {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_configurazioni || process.env.VUE_APP_service_configurazioni
}

async function getToken() {
  // construire la audience in base ai pacchetti necessari
  if (!service) {
    await getService()
  }
  const audience = 'configurazioni,prodotti,base'
  try {
    const response = await fetch(`${service}/configurazioni/token?aud=${audience}`)
    return await response.json()
  } catch (error) {
    console.log('error getToken', error)
    errorService.onError(error, 'getToken')
    return ''
  }
}

async function verifyToken(token) {
  if (!token) return { stato: 'token.scaduto' }
  if (token === 'Licenza scaduta') return { stato: 'licenza.scaduta' }
  let licenza = {}
  try {
    const response = await fetch(`${service}/configurazioni/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
      // body: JSON.stringify({ token }) // verificare se necessario o è possibile prenderlo dal Bearer
    })
    const result = await response.json()
    licenza = result
  } catch (err) {
    // verificare risposta in base a token (es. scaduto)
    // console.log(err)
    licenza.stato = 'token.scaduto'
  }

  return licenza
}

async function sendModuleInfo(baseDb, statuses) {
  try {
    const data = {
      baseDb,
      statuses
    }
    const response = await fetch(`${service}/configurazioni/info/cliente`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return response.status !== 200 ? 'errore' : ''
  } catch (err) {
    return err
  }
}

export default {
  getToken,
  verifyToken,
  sendModuleInfo
}

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupInserimentoTesto main-popup-inserimento-testo"
      @open="onOpen"
      @click-overlay="closePopup"
      >
      <van-field
        v-model="testo"
        label="Testo"
        type="textarea"
        placeholder="Digita il testo da inserire"
        rows="2"
        autosize
      >
        <template #button>
          <van-button size="small" type="primary" @click="onInsertText">Inserisci</van-button>
        </template>
      </van-field>
      <van-field
        :value="barcode"
        label="Barcode"
        type="text"
        placeholder="Eventuale barcode prodotto non caricato in archivio"
      />
      <!-- Completare
      <van-row>
        <van-col span="8"><van-button type="default">Iva 4%</van-button></van-col>
        <van-col span="8"><van-button type="default">Iva 10%</van-button></van-col>
        <van-col span="8"><van-button type="default">Iva 22%</van-button></van-col>
      </van-row> -->
      <simple-popup-keyboard class="keyboard-popup-inserimento-testo" enterText="Inserisci" :keyboardClass="'kb_insert_text'" />
    </van-popup>
</template>

<script>
// import _ from 'lodash'
import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'

export default {
  components: {
    SimplePopupKeyboard
  },
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      testo: '',
      activeTab: 'ricerca',
      activeParent: 'prodotti',
      activeField: 'prodotto'
    }
  },
  async mounted() {
    this.onOpen()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    listResult() {
      return this.$store.getters.listResult
    },
    barcode() {
      return this.context.barcodePerGenerico
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    kbKeyPress(value) {
      if (this.show) {
        if (value === '{esci}') {
          // this.closePopup()
          this.onInsertText()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          // this.closePopup()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    barcode(code) {
      console.log('watch barcode', code, this.testo)
      this.testo = this.testo.split(code).join('')
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', this.testo)
    }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.tab-popup-inserimento-testo')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    handleKey(valore) {
      // console.log('handleKey', valore)
      this.testo = valore
      // this.debounceInputProdotti(valore)
    },
    onInsertText() {
      this.inserisciTesto(this.testo)
    },
    onClearProdotto() {
      // this.prodottoDescrizione = ''
    },
    onFocus(event) {
      console.log('onfocus')
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
    },
    async onSubmit(values) {
      this.clearFields()
    },
    onOpen() {
      this.clearSearchInput()
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
    },
    clearSearchInput() {
      this.testo = ''
    },
    clearFields() {
      this.clearSearchInput()
    },
    closePopup() {
      this.$emit('close')
    },
    inserisciTesto(testo) {
      const event = {
        type: 'INSERISCI_TESTO',
        value: {
          testo: testo
        }
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .swipe-cell-button {
    height: 100%;
  }
  .van-search .van-cell {
    padding: 10px 16px;
  }

  .van-search--show-action {
      padding-right: 12px;
  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupInserimentoTesto, .main-popup-inserimento-testo {
      width: 85%;
      height: 100%;
    }
  }
  @media all and (min-width:1024px) {
    .popupInserimentoTesto, .main-popup-inserimento-testo {
      width: 65%;
      height: 100%;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-inserimento-testo {
      width: 40%;
      height: 100%;
    }
  }

  .van-cell {
    line-height: inherit;
  }
</style>

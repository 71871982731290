/* eslint-disable */
import scontrini from '../services/scontriniService.js'
import stampante from '../services/stampanteService.js'

import { Machine, assign, actions, send, sendParent } from 'xstate'
import fidelityService from '../services/fidelityService.js';
import appuntamentiService from '../services/appuntamentiService.js';
import configurazioni from '../services/configurazioniService.js'

/* import { error } from 'xstate/lib/actionTypes';
const { respond } = actions; */
/*
  usare guard: stampaImmediata --> Nel momento in cui una riga è aggiunta al carrello viene subito stampata (--> correzione con annullamento riga)
  Gestire il carrello su scontriniMachine ?

  Se righeScontrino è vuoto, alla prima riga deve creare un nuovo scontrino sul db e memorizzare l'id per gli accessi successivi
*/
function getFiscaleScontrino(pr) {
  // Probabilemente su barcode scontrino andrà inserito un numero progressivo generale che identifica lo scontrino
  // anche se sono presenti più stampanti fiscali --> da db leggo chiave di ricerca e trovo scontrino
  // nnnnnZZZZxxxx : n = progressivo stampante, Z = ultimo azzeramento, x = progressivo scontrino

  if (!pr || pr.error) return null

  const infoScontrino = {
    ricerca: pr.barcode,
    azzeramento: pr.azzeramento,
    numeroScontrino: pr.numeroScontrino,
    matricola: pr.matricolaStampante,
    data: pr.dataScontrino
  }
  console.log(infoScontrino)
  return infoScontrino
}

const not = fn => (...args) => !fn.apply(null, args)

const stampaImmediata = (context, event) => false
const notStampaImmediata = not(stampaImmediata)
const scontrinoCreato = (context) => context.idScontrino != ''
const notScontrinoCreato = not(scontrinoCreato)

export const scontriniMachine = Machine({
  id: 'scontriniMachine',
  context: {
    idScontrino: '',
    rigaScontrino: null,
    righeScontrino: [],
    error: null,
    infoChiusuraScontrino: null,
    rigaSelezionata: null,
    comandi: [],
    settings: [],
    printerResponse: {}, // ### null
    barcodeScontrino: '',
    fiscaleScontrino: null,
    isRiuso: false,
    idEliminare: '',
    idToDelete: '',
    infoRistampaBuono: null,
    scontrinoErroreComando: null,
    cashmaticLastTransaction: null
  },
  on: {

  },
  initial: 'idle',
  states: {
    idle: {
      on: {
        ADD_RIGA: {
          target: "testNewReceipt"
        },
        UPDATE_RIGA: {
          target: "rowUpdating"
        },
        DELETE_RIGA: {
          target: "rowDeleting"
        },
        ADD_TOTALE: {
          target: "addTotale"
        },        
        ADD_NOTA: {},
        STAMPA_SCONTRINO: {
          target: "printReceipt"
        },
        SCONTRINO_REGALO: {
          target: 'printGiftReceipt'
        },
        SCONTRINO_REGALO_SU_RECUPERO: {
          target: 'printGiftReceipt'
        },
        SCONTRINO_SOLO_RESO: {
          target: 'printReturnOnlyReceipt'
        },
        SCONTRINO_GESTIONALE: {
          target: 'printGestionale'
        },        
        RISTAMPA_BUONO: {
          target: 'ristampaBuono'
        },
        ANNULLA_SCONTRINO: [
          {
            cond: (context) => context.idScontrino,
            target: "deleteReceipt",
            actions: assign({ idToDelete : (context) => context.idScontrino })
          },
          {
            cond: (context) => !context.idScontrino,
            target: "done"
          }          
        ],
        ELIMINA_SCONTRINO: {
          target: "deleteReceipt",
          actions: assign({ idToDelete : (context) => context.idEliminare })
        },
        "GET_CARRELLO": {},
        "GET_TOTALE": {},
        "CLEAR_CARRELLO": {},
        SEND_COMMANDS: {
          target: "sendCommands"
        },
        ANNULLAMENTO_FISCALE: {
          target: 'annullamentoFiscale',
          actions: assign({ isRiuso: (context) => false })
        },
        ANNULLAMENTO_FISCALE_RIUSO: {
          target: 'annullamentoFiscale',
          actions: [
            (context) => console.log('Scontrini: ANNULLAMENTO_FISCALE_RIUSO'),
            assign({ isRiuso: (context) => true })
          ]
        }
/*         ELIMINAZIONE_SCONTRINO: {

        } */
      }
    },
    testNewReceipt: { // Non aspetto il numero dello scontrino fiscale per _id dello scontrino su db perchè potrei avere scontrini non fiscali
      always: [
        {
          cond: scontrinoCreato,
          target: 'rowAdding'
        },
        {
          cond: notScontrinoCreato,
          target: 'newReceipt'
        },
      ]
    },
    newReceipt: {
      invoke: {
        id: 'createReceipt',
        // src: (context, event) => scontrini.nuovoScontrino(),
        src: async (context) => {
          console.log('crea scontrino da createReceipt')
          return await scontrini.nuovoScontrino(configurazioni.getPostazione())
        },
        onDone: {
          target: 'rowAdding',
          actions: assign({ idScontrino: (context, event) => event.data._id })          
        },
        onError: {
          target: 'newReceiptFailure',
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    newReceiptWithRows: {
      invoke: {
        id: 'newReceiptWithRows',
        // src: (context, event) => scontrini.nuovoScontrinoConRighe(context.righeScontrino),
        src: async (context) => {
          console.log('crea scontrino da newReceiptWithRows')
          return await scontrini.nuovoScontrinoConRighe(context.righeScontrino, configurazioni.getPostazione())
        },
        onDone: {
          target: 'done',
          actions: [
            assign({ idScontrino: (context, event) => event.data._id })
          ]         
        },
        onError: {
          target: 'newReceiptFailure',
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },    
    rowAdding: { // gestire rowAdding e rowPrinting come due stati paralleli : https://xstate.js.org/docs/guides/statenodes.html#state-node-types
      invoke: {
        id: 'addRigaDb',
        // src: (context, event) => scontrini.addRigaScontrino(context.idScontrino, context.rigaScontrino),
        src: async (context) => {
          return await scontrini.addRigaScontrino(context.idScontrino, context.rigaScontrino)
        },
        onDone: [
          {
            cond: "stampaImmediata",
            target: 'rowPrinting'
          },
          {
            cond: "notStampaImmediata",
            target: 'done'
          }
        ],
        onError: {
          target: 'rowAddingFailure',
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    rowUpdating: {
      invoke: {
        id: 'updateRigaDb',
        // src: (context, event) => scontrini.updateRigaScontrino(context.idScontrino, context.rigaSelezionata, context.rigaScontrino),
        src: async (context) => {
          return await scontrini.updateRigaScontrino(context.idScontrino, context.rigaSelezionata, context.rigaScontrino)
        },
        onDone: {
          target: 'done'
        },
        onError: {
          target: 'rowUpdatingFailure',
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    rowDeleting: {
      invoke: {
        id: 'deleteRigaDb',
        // src: (context, event) => scontrini.deleteRigaScontrino(context.idScontrino, context.rigaSelezionata),
        src: async (context) => {
          return await scontrini.deleteRigaScontrino(context.idScontrino, context.rigaSelezionata)
        },
        onDone: {
          target: 'done'
        },
        onError: {
          target: 'rowDeletingFailure',
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    addTotale: {
      invoke: {
        id: 'addTotaleDb',
        // src: (context, event) => scontrini.addTotaleScontrino(context.idScontrino, context.righeScontrino, context.infoChiusuraScontrino),
        src: async (context) => {
          return await scontrini.addTotaleScontrino(context.idScontrino, context.righeScontrino, context.infoChiusuraScontrino)
        },
        onDone: [
          {
            cond: "stampaImmediata",
            target: 'rowPrinting' // verificare
          },
          {
            cond: "notStampaImmediata",
            target: 'done'
          }
        ],
        onError: {
          target: 'rowAddingFailure',
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    rowPrinting: {
      invoke: {
        id: 'printRiga',
        // src: (context, event) => stampante.printRigaScontrino(context.rigaScontrino),
        src: async (context) => {
          return await stampante.printRigaScontrino(context.rigaScontrino)
        },
        onDone: {
          target: 'done'
          // actions: sendParent('ADDED')
        },
        onError: {
          target: 'rowPrintingFailure',
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    done: {
      type: 'final',
      data: {
        idScontrino: (context, event) => context.idScontrino,
        barcode: (context, event) => context.barcodeScontrino,
        scontrino: (context, event) => context.scontrino
      }
      // actions: sendParent((context, event) => ('RIGA_ADDED', { idScontrino: context.idScontrino }))
    },
    commandsDone: {
      type: 'final',
      data: {
        printerResponse: (context) => context.printerResponse
      }
    },    
    printReceipt: {
      invoke: {
        id: 'printReceipt',
        // src: (context, event) => stampante.stampaScontrino(context.idScontrino, context.righeScontrino, context.infoChiusuraScontrino),
        src: async (context) => {
          return await stampante.stampaScontrino(context.idScontrino, context.righeScontrino, context.infoChiusuraScontrino, context.scontrinoErroreComando)
        },
        onDone: [
          {
            // cond: (context, event) => true , // event.data.hasOwnProperty('stato') && !event.data.error,
            target: 'aggiornaFiscaleScontrino',
            actions: (context, event) => {
              const response = event.data
              if (response.reso) {
                context.printerResi = response.reso
                delete response.reso
              }
              context.barcodeScontrino = response.barcode
              context.printerResponse =  response
              if (event.data.error) {
                context.infoChiusuraScontrino.stato="non stampato"
              }
            }
          },
/*           {
            cond: (context, event) => !event.data.hasOwnProperty('stato') || event.data.error,
            actions: [
              assign({ printerResponse: (context, event) => event.data }),
              // il messaggio corretto dovrebbe tornare dalla stampante anche per timeout... verificare
              sendParent((context, event) => ({ data: (context.printerResponse ? context.printerResponse: { error: { code: 'TIMEOUT', message: 'Stampante spenta o non collegata' } }) , type: 'PRINTER_ERROR' }))
            ]
          } */
        ],
        onError: {
          // target: 'printReceiptFailure',
          target: 'aggiornaFiscaleScontrino',
          actions: [
            assign({ error: (context, event) => event.data }),
            (context, event) => {
              _.set (context, 'printerResponse.error', 'errore')
              context.printerResponse = context.printerResponse || {}
              context.printerResponse.error = 'errore'
              context.infoChiusuraScontrino.stato="non stampato"
              // context.infoChiusuraScontrino.statoPagamento="pagato" // TODO: Verificare come impostare. Normalemnte lo fa il salvataggio dei dati fiscali
            }
/*             sendParent((context, event) => { return {
              data: {
                idScontrino: context.idScontrino,
                scontrino: context.scontrino,
                error: { code: 'TIMEOUT', message: 'Stampante spenta o non collegata' }} , type: 'PRINTER_ERROR' }}) */
          ]
        }
      }
    },        
    printGiftReceipt: {
      invoke: {
        id: 'printGiftReceipt',
        // src: (context, event) => stampante.stampaScontrinoCortesia(context.barcodeScontrino),
        src: async (context) => {
          return await stampante.stampaScontrinoCortesia(context.barcodeScontrino)
        },
        onDone: [
          {
            cond: (context, event) => event.data.hasOwnProperty('stato') && !event.data.error,
            target: 'done',
            actions: [ 
              assign({ printerResponse: (context, event) => event.data })
            ]
          },
          {
            cond: (context, event) => !event.data.hasOwnProperty('stato') || event.data.error,
            actions: [
              assign({ printerResponse: (context, event) => event.data }),
              // il messaggio corretto dovrebbe tornare dalla stampante anche per timeout... verificare
              sendParent((context, event) => { return ({ data: (context.printerResponse ? context.printerResponse: { error: { code: 'TIMEOUT', message: 'Stampante spenta o non collegata' } }) , type: 'PRINTER_ERROR' })})
            ]
          }          
        ],
        onError: {
          target: 'done', // gestire
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    printReturnOnlyReceipt: {
      invoke: {
        id: 'printReturnOnlyReceipt',
        src: async (context) => {
          return await stampante.stampaScontrinoSoloReso(context.righeScontrino, context.infoChiusuraScontrino, context.scontrinoErroreComando)
        },
        onDone: [
          {
            cond: (context, event) => event.data.hasOwnProperty('stato') && !event.data.error,
            // target: 'done',
            target: 'aggiornaResoSuOrigine',
            actions: [
              assign({ printerResponse: (context, event) => event.data }),
                (context, event) => {
                const response = event.data
                if (response.reso) {
                  context.printerResi = response.reso
                  delete response.reso
                }
              }
            ]
          },
          {
            cond: (context, event) => !event.data.hasOwnProperty('stato') || event.data.error,
            actions: [
              assign({ printerResponse: (context, event) => event.data }),
              sendParent((context, event) => { return ({ data: (context.printerResponse ? context.printerResponse: { error: { code: 'TIMEOUT', message: 'Stampante spenta o non collegata' } }) , type: 'PRINTER_ERROR' })})
            ]
          }          
        ],
        onError: {
          target: 'done', // gestire
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    printGestionale: {
      invoke: {
        id: 'printGestionale',
        src: async (context) => {
          return await stampante.stampaScontrinoGestionale(context.infoScontrinoGestionale)
        },
        onDone: [
          {
            cond: (context, event) => event.data.hasOwnProperty('stato') && !event.data.error,
            target: 'done',
            actions: [ 
              assign({ printerResponse: (context, event) => event.data })
            ]
          },
          {
            cond: (context, event) => !event.data.hasOwnProperty('stato') || event.data.error,
            actions: [
              assign({ printerResponse: (context, event) => event.data }),
              // il messaggio corretto dovrebbe tornare dalla stampante anche per timeout... verificare
              sendParent((context, event) => { return ({ data: (context.printerResponse ? context.printerResponse: { error: { code: 'TIMEOUT', message: 'Stampante spenta o non collegata' } }) , type: 'PRINTER_ERROR' })})
            ]
          }          
        ],
        onError: {
          target: 'done', // gestire
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    aggiornaFiscaleScontrino: {
      // deve salvare il numero dello scontrino e la matricola della stampante per un eventuale reso.
      // attenzione al formato del barcode prodotto dalla stampante
      invoke: {
        id: 'aggiornaFiscaleScontrino',
        // src: (context, event) => scontrini.aggiornaFiscaleScontrino(context.idScontrino, getFiscaleScontrino(context.printerResponse), context.infoChiusuraScontrino),
        src: async (context) => {
          if (context.cashmaticLastTransaction) {
            context.infoChiusuraScontrino.cashmatic = context.cashmaticLastTransaction
          } else {
            delete context.infoChiusuraScontrino.cashmatic
          }
          return await scontrini.aggiornaFiscaleScontrino(context.idScontrino, getFiscaleScontrino(context.printerResponse), context.infoChiusuraScontrino)
        },
        onDone: [
          {
            cond: (context) => !context.printerResi,
            // target: 'done'
            target: 'verificaPostOperazioni'
          },
          {
            cond: (context) => context.printerResi,
            target: 'aggiornaResoSuOrigine'
          }          
        ],
        onError: {
          target: 'done',
          actions: assign({ error: (context, event) => event.data }) // gestire
        }
      }
    },
    aggiornaResoSuOrigine: {
      invoke: {
        id: 'aggiornaResoSuOrigine',
        src: async (context) => {
          return await scontrini.resiSuScontrino(context.printerResi)
        },
        onDone: {
          // target: 'done'
          target: 'verificaPostOperazioni'
        },       
        onError: {
          target: 'done',
          actions: assign({ error: (context, event) => event.data }) // gestire
        }
      }
    },
    annullamentoFiscale: {
      invoke: {
        id: 'annullamentoFiscale',
        src: async (context) => {
          return await stampante.annullaDocumentoFiscale(context.fiscaleScontrino)
        },
        onDone: [
/*           {
            cond: (context, event) => { console.log(event); debugger }
          }, */
          {
            cond: (context, event) => event.data.hasOwnProperty('stato') && !event.data.error,
            target: 'aggiornaAnnullamentoSuOrigine',
            actions: [
              (context) => console.log('Scontrini: annullamentoFiscale'),
              assign({ 
                printerResponse: (context, event) => event.data
              })
            ]
          },
          {
            cond: (context, event) => !event.data.hasOwnProperty('stato') || event.data.error,
            actions: [
              assign({ printerResponse: (context, event) => event.data }),
              // il messaggio corretto dovrebbe tornare dalla stampante anche per timeout... verificare
              sendParent((context, event) => ({ data: (context.printerResponse ? context.printerResponse: { error: { code: 'TIMEOUT', message: 'Stampante spenta o non collegata' } }) , type: 'PRINTER_ERROR' }))
            ]
          }           
        ],
        onError: {
          target: 'done',
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    aggiornaAnnullamentoSuOrigine: {
      invoke: {
        id: 'aggiornaAnnullamentoSuOrigine',
        // src: (context, event) => scontrini.aggiornaAnnullamentoFiscaleSuOrigine(context.idScontrino, getFiscaleScontrino(context.printerResponse)),
        src: async (context) => {
          const resp = await scontrini.aggiornaAnnullamentoFiscaleSuOrigine(context.idScontrino, getFiscaleScontrino(context.printerResponse))
          // TODO: Eventuali operazioni dopo l'annullamento
          if (resp.fidelity) {
            const cardId = resp.fidelity.card
            const punti = resp.fidelity.puntiScontrino
            await fidelityService.annullamentoScontrino(resp._id, cardId, punti)
          }
          return resp
        },
        onDone: [
          {
            // cond: (context) => !context.isRiuso,
            target: 'done',
            actions: (context) => console.log('Scontrini: aggiornaAnnullamentoSuOrigine'),
          }
/*           {
            cond: (context) => context.isRiuso,
            target: 'newReceiptWithRows',
            actions: (context) => console.log('Scontrini: aggiornaAnnullamentoSuOrigine: con riuso'),
          }    */       
        ],       
        onError: {
          target: 'done',
          actions: assign({ error: (context, event) => event.data }) // gestire
        }
      }
    },
    ristampaBuono: {
      invoke: {
        id: 'ristampaBuono',
        // src: (context, event) => stampante.stampaScontrinoCortesia(context.barcodeScontrino),
        src: async (context) => {
          return await stampante.ristampaBuono(context.infoRistampaBuono)
        },
        onDone: [
          {
            cond: (context, event) => event.data.hasOwnProperty('stato') && !event.data.error,
            target: 'done',
            actions: [ 
              assign({ printerResponse: (context, event) => event.data })
            ]
          },
          {
            cond: (context, event) => !event.data.hasOwnProperty('stato') || event.data.error,
            actions: [
              assign({ printerResponse: (context, event) => event.data }),
              // il messaggio corretto dovrebbe tornare dalla stampante anche per timeout... verificare
              sendParent((context, event) => { return ({ data: (context.printerResponse ? context.printerResponse: { error: { code: 'TIMEOUT', message: 'Stampante spenta o non collegata' } }) , type: 'PRINTER_ERROR' })})
            ]
          }          
        ],
        onError: {
          target: 'done', // gestire
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    verificaPostOperazioni: {
      // 1) Verifica se sono stati acquistati dei buoni o prepagate, nel caso salva i riferimenti relativi
      // 2) Verifica se è stato pagato un appuntamento
      invoke: {
        src: async (context, event) => {
          const righeBuoni = context.righeScontrino.filter(x => x.tipoBene === 'BUONOMULTIUSO' || x.tipoBene === 'BUONOMONOUSO' || x.tipoBene === 'PREPAGATA')
          if (righeBuoni.length > 0) {
            const infoAcquisto = {
              idScontrino: context.idScontrino,
              barcode: context.barcodeScontrino
            }
            for (const riga of righeBuoni) {
              // salva nel buono le info dello scontrino di acquisto
              await fidelityService.aggiornaInfoAcquistoCard(riga.infoBuono.buono, infoAcquisto, riga.infoBuono.importo, riga.infoBuono.scadenza)
            }
          }
          if (context.appuntamento && context.appuntamento.pagamentoInCorso) {
            console.log('Pagamento per Appuntamento: ', context.appuntamento._id)
            const infoAcquisto = {
              idScontrino: context.idScontrino,
              barcode: context.barcodeScontrino
            }
            await appuntamentiService.aggiornaInfoAcquisto(context.appuntamento._id, infoAcquisto)
          }
          context.scontrino = event.data

          console.log("invoke statement - final state child1");
        },
        // onDone: 'done' // TODO: se stampa ok --> done, se errore stampante --> ritorna errore
        onDone: [
          {
            cond: (context) => !context.printerResponse.error,
            target: 'done'
          },
          {
            cond: (context) => context.printerResponse.error,
            actions: sendParent((context, event) => ({ 
              data: ({
                error: {
                  code: context.printerResponse && context.printerResponse.error ? context.printerResponse.error.code : 'TIMEOUT',
                  message: context.printerResponse && context.printerResponse.error ? context.printerResponse.error.message : 'Stampante spenta o non collegata'
                },
                idScontrino: context.idScontrino,
                barcode: context.barcodeScontrino,
                scontrino: context.scontrino
              }), type: 'PRINTER_ERROR' }))
          }
        ]
      }
    },
    deleteReceipt: {
      invoke: {
        id: 'deleteReceipt',
        // src: (context, event) => scontrini.cancellaScontrino(context.idToDelete),
        src: async (context) => {
          // Se nello scontrino era stato acquistato un buono, lo deve cancellare
          const righe = context.righeScontrino
          const buoni = righe.filter(x => x.tipoBene === 'BUONOMULTIUSO')
          if (buoni.length > 0) {
            for (let riga of buoni) {
              await fidelityService.cancellaBuono(riga.infoBuono.buono)
            }
          }
          
          return await scontrini.cancellaScontrino(context.idToDelete)
        },
        onDone: {
          target: 'done',        
        },
        onError: {
          target: 'done'
        }
      }
    },
    sendCommands: {
      invoke: {
        id: 'sendCommands',
        // src: (context, event) => stampante.inviaComandi(context.comandi, context.settings),
        src: async (context) => {
          if (context.comandi[0].startsWith('=')) {
            // TODO: Inserito per compatibilità --> togliere
            return await stampante.inviaComandi(context.comandi, context.settings)
          } else {
            return await stampante.inviaComandiByName(context.comandi, context.settings)
          }
        },
        onDone: [
          {
            // cond: (context, event) => event.data.hasOwnProperty('Request') && !event.data.error,
            cond: (context, event) => event.data.status === 'ok',
            target: "commandsDone",
            actions: assign({ printerResponse: (context, event) => event.data }) // TODO Verificare
          },
          {
            // cond: (context, event) => !event.data.hasOwnProperty('Request') || event.data.error,
            cond: (context, event) => event.data.status !== 'ok' || event.data.error,
            actions: [
              assign({ printerResponse: (context, event) => event.data }),
              // il messaggio corretto dovrebbe tornare dalla stampante anche per timeout... verificare
              sendParent((context, event) => ({ data: (context.printerResponse ? context.printerResponse: { error: { code: 'TIMEOUT', message: 'Stampante spenta o non collegata' } }) , type: 'PRINTER_ERROR' }))
            ]
          }
        ],
        onError: {
          target: 'sendCommandsFailure',
          actions: [
            assign({ error: (context, event) => event.data })
          ]
        }
/*         on: {
          PRINTER_ERROR: {
            actions: [
              (context, event) => { debugger },
              respond('PRINTER_ERROR', (context, event) => { debugger; return event.data })
            ]
          }
        } */
      } 
    },
/*     waitCommandResponse: {
      on: {
        TEST_RESPONSE: [
          { 
            cond: (context, event) => event.data.error,
            actions: [
              (context, event) => { debugger },
              sendParent((context, event) => ({ data: event.data, type: 'PRINTER_ERROR' }))
            ]
          },
          { 
            cond: (context, event) => !event.data.error,
            target: "commandsDone"
          }              
        ]
      }
    }, */     
/*     printerError: {

    }, */
    rowAddingFailure: {
      on: {
        RETRY: 'rowAdding'
      }
    },
    rowUpdatingFailure: {
      on: {
        RETRY: 'rowUpdating'
      }
    },
    rowDeletingFailure: {
      on: {
        RETRY: 'rowDeleting'
      }
    },
    rowPrintingFailure: {
      on: {
        RETRY: 'rowPrinting'
      }
    },    
    printReceiptFailure: {
      on: {
        RETRY: 'printReceipt'
      }
    },
    sendCommandsFailure: {
      on: {
        RETRY: 'sendCommands'
      }
    },
    newReceiptFailure: {
      on: {
        RETRY: 'newReceipt'
      }
    }
  }
},
{
  guards: {
    stampaImmediata,
    notStampaImmediata,
    scontrinoCreato,
    notScontrinoCreato
  },
  actions: {

  }
})

/* export default {
  scontriniMachine
} */
<template>
  <div class="gruppoTasti park">
  <div class="tasto" :style="btnPosition(ts)" v-for="(ts, index) in tasti" :key="index">
    <button
      style="width:100%; height: 100%;" :style="getBckColor(ts, trigger)"
      @click="onClick(ts)"
      v-longclick="onLongClick"
      :class="ts.aspect"
      :data-index="index"
    >
      {{ts.descrizione || ts.title}}
      <div class="small-text black">{{ts.dataDescrizione | date}}</div>
    </button>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    context: Object,
    tasti: Array,
    trigger: Number
  },
  data() {
    return {
      forzaApertura: false,
      timeoutId: null
    }
  },
  computed: {
    isContoAperto() {
      return this.context.idScontrino !== ''
    },
    contoAperto() {
      return this.context.idScontrino
    }
  },
  methods: {
    btnPosition(el) {
      return `grid-area: ${el.row} / ${el.col} / span ${el.row_span} / span ${el.col_span}; overflow: hidden;`
    },
    getBckColor(el, trigger) {
      return trigger && `background-color:${this.context.impostazioni.parcheggio.colori[el.payload.stato]}`
    },
    async onClick(el) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(async () => {
          await this.clickAction(el)
          this.timeoutId = null
        }, 200)
      } else {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
        await this.dblClickAction(el)
      }
    },
    async dblClickAction(el) {
      const stato = el.payload.stato || 'libero'
      const conto = el.payload.conto

      if (stato === 'aperto') {
        this.forzaApertura = true
        await this.clickAction(el)
      } else if (stato === 'occupato') {
        if (await this.confermaOperazione('Annullamento', 'Confermi annullamento posizione?')) {
          const event = {
            type: 'PARCHEGGIO',
            payload: {
              command: 'annulla',
              conto
            }
          }
          this.sendEventToParent(event)
          this.$toast('La posizione di parcheggio è stata eliminata !')
          setTimeout(() => {
            this.$emit('refresh')
            el.payload.stato = 'libero'
            this.context.infoParcheggio = null
          }, 1000)
        }
      }
    },
    onLongClick(el) {
      if (el && el.target && el.target.attributes['data-index']) {
        const index = parseInt(el.target.attributes['data-index'].value)
        const ts = this.tasti[index]
        const event = {
          type: 'RENAME_PARK',
          payload: {
            command: 'rinomina',
            zona: ts.codice.split('.')[0],
            posizione: ts.codice
          }
        }
        this.sendEventToParent(event)
      }
    },
    async clickAction(el) {
      const stato = el.payload.stato || 'libero'
      const conto = el.payload.conto
      const descrizone = el.descrizione || el.title || ''
      const isContoAperto = this.isContoAperto
      let event = null
      switch (stato) {
        case 'libero':
          if (isContoAperto) {
            if (this.context.infoParcheggio && this.context.infoParcheggio !== el.codice) {
              if (await this.confermaOperazione('Spostamento conto', 'Confermi spostamento su nuova posizione?')) {
                event = {
                  type: 'PARCHEGGIO',
                  payload: {
                    command: 'sposta',
                    conto,
                    zona: el.codice.split('.')[0],
                    posizione: el.codice
                  }
                }
              }
            } else {
              event = {
                type: 'PARCHEGGIO',
                payload: {
                  command: 'parcheggia',
                  zona: el.codice.split('.')[0],
                  posizione: el.codice
                }
              }
            }
          } else {
            this.$toast('Nessun conto da parcheggiare !')
          }
          break
        case 'occupato':
          if (isContoAperto) {
            if (await this.confermaOperazione('Aggiunta conto', 'Confermi aggiunta a conto parcheggiato?')) {
              event = {
                type: 'PARCHEGGIO',
                payload: {
                  command: 'accoda',
                  conto
                }
              }
            }
          } else {
            this.$emit('apri-dettaglio', conto, descrizone)
          }
          break
        case 'aperto':
          if (isContoAperto) {
            if (conto === this.contoAperto) {
              event = {
                type: 'PARCHEGGIO',
                payload: {
                  command: 'parcheggia',
                  zona: el.codice.split('.')[0],
                  posizione: el.codice
                }
              }
            } else {
              this.$toast('Operazione non consentita !')
            }
          } else {
            if (this.forzaApertura) {
              this.forzaApertura = false
              event = {
                type: 'PARCHEGGIO',
                payload: {
                  command: 'apre',
                  conto
                }
              }
            } else {
              this.$toast('Operazione non consentita !')
            }
          }
          break
        default:
          break
      }
      if (event) {
        this.sendEventToParent(event)
      }
    },
    async confermaOperazione(title, message) {
      try {
        await this.$dialog.confirm({ title, message, cancelButtonText: 'NO', confirmButtonText: 'SI' })
        return true
      } catch {
        return false
      }
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    }
  }
}
</script>

<style scoped lang="scss">
  .small-text {
    font-size: x-small;
    &.black {
      color: black;
    }
  }
</style>

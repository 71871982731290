<template>
  <div class="" :class="accordionOpen ? 'accordionOn' : ''">

    <div v-if="item.titolo" class="accordionListElement" @click="toggleAccordion">
      <span class="labelForm">{{ item.titolo }}</span>
      <span v-if="item.abilitaAccordion" class="accordionIcon">
        <svg v-if="!accordionOpen" width="60" height="60" fill="none"  stroke-width="1" viewBox="0 0 24 24" stroke="currentColor" class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none" ><path d="M16.924 9.617A1 1 0 0 0 16 9H8a1 1 0 0 0-.707 1.707l4 4a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0 .217-1.09z" style="fill:#ffffff" /></svg>
        <svg v-else width="60" height="60" fill="none"  stroke-width="1" viewBox="0 0 24 24" stroke="currentColor" class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none" ><path d="m16.707 13.293-4-4a1 1 0 0 0-1.414 0l-4 4A1 1 0 0 0 8 15h8a1 1 0 0 0 .707-1.707z" style="fill:#ffffff" /></svg>
      </span>
    </div>

    <div v-if="accordionOpen" class="cntList">
      <div class="infoListElement" v-for="(el, index) in item.dataSource" :key="index" :index="index">
        <div v-if="item.html" v-html="item.html"></div>
        <div v-else class="infoListTextCnt">
          <span v-if="el.label" class="labelForm">{{ el.label }} : </span>
          <span v-if="el.descrizione" class="descrizioneList">{{ el.descrizione }}</span>
        </div>
        <div>
          <van-button v-for="(b, i) in item.bottoni" :key="i" @click="onClickBtn(b, el)"
              type="default" class="btnInfoList">{{ b.testo }}</van-button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    modelItem: Object
  },
  data() {
    return {
      isValid: true,
      msgErrore: '',
      value: '',
      msgValidazioni: [],
      accordionOpen: false
    }
  },
  mounted() {
    if (this.item.valore) {
      this.value = this.item.valore
    }
    if (this.item.expanded) {
      this.open()
    }
  },
  methods: {
    onClickBtn(btn, item) {
      var method = btn.onClick
      method && method(item)
    },
    toggleAccordion() {
      this[this.accordionOpen ? 'close' : 'open']()
    },
    open() {
      if (!this.accordionOpen) {
        this.accordionOpen = true
        // animazione apertura
      }
    },
    close() {
      if (this.accordionOpen) {
        this.accordionOpen = false
        // animazione chiusura
      }
    }
  }
}
</script>

<style lang="scss">
.infoListElement{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  margin: 2px;
  padding: 3px;
  border: solid;
  border-width: 1px 0px 0px 0px;
  .infoListTextCnt {
    display: flex;
    align-items: center;
  }
  .labelForm {
    // width: 25%;
    margin-right: 5px;
  }
  .descrizioneList {
    font-size: 2vh;
    padding: 6px;
    max-width: 75%;
  }
}

.infoListElement .btnInfoList {
  font-size: 2vh;
  height: 5vh;
  margin-left: 5px;
  background: white;
  border: solid 2px #cccccc;
  border-radius: 7px;
}

.accordionListElement {
  border: solid;
  border-width: 2px;
  height: 6vh;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.accordionOn .accordionListElement {
  border-radius: 10px 10px 0 0;
  border-width: 2px 2px 0 2px;
}

.accordionOn .cntList {
  border: solid 2px;
  border-radius: 0px 0px 10px 10px;
}

.accordionIcon {
    margin-left: 2vh;
}

</style>

<template>
  <div class="inputText" :class="!isValid ? 'notValid' : ''">
    <div class="labelForm">{{item.label || 'Testo Label'}}</div>
    <div>
      <input  class="modalFormInput" :placeholder="item.placeHolder || 'Inserire testo..'"  v-model="value" @keydown="onKeyboardDown"
        :v-upper="item.forceUpper ? true : false" @change="onChange" ref="input" @focus="onFocus" @blur="onBlur" :readonly="item.readonly" :disabled="item.readonly" />
    </div>
    <span v-if="!isValid" class="labelErrore">{{ msgErrore || 'Messaggio di errore validazione o suggerimento' }}</span>
    <div v-if="item.hint" class="labelHint">
      {{ item.hint }}
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required /* , requiredIf */ } from 'vuelidate/lib/validators'

const validations = {
}
// DEBUG: 02395190545
export default {
  mixins: [validationMixin],
  props: {
    item: Object
  },
  data() {
    return {
      isValid: true,
      msgErrore: '',
      value: '',
      msgValidazioni: []
    }
  },
  validations() {
    var val = { value: {} }
    if (this.item.validations) {
      for (var i = 0; i < this.item.validations.length; i++) {
        var defaultVal = validations[this.item.validations[i].nome]
        if (defaultVal) {
          for (var n in defaultVal) {
            val.value[n] = defaultVal[n]
            if (this.item.validations[i].msgErrore) {
              this.msgValidazioni[n] = this.item.validations[i].msgErrore
            }
          }
        } else {
          if (this.item.validations[i].nome === 'required') {
            val.value.required = required
            this.msgValidazioni.required = 'Il campo è obbligatorio'
          }
        }
      }
    }
    return val
  },
  watch: {
    value(value) {
      this.$forceUpdate()
      this.$emit('update-value', this.getDati())
    }
  },
  created() {
  },
  mounted() {
    if (this.item.valore) {
      this.value = String(this.item.valore)
    }
  },
  methods: {
    onFocus(event) {
      this.$emit('on-focus')
    },
    onBlur(event) {
      // this.$emit('on-blur')
    },
    onChange(event) {
      this.$v.value.$touch()
      this.checkValidations()
    },
    onKeyboardDown(e) {
      if (e.key.length > 1) {
        return
      }
      console.log(e)
      e.preventDefault()
      var charValidato = this.validaChar(e.key)
      if (charValidato !== null) {
        var startPos = this.$refs.input.selectionStart
        var endPos = this.$refs.input.selectionEnd
        var finalSelectionPos = startPos + charValidato.length
        this.value = this.value.substring(0, startPos) + charValidato + this.value.substring(endPos, this.value.length)
        var self = this
        setTimeout(() => {
          self.$refs.input.selectionStart = finalSelectionPos
          self.$refs.input.selectionEnd = finalSelectionPos
        }, 1)
      }
    },
    setFocus() {
      this.$refs.input.focus({ preventScroll: true })
    },
    validaChar(text) {
      var isInt = this.item.numeric && this.item.numeric.int
      var textNumb = parseInt(text)
      if (isInt) {
        if (isNaN(textNumb)) {
          return null
        }
      } else {
        if (isNaN(textNumb)) {
          if ((text === '.' || text === ',') && this.value.indexOf(',') === -1) {
            return ','
          } else {
            return null
          }
        }
      }
      return text
    },
    removeFocus() {
      this.$refs.input.blur()
    },
    onKeyInput(value) {
      if (this.item.readonly) {
        return
      }
      if (value && value.length === 1) {
        var charValidato = this.validaChar(value)
        if (charValidato !== null) {
          this.value += charValidato
          this.onChange()
        }
      } else if (value === '{backspace}' || value === '{bksp}') {
        if (this.value.length > 0) {
          this.value = this.value.substring(0, this.value.length - 1)
          this.onChange()
        }
      }
      var self = this
      setTimeout(() => {
        self.$refs.input.focus()
      }, 100)
    },
    checkValidations() {
      var params = this.$v.value.$params
      if (params.required && !this.$v.value.required) {
        this.msgErrore = this.msgValidazioni.required || 'Il campo è obbligatorio'
        this.isValid = false
        return
      }
      for (var n in params) {
        if (!this.$v.value[n]) {
          this.isValid = false
          this.msgErrore = this.msgValidazioni[n] || 'Correggere questo campo'
          return
        }
      }

      var val = this.value !== null ? this.value.split(',').join('.') : ''
      val = this.item.numeric && this.item.numeric.int ? parseInt(val) : parseFloat(val)

      var validazioniItem = this.item.validations || []
      for (var i = 0; i < validazioniItem.length; i++) {
        var v = validazioniItem[i]
        if (v.nome === 'requiredIf') {
          var compilato = this.$parent.$parent.isCampoCompilato(v.campoIfNot)
          if (!compilato && !this.value) {
            this.isValid = false
            this.msgErrore = v.msgErrore
            return
          }
        }

        if (!isNaN(val)) {
          if (v.nome === 'min') {
            if (val < v.valore) {
              this.isValid = false
              this.msgErrore = v.msgErrore || 'Il valore non deve essere inferiore a ' + v.valore
              return
            }
          } else if (v.nome === 'max') {
            if (val > v.valore) {
              this.isValid = false
              this.msgErrore = v.msgErrore || 'Il valore non deve essere superiore a ' + v.valore
              return
            }
          }
        }
      }
      this.isValid = true
      this.msgErrore = ''
    },
    valida() {
      this.$v.value.$touch()
      this.checkValidations()
      return this.isValid ? [] : [this.msgErrore]
    },
    getDati() {
      var val = this.value !== null ? this.value.split(',').join('.') : ''
      val = this.item.numeric && this.item.numeric.int ? parseInt(val) : parseFloat(val)
      return isNaN(val) ? null : val
    }
  }
}
</script>

<style lang="scss">
</style>

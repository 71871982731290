<template>
  <van-popup
    :value="show"
    position="left"
    :close-on-click-overlay="true"
    class="popupAppuntamenti main-popup-appuntamenti"
    @opened="openPopup"
    @click-overlay="closePopup"
    >
    <van-tabs v-model="activeName" @change="onChangeTab" :lazy-render="false" class="tab-popup-appuntamenti">
      <van-tab title="Appuntamenti" name="appuntamenti">
        <appuntamenti :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="appuntamenti"/>
      </van-tab>
      <van-tab title="Clienti" name="clienti">
        <clienti :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="clienti"/>
      </van-tab>
      <van-tab title="Prodotti" name="prodotti">
        <prodotti :context="context" :skInput="activeName === 'prodotti' ? skInput : ''" @send-event="sendEventToParent" @close-popup="closePopup" ref="prodotti"/>
      </van-tab>
    </van-tabs>
    <popup-list-result class="result-popup-appuntamenti"/>
    <simple-popup-keyboard class="keyboard-popup-appuntamenti" :enterText="$store.getters.enterKeyTitlePopupKeyboard" :keyboardClass="'kb_apt_1'" />
    <div class="command-popup-appuntamenti">
      <van-button @click="chiudiPopup" type="info">Chiudi</van-button>
    </div>
  </van-popup>
</template>

<script>
/* eslint-disable */
import Appuntamenti from './appuntamenti/appuntamenti'
import Clienti from './clienti/clienti' // Verificare se utilizzare lo stesso componente
// import Clienti from '@/components/popup/PopupFunzioni/clienti/clienti'
import Prodotti from '@/components/popup/PopupFunzioni/prodotti/prodotti' // attenzione --> servizi

import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'
import PopupListResult from '@/components/popup/ListResult'

export default {
  components: {
    Appuntamenti,
    Clienti,
    Prodotti,
    SimplePopupKeyboard,
    PopupListResult
  },
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      moduli: ['appuntamenti', 'clienti', 'prodotti'],
      activeName: 'appuntamenti',
      skInput: ''
    }
  },
  mounted() {
    // this.$store.commit('SET_EXTERNAL_TAB', this.activeName)
  },
  methods: {
    onChangeTab(name) {
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      this.$store.commit('SET_EXTERNAL_TAB', name)
      // imposta anche la tab attiva del componente tabs interno
      this.setInternalTab()
      this.moduli.forEach(modulo => {
        this.childOpen(modulo)
      })
      console.log('onChangeTab esterna', name, this.activeName)
    },
    setInternalTab() {
      const refM = this.$refs[this.activeName]
      if (refM) {
        const intName = refM.activeName
        this.$store.commit('SET_INTERNAL_TAB', intName)
      }
    },
    closePopup() {
      // this.$store.commit('HIDE_POPUP_KEYBOARD')
      // this.$store.commit('HIDE_POPUP_LIST_RESULT')
      this.$emit('close')
    },
    openPopup() {
      this.$store.commit('SET_EXTERNAL_TAB', this.activeName)
      this.setInternalTab()

      // this.$store.commit('SHOW_POPUP_KEYBOARD')
      // this.$store.commit('SHOW_POPUP_LIST_RESULT')
      this.moduli.forEach(modulo => {
        this.childOpen(modulo)
      })
    },
    chiudiPopup() {
      const event = {
        type: 'CHIUDI_POPUP',
        value: 'Appuntamento'
      }
      this.$emit('send-event', event)
    },
    childOpen(nome) {
      // console.log('childOpen esterno:', nome)
      const refM = this.$refs[nome]
      if (refM.onOpen) {
        refM.onOpen()
      }
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../popup.scss";
  @import "./style.scss";

  .swipe-cell-button {
    height: 100%;
  }

/*   [name = PopupAppuntamenti] .van-tabs {
    margin-top: 30px;
  } */
  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupAppuntamenti, .main-popup-appuntamenti {
      width: 96%;
      height: 100%;
    }
  }
  @media all and (min-width:1024px) {
    .popupAppuntamenti, .main-popup-appuntamenti {
      width: 96%;
      height: 100%;
    }
  }
  @media all and (min-width:1400px) {
/*     .popupAppuntamenti{
      width: 35%;
      height: 100%;
    } */
    .main-popup-appuntamenti {
      width: 80%;
      height: 100%;
    }
  }
</style>

import prodotti from '@/services/prodottiService.js'
import configurazioni from '@/services/configurazioniService.js'
import movimenti from '@/services/movimentiService.js'
import fidelity from '@/services/fidelityService.js'
import scontrini from '@/services/scontriniService.js'
import clienti from '@/services/clientiService.js'
import appuntamenti from '@/services/appuntamentiService.js'
import etichette from '@/services/etichetteService.js'
import magazzino from '@/services/magazzinoService.js'
import stampante from '@/services/stampanteService.js'
import tabelle from '@/services/tabelleService.js'
import documenti from '@/services/documentiService.js'
import licenze from '@/services/licenzeService.js'
import reporting from '@/services/reportingService.js'

export const insomnia = {
  data() {
    return {
    }
  },
  methods: {
    async cicleSyncProcess() {
      try {
        await prodotti.cicleSync()
        await configurazioni.cicleSync()
        await movimenti.cicleSync()
        await magazzino.cicleSync()
        await fidelity.cicleSync()
        await scontrini.cicleSync()
        await clienti.cicleSync()
        await appuntamenti.cicleSync()
        await tabelle.cicleSync()
        await documenti.cicleSync()
        await reporting.cicleSync()
      } catch (error) {
        console.log('errore cicleSyncProcess', error)
      }
    },
    async getProcessStatus(payload) {
      const status = []
      try {
        status.push(await prodotti.getRunMode())
        const statusConfigurazioni = await configurazioni.getRunMode()
        const baseDb = statusConfigurazioni.baseDb
        status.push(statusConfigurazioni)
        status.push(await movimenti.getRunMode())
        status.push(await fidelity.getRunMode())
        status.push(await scontrini.getRunMode())
        status.push(await clienti.getRunMode())
        status.push(await appuntamenti.getRunMode())
        status.push(await etichette.getRunMode())
        status.push(await magazzino.getRunMode())
        const statusStampante = await stampante.getRunMode()
        statusStampante.payload = payload
        status.push(statusStampante)
        status.push(await tabelle.getRunMode())
        status.push(await documenti.getRunMode())
        status.push(await reporting.getRunMode())
        const statusDisplay = this.getStatusDisplay()
        status.push(statusDisplay)
        await licenze.sendModuleInfo(baseDb, status)
      } catch (error) {
        console.log('errore getProcessStatus', error)
      }
      return status
    },
    async debugMode() {
      try {
        return await configurazioni.getRunMode()
      } catch (error) {
        console.log('errore debugMode', error)
        return {}
      }
    },
    getStatusDisplay() {
      /* eslint-disable */
      return {
        baseDb: '',
        tipo: 'client',
        data: new Date(),
        modulo: 'DISPLAY',
        debugMode: false,
        onlyRemote: false,
        locale: '--no DB--',
        remote: '--no DB--',
        version: configurazioni.getDisplayVersion(),
        health: true // Verificare come calcolare
      }
    }
  }
}

<template>
  <div class="ricercaField">
    <!--<div class="labelForm">{{item.label || 'Cerca'}}</div>-->
    <div>
      <input class="modalFormRicerca" :placeholder="item.placeHolder || 'Inserire testo..'"  v-model="value"
        @input="onChange" ref="input" @focus="onFocus" @blur="onBlur" autocomplete="off" type="text"/>
      <button class="btnCerca van-button van-button--danger van-button--normal" @click="onClickCerca">Cerca</button>
    </div>
    <div class="risultatiRicerca">
      <div v-if="caricamentoInCorso">Sto cercando...</div>
      <div v-else-if="!risultati.length">Nessun risultato</div>
      <ricerca-elemento-field v-for="r in risultati" :key="r.id" :item="r" :config="item.ricerca" ref="risultatiElementi"></ricerca-elemento-field>
    </div>
  </div>
</template>

<script>
import RicercaElementoField from './RicercaElementoField'

export default {
  components: {
    RicercaElementoField
  },
  props: {
    item: Object
  },
  data() {
    return {
      value: '',
      caricamentoInCorso: false,
      risultati: []
    }
  },
  created() {
  },
  mounted() {
    if (this.item.valore) {
      this.value = this.item.valore
    }
    !this.item.disableFirstSearch && this.cerca()
  },
  watch: {
    value(val) {
      this.$forceUpdate()
    }
  },
  methods: {
    onFocus(event) {
      this.$emit('on-focus')
    },
    onBlur(event) {
      // this.$emit('on-blur')
    },
    onChange() {
      clearTimeout(this.timeoutRicerca)
      var self = this
      this.timeoutRicerca = setTimeout(() => {
        self.cerca()
      }, 1000)
    },
    setFocus() {
      this.$refs.input.focus({ preventScroll: true })
    },
    removeFocus() {
      this.$refs.input.blur()
    },
    onKeyInput(value) {
      if (this.item.readonly) {
        return
      }
      if (value === '{space}') {
        value = ' '
      }
      if (value && value.length === 1) {
        this.value += value
        this.onChange()
      } else if (value === '{backspace}' || value === '{bksp}') {
        if (this.value.length > 0) {
          this.value = this.value.substring(0, this.value.length - 1)
          this.onChange()
        }
      }
      var self = this
      setTimeout(() => {
        self.$refs.input.focus()
      }, 100)
    },
    onClickCerca() {
      this.cerca()
    },
    async cerca() {
      clearTimeout(this.timeoutRicerca)
      this.risultati = []
      this.caricamentoInCorso = true
      var ricercaMethod = this.item.ricerca.ricercaMethod
      var res = await ricercaMethod(this.value)
      this.risultati = res && res.length ? res : []
      this.caricamentoInCorso = false
    },
    getDati() {
      return this.value
    }
  }
}
</script>

<style lang="scss" scoped>

  .risultatiRicerca {
    box-sizing: border-box;
    background-color: #ececec;
    overflow-y: scroll;
    // padding: 0 0 0.5em 0.5em;
    padding: 0 0 0.5em 0;
    margin-top: 0.4em;
    height: 43vh;
    width: 100%;
  }

  .btnCerca {
    font-size: 3vh;
    height: 7vh;
    border-radius: 0 7px 7px 0;
    margin-left: 0;
    background: #339900;
    border: 2px solid #339900;
  }

  .modalFormRicerca {
    margin-top: 6px;
    // margin-left: 6px;
    border-radius: 7px 0 0 7px !important;
  }

  .btnRicercaElemento {
    background: #eeeeee;
    border: 1px solid #000000;
  }

  .risultatiRicerca
  {
    &::-webkit-scrollbar{
      display: unset !important;
      width: 2vw;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: #666666;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #666666;
    }
  }
</style>

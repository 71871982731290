<template>
  <div class="gruppoTasti" :class="zona">
    <tasto
      v-for="(ts, index) in tasti"
      :key="index"
      :row="ts.row"
      :col="ts.col"
      :row_span="ts.row_span"
      :col_span="ts.col_span"
      :aspect="ts.aspect"
      :aspect2th="ts.aspect2th"
      :aspectIfValid="ts.aspectIfValid"
      :aspectIfCondition="getAspectIfCondition(ts.aspectTestCondition, ts.aspectIfCondition)"
      :aspectTestCondition="getAspectTestCondition(ts.aspectTestCondition).value"
      :hiddenTestCondition="getAspectTestCondition(ts.hiddenTestCondition).value"
      :title="getTitleVariable(ts.title)"
      :title2th="getTitleVariable(ts.title2th)"
      :action="ts.action"
      :attribute="getAttributeValue(ts.attribute)"
      :infoTasti="context.debug.infoTasti"
      :disabled="testDisable(ts.disabled, ts.ruoliDisab, ts.utentiDisab, ts.ruoloTasto)"
      :variableToDisplay="ts.variableToDisplay"
      :valueToDisplay="getContextValue(ts.variableToDisplay)"
      :maxChar="ts.maxChar"
      :displayFocus="context.displayFocus"
      :scontrinoTick="context.scontrinoTick"
      :helpEnabled="context.helpEnabled"
      :image="ts.image"
      @send-event="sendEventToParent"
    />
    <!-- :disabled="testDisable(ts.disabled)" -->
    <!-- TODO: Passare ts invece di tutte le proprietà per esteso -->
    <!--:aspectIfCondition="ts.aspectIfCondition"
      :aspectTestCondition="getContextValue(ts.aspectTestCondition) === true" -->
  </div>
</template>

<script>
import _ from 'lodash'
import { ruoloPresente } from '@/components/helpers'
import Tasto from '@/components/gruppoTasti/Tasto.vue'

export default {
  components: {
    Tasto
  },
  props: {
    context: Object,
    tasti: Array,
    zona: String
  },
  data() {
    return {
    }
  },
  computed: {
    operatore() {
      return this.context.operatore.servizio
    },
    ruoli() {
      return this.operatore.ruoli
    }
  },
  methods: {
    getTitleVariable(title) {
      if (!title.startsWith('@')) {
        return title
      }
      const variable = title.substring(1)
      return this.getContextValue(variable)
    },
    getAttributeValue(attribute) {
      return _.get(this.context.attributes, attribute) || ''
    },
    getContextValue(variable) {
      if (!variable) return ''
      if (variable && variable.startsWith('#')) {
        return variable.substring(1)
      }
      if (variable && variable.startsWith('€')) {
        const value = _.get(this.context, variable.substring(1))
        return value ? value.toFixed(2) : ''
      }
      if (variable && variable.startsWith('@')) {
        const operands = variable.substring(1).split(' eq ')
        if (operands.length === 2) {
          return String(_.get(this.context, operands[0])) === operands[1]
        } else {
          return false
        }
      }
      if (variable && variable.indexOf('||') > -1) {
        const v1 = variable.split('||')[0]
        const v2 = variable.split('||')[1]
        return _.get(this.context, v1.trim()) || _.get(this.context, v2.trim()) || ''
      }
      return _.get(this.context, variable) || ''
    },
    getAspectIfCondition(itemTest, item) {
      if (!itemTest) return ''
      const index = this.getAspectTestCondition(itemTest).index
      if (index !== -1) {
        const values = item.split(',')
        return values.length > index ? values[index] : ''
      } else {
        return ''
      }
    },
    getAspectTestCondition(itemTest) {
      if (!itemTest) return { index: -1, value: false }
      const tests = itemTest.split(',')
      for (let index = 0; index < tests.length; index++) {
        let varName = tests[index]
        let neg = false
        if (varName.startsWith('!')) {
          varName = varName.substring(1)
          neg = true
        }
        const cond = this.getContextValue(varName) === true
        if (neg ? !cond : cond) {
          return { index, value: true }
        }
      }
      return { index: -1, value: false }
    },
    testDisable(item, ruoliDisab, utentiDisab, ruoloTasto) {
      let disabled = false
      if (ruoliDisab) {
        const ruoliOperatore = _.get(this, 'operatore.ruoli', [])
        const intersect = _.intersection(ruoliDisab, ruoliOperatore)
        if (intersect.length > 0 || ruoliOperatore.length === 0) {
          disabled = true
        }
      }
      if (utentiDisab && utentiDisab.length > 0) {
        const operatore = _.get(this, 'operatore.codice', '')
        if (utentiDisab.includes(operatore)) {
          disabled = true
        }
      }
      if (ruoloTasto) {
        const ruoliOperatore = _.get(this, 'operatore.ruoli', [])
        if (!ruoliOperatore.includes(ruoloTasto)) {
          disabled = true
        }
      }
      const gestioneRuoliOff = _.get(this.context, 'impostazioni.operatori.disabilitaGestioneRuoli', false)
      if (disabled && !gestioneRuoliOff) {
        return true
      } else {
        disabled = false
      }
      try {
        if (item) {
          const value = _.get(this.context, item.variable, false)
          if (item.value && item.value.startsWith('!')) {
            disabled = value !== item.value.substring(1)
          } else {
            disabled = value === item.value
          }
        }
      } catch (ex) {

      }
      return disabled
    },
    testDisableNew(item, ruoliDisabled) { // 08/07/2022 MICHELE -> da completare per includere i ruoli
      let disabled = false
      try {
        if (item) {
          const value = _.get(this.context, item.variable, false)
          if (item.value && item.value.startsWith('!')) {
            disabled = value !== item.value.substring(1)
          } else {
            disabled = value === item.value
          }
        }
        if (!disabled && ruoliDisabled) {
          var ruolo = _.get(this.context, 'operatore.accesso.ruoloEditor', '')

          var r = !Array.isArray(ruoliDisabled) ? [(ruoliDisabled || '')] : ruoliDisabled // trasformo in array se non lo e'
          disabled = ruoloPresente(ruolo, r)
        }
      } catch (ex) {

      }
      return disabled
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupTastiera main-popup-tastiera"
      :overlay="true"
      @open="onOpen"
      @close="onClose"
      @click-overlay="onExit"
    >
      <van-cell-group>
        <van-field
          v-model="testo"
          label="Testo"
          type="textarea"
          rel="inputNota"
          placeholder="Digita il testo da inserire"
          rows="2"
          autosize
          cleareable
          @clear="onClearValore"
          @focus="onFocus"
        >
          <template #button>
            <van-button size="small" type="primary" @click="onInsertText">Inserisci</van-button>
          </template>
        </van-field>
        <van-row>
          <van-col span="8">
            <van-field
              :value="barcode"
              label="Barcode"
              type="text"
              placeholder="Eventuale barcode prodotto non caricato in archivio"
            />
          </van-col>
          <van-col span="16" class="btn-spaced">
            <van-button v-for="(cc, index) in codiciIva" :key="index" type="info" :plain="ivaSelected(cc)" size="small" @click="selectIva(cc)">{{labelIva(cc)}}</van-button>
<!--             <van-button type="info" :plain="ivaSelected(0)" size="small" @click="selectIva(0)">Esente</van-button>
            <van-button type="info" :plain="ivaSelected(4)" size="small" @click="selectIva(4)">4%</van-button>
            <van-button type="info" :plain="ivaSelected(5)" size="small" @click="selectIva(5)">5%</van-button>
            <van-button type="info" :plain="ivaSelected(10)" size="small" @click="selectIva(10)">10%</van-button>
            <van-button type="info" :plain="ivaSelected(22)" size="small" @click="selectIva(22)">22%</van-button> -->
          </van-col>
        </van-row>

      </van-cell-group>
      <simple-popup-keyboard class="keyboard-popup-tastiera" enterText="Conferma" :keyboardClass="'kb_tastiera'" />
    </van-popup>
</template>

<script>
import _ from 'lodash'
import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'
// "codiciIva": ["N2", "4", "5", "10", "22"],
export default {
  components: {
    SimplePopupKeyboard
  },
  props: {
    show: Boolean,
    context: Object
  },
  data(vm) {
    return {
      testo: '',
      activeTab: 'popup',
      activeParent: 'tastieraNote',
      activeField: 'testo',
      ivaSel: '',
      codiciIva: []
    }
  },
  async mounted() {
    this.onOpen()
    await this.$nextTick()
    this.ivaSel = _.get(this.context, 'impostazioni.notaLibera.ivaDefault', '22')
    if (this.fiscale) {
      this.codiciIva = this.fiscale.map(x => x.iva)
    }
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    barcode() {
      return this.context.barcodePerGenerico
    },
    fiscale() {
      return _.get(this.context, 'impostazioni.notaLibera.fiscale')
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    kbKeyPress(value) {
      if (this.show) {
        if (value === '{esci}') {
          // this.closePopup()
          this.onInsertText()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          // this.closePopup()
          this.onInsertText()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    barcode(code) {
      console.log('watch barcode', code, this.testo)
      this.testo = this.testo.split(code).join('')
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', this.testo)
    },
    fiscale() {
      if (this.fiscale) {
        this.codiciIva = this.fiscale.map(x => x.iva)
        this.ivaSel = _.get(this.context, 'impostazioni.notaLibera.ivaDefault', '22')
      }
    }
  },
  methods: {
    ivaSelected(iva) {
      return this.ivaSel !== iva
    },
    selectIva(iva) {
      this.ivaSel = iva
      const ds = this.$refs.inputNota
      if (ds) {
        this.$refs.inputNota.focus()
      }
    },
    labelIva(codice) {
      if (codice && codice.startsWith('N')) {
        return `Esente ${codice}`
      } else {
        return `${codice}%`
      }
    },
    /*     getStilePopup() {
      var cont = document.querySelector('.tab-popup-tastiera')
      if (cont) {
        return `height: "100%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "100%";'
      }
    }, */
    handleKey(valore) {
      // TODO: verificare se ricerca automatica o solo quando si preme "Cerca"
      // this.debounceInputProdotti(valore)
    },
    /*     onClearProdotto() {
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
    }, */

    /*     onFocus(event) {
      console.log('onfocus')
      // this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
    },
    async onSubmit(values) {
      this.clearFields()
    }, */
    onFocus(event) {
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
    },
    onInsertText() {
      const fiscale = _.get(this.context, 'impostazioni.notaLibera.fiscale')
      let reparto = ''
      if (fiscale) {
        reparto = fiscale.find(x => x.iva === this.ivaSel).repartoFiscale
      }
      const event = {
        type: 'INSERISCI_TESTO',
        value: {
          testo: this.testo,
          iva: this.ivaSel,
          repartoFiscale: reparto
        }
      }
      this.$emit('send-event', event)
      // this.context.popups.find(el => el.type === 'TastieraNote').show = false
    },
    onClearValore() {
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
    },
    onOpen() {
      this.clearSearchInput()
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      this.$store.commit('SET_EXTERNAL_TAB', this.activeParent)
      this.$store.commit('SET_INTERNAL_TAB', this.activeTab)
    },
    clearSearchInput() {
      this.testo = ''
    },
    /*     clearSearchInput() {
      this.prodotto = ''
    },
    clearFields() {
      this.clearSearchInput()
    }, */
    closePopup() {
      this.$emit('close')
      this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '')
    },
    onClose() {
      this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '')
      // this.context.popups.find(el => el.type === 'TastieraNote').show = false
    },
    onExit() {
      this.testo = ''
      this.onInsertText()
    }
    /*     aggiungiAlCarrello(codice) {
      const event = {
        type: 'PRODOTTO_CERCA',
        value: {
          code: codice,
          tipoAliquota: 'banco'
        }
      }
      this.$emit('send-event', event)
    } */
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .result-popup-tastiera {
    margin-top: 20px;
  }

  .swipe-cell-button {
    height: 100%;
  }
/*   .van-search .van-cell {
    padding: 10px 16px;
  } */

  .van-search--show-action {
      padding-right: 12px;
  }

  .main-popup-tastiera {
    top: auto;
  }

  .keyboard-popup-tastiera {
    margin-top: 50px;
  }

  .btn-spaced {
    text-align: right;
    padding-right: 15px;;
    & button {
      margin-right: 10px;
      margin-top: 5px;
      min-width: 60px;
    }
  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupTastiera, .main-popup-tastiera {
      width: 85%;
      height: 70%;
    }
  }
  @media all and (min-width:1024px) {
    .popupTastiera, .main-popup-tastiera {
      width: 65%;
      height: 63%;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-tastiera {
      width: 40%;
      height: 50%;
      /* bottom: -200px; */

    }

  }
  .van-popup--left {
    top: 50%;
    left: 0;
  }

</style>

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupReport main-popup-report"
      @open="onOpen"
      @click-overlay="closePopup"
      >
      <van-tabs v-model="activeName"  >
        <van-tab name="generaMovimento" title="Movimento">
          <van-cell-group title="Genera movimento">
            <van-field
              readonly
              clickable
              :value="$v.magazzino.$model"
              name="magazzino"
              ref="magazzino"
              label="Magazzino"
              placeholder="Magazzino"
              :error-message="$v.magazzino.$error ? 'Valore mancante' : magazzinoDescrizione"
              :class="{ 'mostra-descrizione': !$v.magazzino.$error}"
              @click="showMagazzino = true"
            />
            <van-popup v-model="showMagazzino" position="bottom" visible-item-count="8" :style="getStilePopup()">
              <van-picker show-toolbar title="Magazzino" :columns="columnsMagazzino" @confirm="onConfirmMagazzino" @cancel="showMagazzino = false"/>
            </van-popup>

            <van-field
              readonly
              clickable
              name="data"
              :value="$v.data.$model"
              label="Data"
              placeholder="Data movimento"
              @click="showCalendar = true"
            />
            <van-calendar v-model="showCalendar" @confirm="onConfirmData" :show-confirm="false" :style="getStilePopup()"/>

            <van-field
              readonly
              clickable
              :value="$v.causale.$model"
              name="causale"
              ref="causale"
              label="Causale"
              placeholder="Causale"
              :error-message="$v.causale.$error ? 'Valore mancante' : causaleDescrizione"
              :class="{ 'mostra-descrizione': !$v.causale.$error}"
              @click="showCausale = true"
            />
            <van-popup v-model="showCausale" position="bottom" :style="getStilePopup()" visible-item-count="8">
              <van-picker show-toolbar title="Causale" :columns="columnsCausale" @confirm="onConfirmCausale" @cancel="showCausale = false"/>
            </van-popup>

            <van-field
              readonly
              clickable
              :value="$v.nominativo.$model"
              name="nominativo"
              ref="nominativo"
              :label="mostraCliente ? 'Cliente' : 'Fornitore'"
              placeholder="Nominativo"
              :error-message="$v.nominativo.$error ? 'Valore mancante' : nominativoDescrizione"
              :class="{ 'mostra-descrizione': !$v.nominativo.$error}"
              @click="showNominativo = true"
            />
            <van-popup v-model="showNominativo" position="bottom" :style="getStilePopup()" visible-item-count="8">
              <van-picker show-toolbar :title="mostraCliente ? 'Cliente' : 'Fornitore'" :columns="columnsNominativo" @confirm="onConfirmNominativo" @cancel="showNominativo = false"/>
            </van-popup>
            <van-field
              v-model="riferimento"
              name="riferimento"
              ref="riferimento"
              label="Riferimento"
              placeholder="Riferimento movimento"
              :error-message="$v.riferimento.$error ? 'Valore richiesto' : ''"
              :rules="[{trigger:'onBlur'}]"
            />
            <div style="margin: 16px;">
              <van-button block type="info" @click="creaMovimentoGenerico">Crea movimento</van-button>
            </div>
          </van-cell-group>
        </van-tab>
        <van-tab name="conta" title="Inventario">
          <van-cell-group title="Conta per inventario">
            <van-field
              v-model="settore"
              name="settore"
              ref="settore"
              label="Settore"
              placeholder="Settore conteggio"
            />
            <div style="margin: 16px;">
              <van-button block type="info" @click="caricaContaInventario">Carica conta inventario</van-button>
            </div>
          </van-cell-group>
        </van-tab>
        <van-tab name="rettifica" title="Rettifica">
          <van-cell-group title="Rettifica">
          </van-cell-group>
        </van-tab>
        <van-tab name="etichette" title="Etichette">
          <van-cell-group title="Stampa Etichette">
            <van-row type="flex" justify="center">
              <van-col span="18"><h4 style="line-height: 25px;">Vengono stampate le etichette per ciascun prodotto inserito nel carrello, in base alle relative quantità</h4></van-col>
            </van-row>
            <van-cell center>
              <template #title>
                <van-button type="primary" @click="stampaEtichette" class="buttonStampa">Stampa Etichette</van-button>
              </template>
              <template #right-icon>
                Salta <van-stepper v-model="salta" min="0" style="margin-left: 5px;"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-tab>
        <van-tab name="dettagli" title="Dettagli">
          <popup-json-editor class="jsoneditor-popup-usaselezione"/>
        </van-tab>
      </van-tabs>
      <div class="command-popup-report">
        <van-button @click="closePopup" type="info">Torna alla Cassa</van-button>
      </div>
    </van-popup>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import configurazioni from '@/services/configurazioniService.js'
import etichetteService from '@/services/etichetteService.js'
import prodottiService from '@/services/prodottiService.js'
import clientiService from '@/services/clientiService.js'
import magazzinoService from '@/services/magazzinoService.js'
import { dynamicSortMultiple } from '@/components/helpers.js'
import PopupJsonEditor from '@/components/popup/JsonEditor'
/* import movimenti from '@/services/movimentiService.js'
import magazzino from '@/services/magazzinoService.js' */

export default {
  components: {
    PopupJsonEditor
  },
  mixins: [validationMixin],
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      activeName: 'etichette',
      showMagazzino: false,
      showCalendar: false,
      showCausale: false,
      showTipoMovimento: false,
      showNominativo: false,
      magazzini: [],
      causali: [],
      clienti: [],
      magazzino: '',
      magazzinoDescrizione: '',
      data: '',
      causale: '',
      causaleTipo: '',
      causaleDescrizione: '',
      mostraCliente: false,
      mostraFornitore: false,
      nominativo: '',
      nominativoDescrizione: '',
      riferimento: '',
      settore: '',
      submitStatus: null,
      // pathEtichettePrefix: '',
      salta: 0
    }
  },
  validations: {
    magazzino: { required },
    data: { required },
    causale: {},
    nominativo: {},
    riferimento: { required }
  },
  async mounted() {
    this.magazzini = await configurazioni.getConfigurazione('magazzino_magazzini', 'magazzini')
    this.causali = await configurazioni.getConfigurazione('magazzino_causali', 'causali')
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    columnsMagazzino() {
      const v = this.magazzini
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    columnsCausale() {
      const v = this.causali
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    columnsNominativo() {
      // const usaFornitore = this.causale.visualizzaFornitore
      const elenco = this.mostraFornitore ? this.clienti.filter(x => x.amministrativi && x.amministrativi.fornitore) : this.clienti.filter(x => x.amministrativi && x.amministrativi.cliente)
      return elenco.sort(dynamicSortMultiple('ragioneSociale')).map(el => el.ragioneSociale)
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    kbKeyPress(value) {
      if (this.show) {
        if (value === '{esci}') {
          this.closePopup()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          this.closePopup()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    }
    /*     listResult(payload) {
      const item = payload.result
      console.log('payload', payload)
      this.prodottoItem = item
      this.$store.commit('prodotti/SET_PRODOTTO', this.prodottoItem)
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.aggiungiAlCarrello(item.codice)
    } */
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.tab-popup-report')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    handleKey(valore) {
    },
    onConfirmMagazzino(value) {
      this.showMagazzino = false
      const magazzino = this.magazzini.find(x => x.descrizione === value)
      this.magazzino = magazzino.codice
      this.magazzinoDescrizione = magazzino.descrizione
    },
    onConfirmData(value) {
      this.data = moment(value).format('DD/MM/YYYY')
      this.showCalendar = false
    },
    onConfirmCausale(value) {
      this.showCausale = false
      const causale = this.causali.find(x => x.descrizione === value)
      this.causale = causale.codice
      this.causaleTipo = causale.tipo
      this.causaleDescrizione = causale.descrizione
      this.mostraCliente = causale.visualizzaCliente
      this.mostraFornitore = causale.visualizzaFornitore
      this.mostraPrezzoAcquisto = causale.aggiornaPrezzoAcquisto
      this.mostraPrezzoVendita = causale.aggiornaPrezzoVendita
      this.nominativo = null
      this.nominativoDescrizione = ''
      // TODO: Gestione prezzo netto
    },
    onConfirmNominativo(value, index) {
      this.showNominativo = false
      const nominativo = this.clienti.find(x => x.ragioneSociale === value) // TODO: mettere codice
      this.nominativo = nominativo.codice
      this.nominativoDescrizione = nominativo.ragioneSociale
    },
    async creaMovimentoGenerico() {
      // this.$toast('Funzione da Implementare', { type: 'fail', duration: 20000 })
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast('Sono presenti alcuni errori, verificare i dati inseriti', { type: 'fail', duration: 20000 })
      } else {
        const movimentiMultipli = await this.buildMovimentiMultipli()
        await magazzinoService.creaMovimentiMultipli(movimentiMultipli)
        this.$toast('Movimenti creati con successo!', { type: 'success' })
        this.clearFieldsMovimenti()
      }
    },
    async buildMovimentiMultipli() {
      const prodottiInseriti = this.context.righeCarrello
      const ids = prodottiInseriti.map(x => x.codice)
      var prodotti = await prodottiService.getProdottiByIds(ids)
      /*       const mm = await movimentiService.getModello('movimento') */
      const movimenti = prodotti.map(el => {
        const quantita = prodottiInseriti.find(x => x.codice === el._id).quantita
        const nM = {
          magazzino: this.magazzino,
          tipo: this.tipoCausale,
          causale: this.causale,
          data: this.data,
          prodotto: el,
          anagrafica: {},
          riferimento: {
            tipo: 'usaSelezione',
            id: this.riferimento,
            data: this.data
          }
        }
        if (this.mostraCliente) {
          nM.anagrafica.cliente = {
            codice: this.nominativo,
            descrizione: this.nominativoDescrizione
          }
        }
        if (this.mostraFornitore) {
          nM.anagrafica.fornitore = {
            codice: this.nominativo,
            descrizione: this.nominativoDescrizione
          }
        }
        nM.prodotto.quantita = +parseFloat(quantita).toFixed(2) // TODO: VA messo nel movimento, non nel prodotto
        nM.quantita = nM.prodotto.quantita
        if (this.tipoPrezzo === 'acquisto') {
          nM.prodotto.prezzoUnitario = +parseFloat(el.magazzino.prezzoAcquisto || 0).toFixed(2) // TODO: VA messo nel movimento, non nel prodotto
        } else {
          nM.prodotto.prezzoUnitario = +parseFloat(el.prezzo.L1.base || 0).toFixed(2) // TODO: in base al listino di default
        }
        nM.prodotto.prezzo = +(nM.prodotto.prezzoUnitario * parseFloat(quantita)).toFixed(2) // TODO: VA messo nel movimento, non nel prodotto
        nM.prezzo = nM.prodotto.prezzo

        return nM
      })
      return movimenti
    },
    async caricaContaInventario() {
      if (this.settore === '') {
        this.$toast('E\' necessario specificare il settore di caricamento', { type: 'fail', duration: 20000 })
      } else {
        const prodottiInseriti = this.context.righeCarrello
        const datiRaw = prodottiInseriti.map(x => { return { barcode: x.barcode, quantita: x.quantita } })
        const infoImport = {
          data: new Date(),
          settore: this.settore,
          note: '',
          nomeFile: 'cassa',
          tipoFile: 'usaSelezione',
          totaleDati: datiRaw.length,
          datiRaw: datiRaw
        }
        const datiReduce = _.reduce(datiRaw, function(result, value) {
          const chiave = String(value.barcode).trim()
          const prec = result[chiave] || { barcode: chiave, quantita: 0 }
          prec.quantita += parseInt(value.quantita)
          result[chiave] = prec
          return result
        }, {})
        const dati = Object.values(datiReduce)
        await magazzinoService.importaInventarioLetture(infoImport.nomeFile, infoImport.tipoFile, infoImport.settore, infoImport.data, infoImport.note, datiRaw, dati)
        this.$toast('Letture caricate con successo! Cancellare manualmente i prodotti caricati', { type: 'success', duration: 20000 })
        this.settore = ''
      }
    },
    async stampaEtichette() {
      const prodottiInseriti = this.context.righeCarrello
      try {
        await this.$dialog.confirm({ title: 'Stampa etichette', message: 'Confermi la stampa delle etichette per i prodotti selezionati?', cancelButtonText: 'NO', confirmButtonText: 'SI' })
        const ids = prodottiInseriti.map(x => x.codice)
        var prodotti = await prodottiService.getProdottiByIds(ids)
        const etichette = prodotti.map(el => {
          const quantita = prodottiInseriti.find(x => x.codice === el._id).quantita
          return {
            codice: el.codice,
            descrizione: el.descrizione,
            taglia: el.varianti && el.varianti.variante1 && el.varianti.variante1.descrizione ? el.varianti.variante1.descrizione : '',
            colore: el.varianti && el.varianti.variante2 && el.varianti.variante2.descrizione ? el.varianti.variante2.descrizione : '',
            prezzo: el.prezzo && el.prezzo.L1 && el.prezzo.L1.base ? el.prezzo.L1.base : '',
            barcode: el.ricercaBarcode,
            prezzo2: el.prezzo && el.prezzo.L2 && el.prezzo.L2.base ? el.prezzo.L2.base : '',
            prezzo2Sconto: el.prezzo && el.prezzo.L2 && el.prezzo.L2.sconto ? el.prezzo.L2.sconto : '',
            acquisto: el.ultimoAcquisto && el.ultimoAcquisto.prezzo ? el.ultimoAcquisto.prezzo : el.magazzino && el.magazzino.prezzoAcquisto ? el.magazzino.prezzoAcquisto : '',
            quantita: quantita || 1
          }
        })
        const str = JSON.stringify(etichette)
        const tipoStampa = this.context.impostazioni.etichette.tipo
        const report = this.context.impostazioni.etichette.report
        await etichetteService.stampaEtichetta(tipoStampa, report, str, this.salta, 'movimento')
      } catch {
      }
    },
    async setJsonEditor() {
      if (this.context.righeCarrello.length !== 0) {
        const ids = this.context.righeCarrello.map(x => x.codice)
        const dati = await prodottiService.getProdottiByIds(ids)
        this.$store.commit('settings/SET_JSON_EDITOR', dati)
        this.$store.commit('settings/SET_IN_MODIFICA', 'Selezione')
      } else {
        this.$store.commit('settings/SET_JSON_EDITOR', [])
        this.$store.commit('settings/SET_IN_MODIFICA', 'Nessun dato')
      }
      this.$store.commit('settings/SET_READ_ONLY', true)
      this.$store.commit('settings/SHOW_JSON_EDITOR')
    },
    async onOpen() {
      await this.setJsonEditor()
      const data = await clientiService.getFilteredCustomers({}, {})
      this.clienti = data && data.total_rows > 0 ? data.rows.map(x => x.doc) : []
    },
    closePopup() {
      this.$emit('close')
      this.$store.commit('settings/SET_READ_ONLY', false)
    },
    clearFieldsMovimenti() {
      this.magazzino = null
      this.magazzinoDescrizione = ''
      this.causale = null
      this.causaleDescrizione = ''
      this.nominativo = null
      this.nominativoDescrizione = ''
      this.riferimento = ' '
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .van-field__control {
    width: auto;
  }

  .ddTitleClass {
    justify-content: left;
  }

  .swipe-cell-button {
    height: 100%;
  }
  .van-search .van-cell {
    padding: 10px 16px;
  }

  .van-search--show-action {
      padding-right: 12px;
  }

  .jsoneditor-popup-usaselezione {
    height: 90vh;

  }

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupReport, .main-popup-report {
      width: 75%;
      height: 100%;
    }
  }
  @media all and (min-width:1024px) {
    .popupReport, .main-popup-report {
      width: 55%;
      height: 100%;
    }

    .jsoneditor-popup-usaselezione {
      height: 87vh;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-report {
      width: 30%;
      height: 100%;
    }
  }
</style>

import prodotti from '@/services/prodottiService.js'

const validate = async (codice) => {
  const prodotto = await prodotti.getProdotto(codice)
  if (prodotto && prodotto.message === 'missing') {
    return null
  }
  return 'Prodotto esistente'
}

export default {
  validate
}

<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" @salva="salva" :campi="campi" :config="config"></modale-form>
</template>

<script>
import ModaleForm from '../ModaleForm'
import clienti from '@/services/clientiService.js'
import configurazioni from '@/services/configurazioniService.js'
import { cloneObject } from '@/components/helpers'
import _ from 'lodash'
import * as dot from 'dot-object'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      clienteInModifica: null,
      campi: [],
      config: {
        disableEsciOnClick: true,
        clienteDefault: {
          recapiti: [{
            tipo: {
              codice: 'principale',
              descrizione: 'Sede'
            }
          }],
          amministrativi: {
            cliente: true,
            fornitore: false
          },
          contatti: [{
            tipo: 'titolare'
          }]
        }
      }
    }
  },
  async created() {
    // DA ABILITARE UNA VOLTA CHE LE CONFIGURAZIONI SONO A POSTO
    /*     const configModale = await configurazioni.getConfigurazione('display_elementi', 'elementi')
    if (configModale && configModale.clienti) {
      if (this.context.nuovoCliente && this.context.nuovoCliente.clienteInModifica) {
        const campi = configModale.clienti.modifica ? configModale.clienti.modifica.campi || [] : []
        this.config = configModale.clienti.modifica ? configModale.clienti.modifica.modale || {} : {}

        var cliente = await clienti.getCliente(this.context.nuovoCliente.clienteInModifica.codice)
        this.clienteInModifica = cliente

        // prepara dati iniziali
        for (var i = 0; i < campi.length; i++) {
          var c = campi[i]
          if (c.model) {
            var val = dot.pick(c.model, this.clienteInModifica)
            c.valore = val
          }
        }
        this.campi = campi
      } else {
        this.campi = configModale.clienti.nuovo ? configModale.clienti.nuovo.campi || [] : []
        var self = this
        setTimeout(() => {
          self.$set(self.campi[2], 'hidden', true)
          self.$set(self.campi[4], 'hidden', true)
        }, 2000)
        this.config = configModale.clienti.nuovo ? configModale.clienti.nuovo.modale || {} : {}
      }
    }
 */
    /* ESEMPIO CAMPI
    this.campi = [
      { campo: 'nome', tipo: 'text', label: 'Nome', placeHolder: 'Test Placeholder 1..', validations: [{ nome: 'required' }], valore: 'Mario' },
      { campo: 'cognome', tipo: 'text', label: 'Cognome', placeHolder: 'Test Placeholder 1..', validations: [{ nome: 'required' }] },
      { campo: 'codiceFiscale', tipo: 'text', label: 'Codice Fiscale', placeHolder: 'Codice Fiscale', validations: [{ nome: 'codiceFiscale', msgErrore: 'Codice fiscale non è valido' }, { nome: 'requiredIf', campoIfNot: 'partitaIva', msgErrore: 'Compilare almeno un campo tra partita iva e codice fiscale' }] },
      { campo: 'partitaIva', tipo: 'text', label: 'Partita Iva', placeHolder: 'Partita Iva', validations: [{ nome: 'partitaIva', msgErrore: 'Partita Iva non valida' }, { nome: 'requiredIf', campoIfNot: 'codiceFiscale', msgErrore: 'Compilare almeno un campo tra partita iva e codice fiscale' }] },
      { campo: 'testMultiselect', tipo: 'multiselect', label: 'MULTISELECT', placeHolder: 'Placeholder..', valore: [2, 3], validations: [{ nome: 'required' }], dataSource: [{ codice: 1, descrizione: 'Mario Rossi' }, { codice: 2, descrizione: 'Test Test' }, { codice: 3, descrizione: 'Carlo Verdi' }, { codice: 4, descrizione: 'Carlo Verdi' }, { codice: 5, descrizione: 'Carlo Verdi' }, { codice: 6, descrizione: 'Carlo Verdi' }, { codice: 7, descrizione: 'Carlo Verdi' }, { codice: 8, descrizione: 'Carlo Verdi' }] },
      { campo: 'testSelect', tipo: 'select', label: 'SELECT', valore: 2, dataSource: [{ codice: 1, descrizione: 'Mario Rossi' }, { codice: 2, descrizione: 'Test Test' }, { codice: 3, descrizione: 'Carlo Verdi' }], optionLabel: 'Digitare Elemento..' },
      { campo: 'cap', tipo: 'text', label: 'CAP', placeHolder: 'CAP', validations: [{ nome: 'cap', msgErrore: 'Il cap deve avere 5 cifre' }, { nome: 'required' }] },
      { campo: 'privacy', tipo: 'switch', label: 'Accetta Privacy', valore: true, validations: [{ nome: 'required' }] },
      { campo: 'dataNascita', tipo: 'calendar', label: 'Data di Nascita', valore: new Date(), placeHolder: 'GG / MM / AAAA', validations: [{ nome: 'required' }] },
      { campo: 'regione', tipo: 'select', label: 'Regione', cascadeOn: 'provincia', valore: 2, cascadeField: 'regione', dataSource: [{ codice: 1, descrizione: 'Umbria' }, { codice: 2, descrizione: 'Lazio' }, { codice: 3, descrizione: 'Toscana' }], optionLabel: 'Digitare Elemento..' },
      { campo: 'provincia', tipo: 'select', label: 'Provincia', noOptionsLabel: 'Selezionare una regione', valore: 3, dataSource: [{ codice: 1, descrizione: 'Perugia', regione: 1 }, { codice: 2, descrizione: 'Terni', regione: 1 }, { codice: 3, descrizione: 'Roma', regione: 2 }], optionLabel: 'Digitare Elemento..' }] */
    // var comuni = await tabelle.getTabella('istat_comuni')

    /* if (this.context.nuovoCliente && this.context.nuovoCliente.clienteInModifica) {
      var cliente = await clienti.getCliente(this.context.nuovoCliente.clienteInModifica.codice)
      this.clienteInModifica = cliente

      this.campi = [
        { campo: 'titolareNome', model: 'contatti[0].nome', tipo: 'text', label: 'Nome', placeHolder: 'Inserire Nome..', validations: [{ nome: 'required' }] },
        { campo: 'titolareCognome', model: 'contatti[0].cognome', tipo: 'text', label: 'Cognome', placeHolder: 'Inserire Cognome..', validations: [{ nome: 'required' }] },
        { campo: 'dataNascita', tipo: 'calendar', label: 'Data di Nascita', placeHolder: 'GG / MM / AAAA', validations: [{ nome: 'required' }] },
        { campo: 'ragioneSociale', model: 'ragioneSociale', tipo: 'text', label: 'Ragione Sociale', placeHolder: 'Inserire Ragione Sociale..', validations: [{ nome: 'required' }] },
        { campo: 'provincia', model: 'recapiti[0].provincia', tipo: 'text', label: 'Provincia', placeHolder: 'Inserire Provincia..', validations: [{ nome: 'required' }] },
        { campo: 'comune', model: 'recapiti[0].comune', tipo: 'text', label: 'Comune', placeHolder: 'Inserire Comune..', validations: [{ nome: 'required' }] },
        { campo: 'indirizzo', model: 'recapiti[0].indirizzo', tipo: 'text', label: 'Indirizzo', placeHolder: 'Inserire Indirizzo..', validations: [] },
        { campo: 'telefono', model: 'recapiti[0].telefono', tipo: 'text', label: 'Telefono', placeHolder: 'Inserire Telefono..', validations: [] },
        { campo: 'cellulare', model: 'recapiti[0].cellulare', tipo: 'text', label: 'Cellulare', placeHolder: 'Inserire Cellulare..', validations: [] },
        { campo: 'email', model: 'recapiti[0].email', tipo: 'text', label: 'Email', placeHolder: 'Inserire Email..', validations: [{ nome: 'email', msgErrore: 'Email non valida' }] },
        { campo: 'pec', model: 'recapiti[0].pec', tipo: 'text', label: 'Pec', placeHolder: 'Inserire Pec..', validations: [{ nome: 'email', msgErrore: 'Pec non valida' }] },
        { campo: 'naturaGiuridica', model: 'amministrativi.naturaGiuridica', tipo: 'select', label: 'Natura Giuridica', dataSource: 'clienti_nature_giuridiche.natureGiuridiche', placeHolder: 'Digitare per filtrare..' },
        { campo: 'codiceFiscale', model: 'amministrativi.codiceFiscale', tipo: 'text', label: 'Codice Fiscale', placeHolder: 'Codice Fiscale', validations: [{ nome: 'codiceFiscale', msgErrore: 'Codice fiscale non è valido' }, { nome: 'requiredIf', campoIfNot: 'partitaIva', msgErrore: 'Compilare almeno un campo tra partita iva e codice fiscale' }] },
        { campo: 'partitaIva', model: 'amministrativi.partitaIva', tipo: 'text', label: 'Partita Iva', placeHolder: 'Partita Iva', validations: [{ nome: 'partitaIva', msgErrore: 'Partita Iva non valida' }, { nome: 'requiredIf', campoIfNot: 'codiceFiscale', msgErrore: 'Compilare almeno un campo tra partita iva e codice fiscale' }] },
        { campo: 'cap', model: 'amministrativi.cap', tipo: 'text', label: 'CAP', placeHolder: 'CAP', validations: [{ nome: 'cap', msgErrore: 'Il cap deve avere 5 cifre' }, { nome: 'required' }] }
      ]

      for (var j = 0; j < this.campi.length; j++) {
        var c = this.campi[j]
        if (c.model) {
          var val = dot.pick(c.model, this.clienteInModifica)
          c.valore = val
        }
      }
    } else {
      /* this.campi = [
        { campo: 'test', tipo: 'numeric', label: 'Numeric', numeric: { float: true }, placeHolder: 'Numeric', validations: [{ nome: 'required' }] },
        { campo: 'titolareNome', model: 'contatti[0].nome', tipo: 'text', label: 'Nome', placeHolder: 'Inserire Nome..', validations: [{ nome: 'required' }] },
        { campo: 'titolareCognome', model: 'contatti[0].cognome', tipo: 'text', label: 'Cognome', placeHolder: 'Inserire Cognome..', validations: [{ nome: 'required' }] },
        { campo: 'dataNascita', tipo: 'calendar', label: 'Data di Nascita', placeHolder: 'GG / MM / AAAA', validations: [{ nome: 'required' }] },
        { campo: 'ragioneSociale', model: 'ragioneSociale', tipo: 'text', label: 'Ragione Sociale', placeHolder: 'Inserire Ragione Sociale..', validations: [{ nome: 'required' }] },
        { campo: 'provincia', model: 'recapiti[0].provincia', tipo: 'text', label: 'Provincia', placeHolder: 'Inserire Provincia..', validations: [{ nome: 'required' }] },
        { campo: 'comune', model: 'recapiti[0].comune', tipo: 'text', label: 'Comune', placeHolder: 'Inserire Comune..', validations: [{ nome: 'required' }] },
        { campo: 'indirizzo', model: 'recapiti[0].indirizzo', tipo: 'text', label: 'Indirizzo', placeHolder: 'Inserire Indirizzo..', validations: [] },
        { campo: 'telefono', model: 'recapiti[0].telefono', tipo: 'text', label: 'Telefono', placeHolder: 'Inserire Telefono..', validations: [] },
        { campo: 'cellulare', model: 'recapiti[0].cellulare', tipo: 'text', label: 'Cellulare', placeHolder: 'Inserire Cellulare..', validations: [] },
        { campo: 'email', model: 'recapiti[0].email', tipo: 'text', label: 'Email', placeHolder: 'Inserire Email..', validations: [{ nome: 'email', msgErrore: 'Email non valida' }] },
        { campo: 'pec', model: 'recapiti[0].pec', tipo: 'text', label: 'Pec', placeHolder: 'Inserire Pec..', validations: [{ nome: 'email', msgErrore: 'Pec non valida' }] },
        { campo: 'naturaGiuridica', model: 'amministrativi.naturaGiuridica', tipo: 'select', label: 'Natura Giuridica', dataSource: 'clienti_nature_giuridiche.natureGiuridiche', placeHolder: 'Digitare per filtrare..' },
        { campo: 'codiceFiscale', model: 'amministrativi.codiceFiscale', tipo: 'text', label: 'Codice Fiscale', placeHolder: 'Codice Fiscale', validations: [{ nome: 'codiceFiscale', msgErrore: 'Codice fiscale non è valido' }, { nome: 'requiredIf', campoIfNot: 'partitaIva', msgErrore: 'Compilare almeno un campo tra partita iva e codice fiscale' }] },
        { campo: 'partitaIva', model: 'amministrativi.partitaIva', tipo: 'text', label: 'Partita Iva', placeHolder: 'Partita Iva', validations: [{ nome: 'partitaIva', msgErrore: 'Partita Iva non valida' }, { nome: 'requiredIf', campoIfNot: 'codiceFiscale', msgErrore: 'Compilare almeno un campo tra partita iva e codice fiscale' }] },
        { campo: 'cap', model: 'amministrativi.cap', tipo: 'text', label: 'CAP', placeHolder: 'CAP', validations: [{ nome: 'cap', msgErrore: 'Il cap deve avere 5 cifre' }, { nome: 'required' }] }
      ]
    } */
  },
  async mounted() {
    const configModale = await configurazioni.getConfigurazione('display_elementi', 'elementi')
    if (configModale && configModale.clienti) {
      if (this.context.nuovoCliente && this.context.nuovoCliente.clienteInModifica) {
        const campi = configModale.clienti.modifica ? configModale.clienti.modifica.campi || [] : []
        this.config = configModale.clienti.modifica ? configModale.clienti.modifica.modale || {} : {}

        var cliente = await clienti.getCliente(this.context.nuovoCliente.clienteInModifica.codice)
        this.clienteInModifica = cliente

        // prepara dati iniziali
        for (var i = 0; i < campi.length; i++) {
          var c = campi[i]
          if (c.model) {
            var val = dot.pick(c.model, this.clienteInModifica)
            c.valore = val
          }
        }
        this.campi = campi
      } else {
        this.campi = configModale.clienti.nuovo ? configModale.clienti.nuovo.campi || [] : []
        this.config = configModale.clienti.nuovo ? configModale.clienti.nuovo.modale || {} : {}
      }
    }
  },
  computed: {
    titoloModale() {
      return this.clienteInModifica ? 'Modifica cliente: ' + this.clienteInModifica.ragioneSociale : 'Nuovo cliente'
    }
  },
  methods: {
    onKeyPress(button) {
      this.button = button
      if (!this.insertNuovoCliente && !this.modificaDatiCliente && button === '{esci}') {
        // this.seleziona()
      }
    },
    annulla() {
      // this.context.nuovoCliente = null
      const event = {
        type: 'EDIT_CONTEXT',
        payload: {
          nuovoCliente: null
        }
      }
      this.$emit('send-event', event)
      this.$emit('close')
    },
    async salva() {
      var form = this.$refs.form
      var err = form.valida()
      if (err && err.length) {
        return
      }
      var dati = form.getDatiDotNotation()
      console.log(dati)
      if (this.clienteInModifica) { // modifica cliente
        var datiCliente = cloneObject(this.clienteInModifica)
        _.merge(datiCliente, dati)
        console.log(datiCliente)
        const result = await clienti.modificaCliente(datiCliente)
        console.log(result)
        // // verificare se errori di creazione
        this.selezionaCliente(datiCliente)
        this.$toast('Cliente modificato con successo', { type: 'success' })
      } else { // nuovo cliente
        var codice = await this.creaCodiceCliente(dati.ragioneSociale)
        dati.codice = codice
        const result = await clienti.creaCliente(dati)
        console.log(result)
        // verificare se errori di creazione
        this.selezionaCliente(dati)
        this.$toast('Cliente creato con successo', { type: 'success' })
      }
      var self = this
      setTimeout(() => {
        self.annulla()
      }, 200)
    },
    selezionaCliente(dati) {
      var obj = {
        codice: dati.codice,
        descrizione: dati.ragioneSociale,
        label: ' ',
        titolo: dati.codice + ' - ' + dati.ragioneSociale,
        value: ''
      }
      const event = {
        type: 'SELEZIONA_CLIENTE',
        value: obj
      }
      this.$emit('send-event', event)
      this.$store.commit('clienti/SET_CLIENTE', obj)
    },
    async creaCodiceCliente(ragSoc) {
      const impostazioni = await configurazioni.getConfigurazione('backoffice', 'impostazioni')
      const tipoNumeratore = impostazioni.clienti.tipoNumeratore
      var numeratore
      if (tipoNumeratore === '_') {
        const pattern = /[^a-zA-Z0-9-]+/gi
        numeratore = ragSoc.trim().replace(/\s\s+/g, ' ').replace(pattern, '_').toLowerCase()
      } else {
        numeratore = await configurazioni.getNumeratore(tipoNumeratore, true)
      }
      return numeratore
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <slot name="header">
                  <div class="modal-title">Funzioni</div>
                </slot>
              </div>
              <div class="modal-body">
                <slot name="body">
                  <div class="mainFunzioni">
                  <tabs>
                    <tab name="Servizio" :selected="true">
                      <gruppo v-for="(gr, index) in gruppi" :key="index"
                        :context="context"
                        :zona="gr.zona"
                        :tasti="gr.tasti.filter(x => x.page === 'funzioni_servizio')"
                        @send-event="sendEvent"
                      >
                      </gruppo>
                    </tab>
                    <tab name="Sviluppo">
                      <gruppo v-for="(gr, index) in gruppi" :key="index"
                        :context="context"
                        :zona="gr.zona"
                        :tasti="gr.tasti.filter(x => x.page === 'funzioni_sviluppo')"
                        @send-event="sendEvent"
                      >
                      </gruppo>
                    </tab>
                  </tabs>
                  </div>
                </slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  default footer
                  <button class="modal-default-button" @click="$emit('close')">
                    CHIUDI
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
</template>

<script>
import Gruppo from '@/components/gruppoTasti/Gruppo.vue'
import configGruppi from '@/views/Layout_04/gruppi.json' // andrebbe fatto in funzione del layout
import Tabs from './tabs.vue'
import Tab from './tab.vue'

export default {
  props: {
    context: Object
  },
  components: {
    Gruppo,
    Tabs,
    Tab
  },
  data() {
    return {

    }
  },
  methods: {
    sendEvent(event) {
      this.$emit('send-event', event)
    }
  },
  computed: {
    gruppi() {
      return configGruppi.filter(x => x.zona.startsWith('funzioni')) // utilizzare context.pageFunzioni se tab su funzioni
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../modale.scss";
  @import "./style.scss";
  @import "./layout.scss";
</style>

const normalize = (cf) => {
  return cf.replace(/\s/g, '')
}

const format = (cf) => {
  return normalize(cf)
}

const validate = (pi) => {
  pi = normalize(pi)

  if (pi.length === 0) {
    return 'Empty.'
  }
  // Non c'è controllo su partite iva straniere
  if ((pi[0].toUpperCase() >= 'A' && pi[0].toUpperCase() <= 'Z') && !pi.startsWith('IT')) {
    return null
  }
  if (pi.startsWith('IT')) {
    pi = pi.substring(2)
  }
  if (pi.length !== 11) {
    return 'Invalid length.'
  }
  if (!/^[0-9]{11}$/.test(pi)) {
    return 'Invalid characters.'
  }
  var s = 0
  for (var i = 0; i < 11; i++) {
    var n = pi.charCodeAt(i) - '0'.charCodeAt(0)
    if ((i & 1) === 1) {
      n *= 2
      if (n > 9) {
        n -= 9
      }
    }
    s += n
  }
  if (s % 10 !== 0) {
    return 'Invalid checksum.'
  }
  return null
}

export default {
  validate,
  format
}

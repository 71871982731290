export const testCardType = {
  computed: {
    isSconto() {
      return this.card.generale && this.card.generale.sconto
    },
    isListino() {
      return this.card.generale && this.card.generale.listino
    },
    isPrepagata() {
      return this.card.buono && this.card.buono.abilita
    },
    isRaccoltaPunti() {
      return this.card.raccoltaPunti && this.card.raccoltaPunti.abilita
    },
    isAccumuloCredito() {
      return this.card.accumuloCredito && this.card.accumuloCredito.abilita
    },
    isAbbonamenti() {
      if (this.card.abbonamenti) {
        return true
      } else {
        return false
      }
      // return this.card.abbonamenti ? true : false
    }
  },
  methods: {
    isItemSconto(item) {
      return item.generale && item.generale.sconto
    },
    isItemListino(item) {
      return item.generale && item.generale.listino
    },
    isItemPrepagata(item) {
      return item.buono && item.buono.importo
    },
    isItemRaccoltaPunti(item) {
      return item.raccoltaPunti && item.raccoltaPunti.abilita
    },
    isItemAccumuloCredito(item) {
      return item.accumuloCredito && item.accumuloCredito.abilita
    },
    isItemAbbonamenti(item) {
      return item.abbonamenti
    },
    tipologia(item) {
      const tipologie = []
      if (this.isItemSconto(item)) tipologie.push('Sconto')
      if (this.isItemListino(item)) tipologie.push('Listino')
      if (this.isItemPrepagata(item)) tipologie.push('Prepagata')
      if (this.isItemRaccoltaPunti(item)) tipologie.push('Raccolta Punti')
      if (this.isItemAccumuloCredito(item)) tipologie.push('Accumulo Credito')
      if (this.isItemAbbonamenti(item)) tipologie.push('Abbonamenti')
      return tipologie.join(', ')
    }
  }
}

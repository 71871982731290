<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" :campi="campi"
    :config="config"
  ></modale-form>
</template>

<script>
import ModaleForm from '@/components/modale/ModaleForm'
import prodotti from '@/services/prodottiService.js'
import configurazioni from '@/services/configurazioniService.js'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      titoloModale: 'Cerca Prodotto',
      categoria: null,
      campi: [],
      config: { hideBtnSalva: true, hideScrollbar: true, showBtnSposta: true, clickableBackground: true }
    }
  },
  async created() {
    const configModale = await configurazioni.getConfigurazione('display_elementi', 'elementi')
    const categorie = await configurazioni.getConfigurazione('prodotti_categorie', 'categorie')

    if (configModale && configModale.prodotti && configModale.prodotti.ricerca) {
      // this.config = configModale.prodotti.ricerca ? configModale.prodotti.ricerca.modale || {} : this.config
    }

    this.campi = [
      { campo: 'categoria', model: 'categoria', tipo: 'select', label: null, dataSource: categorie, placeHolder: 'Categoria', onUpdateValue: this.onUpdateCategoria },
      {
        campo: 'ricerca',
        tipo: 'ricerca',
        label: 'Cerca Prodotto',
        placeHolder: 'Prodotto',
        validations: [],
        ricerca: {
          ricercaMethod: this.getElencoProdottiMethod(),
          descrizione: this.getDescrizioneElementoRicerca(),
          bottoni: [{
            testo: 'Seleziona',
            onClick: this.getSelezionaProdottoBtnClicked()
          }, {
            testo: 'Aggiungi',
            onClick: this.getAggiungiProdottoBtnClicked()
          }]
        }
      }]
  },
  methods: {
    annulla() {
      var ev = {
        type: 'EDIT_CONTEXT',
        reference: {
          source: 'configuratoreTasti',
          dest: 'debug.configuratoreTasti'
        },
        payload: {
          configuratoreTasti: null
        }
      }
      this.$emit('send-event', ev)

      this.$emit('close')
    },
    getElencoProdottiMethod() {
      var method = async (testo) => {
        var result
        if (this.categoria) {
          const filtri = {
            testo: testo || ''
          }

          filtri[this.categoria.tipo || 'categoria1'] = [this.categoria.codice]
          result = await prodotti.getFilteredProducts(filtri)
          result = (result && result.rows.map(x => x.doc))
        } else {
          result = await prodotti.searchProdotti(testo)
          result = result ? result.docs || [] : []
        }
        return result
      }
      return method
    },
    getDescrizioneElementoRicerca() {
      return (item) => {
        return item.codice + ' - ' + item.descrizione
      }
    },
    getSelezionaProdottoBtnClicked() {
      var self = this
      return (item) => {
        this.context.debug && this.context.debug.configuratoreTasti && this.context.debug.configuratoreTasti.setProdotto(item.ricercaTC, item.descrizione || item.codice)

        setTimeout(() => {
          self.$emit('close')
        }, 100)
      }
    },
    getAggiungiProdottoBtnClicked() {
      return (item) => {
        this.context.debug && this.context.debug.configuratoreTasti &&
         this.context.debug.configuratoreTasti.aggiungiProdotto(item.ricercaTC, item.descrizione || item.codice)
      }
    },
    onUpdateCategoria(cat) {
      this.categoria = cat || null
      var form = this.$refs.form
      var comp = form.getComponentByName('ricerca')
      comp && comp.callMethod('cerca')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

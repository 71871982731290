<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" :campi="campi"
    :config="config"
  ></modale-form>
</template>

<script>
import ModaleForm from '../ModaleForm'
import prodotti from '@/services/prodottiService.js'
import configurazioni from '@/services/configurazioniService.js'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      titoloModale: 'Cerca Prodotto',
      campi: [],
      config: { hideBtnSalva: true, hideScrollbar: true }
    }
  },
  async created() {
    const configModale = await configurazioni.getConfigurazione('display_elementi', 'elementi')
    // const categorie = await configurazioni.getConfigurazione('prodotti_categorie', 'categorie')

    if (configModale && configModale.prodotti && configModale.prodotti.ricerca) {
      this.config = configModale.prodotti.ricerca ? configModale.prodotti.ricerca.modale || {} : this.config
    }

    this.campi = [{
      campo: 'test',
      tipo: 'ricerca',
      label: 'Cerca Prodotto',
      placeHolder: 'Prodotto',
      validations: [],
      ricerca: {
        ricercaMethod: this.getElencoProdottiMethod(),
        descrizione: this.getDescrizioneElementoRicerca(),
        bottoni: [{
          testo: 'Modifica',
          onClick: this.getModificaProdottoBtnClicked()
        }, {
          testo: 'Seleziona',
          onClick: this.getSelezionaProdottoBtnClicked()
        }]
      }
    }]
  },
  methods: {
    annulla() {
      this.$emit('close')
    },
    getElencoProdottiMethod() {
      var method = async (testo) => {
        var result = await prodotti.searchProdotti(testo)
        return result ? result.docs || [] : []
      }
      return method
    },
    getDescrizioneElementoRicerca() {
      return (item) => {
        return item.codice + ' - ' + item.descrizione
      }
    },
    getModificaProdottoBtnClicked() {
      var self = this
      return (item) => {
        var event = {
          type: 'APRI_MODALE',
          value: 'NuovoArticolo',
          payload: {
            nuovoProdotto: { prodottoInModifica: item }
          }
        }
        self.$emit('close')
        self.$emit('send-event', event)
      }
    },
    getSelezionaProdottoBtnClicked() {
      var self = this
      return (item) => {
        const prodottoItem = self.buildProdottoItem(item)
        self.$store.commit('prodotti/SET_PRODOTTO', prodottoItem)

        const event = {
          type: 'PRODOTTO',
          value: {
            code: prodottoItem.codice,
            tipoAliquota: 'banco'
          }
        }
        self.$emit('send-event', event)

        setTimeout(() => {
          self.$emit('close')
        }, 100)
      }
    },
    buildProdottoItem(item) {
      const label = []
      if (item.nome) label.push(item.nome)
      if (item.varianti && item.varianti.variante1) label.push(item.varianti.variante1.descrizione)
      if (item.varianti && item.varianti.variante2) label.push(item.varianti.variante2.descrizione)
      if (item.statistici && item.statistici.categoria1) label.push(item.statistici.categoria1.descrizione)
      if (item.statistici && item.statistici.categoria2) label.push(item.statistici.categoria2.descrizione)
      if (item.statistici && item.statistici.categoria3) label.push(item.statistici.categoria3.descrizione)
      if (item.statistici && item.statistici.categoria4) label.push(item.statistici.categoria4.descrizione)
      const fornitore = item.ultimoAcquisto && item.ultimoAcquisto.fornitore ? item.ultimoAcquisto.fornitore : ''
      let fornitoreDescrizione = ''
      if (fornitore) {
        const fr = item.fornitori.find(x => x.codice === fornitore)
        if (fr) {
          fornitoreDescrizione = fr.descrizione
        }
      }
      // TODO: Se necessario richiamare prodotti da ids come su etichette da selezione... Su questa vista non sono necessari tutti i campi ...
      return {
        // codice: item.codice,
        codice: (item.ricercaTC || ''), // .toUpperCase(), // TODO: lasciare caratteri originali...
        descrizione: item.descrizione,
        prezzoAcquisto: item.ultimoAcquisto && item.ultimoAcquisto.prezzo ? item.ultimoAcquisto.prezzo : item.magazzino && item.magazzino.prezzoAcquisto ? item.magazzino.prezzoAcquisto : '',
        prezzoVendita: item.prezzo && item.prezzo.L1 ? item.prezzo.L1.base : '', // TODO: gestire un prezzo unitario oppure nome listino base su configurazioni ??
        prezzo2: item.prezzo && item.prezzo.L2 ? item.prezzo.L2.base : '',
        prezzo2Sconto: item.prezzo && item.prezzo.L2 ? item.prezzo.L2.sconto : '',
        unitaMisura: item.magazzino && item.magazzino.unitaMisura ? item.magazzino.unitaMisura : '',
        unitaMisuraAmmesse: item.magazzino && item.magazzino.unitaMisuraAmmesse ? item.magazzino.unitaMisuraAmmesse : [],
        fornitore,
        fornitoreDescrizione,
        titolo: `${(item.ricercaTC || '').toUpperCase()} - ${item.descrizione}`,
        label: item.codice + ' - ' + label.join(' - '),
        value: `${item.magazzino ? item.magazzino.giacenza || '---' : '---'}`,
        barcode: item.ricercaBarcode || '',
        codiceProdotto: item.codice,
        variante1: item.varianti && item.varianti.variante1 && item.varianti.variante1.descrizione,
        variante2: item.varianti && item.varianti.variante2 && item.varianti.variante2.descrizione
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div v-if="showEditor">
    <v-jsoneditor v-model="jsonWeb" :options="optionsWeb" :plus="true" height="97%" @error="onErrorWeb" ref="editor"/>
    <van-button v-if="!isReadOnly" type="primary" @click="salvaJson" class="jsonEditorSalva">Salva</van-button>
    <van-button v-if="!isReadOnly" type="warning" @click="chiudi" class="jsonEditorChiudi">Chiudi</van-button>
  </div>
</template>

<script>
import VJsoneditor from 'v-jsoneditor'

export default {
  components: {
    'v-jsoneditor': VJsoneditor
  },
  data() {
    return {
      jsonWeb: '',
      jsonMode: 'view',
      optionsWeb: {
        mode: this.jsonMode,
        modes: ['view', 'form', 'code'],
        search: false,
        enableSort: false,
        enableTransform: false
      }
    }
  },
  mounted() {
    this.jsonWeb = this.jsonContent || ''
  },
  watch: {
    jsonContent(value) {
      this.jsonWeb = value
    }
  },
  computed: {
    showEditor() {
      return this.$store.getters['settings/showJsonEditor']
    },
    jsonContent() {
      return this.$store.getters['settings/jsonContent']
    },
    inModifica() {
      return this.$store.getters['settings/inModifica']
    },
    isReadOnly() {
      return this.$store.getters['settings/readOnly']
    }
  },
  methods: {
    changeMode(mode) {
      const jEd = this.$refs.editor
      this.jsonMode = mode
      jEd.editor.setMode(mode)
    },
    async salvaJson() {
      this.$dialog.confirm({
        title: 'Confermi salvataggio',
        message: 'Impostazioni configurazione: ' + this.inModifica,
        cancelButtonText: 'Annulla',
        confirmButtonText: 'Salva'
      }).then(async () => {
        this.$store.commit('settings/SET_JSON_EDITOR', this.jsonWeb)
        this.$store.commit('settings/SET_SALVATAGGIO_RICHIESTO', true)
        this.$store.commit('settings/HIDE_JSON_EDITOR')
      }).catch(() => {
      })
    },
    onErrorWeb() {

    },
    chiudi() {
      this.$store.commit('settings/HIDE_JSON_EDITOR')
    }
  }
}
</script>

<style lang="scss" scoped>
  .swipe-cell-button {
    height: 100%;
  }
</style>

<style lang="scss">

  .jsoneditor {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 16px;
    background-color: white;
    width: auto;
  }

  .jsonEditorSalva {
    position: relative;
    top: -75px; /* Attenzione a quando va in modalità code */
    right: 45px; /* Attenzione a quando va in modalità code */
    float: right;
  }

  .jsonEditorChiudi {
    position: relative;
    top: -75px; /* Attenzione a quando va in modalità code */
    right: 55px; /* Attenzione a quando va in modalità code */
    float: right;
  }
</style>

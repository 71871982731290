<template>
  <div>
    <van-search
      v-model="cliente"
      show-action
      name="cliente"
      ref="cliente"
      label="Cliente"
      placeholder="Cliente"
      :error-message="cliente ? descrizioneCliente : ''"
      @search="onSearchCliente"
      @clear="onClearCliente"
      @focus="onFocus"
    >
      <template #action>
        <div @click="onSearchCliente">Cerca</div>
      </template>
    </van-search>
  </div>
</template>

<script>
import _ from 'lodash'
import clienti from '@/services/clientiService.js'

export default {
  props: {
    context: Object
  },
  data() {
    return {
      cliente: '',
      descrizioneCliente: '',
      clienteItem: null,
      elencoClienti: [],
      activeTab: 'ricerca',
      activeParent: 'clienti'
    }
  },
  created() {
    this.debounceInput = _.debounce(this.getElencoClienti, 500)
  },
  mounted() {
    this.onOpen()
  },
  computed: {
    kbInput() {
      const res = this.$store.getters.popupKeyboardInput
      console.log('clienti kbInput', res.tab, res.parent)
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.count : 0
    },
    listResult() {
      return this.$store.getters.listResult
    }
  },
  watch: {
    kbInput(value) {
      console.log('kbInput', value)
      this.cliente = value
      this.handleKey(value)
    },
    kbKeyPress(value) {
      if (value === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
        this.tornaAllaCassa()
      } else {
        this.handleKey(this.kbInput)
      }
    },
    kbCounter(value) {
      console.log('kbCounter', value, this.kbInput)
      if (this.kbKeyPress === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
        this.tornaAllaCassa()
      } else {
        this.handleKey(this.kbInput)
      }
    },
    listResult(payload) {
      console.log('ricerca listResult', payload.parent, this.activeParent, payload.tab, this.activeTab)
      if (payload.parent !== this.activeParent || payload.tab !== this.activeTab) return
      const item = payload.result
      this.clienteItem = item
      this.setClienteContext()
      this.cliente = item.codice
      this.descrizioneCliente = item.descrizione
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
    }
  },
  methods: {
    handleKey(value) {
      this.debounceInput(value)
    },
    onOpen() {
      this.clearSearchInput()
      this.$refs.cliente.focus()
    },
    clearSearchInput() {
      this.cliente = ''
      this.descrizioneCliente = ''
    },
    onFocus(event) {
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      // this.$store.commit('SHOW_POPUP_KEYBOARD')
      console.log('focus cliente', this.cliente)
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', this.cliente)
      if (!this.cliente) {
        this.descrizioneCliente = ''
      }
    },
    onSearchCliente() {
      this.getElencoClienti(this.cliente)
    },
    onClearCliente() {
      this.descrizioneCliente = ''
    },
    async getElencoClienti(value) {
      if (!value) {
        this.elencoClienti = []
      } else {
        const result = await clienti.searchClienti(value)
        if (result && result.docs && result.docs.length > 0) {
          const lista = result.docs.map(item => {
            return {
              codice: item.codice,
              descrizione: item.ragioneSociale,
              titolo: `${item.codice} - ${item.ragioneSociale}`,
              label: `${item.recapiti[0].indirizzo}`,
              value: `${item.amministrativi.partitaIva}`
            }
          })
          this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
            list: lista,
            icon: 'user-o',
            classes: {
              titleClass: 'clienti-lr-title',
              labelClass: 'clienti-lr-label',
              valueClass: 'clienti-lr-value'
            }
          })
        } else {
          console.log('getElencoClienti noresult')
          this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
        }
      }
    },
    setClienteContext() {
      const event = {
        type: 'SELEZIONA_CLIENTE',
        value: this.clienteItem
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }

</style>

<template>
  <van-cell-group>
    <van-field
      readonly
      clickable
      :border="false"
      :name="name"
      :value="$v.data.$model"
      :label="elemento.label"
      :placeholder="elemento.placeholder"
      @click="showCalendar = true"
    />
    <van-calendar v-model="showCalendar" @confirm="onConfirmData" :style="getStilePopup()" :show-confirm="false" :min-date="minDate"/>
  </van-cell-group>
</template>

<script>
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  props: {
    elemento: Object,
    minDate: Date,
    name: String
  },
  data() {
    return {
      data: '',
      showCalendar: false
    }
  },
  validations: {
    data: { required }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.main-popup-report')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    onConfirmData(value) {
      // TODO: Verificare le validazioni
      this.data = moment(value).format('DD/MM/YYYY')
      this.showCalendar = false
      this.$emit('change', this.elemento.model, this.data, value)
    }
  }
}
</script>

<template>
  <div>
    <component v-for="(el, index) in modali" :key="index"
      :is="'Modale' + el"
      :name="'Modale' + el"
      :element="element"
      :context="context"
      @close="modalClose"
      @send-event="sendEventToMachine"
      @send-event-delay="sendEventToMachineWithDelay"
    >
    </component>
  </div>
</template>

<script>
import ModaleListini from './ModaleListini'
import ModalePark from './ModalePark'
import ModalePlanning from './ModalePlanning'
import ModaleFunzioni from './ModaleFunzioni'
import ModaleAppuntamento from './ModaleAppuntamento'
import ModaleAgende from './ModaleAgende'
import ModaleStampante from './ModaleStampante'
import ModaleNuovoCliente from './ModaleNuovoCliente'
import ModaleNuovoClienteFidelity from './ModaleNuovoClienteFidelity'
import ModaleDettaglioCliente from './ModaleDettaglioCliente'
import ModaleRicercaCliente from './ModaleRicercaCliente'
import ModaleRicercaArticolo from './ModaleRicercaArticolo'
import ModaleSetupTasti from '@/components/gruppoTasti/editorNew/ModaleSetupTasti'
import ModaleRicercaArticoloSetupTasti from '@/components/gruppoTasti/editorNew/ModaleRicercaArticoloSetupTasti'
import ModaleCaricaSelezione from './ModaleCaricaSelezione'
import ModaleNuovoArticolo from './ModaleNuovoArticolo'
import ModaleHelp from './ModaleHelp'
import ModaleDescrizione from './ModaleDescrizione'
import ModaleCashmatic from './ModaleCashmatic'

export default {
  components: {
    ModaleListini,
    ModalePark,
    ModalePlanning,
    ModaleFunzioni,
    ModaleAppuntamento,
    ModaleAgende,
    ModaleStampante,
    ModaleNuovoCliente,
    ModaleNuovoClienteFidelity,
    ModaleDettaglioCliente,
    ModaleRicercaCliente,
    ModaleRicercaArticolo,
    ModaleSetupTasti,
    ModaleRicercaArticoloSetupTasti,
    ModaleCaricaSelezione,
    ModaleNuovoArticolo,
    ModaleHelp,
    ModaleDescrizione,
    ModaleCashmatic
  },
  props: {
    context: Object,
    element: Object
  },
  data() {
    return {
    }
  },
  computed: {
    modali() {
      return this.context.modali
    },
    showModal() {
      return this.context.modalShow
    },
    mType() {
      return 'Modale' + this.context.modalType
    }
  },
  methods: {
    modalClose() {
      this.$emit('send-event', 'CHIUDI_MODALE')
    },
    sendEventToMachine(event) {
      this.$emit('send-event', event)
    },
    sendEventToMachineWithDelay(event) {
      // NON usato !! --> Aggiunto Payload all'evento
      this.$emit('send-event-delay', event)
    }
  }
}
</script>

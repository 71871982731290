<template>
  <van-cell-group title="Abbonamenti">
    <van-list v-for="(el, index) in abbonamenti" :key="index">
      <van-cell-group :title="'Abbonamento: ' + el.codice">
        <van-cell title="Codice" :value="el.codice"/>
        <van-cell title="Descrizione" :value="el.descrizione"/>
        <van-cell title="Quantità acquistata" :value="el.quantita"/>
        <van-cell title="Quantità utilizzata" :value="el.quantitaUsata"/>
        <van-cell title="Quantità residua" :value="el.quantitaResidua"/>
        <van-cell title="Prezzo" :value="el.prezzo"/>
        <van-cell-group title="Utilizzi abbonamento">
          <van-list>
            <van-cell
              v-for="(ut, index) in utilizzi"
              :key="index"
              :border="true"
              :title="titolo(ut)"
              :label="label(ut)"
              :value="value(ut)"
              icon="cart-o"
              size="large"
            />
          </van-list>
        </van-cell-group>
      </van-cell-group>
    </van-list>
  </van-cell-group>
</template>

<script>
export default {
  props: {
    locCard: Object,
    context: Object
  },
  data() {
    return {
    }
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    abbonamenti() {
      return this.card.abbonamenti ? this.card.abbonamenti || [] : []
    },
    dataDa() {
      return this.card && this.card.accumuloCredito.dallaData ? this.$options.filters.date(this.card.accumuloCredito.dallaData) : ''
    },
    dataA() {
      return this.card && this.card.accumuloCredito.allaData ? this.$options.filters.date(this.card.accumuloCredito.allaData) : ''
    },
    creditoTotale() {
      return this.card && this.card.accumuloCredito.totale ? (this.card.accumuloCredito.totale).toFixed(2) : ''
    },
    dataAzzeramento() {
      return this.card && this.card.accumuloCredito.dataAzzeramento ? this.$options.filters.date(this.card.accumuloCredito.dataAzzeramento) : ''
    }
  },
  methods: {
    utilizzi(item) {
      return item.utilizzi || []
    },
    titolo(item) {
      return this.$options.filters.date(item.data)
    },
    label(item) {
      return item.fiscale && item.fiscale.barcode ? `Scontrino: ${item.fiscale.barcode.documento}` : ''
    },
    value(item) {
      return item.quantita || ''
    }
  }
}
</script>

<template>
  <div class="carrello" :class="{ espanso: isEspanso, reso: isReso, recupero: isRecupero, documento: isDocumento }" :style="[isEspanso ? {width: scontrinoWidth + 'px'} : {}]" ref="scontrino" @dblclick="dblClick">
    <div class="tipoDocumento" :class="{ reso: isReso, recupero: isRecupero, documento: isDocumento }">{{ documento }}</div>
    <riga-carrello v-for="(riga, index) in righeScontrino"
      :key="index"
      :riga="riga"
      :rigaId="index"
      :rigaSelezionata="rigaSelezionata"
      :scontrinoTick="scontrinoTick"
      :reso="reso"
      :recupero="recupero"
      :isSubTotaleCalcolato="isSubTotaleCalcolato"
      :mostraPrezzo="mostraPrezzo"
      :tipoPrezzo="tipoPrezzo"
      @send-event="sendEventToParent"
      @selected="selected"
    />
  </div>
</template>

<script>
import RigaCarrello from '@/components/scontrino/RigaCarrello.vue'

export default {
  props: {
    righe: Array,
    selezione: Number,
    espanso: Boolean,
    reso: Boolean,
    recupero: Boolean,
    scontrinoTick: Number,
    tipoDocumento: String,
    titoloScontrino: String,
    isSubTotaleCalcolato: Boolean,
    mostraPrezzo: Boolean,
    tipoPrezzo: String
    // eventuale TitoloScontrino da passare (es. RESO DA SCONTRINO xxxx-yyyy, RECUPERO DA SCONTRINO xxxx-yyyy)
  },
  components: {
    RigaCarrello
  },
  data() {
    return {
      rigaSelezionata: -1,
      espandiLocale: false,
      scontrinoWidth: 0,
      righeScontrino: []
    }
  },
  mounted() {
    this.calcRigheScontrino()
  },
  computed: {
    isEspanso() {
      return this.espanso || this.espandiLocale
    },
    isReso() {
      return this.reso
    },
    isRecupero() {
      return this.recupero
    },
    isDocumento() {
      return this.tipoDocumento && this.tipoDocumento.toLowerCase() !== 'scontrino'
    },
    espansoWidth() {
      return this.$refs.scontrino.offsetWidth
    },
    documento() { // verificare se necessaria la descrizione
      if (this.titoloScontrino) {
        return `** ${this.titoloScontrino} **`
      }
      if (this.reso) {
        return '** RESO MERCE **'
      }
      if (this.recupero) {
        return '** RECUPERO SCONTRINO **'
      }
      return !this.tipoDocumento || this.tipoDocumento.toLowerCase() === 'scontrino' ? '' : `** ${this.tipoDocumento} **`
    }
  },
  watch: {
    righe(value) {
      this.calcRigheScontrino()
      this.$nextTick(() => {
        this.$refs.scontrino.scrollTop = this.$refs.scontrino.scrollHeight
      })
    },
    selezione(value) {
      if (value == null) {
        this.rigaSelezionata = -1
      }
    },
    scontrinoTick() {
      this.calcRigheScontrino()
    },
    espanso() {
      this.scontrinoWidth = this.$refs.scontrino.offsetWidth - 2 * 20
    }
  },
  methods: {
    sendEventToParent(event) {
      this.$emit('send-event', event)
    },
    selected(riga) {
      this.rigaSelezionata = riga
    },
    dblClick() {
      /*
      this.espandiLocale = !this.espandiLocale */
    },
    calcRigheScontrino() {
      const righe = []
      for (var riga of this.righe) {
        righe.push(riga)
        riga.sconto && righe.push(riga.sconto)
        riga.abbuono && righe.push(riga.abbuono)
      }
      this.righeScontrino = [...righe]
    }
  }
}
</script>

<style scoped lang="scss">
  .tipoDocumento {
    width:100%;
    text-align: center;
    font-size: 2em;
  }

  .reso {
    color: #ff0000;
  }

  .recupero {
    color: #0000ff;
  }

  .documento {
    color: #00C3ff;
  }
</style>

<template>
  <van-cell-group>
    <van-form @submit="onSubmit">

      <van-field v-model.trim="$v.codice.$model"
        name="codice"
        ref="codice"
        clearable
        label="Codice"
        placeholder="Codice"
        @focus="onFocus"
        :error-message="$v.codice.$error ? 'Valore mancante o prodotto esistente' : ''"
      />
      <van-field v-model.trim="$v.descrizione.$model"
        name="descrizione"
        ref="descrizione"
        clearable
        label="Descrizione"
        placeholder="Descrizione"
        @focus="onFocus"
        :error-message="$v.descrizione.$error ? 'Valore mancante o non valido' : ''"
      />
      <van-field v-model.trim="$v.marchio.$model"
        name="marchio"
        ref="marchio"
        clearable
        label="Marchio"
        placeholder="Marchio"
        @focus="onFocus"
        :error-message="$v.marchio.$error ? 'Valore mancante o non valido' : ''"
      />
      <van-field v-model.trim="$v.barcode.$model"
        name="barcode"
        ref="barcode"
        clearable
        label="Barcode"
        placeholder="Barcode"
        @focus="onFocus"
        :error-message="$v.barcode.$error ? 'Valore mancante o non valido' : ''">
        <template #button>
          <van-button size="small" type="primary" @click.prevent="creaBarcode">Genera barcode</van-button>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        :value="$v.tipoItem.$model"
        name="tipoItem"
        ref="tipoItem"
        label="Tipo item"
        placeholder="Tipo item"
        :error-message="$v.tipoItem.$error ? 'Valore mancante' : tipoItemDescrizione"
        :class="{ 'mostra-descrizione': !$v.tipoItem.$error}"
        @click="showTipoItem = true"
      />
      <van-popup v-model="showTipoItem" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar title="Tipo item" :columns="columnsTipoItem" @confirm="onConfirmTipoItem" @cancel="showTipoItem = false"/>
      </van-popup>

      <van-field
        readonly
        clickable
        :value="$v.tipoBene.$model"
        name="tipoBene"
        ref="tipoBene"
        label="Tipo bene"
        placeholder="Tipo bene"
        :error-message="$v.tipoBene.$error ? 'Valore mancante' : tipoBeneDescrizione"
        :class="{ 'mostra-descrizione': !$v.tipoBene.$error}"
        @click="showTipoBene = true"
      />
      <van-popup v-model="showTipoBene" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar title="Tipo bene" :columns="columnsTipoBene" @confirm="onConfirmTipoBene" @cancel="showTipoBene = false"/>
      </van-popup>

<!--       <van-field
        readonly
        clickable
        :value="$v.aliquotaIva.$model"
        name="aliquotaIva"
        ref="aliquotaIva"
        label="Aliquota Iva"
        placeholder="Aliquota Iva"
        :error-message="$v.aliquotaIva.$error ? 'Valore mancante' : aliquotaIvaDescrizione"
        :class="{ 'mostra-descrizione': !$v.aliquotaIva.$error}"
        @click="showAliquotaIva = true"
      />
      <van-popup v-model="showAliquotaIva" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar title="Aliquota Iva" :columns="columnsAliquotaIva" @confirm="onConfirmAliquotaIva" @cancel="showAliquotaIva = false"/>
      </van-popup> -->

      <van-field
        readonly
        clickable
        :value="$v.repartoFiscale.$model"
        name="repartoFiscale"
        ref="repartoFiscale"
        label="Reparto Fiscale"
        placeholder="Reparto Fiscale"
        :error-message="$v.repartoFiscale.$error ? 'Valore mancante' : repartoFiscaleDescrizione"
        :class="{ 'mostra-descrizione': !$v.repartoFiscale.$error}"
        @click="showRepartoFiscale = true"
      />
      <van-popup v-model="showRepartoFiscale" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar title="Reparto Fiscale" :columns="columnsRepartoFiscale" @confirm="onConfirmRepartoFiscale" @cancel="showRepartoFiscale = false"/>
      </van-popup>

      <van-search
        v-model="$v.fornitore.$model"
        show-action
        name="fornitore"
        ref="fornitore"
        label="Fornitore"
        placeholder="Fornitore"
        :error-message="fornitore ? fornitoreDescrizione : ''"
        @search="onSearchFornitore"
        @clear="onClearFornitore"
        @focus="onFocus"
        :class="{ 'mostra-descrizione': !$v.fornitore.$error}"
      >
        <template #action>
          <div @click="onSearchFornitore">Cerca</div>
        </template>
      </van-search>

      <van-field
        readonly
        clickable
        :value="$v.um.$model"
        name="um"
        ref="um"
        label="Unità di Misura"
        placeholder="UM"
        :error-message="$v.um.$error ? 'Valore mancante' : umDescrizione"
        :class="{ 'mostra-descrizione': !$v.um.$error}"
        @click="showUm = true"
      />
      <van-popup v-model="showUm" position="bottom" :style="getStilePopup()" visible-item-count="8">
        <van-picker show-toolbar title="Unità di Misura" :columns="columnsUm" @confirm="onConfirmUm" @cancel="showUm = false"/>
      </van-popup>

      <van-field v-model.trim="$v.peso.$model"
        name="peso"
        ref="peso"
        clearable
        label="Peso"
        placeholder="Peso"
        @focus="onFocus"
        :error-message="$v.peso.$error ? 'Valore mancante o non valido' : ''"
      />

      <van-field
        v-if="categoriaVisibile('categoria1')"
        readonly
        clickable
        :value="$v.categoria1.$model"
        name="categoria1"
        ref="categoria1"
        :label="labelCategoria('categoria1')"
        :placeholder="labelCategoria('categoria1')"
        :error-message="categoria1Descrizione"
        class="mostra-descrizione"
        @click="showCategoria1 = true"
      />
      <van-popup v-model="showCategoria1" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar :title="labelCategoria('categoria1')" :columns="columnsCategoria('categoria1')" @confirm="onConfirmCategoria1" @cancel="showCategoria1 = false"/>
      </van-popup>

      <van-field
        v-if="categoriaVisibile('categoria2')"
        readonly
        clickable
        :value="$v.categoria2.$model"
        name="categoria2"
        ref="categoria2"
        :label="labelCategoria('categoria2')"
        :placeholder="labelCategoria('categoria2')"
        :error-message="categoria2Descrizione"
        class="mostra-descrizione"
        @click="showCategoria2 = true"
      />
      <van-popup v-model="showCategoria2" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar :title="labelCategoria('categoria2')" :columns="columnsCategoria('categoria2')" @confirm="onConfirmCategoria2" @cancel="showCategoria2 = false"/>
      </van-popup>

      <van-field
        v-if="categoriaVisibile('categoria3')"
        readonly
        clickable
        :value="$v.categoria3.$model"
        name="categoria3"
        ref="categoria3"
        :label="labelCategoria('categoria3')"
        :placeholder="labelCategoria('categoria3')"
        :error-message="categoria3Descrizione"
        class="mostra-descrizione"
        @click="showCategoria3 = true"
      />
      <van-popup v-model="showCategoria3" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar :title="labelCategoria('categoria3')" :columns="columnsCategoria('categoria3')" @confirm="onConfirmCategoria3" @cancel="showCategoria3 = false"/>
      </van-popup>

      <van-field
        v-if="categoriaVisibile('categoria4')"
        readonly
        clickable
        :value="$v.categoria4.$model"
        name="categoria4"
        ref="categoria4"
        :label="labelCategoria('categoria4')"
        :placeholder="labelCategoria('categoria4')"
        :error-message="categoria4Descrizione"
        class="mostra-descrizione"
        @click="showCategoria4 = true"
      />
      <van-popup v-model="showCategoria4" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar :title="labelCategoria('categoria4')" :columns="columnsCategoria('categoria4')" @confirm="onConfirmCategoria4" @cancel="showCategoria4 = false"/>
      </van-popup>

      <van-field
        v-if="varianteVisibile('variante1')"
        readonly
        clickable
        :value="$v.variante1.$model"
        name="variante1"
        ref="variante1"
        :label="labelVariante('variante1')"
        :placeholder="labelVariante('variante1')"
        :error-message="variante1Descrizione"
        class="mostra-descrizione"
        @click="showVariante1 = true"
      />
      <van-popup v-model="showVariante1" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar :title="labelVariante('variante1')" :columns="columnsVariante('variante1')" @confirm="onConfirmVariante1" @cancel="showVariante1 = false"/>
      </van-popup>

      <van-field
        v-if="varianteVisibile('variante2')"
        readonly
        clickable
        :value="$v.variante2.$model"
        name="variante2"
        ref="variante2"
        :label="labelVariante('variante2')"
        :placeholder="labelVariante('variante2')"
        :error-message="variante2Descrizione"
        class="mostra-descrizione"
        @click="showVariante2 = true"
      />
      <van-popup v-model="showVariante2" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar :title="labelVariante('variante2')" :columns="columnsVariante('variante2')" @confirm="onConfirmVariante2" @cancel="showVariante2 = false"/>
      </van-popup>

      <van-field
        v-if="varianteVisibile('variante3')"
        readonly
        clickable
        :value="$v.variante3.$model"
        name="variante3"
        ref="variante3"
        :label="labelVariante('variante3')"
        :placeholder="labelVariante('variante3')"
        :error-message="variante3Descrizione"
        class="mostra-descrizione"
        @click="showVariante3 = true"
      />
      <van-popup v-model="showVariante3" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar :title="labelVariante('variante3')" :columns="columnsVariante('variante3')" @confirm="onConfirmVariante3" @cancel="showVariante3 = false"/>
      </van-popup>

      <van-field
        v-if="varianteVisibile('variante4')"
        readonly
        clickable
        :value="$v.variante4.$model"
        name="variante4"
        ref="variante4"
        :label="labelVariante('variante4')"
        :placeholder="labelVariante('variante4')"
        :error-message="variante4Descrizione"
        class="mostra-descrizione"
        @click="showVariante4 = true"
      />
      <van-popup v-model="showVariante4" position="bottom" visible-item-count="8" :style="getStilePopup()">
        <van-picker show-toolbar :title="labelVariante('variante4')" :columns="columnsVariante('variante4')" @confirm="onConfirmVariante4" @cancel="showVariante4 = false"/>
      </van-popup>
      <van-cell-group title="Prezzi">
        <van-field
          v-for="(listino) in listini"
          :key="listino.codice"
          v-model.trim="prezzi[listino.codice]"
          :name="listino.codice"
          :ref="listino.codice"
          type="number"
          clearable
          :label="listino.codice"
          :placeholder="listino.descrizione"
          @focus="onFocus"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit">Crea prodotto</van-button>
      </div>
    </van-form>
  </van-cell-group>
</template>

<script>
import _ from 'lodash'
// import moment from 'moment'
import errorService from '@/services/errorService'
import { validationMixin } from 'vuelidate'
// import { helpers, required, numeric, email, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { required } from 'vuelidate/lib/validators'
import EsistenzaProdotto from '../helpers/esistenzaProdotto'

import prodotti from '@/services/prodottiService.js'
import clienti from '@/services/clientiService.js'
// import tabelle from '@/services/tabelleService.js'
import configurazioni from '@/services/configurazioniService.js'
import { dynamicSortMultiple } from '@/components/helpers.js'

const valCodiceProdotto = async (value) => !(await EsistenzaProdotto.validate(value))

export default {
  mixins: [validationMixin],
  props: {
    context: Object
  },
  data() {
    return {
      showUm: false,
      showTipoItem: false,
      showTipoBene: false,
      showAliquotaIva: false,
      showRepartoFiscale: false,
      showCategoria1: false,
      showCategoria2: false,
      showCategoria3: false,
      showCategoria4: false,
      showVariante1: false,
      showVariante2: false,
      showVariante3: false,
      showVariante4: false,
      tipoItemDescrizione: '',
      tipoBeneDescrizione: '',
      aliquotaIvaDescrizione: '',
      repartoFiscaleDescrizione: '',
      umDescrizione: '',
      categoria1Descrizione: '',
      categoria2Descrizione: '',
      categoria3Descrizione: '',
      categoria4Descrizione: '',
      variante1Descrizione: '',
      variante2Descrizione: '',
      variante3Descrizione: '',
      variante4Descrizione: '',
      prezzi: {},
      tipiItem: [],
      tipiBene: [],
      aliquoteIva: [],
      repartiFiscali: [],
      categorie: [],
      labelCategorie: [],
      varianti: [],
      labelVarianti: [],
      unitaMisura: [],
      listini: [],
      codice: '',
      descrizione: '',
      marchio: '',
      tipoItem: '',
      tipoBene: '',
      // aliquotaIva: '',
      repartoFiscale: '',
      repartoFiscaleItem: null,
      fornitore: '',
      fornitoreDescrizione: '',
      peso: '',
      um: '',
      prezzo: '',
      categoria1: '',
      categoria2: '',
      categoria3: '',
      categoria4: '',
      variante1: '',
      variante2: '',
      variante3: '',
      variante4: '',
      barcode: '',
      submitStatus: null,
      activeField: 'codice',
      activeTab: 'nuovo',
      activeParent: 'prodotti'
    }
  },
  validations: {
    codice: { valCodiceProdotto },
    descrizione: { },
    marchio: { },
    tipoItem: { required },
    tipoBene: { required },
    // aliquotaIva: { required },
    repartoFiscale: { required },
    fornitore: {},
    peso: {},
    um: { required },
    prezzo: {},
    categoria1: {},
    categoria2: {},
    categoria3: {},
    categoria4: {},
    variante1: {},
    variante2: {},
    variante3: {},
    variante4: {},
    barcode: {}
  },
  directives: {
    uppercase: {
      update (el) {
        el.value = el.value.toUpperCase()
      }
    }
  },
  created() {
    this.debounceInputFornitori = _.debounce(this.getElencoFornitori, 500)
  },
  async mounted() {
    try {
      this.unitaMisura = await configurazioni.getConfigurazione('prodotti_unita_misura', 'um')
      this.tipiItem = await configurazioni.getConfigurazione('prodotti_tipi_item', 'tipi')
      this.tipiBene = await configurazioni.getConfigurazione('prodotti_tipi_bene', 'tipi')
      this.aliquoteIva = await configurazioni.getConfigurazione('prodotti_aliquote_iva', 'aliquote')
      this.repartiFiscali = await configurazioni.getConfigurazione('prodotti_reparti_fiscali', 'reparti')
      this.categorie = await configurazioni.getConfigurazione('prodotti_categorie', 'categorie')
      this.labelCategorie = await configurazioni.getConfigurazione('prodotti_categorie', 'labelCategorie')
      this.varianti = await configurazioni.getConfigurazione('prodotti_varianti', 'varianti')
      this.labelVarianti = await configurazioni.getConfigurazione('prodotti_varianti', 'labelVarianti')
      this.listini = await configurazioni.getConfigurazione('listini', 'listini')
    } catch {
      errorService.onError('Tabelle di base non trovate', 'mounted nuovo prodotto')
    }
    this.onOpen()
    this.$refs.codice.focus()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.key : ''
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.tab === this.activeTab && res.parent === this.activeParent ? res.count : 0
    },
    columnsTipoItem() {
      const v = this.tipiItem
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    columnsTipoBene() {
      const v = this.tipiBene
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    /*     columnsAliquotaIva() {
      return this.aliquoteIva.map(el => el.descrizione).sort()
    }, */
    columnsRepartoFiscale() {
      const v = this.repartiFiscali
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    columnsUm() {
      const v = this.unitaMisura
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    listResult() {
      return this.$store.getters.listResult
    }
  },
  watch: {
    kbInput(value) {
      const variabile = this.activeField
      const valore = String(value)
      this[variabile] = valore
      this.handleKey(valore)
    },
    kbKeyPress(value) {
      if (value === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    },
    kbCounter(value) {
      if (this.kbKeyPress === '{esci}') {
        // this.$store.commit('HIDE_POPUP_KEYBOARD')
      } else {
        this.handleKey(this.kbInput)
      }
    },
    listResult(payload) {
      console.log('prodotti', payload.parent, this.activeParent, payload.tab, this.activeTab)
      if (payload.parent !== this.activeParent || payload.tab !== this.activeTab) return
      const item = payload.result
      console.log('listResult prodotti', this.activeField, item)
      switch (this.activeField) {
        case 'fornitore':
          this.fornitore = item.codice
          this.fornitoreDescrizione = item.descrizione
          break
        default:
          break
      }
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
    }
  },
  methods: {
    columnsCategoria(codice) {
      const v = this.categorie.filter(x => x.tipo === codice)
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    labelCategoria(codice) {
      const lbl = this.labelCategorie.find(x => x.codice === codice)
      return (lbl && lbl.descrizione) || ''
    },
    categoriaVisibile(codice) {
      const lbl = this.labelCategorie.find(x => x.codice === codice)
      return lbl && lbl.visible
    },
    descrizioneCategoria(codice) {
      const cat = this.categorie.find(x => x.codice === codice)
      return (cat && cat.descrizione) || ''
    },
    columnsVariante(codice) {
      const v = this.varianti.filter(x => x.tipo === codice)
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    labelVariante(codice) {
      const lbl = this.labelVarianti.find(x => x.codice === codice)
      return (lbl && lbl.descrizione) || ''
    },
    varianteVisibile(codice) {
      const lbl = this.labelVarianti.find(x => x.codice === codice)
      return lbl && lbl.visible
    },
    descrizioneVariante(codice) {
      const vr = this.varianti.find(x => x.codice === codice)
      return (vr && vr.descrizione) || ''
    },
    getStilePopup() {
      var cont = document.querySelector('.tab-popup-funzioni')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    handleKey(valore) {
      switch (this.activeField) {
        case 'fornitore':
          this.debounceInputFornitori(valore)
          break
        default:
          break
      }
    },
    onConfirmUm(value) {
      this.showUm = false
      const um = this.unitaMisura.find(x => x.descrizione === value)
      this.um = um.codice
      this.umDescrizione = um.descrizione
    },
    onConfirmTipoItem(value) {
      this.showTipoItem = false
      const tipoItem = this.tipiItem.find(x => x.descrizione === value)
      this.tipoItem = tipoItem.codice
      this.tipoItemDescrizione = tipoItem.descrizione
    },
    onConfirmTipoBene(value) {
      this.showTipoBene = false
      const tipoBene = this.tipiBene.find(x => x.descrizione === value)
      this.tipoBene = tipoBene.codice
      this.tipoBeneDescrizione = tipoBene.descrizione
    },
    onConfirmRepartoFiscale(value) {
      this.showRepartoFiscale = false
      const repartoFiscale = this.repartiFiscali.find(x => x.descrizione === value)
      this.repartoFiscale = repartoFiscale.codice
      this.repartoFiscaleDescrizione = repartoFiscale.descrizione
      this.repartoFiscaleItem = repartoFiscale
    },
    onConfirmCategoria1(value) {
      this.showCategoria1 = false
      const categoria = this.categorie.find(x => x.descrizione === value)
      this.categoria1 = categoria.codice
      this.categoria1Descrizione = categoria.descrizione
    },
    onConfirmCategoria2(value) {
      this.showCategoria2 = false
      const categoria = this.categorie.find(x => x.descrizione === value)
      this.categoria2 = categoria.codice
      this.categoria2Descrizione = categoria.descrizione
    },
    onConfirmCategoria3(value) {
      this.showCategoria3 = false
      const categoria = this.categorie.find(x => x.descrizione === value)
      this.categoria3 = categoria.codice
      this.categoria3Descrizione = categoria.descrizione
    },
    onConfirmCategoria4(value) {
      this.showCategoria4 = false
      const categoria = this.categorie.find(x => x.descrizione === value)
      this.categoria4 = categoria.codice
      this.categoria4Descrizione = categoria.descrizione
    },
    onConfirmVariante1(value) {
      this.showVariante1 = false
      const variante = this.varianti.find(x => x.descrizione === value)
      this.variante1 = variante.codice
      this.variante1Descrizione = variante.descrizione
    },
    onConfirmVariante2(value) {
      this.showVariante2 = false
      const variante = this.varianti.find(x => x.descrizione === value)
      this.variante2 = variante.codice
      this.variante2Descrizione = variante.descrizione
    },
    onConfirmVariante3(value) {
      this.showVariante3 = false
      const variante = this.varianti.find(x => x.descrizione === value)
      this.variante3 = variante.codice
      this.variante3Descrizione = variante.descrizione
    },
    onConfirmVariante4(value) {
      this.showVariante4 = false
      const variante = this.varianti.find(x => x.descrizione === value)
      this.variante4 = variante.codice
      this.variante4Descrizione = variante.descrizione
    },
    onSearchProdotto() {
      this.getElencoProdotti(this.prodotto)
    },
    onSearchCliente() {
      this.getElencoClienti(this.cliente)
    },
    onSearchFornitore() {
      this.getElencoFornitori(this.fornitore)
    },
    onClearProdotto() {
      this.prodottoDescrizione = ''
    },
    onClearCliente() {
      this.clienteDescrizione = ''
    },
    onClearFornitore() {
      this.fornitoreDescrizione = ''
    },
    async getElencoFornitori(value) {
      if (!value) {
        this.elencoFornitori = []
      } else {
        const result = await clienti.searchFornitori(value)
        if (result && result.docs && result.docs.length > 0) {
          const lista = result.docs.map(item => {
            return {
              codice: item.codice,
              descrizione: item.ragioneSociale,
              titolo: `${item.codice} - ${item.ragioneSociale}`,
              label: `${item.recapiti[0].indirizzo}`,
              value: `${item.amministrativi.partitaIva}`
            }
          })
          this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
            list: lista,
            icon: 'user-o',
            classes: {
              titleClass: 'fornitori-lr-title',
              labelClass: 'fornitori-lr-label',
              valueClass: 'fornitori-lr-value'
            }
          })
        } else {
          console.log('getElencoFornitori noresult')
          this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
        }
      }
    },
    onFocus(event) {
      console.log('onfocus')
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      // this.$store.commit('SHOW_POPUP_KEYBOARD')
      this.$v.$touch()
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
      // this.prodottoDescrizione = this.prodotto ? this.prodottoDescrizione : ''
      // this.clienteDescrizione = this.cliente ? this.clienteDescrizione : ''
      this.fornitoreDescrizione = this.fornitore ? this.fornitoreDescrizione : ''
    },
    async onSubmit(values) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast('Sono presenti alcuni errori, verificare i dati inseriti', { type: 'fail', duration: 20000 })
      } else {
        const prodotto = await this.buildProdotto()
        await prodotti.creaProdotto(prodotto)
        this.$toast('Prodotto creato con successo', { type: 'success' })

        this.clearFields()
      }
    },
    onOpen() {
      this.clearSearchInput()
      this.storeToLocal()
    },
    clearSearchInput() {
      // this.prodotto = ''
      // this.cliente = ''
      this.fornitore = ''
      // this.prodottoDescrizione = ''
      // this.clienteDescrizione = ''
      this.fornitoreDescrizione = ''
    },
    clearFields() {
      this.tipoItemDescrizione = ''
      this.tipoBeneDescrizione = ''
      this.aliquotaIvaDescrizione = ''
      this.repartoFiscaleDescrizione = ''
      this.umDescrizione = ''
      this.categoria1Descrizione = ''
      this.categoria2Descrizione = ''
      this.categoria3Descrizione = ''
      this.categoria4Descrizione = ''
      this.variante1Descrizione = ''
      this.variante2Descrizione = ''
      this.variante3Descrizione = ''
      this.variante4Descrizione = ''
      this.codice = ''
      this.descrizione = ''
      this.marchio = ''
      this.tipoItem = ''
      this.tipoBene = ''
      // this.aliquotaIva = ''
      this.repartoFiscale = ''
      this.fornitore = ''
      this.fornitoreDescrizione = ''
      this.peso = ''
      this.um = ''
      this.prezzo = ''
      this.categoria1 = ''
      this.categoria2 = ''
      this.categoria3 = ''
      this.categoria4 = ''
      this.variante1 = ''
      this.variante2 = ''
      this.variante3 = ''
      this.variante4 = ''
      this.barcode = ''
      this.prezzi = {}
      this.$v.$reset()
    },
    storeToLocal() {
      const prodotto = this.$store.getters['prodotti/prodotto']
      if (prodotto) {
        this.magazzino = prodotto.magazzino
        this.data = prodotto.data
        this.causale = prodotto.causale
        this.prodotto = prodotto.prodotto.codice
        this.cliente = prodotto.anagrafica.cliente
        this.fornitore = prodotto.anagrafica.fornitore
      }
    },
    async buildProdotto() {
      const pr = await prodotti.getModello('prodotto')
      pr.codice = this.codice
      pr.descrizione = this.descrizione
      pr.marchio = this.marchio
      pr.tipoItem = this.tipoItem
      pr.tipoBene = this.tipoBene

      const rF = this.repartiFiscali.find(x => x.codice === this.repartoFiscale)
      if (rF) {
        // pr.datiFiscali.reparto = rF.codice
        pr.datiFiscali.reparto = {
          codice: rF.codice,
          descrizione: rF.descrizione,
          stampante: rF.stampante.numero
        }
        // Teoricamente l'iva nel prodotto non serve perchè è legata al reparto fiscale, però nel programma viene utilizzata --> la inserisco.
        // TODO: Verificare se togliere (attenzione se, nel tempo, cambio associazione tra reparto fiscale e iva, si perde lo storico)
        const aliquotaIva = this.aliquoteIva.find(x => x.codice === rF.codiceIva)
        if (aliquotaIva) {
          pr.datiFiscali.aliquoteIva = pr.datiFiscali.aliquoteIva.filter(x => x.codice !== aliquotaIva.codice)
          const iva = {
            codice: aliquotaIva.codice,
            valore: aliquotaIva.aliquota,
            descrizione: aliquotaIva.descrizione,
            tipoApplicazione: 'banco' // TODO: verificare come gestire i vari tipi applicazione
          }
          pr.datiFiscali.aliquoteIva.push(iva)
        }
      }
      if (this.fornitore) {
        pr.fornitori = pr.fornitori.filter(x => x.codice !== this.fornitore)
        const fornitore = {
          codice: this.fornitore,
          descrizione: this.fornitoreDescrizione
        }
        pr.fornitori.push(fornitore)
      }
      pr.magazzino.peso = this.peso
      pr.magazzino.unitaMisura = this.um
      //   this.prezzo

      if (this.categoria1) {
        const categoria = {
          codice: this.categoria1,
          descrizione: this.descrizioneCategoria(this.categoria1)
        }
        // pr.statistici.push(categoria)
        pr.statistici.categoria1 = categoria
      }
      if (this.categoria2) {
        const categoria = {
          codice: this.categoria2,
          descrizione: this.descrizioneCategoria(this.categoria2)
        }
        // pr.statistici.push(categoria)
        pr.statistici.categoria2 = categoria
      }
      if (this.categoria3) {
        const categoria = {
          codice: this.categoria3,
          descrizione: this.descrizioneCategoria(this.categoria3)
        }
        // pr.statistici.push(categoria)
        pr.statistici.categoria3 = categoria
      }
      if (this.categoria4) {
        const categoria = {
          codice: this.categoria4,
          descrizione: this.descrizioneCategoria(this.categoria4)
        }
        // pr.statistici.push(categoria)
        pr.statistici.categoria4 = categoria
      }
      if (this.variante1) {
        const variante = {
          codice: this.variante1,
          descrizione: this.descrizioneVariante(this.variante1)
        }
        // pr.varianti.push(variante)
        pr.varianti.variante1 = variante
      }
      if (this.variante2) {
        const variante = {
          codice: this.variante2,
          descrizione: this.descrizioneVariante(this.variante2)
        }
        // pr.varianti.push(variante)
        pr.varianti.variante2 = variante
      }
      if (this.variante3) {
        const variante = {
          codice: this.variante3,
          descrizione: this.descrizioneVariante(this.variante3)
        }
        // pr.varianti.push(variante)
        pr.varianti.variante3 = variante
      }
      if (this.variante4) {
        const variante = {
          codice: this.variante4,
          descrizione: this.descrizioneVariante(this.variante4)
        }
        // pr.varianti.push(variante)
        pr.varianti.variante4 = variante
      }
      if (this.barcode) {
        const barcode = {
          codice: this.barcode,
          attivo: true,
          da: new Date()
        }
        pr.barcode.push(barcode)
      }
      if (this.prezzi) {
        const keys = Object.keys(this.prezzi)
        for (const key of keys) {
          pr.prezzo[key] = { base: this.prezzi[key] }
        }
      }
      return pr
    },
    async creaBarcode() {
      const result = await prodotti.creaBarcodeProdotto()
      if (result) {
        this.barcode = result.barcode
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .swipe-cell-button {
    height: 100%;
  }

</style>

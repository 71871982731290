/* TEST macchina su https://xstate.js.org/viz/?gist=06b130cce5e745cf605eee8a8a646de5 Togliere "import" e "export" */

// Salvare il contesto su localstorage ad ogni modifica in modo da ripartire se la macchina viene spenta senza uscita pulita

/* eslint-disable */
import * as _ from 'lodash';
import * as dot from 'dot-object'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import logger from '@/services/loggerService.js'
// import utils from '@/utils/shutdown.js'
// const fkill = require('fkill') // https://www.npmjs.com/package/fkill
// import fkill from 'fkill'

import prodotti from '../services/prodottiService.js'
import reparti from '../services/repartiService.js'
import configurazioni from '../services/configurazioniService.js'
import clienti from '../services/clientiService.js'
import barcode from '../services/barcodeService.js'
import scontrini from '../services/scontriniService.js'
import fidelity from '../services/fidelityService.js'
import magazzino from '../services/magazzinoService.js'
import stampante from '../services/stampanteService.js'
import documenti from '../services/documentiService.js'
import tabelle from '../services/tabelleService.js'
import escpos from '../services/escposService.js'
import operazioniCassa from './elementi/operazioniCassa.js';
import stampe from './elementi/stampe.js';
import Semaphore from 'semaphore-async-await'
import usaSelezione from './elementi/usaSelezione.js'
import funzioni from './elementi/funzioni_01.js'
// import funzioniCoupon from './elementi/funzioniCoupon.js'
import infoRepartiFiscali from './elementi/repartiFiscali.js'
// import ristorazione from './elementi/ristorazione.js';

import { Machine, assign, actions, spawn } from 'xstate';
const { raise, pure, send, cancel } = actions;
import { scontriniMachine }  from './scontriniMachine.js'

// import { barcodeMachine }  from './barcodeMachine.js'
const FIFO = require('fast-fifo')

const not = fn => (...args) => !fn.apply(null, args);
const isZero = (context, event) => event.value === 0 && useDecimalPoint;
// const isNotZero = not(isZero);
const isNotZero = (context, event) => event.value !== 0 && useDecimalPoint;
const isMinus = (context, event) => event.operator === "-";
const isNotMinus = not(isMinus);
const divideByZero = (context, event) => context.operand2 === "0." && context.operator === "/";
const notDivideByZero = not(divideByZero);
const useDecimalPoint = (context, event) => false; // da impostazioni ? --> su creazione macchina
const useNotDecimalPoint = not(useDecimalPoint);
const isDecimalPointPresent = (context, event) => context.displayValue.indexOf('.') !== -1;
const isNotDecimalPointPresent = not(isDecimalPointPresent)
const isDisplayZero = (context, event) => context.displayValue === '0'
const isNotDisplayZero = not(isDisplayZero)
const isReparto = (context, event) => context.reparto !== null;
const isProdotto = (context, event) => context.prodotto !== null;
const isRichiestaPrezzo = (context, event) => context.richiestaPrezzo;
const isNotRichiestaPrezzo = not(isRichiestaPrezzo)
const isSubTotale = (context, event) => context.isSubTotaleCalcolato && context.rigaCarrello.tipo !== 'scontoSub'
const isNotSubTotale = not(isSubTotale)
const scontoEffettuato = (context) => context.rigaCarrello.tipo === 'sconto' || context.rigaCarrello.tipo === 'scontoSub'
// const scontoSubEffettuato = (context) => context.rigaCarrello.tipo === 'scontoSub'
const abbuonoMaggioreTotale = (context) => parseFloat(context.displayValue) / 100 > parseFloat(context.totaleScontrino)
const isRigaSelezionata = (context) => context.displayValue !== '0' && context.rigaSelezionata != null;
const isNotRigaSelezionata = not(isRigaSelezionata);
const codiceLotteriaInserito = (context) => context.righeCarrello.some(x => x.tipo === 'lotteria')
const notCodiceLotteriaInserito = not(codiceLotteriaInserito);
const codiceFiscaleInserito = (context) => context.righeCarrello.some(x => x.tipo === 'codicefiscale')
const notCodiceFiscaleInserito = not(codiceFiscaleInserito);
const lotteriaOrCodiceFiscale = (context) => context.righeCarrello.some(x => x.tipo === 'lotteria') || context.righeCarrello.some(x => x.tipo === 'codicefiscale')

// const righeDetraibili = (context) => context.righeCarrello.some(x => x.ssnn)
// const righeDetraibiliNoCodFis = (context) => context.righeCarrello.some(x => x.descrizione.startsWith('*')) && !context.righeCarrello.some(x => x.tipo === 'codicefiscale')

const q = new FIFO() // coda FIFO per lettura veloce barcode prodotti
const lock = new Semaphore(1);

function addNota(context, { tipoRiga, nota, prezzo, payload }) {
  var riga = {
    quantita: 0,
    descrizione: nota,
    prezzoUnitario: 0,
    prezzo: prezzo,
    tipo: tipoRiga,
    payload,
    rigaCodice: uuidv4()
  };
  context.righeCarrello.push(riga)
  context.rigaCarrello = riga;
}

function addArrotonamento(context, { valore, descrizione, tipo }) {
    var riga = {
      quantita: 0,
      descrizione: descrizione,
      prezzoUnitario: 0,
      prezzo: valore.toFixed(2),
      tipo: tipo,
      aliquota: '',
      valore: valore
    };
    context.righeCarrello.push(riga)
    context.rigaCarrello = riga;
    context.arrotondamentoPresente = true
}

function aggiungiAbbuonoAlTotaleCarrello(context, valore) {
  let abbuono = valore ? valore : parseFloat(context.displayValue) / 100;
  var riga = {
    quantita: 0,
    descrizione: `Sconto Sub. ${abbuono.toFixed(2)} euro`,
    prezzoUnitario: 0,
    prezzo: (-abbuono).toFixed(2),
    tipo: 'abbuonoSub',
    aliquota: '',
    valore: abbuono,
    rigaCodice: uuidv4()
  };
  context.righeCarrello.push(riga)
  context.rigaCarrello = riga;
}

function fnCalcolaTotaleScontrino(context) {
  let righe = context.righeCarrello
  let st = 0
  for(var i = 0; i < righe.length; i++) {
    if (!['subtotale', 'separatore', 'lotteria', 'codicefiscale', 'cortesia', 'descrizione'].includes(righe[i].tipo) && righe[i].tipoBene !== 'VINCITA') {
      st += parseFloat(righe[i].prezzo)
      if (righe[i].sconto) {
        st += parseFloat(righe[i].sconto.prezzo || 0)
      }
      if (righe[i].abbuono) {
        st += parseFloat(righe[i].abbuono.prezzo || 0)
      }
    }
  }
  const abilitaSegnalazione = _.get(context.impostazioni,'ssnn.abilitaSegnalazioneCfMancante', true)
  context.righeDetraibiliNoCodFis = abilitaSegnalazione && context.righeCarrello.some(x => x.descrizione.startsWith('*')) && !context.righeCarrello.some(x => x.tipo === 'codicefiscale')
  return st.toFixed(2)
}

function fnCalcolaTotaleScontrinoNoResi(context) {
  let righe = context.righeCarrello
  let st = 0
  for(var i = 0; i < righe.length; i++) {
    if (!['subtotale', 'separatore', 'lotteria', 'codicefiscale', 'cortesia', 'descrizione'].includes(righe[i].tipo) && righe[i].quantita > 0 && righe[i].tipoBene !== 'VINCITA') {
      st += parseFloat(righe[i].prezzo)
      if (righe[i].sconto) {
        st += parseFloat(righe[i].sconto.prezzo || 0)
      }
      if (righe[i].abbuono) {
        st += parseFloat(righe[i].abbuono.prezzo || 0)
      }
    }
  }
  return st.toFixed(2)
}

function fnCalcolaNumeroItemScontrino(context) {
  let righe = context.righeCarrello
  let st = 0
  for(var i = 0; i < righe.length; i++) {
    // TODO: Verificare calcolo quantità
    if (!['subtotale', 'separatore', 'lotteria', 'codicefiscale', 'cortesia', 'abbuono', 'descrizione'].includes(righe[i].tipo) && parseInt(righe[i].quantita) > 0 && righe[i].tipoBene !== 'VINCITA') {
      st += parseInt(righe[i].quantita)
    }
  }
  return String(st)
}

const totalePerTipoItem = function(context, tipoItem) {
  let righe = context.righeCarrello
  let st = 0
  for(var i = 0; i < righe.length; i++) {
    if (!['subtotale', 'separatore', 'lotteria', 'codicefiscale', 'cortesia', 'descrizione'].includes(righe[i].tipo) && righe[i].tipoBene === tipoItem) {
      st += parseFloat(righe[i].prezzo)
      if (righe[i].sconto) {
        st += parseFloat(righe[i].sconto.prezzo || 0)
      }
      if (righe[i].abbuono) {
        st += parseFloat(righe[i].abbuono.prezzo || 0)
      }
    }
  }
  return st.toFixed(2)
}

function fnGetOperatore(context) {
  let op = ''
  if (context.operatore.servizio) {
    op = context.operatore.servizio.codice || ''
  }
  if (!op && context.operatore.accesso) {
    op = context.operatore.accesso.codice || ''
  }
  return op
}

function fnGetNomeOperatore(context, tipoInfo) {
  let op = ''
  if (context.operatore.servizio) {
    op = tipoInfo === 'descrizione' ? context.operatore.servizio.nome || '' : context.operatore.servizio.codice || ''
  }
  if (!op && context.operatore.accesso) {
    op = tipoInfo === 'descrizione' ? context.operatore.accesso.nome || '' : context.operatore.accesso.codice || ''
  }
  return op
}

function getOperatoreDaBarcode(code) {
  var result = configurazioni.getOperatoriSynk()
  const operatore = result ? result.find(x => x.barcode === code) || null : null
  return operatore
}

function fnIsAccessoVisibile(context, code) {
  // return context.bloccaCassa || (context.impostazioni.operatori.obbligatorio && !context.operatore.accesso)
  let condizione = context.bloccaCassa || (context.impostazioni.operatori.obbligatorio && !context.operatore.accesso)
  if (condizione) {
    // verifica se il barcode è un barcode utente
    const op = getOperatoreDaBarcode(code)
    if (op) {
      condizione = false
    }
  }
  return condizione
}

const getInfoReparto = async (context, event) => {
  let reparto = await reparti.getInfoReparto(event.value.code, event.value.tipoAliquota)
  if (reparto.tipoBene === 'BUONOMULTIUSO') {
    const idCassa = context.impostazioni.stampante.progressivo_stampante
    const pzUnitario = reparto.prezzoPredefinito || (parseFloat(context.displayValue)/100).toFixed(2);
    const buono = await fidelity.creaBuonoMultiuso(idCassa, +pzUnitario)
    reparto.infoBuono = buono
    if (parseFloat(context.quantita) > 1) {
      context.quantita = 1 // Per i buoni devo avere sempre quantità = 1, nel caso aggiungere più buoni
      context.messaggioToast = 'Max un buono per riga !!!'
    }
  }
  if (reparto.tipoBene === 'BUONOMONOUSO') {
    const idCassa = context.impostazioni.stampante.progressivo_stampante
    const pzUnitario = reparto.prezzoPredefinito || (parseFloat(context.displayValue)/100).toFixed(2);
    const buono = await fidelity.creaBuonoMonouso(idCassa, +pzUnitario)
    reparto.infoBuono = buono
    if (parseFloat(context.quantita) > 1) {
      context.quantita = 1 // Per i buoni devo avere sempre quantità = 1, nel caso aggiungere più buoni
      context.messaggioToast = 'Max un buono per riga !!!'
    }
  }
  if (reparto.tipoBene === 'PREPAGATA') {// ATTENZIONE: Deve essere caricata una prepagata
    // const idCassa = context.impostazioni.stampante.progressivo_stampante
    // const pzUnitario = reparto.prezzoPredefinito || (parseFloat(context.displayValue)/100).toFixed(2);
    // const buono = await fidelity.creaBuonoMultiuso(idCassa, +pzUnitario)
    // reparto.infoBuono = buono
    reparto.infoBuono = { // verificare
      // manca l'importo
      buono: context.fidelityCard.codice,
      secureCode: context.fidelityCard.secureCode,
      importo: event.value.importo
    }
    if (parseFloat(context.quantita) > 1) {
      context.quantita = 1 // Per i buoni devo avere sempre quantità = 1, nel caso aggiungere più buoni
      context.messaggioToast = 'Max una prepagata per riga !!!'
    }
  }
  context.reparto = reparto
}

const getInfoProdotto = async (context, event) => {
  const cliente = context.cliente ? context.cliente.codice : ''
  const cercaDisattivati = false || context.impostazioni.carrello.cercaDisattivati
  let prodotto = await prodotti.getInfoProdotto(event.value.code, context.listino, cliente, context.variante, event.value.tipoAliquota, event.value.barcode, cercaDisattivati)
  if (prodotto.tipoBene === 'BUONOMULTIUSO') {
    const idCassa = context.impostazioni.stampante.progressivo_stampante
    const buono = await fidelity.creaBuonoMultiuso(idCassa, prodotto.prezzo)
    prodotto.infoBuono = buono
    if (prodotto.scadenza) {
      prodotto.infoBuono.scadenza = prodotto.scadenza
    }
    if (parseFloat(context.quantita) > 1) {
      context.quantita = 1 // Per i buoni devo avere sempre quantità = 1, nel caso aggiungere più buoni
      context.messaggioToast = 'Max un buono per riga !!!'
    }
  }
  if (prodotto.tipoBene === 'BUONOMONOUSO') {
    const idCassa = context.impostazioni.stampante.progressivo_stampante
    const buono = await fidelity.creaBuonoMonouso(idCassa, prodotto.prezzo)
    prodotto.infoBuono = buono
    if (parseFloat(context.quantita) > 1) {
      context.quantita = 1 // Per i buoni devo avere sempre quantità = 1, nel caso aggiungere più buoni
      context.messaggioToast = 'Max un buono per riga !!!'
    }
  }
  if (prodotto.tipoBene === 'PREPAGATA') { // ATTENZIONE: Deve essere caricata una prepagata
    // const idCassa = context.impostazioni.stampante.progressivo_stampante
    // const buono = await fidelity.creaBuonoMultiuso(idCassa, prodotto.prezzo)
    prodotto.infoBuono = { // verificare
      buono: context.fidelityCard.codice,
      secureCode: context.fidelityCard.secureCode
    }
    if (parseFloat(context.quantita) > 1) {
      context.quantita = 1 // Per i buoni devo avere sempre quantità = 1, nel caso aggiungere più prepagate
      context.messaggioToast = 'Max una prepagata per riga !!!'
    }
  }
  if (prodotto.barcodeArray && event.value.barcode) {
    console.log('array presente, verifica quantità')
    const br = prodotto.barcodeArray.find(x => x.codice === event.value.barcode)
    if (br && (br.quantita || 1) !== 1) {
      context.quantita = context.quantita * br.quantita
    }
  }
  context.prodotto = prodotto
  return prodotto
}

const aggiungiProdottoAlCarrello = (context, event) => {
  // verificare eventuali sconti cliente o listini... Forse già passati su calcolo prezzo prodotto
  let pzUnitario = parseFloat(context.prezzoImposto || context.prodotto.prezzo).toFixed(2);
  let qta = context.quantita
  let iva = context.prodotto.iva
  let sintetico = context.impostazioni.carrello.scontrino_sintetico ? dot.pick(context.impostazioni.carrello.scontrino_sintetico, context.prodotto)  : ''

  let prezzoAcquistoIvato = context.impostazioni.carrello.prezzoAcquistoIvato
  let prezzoAcquisto = context.prodotto.magazzino && parseFloat(context.prodotto.magazzino.prezzoAcquisto || 0)
  let ivaAcquisto = context.prodotto.magazzino && context.prodotto.magazzino.ivaAcquisto || 0.22
  if (prezzoAcquistoIvato) {
    prezzoAcquisto = prezzoAcquisto * (1 + ivaAcquisto)
  }
  // ------------------------------------------------------------------------------
  const separaMenuComposto = _.get(context, 'impostazioni.menu.separaMenuComposto', false)

  // Verifica prodotto già inserito se abilitata gestione multipli
  if (context.impostazioni.cassa.inserisciProdottiMultipli && !context.prodottoAPeso && !separaMenuComposto) {
    const codice = context.prodotto.ricercaTC.toLowerCase()
    const riga = context.righeCarrello.find(x => x.codice === codice && x.quantita > 0)
    if (riga) {
      riga.quantita += qta
      riga.prezzo += pzUnitario * qta
      riga.prezzo = +riga.prezzo.toFixed(2)
      // nel caso di menù composto --> array di menù per memorizzare tutte le scelte
      if (riga.menuComposto && context.prodotto.menuComposto) {
        riga.menuComposto.push(context.prodotto.menuComposto)
      }
      context.rigaCarrello = riga
      return
    }
  }
  // ------------------------------------------------------------------------------

  var riga = {
    // codice: context.prodotto.codice,
    codice: context.prodotto.ricercaTC.toLowerCase(),
    codiceProdotto: String(context.prodotto.codice).toLowerCase(),
    barcode: context.prodotto.barcode,
    variante1: context.prodotto.varianti && context.prodotto.varianti.variante1 && context.prodotto.varianti.variante1.codice,
    variante2: context.prodotto.varianti && context.prodotto.varianti.variante2 && context.prodotto.varianti.variante2.codice,
    quantita: qta,
    unitaMisura: context.prodotto.magazzino && context.prodotto.magazzino.unitaMisura || '',
    descrizione: sintetico || context.prodotto.descrizioneTC || context.prodotto.descrizione,
    prezzoUnitario: pzUnitario,
    prezzo: +(parseFloat(pzUnitario) * qta).toFixed(2),
    aliquota: iva.valore,
    natura: iva.natura, // verificare
    tipo: 'prodotto',
    tipoBene: context.prodotto.tipoBene,
    nota: context.prodotto.nota || '',
    repartoFiscale: context.prodotto.repartoFiscale ? context.prodotto.repartoFiscale.stampante || '' : '',
    prezzoAcquisto: prezzoAcquisto > 0 ? prezzoAcquisto.toFixed(2) : '---',
    operatore: fnGetOperatore(context),
    rigaCodice: uuidv4(),
    listini: context.prodotto.listini,
    magazzino: context.prodotto.magazzino
    // stampa: _.get(context.prodotto, 'repartoFiscale.stampa', '')
    // iva
    // altri eventuali campi
  };
  const rep = context.prodotto.repartoFiscale ? context.prodotto.repartoFiscale.codice || '' : ''
  const stampa = _.get(context.prodotto, 'repartoFiscale.stampa', '') || infoRepartiFiscali.tipoStampaReparto(rep)
  if (stampa) {
    riga.stampa = stampa
  }
  if (context.prodotto.ssnn) {
    riga.ssnn = {...context.prodotto.ssnn}
    riga.descrizione = '*' + riga.descrizione
    // context.righeDetraibiliNoCodFis = !context.righeCarrello.some(x => x.tipo === 'codicefiscale')
  }
  if (context.prodotto.infoBuono) {
    riga.infoBuono = {...context.prodotto.infoBuono}
  }

  const fd = context.fidelityCard
  if (fd && fd.generale && fd.generale.sconto) {
    const scontoPerc = parseInt(fd.generale.sconto)
    let ultimoPrezzo = parseFloat(riga.prezzo)
    let sconto = ultimoPrezzo * scontoPerc / 100
    var subriga = {
      quantita: 0,
      descrizione: `sconto card ${scontoPerc}%`,
      prezzoUnitario: 0,
      prezzo: (-sconto).toFixed(2),
      tipo: 'abbuono',
      aliquota: riga.aliquota,
      natura: riga.natura,
      valore: sconto.toFixed(2),
      rigaCodice: uuidv4()
    };
    riga.sconto = subriga
  }
  if (context.prodotto.menuComposto) {
    riga.menuComposto = riga.menuComposto || []
    riga.menuComposto.push(context.prodotto.menuComposto)
  }
  if (context.prodotto.isVariazione) {
    riga.isVariazione = context.prodotto.isVariazione
    riga.descrizione = riga.isVariazione + riga.descrizione
  }
  if (context.prodotto.bom) {
    riga.bom = { ...context.prodotto.bom } 
  }
  if (context.prodotto.lotti) {
    riga.lotti = { ...context.prodotto.lotti } 
  }
  if (context.prodotto.caricatoDaBackoffice) {
    riga.caricatoDaBackoffice = context.prodotto.caricatoDaBackoffice
  }
  context.righeCarrello.push(riga)
  context.rigaCarrello = riga;
  context.prodottoAPeso = null
  if (riga.tipoBene === 'VINCITA') {
    // context.impostaVincita = +(pzUnitario * qta).toFixed(2)
    context.vinciteCaricate += +(pzUnitario * qta).toFixed(2)
    impostaContanti(context, +(pzUnitario * qta).toFixed(2))
  } else {
    // context.impostaVincita = true
    // impostaContanti(context, 0)
  }
}

const aggiungiVariazioniGruppo = async (context, gruppo, prodottoPadre, qtaImpostata, stampaArticoliPrezzoZero) => {
  const variazioni = gruppo.variazioni
  if (variazioni) {
    for (let v of variazioni) {
      if (v.deltaPrezzo || stampaArticoliPrezzoZero) {
        if (v.deltaPrezzo) {
          context.prezzoImposto = +v.deltaPrezzo.toFixed(2)
          // TODO: Impostare iva e reparto fiscale
          const nota = {
            value: {
              iva: prodottoPadre.iva.codice, //  '22',
              repartoFiscale : prodottoPadre.repartoFiscale.stampante, // '1',
              testo: `${v.segno}${v.descrizione}`,
              isVariazione: v.segno,
              caricatoDaBackoffice: true
            }
          }
          context.quantita = qtaImpostata
          await aggiungiRepartoNotaLiberaAlCarrello(context, nota)
        } else {
          addNota(context, { tipoRiga: 'descrizione', nota: `${v.segno}${v.descrizione}`, payload: { caricatoDaBackoffice: true }})
        }
      }
    }
  }
}

const aggiungiPreparazioniGruppo = async (context, gruppo, prodottoPadre, qtaImpostata, stampaPreparazioniPrezzoZero) => {
  const preparazioni = gruppo.preparazioni
  if (preparazioni) {
    for (let p of preparazioni) {
      if (p.deltaPrezzo || stampaPreparazioniPrezzoZero) {
        if (p.deltaPrezzo) {
          context.prezzoImposto = +p.deltaPrezzo.toFixed(2)
          const nota = {
            value: {
              iva: prodottoPadre.iva.codice, //  '22',
              repartoFiscale : prodottoPadre.repartoFiscale.stampante, // '1',
              testo: `${p.segno}${p.descrizione}`,
              isVariazione: p.segno,
              caricatoDaBackoffice: true
            }
          }
          context.quantita = qtaImpostata
          await aggiungiRepartoNotaLiberaAlCarrello(context, nota)
        } else {
          addNota(context, { tipoRiga: 'descrizione', nota: `${p.segno}${p.descrizione}`, payload: { caricatoDaBackoffice: true }})
        }
      }
    }
  }
}

const cancellaRigheDaBackoffice = async (context) => {
  const righeDaCancellare = context.righeCarrello.filter(x => x.caricatoDaBackoffice || (x.tipo === 'descrizione' && x.payload && x.payload.caricatoDaBackoffice ))
  for (let r of righeDaCancellare) {
    let rigaCodice = r.rigaCodice
    const idx = context.righeCarrello.findIndex(x => x.rigaCodice === rigaCodice)
    context.righeCarrello.splice(idx, 1)
    await scontrini.deleteRigaScontrinoByCode(context.idScontrino, rigaCodice)
  }
}

const caricaMenuComposto = async (context, payload_1) => {
  const stampaArticoliPrezzoZero = context.impostazioni.menu.stampaArticoliPrezzoZero
  const stampaPreparazioniPrezzoZero = context.impostazioni.menu.stampaPreparazioniPrezzoZero
  const stampaDettaglioMenuComposto = context.impostazioni.menu.stampaDettaglioMenuComposto

  if (context.idScontrino === '') {
    let result = await scontrini.nuovoScontrino(configurazioni.getPostazione())
    context.idScontrino = result._id
  }
  const qtaImpostata = context.quantita
  const cliente = context.cliente ? context.cliente.codice : ''
  const tipoAliquota = 'banco'

  await cancellaRigheDaBackoffice(context)
  for (let mt of Object.keys(context.menuTotali)) {
    const payload = context.menuTotali[mt]
    const menuCreati = payload.menuCreati
    const numeroGruppi = payload.numeroGruppi
    var prodotto
    for (let riga of menuCreati) {
      const gruppi = Object.keys(riga)
      if (gruppi.length === numeroGruppi) {
        // aggiungo il menu
        prodotto = await prodotti.getInfoProdotto(payload.idmenu, context.listino, cliente, null, tipoAliquota, null, false)
        prodotto.menuComposto = riga
        prodotto.caricatoDaBackoffice = true
        context.prodotto = prodotto
        context.quantita = qtaImpostata
        aggiungiProdottoAlCarrello(context)
        await scontrini.addRigaScontrino(context.idScontrino, context.rigaCarrello)
        
        for (let g of gruppi) {
          const gruppo = riga[g]
          prodotto = await prodotti.getInfoProdotto(gruppo.codice, context.listino, cliente, null, tipoAliquota, null, false)
          if (stampaDettaglioMenuComposto) {
            addNota(context, { tipoRiga: 'descrizione', nota: '  ' + prodotto.descrizione, payload: { caricatoDaBackoffice: true }})
          } 
          await aggiungiVariazioniGruppo(context, gruppo, prodotto, qtaImpostata, stampaArticoliPrezzoZero)
          await aggiungiPreparazioniGruppo(context, gruppo, prodotto, qtaImpostata, stampaPreparazioniPrezzoZero)
        }
      } else {
        // aggiungo i prodotti sfusi
        for (let g of gruppi) {
          const gruppo = riga[g]
          prodotto = await prodotti.getInfoProdotto(gruppo.codice, context.listino, cliente, null, tipoAliquota, null, false)
          prodotto.caricatoDaBackoffice = true
          context.prodotto = prodotto
          context.quantita = qtaImpostata
          aggiungiProdottoAlCarrello(context)
          await scontrini.addRigaScontrino(context.idScontrino, context.rigaCarrello)
          await aggiungiVariazioniGruppo(context, gruppo, prodotto, qtaImpostata, stampaArticoliPrezzoZero)
          await aggiungiPreparazioniGruppo(context, gruppo, prodotto, qtaImpostata, stampaPreparazioniPrezzoZero)
        }
      }
    }
  }
  context.totaleScontrino = fnCalcolaTotaleScontrino(context)
  context.itemsScontrino = fnCalcolaNumeroItemScontrino(context)
  context.scontrinoTick = context.scontrinoTick + 1
}

const aggiungiRepartoNotaLiberaAlCarrello = async (context, event) => {
  // aggiungo un acquisto specificando la descrizione
  if (event.value.testo.length === 0) return
  if (context.idScontrino === '') {
    console.log('crea scontrino da aggiungiNota')
    let result = await scontrini.nuovoScontrino(configurazioni.getPostazione())
    context.idScontrino = result._id
    console.log('creato scontrino da aggiungiNota', context.idScontrino)
    // scontrinoCreato = true
  }
  const reparto = context.impostazioni.notaLibera.reparto
  const codiceIva = event.value.iva
  const repartoFiscale = event.value.repartoFiscale
  let impo = await configurazioni.getConfigurazione('prodotti_aliquote_iva', 'aliquote')
  const iva = impo.find(x => x.codice === codiceIva)
  if (!iva) {
    context.displayMessage = `Attenzione ! Iva ${codiceIva} non definita`
    return
  }
  let pzUnitario = context.prezzoImposto || (parseFloat(context.displayValue)/100).toFixed(2);
  let qta = context.quantita
  // let iva = reparto.iva
  var riga = {
    codice: reparto.codice,
    quantita: qta,
    descrizione: event.value.testo.substring(0, 30), // La stampante non supporta più di 30 caratteri
    prezzoUnitario: pzUnitario ? parseFloat(pzUnitario).toFixed(2) : '',
    prezzo: (pzUnitario * qta).toFixed(2),
/*     aliquota: iva.valore,
    natura: iva.natura, */
    aliquota: iva.aliquota,
    natura: '', // iva.natura,
    tipo: 'reparto',
    tipoBene: reparto.tipoBene,
    nota: reparto.nota || '',
    // repartoFiscale: reparto.repartoFiscale ? reparto.repartoFiscale.stampante || '' : '',
    repartoFiscale: repartoFiscale || '',
    operatore: fnGetOperatore(context),
    rigaCodice: uuidv4(),
    barcodeGenerico: context.barcodePerGenerico
  // altri eventuali campi
  }
  if (event.value.isVariazione) {
    riga.isVariazione = event.value.isVariazione
  }
  if (event.value.caricatoDaBackoffice) {
    riga.caricatoDaBackoffice = event.value.caricatoDaBackoffice
  }
  /* if (context.reparto.infoBuono) {
    riga.infoBuono = {...context.reparto.infoBuono}
  } */
  context.righeCarrello.push(riga)
  context.rigaCarrello = riga;

	await scontrini.addRigaScontrino(context.idScontrino, context.rigaCarrello)
  context.totaleScontrino = fnCalcolaTotaleScontrino(context)
  context.itemsScontrino = fnCalcolaNumeroItemScontrino(context)
  context.scontrinoTick = context.scontrinoTick + 1
  if (!context.richiestaPrezzo) {
    context.displayValue = '0'
    context.displayDescrizione = ''
  }
  context.richiestaPrezzo = false
  context.quantita = 1
  context.prezzoImposto = ''
  context.barcodePerGenerico = ''
}

const getIdRigaDaCodice = (context, rigaCodice) => {
  let idRiga = context.righeCarrello.findIndex(x => x.rigaCodice === rigaCodice)
  if (idRiga === -1) {
    idRiga = context.righeCarrello.findIndex(x => (x.abbuono && x.abbuono.rigaCodice === rigaCodice) || (x.sconto && x.sconto.rigaCodice === rigaCodice))
    idRiga += 1000 // serve per capire che è stato selezionato lo sconto
  }
  return idRiga
}

const preparaRigheDocumento = async (context, includiNote) => {
  const r = context.righeCarrello
  /*    let item = r.find(x => x.tipo === 'totalecomplessivo')
        totaleComplessivo += item ? parseFloat(item.prezzo) : 0
        item = r.find(x => x.tipo === 'totale')
        totalePagato += item ? parseFloat(item.prezzo) : 0
        const resto = parseFloat(sc.pagamento.resto || 0)
        item = sc.pagamento.pagamenti.find(x => x.tipo === 'contanti')
        contanti += ((item ? parseFloat(item.importo) : 0) - resto)
        item = sc.pagamento.pagamenti.find(x => x.tipo === 'cartadicredito')
        cartaDiCredito += item ? parseFloat(item.importo) : 0
        item = sc.pagamento.pagamenti.find(x => x.tipo === 'nonriscossobeni')
        nonRiscosso += item ? parseFloat(item.importo) : 0
        item = sc.pagamento.pagamenti.find(x => x.tipo === 'buonimultiuso')
        buoni += item ? parseFloat(item.importo) : 0 */

		const {scontoSub, maggiorazioniSub, abbuonoPerEuro} = getScontiScontrino(r)

    // const repartiFiscali = await configurazioni.getConfigurazione('prodotti_reparti_fiscali', 'reparti')
    const repartiFiscali = await infoRepartiFiscali.getElencoReparti()
    const aliquote = await configurazioni.getConfigurazione('prodotti_aliquote_iva', 'aliquote')
    let righeDocumento = []
    // x.repartoFiscale
    for (let el of r.filter(x => (parseFloat(x.quantita || 0) > 0) || (includiNote && x.tipo === 'descrizione'))) {
      var riga
      if (el.tipo === 'descrizione') {
        riga = {
          descrizione: el.descrizione
        }
      } else {
        let sconto = 0
        let tst = el.sconto
        sconto += (tst ? +parseFloat(tst.valore) : 0)
        tst = el.abbuono
        sconto += (tst ? tst.valore : 0)
        sconto += abbuonoPerEuro * parseFloat(el.prezzo)
        const repFis = repartiFiscali.find(x => x.stampante.numero === el.repartoFiscale)
        const iva = aliquote.find(x => x.codice === repFis.codiceIva)
        const listini = await configurazioni.getConfigurazione('listini', 'listini')
        const listino = listini.find(x => x.codice === context.listino)
        const ivato = listino.ivato
        const aliquota = iva.aliquota
        const prezzoUnitario = ivato ? parseFloat(el.prezzo) : parseFloat(el.prezzo) * (1 + aliquota / 100)
        const prezzoNettoUnitario = ivato ? parseFloat(el.prezzo) / (1 + aliquota / 100) : parseFloat(el.prezzo)
        const importoUnitario = ivato ? (parseFloat(el.prezzo) - sconto) : (parseFloat(el.prezzo) - sconto) * (1 + aliquota / 100)
        const importoNettoUnitario = ivato ? (parseFloat(el.prezzo) - sconto) / (1 + aliquota / 100) : (parseFloat(el.prezzo) - sconto)
        riga = {
          codice: el.codice,
          descrizione: el.descrizione,
          tipoBene: el.tipoBene,
          quantita: parseFloat(el.quantita),
          unitaMisura: el.unitaMisura,
          iva: iva,
          repartoFiscale: repFis.codice,
          listino: listino,
          sconti: context.cliente.calcolati && context.cliente.calcolati.sconto || '',
          prezzoUnitario: prezzoUnitario.toFixed(2),
          prezzoTotale: (prezzoUnitario * parseFloat(el.quantita)).toFixed(2),
          prezzoNettoUnitario: prezzoNettoUnitario.toFixed(2),
          prezzoNettoTotale: (prezzoNettoUnitario * parseFloat(el.quantita)).toFixed(2),
          importoUnitario: importoUnitario.toFixed(2),
          importoTotale: (importoUnitario * parseFloat(el.quantita)).toFixed(2),
          importoNettoUnitario: importoNettoUnitario.toFixed(2),
          importoNettoTotale: (importoNettoUnitario * parseFloat(el.quantita)).toFixed(2),
        }
      }
      righeDocumento.push(riga)
    }
    return righeDocumento
}

const emissioneDocumento = async (context, tipoPagamento) => {
  // Se è necessaria la possibilità di specificare il sotto tipo documento (es ddt da bollettario 1, da bollettario 2, etc),
  // basta aggiungere un campo nel payload di chiamata del tasto)
  const righeDocumento = await preparaRigheDocumento(context, true)
  let dettaglioTipo = _.get(context.impostazioni, `documenti.tipiDocumento.${context.tipoDocumento}`, context.tipoDocumento)
  if (_.get(context.impostazioni, 'documenti.usaTipoCliente', false)) {
    dettaglioTipo = _.get(context.cliente, `fatturazione.tipiDocumento.${context.tipoDocumento}`, dettaglioTipo)
  }
  switch (context.tipoDocumento) {
    case 'fatturaImmediata':
      context.documento = await documenti.creaFatturaImmediata(dettaglioTipo, context.cliente, tipoPagamento, righeDocumento)
      // o salvare tutto se servono altre informazioni
      // TODO: decidere per stampa, dove, chi (probabilemente deve stampare: documenti)
      break;
    case 'ddt':
      context.documento = await documenti.creaDocumentoDiTrasporto(dettaglioTipo, context.cliente, tipoPagamento, righeDocumento)
      break;
    case 'scontrinoSegueFattura':
      // Poichè si tratta di una fattura riepilogatica emessa a fine mese per gli scontrini fatti con questa modalità,
      // è sufficiente segnare nello scontrino i dati del cliente e la necessità di emettere fattura riepilogativa
      // non serve creare alcun documento in questo momento, nè movimenti di magazzino perchè ci sono già dallo scontrino
      // TODO: Scrivere nello scontrino i dati necessari
      context.documento = {
        tipoDocumento: {
          codice: dettaglioTipo,
          descrizione: "Scontrino Segue Fattura"
        },
        anagrafica: {
          cliente: {
            codice: context.cliente.codice,
            descrizione: context.cliente.ragioneSociale
          }
        }
      }
      break;
    default:
      break;
  }
}

const usaRigheRecupero = async (context) => {
  context.idScontrino = ''
  context.righeCarrello = []
  context.titoloScontrino = ''
  context.recuperoInCorso = false
  context.confermaAnnulloVisibile = false

  let righe = []
  for (let i = 0; i < context.scontrinoRecupero.righe.length; i++) {
    let riga = context.scontrinoRecupero.righe[i]
    if (['prodotto', 'reparto', 'subtotale', 'scontoSub', 'abbuonoSub'].includes(riga.tipo)) {
      righe.push(riga)
    }
    if (riga.tipo === 'separatore' && context.scontrinoRecupero.righe[i + 1].tipo === 'subtotale') {
      righe.push(riga)
    }
  }

  context.scontrinoRecupero = null
  context.righeCarrello = [...righe]

  let result = await scontrini.nuovoScontrino(configurazioni.getPostazione())
  context.idScontrino = result._id

  context.totaleScontrino = fnCalcolaTotaleScontrino(context)
  context.itemsScontrino = fnCalcolaNumeroItemScontrino(context)
  context.scontrinoTick = context.scontrinoTick + 1
}

const funzioniRapideCall = function(context, event) {
  let returnValue = ''
  let msg = ''
  let title = ''
  switch (event.value.funzione) {
    case "RISTAMPA_SCONTRINO":
      returnValue = { value: { commands: ['=C453/$1'] }, type: 'PRINTER'}
      title = 'Ristampa scontrino'
      msg = 'Confermi ristampa fiscale ?'
      break;
    case "LETTURA_GIORNALIERA":
      // returnValue = { value: { commands: ['=C2', '=C10', '=C1'] }, type: 'PRINTER_LETTURA_GIORNALIERA'}
      returnValue = { type: 'PRINTER_LETTURA_GIORNALIERA'}
      title = 'Lettura giornaliera'
      msg = 'Confermi stampa lettura giornaliera ?'
      break;
    case "CHIUSURA_GIORNALIERA":
      returnValue = { type: 'PRINTER_CHIUSURA_GIORNALIERA'}
      // returnValue = { value: { commands: ['=C3', '=C10', '=C1'] }, type: 'PRINTER_CHIUSURA_GIORNALIERA'}
      title = 'Chiusura giornaliera'
      msg = 'Confermi esecuzione chiusura fiscale ?'
      break;
    case "RICARICA_PAGINA":
      context.reloadPage = true
      break;
    case "CHIUDI_BROWSER":
      returnValue = { type: 'CHIUDI_BROWSER'}
      title = 'Chiusura programma'
      msg = 'Confermi chiusura Ubisell ?'
      break;
    case "CHIUDI_PC":
      returnValue = { type: 'SHUTDOWN'}
      title = 'Spengnimento PC'
      msg = 'Confermi spegnimento PC ?'
      break;
    case "IMPORT_DATI":
      context.importDati = true
      break;
    case 'CHIUDI_SCONTRINO':
      returnValue = { type: 'CHIUDI_SCONTRINO' }
      break;
    default:
      break;
  }

  return { title, msg, value: returnValue }
}

function getTotaleProdotti(r) { // attenzione alle note con valore
  let qta = 0
  let prezzo = 0
  for (let i = r.length - 1; i >= 0; i--) {
    let riga = r[i]
    if (['prodotto', 'reparto'].includes(riga.tipo) && parseFloat(riga.quantita || 0) > 0) {
      let sconto = 0
      let abbuono = 0
      if (riga.sconto) {
        sconto += parseFloat(riga.sconto.prezzo)
      }
      if (riga.abbuono) {
        abbuono += parseFloat(riga.abbuono.prezzo)
      }
      qta += parseFloat(riga.quantita)
      prezzo += (parseFloat(riga.prezzo) + sconto + abbuono)
    }
  }
  return { qta, totale: prezzo}
}

export function getScontiScontrino(r) {
  let scontoSub = 0
  let maggiorazioniSub = 0
  let locSub = 0
  let item = r.find(x => x.tipo === 'scontoSub')
  scontoSub += item ? parseFloat(item.valore) : 0
  locSub += item ? parseFloat(item.valore) : 0

  item = r.find(x => x.tipo === 'abbuonoSub')
  scontoSub += item ? parseFloat(item.valore) : 0
  locSub += item ? parseFloat(item.valore) : 0

  item = r.find(x => x.tipo === 'arrotondaDifetto')
  scontoSub += item ? -parseFloat(item.valore) : 0
  locSub += item ? parseFloat(item.valore) : 0

  item = r.find(x => x.tipo === 'arrotondaEccesso')
  maggiorazioniSub += item ? parseFloat(item.valore) : 0
  locSub += item ? parseFloat(item.valore) : 0

  item = r.find(x => x.tipo === 'subtotale')
  let subtotale = item ? parseFloat(item.prezzo) : 0
  if (subtotale === 0 && locSub !== 0) {
    subtotale = getTotaleProdotti(r).totale
  }
  const abbuonoPerEuro = subtotale > 0 ? (locSub) / subtotale : 0
  return {scontoSub, maggiorazioniSub, abbuonoPerEuro, subtotale}
}

/* function getTotaleResi(resi) {
  let qta = 0
  let prezzo = 0
  for (let i = resi.length - 1; i >= 0; i--) {
    let righe = resi[i].righe
    for (let k = 0; k < righe.length; k++) {
      let riga = righe[k]
      if (['prodotto', 'reparto'].includes(riga.tipo) && parseFloat(riga.quantita || 0) < 0) {
        let sconto = 0
        let abbuono = 0
        if (riga.sconto) {
          sconto += parseFloat(riga.sconto.prezzo)
        }
        if (riga.abbuono) {
          abbuono += parseFloat(riga.abbuono.prezzo)
        }
        qta -= parseFloat(riga.quantita)
        prezzo -= (parseFloat(riga.prezzo) + sconto + abbuono)
      }
    }
  }
  return { qta, totale: prezzo}
} */

function getTotaleResi(righe) {
  let qta = 0
  let prezzo = 0
  // for (let i = resi.length - 1; i >= 0; i--) {
  //   let righe = resi[i].righe
    for (let k = 0; k < righe.length; k++) {
      let riga = righe[k]
      if (['prodotto', 'reparto'].includes(riga.tipo) && parseFloat(riga.quantita || 0) < 0) {
        let sconto = 0
        let abbuono = 0
        if (riga.sconto) {
          sconto += parseFloat(riga.sconto.prezzo)
        }
        if (riga.abbuono) {
          abbuono += parseFloat(riga.abbuono.prezzo)
        }
        qta -= parseFloat(riga.quantita)
        prezzo -= (parseFloat(riga.prezzo) + sconto + abbuono)
      }
    }
  // }
  return { qta, totale: prezzo}
}

const mostraStatisticaRapida = async (context, event) => {
  const funzione = event.value.funzione
  const offsetGiorni = event.value.offsetGiorni || 0
  const mData = moment().add(offsetGiorni, 'days')
  switch(funzione) {
    case 'SCONTRINI_DEL_GIORNO':
      const dataDa = mData.startOf('day').toJSON()
      const dataA = mData.endOf('day').toJSON()
      // TODO: verificare se utilizzare analisi.dashboardVendutoPeriodo(filtro, tipo, this.tipoDashboard)
      let elenco = await scontrini.elencoScontriniPerData(dataDa, dataA, 1000)
      const postazione = configurazioni.getPostazione()
      elenco = elenco.filter(x => !x.postazione || x.postazione === postazione)
      let annullati = elenco.filter(x => x.stato === 'annullamento-fiscale')

      const scReso = elenco.filter(x => x.righe.some(r => r.payload && r.payload.fiscale && r.payload.fiscale.ricerca.documento))
      const scResoR = scReso.map(x => x.righe)
      const scResoRR = (_.flatten(scResoR)).filter(r => r.payload && r.payload.fiscale && r.payload.fiscale.ricerca.documento)
      const docReso = _.uniq(scResoRR.map(x => x.payload.fiscale.ricerca.documento))

      elenco = elenco.filter(x => x.stato === 'chiuso')
      let totale = 0
      let numeroScontriniValidi = 0
      let totaleComplessivo = 0
      let totalePagato = 0
      let contanti = 0
      let cartaDiCredito = 0
      let nonRiscosso = 0
      let scontoAPagare = 0
      let buoni = 0
      let resi = 0
      let vincite = 0
      let nonFiscali = 0
      let totaliAliquote = {}
      let scontoSub = 0
      let maggiorazioniSub = 0
      // const elencoReparti = await configurazioni.getConfigurazione('prodotti_reparti_fiscali', 'reparti')
      const elencoReparti = await infoRepartiFiscali.getElencoReparti()
      const aliquote =  await configurazioni.getConfigurazione('prodotti_aliquote_iva', 'aliquote')
      const repartiStatistica = elencoReparti.map(x => {
        const iva = aliquote.find(i => i.codice === x.codiceIva)
        return {
          repartoFiscale: x.stampante && parseInt(x.stampante.numero),
          tipoBene: x.descrizione,
          descrizione: `Iva ${x.stampante.posizioneIva}: ${iva ? iva.descrizione : ''}`,
          aliquota: iva ? iva.aliquota : 0
        }
      })

      for (let sc of elenco) {
        let r = sc.righe
        if (r.length > 0) {
          let item = r.find(x => x.tipo === 'totalecomplessivo')
          totaleComplessivo += item ? parseFloat(item.prezzo) : 0
          item = r.find(x => x.tipo === 'totale')
          totalePagato += item ? parseFloat(item.prezzo) : 0
          if (sc.pagamento && sc.pagamento.pagamenti) {
            numeroScontriniValidi++
            const resto = parseFloat(sc.pagamento.resto || 0)
            item = sc.pagamento.pagamenti.find(x => x.tipo === 'contanti')
            contanti += ((item ? parseFloat(item.importo) : 0) - resto)
            item = sc.pagamento.pagamenti.find(x => x.tipo === 'cartadicredito')
            cartaDiCredito += item ? parseFloat(item.importo) : 0
            item = sc.pagamento.pagamenti.find(x => x.tipo === 'nonriscossobeni')
            nonRiscosso += item ? parseFloat(item.importo) : 0

            item = sc.pagamento.pagamenti.find(x => x.tipo === 'scontoapagare')
            scontoAPagare += item ? parseFloat(item.importo) : 0

            item = sc.pagamento.pagamenti.find(x => x.tipo === 'buonimultiuso')
            buoni += item ? parseFloat(item.importo) : 0
          }
          if (r.some(x => x.quantita < 0)) {
            const righeResi = r.filter(x => x.quantita < 0)
            const resiScontrino = getTotaleResi(righeResi)
            resi += resiScontrino.totale
          }
          const scontiScontrino = getScontiScontrino(r)
          maggiorazioniSub += scontiScontrino.maggiorazioniSub
          const abbuonoPerEuro = scontiScontrino.abbuonoPerEuro

          for (let rp of repartiStatistica) {
            const qta = _.sumBy(r.filter(x => x.repartoFiscale === String(rp.repartoFiscale) && parseFloat(x.quantita || 0) > 0), (el) => { return parseFloat(el.quantita) })
            const vl = _.sumBy(r.filter(x => x.repartoFiscale === String(rp.repartoFiscale) /* && parseFloat(x.quantita || 0) > 0 */), (el) => { return parseFloat(el.prezzo) })
            let sconti = _.sumBy(r.filter(x => x.repartoFiscale === String(rp.repartoFiscale) /* && parseFloat(x.quantita || 0) > 0 */), (el) => {
              let sconto = 0
              let tst = el.sconto
              sconto += (tst && tst.valore ? +parseFloat(tst.valore) : 0)
              tst = el.abbuono
              sconto += (tst && tst.valore ? tst.valore : 0)
              return sconto
            })

            const prezzo = vl - sconti
            sconti += abbuonoPerEuro * prezzo
            scontoSub += sconti
            const pz = +(vl - sconti).toFixed(2)
            const impo = rp.aliquota !== 0 ? +(pz / (1 + rp.aliquota / 100)).toFixed(2) : pz
            const iva = pz - impo

            let tal = totaliAliquote[rp.repartoFiscale] || {}
            tal.quantita = (tal.quantita || 0) + qta
            tal.valore = (tal.valore || 0) + vl
            tal.prezzo = (tal.prezzo || 0) + pz
            tal.imponibile = (tal.imponibile || 0) + impo
            tal.iva = (tal.iva || 0) + iva
            tal.sconti = (tal.sconti || 0) + sconti
            totaliAliquote[rp.repartoFiscale] = tal
          }
        }
        if (sc.nonFiscali) {
          vincite += _.sumBy(sc.nonFiscali.filter(x => x.tipoBene === 'VINCITA'), (el) => { return parseFloat(el.prezzo) })
          nonFiscali += _.sumBy(sc.nonFiscali.filter(x => x.tipoBene !== 'VINCITA'), (el) => { return parseFloat(el.prezzo) })
        }

      }

      const contantiMenoVincite = contanti - vincite

      context.statistica = []
      context.statistica.push({ tipo: 'totalecomplessivo', descrizione: `Statistica giornaliera: ${mData.format('DD/MM/YYYY')}` })
      context.statistica.push({ tipo: 'separatore' })
      context.statistica.push({ tipo: 'reparto', descrizione: 'Numero scontrini validi', prezzo: numeroScontriniValidi }) // elenco.length
      context.statistica.push({ tipo: 'reparto', descrizione: 'Scontrini di reso', prezzo: docReso.length })
      context.statistica.push({ tipo: 'reparto', descrizione: 'Scontrini annullati', prezzo: annullati.length })
      context.statistica.push({ tipo: 'separatore' })
      context.statistica.push({ tipo: 'reparto', descrizione: 'Totale contanti', prezzo: contantiMenoVincite.toFixed(2) })
      context.statistica.push({ tipo: 'reparto', descrizione: 'Totale pagamenti elettronici', prezzo: cartaDiCredito.toFixed(2) })
      context.statistica.push({ tipo: 'reparto', descrizione: 'Totale sconto a pagare', prezzo: scontoAPagare.toFixed(2) })
      context.statistica.push({ tipo: 'separatore' })
      context.statistica.push({ tipo: 'totalecomplessivo', descrizione: 'TOTALE INCASSO', prezzo: (contanti + cartaDiCredito).toFixed(2) })
      context.statistica.push({ tipo: 'reparto', descrizione: 'TOTALE MOVIMENTI', prezzo: (contanti + cartaDiCredito + scontoAPagare).toFixed(2) })
      context.statistica.push({ tipo: 'separatore' })
      context.statistica.push({ tipo: 'reparto', descrizione: 'Totale buoni', prezzo: buoni.toFixed(2) })
      context.statistica.push({ tipo: 'reparto', descrizione: 'Totale non riscosso', prezzo: nonRiscosso.toFixed(2) })
      context.statistica.push({ tipo: 'reparto', descrizione: 'Totale resi', prezzo: resi.toFixed(2) })
      if (nonFiscali > 0) {
        context.statistica.push({ tipo: 'reparto', descrizione: 'Totale altri prodotti', prezzo: nonFiscali.toFixed(2) })
      }
      if (vincite > 0) {
        // vincite = -vincite // Verificare se indicare come negativo perchè uscita di cassa
        context.statistica.push({ tipo: 'reparto', descrizione: 'Totale pagamenti vincite', prezzo: vincite.toFixed(2) })
      }

      context.statistica.push({ tipo: 'separatore' })
      if (_.get(context.impostazioni, 'statistiche.rapide.mostraDettaglioReparti', false)) {
        context.statistica.push({ tipo: 'separatore' })
        for (let rp of repartiStatistica)  {
          const el = totaliAliquote[rp.repartoFiscale]
          if (el && el.valore > 0) {
            // context.statistica.push({ tipo: 'reparto', descrizione: rp.tipoBene, prezzo: (el.valore - el.sconti).toFixed(2) })
            context.statistica.push({ tipo: 'reparto', descrizione: rp.tipoBene, prezzo: (el.prezzo).toFixed(2) })
            context.statistica.push({ tipo: 'descrizione', descrizione: `Quantità: ${el.quantita}`, prezzo: '' })
            context.statistica.push({ tipo: 'descrizione', descrizione: 'Sconti', prezzo: el.sconti.toFixed(2) })
          }
        }
        context.statistica.push({ tipo: 'separatore' })
        context.statistica.push({ tipo: 'reparto', descrizione: 'Sconti totali', prezzo: scontoSub.toFixed(2) })
        context.statistica.push({ tipo: 'reparto', descrizione: 'Maggiorazioni totali', prezzo: maggiorazioniSub.toFixed(2) })


      // ------------------------------- Movimento contabili cassa ------------------------------------------------
      if (_.get(context, 'impostazioni.statistiche.rapide.gestioneFondocassa')) {
        const contabili = await operazioniCassa.getMovimentiContabili()
        context.statistica.push({ tipo: 'separatore' })
        context.statistica.push({ tipo: 'reparto', descrizione: 'Fondo cassa', prezzo: contabili.fondocassa.toFixed(2) })
        context.statistica.push({ tipo: 'reparto', descrizione: 'Contanti', prezzo: contanti.toFixed(2) })
        context.statistica.push({ tipo: 'reparto', descrizione: 'Versamenti', prezzo: contabili.versamenti.toFixed(2) })
        context.statistica.push({ tipo: 'reparto', descrizione: 'Prelievi', prezzo: contabili.prelievi.toFixed(2) })
        const totaleCassetto = contanti + contabili.fondocassa + contabili.versamenti - contabili.prelievi
        context.statistica.push({ tipo: 'totalecomplessivo', descrizione: 'Totale Cassetto', prezzo: totaleCassetto.toFixed(2) })
      }
      // ----------------------------------------------------------------------------------------------------------

        context.scontrinoEspanso = _.get(context.impostazioni, 'statistiche.rapide.espandiScontrino', true)
      }
      break;
    default:
      break;
  }
}

const funzioniRapide = pure((context, event) => {
  return send(context.confirmAction)
})

const impostaContanti = function (context, value) {
  let displayValue = context.displayValue
  if (value) {
    displayValue = value * 100
  }/*  else {
    if (event && event.type !== 'CALCOLA_TOTALE_SCONTRINO') {
      displayValue = '0'
    }
  } */
  // let importoDigitato = value && parseFloat(displayValue) / 100 || 0
  let importoDigitato = displayValue && parseFloat(displayValue) / 100 || 0
  const totaleVincite = parseFloat(totalePerTipoItem(context, 'VINCITA'))
  const delta = totaleVincite - context.vinciteCaricate
  if (delta !== 0) {
    importoDigitato += delta
    context.vinciteCaricate += delta
  }

  let pg = context.attributes && context.attributes.partialAccount && (parseFloat(context.attributes.partialAccount['contanti'] || 0)) + (parseFloat(context.attributes.partialAccount['cartadicredito'] || 0)) || 0
  // let pagato = context.giaPagato + importoDigitato + (context.resto || 0)
  let pagato = importoDigitato + pg
  let valoreDisplayValue = displayValue && (String(displayValue).indexOf('.') !== -1 ? parseFloat(displayValue) : parseFloat(displayValue) / 100) || 0
  // let totaleScontrino = parseFloat(context.totaleScontrino || 0) + (!value ? parseFloat(context.displayValue) || 0 : 0)
  let totaleScontrino = parseFloat(context.totaleScontrino || 0) + (!displayValue ? valoreDisplayValue : 0)
  if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
    if (pagato > totaleScontrino && totaleScontrino > 0) {
      context.resto = +(pagato - totaleScontrino).toFixed(2)
      context.giaPagato = totaleScontrino
    } else if (pagato === totaleScontrino) {
      context.resto = 0
      context.giaPagato = totaleScontrino
    } else {
      if (totaleVincite > 0 && totaleScontrino === 0) {
        context.resto = totaleVincite // TODO: Verificare
      } else {
        context.resto = null
      }

      context.giaPagato += importoDigitato
    }
    if (context.mostraVersato) {
      context.importoPagato.contanti = (context.importoPagato.contanti || 0) + importoDigitato
    } else {
      context.importoPagato.contanti = (context.importoPagato.contanti || 0) + (importoDigitato - (context.resto || 0))
    }
    if (context.resto != null && (parseFloat(context.resto) > 0 || totaleVincite > 0)) {
      context.displayDescrizione = 'Resto'
      context.displayMessage = ''
      context.displayValue = parseFloat(context.resto).toFixed(2)
      context.chiusuraPossibile = true
    } else {
      if (pagato > 0) {
        context.displayDescrizione = '' // verificare
        context.displayMessage = "L'importo non è sufficiente!"
        context.displayValue = '0'
      }
      context.chiusuraPossibile = false
    }
    context.attributes.partialAccount ||= {};
    context.attributes.partialAccount['contanti'] = context.importoPagato.contanti.toFixed(2)
    context.giaPagatoTotale += importoDigitato
    context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
    context.displayValue= '0'
  } else {
    // 'eventuali verifiche per aliquota
    context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
    context.chiusuraPossibile = false
  }
  context.scontrinoTick++
}


// TODO: modificare ciclo per richiamarlo una sola volta invece che per ogni messaggio aggiunto
// TODO: Dovrebbe accodare tutte le operazioni (sconti, totale, etc..)
var extIdScontrino = '' // verificare per debug
async function dequeueBarcode(context, cb) {
  while (!q.isEmpty()) {
    // let br = await q.shift()
    const bra = await q.shift()
    const brr = bra.split('§')
    let br = brr[0]
    const filter = brr.length === 2 ? brr[1] : ''
    let info = await barcode.getInfoBarcode(br, filter)
/*     if (context.chiusuraPossibile || context.modali.indexOf('Cashmatic') >= 0) {
      console.log('BARCODE CON CHIUSURA POSSIBILE !!')
      continue
    } */
    if (info.tipoBarcode === '') {
      // context.displayMessage = 'Tipo barcode non conosciuto'
      context.confirmToast = `Attenzione! Barcode: ${br} non conosciuto o errato`
      context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: 'beeps' }
      // TODO: eventualmente prevedere arresto coda + evento per riavviar
      continue
    }
    context.displayMessage = ''
    context.confirmToast = ''
    // Verifica tipobarcode

    if ((context.isSubTotaleCalcolato || _.get(context.impostazioni, 'fidelityCard.coupon.chiudeScontrino', false)) && ['coupon'].includes(info.tipoBarcode)) {
      // Applicare lo sconto sul subtotale
      const cp = await fidelity.getFidelity(info.code)
      if (cp && cp.coupon.importoResiduo === 0) {
        context.confirmToast = `Attenzione! Coupon scaduto o già utilizzato`
        context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: 'games' }
        continue
      }
      const totale = context.impostazioni.carrello.totale_solo_vendita ? fnCalcolaTotaleScontrinoNoResi(context) : context.totaleScontrino
      if (cp) {
        let valore = cp.coupon.importoResiduo
        const valoreSconto = Math.min(parseFloat(totale), valore)
        if (valoreSconto === parseFloat(totale)) {
          // Non posso fare uno sconto pari al totale del carrello perchè avrei totale scontrino = zero
          clearParzialeFunc(context)
          context.confirmToast = `Attenzione! Non è possibile effettuare uno sconto pari al totale del carrello`
          context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: 'games' }
          continue
        }
        if (context.righeCarrello.some(x => x.tipo === 'scontoSub')) {
          clearParzialeFunc(context)
          context.confirmToast = `Sconto subtotale già presente`
          context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: 'games' }
          continue
        }
      }
      if (!context.isSubTotaleCalcolato) {
        // Inserisce SUBTOTALE
        context.isSubTotaleCalcolato = true
        addNota(context, { type: 'NOTA', tipoRiga: 'separatore', nota: '', prezzo: '' })
        addNota(context, {
          type: 'NOTA',
          tipoRiga: 'subtotale',
          nota: 'Subtotale',
          prezzo: context.impostazioni.carrello.totale_solo_vendita ? fnCalcolaTotaleScontrinoNoResi(context) : context.totaleScontrino
        })
      }
      if (cp) {
        context.coupon = cp
        let valore = cp.coupon.importoResiduo
        const valoreSconto = Math.min(parseFloat(totale), valore)
        const uso = {
          data: new Date(),
          importo: +parseFloat(valoreSconto).toFixed(2)
        }
        context.coupon.coupon.utilizzi = context.coupon.coupon.utilizzi || []
        context.coupon.coupon.utilizzi.push(uso)
        context.coupon.coupon.importoUsato += valoreSconto
        context.coupon.coupon.importoResiduo -= valoreSconto
        aggiungiAbbuonoAlTotaleCarrello(context, valoreSconto)
      }
      clearParzialeFunc(context)
      continue
    }

    if (context.isSubTotaleCalcolato && !['fidelitycard', 'buonomultiuso', 'buonomonouso'].includes(info.tipoBarcode)) {
      clearParzialeFunc(context)
      continue
    }

    if (info.tipoBarcode !== 'prodotto') {
      if (info.code === '' && info.tipoBarcode === 'scontrino') {
        info.code = br
      }
      if(info.tipoBarcode === 'partitaiva') {
        // TODO: Verificare. Altrimenti dovrei modificare la gestione su tutta l'applicazione (fare solo se necessario...)
        info.tipoBarcode = 'codicefiscale'
      }
      // TODO: lotteria, codicefiscale e partitaiva da gestire solo se la finestra di richiesta è aperta
      // Vedi su gestione tastira --> aggiungo un prefisso fittizio es LOT_ (anche su formati_barcode)
      if (info.tipoBarcode === 'lotteria' || info.tipoBarcode === 'codicefiscale' || info.tipoBarcode === 'operatore') {
        info.code = br
      }
      cb({ data: info, type: 'BARCODE_DIVERSO' })
      continue
    }

    // let ev = { value: { code: info.code, tipoAliquota: 'banco' } } : versione con 2 richieste al db , la prima passando barcode ritorna codice prodotto
    let ev = { value: { code: null, barcode: (info.code || br || '').toUpperCase(), tipoAliquota: 'banco' } } // versione con unica chiamata, alternativa tra codice prodotto e barcode
    // TODO: Per velocizzare richiesta per barcode con quantità diversa da 1 (es tabaccai)
    //        --> In apertura popolo cache facendo una query sui prodotti leggendo da array barcode (codice + quantità (def = 1))

    // Gestione Peso Variabile --------------------------------------------------------------------
    context.prodottoAPeso = null
    const pesoVariabile = _.get(context, 'impostazioni.articoli.pesoVariabile', {})
    let prezzoVariabile = null
    if (pesoVariabile.gestione && br.startsWith(pesoVariabile.prefissoBarcode || '2')) {
      context.prodottoAPeso = pesoVariabile.tipoInformazione
      const suffisso = pesoVariabile.suffissoProdotto || '00000'
      const lenCodiceProdotto = 12 - suffisso.length
      const brProdotto = barcode.addEANCheckDigit(br.substring(0, lenCodiceProdotto) + (pesoVariabile.suffissoProdotto || '00000'))
      ev = { value: { code: null, barcode: brProdotto, tipoAliquota: 'banco' } }
      if (pesoVariabile.tipoInformazione === 'peso') {
        context.quantita = +(parseFloat(br.substring(lenCodiceProdotto, 12)) / (pesoVariabile.fattoreMoltiplicativo || 1000)).toFixed(3)
      } else {
        prezzoVariabile = +(parseFloat(br.substring(lenCodiceProdotto, 12)) / (pesoVariabile.fattoreMoltiplicativo || 100)).toFixed(2)
      }
    }
    // --------------------------------------------------------------------------------------------

    let pr = await getInfoProdotto(context, ev)
    if (pr) {
      context.displayCodice = info.code
      context.displayDescrizione = pr.descrizione
      if (context.prodottoAPeso === 'prezzo') {
        context.prodotto.prezzo = prezzoVariabile
      }
      if (context.richiestaPrezzo) {
        context.displayValue =  parseFloat(context.prodotto.prezzo).toFixed(2)
        context.displayCodice = context.prodotto.ricercaTC.toLowerCase()
        continue
      }
      aggiungiProdottoAlCarrello(context)
      // console.log('dopoadd:', context.rigaCarrello.codice)

      if (context.idScontrino === '') {
        console.log('crea scontrino da barcode')
        let result = await scontrini.nuovoScontrino(configurazioni.getPostazione())
        context.idScontrino = result._id
        extIdScontrino = result._id
        console.log('creato scontrino da barcode', context.idScontrino)
      }
      context.impostaVincita = true
      try {
        // console.log('addRigaScontrino')
        await scontrini.addRigaScontrino(context.idScontrino, context.rigaCarrello)
        // console.log('dopoaddriga:', context.rigaCarrello.codice)
      } catch(err) {
        console.log('errore stampante ?')
      }


      // deve richiamare RIGA_AGGIUNTA ?
    } else {
      // context.displayMessage = 'Prodotto non trovato'
      context.confirmToast = `Attenzione! Prodotto non trovato per barcode: ${br}`
      context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: 'games' }
    }
  }
  context.totaleScontrino = fnCalcolaTotaleScontrino(context)
  context.itemsScontrino = fnCalcolaNumeroItemScontrino(context)
  context.scontrinoTick = context.scontrinoTick + 1
  if (!context.richiestaPrezzo) {
    context.displayValue = '0'
    context.displayDescrizione = ''
  }
  context.richiestaPrezzo = false
  context.quantita = 1
  context.prezzoImposto = ''
}

async function dequeueBarcodeSynchronous(context, cb) {
  await lock.acquire();
  try {
    await dequeueBarcode(context, cb);
  } catch {}
  lock.release();
  if (extIdScontrino && !context.idScontrino) {
    context.idScontrino = extIdScontrino
  }
}

async function aggiungiNota (context, event) {
  if (event.value.testo.length === 0) return

  let scontrinoCreato = false
  if (context.idScontrino === '') {
    console.log('crea scontrino da aggiungiNota')
    let result = await scontrini.nuovoScontrino(configurazioni.getPostazione())
    context.idScontrino = result._id
    console.log('creato scontrino da aggiungiNota', context.idScontrino)
    scontrinoCreato = true
  }
  const note = event.value.testo.length > 30 ? event.value.testo.split(/(.{30})/gy) : [event.value.testo]
  var riga
  for (let nota of note) {
    riga = {
      quantita: 0,
      descrizione: nota,
      prezzoUnitario: 0,
      prezzo: '',
      tipo: 'descrizione',
      rigaCodice: uuidv4()
    };
    context.righeCarrello.push(riga)
  }
  context.rigaCarrello = riga;
  if (scontrinoCreato) {
    await scontrini.addRigaScontrino(context.idScontrino, context.rigaCarrello)
  }
}

async function gestioneParcheggio(context, event) {
  const comando = event.payload.command
  const conto = event.payload.conto
  const zona = event.payload.zona
  const posizione = event.payload.posizione
  const descrizione = event.payload.descrizione

  let dati = null
  switch(comando) {
    case 'apre':
      const scontrino = await scontrini.getScontrinoById(conto)
      context.idScontrino = conto
      context.righeCarrello = scontrino.righe
      // TODO: calcola totale
      dati = {
        parcheggio: {
          stato: "aperto",
          zona: scontrino.parcheggio.zona,
          posizione: scontrino.parcheggio.posizione,
          data: new Date().toJSON()
        }
      }
      // await scontrini.updateScontrino(context.idScontrino, dati)
      const newSc = await scontrini.updateScontrinoChangeDate(context.idScontrino, dati, new Date())
      context.idScontrino = newSc._id
      context.modali.splice(-1, 1)
      for (let r of context.righeCarrello) {
        if (r.tipoBene === 'VINCITA') {
          context.impostaVincita = true
          context.vinciteCaricate += +(parseFloat(r.prezzo)).toFixed(2)
          impostaContanti(context, +(parseFloat(r.prezzo)).toFixed(2))
        }
      }

      context.totaleScontrino = fnCalcolaTotaleScontrino(context),
      context.itemsScontrino = fnCalcolaNumeroItemScontrino(context),
      context.scontrinoTick++
      context.infoParcheggio = scontrino.parcheggio.posizione
      context.messaggioToast = 'Apertura conto parcheggiato'
      break
    case 'parcheggia':
      dati = {
        parcheggio: {
          stato: "occupato",
          zona: zona,
          posizione: posizione,
          data: new Date().toJSON()
        }
      }
      await scontrini.updateScontrino(context.idScontrino, dati)
      context.modali.splice(-1, 1)
      resetValues(context)
      context.attributes = {}
      context.importoPagato = {}
      context.messaggioToast = 'Conto parcheggiato'
      break
    case 'sposta':
      dati = {
        parcheggio: {
          stato: "occupato",
          zona: zona,
          posizione: posizione,
          data: new Date().toJSON()
        }
      }
      await scontrini.updateScontrino(context.idScontrino, dati)
      context.modali.splice(-1, 1)
      resetValues(context)
      context.messaggioToast = 'Conto parcheggiato su nuova posizione'
      break
    case 'accoda':
      await scontrini.addRigheScontrino(conto, context.righeCarrello)
      context.messaggioToast = 'Il conto è stato accodato a quello selezionato'
      context.modali.splice(-1, 1)
      resetValues(context)
      break
    case 'annulla':
      await scontrini.annullaParcheggio(conto)
    case 'rinomina':
      let parcheggio = await configurazioni.getConfigurazione('parcheggio')
      let pZone = parcheggio.zone
      let pZona = pZone.find(x => x.codice === zona)
      if (pZona) {
        let pPosizione = pZona.posizioni.find(x => x.codice === posizione)
        // Non modifica "title", altrimenti problemi con ripristino
        if (pPosizione) {
          pPosizione.descrizione = descrizione || ''
          if (descrizione) {
            pPosizione.dataDescrizione = new Date()
          } else {
            delete pPosizione.dataDescrizione
          }
          await configurazioni.modificaConfigurazione(parcheggio)
        }
      }
    default:
      break
  }
  context.aggiornaParcheggio++
}

async function shutdownPC() {
  await configurazioni.shutdownPC()
}

function resetValues(context) {
  context.displayValue = "0"
  context.displayDescrizione = ''
  context.displayMessage = ''
  context.operand1 = null
  context.operand2 = null
  context.operator = null
  context.quantita = 1
  context.quantitaModificata = false
  context.page = 1
  context.pageList = { tasti: 1, tasti2: 1 }
  context.righeCarrello = []
  context.righeNonFiscali = []
  context.rigaCarrello = null
  context.scontrinoStampato = null
  context.idScontrino = ''
  extIdScontrino = ''
  context.prodotto = null
  context.reparto = null
  context.richiestaPrezzo = false
  context.prezzoImposto = ''
  context.totaleScontrino = ''
  context.totaleVincite = ''
  context.itemsScontrino = ''
  context.isSubTotaleCalcolato = false
  context.rigaSelezionata = null
  context.scontrinoTick = 0
  context.attributiTick = 0
  context.cliente = null
  context.giaPagato = 0
  context.giaPagatoTotale = 0
  context.daPagareTotale = 0
  context.chiusuraPossibile = false
  context.stampaEffettuata = false
  context.resto = null
  context.tipoDocumento = 'scontrino'
  context.tipoDocumentoDesc = 'Scontrino'
  context.documento = null
  context.attesaCodiceLotteria = false
  context.attesaCodiceFiscale = false
  context.attesaCodiceScontrino = false
  context.barcodeUltimoScontrino = ''
  context.infoScontrinoGestionale = null
  context.displayCodice = ''
  context.resoInCorso = false
  context.resoFiscale = {}
  context.righeReso = []
  context.righeParcheggiate = []
  context.statistica = null
  context.rendiItemVisible = false
  context.recuperoInCorso = false
  context.confermaResoVisibile = false
  context.titoloScontrino = ''
  context.confermaAnnulloVisibile = false
  context.scontrinoRecupero = null
  context.scontrinoAnnullamento = null
  context.scontrinoErroreComando = null
  context.targetDoneScontrini = ''
  context.arrotondamentoPresente = false
  context.buonoMultiuso = null
  context.buonoMultiusoUtilizzo = null
  context.coupon = null
  context.coupons = []
  context.buoniMultiuso = []
  context.buonoMonouso = null
  context.buonoMonousoUtilizzo = null
  context.descrizioneBuono = ''
  context.buonoCaricato = false
  context.confermaChiusuraConBuono = false
  context.fidelityCard = null
  context.fidelityCardDaAssociare = false
  context.fidelityCardAssociata = false
  context.fidelityCardDaCreare = ''
  context.fidelityCardUtilizzo = null
  context.messaggioToast = ''
  context.confirmToast = ''
  context.fidelityComandi = null
  context.pagaAppuntamentoInCorso = 0
  context.displayFocus = 0
  context.resetBuffer = 0
  context.scontrinoEspanso = false
  context.reloadPage =  false
  context.importDati = false
  context.barcodePerGenerico = ''
  context.listino = context.impostazioni.listini.base
  context.infoParcheggio = null
  context.printerInitialStatusRequest = false
  context.chiusuraGiornalieraInCorso = false
  context.infoRistampaBuono = null
  context.righeDetraibiliNoCodFis = false
  context.isRigheReso = false
  context.noteDiCoda = []
  context.righeFiscali = false
  context.usaSelezione = {}
  context.inputDescrizione = ''
  context.cassaOperazione = '',
  context.pagamentoCashmaticEffettuato = false
  context.vinciteCaricate = 0
  context.aggiornaParcheggio = 0
  context.tastieraGenericaValue = null
  context.testoNotaGenerica = ''
  context.prodottoAPeso = null
  context.importoPagato = {}
  context.menuTotali = null
}

function clearParzialeFunc(context) {
  context.displayValue = "0"
  context.displayDescrizione = ''
  context.displayMessage = ''
  context.prodotto = null
  context.reparto = null
  context.quantita = 1
  // context.resto = null
  context.quantitaModificata = false
  context.operand1 = null
  context.richiestaPrezzo = false
  context.prezzoImposto = ''
  context.attesaCodiceLotteria = false
  context.attesaCodiceFiscale = false
  context.attesaCodiceScontrino = false
  context.confermaChiusuraConBuono = false
  context.resetBuffer = context.resetBuffer + 1
}

async function infoFidelity(context, event) {
  let card = await fidelity.getFidelity(event.value.code)
  if (card && !card.error) {
    card.descrizione = `${card.codice}`
    context.fidelityCard = card
    if (card.associato) {
      // carica il cliente
      context.cliente = await clienti.getCliente(card.cliente.codice)
      context.fidelityCardAssociata = true
      const impCard = context.impostazioni.fidelityCard
      if (card.raccoltaPunti && card.raccoltaPunti.abilita) { // TODO: Anche date validità
        context.fidelityCard.punti = card.raccoltaPunti.totale
        context.fidelityCard.puntiPrecedenti = card.raccoltaPunti.totale
        // Verifica eventuali limiti da segnalare
        if (impCard) {
          const limite = _.get(impCard, 'limiteDaSegnalare.punti', 0)
          context.fidelityCard.limiteRaggiunto = (limite && card.raccoltaPunti.totale >= limite)
          if (context.fidelityCard.limiteRaggiunto) {
            context.confirmToast = `Il cliente ha superato ${limite} punti !!!`
            context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: impCard.limiteDaSegnalare.sound || '' }
            const tipoStampa = _.get(impCard, 'limiteDaSegnalare.tipoStampa', 'coupon')
            const valoreCoupon = _.get(impCard, 'limiteDaSegnalare.coupon.valore', 20)

            // context.infoScontrinoGestionale = _.get(impCard, 'limiteDaSegnalare.scontrino', null)
/*             let scontrino_info = _.get(impCard, 'limiteDaSegnalare.scontrino', [])
            let scontrinoComandi = _.get(impCard, 'limiteDaSegnalare.scontrinoComandi', []) */
            let scontrino_info = JSON.parse(JSON.stringify(_.get(impCard, 'limiteDaSegnalare.scontrino', [])))
            let scontrinoComandi = JSON.parse(JSON.stringify(_.get(impCard, 'limiteDaSegnalare.scontrinoComandi', [])))
            let barcode = null
            const puntiTotali = card.raccoltaPunti.totale
            const puntiResidui = puntiTotali - limite
            if (scontrino_info.length > 0) {
              try {
                for (let i = 0; i < scontrino_info.length; i++) {
                  scontrino_info[i] = scontrino_info[i].replace('#cliente#', context.cliente.ragioneSociale)
                  scontrino_info[i] = scontrino_info[i].replace('#codice_cliente#', context.cliente._id)
                  scontrino_info[i] = scontrino_info[i].replace('#fidelity#', context.fidelityCard.codice)
                  scontrino_info[i] = scontrino_info[i].replace('#punti#', limite)
                  scontrino_info[i] = scontrino_info[i].replace('#valore#', valoreCoupon)
                  scontrino_info[i] = scontrino_info[i].replace('#punti_totali#', puntiTotali)
                  scontrino_info[i] = scontrino_info[i].replace('#punti_residui#', puntiResidui)
                }
                if (_.get(impCard, 'limiteDaSegnalare.barcode_su_scontrino', false)) {
                  barcode = {
                    code: context.fidelityCard.codice
                  }
                }
                if (tipoStampa === 'coupon') {
                  // crea il coupon come fidelity card e associa numero
                  const idCassa = context.impostazioni.stampante.progressivo_stampante
                  const applicaA = _.get(impCard, 'limiteDaSegnalare.coupon.applicaA', 'subtotale')
                  const coupon = await fidelity.creaCoupon(idCassa, valoreCoupon, applicaA)
                  barcode.code = coupon.codice
                  const tipoStampante = _.get(impCard, 'limiteDaSegnalare.coupon.tipoStampante', 'fiscale')
                  if (tipoStampante === 'fiscale') {
                    await stampante.stampaScontrinoGestionale(scontrino_info, barcode)
                  } else {
                    await escpos.stampaCoupon(scontrino_info, barcode)
                  }
                } else {
                  await stampante.stampaScontrinoGestionale(scontrino_info, barcode)
                }
              } catch (err) {
                console.log(err)
              }
            }
            if (scontrinoComandi.length > 0) { // in questo caso solo su Stampante ESCPOS --> comande
              try {
                var coupon
                if (tipoStampa === 'coupon') {
                  // crea il coupon come fidelity card e associa numero
                  const idCassa = context.impostazioni.stampante.progressivo_stampante
                  const applicaA = _.get(impCard, 'limiteDaSegnalare.coupon.applicaA', 'subtotale')
                  coupon = await fidelity.creaCoupon(idCassa, valoreCoupon, applicaA)
                }
                for (let i = 0; i < scontrinoComandi.length; i++) {
                  scontrinoComandi[i].v1 = String(scontrinoComandi[i].v1).replace('#cliente#', context.cliente.ragioneSociale)
                  scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#codice_cliente#', context.cliente._id)
                  scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#fidelity#', context.fidelityCard.codice)
                  scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#punti#', limite)
                  scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#valore#', valoreCoupon)
                  scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#punti_totali#', puntiTotali)
                  scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#punti_residui#', puntiResidui)
                  // TODO: EAN13 deve passare 12 char, verificare per altri
                  scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#barcode#', coupon.codice.substring(0, 12))
                }
                if (tipoStampa === 'coupon') {
                  const tipoStampante = _.get(impCard, 'limiteDaSegnalare.coupon.tipoStampante', 'fiscale')
                  if (tipoStampante === 'comande') {
                    await escpos.stampaCouponComandi(scontrinoComandi)
                  }
                }
              } catch (err) {
                console.log(err)
              }
            }
            if (_.get(impCard, 'limiteDaSegnalare.azzeramento.abilita', false)) {
              /* const puntiTotali = card.raccoltaPunti.totale
              const puntiResidui = puntiTotali - limite */
              const descrizione = _.get(impCard, 'limiteDaSegnalare.azzeramento.descrizione', 'Buono da #punti# punti')
              card.raccoltaPunti.totale = puntiResidui
              card.raccoltaPunti.dataAzzeramento = new Date()
              card.raccoltaPunti.azzeramenti = card.raccoltaPunti.azzeramenti || []
              card.raccoltaPunti.azzeramenti.push({
                data: card.raccoltaPunti.dataAzzeramento,
                punti: puntiTotali,
                puntiResidui: puntiResidui,
                puntiUtilizzati: limite,
                descrizione: descrizione.replace('#punti#', limite)
              })
              await fidelity.aggiornaFidelity(card._id, card)
              context.fidelityCard.punti = puntiResidui
            }
          }
        }
      }
      _.set(context.cliente, 'calcolati.sconto', _.get(card,'generale.sconto', '') || _.get(context.cliente, 'calcolati.sconto', ''))
      // TODO: Verificare se c'è uno scontrino aperto e se il listino nuovo è diverso da quello presente
      // --> chiede conferma e aggionra prezzi al nuovo listino !!
      context.listino = _.get(card, 'generale.listino', '') || context.listino
      // TODO: Verificare eventuali offerte per TipoCliente
      // card.tipoCliente
      if (impCard.offerte && card.tipoCliente) {
        let offerte = impCard.offerte.filter(x => x.tipoCliente === card.tipoCliente && moment().isBetween(x.dallaData || moment().add(-1,'d'), x.allaData || moment().add(20, 'y')))
        if (offerte.length > 0) {
          const offerta = offerte[0] // prende la prima attiva...rivedere se necessario
          context.fidelityCard.offerteAttive = offerta
          if (offerta.listino) {
            context.listino = offerta.listino
          }
/*                   for (let f of offerte) {
            if (f.offerta.listino) {
              context.listino = f.listino
            }
          } */
        }
      }
    } else {
      // avvia procedura di associazione
      context.fidelityCardDaAssociare = true
      context.fidelityCardAssociata = false
      context.messaggioToast = "E' necessario associare la tessera ad un cliente"
    }
  } else {
    context.fidelityCard = null
    context.fidelityCardDaAssociare = false
    context.fidelityCardAssociata = false
    if (event.value.code) {
      context.fidelityCardDaCreare = event.value.code
      context.confirmToast = 'Confermi creazione nuova Fidelity card ?'
      context.confirmToastObj = { event: 'FIDELITY_NUOVA', title: 'Tessera non in archivio', cancelText: 'NO' , confirmText: 'SI' }
    }
  }
}

const sendResetTimerAfterDelay = send('TIMED_RESET', {
  delay: (context) => (context.impostazioni.cassa.secondiNuovoScontrino || 0) * 1000 ,
  id: 'resetTimer'
})
const cancelResetTimer = cancel('resetTimer')

const sendResetMemoriaTotale = send('TIMED_RESET_MEMORIA_TOTALE', {
  delay: (context) => (context.impostazioni.cassa.secondiTimeoutMemoriaTotale || 0) * 1000,
  id: 'resetMemoriaTotale'
})

function doMath(operand1, operand2, operator) {
  switch (operator) {
    case "+":
      return +operand1 + +operand2;
    case "-":
      return +operand1 - +operand2;
    case "/":
      return +operand1 / +operand2;
    case "x":
      return +operand1 * +operand2;
    default:
      return Infinity;
  }
}
/* **********************  TODO ****************************************
  - nuova funzione per cancella scontrino ?
  - la funzione CLEAR_EVERYTHING cancella tutta l'area display ?
  - la funzione CLEAR_ENTRY cancella campo
  - la funzione CLEAR cancella ultimo carattere

*********************************************************************** */
// This machine is completely decoupled from Vue
export const dashboardMachine = Machine({
  id: 'dashboardMachine',
  context: {
    // **********  Impostazioni Generali da DB Configurazioni:display_generale  ************
    impostazioni: {},
    // ***************************************************************
    layoutTasti: '',
    displayValue: "0",
    displayDescrizione: '',
    displayMessage: '',
    operand1: null,
    operand2: null, // da togliere
    listino: '', // context.impostazioni.listini.base, //  "L1", // da impostazioni
    cliente: null,
    appuntamento: null,
    pagaAppuntamentoInCorso: 0,
    tipoDocumento: 'scontrino',
    tipoDocumentoDesc: 'Scontrino',
    documento: null,
    totaleScontrino: '',
    totaleVincite: '',
    itemsScontrino: '',
    quantita: 1,
    quantitaModificata: false,
    quantitaIsPeso: false,
    prezzoImposto: '',
    displayCodice: '',
    ultimoProdottoIsCodice: true,
    page: 1,
    pageList: { tasti: 1, tasti2: 1 },
    rigaCarrello: null,
    righeCarrello: [],
    righeNonFiscali: [],
    noteDiCoda: [],
    righeFiscali: false,
    statistica: null,
    statisticaOffsetGioni: 0,
    // valori preparatori per aggiunta a carrello. Vengono compilati a seconda della selezione dei tasti
    reparto: null, // { codice, descrizione, prezzoPredefinito, aliquota, etc.}
    prodotto: null,
    variante: null,
    richiestaPrezzo: false,
    modalType: '',
    modalShow: false,
    modali: [],
    popups: [
      { type: 'CercaScontrino', show: false },
      { type: 'CercaOperatore', show: false },
      { type: 'CercaCliente', show: false },
      { type: 'CercaBuono', show: false },
      { type: 'Fidelity', show: false },
      { type: 'Funzioni', show: false },
      { type: 'Appuntamento', show: false },
      { type: 'CercaProdotto', show: false },
      { type: 'Report', show: false },
      { type: 'UsaSelezione', show: false },
      { type: 'InserimentoTesto', show: false },
      { type: 'Movimenti', show: false },
      { type: 'Tastiera', show: false },
      { type: 'TastieraNote', show: false },
      { type: 'TastieraGenerica', show: false },
      { type: 'CercaDocumento', show: false },
      { type: 'Backoffice', show: false }

/*       { type: 'AssociaFidelity', show: false },
      { type: 'GestioneFidelity', show: false } */
    ],
    isSubTotaleCalcolato: false, // vedere come calcolare
    rigaSelezionata: null,
    resto: null,
    scontrinoStampato: null,
    scontrinoEspanso: false,
    idScontrino: '',
    scontrinoSelezionato: '',
    scontrinoErroreComando: null,
    barcodeUltimoScontrino: '',
    comandoScontrini: '',
    targetDoneScontrini: '',
    infoChiusuraScontrino_old: {
      pagamento: null,
      contanti: null,
      totale: null,
      resto: null
    },
    attributes: {},
    rigaStampa: null,
    scontrinoTick: 0, // per richiedere aggiornamento interfaccia scontrino nel caso di operazioni all'interno della riga (es sconti)
    attributiTick: 0,
    reloadPage: false,
    importDati: false,
    invioDisplayInCorso: false,
    bloccaCassa: false,
    togglePrezzoAcquisto: false,
    usaSelezione: {},
    inputDescrizione: '',
    cassaOperazione: '',
    pagamentoCashmaticEffettuato: false,
    prodottoAPeso: null,
    // ***************************************************************
    mostraVersato: true, // visualizza il denaro versato, non sottratto il resto
    giaPagato: 0,
    giaPagatoTotale: 0,
    daPagareTotale: 0,
    // giaPagatoTesto: giaPagato.toFixed(2),
    importoPagato: {},
    // totaleImportoScontrino: 0,
    infoChiusuraScontrino: {
      pagamenti: [],
      contanti: 0,
      totale: null,
      resto: null
    },
    chiusuraPossibile: false,
    stampaEffettuata: false,
    // codiceLotteriaInserito: false,
    attesaCodiceLotteria: false,
    attesaCodiceFiscale: false,
    righeDetraibiliNoCodFis: false,
    titoloScontrino: '',
    // bloccoInserimentoCodici: false,
    tipoInserimentoTesto: '',
    barcodePerGenerico: '', // Inserimento articolo generico (memorizza barcode di articolo non ancora presente in anagrafica, per successivi movimenti)
    infoParcheggio: null,
    printerInitialStatusRequest: false,
    chiusuraGiornalieraInCorso: false,
    infoRistampaBuono: null,
    infoScontrinoGestionale: null,
    memoriaTotale: '',
    helpEnabled: false,
    helpSource: '',
    menuTotali: null,
    // ***************  Gestione Resi  ***************************************
    resoInCorso: false,
    resoFiscale: {}, // info fiscali del documento di reso
    righeReso: [],
    righeParcheggiate: [], // righe presenti nel carrello prima della lettura di uno scontrino di reso
    rendiItemVisible: false, // mostra il tasto rendi item se c'è un reso in corso ed è stata selezionata una riga
    confermaResoVisibile: false, // calcolare : true se ho selezionato delle righe da rendere
    isRigheReso: false, // Nel carrello sono presenti righe di reso
    // ***************  Gestione Recupero Scontrino  *****************
    attesaCodiceScontrino: false, // gestione recupera scontrino, annulla, etc.
    recuperoInCorso: false,
    confermaAnnulloVisibile: false,
    scontrinoRecupero: null,
    scontrinoAnnullamento: null,
    // **************************************************************
    arrotondamentoPresente: false,
    operatore: {},
    utente: null,
    richiediPin: false,
    erroreStampante: null,
    messaggioToast: '',
    confirmToast: '',
    confirmAction: null,
    idEliminare: '',
    displayFocus: 0,
    resetBuffer: 0,
    impostaVincita: false,
    vinciteCaricate: 0,
    aggiornaParcheggio: 0,
    tastieraGenericaValue: null,
    testoNotaGenerica: '',
    inviaBenvenutoAMF: false,
    // ***************  Fidelity  ************************************
    fidelityCard: null,
    buoniMultiuso: [],
    buonoMultiuso: null,
    buonoMultiusoUtilizzo: null, // memorizza l'id dell'array su cui è stato caricato l'eventuale utilizzo del buono (potrei aver selezionato un buono ma non usarlo)
    coupon: null,
    coupons: [],
    buonoMonouso: null,
    buonoMonousoUtilizzo: null,
    descrizioneBuono: '',
    buonoCaricato: false,
    fidelityCardDaAssociare: false,
    fidelityCardAssociata: false,
    fidelityCardDaCreare: '',
    fidelityComandi: null,
    fidelityCardUtilizzo: null,// memorizza l'id dell'array su cui è stato caricato l'eventuale utilizzo della prepagata (potrei aver selezionato una card ma non usarla)
    // ***************  Debug  ***************************************
    debug: { infoTasti: false, setupTasti: false }
    // ************* Spawned machines ********************************
  },
  on: {
    "*": {
      cond: (context) => context.stampaEffettuata,
      actions: (context) => console.log("bloccato ")
    },
    CLEAR_EVERYTHING: {
      target: ".start",
      actions: ["reset"]
    },
    CLEAR_PARTIAL: {
      target: "operand1",
      actions: ["clearParziale"]
    },
    PRODOTTI_PAGINA: {
      actions: [
        "setPage",
        assign({ displayFocus: (context) => context.displayFocus + 1 })
      ]
    },
    REPARTI_PAGINA: [
      {
        cond: (context) => context.displayValue === '0' || context.displayValue === '',
        actions: [
          "setPage",
          assign({ displayFocus: (context) => context.displayFocus + 1 })
        ]
      },
      {
        cond: (context) => context.displayValue !== '0' && !context.isSubTotaleCalcolato,
        target: "reparto_info",
        actions: [
          assign({ displayFocus: (context) => context.displayFocus + 1 })
        ]
      }
    ],
    CALCOLA_TOTALE_SCONTRINO : {
      actions: [
        "calcolaTotaleScontrino",
        // (context, event) => setTimeout(() => impostaContanti(context, 0, event), 100)
        (context) => context.impostaVincita = true
      ]
    },
    STAMPA_RIGA: {
      target: "gestioneScontrino",
      actions: [
        assign({ comandoScontrini: () => 'ADD_RIGA' }),
        assign({ rigaStampa: (context, event) => event.riga || context.rigaCarrello})
      ]
    },
    MODIFICA_RIGA: {
      target: "gestioneScontrino",
      actions: [
        assign({ comandoScontrini: () => 'UPDATE_RIGA' }),
        assign({ rigaStampa: (context, event) => event.riga })
      ]
    },
    CANCELLA_RIGA: [
      {
        target: "gestioneScontrino",
        actions: [
          assign({ comandoScontrini: () => 'DELETE_RIGA' }),
          assign({ rigaStampa: (context, event) => event.riga }) // TODO: verificare
        ]
      }
    ],
/*     CANCELLA_RIGA: {
      target: "gestioneScontrino",
      actions: [
        assign({ comandoScontrini: () => 'DELETE_RIGA' })
      ]
    }, */
    STAMPA_TOTALE: {
      target: "gestioneScontrino",
      actions: assign({ comandoScontrini: () => 'ADD_TOTALE' })
    },
    STAMPA_SCONTRINO: [
      {
        cond: (context) => context.tipoDocumento === 'scontrino' || context.tipoDocumento === 'scontrinoSegueFattura',
        target: "gestioneScontrino",
        actions: [
          assign({ comandoScontrini: () => 'STAMPA_SCONTRINO' })
        ]
      },
      {
        cond: (context) => context.tipoDocumento !== 'scontrino',
        target: "verifica_chiusura",
        actions: [
          send('VERIFICA_USO_BUONI')
        ]
      }
    ],



/*     STAMPA_SCONTRINO: {
      target: "gestioneScontrino",
      actions: [
        // assign({ bloccoInserimentoCodici: () => true }),
        assign({ comandoScontrini: () => 'STAMPA_SCONTRINO' })
      ]
    }, */
    EDIT_CONTEXT: {
      actions: ['editContext']
    },
    APRI_MODALE: {
      // Oltre ad aprire la modale corretta deve passare il controllo alla macchina a stati che gestisce la modale (es clienti, codici, note, etc.)
      // per debug tutto qui, poi spostare
      target: 'modale_aperta',
      actions: ['apriModale']
    },
    APRI_POPUP: {
      target: "popup_aperto",
      actions: ['apriPopup']
    },
    EXPAND: {
      actions: assign({ scontrinoEspanso: (context,event) => !context.scontrinoEspanso }),
    },
    FUNZIONI: { // COMPORTAMENTO DA DEFINIRE--> APRE MODALE CON VARIE IMPOSTAZIONI...
      actions: [
        // 'setPage',
        send(() => ({ value: 'Funzioni', type: 'APRI_MODALE' }))
      ]
    },
    INFO_TASTI: {
      actions: (context) => {
        context.debug.infoTasti = !context.debug.infoTasti
        context.popups.find(el => el.type === 'Funzioni').show = false
      }
    },
    SETUP_TASTI: {
      actions: (context) => {
        context.debug.setupTasti = !context.debug.setupTasti
        context.popups.find(el => el.type === 'Funzioni').show = false
      }
    },
    DOCUMENTO: [
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond:  (context) => !context.cliente,
        actions: assign({ displayMessage: () => "E' necessario selezionare un cliente !!" })
      },
      {
        cond: (context) => context.cliente,
        target: "",
        actions: (context, event) => {
          if (context.tipoDocumento === 'scontrino') {
            context.tipoDocumento = event.value.tipoDocumento
            context.tipoDocumentoDesc = event.value.tipoDocumentoDesc || event.value.tipoDocumento
          } else {
            context.tipoDocumento = 'scontrino'
            context.tipoDocumentoDesc = 'Scontrino'
          }
        }
      }
    ],
    NON_RISCOSSO: [
      // In base al payload della chiamata, definisco il tipoDocumento
      // In questo caso la chiusura non può essere parziale --> o tutto NON_RISCOSSO o altri pagamenti
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
/*       {
        cond: (context) => context.chiusuraPossibile
      }, */
      {
        cond:  (context) => !context.cliente,
        actions: assign({ displayMessage: () => "E' necessario selezionare un cliente !!" })
      },
      {
        cond: (context) => context.cliente,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            let totaleScontrino = parseFloat(context.totaleScontrino)
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              context.giaPagato = totaleScontrino
              context.importoPagato.nonriscosso = totaleScontrino
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['nonriscosso'] = context.importoPagato.nonriscosso.toFixed(2)
              context.giaPagatoTotale = totaleScontrino
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
              context.chiusuraPossibile = true
            } else {
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      }
    ],
    NON_RISCOSSO_OLD: [ // bisogna verificare il totale negativo dello scontrino
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.chiusuraPossibile
      },
      {
        cond:  (context) => !context.cliente && context.totaleScontrino,
        actions: assign({ displayMessage: () => "E' necessario selezionare un cliente !!" })
      },
      {
        cond: (context) => context.displayValue === '0' && context.cliente && context.tipoDocumento === 'fatturaImmediata' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context,event) => {
            let totaleScontrino = parseFloat(context.totaleScontrino)
            let importo = totaleScontrino - context.giaPagato
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              context.importoPagato.nonriscosso = (context.importoPagato.nonriscosso || 0) + importo
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['nonriscosso'] = context.importoPagato.nonriscosso.toFixed(2)
              context.chiusuraPossibile = true
              context.giaPagatoTotale += importo
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      },
      { // ### TODO: Verificare se e come gestire !!!
        cond: (context) => false && context.displayValue === '0' && context.cliente && context.tipoDocumento !== 'fatturaImmediata' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context,event) => {
            let totaleScontrino = parseFloat(context.totaleScontrino)
            let importo = totaleScontrino - context.giaPagato
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              context.importoPagato.nonriscosso = (context.importoPagato.nonriscosso || 0) + importo
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['nonriscosso'] = context.importoPagato.nonriscosso.toFixed(2)
              context.chiusuraPossibile = true
              context.giaPagatoTotale += importo
              context.daPagareTotale = totaleScontrino - context.giaPagatoTotale
            } else {
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      },
      { // ### TODO: Verificare se e come gestire !!!
        cond: (context) => false && context.displayValue !== '0' && context.cliente && context.tipoDocumento !== 'fatturaImmediata' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            // Se il pagamento è con fattura a fine mese --> OK, altrimenti va verificato che il credito sia sufficiente
            // Va verificato che il credito sia sufficiente
            let importoDigitato = parseFloat(context.displayValue) / 100
            let pagato = context.giaPagato + importoDigitato
            let totaleScontrino = parseFloat(context.totaleScontrino)
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              if (pagato > totaleScontrino) { // Verificare se accettabile per normativa o per impostazioni
                context.resto = +(pagato - totaleScontrino).toFixed(2)
                context.giaPagato = totaleScontrino
              } else if (pagato === totaleScontrino) {
                context.resto = 0
                context.giaPagato = totaleScontrino
              } else {
                context.resto = null
                context.giaPagato += importoDigitato
              }
              if (context.mostraVersato) {
                context.importoPagato.nonriscosso = (context.importoPagato.nonriscosso || 0) + importoDigitato
              } else {
                context.importoPagato.nonriscosso = (context.importoPagato.nonriscosso || 0) + (importoDigitato - (context.resto || 0))
              }
              if (context.resto != null) {
                context.displayDescrizione = 'Resto'
                context.displayMessage = ''
                context.displayValue = parseFloat(context.resto).toFixed(2)
                context.chiusuraPossibile = true
              } else {
                context.displayDescrizione = '' // verificare
                context.displayMessage = "L'importo non è sufficiente!"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              }
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['nonriscosso'] = context.importoPagato.nonriscosso.toFixed(2)
              context.giaPagatoTotale += importoDigitato
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              // 'eventuali verifiche per aliquota
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      }
    ],
    BUONI_PASTO: [
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.chiusuraPossibile
      },
      {
        cond: (context) => context.displayValue === '0' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context,event) => {
            let totaleScontrino = parseFloat(context.totaleScontrino)
            let importo = totaleScontrino - context.giaPagato
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              context.importoPagato.buonipasto = (context.importoPagato.buonipasto || 0) + importo
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['buonipasto'] = context.importoPagato.buonipasto.toFixed(2)
              context.chiusuraPossibile = true
              context.giaPagatoTotale += importo
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      },
      {
        cond: (context) => context.displayValue !== '0' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            let importoDigitato = parseFloat(context.displayValue) / 100
            let pagato = context.giaPagato + importoDigitato
            let totaleScontrino = parseFloat(context.totaleScontrino)
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              if (pagato > totaleScontrino) { // Verificare se accettabile per normativa o per impostazioni
                context.resto = +(pagato - totaleScontrino).toFixed(2)
                context.giaPagato = totaleScontrino
              } else if (pagato === totaleScontrino) {
                context.resto = 0
                context.giaPagato = totaleScontrino
              } else {
                context.resto = null
                context.giaPagato += importoDigitato
              }
              if (context.mostraVersato) {
                context.importoPagato.buonipasto = (context.importoPagato.buonipasto || 0) + importoDigitato
              } else {
                context.importoPagato.buonipasto = (context.importoPagato.buonipasto || 0) + (importoDigitato - (context.resto || 0))
              }
              if (context.resto != null) {
                context.displayDescrizione = 'Resto'
                context.displayMessage = ''
                context.displayValue = parseFloat(context.resto).toFixed(2)
                context.chiusuraPossibile = true
              } else {
                context.displayDescrizione = '' // verificare
                context.displayMessage = "L'importo non è sufficiente!"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              }
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['buonipasto'] = context.importoPagato.buonipasto.toFixed(2)
              context.giaPagatoTotale += importoDigitato
            } else {
              // 'eventuali verifiche per aliquota
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      }
    ],
    CARTA_DI_CREDITO: [
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.chiusuraPossibile
      },
      {
        cond: (context) => context.tipoDocumento === 'scontrinoSegueFattura',
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Tipo pagamento non ammesso'
          })
        ]
      },
      {
        cond: (context) => context.displayValue === '0' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context,event) => {
            let totaleScontrino = parseFloat(context.totaleScontrino)
            let importo = totaleScontrino - context.giaPagato
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              context.importoPagato.cartadicredito = (context.importoPagato.cartadicredito || 0) + importo
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['cartadicredito'] = context.importoPagato.cartadicredito.toFixed(2)
              context.chiusuraPossibile = true
              context.giaPagatoTotale += importo
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      },
      {
        cond: (context) => context.displayValue !== '0' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            let importoDigitato = parseFloat(context.displayValue) / 100
            let pagato = context.giaPagato + importoDigitato
            let totaleScontrino = parseFloat(context.totaleScontrino)
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              if (pagato > totaleScontrino) { // Verificare se accettabile per normativa o per impostazioni
                context.resto = +(pagato - totaleScontrino).toFixed(2)
                context.giaPagato = totaleScontrino
              } else if (pagato === totaleScontrino) {
                context.resto = 0
                context.giaPagato = totaleScontrino
              } else {
                context.resto = null
                context.giaPagato += importoDigitato
              }
              if (context.mostraVersato) {
                context.importoPagato.cartadicredito = (context.importoPagato.cartadicredito || 0) + importoDigitato
              } else {
                context.importoPagato.cartadicredito = (context.importoPagato.cartadicredito || 0) + (importoDigitato - (context.resto || 0))
              }
              if (context.resto != null) {
                context.displayDescrizione = 'Resto'
                context.displayMessage = ''
                context.displayValue = parseFloat(context.resto).toFixed(2)
                context.chiusuraPossibile = true
              } else {
                context.displayDescrizione = '' // verificare
                context.displayMessage = "L'importo non è sufficiente!"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              }
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['cartadicredito'] = context.importoPagato.cartadicredito.toFixed(2)
              context.giaPagatoTotale += importoDigitato
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              // 'eventuali verifiche per aliquota
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      }
    ],
    CONTANTI: [ // se documento = 'scontrinoSegueFattura' non è ammesso
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
/*       {
        cond: (context) => context.chiusuraPossibile,
        target: "verifica_chiusura",
        actions: send('CHIUDI_SCONTRINO')
      },  */
      {
        // cond: (context) => context.chiusuraPossibile && (!context.resto || !_.get(context.impostazioni, 'cassa.cashmatic.pagaVincita', false)),
        cond: (context) => {
          let cc = context.chiusuraPossibile
          if (_.get(context.impostazioni, 'cassa.cashmatic.pagaVincita', false) && context.resto) {
            cc = false
          }
          return cc
        },
        target: "verifica_chiusura",
        actions: send('CHIUDI_SCONTRINO')
      },
      {
        cond: (context) => context.tipoDocumento === 'scontrinoSegueFattura',
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Tipo pagamento non ammesso'
          })
        ]
      },
      {
        // Gestione Cashmatic
        cond: (context) =>
          (context.displayValue === '0' || context.displayValue === '') &&
          context.totaleScontrino &&
          _.get(context.impostazioni, 'cassa.cashmatic.abilita', false) &&
          !context.pagamentoCashmaticEffettuato,
        actions: [
          send(() => ({ value: 'Cashmatic', type: 'APRI_MODALE' }))
          // assign({totaleVincite: (context) => parseFloat(totalePerTipoItem(context, 'VINCITA'))})
        ]
      },
      {
        cond: (context) =>
          (context.displayValue === '0' || context.displayValue === '') &&
          context.totaleScontrino &&
          (!_.get(context.impostazioni, 'cassa.cashmatic.abilita', false) || context.pagamentoCashmaticEffettuato),
        target: "verifica_chiusura",
        actions: [
          (context,event) => {
            let totaleScontrino = parseFloat(context.totaleScontrino)
            // const totaleVincite = 0 // parseFloat(totalePerTipoItem(context, 'VINCITA'))
            let importo = totaleScontrino - context.giaPagato
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              context.importoPagato.contanti = (context.importoPagato.contanti || 0) + importo
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['contanti'] = context.importoPagato.contanti.toFixed(2)
              context.chiusuraPossibile = true
              context.resto = null
              context.giaPagatoTotale += importo
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      },
      {
        cond: (context) => context.displayValue !== '0' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            impostaContanti(context) // nel caso di vincite --> rimettere 21/12/2022
            return
            let importoDigitato = parseFloat(context.displayValue) / 100
            let pagato = context.giaPagato + importoDigitato
            let totaleScontrino = parseFloat(context.totaleScontrino)
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              if (pagato > totaleScontrino) {
                context.resto = +(pagato - totaleScontrino).toFixed(2)
                context.giaPagato = totaleScontrino
              } else if (pagato === totaleScontrino) {
                context.resto = 0
                context.giaPagato = totaleScontrino
              } else {
                context.resto = null
                context.giaPagato += importoDigitato
              }
              if (context.mostraVersato) {
                context.importoPagato.contanti = (context.importoPagato.contanti || 0) + importoDigitato
              } else {
                context.importoPagato.contanti = (context.importoPagato.contanti || 0) + (importoDigitato - (context.resto || 0))
              }
              if (context.resto != null) {
                context.displayDescrizione = 'Resto'
                context.displayMessage = ''
                context.displayValue = parseFloat(context.resto).toFixed(2)
                context.chiusuraPossibile = true
              } else {
                context.displayDescrizione = '' // verificare
                context.displayMessage = "L'importo non è sufficiente!"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              }
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['contanti'] = context.importoPagato.contanti.toFixed(2)
              context.giaPagatoTotale += importoDigitato
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              // 'eventuali verifiche per aliquota
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      }
    ],
    BUONO: [
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.chiusuraPossibile
      },
      {
        cond: (context) => context.totaleScontrino && context.buonoMultiuso,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            let importoDigitato = parseFloat(context.displayValue) / 100
            let totaleScontrino = parseFloat(context.totaleScontrino)
            if (context.displayValue === '0') {
              const importo = totaleScontrino - context.giaPagato
              importoDigitato = Math.min(importo, context.buonoMultiuso.buono.importoResiduo)
            }
            let pagato = context.giaPagato + importoDigitato
            // Inserire qui la richiesta di conferma chiusura
            // ------------------------------------------------------
            if (pagato === totaleScontrino && context.confermaChiusuraConBuono && totaleScontrino > 0) {
              context.confirmToast = 'Confermi chiusura conto con buono ?'
              context.confirmToastObj = { event: 'BUONO', cancelText: 'NO' , confirmText: 'SI' }
              context.chiusuraPossibile = false
              context.confermaChiusuraConBuono = false
              return
            }
            // ------------------------------------------------------

            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              if (pagato > totaleScontrino) {
                // non ammesso
                // context.resto = pagato - totaleScontrino
                // context.giaPagato = totaleScontrino
                context.displayDescrizione = '' // verificare
                context.displayMessage = "Il resto non è ammesso"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              } else {
                context.buonoMultiuso.buono.importoUsato += importoDigitato
                context.buonoMultiuso.buono.usoAttuale = importoDigitato // #### 05/10/2022
                const buono = context.buonoMultiuso.buono
                const residuo = buono.importo - (buono.importoUsato || 0)
                context.buonoMultiuso.buono.importoResiduo = residuo
                context.buonoMultiuso.descrizione = `${context.buonoMultiuso.secureCode}: ${residuo.toFixed(2)} €`
                context.descrizioneBuono = `${context.buonoMultiuso.secureCode}: ${residuo.toFixed(2)} €`

                context.buoniMultiuso[context.buoniMultiuso.length - 1] = context.buonoMultiuso
                context.resto = null
                if (pagato === totaleScontrino) {
                  context.giaPagato = totaleScontrino
                } else {
                  context.giaPagato += importoDigitato
                }
              }

              if (context.mostraVersato) {
                context.importoPagato.buonimultiuso = (context.importoPagato.buonimultiuso || 0) + importoDigitato
              } else {
                context.importoPagato.buonimultiuso = (context.importoPagato.buonimultiuso || 0) + (importoDigitato - (context.resto || 0))
              }
              if (pagato === totaleScontrino) {
                context.displayDescrizione = ''
                context.displayMessage = ''
                context.displayValue = '0'
                context.chiusuraPossibile = true
              } else {
                context.displayDescrizione = '' // verificare
                context.displayMessage = "L'importo non è sufficiente!"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              }
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['buonimultiuso'] = context.importoPagato.buonimultiuso.toFixed(2)
              context.giaPagatoTotale += importoDigitato
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              // 'eventuali verifiche per aliquota
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      },
      {
        cond: (context) => context.totaleScontrino && context.buonoMonouso,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            let importoDigitato = parseFloat(context.displayValue) / 100
            let totaleScontrino = parseFloat(context.totaleScontrino)
            if (context.displayValue === '0') {
              const importo = totaleScontrino - context.giaPagato
              importoDigitato = Math.min(importo, context.buonoMonouso.buono.importoResiduo)
            }
            let pagato = context.giaPagato + importoDigitato
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              if (pagato > totaleScontrino) {
                // non ammesso
                // context.resto = pagato - totaleScontrino
                // context.giaPagato = totaleScontrino
                context.displayDescrizione = '' // verificare
                context.displayMessage = "Il resto non è ammesso"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              } else {
                context.buonoMonouso.buono.importoUsato += importoDigitato
                const buono = context.buonoMonouso.buono
                const residuo = buono.importo - (buono.importoUsato || 0)
                context.buonoMonouso.buono.importoResiduo = 0 // (residuo)  Il buono monouso viene consumato tutto
                context.buonoMonouso.descrizione = `${context.buonoMonouso.secureCode}: ${residuo.toFixed(2)} €`
                context.descrizioneBuono = `${context.buonoMonouso.secureCode}: ${residuo.toFixed(2)} €`
                context.resto = null
                if (pagato === totaleScontrino) {
                  context.giaPagato = totaleScontrino
                } else {
                  context.giaPagato += importoDigitato
                }
              }

              if (context.mostraVersato) {
                context.importoPagato.buonimonouso = (context.importoPagato.buonimonouso || 0) + importoDigitato
              } else {
                context.importoPagato.buonimonouso = (context.importoPagato.buonimonouso || 0) + (importoDigitato - (context.resto || 0))
              }
              if (pagato === totaleScontrino) {
                context.displayDescrizione = ''
                context.displayMessage = ''
                context.displayValue = '0'
                context.chiusuraPossibile = true
              } else {
                context.displayDescrizione = '' // verificare
                context.displayMessage = "L'importo non è sufficiente!"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              }
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['buonimonouso'] = context.importoPagato.buonimonouso.toFixed(2)
              context.giaPagatoTotale += importoDigitato
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              // 'eventuali verifiche per aliquota
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      }
    ],
    PREPAGATA: [ // Verificare se possibile utilizzare un solo tasto BUONI / PREPAGATE
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.chiusuraPossibile
      },
      {
        cond: (context) => context.totaleScontrino && context.fidelityCard && context.fidelityCard.buono && context.fidelityCard.buono.abilita,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            let importoDigitato = parseFloat(context.displayValue) / 100
            let totaleScontrino = parseFloat(context.totaleScontrino)
            if (context.displayValue === '0') {
              const importo = totaleScontrino - context.giaPagato
              importoDigitato = Math.min(importo, context.fidelityCard.buono.importoResiduo)
            }
            let pagato = context.giaPagato + importoDigitato
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              if (pagato > totaleScontrino) {
                context.displayDescrizione = '' // verificare
                context.displayMessage = "Il resto non è ammesso"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              } else {
                context.fidelityCard.buono.importoUsato += importoDigitato
                const buono = context.fidelityCard.buono
                const residuo = buono.importo - (buono.importoUsato || 0)
                context.fidelityCard.buono.importoResiduo = residuo
                context.fidelityCard.descrizione = `${context.fidelityCard.secureCode}: ${residuo.toFixed(2)} €`
                context.resto = null
                if (pagato === totaleScontrino) {
                  context.giaPagato = totaleScontrino
                } else {
                  context.giaPagato += importoDigitato
                }
              }

              if (context.mostraVersato) {
                context.importoPagato.fidelitycard = (context.importoPagato.fidelitycard || 0) + importoDigitato
              } else {
                context.importoPagato.fidelitycard = (context.importoPagato.fidelitycard || 0) + (importoDigitato - (context.resto || 0))
              }
              if (pagato === totaleScontrino) {
                context.displayDescrizione = ''
                context.displayMessage = ''
                context.displayValue = '0'
                context.chiusuraPossibile = true
              } else {
                context.displayDescrizione = '' // verificare
                context.displayMessage = "L'importo non è sufficiente!"
                context.displayValue = '0'
                context.chiusuraPossibile = false
              }
              context.attributes.partialAccount ||= {};
              context.attributes.partialAccount['fidelitycard'] = context.importoPagato.fidelitycard.toFixed(2)
              context.giaPagatoTotale += importoDigitato
              context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
            } else {
              // 'eventuali verifiche per aliquota
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      }
    ],
    NON_RISCOSSO_BENI: [
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.chiusuraPossibile
      },
      {
        cond: (context) => context.displayValue === '0' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context,event) => {
            let totaleScontrino = parseFloat(context.totaleScontrino)
            let importo = totaleScontrino - context.giaPagato
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              const totaleBeniScontrino = totalePerTipoItem(context, 'PRODOTTO')
              if ((context.importoPagato.nonriscossobeni || 0) + importo > totaleBeniScontrino) {
                context.displayMessage = context.impostazioni.carrello.totale_carrello_bene
                context.chiusuraPossibile = false
              } else {
                context.importoPagato.nonriscossobeni = (context.importoPagato.nonriscossobeni || 0) + importo
                context.attributes.partialAccount ||= {};
                context.attributes.partialAccount['nonriscossobeni'] = context.importoPagato.nonriscossobeni.toFixed(2)
                context.chiusuraPossibile = true
                context.giaPagatoTotale += importo
                context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
              }
            } else {
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      },
      {
        cond: (context) => context.displayValue !== '0' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            let importoDigitato = parseFloat(context.displayValue) / 100
            const totaleBeniScontrino = totalePerTipoItem(context, 'PRODOTTO')
            if ((context.importoPagato.nonriscossobeni || 0) + importoDigitato > totaleBeniScontrino) {
              context.displayDescrizione = ''
              context.displayMessage = context.impostazioni.carrello.totale_carrello_bene
              context.displayValue = '0'
              context.chiusuraPossibile = false
            } else {
              let pagato = context.giaPagato + importoDigitato
              let totaleScontrino = parseFloat(context.totaleScontrino)
              if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
                if (pagato > totaleScontrino) { // Verificare se accettabile per normativa o per impostazioni
                  context.resto = +(pagato - totaleScontrino).toFixed(2)
                  context.giaPagato = totaleScontrino
                } else if (pagato === totaleScontrino) {
                  context.resto = 0
                  context.giaPagato = totaleScontrino
                } else {
                  context.resto = null
                  context.giaPagato += importoDigitato
                }
                if (context.mostraVersato) {
                  context.importoPagato.nonriscossobeni = (context.importoPagato.nonriscossobeni || 0) + importoDigitato
                } else {
                  context.importoPagato.nonriscossobeni = (context.importoPagato.nonriscossobeni || 0) + (importoDigitato - (context.resto || 0))
                }
                if (context.resto != null) {
                  context.displayDescrizione = 'Resto'
                  context.displayMessage = ''
                  context.displayValue = parseFloat(context.resto).toFixed(2)
                  context.chiusuraPossibile = true
                } else {
                  context.displayDescrizione = '' // verificare
                  context.displayMessage = "L'importo non è sufficiente!"
                  context.displayValue = '0'
                  context.chiusuraPossibile = false
                }
                context.attributes.partialAccount ||= {};
                context.attributes.partialAccount['nonriscossobeni'] = context.importoPagato.nonriscossobeni.toFixed(2)
                context.giaPagatoTotale += importoDigitato
                context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
              } else {
                // 'eventuali verifiche per aliquota
                context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
                context.chiusuraPossibile = false
              }
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      }
    ],
    NON_RISCOSSO_SERVIZI: [
      {
        cond: (context) => !q.isEmpty() || context.invioDisplayInCorso
      },
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.chiusuraPossibile
      },
      {
        cond: (context) => context.displayValue === '0' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context,event) => {
            let totaleScontrino = parseFloat(context.totaleScontrino)
            let importo = totaleScontrino - context.giaPagato
            if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
              const totaleServiziScontrino = totalePerTipoItem(context, 'SERVIZIO')
              if ((context.importoPagato.nonriscossoservizi || 0) + importo > totaleServiziScontrino) {
                context.displayMessage = context.impostazioni.carrello.totale_carrello_servizio
                context.chiusuraPossibile = false
              } else {
                context.importoPagato.nonriscossoservizi = (context.importoPagato.nonriscossoservizi || 0) + importo
                context.attributes.partialAccount ||= {};
                context.attributes.partialAccount['nonriscossoservizi'] = context.importoPagato.nonriscossoservizi.toFixed(2)
                context.chiusuraPossibile = true
                context.giaPagatoTotale += importo
                context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
              }
            } else {
              context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
              context.chiusuraPossibile = false
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      },
      {
        cond: (context) => context.displayValue !== '0' && context.totaleScontrino,
        target: "verifica_chiusura",
        actions: [
          (context, event) => {
            let importoDigitato = parseFloat(context.displayValue) / 100
            const totaleServiziScontrino = totalePerTipoItem(context, 'SERVIZIO')
            if ((context.importoPagato.nonriscossoservizi || 0) + importoDigitato > totaleServiziScontrino) {
              context.displayDescrizione = ''
              context.displayMessage = context.impostazioni.carrello.totale_carrello_servizio
              context.displayValue = '0'
              context.chiusuraPossibile = false
            } else {
              let pagato = context.giaPagato + importoDigitato
              let totaleScontrino = parseFloat(context.totaleScontrino)
              if (totaleScontrino >= 0) { // + eventuali verifiche per aliquota
                if (pagato > totaleScontrino) { // Verificare se accettabile per normativa o per impostazioni
                  context.resto = +(pagato - totaleScontrino).toFixed(2)
                  context.giaPagato = totaleScontrino
                } else if (pagato === totaleScontrino) {
                  context.resto = 0
                  context.giaPagato = totaleScontrino
                } else {
                  context.resto = null
                  context.giaPagato += importoDigitato
                }
                if (context.mostraVersato) {
                  context.importoPagato.nonriscossoservizi = (context.importoPagato.nonriscossoservizi || 0) + importoDigitato
                } else {
                  context.importoPagato.nonriscossoservizi = (context.importoPagato.nonriscossoservizi || 0) + (importoDigitato - (context.resto || 0))
                }
                if (context.resto != null) {
                  context.displayDescrizione = 'Resto'
                  context.displayMessage = ''
                  context.displayValue = parseFloat(context.resto).toFixed(2)
                  context.chiusuraPossibile = true
                } else {
                  context.displayDescrizione = '' // verificare
                  context.displayMessage = "L'importo non è sufficiente!"
                  context.displayValue = '0'
                  context.chiusuraPossibile = false
                }
                context.attributes.partialAccount ||= {};
                context.attributes.partialAccount['nonriscossoservizi'] = context.importoPagato.nonriscossoservizi.toFixed(2)
                context.giaPagatoTotale += importoDigitato
                context.daPagareTotale = Math.max(totaleScontrino - context.giaPagatoTotale, 0)
              } else {
                // 'eventuali verifiche per aliquota
                context.displayMessage = context.impostazioni.carrello.totale_carrello_negativo
                context.chiusuraPossibile = false
              }
            }
          },
          send('CHIUDI_SCONTRINO')
        ]
      }
    ],

    NOTA: {
      actions: (context, event) => {
        var riga = {
          quantita: 0,
          descrizione: event.nota,
          prezzoUnitario: 0,
          prezzo: event.prezzo,
          tipo: event.tipoRiga,
          rigaCodice: uuidv4()
        };
        context.righeCarrello.push(riga)
        context.rigaCarrello = riga;
      }
    },
    RIGA_ADDED: {
      actions: assign({ idScontrino: (context,event) => event.idScontrino })
    },
    REPARTO_SCONTO: {
      cond: (context) => !context.stampaEffettuata,
      target: "operand1.generic_value",
      actions: [
        assign({ displayValue: (context, event) => event.value.value }),
        send('SCONTO_PERCENTUALE')
      ]
    },
    REPARTO_ABBUONO: {
      cond: (context) => !context.stampaEffettuata,
      target: "operand1.generic_value",
      actions: [
        assign({ displayValue: (context,event) => event.value.value }),
        send('ABBUONO')
      ]
    },
    CHIUDI_MODALE: {
      target: 'operand1',
      actions: [(context) => context.modali.splice(-1, 1)]
    },
    CHIUDI_POPUP: {
      target: 'operand1',
      actions: [(context, event) => {context.popups.find(el => el.type === event.value).show = false }]
    },
    SELEZIONA_CLIENTE: {
      target: 'operand1',
      actions: send((context, event) => ({ value: { code: event.value.codice }, type: 'CLIENTE' }))
    },
    TOGGLE_CLIENTE: [
      {
        cond: (context) => context.cliente,
        target: 'operand1',
        actions:  assign({ cliente: () => null })
      },
      {
        cond: (context) => !context.cliente,
        actions:  send({ value:'CercaCliente', type:'APRI_POPUP'})
      }
    ],
    TOGGLE_PREZZO_ACQUISTO: {
      actions:  assign({ togglePrezzoAcquisto: (context) => !context.togglePrezzoAcquisto })
    },
    SELEZIONA_APPUNTAMENTO: {
      actions: assign({ appuntamento: (context, event) => event.value })
    },
    SELEZIONA_AGENDA: {
      actions: (context, event) => context.appuntamento.agenda = event.value // assign({ appuntamento: (context, event) => event.value })
    },
    ADD_BARCODE:
    [
      {
        cond: (context, event) => fnIsAccessoVisibile(context, event.code) ||
          context.popups.find(el => el.type === 'CercaProdotto').show || // ricerca prodotto aperta.
          context.popups.find(el => el.type === 'CercaBuono').show  || // ricerca buono aperta.
          context.popups.find(el => el.type === 'TastieraGenerica').show  || // tastiera generica aperta.
          (context.modali && ['NuovoCliente', 'NuovoClienteFidelity', 'RicercaCliente', 'SetupTasti', 'CaricaSelezizone', 'Descrizione'].some((val) => context.modali.includes(val)))
      },
      {
        cond: (context) => (context.popups.find(el => el.type === 'TastieraNote').show),
        actions: assign({ barcodePerGenerico: (context, event) => event.code })
      },
      {
        cond: (context) => !context.isSubTotaleCalcolato,
        target: "accoda_barcode",
        actions: [
          raise((context, event) => ({ value: event.code, filter: event.filter, type: 'ACCODA' }))
        ]
      }
    ],
    ENTER: [
      {
        cond: (context) => context.displayValue.length > 7 && !context.isSubTotaleCalcolato,
        target: "accoda_barcode",
        actions: [
          raise((context, event) => ({ value: context.displayValue, type: 'ACCODA' }))
          // assign({ displayValue: () => '0' })
        ]
        // actions: send('ACCODA')
      },
      {
        cond: (context) => context.isSubTotaleCalcolato,
        actions : send({ type: 'RESET_DISPLAY' }, { delay: 100 })
      }
    ],
    DIGITA: [
      {
        // cond: (context => !context.resoInCorso),
        actions: [
          assign({ displayValue: (context, event) => event.value }),
          send('ENTER') // TODO: Verificare
        ]
      }
    ],
/*     DIGITA: {
      actions: send( (context, event) => ({ data: event.value, type: 'NEW_BARCODE' }), { to: 'barcodeService' })
    }, */
    DIGITA_SINGOLA: {
      actions: ["appendNum"]
    },
    CODE: {
      actions: send('ENTER')
    },
    PRINTER: {
      target: "inviaComandiStampante"
    },
    PRINTER_LETTURA_GIORNALIERA: {
      target: 'lettura_giornaliera',
      actions: assign({ displayMessage: () => 'Attendere... Stampa in corso' })
    },
    PRINTER_CHIUSURA_GIORNALIERA: {
      target: 'chiusura_giornaliera',
      actions: [
        (contex) => { console.log('PRINTER_CHIUSURA_GIORNALIERA') },
        assign({
          displayMessage: () => 'Attendere... Stampa in corso',
          chiusuraGiornalieraInCorso: () => true
        })
      ]
    },
    PRINTER_GESTIONALE_GENERICO: {
      target: 'gestionale_generico',
      actions: assign({ displayMessage: () => 'Attendere... Stampa in corso' }) // INSERIRE RIGHE
    },
    LOTTERIA: [
      {
        cond: (context) => context.resoInCorso,
        target: "operand1",
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.attesaCodiceLotteria,
        target: "operand1",
        actions: [
          (context => { setTimeout( () => { context.attesaCodiceLotteria = false}, 500)}),  // TODO: Verificare, senza timeout non funziona --> correggere
          // assign({ attesaCodiceLotteria: () => false }),
          (context) => context.popups.find(el => el.type === 'Tastiera').show = false
        ]
      },
      {
        cond: codiceLotteriaInserito,
        actions: assign({ displayMessage: () => 'Codice già inserito !!!' })
      },
      {
        cond: lotteriaOrCodiceFiscale,
        actions: assign({ displayMessage: () => 'Lotteria e Codice Fiscale sono alternativi' })
      },
      {
        cond: notCodiceLotteriaInserito,
        target: "attesaCodiceLotteria",
        actions: [
          assign({
            attesaCodiceLotteria: () => true
          }),
          send({ value:'Tastiera', type:'APRI_POPUP'})
        ]
      }
    ],
    CODICE_FISCALE: [
      {
        cond: (context) => context.resoInCorso,
        target: "operand1",
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.attesaCodiceFiscale,
        target: "operand1",
        actions: [
          assign({ attesaCodiceFiscale: () => false }),
          (context) => context.popups.find(el => el.type === 'Tastiera').show = false
        ]
      },
      {
        cond: codiceFiscaleInserito,
        actions: assign({ displayMessage: () => 'Codice già inserito !!!' })
      },
      {
        cond: lotteriaOrCodiceFiscale,
        actions: assign({ displayMessage: () => 'Lotteria e Codice sono alternativi' })
      },
      {
        cond: notCodiceFiscaleInserito,
        target: "attesaCodiceFiscale",
        actions: [
          assign({
            attesaCodiceFiscale: () => true
          }),
          send({ value:'Tastiera', type:'APRI_POPUP'})
        ]
      }
    ],
    /* PARTITA_IVA: [
      {
        cond: (context) => context.resoInCorso,
        target: "operand1",
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        cond: (context) => context.attesaPartitaIva,
        target: "operand1",
        actions: [
          assign({ attesaPartitaIva: () => false }),
          (context) => context.popups.find(el => el.type === 'Tastiera').show = false
        ]
      },
      {
        cond: partitaIvaInserita,
        actions: assign({ displayMessage: () => 'Partita iva già inserita !!!' })
      },
      {
        cond: lotteriaOrCodiceFiscale,
        actions: assign({ displayMessage: () => 'Lotteria e Codice Fiscale sono alternativi' })
      },
      {
        cond: notPartitaIvaInserita,
        target: "attesaPartitaIva",
        actions: [
          assign({
            attesaPartitaIva: () => true
          }),
          send({ value:'Tastiera', type:'APRI_POPUP'})
        ]
      }
    ],   */
    CONFERMA_RESO: {
      actions: [
        "confermaReso",
        send('CALCOLA_TOTALE_SCONTRINO'),
        assign({
          displayValue: () => '0',
          displayMessage: () => ''
        })
      ]
    },
    RESO_COMPLETO: {
      actions: [
        "resoCompleto",
        "confermaReso",
        send('CALCOLA_TOTALE_SCONTRINO'),
        assign({
          displayValue: () => '0',
          displayMessage: () => ''
        })
      ]
    },
    SOLO_RESO: {
      target: "gestioneScontrino",
      actions: [
/*         "resoCompleto",
        "confermaReso",
        send('CALCOLA_TOTALE_SCONTRINO'), */
        // Stampare scontrino senza verificare totale
        assign({
          displayValue: () => '0',
          displayMessage: () => '',
          comandoScontrini: () => 'SCONTRINO_SOLO_RESO'
        })
      ]
    },
    SCONTRINO_GESTIONALE: {
      target: "gestioneScontrino",
      actions: [
        assign({
          displayValue: () => '0',
          displayMessage: () => '',
          comandoScontrini: () => 'SCONTRINO_GESTIONALE'
        })
      ]
    },
    RECUPERA_SCONTRINO: [
      {
        cond: (context) => context.resoInCorso,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Selezione prodotti reso in corso'
          })
        ]
      },
      {
        actions: [
          assign({ attesaCodiceScontrino: (context) => !context.attesaCodiceScontrino }),
          send('FOCUS')
        ]
      }
    ],
    POPUP_RESO_SCONTRINO: [
      {
        cond: (context) => context.isSubTotaleCalcolato,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Inserimento reso non possibile. Subtotale calcolato!'
          })
        ]
      },
      {
        cond: (context) => context.importoPagato.scontoapagare,
        actions: [
          assign({
            displayValue: () => '0',
            messaggioToast: () => 'Inserimento reso non possibile. Presente Sconto a Pagare!'
          })
        ]
      },
      {
        cond: (context) => !context.isSubTotaleCalcolato,
        target: "operand1.zero",
        actions:[
          (context, event) => {context.popups.find(el => el.type === 'CercaScontrino').show = false },
          assign({
            recuperoInCorso: () => false,
            confermaAnnulloVisibile: () => false
          }),
          "caricaScontrino"
        ]
      }
    ],
    POPUP_RECUPERO_SCONTRINO: {
      target: "attesaInputSuRecupero",
      actions:[
        (context, event) => {context.popups.find(el => el.type === 'CercaScontrino').show = false },
        assign({
          resoInCorso: () => false,
          confermaResoVisibile: () => false
        }),
        "recuperaScontrino"
      ]
    },
    POPUP_APRI_SCONTRINO: {
      target: "operand1",
      actions:[
        (context, event) => {context.popups.find(el => el.type === 'CercaScontrino').show = false },
        assign({
          resoInCorso: () => false,
          confermaResoVisibile: () => false
        }),
        "riapreScontrinoAperto",
        send('CALCOLA_TOTALE_SCONTRINO'),
        send('CLEAR_PARTIAL')
      ]
    },
    POPUP_ANNULLAMENTO_SCONTRINO: {
      target: "attesaInputSuRecupero",
      actions:[
        (context, event) => {context.popups.find(el => el.type === 'CercaScontrino').show = false },
        assign({
          resoInCorso: () => false,
          confermaResoVisibile: () => false,
          fiscaleScontrino: (context, event) => event.data.fiscale,
          scontrinoRecupero: (context, event) => event.data
        }),
        /* "recuperaScontrino", */
        send('ANNULLAMENTO_FISCALE')
      ]
    },
    POPUP_ELIMINAZIONE_SCONTRINO: {
      target: "operand1",
      actions:[
        (context, event) => {context.popups.find(el => el.type === 'CercaScontrino').show = false },
        // assign({ messaggioToast : () => 'Lo scontrino è stato eliminato 2'}),
        assign({ idEliminare : (context, event) => event.data._id }),
        send("ELIMINA_SCONTRINO")
      ]
    },
    POPUP_STAMPA_SCONTRINO_NON_STAMPATO: {
      target: "gestioneScontrino",
      actions:[
        (context, event) => { context.popups.find(el => el.type === 'CercaScontrino').show = false },
        assign({
          resoInCorso: () => false,
          confermaResoVisibile: () => false,
          comandoScontrini: () => 'STAMPA_SCONTRINO'
        }),
        "stampaScontrinoNonStampato"
      ]
    },
    POPUP_SCONTRINO_CORTESIA: {
      target: "gestioneScontrino",
      actions:[
        (context, event) => {context.popups.find(el => el.type === 'CercaScontrino').show = false },
        assign({
          barcodeUltimoScontrino: (context, event) => event.data.fiscale.ricerca,
          comandoScontrini: () => 'SCONTRINO_REGALO'
        })
      ]
    },
    SELEZIONA_OPERATORE: {
      target: "operand1",
      actions: [
        (context, event) => {context.popups.find(el => el.type === 'CercaOperatore').show = false },
        (context, event) => {
          if (event.value.code) {
            const brOp = getOperatoreDaBarcode(event.value.code)
            const esistente = _.get(context.operatore, 'accesso.codice', _.get(context.operatore, 'servizio.codice', ''))
            if (esistente === brOp.codice) {
              // se ripassa il badge effettua il logout
              event.value.item = null
            } else {
              event.value.tipo = 'accesso'
              event.value.item = brOp
            }
          }
          var op = context.operatore || {}
          op[event.value.tipo] = event.value.item
          if (!event.value.item && context.impostazioni.operatori.obbligatorio) {
            context.operatore = {}
          } else {
            if (event.value.tipo === 'accesso' && context.impostazioni.cassa.sbloccaSuUtente) {
              context.bloccaCassa = false
              context.displayValue = '0'
              if (event.value.item.servizio) {
                op.servizio = event.value.item
              }
            }
            context.operatore = {...op}
          }
        },
        assign({ richiediPin: (context, event) => false })
      ]
    },
    RICHIEDI_PIN: {
      target: "operand1",
      actions: assign({ richiediPin: (context, event) => true })
    },
    SELEZIONA_UTENTE: {
      target: "operand1",
      actions: [
        (context, event) => { context.popups.find(el => el.type === 'CercaUtente').show = false },
        assign({ utente: (context, event) => event.value.item })
      ]
    },
    GET_PRINTER_STATUS: {
      // TODO: deve solo inviare la richiesta generica alla stampante. Il comando preciso dipende dalla stampante...
      // Attenzione: Inviare messaggio al servizio stampante che provvede ad inviare il messaggio giusto
      actions: send({ value: { commands: ['<</?i/*4']}, type:'PRINTER' })
    },
    GET_INITIAL_PRINTER_STATUS: {
      // TODO: deve solo inviare la richiesta generica alla stampante. Il comando preciso dipende dalla stampante...
      actions: [
        send(() => ({ value: 'Stampante', type: 'APRI_MODALE' }))
        // TODO: Verificare il seguente
        // assign({ printerInitialStatusRequest: () => true}),
        // send({ value: { commands: ['<</?i/*4']}, type:'PRINTER' }),
        /* send({ value:'Stampante', type:'APRI_MODALE'}, { delay: 1000 }) */ // TODO Terminare e rimettere
      ]
    },
    APERTURA_CASSETTO: {
      // TODO: deve solo inviare la richiesta generica alla stampante. Il comando preciso dipende dalla stampante...
      // actions: send({ value: { commands: ['=C86']}, type:'PRINTER' })
      actions: send({ value: { commands: ['APERTURA_CASSETTO']}, type:'PRINTER' })
    },
    DETTAGLIO_BUONO: {
      actions: send({ value:'CercaBuono', type:'APRI_POPUP'})
    },
    DETTAGLIO_FIDELITY: {
      actions: send({ value:'Fidelity', type:'APRI_POPUP'})
    },
    SCONTRINO: {
      target: "scontrino_info",
      actions: [
        assign({ displayValue: (context, event) => event.value.code })
      ]
    },
    BUONO_MULTIUSO: {
      target: "buono_info"
    },
    ANNULLA_BUONO_MULTIUSO: {
      target: "annulla_buono"
    },
    RISTAMPA_BUONO: {
      target: "gestioneScontrino",
      actions: assign({
        infoRistampaBuono: (context, event) => event.value.scontrino,
        comandoScontrini: () => 'RISTAMPA_BUONO'
      })
    },
    BUONO_MONOUSO: {
      target: "buono_info"
    },
    ANNULLA_BUONO_MONOUSO: {
      target: "annulla_buono"
    },
    FIDELITY: {
      target: "fidelity_info"
    },
    FIDELITY_ASSOCIA: {
      target: "fidelity_associa"
    },
    FIDELITY_NUOVA: {
      target: "fidelity_nuova"
    },
    AGGIORNA_FIDELITY_CARD: {
      target: "fidelity_aggiorna"
    },
    RICARICA_FIDELITY_CARD: {
      target: "operand1",
      actions: [
        (context) => {
          context.popups.find(el => el.type === 'Fidelity').show = false
        },
        assign({
          displayValue: (context, event) => event.value.comandi.caricaReparto
        }),
        send((context, event) => {
          return {
            value: {
              code: 'Prepagata',   // leggere da configurazioni
              tipoAliquota: 'banco',
              importo: +(parseFloat(event.value.comandi.caricaReparto)/100).toFixed(2)
            },
            type: 'REPARTO' }
        })
      ]
    },
/*     // Deprecato. usare PAGAMENTO_APPUNTAMENTO
    PAGA_APPUNTAMENTO: {
      actions: assign({ pagaAppuntamentoInCorso: (context, event) => event.value })
    },
    // Deprecato. usare PAGAMENTO_APPUNTAMENTO
    CHIUDI_PAGA_APPUNTAMENTO: {
      target: 'operand1',
      actions: [
        (context) => {
          context.popups.find(el => el.type === 'Appuntamento').show = false
          context.modali.splice(-1, 1)
          context.appuntamento.pagamentoInCorso = true
        },
        assign({ pagaAppuntamentoInCorso: () => 0 })
      ]
    }, */
    PAGAMENTO_APPUNTAMENTO: {
      target: 'pagamento_appuntamento',
      actions: [
        async (context, event) => {
          context.appuntamento = event.value.appuntamento
          context.cliente = event.value.cliente
          context.appuntamento.pagamentoInCorso = true
          context.pagaAppuntamentoInCorso = 1
          if (context.idScontrino === '') {
            // console.log('crea scontrino da PAGAMENTO_APPUNTAMENTO')
            let result = await scontrini.nuovoScontrino(configurazioni.getPostazione())
            context.idScontrino = result._id
            // console.log('creato scontrino da PAGAMENTO_APPUNTAMENTO', context.idScontrino)
            // scontrinoCreato = true
          }
        }
      ]
    },
    TOAST_CONFIRM : {
      target: 'operand1',
      actions: [
        send((context, event) => ({ type: event.value.code })),
        assign({
          messaggioToast: () => '',
          confirmToast: () => ''
        })
      ]
    },
    TOAST_RESET: {
      actions: [
        assign({
          messaggioToast: () => '',
          confirmToast: () => ''
        })
      ]
    },
    FUNZIONE_RAPIDA_CONFIRM: {
      actions: (context, event) => {
        let result = funzioniRapideCall(context, event)
        context.confirmAction = result.value
        context.confirmToast = result.msg
        context.confirmToastObj = { event: 'FUNZIONE_RAPIDA', title: result.title, cancelText: 'NO' , confirmText: 'SI' }
      }
    },
    FUNZIONE_RAPIDA: {
      actions: funzioniRapide
    },
    STATISTICHE_RAPIDE_RESET: {
      actions: async (context, event) => {
        context.statistica = null
        context.statisticaOffsetGioni = 0
      }
    },
    STATISTICHE_RAPIDE: {
      actions: async (context, event) => {
        if (!context.statistica) {
          context.statisticaOffsetGioni = 0
        } else {
          context.statisticaOffsetGioni = context.statisticaOffsetGioni - 1
        }
        event.value.offsetGiorni = context.statisticaOffsetGioni
        await mostraStatisticaRapida(context, event)
      }
    },
    SHUTDOWN: {
      // actions: () => utils.shutdown({ force: true, timerseconds: 1 })
      actions: async (context, event) => await shutdownPC()
    },
    CHIUDI_BROWSER: {
      // actions: () => fkill('chrome')
      actions: () => fkill(':8080')
    },
    CERCA_PRODOTTO: {
      actions: send({ value:'CercaProdotto', type:'APRI_POPUP'})
    },
    REPORT: {
      actions: send({ value:'Report', type:'APRI_POPUP'})
    },
    ADD_NOTA: [
      {
        cond: (context) => context.displayValue === '0' && context.prezzoImposto === '',
        actions: [
          assign({ tipoInserimentoTesto: () => 'NOTA' }),
          send({ value:'TastieraNote', type:'APRI_POPUP'})
        ]
      },
      // Da impostazioni devo verificare se ho iva fissa --> posso farlo, altrimenti è necessario un tasto con l'iva fissa (prevedere in futuro la scelta dell'iva)
      {
        cond: (context) => context.displayValue !== '0' || context.prezzoImposto !== '',
/*         cond: (context, event) => {
          const ivaFissa = context.impostazioni.notaLibera && context.impostazioni.notaLibera.reparto
          if ((context.displayValue !== '0' || context.prezzoImposto !== '') && !ivaFissa) {
            context.displayMessage = 'Acquisto generico non consentito!'
            return false
          }
          return true
        }, */
        actions: [
          assign({ tipoInserimentoTesto: () => 'ACQUISTO_NOTA_LIBERA' }),
          send({ value:'TastieraNote', type:'APRI_POPUP'})
        ]
      }
    ],
    INSERISCI_TESTO: [
      {
        cond: (context) => context.tipoInserimentoTesto === 'NOTA',
        target:'operand1',
        actions: [
          async (context, event) => await aggiungiNota (context, event),
          // (context) => context.popups.find(el => el.type === 'InserimentoTesto').show = false
          (context) => context.popups.find(el => el.type === 'TastieraNote').show = false
        ]
      },
      {
        cond: (context) => context.tipoInserimentoTesto === 'ACQUISTO_NOTA_LIBERA',
        target:'operand1',
        actions: [
          async (context, event) => await aggiungiRepartoNotaLiberaAlCarrello (context, event),
          (context) => context.popups.find(el => el.type === 'TastieraNote').show = false
        ]
      },
      {
        cond: (context) => context.tipoInserimentoTesto === 'PARK_NAME',
        target:'operand1',
        actions: [
          async (context, event) => {
            let evento = {
              payload: context.tastieraGenericaValue
            }
            evento.payload.descrizione = event.value.testo
            await gestioneParcheggio (context, evento)
            context.testoNotaGenerica = event.value.testo || '<update>'
          },
          (context) => context.popups.find(el => el.type === 'TastieraGenerica').show = false
        ]
      }
    ],
    RENAME_PARK: {
      actions: [
        assign({
          tipoInserimentoTesto: () => 'PARK_NAME',
          tastieraGenericaValue: (context, event) => event.payload
        }),
        send({ value:'TastieraGenerica', type:'APRI_POPUP'})
      ]
    },
    BLOCCA_CASSA: {
      actions: [
        assign({ bloccaCassa: (context, event) => event.value }),
        send('RESET_DISPLAY')
      ]
    },
    PARCHEGGIO: {
      target: "operand1",
      actions: [
        async (context, event) => await gestioneParcheggio (context, event)
      ]
    },
    ESCI_DA_RESO: {
      actions: (context) => {
        context.righeReso = []
        context.righeCarrello = []
        context.righeCarrello = [...context.righeParcheggiate]
        context.righeParcheggiate = []
        context.resoInCorso = false
        context.confermaResoVisibile = false
        context.barcodeUltimoScontrino = ''
        context.titoloScontrino = ''
      }
    },
    USA_SELEZIONE: {
      actions: (context, event) => {
        context.confirmAction = { type: 'USA_SELEZIONE_CONFIRM', value: event.value, payload: event.payload }
        context.confirmToast = `Confermi OPERAZIONE ?`
        context.confirmToastObj = { event: 'FUNZIONE_RAPIDA', title: '', cancelText: 'NO' , confirmText: 'SI' }
      }
    },
    USA_SELEZIONE_CONFIRM: {
      target: "usa_selezione"
    },
    TIMED_RESET_MEMORIA_TOTALE: {
      actions: assign({ memoriaTotale: () => '' })
    },
    TOGGLE_HELP_OVERLAY: {
      actions: [
        (context) => {
          context.helpEnabled = !context.helpEnabled
          if (!context.helpEnabled && context.modali.indexOf('Help') >= 0) {
            context.modali.splice(-1, 1)
            context.helpSource = ''
          }
        }
      ]
    },
    DISPLAY_CONTEXTUAL_HELP: {
      actions: [
        assign({ helpSource: (context, event) => event.value }),
        (context) => context.modali.push('Help')
      ]
    },
    DISPLAY_BACKOFFICE: {
      // TODO: L'apertura della popup backoffice va gestita per le varie funzioni --> richiamare passando il tipo di richiesta ed il payload
      actions: [
        assign({ backoffice: (context, event) => event.value }),
        send({ value:'Backoffice', type:'APRI_POPUP'})
      ]
    },
    BACKOFFICE_RETURN: {
      target: 'operand1',
      actions: [
        async (context, event) => {
          const backoffice = event.backoffice
          const payload = event.payload
          switch (backoffice.contesto) {
            case "menuComposto":
              var menuTotali = event.menuTotali
              context.menuTotali = menuTotali
              if (payload) {
                await caricaMenuComposto(context, payload)
              }
              break
            default:
              break
          }
        },
        (context, event) => { context.popups.find(el => el.type === 'Backoffice').show = false }
      ]
    },
    CASSA_GESTIONE: [
      {
        cond: (context, event) => event.value === 'FONDOCASSA',
        actions: (context, event) => {
          context.confirmAction = { type: 'CASSA_GESTIONE_CONFIRM', value: event.value }
          context.confirmToast = `Confermi OPERAZIONE ?`
          context.confirmToastObj = { event: 'FUNZIONE_RAPIDA', title: '', cancelText: 'NO' , confirmText: 'SI' }
        }
      },
      {
        actions: [
          assign({ cassaOperazione: (context, event) => event.value }),
          send(() => ({ value: 'Descrizione', type: 'APRI_MODALE' }))
        ]
      }
    ],
    CASSA_GESTIONE_CONFIRM: {
      target: "cassa_movimenti"
    },
    CASHMATIC_PAGAMENTO_EFFETTUATO: {
      actions: [
        assign({ pagamentoCashmaticEffettuato: () => true }),
        assign({ cashmaticLastTransaction: (context, event) => event.payload }),
        (context) => context.modali.splice(-1, 1),
        send("CONTANTI")
      ]
    },
    CUSTOM_AGGIORNA_TABACCHI: {
      actions: (context, event) => {
        context.confirmAction = { type: 'CUSTOM_AGGIORNA_TABACCHI_CONFIRM' }
        context.confirmToast = `Confermi aggiornamento Tabacchi ?`
        context.confirmToastObj = { event: 'FUNZIONE_RAPIDA', title: 'Aggiornamento Tabacchi', cancelText: 'NO' , confirmText: 'SI' }
      }
    },
    CUSTOM_AGGIORNA_TABACCHI_CONFIRM: {
      target: "custom_aggiorna_tabacchi"
    },
    RICALCOLA_TOTALI: {
      actions: (context) => impostaContanti(context, 0)
    },
    STAMPA_REPORT: {
      actions: async (context, event) => stampe.stampaReport(context, event)
    }
  },
  initial: 'operand1',
  invoke: {
    id: 'barcodeService', // https://github.com/davidkpiano/xstate/issues/434
    src: (context) => (cb, onReceive) => {
      onReceive(event => {
        console.log('onReceive', event)
        const data = event.data.split(',') // posso mandare anche un elenco di codici
        // data.forEach(x => q.push(x))
        data.forEach(x => {
          const cod = event.filter ? x + '§' + event.filter : x
          return q.push(cod)
        })
        context.displayValue = "0"
        context.displayFocus = context.displayFocus + 1
        setTimeout(async () => await dequeueBarcodeSynchronous(context, cb), 10)
      })
    }
  },
  states: {
    accoda_barcode: {
      on: {
        ACCODA: [
          {
            cond: (context) => !context.resoInCorso /* && !context.bloccoInserimentoCodici */,
            target: "operand1",
            actions: [
              assign({
                rigaSelezionata: (context, event) => null,
                displayFocus: (context) => context.displayFocus + 1,
                impostaVincita: (context) => true
              }),
              send( (context, event) => ({ data: event.value, filter: event.filter, type: 'NEW_BARCODE' }), { to: 'barcodeService' })
            ]
          },
          {
            cond: (context) => context.resoInCorso,
            target: "operand1",
            actions: [
              assign({
                displayValue: () => '0',
                messaggioToast: () => 'Selezione prodotti reso in corso'
              })
            ]
          }
        ]
      }
    },
    attesaCodiceLotteria: { // Gestire input numeri o flag boolean attesaLotteria
      on: {
        ENTER: {
          // aggiunge una nota allo scontrino
          target: "operand1",
          actions: [
            // assign({ codiceLotteriaInserito: () => true }),
            "aggiungiCodiceLotteriaAlCarrello",
            send("RIGA_AGGIUNTA"),
            send('CLEAR_PARTIAL')
          ]
        },
        CODE: {
          actions: send('ENTER')
        },
        DIGITA: {
          actions: [
            assign({ displayValue: (context, event) => event.value }),
            send('ENTER')
          ]
        },
        DIGITA_SINGOLA: {
          actions: ["appendNum"]
        },
        NUMBER: {
          actions: ["appendNum"]
        }
      }
    },
    attesaCodiceFiscale: {
      on: {
        ENTER: {
          target: "operand1",
          actions: [
            "aggiungiCodiceFiscaleAlCarrello",
            send("RIGA_AGGIUNTA"),
            send('CLEAR_PARTIAL')
          ]
        },
        CODE: {
          actions: send('ENTER')
        },
        DIGITA: {
          actions: [
            assign({ displayValue: (context, event) => event.value }),
            send('ENTER')
          ]
        },
        DIGITA_SINGOLA: {
          actions: ["appendNum"]
        },
        NUMBER: {
          actions: ["appendNum"]
        }
      }
    },
    fidelity_calcola_punti: {
      invoke: {
        id: 'calcolaPuntiFidelity',
        src: async (context, event) => {
          const infoPunti = await fidelity.infoRaccoltaPunti(context.fidelityCard._id, context.totaleScontrino)
          if (infoPunti.nonAttiva) {
            const impCard = context.impostazioni.fidelityCard
            context.confirmToast = `Attenzione! Raccolta punti non abilitata per la fidelity`
            context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: impCard.limiteDaSegnalare.sound || '' }
          }
          context.fidelityCard.infoPunti = infoPunti
        },
        onDone: {
          target: "verifica_chiusura",
          actions: send('VERIFICA_DOCUMENTO') // send('ARROTONDAMENTI_OK')
		    }
      }
    },
    verifica_chiusura: {
      on: {
        CHIUDI_SCONTRINO: [
          {
            cond: (context) => context.chiusuraPossibile,
            actions: [
              "calcolaArrotondamenti",
              // send('ARROTONDAMENTI_OK')
              send('CALCOLA_PUNTI_FIDELITY'),
              assign({totaleVincite: (context) => parseFloat(totalePerTipoItem(context, 'VINCITA'))})
            ]
          },
          {
            cond: (context) => !context.chiusuraPossibile,
            target: 'operand1',
            actions: assign({
              confermaChiusuraConBuono: () => false,
              attributiTick: (context) => context.attributiTick + 1
            })
          }
        ],
        CALCOLA_PUNTI_FIDELITY: [
          {
            cond: (context) => !context.fidelityCard,
            actions: [
              // send('ARROTONDAMENTI_OK')
              send('VERIFICA_DOCUMENTO')
            ]
          },
          {
            cond: (context) => context.fidelityCard,
            target: 'fidelity_calcola_punti'
          }
        ],
        VERIFICA_DOCUMENTO: [
          {
            cond: (context) => context.tipoDocumento === 'scontrino' && !funzioni.presenzaRigheDaNonStampare(context),
            actions: [
              /* assign({ righeCarrelloComplete: () => null }), */
              send('ARROTONDAMENTI_OK')
            ]
          },
          {
            cond: (context) => context.tipoDocumento === 'scontrino' && funzioni.presenzaRigheDaNonStampare(context),
            /* actions: assign({ righeCarrelloComplete: (context) => context.righeCarrello }), */
            target: "separa_scontrini"
          },
          {
            cond: (context) => context.tipoDocumento !== 'scontrino',
            target: "crea_documento"
          }
        ],
        ARROTONDAMENTI_OK: [
          {
            cond: (context) => !context.arrotondamentoPresente,
            target: 'operand1',
            actions: [
              "chiudiScontrino",
              send('STAMPA_TOTALE')
            ]
          },
          {
            cond: (context) => context.arrotondamentoPresente,
            target: 'operand1',
            actions: [
              send((context) => ({ riga: context.rigaCarrello, type: 'STAMPA_RIGA' })),
              "chiudiScontrino",
              send('STAMPA_TOTALE')
            ]
          }
        ],
        VERIFICA_USO_BUONI: [
          {
            cond: (context) => !context.buonoMultiuso && !context.fidelityCard && !context.buonoMonouso && !context.coupon,
            target: 'esegui_movimenti_magazzino'
          },
          {
            cond: (context) => context.buonoMultiuso,
            target: 'aggiorna_buono_multiuso'
          },
          {
            cond: (context) => context.fidelityCard,
            target: 'aggiorna_fidelity'
          },
          {
            cond: (context) => context.buonoMonouso,
            target: 'aggiorna_buono_monouso'
          },
          {
            cond: (context) => context.coupon,
            target: 'aggiorna_coupon'
          }
        ]
      }
    },
    crea_documento: {
      invoke: {
        id: 'crea_documento',
        src: async (context) => {
          const tipoPagamento = (context.importoPagato.cartadicredito > 0) ? 'CARTA_DI_CREDITO' : 'CONTANTI'
          return await emissioneDocumento(context, tipoPagamento)
        },
        onDone: {
          target: 'verifica_chiusura',
          actions: send('ARROTONDAMENTI_OK')
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore creazione documento'
          })
        }
      }
    },
    separa_scontrini: {
      invoke: {
        id: 'separa_scontrini',
        src: async (context) => {
          // 1) Verifica su quale stampante deve inviare lo scontrino
          // 2) Verifica se sono presenti articoli da non stampare

          // TODO Problemi:
          // 1) se pago una parte in contanti e una parte con cdc, come separo ? Se l'importo della cdc supera l'importo dei fiscali ?
          // 2) Come gestisco gli arrotondamenti del pagamento in contanti ?
          let arrotondamenti = _.sumBy(context.righeCarrello.filter(x => ['arrotondaEccesso', 'arrotondaDifetto'].includes(x.tipo)), (el) => parseFloat(el.prezzo))
          const righe = context.righeCarrello.filter(x => ['PRODOTTO', 'SERVIZIO', 'VINCITA'].includes(x.tipoBene))
          let righeNonFiscali = righe.filter(x => x.stampa && ['nonFiscale', 'comeNota', 'noStampa'].includes(x.stampa))
          let righeFiscali = righe.filter(x => !x.stampa || (x.stampa && ['fiscale'].includes(x.stampa)))

          let totaleFiscale = _.sumBy(righeFiscali, (el) => parseFloat(el.prezzo))
          let totaleNonFiscale = _.sumBy(righeNonFiscali.filter(x => ['PRODOTTO', 'SERVIZIO'].includes(x.tipoBene)), (el) => parseFloat(el.prezzo))
          let totaleVincite = _.sumBy(righeNonFiscali.filter(x => ['VINCITA'].includes(x.tipoBene)), (el) => parseFloat(el.prezzo))

          let delta = +(totaleNonFiscale + arrotondamenti).toFixed(2) // Tiene conto anche di eventuali arrotondamenti inseriti dalla procedura originale
          context.righeFiscali = (righeFiscali.length > 0)
          if (righeFiscali.length > 0) {
            // TODO: Filtrare le righe direttamente in stampa, altrimenti si perdono per i movimenti di magazzino, etc.
            context.righeCarrello = righeFiscali
            let totale = parseFloat(funzioni.fnCalcolaTotaleScontrino(context))
            let cartadicredito = context.importoPagato.cartadicredito
            let contanti = context.importoPagato.contanti
            // let scontoapagare = context.importoPagato.scontoapagare

            if (cartadicredito > 0) {
              if (cartadicredito > totale) {
                context.importoPagato.cartadicredito = totale
                cartadicredito = +(cartadicredito - totale).toFixed(2)
                totale = 0
                context.importoPagato.contanti = 0
              } else {
                totale -= cartadicredito
              }
            }
            // if (totale > 0 && contanti > 0) {
            if (contanti > 0) {
              if (contanti > totale) {
                context.importoPagato.contanti = totale
                contanti = +(contanti - totale).toFixed(2)
                totale = 0
              } else {
                totale -= contanti
              }
            }
            if (context.resto) {
              context.giaPagatoTotale = +(context.giaPagatoTotale - delta).toFixed(2)
              context.importoPagato.contanti = +(context.importoPagato.contanti + context.resto + arrotondamenti).toFixed(2)
              context.resto = +(context.resto - arrotondamenti).toFixed(2)
            }

            context.itemsScontrino = funzioni.fnCalcolaNumeroItemScontrino(context)
            funzioni.calcolaArrotondamenti(context)

            let righeComeNota = righe.filter(x => x.stampa && ['comeNota'].includes(x.stampa))
            if (righeComeNota.length > 0) {
              context.noteDiCoda.push(_.get(context.impostazioni,'noteDiCoda.apertura', '----------- ALTRI PRODOTTI -----------'))
              // addNota(context, { tipoRiga: 'descrizione', nota: '-'.repeat(30) })
              var info
              for(let r of righeComeNota) {
                info = `${r.descrizione.padEnd(30).slice(0, 30)}${String(r.prezzo).padStart(7).slice(-7)}`
                // addNota(context, { tipoRiga: 'descrizione', nota: info })
                context.noteDiCoda.push(info)
              }

              // TODO: Verificare se serve e con che descrizione inserirlo
              const tot = totaleNonFiscale.toFixed(2)
              info = `${'EURO'.padEnd(30)}${tot.padStart(7).slice(-7)}`
              // context.noteDiCoda.push(info)

              // addNota(context, { tipoRiga: 'descrizione', nota: info })
              // addNota(context, { tipoRiga: 'descrizione', nota: '-'.repeat(30) })
              context.noteDiCoda.push(_.get(context.impostazioni,'noteDiCoda.chiusura','-'.repeat(38)))
            }
          }
          if (righeNonFiscali.length > 0) {
            if (righeFiscali.length === 0) {
              context.righeCarrello = []
            }
            context.righeNonFiscali = righeNonFiscali
            let righeDaStampare = righe.filter(x => x.stampa && ['nonFiscale'].includes(x.stampa))
            if (righeDaStampare.length > 0) {
              // Crea uno scontrino gestionale
              const scontrino_info = []
              var info
              for(let r of righeDaStampare) {
                info = `${r.descrizione.padEnd(30).slice(0, 30)}${String(r.prezzo).padStart(7).slice(-7)}`
                scontrino_info.push(info)
              }
              const tot = totaleNonFiscale.toFixed(2)
              info = `${'EURO'.padEnd(30)}${tot.padStart(7).slice(-7)}`
              scontrino_info.push(info)
              // TODO: Verificare se inserire un barcode
              const barcode = null
              await stampante.stampaScontrinoGestionale(scontrino_info, barcode)
            }
          }

        },
        onDone: [
          {
            cond: (context) => context.righeFiscali,
            target: 'verifica_chiusura',
            actions: send('ARROTONDAMENTI_OK')
          },
          {
            cond: (context) => !context.righeFiscali,
            target: 'verifica_chiusura',
            actions: send('VERIFICA_USO_BUONI')
          }
        ],
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore separazione scontrini'
          })
        }
      }
    },
    aggiorna_buono_multiuso: {
      invoke: {
        id: 'aggiorna_saldo_buono_multiuso',
        src: async (context) => {
          var result
          if (context.buoniMultiuso.length > 0 && context.buoniMultiuso.some(x => x.buono.usoAttuale > 0)) {
            for (let b of context.buoniMultiuso) {
              result = await fidelity.aggiornaBuonoMultiuso(b)
            }
          }
          return result
          // return await fidelity.aggiornaBuonoMultiuso(context.buonoMultiuso)
        },
        onDone: {
          target: 'esegui_movimenti_magazzino',
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore aggiornamento buono'
          })
        }
      }
    },
    aggiorna_coupon: {
      invoke: {
        id: 'aggiorna_saldo_coupon',
        src: async (context) => {
          var result
          if (context.coupon) {
            context.coupon.idScontrino = context.idScontrino
            result = await fidelity.aggiornaCoupon(context.coupon)
          }
          return result
        },
        onDone: {
          target: 'esegui_movimenti_magazzino',
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore aggiornamento coupon'
          })
        }
      }
    },
    aggiorna_buono_monouso: {
      invoke: {
        id: 'aggiorna_saldo_buono_monouso',
        // src: (context, event) => fidelity.aggiornaBuonoMonouso(context.buonoMonouso),
        src: async (context) => {
          return await fidelity.aggiornaBuonoMonouso(context.buonoMonouso)
        },
        onDone: {
          target: 'esegui_movimenti_magazzino',
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore aggiornamento buono'
          })
        }
      }
    },
    aggiorna_fidelity: {
      invoke: {
        id: 'aggiorna_saldo_fidelity',
        // src: (context, event) => fidelity.aggiornaPrepagata(context.fidelityCard),
        src: async (context) => {
          const card = context.fidelityCard
          if (card.raccoltaPunti) {
            const updatedCard = await fidelity.aggiornaRaccoltaPunti(context.fidelityCard, context.scontrinoStampato)
            const impCard = context.impostazioni.fidelityCard
            if (impCard) {
              const limite = _.get(impCard, 'limiteDaSegnalare.punti', 0)
              context.fidelityCard.limiteRaggiunto = (limite && updatedCard.raccoltaPunti.totale >= limite)
              if (context.fidelityCard.limiteRaggiunto) {
                context.confirmToast = `Il cliente ha superato ${limite} punti !!!`
                context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: impCard.limiteDaSegnalare.sound || '' }
                const tipoStampa = _.get(impCard, 'limiteDaSegnalare.tipoStampa', 'coupon')
                const valoreCoupon = _.get(impCard, 'limiteDaSegnalare.coupon.valore', 20)

                // context.infoScontrinoGestionale = _.get(impCard, 'limiteDaSegnalare.scontrino', null)
                let scontrino_info = JSON.parse(JSON.stringify(_.get(impCard, 'limiteDaSegnalare.scontrino', [])))
                let scontrinoComandi = JSON.parse(JSON.stringify(_.get(impCard, 'limiteDaSegnalare.scontrinoComandi', [])))
                let barcode = null
                const puntiTotali = updatedCard.raccoltaPunti.totale
                const puntiResidui = puntiTotali - limite
                if (scontrino_info.length > 0) {
                  try {
                    for (let i = 0; i < scontrino_info.length; i++) {
                      scontrino_info[i] = scontrino_info[i].replace('#cliente#', context.cliente.ragioneSociale)
                      scontrino_info[i] = scontrino_info[i].replace('#codice_cliente#', context.cliente._id)
                      scontrino_info[i] = scontrino_info[i].replace('#fidelity#', context.fidelityCard.codice)
                      scontrino_info[i] = scontrino_info[i].replace('#punti#', limite)
                      scontrino_info[i] = scontrino_info[i].replace('#valore#', valoreCoupon)
                      scontrino_info[i] = scontrino_info[i].replace('#punti_totali#', puntiTotali)
                      scontrino_info[i] = scontrino_info[i].replace('#punti_residui#', puntiResidui)
                    }
                    if (_.get(impCard, 'limiteDaSegnalare.barcode_su_scontrino', false)) {
                      barcode = {
                        code: context.fidelityCard.codice
                      }
                    }
                    if (tipoStampa === 'coupon') {
                      // crea il coupon come fidelity card e associa numero
                      const idCassa = context.impostazioni.stampante.progressivo_stampante
                      const applicaA = _.get(impCard, 'limiteDaSegnalare.coupon.applicaA', 'subtotale')
                      const coupon = await fidelity.creaCoupon(idCassa, valoreCoupon, applicaA)
                      const tipoStampante = _.get(impCard, 'limiteDaSegnalare.coupon.tipoStampante', 'fiscale')
                      if (tipoStampante === 'fiscale') {
                        await stampante.stampaScontrinoGestionale(scontrino_info, barcode)
                      } else {
                        await escpos.stampaCoupon(scontrino_info, barcode)
                      }
                    } else {
                      await stampante.stampaScontrinoGestionale(scontrino_info, barcode)
                    }
                  } catch (err) {
                    console.log(err)
                  }
                }
                if (scontrinoComandi.length > 0) { // in questo caso solo su Stampante ESCPOS --> comande
                  try {
                    if (tipoStampa === 'coupon') {
                      var coupon
                      // crea il coupon come fidelity card e associa numero
                      const idCassa = context.impostazioni.stampante.progressivo_stampante
                      const applicaA = _.get(impCard, 'limiteDaSegnalare.coupon.applicaA', 'subtotale')
                      coupon = await fidelity.creaCoupon(idCassa, valoreCoupon, applicaA)
                    }
                    for (let i = 0; i < scontrinoComandi.length; i++) {
                      scontrinoComandi[i].v1 = String(scontrinoComandi[i].v1).replace('#cliente#', context.cliente.ragioneSociale)
                      scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#codice_cliente#', context.cliente._id)
                      scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#fidelity#', context.fidelityCard.codice)
                      scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#punti#', limite)
                      scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#valore#', valoreCoupon)
                      scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#punti_totali#', puntiTotali)
                      scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#punti_residui#', puntiResidui)
                      // TODO: EAN13 deve passare 12 char, verificare per altri
                      scontrinoComandi[i].v1 = scontrinoComandi[i].v1.replace('#barcode#', coupon.codice.substring(0, 12))
                    }
                    if (tipoStampa === 'coupon') {
                      const tipoStampante = _.get(impCard, 'limiteDaSegnalare.coupon.tipoStampante', 'fiscale')
                      if (tipoStampante === 'comande') {
                        await escpos.stampaCouponComandi(scontrinoComandi)
                      }
                    }
                  } catch (err) {
                    console.log(err)
                  }
                }
                if (_.get(impCard, 'limiteDaSegnalare.azzeramento.abilita', false)) {
                  /* const puntiTotali = card.raccoltaPunti.totale
                  const puntiResidui = puntiTotali - limite */
                  const descrizione = _.get(impCard, 'limiteDaSegnalare.azzeramento.descrizione', 'Buono da #punti# punti')
                  updatedCard.raccoltaPunti.totale = puntiResidui
                  updatedCard.raccoltaPunti.dataAzzeramento = new Date()
                  updatedCard.raccoltaPunti.azzeramenti = card.raccoltaPunti.azzeramenti || []
                  updatedCard.raccoltaPunti.azzeramenti.push({
                    data: updatedCard.raccoltaPunti.dataAzzeramento,
                    punti: puntiTotali,
                    puntiResidui: puntiResidui,
                    puntiUtilizzati: limite,
                    descrizione: descrizione.replace('#punti#', limite)
                  })
                  await fidelity.aggiornaFidelity(updatedCard._id, updatedCard)
                  context.fidelityCard.punti = puntiResidui
                }
              }
            }
          }
          if (card.buono) {
            await fidelity.aggiornaPrepagata(context.fidelityCard)
          }
        },
        onDone: {
          target: 'esegui_movimenti_magazzino',
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore aggiornamento fidelity card'
          })
        }
      }
    },
    esegui_movimenti_magazzino: {
      invoke: {
        id: 'esegui_movimenti_magazzino',
        src: async (context) => {
          console.log('esegui_movimenti_magazzino')
          let scontrino = context.scontrinoStampato || { _id: context.idScontrino }

          if (context.cliente && scontrino) { //TODO: VERIFICARE ####
            scontrino.cliente = context.cliente
            scontrino.listino = context.listino
          }
          // aggiungere righeNonFiscali !== VINCITA: Verificare scontrino misto / scontrino solo non fiscale
          // const righeMovimenti = (context.righeCarrello).concat(context.righeNonFiscali.filter(x => x.tipoBene !== 'VINCITA'))
          const righeMovimenti = (context.righeCarrello).concat(context.righeNonFiscali) // .filter(x => x.tipoBene !== 'VINCITA'))
          if (righeMovimenti.length > 0) {
            await magazzino.creaMovimentiScontrino('scontrino', 'mag01', 'venditaClienti', righeMovimenti, scontrino, context.documento)
          }

          // Devo aggiornare manualmente il documento se non ho righe fiscali, altrimenti rimane nello stato di aperto
          // ATTENZIONE verificare: La procedura chiude comunque lo scontrino anche se lato pagamento potrebbero esserci problemi ...

          if (context.righeNonFiscali.length > 0 && context.tipoDocumento === 'scontrino') {
            await scontrini.aggiornaDocumentoScontrino(context.idScontrino, null, context.righeNonFiscali)
          }

          if (context.tipoDocumento !== 'scontrino') {
            await scontrini.aggiornaDocumentoScontrino(context.idScontrino, context.documento)
            if (context.tipoDocumento !== 'scontrinoSegueFattura' ) {
              await documenti.aggiornaScontrinoDocumento(context.documento._id, context.idScontrino)
            }
          }
          if (_.get(context.impostazioni, 'cassa.contabili.vincite', false) && context.totaleVincite > 0) {
            const operatore = { codice: fnGetOperatore(context), descrizione: fnGetNomeOperatore(context) }
            context.inputDescrizione = 'Pagamento vincita'
            await operazioniCassa.cassaMovimenti(context, { value: 'VINCITA' }, operatore, configurazioni.getPostazione(), context.totaleVincite)

          }
          return true
        },
        onDone: [
          {
            cond: (context) => context.righeReso && context.impostazioni.resi.ristampa_cartellino_prodotti,
            target: 'attesaInputDopoChiudi',
            // actions: (context) => { debugger } --> gestire ristampa cartellino prodotti. Verificare impostazione salta, etc. oppure inserire direttamente i prodotti
            //                                        in una lista da cui far stampare le etichette (es. movimenti ultimo scontrino, etc)
          },
          {
            target: 'attesaInputDopoChiudi'
          }
        ],
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore inserimento movimenti a magazzino'
          })
        }
      }
    },
    esegui_movimenti_magazzino_annullamento: {
      invoke: {
        id: 'esegui_movimenti_magazzino_annullamento',
        src: async (context, event) => {
          console.log('esegui_movimenti_magazzino_annullamento')
          const scontrino = { _id : context.scontrinoAnnullamento.idScontrino, cliente: '' }
          return await magazzino.creaMovimentiScontrino('annullamento', 'mag01', 'annullamentoScontrino', context.righeCarrello, scontrino )
        },
        onDone: [
          {
            cond: (context) => context.comandoScontrini === 'ANNULLAMENTO_FISCALE',
            target: 'operand1',
            actions: "reset"
          },
          {
            cond: (context) => context.comandoScontrini === 'ANNULLAMENTO_FISCALE_RIUSO',
            target: 'riusa_prodotti_annullo'
          }
        ],
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore inserimento movimenti a magazzino'
          })
        }
      }
    },
    usa_selezione: {
      invoke: {
        id: 'usaSelezione',
        src: async (context, event) => {
          if (context.righeCarrello.length > 0) {
            switch(event.value) {
              case 'AUTOCONSUMO':
                await usaSelezione.autoconsumo(context.righeCarrello)
                break;
              case 'PC':
                await usaSelezione.pc(context.righeCarrello)
                break;
              case 'ACQUISTO':
                const causaleMovimento = _.get(context, 'impostazioni.articoli.usaSelezione.causaleMovimento', 'acquisto')
                await usaSelezione.acquisto(context.righeCarrello, causaleMovimento, context.usaSelezione.riferimento, context.usaSelezione.data)
                break;
              case 'RISCONTRO_DDT':
                // seleziona il DDT di riferimento + imposta le qtà arrivate + aggiunge eventuali prodotti non presenti
                const codice = event.payload.code
                const documento = await documenti.riscontroDocumento(codice, context.righeCarrello)
                await usaSelezione.verificaAggiungiMovimenti(documento, context.righeCarrello)
                context.popups.find(el => el.type === 'CercaDocumento').show = false
                break
              default:
                throw 'Comando non riconosciuto'
            }
          } else {
            throw 'Nessun articolo selezionato'
          }
        },
        onDone: {
          target: "operand1",
          actions: [
            assign({
              displayValue: () => '0',
              messaggioToast: () => 'Movimenti creati con successo'
            }),
            send('ANNULLA_SCONTRINO')
          ]
        },
        onError: {
          target: "operand1",
          actions: [
            assign({
              displayValue: () => '0',
              messaggioToast: (context, event) => event.data || 'Errore generazione movimento'
            })
          ]
        }
      }
    },
    cassa_movimenti: {
      invoke: {
        id: 'cassaMovimenti',
        src: async (context, event) => {
          const operatore = { codice: fnGetOperatore(context), descrizione: fnGetNomeOperatore(context) }
          return await operazioniCassa.cassaMovimenti(context, event, operatore, configurazioni.getPostazione())
        },
        onDone: {
          target: "operand1",
          actions: [
            assign({
              displayValue: () => '0',
              messaggioToast: () => 'Movimento creato con successo'
            }),
            send({ type: 'ANNULLA_SCONTRINO' }, { delay: 1000 })
          ]
        },
        onError: {
          target: "operand1",
          actions: [
            assign({
              displayValue: () => '0',
              messaggioToast: (context, event) => event.data.message || 'Errore generazione movimento'
            })
          ]
        }
      }
    },
    custom_aggiorna_tabacchi: {
      invoke: {
        id: 'customAggiornaTabacchi',
        src: async (context, event) => {
          return await tabelle.customAggiornaTabacchi()
        },
        onDone: {
          target: "operand1",
          actions: [
            assign({
              displayValue: () => '0',
              messaggioToast: () => 'Aggiornamento effettuato con successo'
            })
          ]
        },
        onError: {
          target: "operand1",
          actions: [
            assign({
              displayValue: () => '0',
              messaggioToast: (context, event) => event.data.message || 'Errore aggiornamento tabacchi'
            })
          ]
        }
      }
    },
    modale_aperta: {
      on: {
        MODALE_CLIENTI: {
          target: 'operand1',
          actions: [
            assign({ cliente: (context, event) => event.value.cliente }),
            assign({ tipoDocumento: (context, event) => event.value.tipoDocumento }),
            assign({ tipoDocumentoDesc: (context, event) => event.value.tipoDocumentoDesc }),
            assign({ modalShow: () => false }),
            assign({ modalType: () => '' }),
            (context, event) => context.modali.splice(-1, 1)
          ]
        },
        PRODOTTO: {
          target:'operand1',
          actions: send((context, event) => { return { value: event.value, type: 'PRODOTTO' } })
        },
        LISTINO: {
          target: 'operand1',
          actions: [
            assign({ listino: (context, event) => event.value.listino }),
            (context, event) => context.modali.splice(-1, 1)
          ]
        },
        DESCRIZIONE_CASSA: {
          target: 'cassa_movimenti',
          actions: [
            assign({ inputDescrizione: (context, event) => event.payload.value }),
            (context) => context.modali.splice(-1, 1)
          ]
        }
      }
    },
    popup_aperto: {
      on: {
        PRODOTTO: {
          target:'operand1',
          actions: send((context, event) => { return { value: event.value, type: 'PRODOTTO' } })
        },
        PRODOTTO_CASSA: { // TODO: Attenzione se il carrello è vuoto (la prima volta) non aggiunge e non chiude ...
          target:'operand1',
          actions: [
            send((context, event) => { return { value: event.value, type: 'PRODOTTO' } }),
            (context) => context.popups.find(el => el.type === 'Funzioni').show = false
          ]
        },
        PRODOTTO_CERCA: { // TODO: Attenzione se il carrello è vuoto (la prima volta) non aggiunge e non chiude ...
          target:'operand1',
          actions: [
            send((context, event) => { return { value: event.value, type: 'PRODOTTO' } }),
            (context) => context.popups.find(el => el.type === 'CercaProdotto').show = false
          ]
        }
      }
    },
/*     resi_su_scontrino: {
      invoke: {
        id: 'resi_su_scontrino',
        // passa idScontrino dello scontrino originale, in cui inserire le righe di reso
        // src: (context, event) => scontrini.resiSuScontrino(context.resoFiscale.idScontrino, context.righeReso),
        src: async (context) => {
          return await scontrini.resiSuScontrino(context.resoFiscale.idScontrino, context.righeReso)
        },
        onDone: {
          target: "operand1.zero",
          actions: [ assign({ })]
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => event.data
          })
        }
      }
    }, */
    start: {
      on: {
        NUMBER: [
          {
            cond: "useNotDecimalPoint",
            target: "operand1.generic_value",
            actions: ["appendNum"]
          },
          {
            cond: "isZero",
            target: "operand1.zero",
            actions: ["defaultReadout"]
          },
          {
            cond: "isNotZero",
            target: "operand1.before_decimal_point",
            actions: ["setReadoutNum"]
          }
        ],
        DECIMAL_POINT: {
          target: "operand1.after_decimal_point",
          actions: ["defaultReadout"]
        },
        PRICE: [
          {
          }
        ],
        // CODE: {},
        PARK: {},
        MULTIPLY: {},
        CLEAR: {}
      }
    },
    errore: {

    },
    operand1: {
      on: {
       "*": {
          cond: (context) => context.stampaEffettuata,
          actions: (context) => console.log("bloccato operand1")
        },
        CLEAR_EVERYTHING: {
          target: "operand1.zero",
          actions: ["reset"]
        },
        CLEAR_ENTRY: {
          // target: "operand1.zero",
          actions: ["clearParziale"]
          // actions: ["reset"] // Verificare se reset o clearParziale
        },
        // CODE: {},
        PARK: {},
        MULTIPLY: [
          {
            cond: (context) =>
              context.displayValue !== '0' &&
              context.rigaSelezionata != null &&
              context.rigaSelezionata < 1000 &&
              !context.resoInCorso &&
              !['subtotale', 'abbuonoSub', 'scontoSub'].includes(context.righeCarrello[context.rigaSelezionata].tipo),
            actions: [
              "modificaQuantitaRiga",
              send('CALCOLA_TOTALE_SCONTRINO'),
              send('CLEAR_PARTIAL'),
              send('FOCUS'),
              send((context) => ({ riga: context.righeCarrello[context.rigaSelezionata], type: 'MODIFICA_RIGA' }))
            ]
          },
          {
            cond: (context) => context.rigaSelezionata != null && context.displayValue !== '0' && context.displayValue !== '' && context.resoInCorso,
            actions: [
              send({ type: 'RENDI_ITEM' })
            ]
          },
          {
            cond: (context) => context.rigaSelezionata == null && context.displayValue !== '0' && context.displayValue !== '' && !context.resoInCorso,
            target: ".generic_value",
            actions: [
              "multiply_quantity",
              send('FOCUS')
            ]
          },
          {
            actions: send('CLEAR_PARTIAL')
          }
        ],
        FOCUS: {
          actions: [
            assign({ displayFocus: (context) => context.displayFocus + 1 }),
            (context) => { console.log('displayFocus: ', context.displayFocus) }
          ]
          // E' necessario ridare il fuoco a display, altrimenti su multiply e price, riprende lo stesso evento
        },
        CLEAR: [
          {
           //  cond: "isRigaSelezionata",
            cond:  (context) => context.displayValue === '0' && context.rigaSelezionata != null,
            actions: [
              // (context, event) => { debugger }, // TODO: Quando ci viene ? Verificare se deve rimanere STAMPA_RIGA
              "cancellaRiga",
              send('CALCOLA_TOTALE_SCONTRINO'),
              send('CLEAR_PARTIAL'),
              send((context, event) => ({ riga: context.rigaCarrello, type: 'STAMPA_RIGA' })) // verificare deve cancellare
            ]
          },
          {
            cond:  (context) => context.displayValue !== '0' && context.rigaSelezionata != null,
            actions: ["clearNum"]
          },
          {
            cond: "useNotDecimalPoint",
            // target: ".generic_value",
            actions: ["clearNum"]
          }
        ],
        REPARTO: [
          {
            cond: (context) => context.resoInCorso,
            target: "operand1",
            actions: [
              assign({
                displayValue: () => '0',
                messaggioToast: () => 'Selezione prodotti reso in corso'
              })
            ]
          },
          {
            cond: (context, event) => context.displayValue !== '0' && !context.isSubTotaleCalcolato,
            target: "reparto_info"
          },
          {
            cond: "isDisplayZero",
            meta: {
              message: "E' necessario impostare il prezzo !"
            },
            actions: assign({ displayMessage: () => "E' necessario impostare il prezzo !" })
          }
        ],
        PRODOTTO : [
          {
            cond: (context) => context.resoInCorso,
            target: "operand1",
            actions: [
              assign({
                displayValue: () => '0',
                messaggioToast: () => 'Selezione prodotti reso in corso'
              })
            ]
          },
          {
            cond: (context) => context.isSubTotaleCalcolato
          },
          {
            target: "prodotto_info",
            actions: [
              assign({ rigaSelezionata: (context, event) => null })
            ]
          }
        ],
        CLIENTE : {
          target: "cliente_info"
        },
        FIDELITY: {
          target: "fidelity_info"
        },
        CLIENTE_FIDELITY: {
          target: "cliente_fidelity_info"
        },
        BUONO_MULTIUSO: {
          target: "buono_info"
        },
        BUONO_MONOUSO: {
          target: "buono_info"
        },
        RISTAMPA_BUONO: {
          target: "gestioneScontrino",
          actions: assign({
            infoRistampaBuono: (context, event) => event.value.scontrino,
            comandoScontrini: () => 'RISTAMPA_BUONO'
          })
        },
        ANNULLA_SCONTRINO: [
          {
            cond: (context) => context.idScontrino !== '',
            target: "gestioneScontrino",
            actions: [
              assign({
                comandoScontrini: () => 'ANNULLA_SCONTRINO',
                targetDoneScontrini: () => 'scontrinoAnnullato',
                displayFocus: (context) => context.displayFocus + 1,
                inviaBenvenutoAMF: () => true
              })
            ]
          },
          {
            cond: (context) => context.idScontrino === '',
            actions: [
              "reset",
              (context) => {
                context.attributes = {}
                context.importoPagato = {}
                context.inviaBenvenutoAMF = true
              },
              assign({
                displayFocus: (context) => context.displayFocus + 1,
                resetBuffer: (context) => context.resetBuffer + 1
              })
            ]
          }
        ],
        ELIMINA_SCONTRINO: {
          target: "gestioneScontrino",
          actions: [
            assign({ comandoScontrini: () => 'ELIMINA_SCONTRINO' })
            // assign({ idEliminare: (context) => context. }),
            // assign({ targetDoneScontrini: () => 'scontrinoEliminato' })
          ]
        },
        PRICE: [
          {
            cond: (context) => context.isSubTotaleCalcolato,
            actions: send('CLEAR_PARTIAL')
          },
          {
            cond: (context) => context.displayValue !== '0' &&
              context.rigaSelezionata != null &&
              ['descrizione'].includes(context.righeCarrello[context.rigaSelezionata].tipo),
            actions: send('CLEAR_PARTIAL')
          },
          {
            cond: "isRigaSelezionata",
/*             cond: (context) =>
              context.displayValue !== '0' &&
              context.rigaSelezionata != null &&
              context.rigaSelezionata < 1000 &&
              !['subtotale', 'abbuonoSub', 'scontoSub'].includes(context.righeCarrello[context.rigaSelezionata].tipo),  */
            actions: [
              "modificaPrezzoRiga",
              send('CALCOLA_TOTALE_SCONTRINO'),
              send('CLEAR_PARTIAL'),
              send('FOCUS'),
              // send((context, event) => ({ riga: context.rigaCarrello, type: 'STAMPA_RIGA' })) // verificare deve aggiornare e non aggiungere
              send((context) => ({ riga: context.righeCarrello[context.rigaSelezionata], type: 'MODIFICA_RIGA' }))
            ]
          },
          {
            cond: (context, event) => context.displayValue !== '0' && context.displayDescrizione.length === 0,
/*             cond: (context) => {
             //  debugger;
            return context.displayValue !== '0' &&
            context.displayDescrizione.length === 0 &&
            context.rigaSelezionata < 1000 &&
            !['subtotale', 'abbuonoSub', 'scontoSub'].includes(context.righeCarrello[context.rigaSelezionata].tipo) }, */
            actions: [
              assign({
                prezzoImposto: (context, event) => (parseFloat(context.displayValue)/100).toFixed(2),
                displayValue: () => "0",
                displayDescrizione: () => ''
              }),
              send('FOCUS')
            ]
          },
          {
            cond: (context, event) => context.displayValue === '0' || context.displayDescrizione.length > 0,
            actions: [assign({ richiestaPrezzo: () => true })]
          },
          {
            actions: send('CLEAR_PARTIAL')
          }
        ],
        SUBTOTALE: [
          {
            cond: (context) => context.resoInCorso,
            actions: [
              assign({
                displayValue: () => '0',
                messaggioToast: () => 'Selezione prodotti reso in corso'
              })
            ]
          },
          {
            cond: (context) => !context.righeCarrello.some(x => x.tipo === 'subtotale') && context.totaleScontrino,
            actions: [
              assign({ isSubTotaleCalcolato: () => true }),
              assign({ displayValue: (context) => '0' }),
              send(() => ({ type: 'NOTA', tipoRiga: 'separatore', nota: '', prezzo: '' })),
              send((context) => ({
                type: 'NOTA',
                tipoRiga: 'subtotale',
                nota: 'Subtotale',
                prezzo: context.impostazioni.carrello.totale_solo_vendita ? fnCalcolaTotaleScontrinoNoResi(context) : context.totaleScontrino
              }))
            ]
          }
        ],
        SELECT_RIGA_SCONTRINO: [
          {
            cond: (context) => (context.displayValue === '' || context.displayValue ==='0') && context.resoInCorso,
            target: "operand1.riga_selezionata",
            actions: [
              assign({
                // rigaSelezionata: (context, event) => event.value.rigaId,
                rigaSelezionata: (context, event) => getIdRigaDaCodice(context, event.value.rigaCodice),
                rendiItemVisible: (context) => context.resoInCorso,
                scontrinoTick: (context) => context.scontrinoTick + 1
              })
              // send('RENDI_ITEM')
            ]
          },
          {
            cond: (context) => (context.displayValue !== '' && context.displayValue !=='0') && context.resoInCorso,
            target: "operand1.riga_selezionata",
            actions: [
              assign({
                // rigaSelezionata: (context, event) => event.value.rigaId,
                rigaSelezionata: (context, event) => getIdRigaDaCodice(context, event.value.rigaCodice),
                rendiItemVisible: (context) => context.resoInCorso,
                scontrinoTick: (context) => context.scontrinoTick + 1
              }),
              send('RENDI_ITEM')
            ]
          },
          {
            cond: (context) => (context.displayValue === '' || !context.resoInCorso) && ( context.quantita === 1 && context.prezzoImposto === ''), // dovrebbe essere: diversa da quella impostata ...
            target: "operand1.riga_selezionata",
            actions: [
              assign({
                // rigaSelezionata: (context, event) => event.value.rigaId,
                rigaSelezionata: (context, event) => getIdRigaDaCodice(context, event.value.rigaCodice),
                rendiItemVisible: (context) => context.resoInCorso,
                scontrinoTick: (context) => context.scontrinoTick + 1
              })
            ]
          },
          {
            cond: (context, event) => {
              return (context.displayValue === '0' || !context.resoInCorso) &&
              (context.quantita !== 1 || context.prezzoImposto !== '') &&
              !['subtotale', 'abbuonoSub', 'scontoSub'].includes(context.righeCarrello[getIdRigaDaCodice(context, event.value.rigaCodice)].tipo)},
            target: "operand1.riga_selezionata",
            actions: [
              assign({
                // rigaSelezionata: (context, event) => event.value.rigaId
                rigaSelezionata: (context, event) => getIdRigaDaCodice(context, event.value.rigaCodice),
              }),
              "modificaValoriRiga",
              send('CALCOLA_TOTALE_SCONTRINO'),
              send('CLEAR_PARTIAL'),
              send((context) => ({ riga: context.righeCarrello[context.rigaSelezionata], type: 'MODIFICA_RIGA' }))
            ]
          }
        ],
        ANNULLAMENTO_FISCALE_SI: {
          target:'attesaInputSuRecupero',
          actions: send('ANNULLAMENTO_FISCALE_SI')
        },
        ANNULLAMENTO_FISCALE_RIUSO_SI: {
          target:'attesaInputSuRecupero',
          actions: send('ANNULLAMENTO_FISCALE_RIUSO_SI')
        }
      },
      initial: "zero",
      states: {
        zero: {
          on: {
           "*": {
              cond: (context) => context.stampaEffettuata,
              actions: (context) => console.log("bloccato operant1.zero")
            },
            NUMBER: [
              {
                cond: "useDecimalPoint",
                target: "before_decimal_point",
                actions: ["setReadoutNum"]
              },
              {
                cond: (context, event) => useNotDecimalPoint && !context.richiestaPrezzo,
                target: "generic_value",
                actions: ["appendNum"]
              },
              {
                // cond: "useNotDecimalPoint",
                cond: (context, event) => useNotDecimalPoint && context.richiestaPrezzo,
                target: "generic_value",
                actions: [
                  assign({ richiestaPrezzo : () => false }),
                  "clearAndAppendNum"
                ]
              }
            ],
            DECIMAL_POINT:[
              {
                cond: "useDecimalPoint",
                target: "after_decimal_point",
                actions: ["appendDecimalPoint"]
              },
              {
                cond: "useNotDecimalPoint",
                target: "generic_value",
                actions: ["numBy100"]
              }
            ],
            RIGA_AGGIUNTA: {
              actions: send((context, event) => ({ riga: context.rigaCarrello, type: 'STAMPA_RIGA' }))
            },
            SCONTO_AGGIUNTO: {
              actions: [
                // send((context, event) => ({ riga: context.rigaCarrello.sconto, type: 'STAMPA_RIGA' }))
                assign({ rigaSelezionata: (context) => getIdRigaDaCodice(context, context.rigaCarrello.rigaCodice) }),
                send((context) => ({ riga: context.rigaCarrello, type: 'MODIFICA_RIGA' }))
              ]
            },
            SCONTO_MODIFICATO: { // Verificare se lo scontrino salvato su DB tiene il prodotto
              actions: [
                send((context) => ({ riga: context.righeCarrello[context.rigaSelezionata], type: 'MODIFICA_RIGA' })),
              ]
            },
            ABBUONO_AGGIUNTO: { // Verificare se lo scontrino salvato su DB tiene il prodotto
              // actions: send((context, event) => ({ riga: context.rigaCarrello, type: 'STAMPA_RIGA' }))
              actions: [
                assign({ rigaSelezionata: (context) => getIdRigaDaCodice(context, context.rigaCarrello.rigaCodice) }),
                send((context) => ({ riga: context.rigaCarrello, type: 'MODIFICA_RIGA' }))
              ]
            },
            ABBUONO_MODIFICATO: {
              actions: [
                send((context) => ({ riga: context.righeCarrello[context.rigaSelezionata], type: 'MODIFICA_RIGA' })),
              ]
            },
            SCONTRINO_REGALO: [
              {
                cond: (context) => context.resoInCorso,
                target: "zero",
                actions: [
                  assign({
                    displayValue: () => '0',
                    messaggioToast: () => 'Selezione prodotti reso in corso'
                  })
                ]
              },
              {
                cond: (context) => context.quantita <= 5,
                actions: send('SCONTRINO_REGALO_CONFIRM')
              },
              {
                actions: (context, event) => {
                  context.confirmAction = { type: 'SCONTRINO_REGALO_CONFIRM' }
                  context.confirmToast = `Confermi la stampa di ${context.quantita} scontrini regalo ?`
                  context.confirmToastObj = { event: 'FUNZIONE_RAPIDA', title: 'Scontrini regalo', cancelText: 'NO' , confirmText: 'SI' }
                }
              }
            ],
            SCONTRINO_REGALO_CONFIRM: {
              target: "zero",
              actions: [
                'aggiungiCortesiaAlCarrello',
                send('CALCOLA_TOTALE_SCONTRINO'),
                send('CLEAR_PARTIAL'),
                send('RIGA_AGGIUNTA')
              ]
            },
            PRINTER_RESUME: {
              actions: send('GET_PRINTER_STATUS')
            },
            BARCODE_DIVERSO: [
              {
                cond: (context, event) => event.data.tipoBarcode === 'fidelitycard',
                target: '..operand1',
                actions: [
                  send((context, event) => ({ value: { code: event.data.code }, type: 'FIDELITY' }))
                ]
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'buonomultiuso',
                target: '..operand1',
                actions: [
                  send((context, event) => ({ value: { code: event.data.code }, type: 'BUONO_MULTIUSO' }))
                ]
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'buonomonouso',
                target: '..operand1',
                actions: [
                  send((context, event) => ({ value: { code: event.data.code }, type: 'BUONO_MONOUSO' }))
                ]
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'scontrino',
                target: '..operand1',
                actions: [
                  send((context, event) => ({ value: { code: event.data.code }, type: 'SCONTRINO' }))
                ]
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'lotteria' && codiceLotteriaInserito(context),
                actions: assign({ displayMessage: () => 'Codice già inserito !!!' })
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'lotteria' && lotteriaOrCodiceFiscale(context),
                actions: assign({ displayMessage: () => 'Lotteria e Codice Fiscale sono alternativi' })
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'lotteria',
                target: '..operand1',
                actions: [
                  "aggiungiCodiceLotteriaAlCarrello",
                  send("RIGA_AGGIUNTA"),
                  send('CLEAR_PARTIAL')
                ]
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'codicefiscale' && codiceFiscaleInserito(context),
                actions: assign({ displayMessage: () => 'Codice già inserito !!!' })
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'codicefiscale' && lotteriaOrCodiceFiscale(context),
                actions: assign({ displayMessage: () => 'Lotteria e Codice Fiscale sono alternativi' })
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'codicefiscale',
                target: '..operand1',
                actions: [
                  "aggiungiCodiceFiscaleAlCarrello",
                  send("RIGA_AGGIUNTA"),
                  send('CLEAR_PARTIAL')
                ]
              },
              {
                cond: (context, event) => event.data.tipoBarcode === 'operatore',
                target: '..operand1',
                actions: send((context, event) => ({ value: { code: event.data.code, type: 'accesso' }, type: 'SELEZIONA_OPERATORE' }))
               //  actions: send((_, event) => ({ type: 'SELEZIONA_OPERATORE', data: event.data.code }, { to: 'loginOperatore' }))
/*                 actions: [
                  (context, event) => {
                    debugger
                    const operatore = getOperatoreDaBarcode(event.data.code)
                    const action = { value: { item: operatore, type: 'accesso' }, type: 'SELEZIONA_OPERATORE' }
                    send(action)
                  }
                ] */
              }
            ],
            RESET_DISPLAY: {
              actions: "clearParziale"
            }
          }
        },
        loginOperatore: {
          // https://github.com/statelyai/xstate/issues/434
          invoke: {
            id: 'loginOperatore',
            src: () => (cb, onReceive) => {
              onReceive(event => {
                if (event.type === 'SELEZIONA_OPERATORE') {
                  const operatore = getOperatoreDaBarcode(event.data.code)
                  const action = { value: { item: operatore, type: 'accesso' }, type: 'SELEZIONA_OPERATORE' }
                  cb(action)
                }
              })
            }
          }
        },
        generic_value: {
          on: {
            "*": {
              cond: (context) => context.stampaEffettuata,
              actions: (context) => console.log("bloccato generic_value")
            },
            RESET_DISPLAY: {
              actions: "clearParziale"
            },
            NUMBER: {
              target: "generic_value",
              actions: ["appendNum"]
            },
            DECIMAL_POINT: {
              target: "generic_value",
              /* actions: ["numBy100"] */
              actions: ["appendDecimalPoint"]
            },
            SCONTO_PERCENTUALE: [
              {
                cond:  (context) => !context.isSubTotaleCalcolato && context.rigaSelezionata != null && (context.righeCarrello[context.rigaSelezionata].quantita < 0),
                actions: [
                  assign({
                    displayMessage: () => "Sconto non applicabile !",
                    displayValue: () => "0"
                  }),
                  send({ type: 'RESET_MSG' }, { delay: 3000 })
                ]
              },
              {
                cond: (context) => context.resoInCorso,
                actions: assign({
                  displayValue: () => '0',
                  messaggioToast: () => 'Selezione prodotti reso in corso'
                })
              },
              {
                // cond: (context) => context.isSubTotaleCalcolato && !context.righeCarrello.some(x => x.tipo === 'scontoSub' || x.tipo === 'abbuonoSub'),
                cond: (context) => context.isSubTotaleCalcolato && !context.righeCarrello.some(x => x.tipo === 'scontoSub'),
                target: "zero",
                actions: [
                  'aggiungiScontoAlTotaleCarrello',
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  send('RIGA_AGGIUNTA') // va sul totale, non sul prodotto
                ]
              },
              {
                // cond:  (context) => context.isSubTotaleCalcolato && context.righeCarrello.some(x => x.tipo === 'scontoSub' || x.tipo === 'abbuonoSub'),
                cond:  (context) => context.isSubTotaleCalcolato && context.righeCarrello.some(x => x.tipo === 'scontoSub'),
                target: "zero",
                actions: [
                  assign({
                    displayMessage: () => "E' stato già effettuato uno sconto sul totale !",
                    displayValue: () => "0" // va fatto subito
                  }),
                  send({ type: 'RESET_DISPLAY' }, { delay: 3000 })
                ]
              },
              {
                cond:  (context) => ['cortesia', 'lotteria', 'codicefiscale'].includes(context.rigaCarrello.tipo),
                target: "zero",
                actions: assign({
                  displayMessage: () => "Sconto non applicabile !",
                  displayValue: () => "0"
                })
              },
              {
                // sconto effettuato : va gestito sconto e abbuono appartentente al prodotto --> posso modificare un prodotto aggiungendo lo sconto
                // per adesso evito che si possa mettere sconto e abbuono sullo stesso prodotto
                cond:  (context) => (context.rigaCarrello.sconto || context.rigaCarrello.abbuono), // "scontoEffettuato",
                target: "zero",
                actions: assign({
                  displayMessage: () => "E' stato già effettuato uno sconto !",
                  displayValue: () => "0"
                })
              },
              {
                cond: (context) => !context.isSubTotaleCalcolato, // "isNotSubTotale",
                target: "zero", // "zero",
                actions: [
                  'aggiungiScontoAlCarrello',
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  send('SCONTO_AGGIUNTO')
                ]
              }
            ],
            ABBUONO: [
              {
                cond:  (context) => !context.isSubTotaleCalcolato && context.rigaSelezionata != null && context.righeCarrello[context.rigaSelezionata].quantita < 0,
                actions: [
                  assign({
                    displayMessage: () => "Sconto non applicabile !",
                    displayValue: () => "0"
                  }),
                  send({ type: 'RESET_MSG' }, { delay: 3000 })
                ]
              },
              {
                cond: (context) => context.resoInCorso,
                actions: assign({
                  displayValue: () => '0',
                  messaggioToast: () => 'Selezione prodotti reso in corso'
                })
              },
              {
                cond: (context) => {
                  const operatore = context.operatore
                  const massimoScontoLibero = operatore && operatore.servizio && operatore.servizio.massimoScontoLibero
                  if (massimoScontoLibero) {
                    if (context.isSubTotaleCalcolato) {
                      if (parseFloat(context.displayValue) / 100 > massimoScontoLibero) {
                        context.displayValue = '0'
                        context.messaggioToast = `Valore sconto troppo elevato. Limite: ${massimoScontoLibero}€`
                        return true
                      } else {
                        return false
                      }
                    } else {
                      context.displayValue = '0'
                      context.messaggioToast = 'Operatore non abilitato allo sconto'
                      return true
                    }
                  } else {
                    return false
                  }
                }
              },
              {
                // cond: (context) => context.isSubTotaleCalcolato && !context.righeCarrello.some(x => x.tipo === 'abbuonoSub' || x.tipo === 'scontoSub'),
                cond: (context) => context.isSubTotaleCalcolato && !context.righeCarrello.some(x => x.tipo === 'abbuonoSub'),
                target: "zero",
                actions: [
                  // 'aggiungiAbbuonoAlTotaleCarrello',
                  (context) => aggiungiAbbuonoAlTotaleCarrello(context),
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  // send((context, event) => ({ riga: context.rigaCarrello, type: 'STAMPA_RIGA' }))
                  send('RIGA_AGGIUNTA') // va sul totale, non sul prodotto
                ]
              },
              {
                cond: "abbuonoMaggioreTotale",
                target: "zero",
                actions: assign({ displayMessage: () => "Il valore dello sconto è maggiore del totale carrello !" })
              },
              {
                // cond:  (context) => context.isSubTotaleCalcolato && context.righeCarrello.some(x => x.tipo === 'scontoSub' || x.tipo === 'abbuonoSub'),
                cond:  (context) => context.isSubTotaleCalcolato && context.righeCarrello.some(x => x.tipo === 'abbuonoSub'),
                target: "zero",
                actions: [
                  assign({
                    displayMessage: () => "E' stato già effettuato uno sconto sul totale !",
                    displayValue: () => "0" // va fatto subito
                  }),
                  send({ type: 'RESET_DISPLAY' }, { delay: 3000 })
                ]
              },
              {
                cond:  (context) => ['cortesia', 'lotteria', 'codicefiscale'].includes(context.rigaCarrello.tipo),
                target: "zero",
                actions: assign({
                  displayMessage: () => "Sconto non applicabile !",
                  displayValue: () => "0"
                })
              },
              {
                // sconto effettuato : va gestito sconto e abbuono appartentente al prodotto --> posso modificare un prodotto aggiungendo lo sconto
                // per adesso evito che si possa mettere sconto e abbuono sullo stesso prodotto
                cond:  (context) => (context.rigaCarrello.abbuono || context.rigaCarrello.sconto),
                target: "zero",
                actions: assign({ displayMessage: () => "E' stato già effettuato uno sconto !" })
              },
              {
                // cond:  (context) => !context.isSubTotaleCalcolato && parseFloat(context.displayValue) / 100 > parseFloat(context.righeCarrello[context.rigaSelezionata].prezzo),
                cond:  (context) => !context.isSubTotaleCalcolato && parseFloat(context.displayValue) / 100 > parseFloat(context.rigaCarrello.prezzo),
                target: "zero",
                actions: assign({
                  displayMessage: () => "Sconto maggiore dell'importo del prodotto !",
                  displayValue: () => "0"
                })
              },
              {
                cond: (context) => !context.isSubTotaleCalcolato,
                target: "zero",
                actions: [
                  'aggiungiAbbuonoAlCarrello',
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  send('ABBUONO_AGGIUNTO')
                ]
              }
            ],
            SCONTRINO_REGALO: [
              {
                cond: (context) => context.resoInCorso,
                actions: assign({
                  displayValue: () => '0',
                  messaggioToast: () => 'Selezione prodotti reso in corso'
                })
              },
              {
                cond: (context) => context.quantita <= 5,
                actions: send('SCONTRINO_REGALO_CONFIRM')
              },
              {
                actions: (context, event) => {
                  context.confirmAction = { type: 'SCONTRINO_REGALO_CONFIRM' }
                  context.confirmToast = `Confermi la stampa di ${context.quantita} scontrini regalo ?`
                  context.confirmToastObj = { event: 'FUNZIONE_RAPIDA', title: 'Scontrini regalo', cancelText: 'NO' , confirmText: 'SI' }
                }
              }
            ],
            SCONTRINO_REGALO_CONFIRM: {
              target: "zero",
              actions: [
                'aggiungiCortesiaAlCarrello',
                send('CALCOLA_TOTALE_SCONTRINO'),
                send('CLEAR_PARTIAL'),
                send('RIGA_AGGIUNTA')
              ]
            }
          }
        },
        riga_selezionata: {
          exit: [
            assign({
              scontrinoTick: (context) => context.scontrinoTick + 1
            })
          ],
          on: {
            SELECT_RIGA_SCONTRINO: [
              {
                cond: (context) => (context.displayValue !== '' && context.displayValue !== '0') && context.resoInCorso,
                target: "riga_selezionata",
                actions: [
                  assign({
                    // rigaSelezionata: (context, event) => event.value.rigaId,
                    rigaSelezionata: (context, event) => getIdRigaDaCodice(context, event.value.rigaCodice),
                    rendiItemVisible: (context) => context.resoInCorso,
                    scontrinoTick: (context) => context.scontrinoTick + 1
                  }),
                  send('RENDI_ITEM')
                ]
              },
              {
                cond: (context) => (context.displayValue === '') || !context.resoInCorso,
                target: "riga_selezionata",
                actions: [
                  assign({
                    // rigaSelezionata: (context, event) => event.value.rigaId,
                    rigaSelezionata: (context, event) => getIdRigaDaCodice(context, event.value.rigaCodice),
                    rendiItemVisible: (context) => context.resoInCorso,
                    scontrinoTick: (context) => context.scontrinoTick + 1
                  })
                ]
              }
            ],
            RELEASE_RIGA_SCONTRINO: {
              target: "zero",
              actions: [assign({
                rigaSelezionata: (context, event) => null,
                rendiItemVisible: (context) => false,
                scontrinoTick: (context) => context.scontrinoTick + 1
              })]
            },
            NUMBER: {
              actions: ["appendNum"]
            },
            CLEAR: [
              {
                cond: (context) => (context.resoInCorso || (context.displayValue !== '' && context.displayValue !== '0')) && !(context.rigaSelezionata >= 1000),
                actions: ["removeReadoutNum"]
              },
              { // Nel caso di abilitazione multipli e qta > 1
                cond: (context) => {
                  return context.rigaSelezionata != null &&
                    context.impostazioni.cassa.inserisciProdottiMultipli &&
                    !context.resoInCorso &&
                    context.rigaSelezionata < 1000 &&
                    (context.righeCarrello[context.rigaSelezionata].quantita || 0) > 1

                },
                actions: [
                  (context) => {
                    context.righeCarrello[context.rigaSelezionata].quantita--
                    const prz = context.righeCarrello[context.rigaSelezionata].prezzoUnitario * context.righeCarrello[context.rigaSelezionata].quantita
                    context.righeCarrello[context.rigaSelezionata].prezzo = +prz.toFixed(2)
                  }
                ]
              },
              {
                cond: (context) => {
                  return context.rigaSelezionata != null &&
                    !context.resoInCorso && (
                      context.rigaSelezionata >= 1000 ||
                      context.righeCarrello[context.rigaSelezionata].quantita > 0 ||
                      context.righeCarrello[context.rigaSelezionata].tipo === 'subtotale' ||
                      context.righeCarrello[context.rigaSelezionata].tipo === 'scontoSub' ||
                      context.righeCarrello[context.rigaSelezionata].tipo === 'abbuonoSub'
                    )
                },
                actions: [
                  "cancellaRiga",
                  send('RELEASE_RIGA_SCONTRINO'),
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  (context) => {
                    if (context.righeCarrello.length > 0) {
                      context.rigaCarrello = context.righeCarrello[context.righeCarrello.length - 1]
                    }
                  }
                ]
              },
              {
                cond: (context) => context.rigaSelezionata != null && !context.resoInCorso && context.righeCarrello[context.rigaSelezionata].quantita < 0,
                actions: [
                  "cancellaRiga", // non deve cancellare su scontrino
                  send('RELEASE_RIGA_SCONTRINO'),
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL')
                ]
              }
            ],
            CLEAR_ENTRY: [
              { // Nel caso di inserimento multiplo, la pressione lunga cancella tutta la riga
                cond: (context) => {
                  return context.rigaSelezionata != null &&
                    context.impostazioni.cassa.inserisciProdottiMultipli &&
                    !context.resoInCorso && (
                      context.rigaSelezionata >= 1000 ||
                      context.righeCarrello[context.rigaSelezionata].quantita > 1 ||
                      context.righeCarrello[context.rigaSelezionata].tipo === 'subtotale' ||
                      context.righeCarrello[context.rigaSelezionata].tipo === 'scontoSub' ||
                      context.righeCarrello[context.rigaSelezionata].tipo === 'abbuonoSub'
                    )
                },
                actions: [
                  "cancellaRiga",
                  send('RELEASE_RIGA_SCONTRINO'),
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  (context) => {
                    if (context.righeCarrello.length > 0) {
                      context.rigaCarrello = context.righeCarrello[context.righeCarrello.length - 1]
                    }
                  }
                ]
              },
              // TODO: Verificare la seguente condizione, non capisco l'utilità + va in errore se riga di reso
/*               { // Verificare il comportamento se scontrino preesistente e parcheggiato
                cond: (context) => context.resoInCorso,
                target: "zero",
                actions: ["clearParziale"]
              } */
            ],
            RESET_MSG: {
              actions: assign({ displayMessage: () => "" })
            },
            REPARTO_SCONTO: {
              cond: (context) => !context.stampaEffettuata,
              actions: [
                assign({ displayValue: (context,event) => event.value.value }),
                send('SCONTO_PERCENTUALE')
              ]
            },
            REPARTO_ABBUONO: {
              cond: (context) => !context.stampaEffettuata,
              actions: [
                assign({ displayValue: (context,event) => event.value.value }),
                send('ABBUONO')
              ]
            },
            SCONTO_PERCENTUALE: [
              {
                cond:  (context) => context.righeCarrello[context.rigaSelezionata].quantita < 0 || context.resoInCorso,
                actions: [
                  assign({
                    displayMessage: () => "Sconto non applicabile !",
                    displayValue: () => "0"
                  }),
                  send({ type: 'RESET_MSG' }, { delay: 3000 })
                ]
              },
              {
                cond:  (context) => context.righeCarrello[context.rigaSelezionata].sconto || context.righeCarrello[context.rigaSelezionata].abbuono,
                target: "zero",
                actions: assign({ displayMessage: () => "E' stato già effettuato uno sconto !" })
              },
              {
                cond:  (context) => ['cortesia', 'lotteria', 'codicefiscale'].includes(context.righeCarrello[context.rigaSelezionata].tipo),
                target: "zero",
                actions: assign({
                  displayMessage: () => "Sconto non applicabile !",
                  displayValue: () => "0"
                })
              },
              {
                cond: (context) => !context.isSubTotaleCalcolato, // "isNotSubTotale",
                target: "zero", // "zero",
                actions: [
                  'aggiungiScontoAlCarrello',
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  send('SCONTO_MODIFICATO')
                ]
              },
              {
                cond: (context) => context.isSubTotaleCalcolato && !context.righeCarrello.some(x => x.tipo === 'scontoSub'),
                target: "zero",
                actions: [
                  'aggiungiScontoAlTotaleCarrello',
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  send('RIGA_AGGIUNTA')
                ]
              },
              {
                cond:  (context) => context.isSubTotaleCalcolato && context.righeCarrello.some(x => x.tipo === 'scontoSub'),
                target: "zero",
                actions: [
                  assign({
                    displayMessage: () => "E' stato già effettuato uno sconto sul totale !",
                    displayValue: () => "0" // va fatto subito
                  }),
                  send({ type: 'RESET_DISPLAY' }, { delay: 3000 })
                ]
              }
            ],
            ABBUONO: [
              {
                cond:  (context) => context.righeCarrello[context.rigaSelezionata].quantita < 0 || context.resoInCorso,
                actions: [
                  assign({
                    displayMessage: () => "Sconto non applicabile !",
                    displayValue: () => "0"
                  }),
                  send({ type: 'RESET_MSG' }, { delay: 3000 })
                ]
              },
              {
                cond: "abbuonoMaggioreTotale",
                target: "zero",
                actions: assign({ displayMessage: () => "Il valore dello sconto è maggiore del totale carrello !" })
              },
              {
                cond:  (context) => context.righeCarrello[context.rigaSelezionata].abbuono || context.righeCarrello[context.rigaSelezionata].sconto,
                target: "zero",
                actions: assign({ displayMessage: () => "E' stato già effettuato uno sconto !" })
              },
              {
                cond:  (context) => ['cortesia', 'lotteria', 'codicefiscale'].includes(context.righeCarrello[context.rigaSelezionata].tipo),
                target: "zero",
                actions: assign({
                  displayMessage: () => "Sconto non applicabile !",
                  displayValue: () => "0"
                })
              },
              {
                cond:  (context) => !context.isSubTotaleCalcolato && parseFloat(context.displayValue) / 100 > parseFloat(context.righeCarrello[context.rigaSelezionata].prezzo),
                target: "zero",
                actions: assign({
                  displayMessage: () => "Sconto maggiore dell'importo del prodotto !",
                  displayValue: () => "0"
                })
              },
              {
                cond: (context) => !context.isSubTotaleCalcolato,
                target: "zero",
                actions: [
                  'aggiungiAbbuonoAlCarrello',
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  send('ABBUONO_MODIFICATO')
                ]
              },
              {
                cond: (context) => context.isSubTotaleCalcolato && !context.righeCarrello.some(x => x.tipo === 'abbuonoSub'),
                target: "zero",
                actions: [
                  // 'aggiungiAbbuonoAlTotaleCarrello',
                  (context) => aggiungiAbbuonoAlTotaleCarrello(context),
                  send('CALCOLA_TOTALE_SCONTRINO'),
                  send('CLEAR_PARTIAL'),
                  send('RIGA_AGGIUNTA') // va sul totale, non sul prodotto
                ]
              },
              {
                cond:  (context) => context.isSubTotaleCalcolato && context.righeCarrello.some(x => x.tipo === 'abbuonoSub'),
                target: "zero",
                actions: [
                  assign({
                    displayMessage: () => "E' stato già effettuato uno sconto sul totale !",
                    displayValue: () => "0" // va fatto subito
                  }),
                  send({ type: 'RESET_DISPLAY' }, { delay: 3000 })
                ]
              },
            ],
            RENDI_ITEM: [
              // prende quantità da rendere. tutto = default, altrimenti imposto quantità (verificare <= tutto)
              // aggiunge numero a fianco di acquisto per qta reso
              // la riga diventa rossa se qta reso > 0
              {
                cond: (context, event) => parseInt(context.displayValue || '0') > parseInt(context.righeCarrello[context.rigaSelezionata].quantita),
                actions: [
                  assign({ displayMessage: () => 'Quantità non consentita'}),
                  send({ type: 'RESET_DISPLAY' }, { delay: 2000 }),
                  send('RELEASE_RIGA_SCONTRINO')
                ]
              },
              {
                cond: (context, event) => parseInt(context.displayValue || '0') <= parseInt(context.righeCarrello[context.rigaSelezionata].quantita),
                actions: [
                  "rendiItem",
                  assign({ displayMessage: () => '' }),
                  send('RELEASE_RIGA_SCONTRINO')
                ]
              }
            ],
            SELEZIONA_OPERATORE: {
              actions: [
                (context, event) => {
                  context.popups.find(el => el.type === 'CercaOperatore').show = false
                  // context.righeCarrello[context.rigaSelezionata].operatore = context.operatore.servizio ? context.operatore.servizio.codice || '' : ''
                  context.righeCarrello[context.rigaSelezionata].operatore = fnGetOperatore(context)
                  var op = context.operatore || {}
                  op[event.value.tipo] = event.value.item
                  context.operatore = {...op}
                  if (event.value.tipo === 'accesso' && context.impostazioni.cassa.sbloccaSuUtente) {
                    context.bloccaCassa = false
                    context.displayValue = '0'
                    if (event.value.item.servizio) {
                      op.servizio = {...op}
                    }
                  }
                },
                send('RELEASE_RIGA_SCONTRINO'),
                assign({ richiediPin: (context, event) => false })
              ]
            }
          }
        },
        before_decimal_point: {
          on: {
            NUMBER: {
              target: "before_decimal_point",
              actions: ["appendReadoutNum"]
            },
            DECIMAL_POINT: {
              target: "after_decimal_point",
              actions: ["appendDecimalPoint"]
            },
            CLEAR: {
              target: "test_decimal_point",
              actions: ["removeReadoutNum"]
            }
          }
        },
        after_decimal_point: {
          on: {
            NUMBER: {
              target: "after_decimal_point",
              actions: ["appendReadoutNum"]
            },
            CLEAR: {
              target: "test_decimal_point",
              actions: ["removeReadoutNum"]
            }
          }
        },
        test_decimal_point: {
          always: [
            { target: 'zero', cond: 'isDisplayZero' },
            { target: 'before_decimal_point', cond: 'isNotDecimalPointPresent' },
            { target: 'after_decimal_point', cond: 'isDecimalPointPresent' }
          ]
        }
      }
    },
    reparto_info: {
      invoke: { // https://xstate.js.org/docs/guides/communication.html#invoking-promises
        id: 'infoReparto',
        // src: (context, event) => getInfoReparto(context, event),
        src: async (context, event) => {
          return await getInfoReparto(context, event)
        },
        onDone: {
          target: "reparto_trovato"
/*           actions: [
            assign({ reparto: (context, event) => event.data }),
          ] */
        },
        onError: {
          actions: assign({
            reparto: (context, event) => null,
            displayMessage: (context, event) => event.data
          })
        }
      }
    },
    prodotto_info: {
      invoke: { // https://xstate.js.org/docs/guides/communication.html#invoking-promises
        id: 'infoProdotto',
        src: async (context, event) => {
          return await getInfoProdotto(context, event)
        },
        onDone: {
          target: "prodotto_trovato",
          // actions:[ assign({ prodotto: (context, event) => event.data })]
        },
        onError: {
          actions: assign({
            prodotto: (context, event) => null,
            displayMessage: (context, event) => event.data
          })
        }
      }
    },
    reparto_trovato: {
      always: [
        {
          cond: (context) => parseFloat(context.reparto.prezzoPredefinito || (parseFloat(context.displayValue)/100).toFixed(2)) === 0,
          target: "operand1.zero",
          actions: assign({ displayMessage: () => "Prezzo zero non ammesso !" })
        },
        {
          cond: (context) => parseFloat(context.reparto.prezzoPredefinito || (parseFloat(context.displayValue)/100).toFixed(2)) !== 0,
          target: "operand1.zero",
          actions: [
            'aggiungiRepartoAlCarrello',
            'infoReparto',
            send('CALCOLA_TOTALE_SCONTRINO'),
            send('CLEAR_PARTIAL'),
            send('RIGA_AGGIUNTA'),
            send('FOCUS')
          ]
        }
      ]
/*       always: {
        target: "operand1.zero",
        actions: [
          'aggiungiRepartoAlCarrello',
          'infoReparto',
          send('CALCOLA_TOTALE_SCONTRINO'),
          send('CLEAR_PARTIAL'),
          send('RIGA_AGGIUNTA')
        ]
      } */
    },
    prodotto_trovato : {
      always: [
        {
          // cond: "isRichiestaPrezzo",
          cond: (context, event) => context.richiestaPrezzo,
          target: "operand1.zero",
          actions: ["infoProdotto"]
        },
        {
          cond: (context, event) => !context.richiestaPrezzo && !context.prodotto,
          target: "operand1.zero",
          actions: [
            assign({ displayMessage: () => 'Prodotto non trovato! '})
          ]
        },
        {
          cond: (context, event) => context.nuovoAppuntamento && context.nuovoAppuntamento.modaleAppuntamento,
          target: "operand1.zero",
          actions: [
            'aggiungiProdottoAlCarrello'
          ]
        },
        {
          // cond: "isNotRichiestaPrezzo",
          cond: (context, event) => !context.richiestaPrezzo && context.prodotto,
          target: "operand1.zero",
          actions: [
            'aggiungiProdottoAlCarrello',
            'infoProdotto',
            send('CALCOLA_TOTALE_SCONTRINO'),
            send('CLEAR_PARTIAL'),
            send('RIGA_AGGIUNTA'),
            send('FOCUS')
            // send((context, event) => ({ riga: context.rigaCarrello, type: 'STAMPA_RIGA' }))
          ]
        }
      ]
    },
    fidelity_info: {
      invoke: {
        id: 'infoFidelity',
        src: (context, event) => infoFidelity(context, event),
        onDone: [
          {
            cond: (context) => context.popups.find(el => el.type === 'Fidelity').show,
            target: "popup_aperto"
          },
          {
            cond: (context) => !context.popups.find(el => el.type === 'Fidelity').show,
            target: "operand1",
            actions:[
              send('CLEAR_PARTIAL')
            ]
          }
        ],
        onError: {
          target: "operand1.zero",
          actions: assign({
            fidelityCard: (context, event) => null,
            displayMessage: (context, event) => event.data
          })
        }
      }
    },
    fidelity_associa: {
      invoke: {
        id: 'associaFidelity',
        src: async (context, event) => {
          let card = await fidelity.associaFidelity(context.fidelityCard.codice, context.cliente.codice, context.cliente.ragioneSociale)
          card.descrizione = `${card.codice}`
          context.fidelityCard = card
          context.popups.find(el => el.type === 'Fidelity').show = false
        },
        onDone: {
          target: "operand1",
          actions: assign(
            { fidelityCardDaAssociare: (context, event) => false },
            { fidelityCardAssociata: (context, event) => true }
          )
		    },
		    onError: {
          target: "operand1",
          actions: assign({ displayMessage: (context, event) => event.data.displayMessage })
        }
      }
    },
    fidelity_nuova: {
      invoke: {
        id: 'nuovaFidelity',
        src: async (context, event) => {
          // E' stato letto un barcode corrispondente ad una fidelity che non è presente nel database --> crea la fidelity come non associata
          const idCassa = context.impostazioni.stampante.progressivo_stampante
          const codice = context.fidelityCardDaCreare
          context.fidelityCardDaCreare = ''
          // tipo fidelity di default da impostazioni
          const impCard = context.impostazioni.fidelityCard
          const payload = _.get(impCard, 'defaultNonPresente', {})
          await fidelity.creaFidelityCardEsterna(codice, payload)
          context.messaggioToast = "Fidelity card inserita. Leggere di nuovo per caricarla!"
        },
        onDone: {
          target: "operand1",
          actions: assign(
            { fidelityCardDaAssociare: (context, event) => false },
            { fidelityCardAssociata: (context, event) => true }
          )
		    },
		    onError: {
          target: "operand1",
          actions: assign({ displayMessage: (context, event) => event.data.displayMessage })
        }
      }
    },
    fidelity_aggiorna: {
      invoke: {
        id: 'aggiornaFidelity',
        src: async (context, event) => {
          if (!context.fidelityCard) {
            // la card non esiste --> devo crearla
            const idCassa = context.impostazioni.stampante.progressivo_stampante
            context.fidelityCard = await fidelity.creaFidelityCard(idCassa)
            context.fidelityCard.descrizione = `${context.fidelityCard.codice}`
            context.fidelityCardDaAssociare = true
            context.fidelityCardAssociata = false
            if(context.cliente) {
              context.fidelityCard.cliente = { codice: context.cliente.codice, ragioneSociale: context.cliente.ragioneSociale }
              context.fidelityCard.associato = true
              context.fidelityCardAssociata = true
              context.fidelityCardDaAssociare = false
            }
          }
          let card = { ...context.fidelityCard }
          _.merge(context.fidelityCard, event.value.data)
          if (event.value.azzeramento) {
            context.fidelityCard.raccoltaPunti.azzeramenti = context.fidelityCard.raccoltaPunti.azzeramenti || []
            context.fidelityCard.raccoltaPunti.azzeramenti.push(event.value.azzeramento)
          }
          delete card.punti
          delete card.puntiPrecedenti
          delete card.limiteRaggiunto
          delete card.offerteAttive
          _.merge(card, event.value.data)
          await fidelity.aggiornaFidelity(card.codice, card)
          context.displayValue = '0'
          if (event.value.comandi) {
            if (event.value.comandi.caricaReparto) {
              context.displayValue = event.value.comandi.caricaReparto
              context.fidelityComandi = 'reparto'
              return { value: { code: 'Prepagata', tipoAliquota: 'banco', importo: +(parseFloat(event.value.comandi.caricaReparto)/100).toFixed(2) } }  // leggere da configurazioni
            }
          }
          // Ricalcola i valori visualizzati
          context.fidelityCard.punti = _.get(context.fidelityCard,'raccoltaPunti.totale', '')
          _.set(context.cliente, 'calcolati.sconto', card.generale.sconto || _.get(context.cliente, 'calcolati.sconto', ''))
          context.listino = card.generale.listino || context.listino
          context.scontrinoTick++
        },
        onDone: [
          {
            cond: (context, event) => context.fidelityComandi === 'reparto',
            target: "operand1",
            actions: [
              assign({ fidelityCard: (context) => context.fidelityCard}),
              assign({ messaggioToast: () => "Le modifiche sono state correttamente salvate !"}),
              send((context, event) => { return { value: event.data.value, type: 'REPARTO' }})
            ]
          },
          {
            cond: (context) => !context.fidelityComandi,
            target: "operand1",
            actions: [
              assign({ fidelityCard: (context) => context.fidelityCard}),
              assign({ messaggioToast: () => "Le modifiche sono state correttamente salvate !"})
            ]
          }
        ],
        onError: {
          actions: assign({ messaggioToast: () => "Si è verificato un errore nell'aggiornamento della card "})
        }
      }
    },
    buono_info: {  // TODO: da gestire
      invoke: {
        id: 'infoBuono',
        // src: (context, event) => fidelity.getFidelity(event.value.code),
        src: async (context, event) => {
          return await fidelity.getFidelity(event.value.code)
        },
        onDone: {
          // target: "operand1.zero",
          target: "verifica_acquisto_con_buono",
          actions:[
            (context, event) => {
              if (event.data) {
                // Gestire Multiuso e monouso
                if (event.data.annullamento) { // considerare status === 'annullato' ??
                  context.buonoMultiuso = null
                  context.buonoMonouso = null
                  context.messaggioToast = "Buono non valido o annullato!"
                  context.buonoCaricato = false
                } else if (event.data.buono.dataScadenza && moment().isAfter(event.data.buono.dataScadenza)) {
                  context.buonoMultiuso = null
                  context.buonoMonouso = null
                  // context.messaggioToast = `Il buono è scaduto in data: ${moment(event.data.buono.dataScadenza).format("DD/MM/YYYY")}`
                  context.buonoCaricato = false
                  context.confirmToast = `Il buono è scaduto in data: ${moment(event.data.buono.dataScadenza).format("DD/MM/YYYY")}`
                  context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: 'beeps' }
                } else {
                  if (event.data.tipo === 'multiuso') {
                    context.buonoMultiuso = event.data
                    context.buonoMonouso = null
                    context.buonoMultiuso.descrizione = `${event.data.secureCode}: ${event.data.buono.importoResiduo.toFixed(2)} €`
                    context.descrizioneBuono = `${event.data.secureCode}: ${event.data.buono.importoResiduo.toFixed(2)} €`
                    context.buonoCaricato = true
                    context.confermaChiusuraConBuono = true

                    context.buoniMultiuso.push(context.buonoMultiuso)
                  } else if (event.data.tipo === 'monouso') {
                    context.buonoMonouso = event.data
                    context.buonoMultiuso = null
                    context.buonoMonouso.descrizione = `${event.data.secureCode}: ${event.data.buono.importoResiduo.toFixed(2)} €`
                    context.descrizioneBuono = `${event.data.secureCode}: ${event.data.buono.importoResiduo.toFixed(2)} €`
                    context.buonoCaricato = true
                    context.confermaChiusuraConBuono = true
                  }
                }
              } else {
                context.buonoMultiuso = null
                context.buonoMonouso = null
                context.descrizioneBuono = ''
                context.buonoCaricato = false
              }
            },
            // send('CLEAR_PARTIAL')
            send('VERIFICA')
          ]
        },
        onError: {
          target: "operand1.zero",
          actions: assign({
            buonoMultiuso: (context, event) => null,
            buonoMonouso: (context, event) => null,
            descrizioneBuono: (context, event) => '',
            buonoCaricato: () => false,
            displayMessage: (context, event) => 'Buono non trovato o non valido'
          })
        }
      }
    },
    verifica_acquisto_con_buono: {
      on: {
        VERIFICA: [
          {
            cond: (context) => context.buonoCaricato && (parseFloat(context.totaleScontrino) > 0 || parseFloat(context.totaleScontrino) < 0),
            actions: [
              assign({ displayValue: () => '0'}),
              send('BUONO')
            ]
          },
          {
            cond: (context) => !context.buonoCaricato || parseFloat(context.totaleScontrino) === 0 || !context.totaleScontrino,
            actions: [
              send('CLEAR_PARTIAL')
            ]
          }
        ]
      }
    },
    annulla_buono: {
      invoke: {
        id: 'annullaBuono',
        // src: (context, event) => fidelity.deleteFidelity(event.value.code),
        src: async (context, event) => {
          return await fidelity.deleteFidelity(event.value.code)
        },
        onDone: {
          actions:[
            assign({buonoMultiuso: (context) => null}), // TODO: Gestire Monouso
            send('CLEAR_PARTIAL')
          ]
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore cancellazione'
          })
        }
      }
    },
/*     gestioneFidelity: {
      on: {
        TEST_ASSOCIATA: [
          {
            cond: (context, event) => { context.fidelityCard.associato },
            target: "operand1" // verificare
            // carica cliente
          },
          {
            cond: (context, event) => { !context.fidelityCard.associato },
            target: "operand1", // verificare
            actions: [
              // send({ value:'AssociaFidelity', type: 'APRI_POPUP' })
            ]
            //avvia la procedura di associazione
          }
        ]
      }
    }, */
    cliente_fidelity_info: {
      invoke: {
        id: 'infoClienteFidelity',
        src: async (context, event) => {
          const cliente = await clienti.getCliente(event.value._id)
          context.cliente = {}
          context.cliente = cliente
          const card = await fidelity.cercaFidelityByCliente('fidelitycard', context.cliente.codice)
          if (card.length > 0) {
            // prendo la prima !!
            const cardSel = card[0]
            const ev = {
              value: {
                code: cardSel._id
              }
            }
            await infoFidelity(context, ev)
          }
        },
        onDone:[
          {
            target: "operand1.zero",
            actions:[
              send('CLEAR_PARTIAL'),
              send({ type: 'PRODOTTI_PAGINA', value: { page:"1000", zona: "tasti2", toggle: 1 }})
            ]
          }
        ],
        onError: {
          target: "operand1.zero",
          actions: assign({
            cliente: (context, event) => null,
            displayMessage: (context, event) => event.data
          })
        }
      }
    },
    cliente_info: {
      invoke: {
        id: 'infoCliente',
        src: async (context, event) => {
          return await clienti.getCliente(event.value.code)
        },
        onDone:[
          {
            cond: (context) => !context.pagaAppuntamentoInCorso,
            target: "operand1.zero",
            actions:[
              assign({
                cliente: (context, event) => event.data
               }),
              (context) => context.popups.find(el => el.type === 'CercaCliente').show = false,
              send('CLEAR_PARTIAL')
            ]
          },
          {
            cond: (context) => context.pagaAppuntamentoInCorso,
            target: "modale_aperta",
            actions:[
              assign({
                cliente: (context, event) => event.data,
                pagaAppuntamentoInCorso: (context, event) => context.pagaAppuntamentoInCorso + 1
              }),
              (context) => context.popups.find(el => el.type === 'CercaCliente').show = false
            ]
          }
        ],
        onError: {
          target: "operand1.zero",
          actions: assign({
            cliente: (context, event) => null,
            displayMessage: (context, event) => event.data
          })
        }
      }
    },
    scontrino_info: {
      invoke: {
        id: 'infoScontrino',
        src: async (context, event) => {
          return await scontrini.getScontrino(context.displayValue)
        },
        onDone: [
           {
            cond: (_, event) => !event.data.fiscale,
            target: "operand1.zero",
            actions:[
              assign({
                displayValue: () => '',
                displayMessage: 'Scontrino non trovato'
              })
            ]
          },
          {
            cond: (context) => !context.attesaCodiceScontrino, // comportamento di default (è un reso), eventualmente modificare con è un recupero --> Tasto attesa reso
            target: "operand1.zero",
            actions:[
              "caricaScontrino",
              assign({ displayValue: () => '' })
            ]
          },
          {
            cond: (context) => context.attesaCodiceScontrino,
            target: "attesaInputSuRecupero",
            actions:[
              "recuperaScontrino",
              assign({
                displayValue: () => ''
                // attesaCodiceScontrino: () => false
              })
            ]
          }
        ],
        onError: {
          actions: assign({
            displayMessage: (context, event) => event.data
          })
        }
      }
    },
    result: {
      on: {
        NUMBER: [
          {
            cond: "isZero",
            target: "operand1",
            actions: ["defaultReadout"]
          },
          {
            cond: "isNotZero",
            target: "operand1.before_decimal_point",
            actions: ["setReadoutNum"]
          }
        ]
      }
    },
    verificaImporto: {
    },
    chiusuraPossibile: {
      entry: [
        assign({ scontrinoEspanso: () => true })
      ],
      on: {
/*         NOTA: {
          actions: [
            "aggiungiNotaAlCarrello",
          ]
        } */
      }
    },
    attesaInputDopoChiudi: {
      entry: sendResetTimerAfterDelay, // Scatena un evento qualsiasi (c'è *) a tempo
      exit: [
        cancelResetTimer,
        assign({ memoriaTotale: (context) => context.totaleScontrino }),
        sendResetMemoriaTotale
      ],
      on: {
        SCONTRINO_REGALO: [
          {
            cond: (context) => context.barcodeUltimoScontrino,
            target: "gestioneScontrino",
            actions: [
              assign({ comandoScontrini: () => 'SCONTRINO_REGALO' })
            ]
          }
        ],
        "*" : [
          {
            cond: (context, event) => event.type !== 'SCONTRINO_REGALO',
            target: "operand1",
            actions: [
              assign({ displayFocus: (context) => context.displayFocus + 1 }),
              assign({ scontrinoEspanso: () => false }),
              "reset",
              (context) => {
                context.attributes = {}
                context.importoPagato = {}
                context.inviaBenvenutoAMF = true
              },
              send((context, event) => event)
            ]
          }
        ]
      }
    },
    attesaInputSuRecupero: {
      on: {
        SCONTRINO_REGALO: [
          {
            cond: (context) => context.barcodeUltimoScontrino,
            target: "gestioneScontrino",
            actions: assign({ comandoScontrini: () => 'SCONTRINO_REGALO_SU_RECUPERO' })
          }
        ],
        ANNULLA_SCONTRINO: {
          target: 'operand1',
          actions: [
            "reset",
            (context) => {
              context.attributes = {}
              context.importoPagato = {}
              context.inviaBenvenutoAMF = true
            }
          ]
        },
        ANNULLAMENTO_FISCALE: {
          actions: (context) => {
            context.confirmToast = 'Confermi annullamento fiscale scontrino ?'
            context.confirmToastObj = { event: 'ANNULLAMENTO_FISCALE_SI', title: 'Annullamento Fiscale', cancelText: 'NO' , confirmText: 'SI' }
          }
        },
        ANNULLAMENTO_FISCALE_SI: {
          target: "gestioneScontrino",
          actions: assign({ comandoScontrini: () => 'ANNULLAMENTO_FISCALE' })
        },
        ANNULLAMENTO_FISCALE_RIUSO: {
          actions: (context) => {
            context.confirmToast = 'Confermi annullamento fiscale scontrino ?'
            context.confirmToastObj = { event: 'ANNULLAMENTO_FISCALE_RIUSO_SI', title: 'Annullamento Fiscale', cancelText: 'NO' , confirmText: 'SI' }
          }
        },
        ANNULLAMENTO_FISCALE_RIUSO_SI: {
          target: "gestioneScontrino",
          actions: [
            assign({
              comandoScontrini: () => 'ANNULLAMENTO_FISCALE_RIUSO'
            })
          ]
        }
      }
    },
/*     stampaScontrino: {
      entry: send('STAMPA_SCONTRINO')
    }, */
    gestioneScontrino: { // probabilemente va aggiunto uno stato per selezionare il tipo documento da produrre
      invoke: {
        id: 'scontriniM',
        src: scontriniMachine,
        data: {
          idScontrino: (context) => context.scontrinoRecupero ? context.scontrinoRecupero._id : context.idScontrino,
          rigaScontrino: (context) => context.rigaStampa,
          infoChiusuraScontrino: (context) => context.infoChiusuraScontrino, // aggiungere solo qui : tipo documento, info cliente, etc.
          righeScontrino: (context) => context.righeCarrello,
          rigaSelezionata: (context) => context.rigaSelezionata,
          comandoScontrini: (context) => context.comandoScontrini,
          barcodeScontrino: (context) => context.barcodeUltimoScontrino,
          fiscaleScontrino: (context) => context.scontrinoRecupero ? context.scontrinoRecupero.fiscale : null,
          appuntamento: (context) => context.appuntamento,
          idEliminare: (context) => context.idEliminare,
          infoRistampaBuono: (context) => context.infoRistampaBuono,
          scontrinoErroreComando: (context) => context.scontrinoErroreComando,
          cashmaticLastTransaction: (context) => context.cashmaticLastTransaction
        },
        onDone: [
          {
            cond: (context) => context.comandoScontrini === 'ADD_RIGA' && context.pagaAppuntamentoInCorso === 0,
            target: 'operand1',
            actions: assign({ idScontrino: (context,event) => event.data.idScontrino })
          },
          {
            cond: (context) => context.comandoScontrini === 'ADD_RIGA' && context.pagaAppuntamentoInCorso > 0,
            // target: 'modale_aperta',
            target: 'popup_aperto',
            actions: assign({
              idScontrino: (context,event) => event.data.idScontrino,
              pagaAppuntamentoInCorso: (context) => context.pagaAppuntamentoInCorso + 1
            })
          },
          {
            cond: (context) => context.comandoScontrini === 'ANNULLA_SCONTRINO',
            target: 'operand1',
            actions: [
              "reset",
              assign({ idScontrino: () => '' }),
              (context) => {
                context.attributes = {}
                context.importoPagato = {}
              }
            ]
          },
          {
            cond: (context) => context.comandoScontrini === 'ADD_TOTALE',
            // target: 'stampaScontrino'
            actions: send('STAMPA_SCONTRINO')
          },
          {
            cond: (context) => context.comandoScontrini === 'STAMPA_SCONTRINO',
            target: 'verifica_chiusura',
            actions: [
              'ritornoDaStampaScontrino',
              assign({
                displayCodice: (context, event) => event.data.barcode.documento,
                scontrinoSelezionato: (context, event) => event.data.idScontrino,
                barcodeUltimoScontrino: (context, event) => event.data.barcode
              }),
              send('VERIFICA_USO_BUONI')
            ]
          },
          {
            cond: (context) => context.comandoScontrini === 'UPDATE_RIGA',
            target: 'operand1',
            actions: assign({ rigaSelezionata: () => null })
          },
          {
            cond: (context) => context.comandoScontrini === 'DELETE_RIGA',
            target: 'operand1',
            actions: assign({ rigaSelezionata: () => null })
          },
          {
            cond: (context) => context.comandoScontrini === 'SCONTRINO_REGALO' || context.comandoScontrini === 'RISTAMPA_BUONO' || context.comandoScontrini === 'SCONTRINO_SOLO_RESO' || context.comandoScontrini === 'SCONTRINO_GESTIONALE',
            target: 'attesaInputDopoChiudi'
          },
          {
            cond: (context) => context.comandoScontrini === 'SCONTRINO_REGALO_SU_RECUPERO',
            target: 'attesaInputSuRecupero'
          },
/*           {
            cond: (context) => context.comandoScontrini === 'ANNULLAMENTO_FISCALE', //  && !context.targetDoneScontrini,
            // target: 'operand1',  // riprendere eventuali righe parcheggiate (gestione esplicita parcheggio...)
            // actions: "reset" // va fatto il movimento di carico magazzino
            target: "esegui_movimenti_magazzino_annullamento"
          },
          {
            cond: (context) => context.comandoScontrini === 'ANNULLAMENTO_FISCALE_RIUSO', //  && context.targetDoneScontrini, // separare cond: se solo annullo o riuso
            target: 'riusa_prodotti_annullo',  // va fatto il movimento di carico magazzino
          }, */
          {
            cond: (context) => ['ANNULLAMENTO_FISCALE', 'ANNULLAMENTO_FISCALE_RIUSO'].includes(context.comandoScontrini),
            target: "esegui_movimenti_magazzino_annullamento",
            actions: assign({ scontrinoAnnullamento : (context, event) => event.data })
          },
          {
            cond: (context) => context.comandoScontrini === 'ELIMINA_SCONTRINO', // TODO: Messaggio scontrino cancellato
            target: 'attesaInputDopoChiudi',
            actions: assign({ messaggioToast : () => 'Lo scontrino è stato eliminato'})
          }
        ]
      },
      entry: send((context) => context.comandoScontrini, { to: 'scontriniM' }),
      on: {
        // Eventuali eventi dalla macchina degli scontrini
        PRINTER_ERROR : [
          {
            cond: (context, event) => context.impostazioni.stampante.stampa_disabilitata,
            actions: [
              raise((context, event) => ({ event, type: 'PRINTER_SAVE_RECEIPT' }))
            ]
          },
          {
            cond: (context) => !['ANNULLAMENTO_FISCALE', 'ANNULLAMENTO_FISCALE_RIUSO'].includes(context.comandoScontrini),
            actions: [
              (context, event) => {
                console.log('errore stampante PRINTER_ERROR')
                context.scontrinoStampato = event.data.scontrino || {}
                context.scontrinoSelezionato = event.data.idScontrino
                context.stampaEffettuata = true
                context.scontrinoErroreComando = _.get(event.data, 'error.message.command', null)
              },
              assign({
                erroreStampante: (context, event) => {
                  let err = event.data.error
                  err.scontrino = event.data.error.code === 'TIMEOUT_ERROR'
                  return err }
                }),
              (context, event) => logger.logError(`gestioneScontrino [Errore Stampante]: ${JSON.stringify(event.data)}`)
            ]
          },
          {
            cond: (context) => ['ANNULLAMENTO_FISCALE', 'ANNULLAMENTO_FISCALE_RIUSO'].includes(context.comandoScontrini),
            target: 'operand1',
            actions: [
              assign({
                erroreStampante: (context, event) => {
                  let err = {
                    code: 'STATUS_ERROR',
                    message: 'Annullamento non eseguito !',
                    scontrino: false
                  }
                  return err }
                })
            ]
          }
        ],
        PRINTER_RESUME: [
          {
            cond: (context) => context.comandoScontrini === 'STAMPA_SCONTRINO',
            target: 'gestioneScontrino',
            actions: [
              (context, event) => {
                console.log('PRINTER_RESUME 1', event)
                console.log('ultimo comando: ', context.comandoScontrini)
                console.log('scontrinoErroreComando: ', context.scontrinoErroreComando)
                // context.scontrinoErroreComando = _.get(event.data, 'error.message.command', null)
                send('STAMPA_SCONTRINO')
              }
            ]
          },
          {
            cond: (context) => context.comandoScontrini !== 'STAMPA_SCONTRINO',
            actions: [
              (context, event) => {
                console.log('PRINTER_RESUME 1', event)
                console.log('ultimo comando: ', context.comandoScontrini)
                console.log('VERIFICARE COME GESTIRE')
              }
            ]
          }
        ],
        PRINTER_ABORT: [
          {
            cond: (context) => context.comandoScontrini === 'STAMPA_SCONTRINO',
            actions: [
              assign({
              erroreStampante: (context, event) => {
                let err = {
                  code: 'STATUS_ERROR',
                  scontrino: true
                }
                return err }
              })
            ]
          },
          {
            cond: (context) => context.comandoScontrini !== 'STAMPA_SCONTRINO',
            target: "operand1", // TODO: Verificare
            actions: [
              (context, event) => logger.logError('PRINTER_ABORT 1, no STAMPA_SCONTRINO'),
              (context, event) => { console.log('PRINTER_ABORT 1', event)}
            ]
          }
        ],
/*         PRINTER_ABORT: {
          actions: (context, event) => { console.log('PRINTER_ABORT 1', event)}
        }, */
        PRINTER_SAVE_RECEIPT: {
          target : "verifica_chiusura",
          actions : [
            () => { console.log('ritorno da stampa scontrino ...')},
            'ritornoDaStampaScontrino',
            send('VERIFICA_USO_BUONI'),
            assign({
              scontrinoSelezionato: (context, event) => event.data && event.data.idScontrino
            }),
            (context, event) => {
              console.log('PRINTER_SAVE_RECEIPT', event)
            }
          ]
        },
        PRINTER_DELETE_RECEIPT: {
          target: "operand1",
          actions : [
            // assign({ messaggioToast : () => 'Lo scontrino è stato eliminato 1'}),
            assign({ idEliminare : (context, event) => context.idScontrino }),
            assign({ stampaEffettuata : () => false }),
            send("ELIMINA_SCONTRINO")
          ]
        }
      }
    },
    inviaComandiStampante: {
      invoke: {
        id: 'comandiM',
        src: scontriniMachine,
        data: {
          comandi: (context, event) => event.value.commands,
          settings: (context, event) => event.value.settings
        },
        onDone: [
          {
            cond: (context) => { return context.printerInitialStatusRequest && context.impostazioni.stampante.mostra_pannello_iniziale },
            target: 'operand1',
            actions: [
              assign({
                printerResponse: (context, event) => event.data.printerResponse,
                printerInitialStatusRequest: () => false
              }),
              send(() => ({ value: 'Stampante', type: 'APRI_MODALE' }))
            ]
          },
          {
            target: 'operand1',
            actions: [
              assign({ printerResponse: (context, event) => event.data.printerResponse })
            ]
          }
        ]
      },
      entry: send((context) => 'SEND_COMMANDS', { to: 'comandiM' }),
      on: {
        // Eventuali eventi dalla macchina degli scontrini
        PRINTER_ERROR : {
          target: "erroreStampante",
          actions: [
            assign({
              erroreStampante: (context, event) => {
                let err = event.data.error
                if (err) {
                  err.scontrino = false
                }
                return err },
              printerResponse: (context, event) => event.data
            }),
            (context, event) => {
              if (event.data && event.data.error) {
                console.log('inviaComandiStampante --> messaggio di errore', event.data.error.code, event.data.error.message);
              }
            },
            send('ABORT') // gestire
          ]
        },
        PRINTER_RESUME: {
          actions: (context, event) => { console.log('PRINTER_RESUME 2', event)}
        },
        PRINTER_ABORT: {
          actions: (context, event) => { console.log('PRINTER_ABORT 2', event)}
        }
      }
    },
/*     stampaGestionaleGenerico: {
      invoke: {
        id: 'gestionaleGenerico',
        src: scontriniMachine,
        data: {
          comandi: (context, event) => event.value.commands,
          settings: (context, event) => event.value.settings
        },
        onDone: [
          {
            cond: (context) => context.printerInitialStatusRequest && context.impostazioni.stampante.mostra_pannello_iniziale,
            target: 'operand1',
            actions: [
              assign({
                printerResponse: (context, event) => event.data.printerResponse,
                printerInitialStatusRequest: () => false
              }),
              send(() => ({ value: 'Stampante', type: 'APRI_MODALE' }))
            ]
          },
          {
            target: 'operand1',
            actions: [
              assign({ printerResponse: (context, event) => event.data.printerResponse })
            ]
          }
        ]
      },
      entry: send((context) => 'SEND_COMMANDS', { to: 'comandiM' }),
      on: {
        // Eventuali eventi dalla macchina degli scontrini
        PRINTER_ERROR : {
          target: "erroreStampante",
          actions: [
            assign({
              erroreStampante: (context, event) => {
                let err = event.data.error
                err.scontrino = false
                return err },
              printerResponse: (context, event) => event.data
            }),
            (context, event) => {
              console.log('inviaComandiStampante --> messaggio di errore', event.data.error.code, event.data.error.message);
            },
            send('ABORT') // gestire
          ]
        },
        PRINTER_RESUME: {
          actions: (context, event) => { console.log('PRINTER_RESUME 2', event)}
        },
        PRINTER_ABORT: {
          actions: (context, event) => { console.log('PRINTER_ABORT 2', event)}
        }
      }
    },    */
    erroreStampante: {
      on: {
        PRINTER_RESUME: {
          actions: (context, event) => { console.log('PRINTER_RESUME 3', event)}
        },
        PRINTER_ABORT: {
          actions: (context, event) => { console.log('PRINTER_ABORT 3', event)}
        },
        RETRY: {},
        ABORT: {
          target: 'operand1'
        }
      }
    },
    riusa_prodotti_annullo: {
      invoke: {
        id: 'riusa_prodotti_annullo',
        // src: (context, event) => usaRigheRecupero(context),
        src: async (context) => {
          return await usaRigheRecupero(context)
        },
        onDone: {
          target: 'operand1',
          actions: [
            send('CALCOLA_TOTALE_SCONTRINO')
          ]
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore riuso'
          })
        }
      }
    },
    lettura_giornaliera: {
      invoke: {
        id: 'lettura_giornaliera',
        src: async (context) => {
          return await stampante.letturaGiornaliera()
        },
        onDone: {
          target: 'operand1',
          actions: [
            assign({ displayMessage: () => '' })
          ]
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore lettura giornaliera'
          })
        }
      }
    },
    chiusura_giornaliera: {
      invoke: {
        id: 'chiusura_giornaliera',
        src: async (context) => {
          return await stampante.chiusuraGiornaliera()
        },
        onDone: {
          target: 'operand1',
          actions: [
            assign({
              displayMessage: () => '',
              chiusuraGiornalieraInCorso: () => false
            })
          ]
        },
        onError: {
          target: 'operand1',
          actions: assign({
            displayMessage: (context, event) => 'Errore chiusura giornaliera',
            chiusuraGiornalieraInCorso: () => false
          })
        }
      }
    },
    gestionale_generico: {
      invoke: {
        id: 'gestionale_generico',
        src: async (context) => {
          const righe = []
          return await stampante.stampaScontrinoGestionale(righe)
        },
        onDone: {
          target: 'operand1',
          actions: [
            assign({ displayMessage: () => '' })
          ]
        },
        onError: {
          actions: assign({
            displayMessage: (context, event) => 'Errore stampa generica'
          })
        }
      }
    },
    pagamento_appuntamento: {
      invoke: {
      id: 'pagaAppuntamento',
      src: async (context, event) => {
        console.log('pagamento appuntamento')
        const prodotti = context.appuntamento.prodotti
        for (let pr of prodotti) {
          const prodotto = await getInfoProdotto(context, { value: { code: pr.codice, tipoAliquota: 'banco' } })
          context.prodotto = {...prodotto}
          context.quantita = pr.quantita
          // console.log(prodotto)
          aggiungiProdottoAlCarrello(context)
        }
      },
      onDone: {
        target: "operand1",
        actions:[
          send('CALCOLA_TOTALE_SCONTRINO'),
          send('CLEAR_PARTIAL')
        ]
      },
      onError: {
        target: "operand1.zero",
        actions: assign({
        displayMessage: (context, event) => event.data
        })
      }
      }
    }
  }
},
{
  guards: {
    isMinus,
    isNotMinus,
    isZero,
    isNotZero,
    notDivideByZero,
    useDecimalPoint,
    useNotDecimalPoint,
    isDecimalPointPresent,
    isNotDecimalPointPresent,
    isDisplayZero,
    isNotDisplayZero,
    isReparto,
    isProdotto,
    isRichiestaPrezzo,
    isNotRichiestaPrezzo,
    isSubTotale,
    isNotSubTotale,
    scontoEffettuato,
    // scontoSubEffettuato,
    abbuonoMaggioreTotale,
    isRigaSelezionata,
    isNotRigaSelezionata,
    codiceLotteriaInserito,
    notCodiceLotteriaInserito
  },
  actions: {
    appendNum: (context, event) => {
      console.log('event.value', event.value)
/*       if (event.value === 13 && context.displayValue.length > 7) {
        q.push(context.displayValue)
        context.displayValue = ''
        return
      } */
      let oldVal = context.displayValue === '0' ? '' : context.displayValue;
      context.displayValue = String(oldVal).concat(event.value);
    },
    /*     appendNum: assign({
      displayValue: (context, event) => {
        let oldVal = context.displayValue === '0' ? '' : context.displayValue;
        let newVal = String(oldVal).concat(event.value);
        return newVal
      }
    }), */
    clearAndAppendNum: assign({
      displayValue: (context, event) => {
        let oldVal = '';
        let newVal = String(oldVal).concat(event.value);
        return newVal
      },
      displayDescrizione: () => ''
    }),
    numBy100: assign({
      displayValue: (context, event) => {
/*         let newVal = Math.round(parseFloat(context.displayValue) * 100, 0) // moltiplica il valore per 100
        return newVal.toFixed(2) */
        let newVal = context.displayValue
        return newVal
      }
    }),
    clearNum: assign({
      displayValue: (context, event) => {
/*         let oldVal = Math.round(parseFloat(context.displayValue) * 100, 0)
        let newVal = (parseInt(String(oldVal).slice(0, -1))) / 100;
        if (isNaN(newVal)) {
          newVal = 0;
        }
        return newVal.toFixed(2) */
        let oldVal = context.displayValue
        let newVal = String(oldVal).slice(0, -1);
        if (isNaN(newVal) || newVal === '') {
          newVal = '0';
        }
        return newVal
      },
      displayDescrizione: () => ''
    }),

    setReadoutNum: assign({
      displayValue: (context, event) => event.value,
      displayDescrizione: () => ''
    }),

    appendReadoutNum: assign({
      displayValue: (context, event) => String(context.displayValue).concat(event.value),
      displayDescrizione: () => ''
    }),

    appendDecimalPoint: assign({
      displayValue: (context, event) => context.displayValue + '.',
      displayDescrizione: () => ''
    }),

    removeReadoutNum: assign({
      displayValue: (context, event) => {
        let newValue = String(context.displayValue).slice(0, -1)
        if (newValue.charAt(newValue.length - 1) === '.') {
          newValue = newValue.slice(0, -1)
        }
        if (!newValue) {
          newValue = '0'
        }
        return newValue
      },
      displayDescrizione: () => ''
    }),
    multiply_quantity: assign({
      quantita: (context, event) => parseFloat(context.displayValue),
      quantitaModificata: (context, event) => {
        return context.quantita !== 1
      },
      displayValue: () => "0",
      displayDescrizione: () => ''
    }),
    setPage: (context, event) => {
      context.page = event.value.page
      if (context.pageList[event.value.zona || 'tasti'] == event.value.page && event.value.toggle) {
        context.pageList[event.value.zona || 'tasti'] = event.value.toggle
      } else {
        context.pageList[event.value.zona || 'tasti'] = event.value.page
      }
    },
/*     setPage: assign({
        page: (context, event) => event.value.page
      }), */
    aggiungiProdottoAlCarrello: (context, event) => {
      // verificare eventuali sconti cliente o listini... Forse già passati su calcolo prezzo prodotto
      console.log('aggiungiProdottoAlCarrello ACTION')

      let pzUnitario = parseFloat(context.prezzoImposto || context.prodotto.prezzo).toFixed(2);
      let qta = context.quantita
      let iva = context.prodotto.iva
      let sintetico = context.impostazioni.carrello.scontrino_sintetico ? dot.pick(context.impostazioni.carrello.scontrino_sintetico, context.prodotto)  : ''
      let prezzoAcquistoIvato = context.impostazioni.carrello.prezzoAcquistoIvato
      let prezzoAcquisto = context.prodotto.magazzino && parseFloat(context.prodotto.magazzino.prezzoAcquisto || 0)
      let ivaAcquisto = context.prodotto.magazzino && context.prodotto.magazzino.ivaAcquisto || 0.22
      if (prezzoAcquistoIvato) {
        prezzoAcquisto = prezzoAcquisto * (1 + ivaAcquisto)
      }
      // ------------------------------------------------------------------------------
      // Verifica prodotto già inserito se abilitata gestione multipli
      if (context.impostazioni.cassa.inserisciProdottiMultipli && !context.prodottoAPeso) {
        const codice = context.prodotto.ricercaTC.toLowerCase()
        const riga = context.righeCarrello.find(x => x.codice === codice && x.quantita > 0)
        if (riga) {
          riga.quantita += qta
          riga.prezzo += pzUnitario * qta
          riga.prezzo = +riga.prezzo.toFixed(2)
          context.rigaCarrello = riga
          return
        }
      }
      // ------------------------------------------------------------------------------
      var riga = {
        // codice: context.prodotto.codice,
        codice: context.prodotto.ricercaTC.toLowerCase(),
        codiceProdotto: context.prodotto.codice.toLowerCase(),
        barcode: context.prodotto.barcode,
        variante1: context.prodotto.varianti && context.prodotto.varianti.variante1 && context.prodotto.varianti.variante1.codice,
        variante2: context.prodotto.varianti && context.prodotto.varianti.variante2 && context.prodotto.varianti.variante2.codice,
        quantita: qta,
        unitaMisura: context.prodotto.magazzino && context.prodotto.magazzino.unitaMisura || '',
        descrizione: sintetico || context.prodotto.descrizioneTC || context.prodotto.descrizione,
        prezzoUnitario: pzUnitario,
        prezzo: +(parseFloat(pzUnitario) * qta).toFixed(2),
        aliquota: iva.valore,
        natura: iva.natura, // verificare
        tipo: 'prodotto',
        tipoBene: context.prodotto.tipoBene,
        nota: context.prodotto.nota || '',
        repartoFiscale: context.prodotto.repartoFiscale ? context.prodotto.repartoFiscale.stampante || '' : '',
        prezzoAcquisto: prezzoAcquisto > 0 ? prezzoAcquisto.toFixed(2) : '---',
        // operatore: context.operatore.servizio ? context.operatore.servizio.codice || '' : '',
        operatore: fnGetOperatore(context),
        rigaCodice: uuidv4(),
        listini: context.prodotto.listini,
        magazzino: context.prodotto.magazzino
        // stampa: _.get(context.prodotto, 'repartoFiscale.stampa', '')
        // iva
        // altri eventuali campi
      };
      const rep = context.prodotto.repartoFiscale ? context.prodotto.repartoFiscale.codice || '' : ''
      const stampa = _.get(context.prodotto, 'repartoFiscale.stampa', '') || infoRepartiFiscali.tipoStampaReparto(rep)
      if (stampa) {
        riga.stampa = stampa
      }
      if (context.prodotto.ssnn) {
        riga.ssnn = {...context.prodotto.ssnn}
        riga.descrizione = '*' + riga.descrizione
        // context.righeDetraibiliNoCodFis = !context.righeCarrello.some(x => x.tipo === 'codicefiscale')
      }
      if (context.prodotto.infoBuono) {
        riga.infoBuono = {...context.prodotto.infoBuono}
      }
      if (context.prodotto.bom) {
        riga.bom = { ...context.prodotto.bom } 
      }
      if (context.prodotto.lotti) {
        riga.lotti = { ...context.prodotto.lotti } 
      }      
      if (context.prodotto.servizio) {
        riga.servizio = {...context.prodotto.servizio}
      }

      const fd = context.fidelityCard
      if (fd && fd.generale && fd.generale.sconto) {
        const scontoPerc = parseInt(fd.generale.sconto)
        let ultimoPrezzo = parseFloat(riga.prezzo)
        let sconto = ultimoPrezzo * scontoPerc / 100
        var subriga = {
          quantita: 0,
          descrizione: `sconto card ${scontoPerc}%`,
          prezzoUnitario: 0,
          prezzo: (-sconto).toFixed(2),
          tipo: 'abbuono',
          aliquota: riga.aliquota,
          natura: riga.natura,
          valore: sconto.toFixed(2),
          rigaCodice: uuidv4()
        };
        riga.sconto = subriga

      }
      // if (riga.servizio && context.nuovoAppuntamento && context.nuovoAppuntamento.modaleAppuntamento) {
      if (riga.tipoBene === 'SERVIZIO' && context.nuovoAppuntamento && context.nuovoAppuntamento.modaleAppuntamento) {
        context.nuovoAppuntamento.modaleAppuntamento.onSelezionaServizioDaDisplay(riga)
        return
      }

      context.righeCarrello.push(riga) // togliere quando funzione macchina separata
      context.rigaCarrello = riga;
      context.prodottoAPeso = null
      if (riga.tipoBene === 'VINCITA') {
        context.vinciteCaricate += +(pzUnitario * qta).toFixed(2)
        impostaContanti(context, +(pzUnitario * qta).toFixed(2))
      } else {
        // context.impostaVincita = true
        // impostaContanti(context, 0)
      }
    },
    aggiungiRepartoAlCarrello: (context, event) => {
      let pzUnitario = context.reparto.prezzoPredefinito || (parseFloat(context.displayValue)/100).toFixed(2);
/*       if (parseFloat(pzUnitario) === 0) {
        context.displayMessage = `Attenzione ! Non è stato specificato un prezzo`
        return
      } */
      let qta = context.quantita
      let iva = context.reparto.iva
      var riga = {
        codice: context.reparto.codice,
        quantita: qta,
        descrizione: context.reparto.descrizione, // verificare
        prezzoUnitario: pzUnitario,
        prezzo: (pzUnitario * qta).toFixed(2),
        aliquota: iva.valore,
        natura: iva.natura, // verificare
        tipo: 'reparto',
        tipoBene: context.reparto.tipoBene,
        nota: context.reparto.nota || '',
        repartoFiscale: context.reparto.repartoFiscale ? context.reparto.repartoFiscale.stampante || '' : '',
        // operatore: context.operatore.servizio ? context.operatore.servizio.codice || '' : '',
        operatore: fnGetOperatore(context),
        rigaCodice: uuidv4()
      // altri eventuali campi
      }
      const rep = context.reparto.repartoFiscale ? context.reparto.repartoFiscale.codice || '' : ''
      const stampa = _.get(context.reparto, 'repartoFiscale.stampa', '') || infoRepartiFiscali.tipoStampaReparto(rep)
      if (stampa) {
        riga.stampa = stampa
      }
      if (context.reparto.ssnn) {
        riga.ssnn = {...context.reparto.ssnn}
        riga.descrizione = '*' + riga.descrizione
      }
      if (context.reparto.infoBuono) {
        riga.infoBuono = {...context.reparto.infoBuono}
      }
      context.righeCarrello.push(riga)
      context.rigaCarrello = riga;
      if (riga.tipoBene === 'VINCITA') {
        context.vinciteCaricate += +(pzUnitario * qta).toFixed(2)
        impostaContanti(context, +(pzUnitario * qta).toFixed(2))
      } else {
        // context.impostaVincita = true
        // impostaContanti(context, 0)
      }
    },
    aggiungiScontoAlCarrello: (context, event) => {
      console.log('sconto:', context.rigaCarrello.codice)
      let scontoPerc = parseFloat(context.displayValue);
      let riga = context.rigaSelezionata != null ? context.righeCarrello[context.rigaSelezionata] : context.rigaCarrello
      // let idRiga = context.rigaSelezionata  || (context.righeCarrello.length - 1);
      let ultimoPrezzo = parseFloat(riga.prezzo);
      let sconto = ultimoPrezzo * scontoPerc / 100;
      var subriga = {
        quantita: 0,
        descrizione: `sconto ${scontoPerc}%`,
        prezzoUnitario: 0,
        prezzo: (-sconto).toFixed(2),
        tipo: 'abbuono', // per evitare arrotondamento stampante passo già il valore arrotondato ai centesimi
        // tipo: 'sconto',  // ####
        aliquota: riga.aliquota,
        natura: riga.natura, // verificare
        // valore: scontoPerc // ####
        valore: sconto.toFixed(2),
        rigaCodice: uuidv4()
      };
      // context.righeCarrello[idRiga].sconto = subriga;
      riga.sconto = subriga
/*       if (context.rigaSelezionata != null) {
        context.righeCarrello[context.rigaSelezionata] = riga
      } */
    },
    aggiungiScontoAlTotaleCarrello: (context, event) => {
      let scontoPerc = parseFloat(context.displayValue);
      let totaleCarrello = parseFloat(context.totaleScontrino);
      if (context.impostazioni.carrello.totale_solo_vendita) {
        // TODO: Lo sconto va calcolato solo sulle righe di vendita e non sul reso (da Impostazioni)
        totaleCarrello = parseFloat(fnCalcolaTotaleScontrinoNoResi(context))
      }

      let sconto = totaleCarrello * scontoPerc / 100;
      var riga = {
        quantita: 0,
        descrizione: `Sconto Sub. ${scontoPerc}%`,
        prezzoUnitario: 0,
        prezzo: (-sconto).toFixed(2),
        tipo: 'scontoSub',
        // tipo:'abbuonoSub',
        aliquota: '',
        // valore: scontoPerc, ###
        valore: +(sconto).toFixed(2),
        rigaCodice: uuidv4()
      };
      context.righeCarrello.push(riga)
      context.rigaCarrello = riga;
    },
    aggiungiAbbuonoAlCarrello: (context, event) => {
      let abbuono = parseFloat(context.displayValue) / 100;
      let riga = context.rigaSelezionata != null ? context.righeCarrello[context.rigaSelezionata] : context.rigaCarrello
      // let idRiga = context.rigaSelezionata  || (context.righeCarrello.length - 1);
      var subriga = {
        quantita: 0,
        descrizione: `sconto ${abbuono.toFixed(2)} euro`,
        prezzoUnitario: 0,
        prezzo: (-abbuono).toFixed(2),
        tipo: 'abbuono',
        aliquota: riga.aliquota,
        natura: riga.natura,
        valore: abbuono,
        rigaCodice: uuidv4()
      };
      // let idRiga = context.righeCarrello.length -1; // calcolare o passare. OK per ultimo ma non va bene per modifica

      // context.righeCarrello[idRiga].abbuono = subriga;
      riga.abbuono = subriga;
    },
    aggiungiNotaAlCarrello: (context, event) => {
      var riga = {
        quantita: 0,
        descrizione: event.nota,
        prezzoUnitario: 0,
        prezzo: event.prezzo,
        tipo: event.tipoRiga,
        rigaCodice: uuidv4()
      };
      context.righeCarrello.push(riga)
      context.rigaCarrello = riga;
    },
    aggiungiCodiceLotteriaAlCarrello: (context, event) => {
      let codice = context.displayValue
      if (event && event.data && event.data.tipoBarcode && event.data.tipoBarcode === 'lotteria') {
        codice = event.data.code.toUpperCase()
      }
      console.log('lotteria', codice)
      var riga = {
        quantita: 0,
        descrizione: 'Codice lotteria: ' + codice.replace('LOT_', ''),
        prezzoUnitario: 0,
        prezzo: '',
        tipo: 'lotteria',
        payload: { codice: codice.replace('LOT_', '') }
      };
      context.righeCarrello.push(riga)
      context.rigaCarrello = riga;
    },
    aggiungiCodiceFiscaleAlCarrello: (context, event) => {
      let codice = context.displayValue
      if (event && event.data && event.data.tipoBarcode && event.data.tipoBarcode === 'codicefiscale') {
        codice = event.data.code.toUpperCase()
      }
      console.log('codice fiscale', codice)
      var riga = {
        quantita: 0,
        descrizione: 'Codice fiscale: ' + codice,
        prezzoUnitario: 0,
        prezzo: '',
        tipo: 'codicefiscale',
        payload: { codice: codice }
      };
      context.righeCarrello.push(riga)
      context.rigaCarrello = riga;
      context.righeDetraibiliNoCodFis = false
    },
    aggiungiCortesiaAlCarrello: (context, event) => {
      let qta = context.quantita
      var riga = {
        quantita: qta,
        descrizione: 'Scontrino regalo',
        prezzoUnitario: 0,
        prezzo: '',
        tipo: 'cortesia'
      }
      context.righeCarrello.push(riga)
      context.rigaCarrello = riga;
    },
    calcolaTotaleScontrino: assign({
      totaleScontrino: (context, event) => fnCalcolaTotaleScontrino(context),
      itemsScontrino: (context, event) => fnCalcolaNumeroItemScontrino(context),
      scontrinoTick: (context) => context.scontrinoTick + 1
    }),
    calcolaArrotondamenti: (context) => {
      let scontoapagare = parseFloat(context.importoPagato.scontoapagare || 0) // ATTENZIONE!! Lo sconto a pagare è già compreso nei contanti pagati
      // ********** GESTIONE ARROTONDAMENTI FISCALI (solo se contanti) **********************
      if (context.importoPagato.contanti > 0) {
        // Verificare come gestire il caso di pagamento multiplo (contanti + carta) e sconto a pagare maggiore dei contanti
        // es. tot 77.12 , carta 30.04 , sconto a pagare 30.04 contanti 17.04
        // debugger
        // let contantiPerArrotondamento = context.importoPagato.contanti - scontoapagare
        // let contantiPerArrotondamento = context.importoPagato.contanti // TODO: Verificare - scontoapagare
        let contantiPerArrotondamento = context.importoPagato.contanti - (context.resto || 0)
        const abilita = _.get(context, 'impostazioni.cassa.abilitaArrotondamenti', true)
        if (contantiPerArrotondamento > 0 && abilita) {
          const ics = String(Math.round(contantiPerArrotondamento * 100))
          const ic = ics.substr(ics.length - 1)
          let arrotondamento = 0
          if (ic === '1' || ic === '2' || ic === '6' || ic === '7') {
            // per difetto abbuono
            arrotondamento = -(parseFloat(ic) - ((ic === '1' || ic === '2') ? 0 : 5)) / 100
            addArrotonamento(context, {
              valore: arrotondamento,
              descrizione: 'Arrotondamento per DL. 50/2017',
              tipo: 'arrotondaDifetto'
            })
          }
          if (ic==='3' || ic === '4' || ic==='8' || ic==='9') {
            arrotondamento = (((ic === '3' || ic === '4') ? 5 : 10) - parseFloat(ic)) / 100
            addArrotonamento(context, {
              valore: arrotondamento,
              descrizione: 'Arrotondamento per DL. 50/2017',
              tipo: 'arrotondaEccesso'
            })
          }
          if (arrotondamento !== 0) {
            if (context.resto) {
              context.resto = + (context.resto - arrotondamento).toFixed(2)
            } else {
              context.importoPagato.contanti = + (context.importoPagato.contanti + arrotondamento).toFixed(2)
            }
            context.giaPagatoTotale = + (context.giaPagatoTotale + arrotondamento).toFixed(2)
            // context.importoPagato.contanti += arrotondamento
            // context.giaPagatoTotale += arrotondamento
            if (context.daPagareTotale > 0) {
              context.daPagareTotale = +(context.daPagareTotale + arrotondamento).toFixed(2)
              // context.daPagareTotale += arrotondamento
            }
            context.totaleScontrino = fnCalcolaTotaleScontrino(context)
            context.itemsScontrino = fnCalcolaNumeroItemScontrino(context)
          }
        }
      }
      // ************************************************************************************
    },
    chiudiScontrino: async (context) => {
      context.scontrinoEspanso = true
      delete context.infoChiusuraScontrino.stato
      context.infoChiusuraScontrino.pagamenti.length = 0
      addNota(context, { tipoRiga: 'separatore', nota: '', prezzo: '' })

      // IMPORTANTE
      // Verificare come procedere in base al tipo di documento scelto
      // Nel caso non venga stampato lo scontrino, è necessario inibire la chiamata a STAMPA_TOTALE
      // Aggiungere uno stato che richiama le varie procedure in base al tipo di documento
      let scontoapagare = parseFloat(context.importoPagato.scontoapagare || 0)
      let totaleComplessivo =  parseFloat(context.totaleScontrino) + scontoapagare

      let totaleNonFiscale = 0
      for (let r of context.righeNonFiscali.filter(x => ['PRODOTTO', 'SERVIZIO'].includes(x.tipoBene))) {
        totaleNonFiscale += parseFloat(r.prezzo)
      }

      addNota(context, { tipoRiga: 'totalecomplessivo', nota: 'Totale complessivo', prezzo: (totaleComplessivo - totaleNonFiscale).toFixed(2) })

      // TODO: Creare pagamenti generici da associare a tasti particolari (es. eBay)
      if (context.importoPagato.scontoapagare > 0) {
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'scontoapagare', importo: context.importoPagato.scontoapagare })
        addNota(context, { tipoRiga: 'scontoapagare', nota: 'Sconto a pagare (Reso)', prezzo: parseFloat(context.importoPagato.scontoapagare).toFixed(2) })
      }
      if (context.importoPagato.cartadicredito > 0) {
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'cartadicredito', importo: context.importoPagato.cartadicredito })
        addNota(context, { tipoRiga: 'cartadicredito', nota: 'Carta di Pagamento', prezzo: parseFloat(context.importoPagato.cartadicredito).toFixed(2) })
      }
/*       if (context.importoPagato.contanti > 0) {
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'contanti', importo: +context.importoPagato.contanti.toFixed(2) })
        addNota(context, { tipoRiga: 'contanti', nota: 'Pagamento contante', prezzo: parseFloat(context.importoPagato.contanti).toFixed(2) })
      } */
      if (context.importoPagato.buonipasto > 0) {
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'buonipasto', importo: context.importoPagato.buonipasto })
        addNota(context, { tipoRiga: 'buonipasto', nota: 'Buoni Pasto', prezzo: parseFloat(context.importoPagato.buonipasto).toFixed(2) })
      }
      if (context.importoPagato.nonriscosso > 0) {
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'nonriscosso', importo: context.importoPagato.nonriscosso })
        addNota(context, { tipoRiga: 'nonriscosso', nota: 'Non riscosso', prezzo: parseFloat(context.importoPagato.nonriscosso).toFixed(2) })
      }
      if (context.importoPagato.nonriscossobeni > 0) {
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'nonriscossobeni', importo: context.importoPagato.nonriscossobeni })
        addNota(context, { tipoRiga: 'nonriscossobeni', nota: 'Non riscosso Beni', prezzo: parseFloat(context.importoPagato.nonriscossobeni).toFixed(2) })
      }
      if (context.importoPagato.nonriscossoservizi > 0) {
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'nonriscossoservizi', importo: context.importoPagato.nonriscossoservizi })
        addNota(context, { tipoRiga: 'nonriscossoservizi', nota: 'Non riscosso Servizi', prezzo: parseFloat(context.importoPagato.nonriscossoservizi).toFixed(2) })
      }
/*       if (context.importoPagato.scontoapagare > 0) {
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'scontoapagare', importo: context.importoPagato.scontoapagare })
        addNota(context, { tipoRiga: 'scontoapagare', nota: 'Sconto a pagare (Reso)', prezzo: parseFloat(context.importoPagato.scontoapagare).toFixed(2) })
      } */
      let importoBuoni = 0
      if (context.importoPagato.buonimultiuso > 0) {
        importoBuoni = context.importoPagato.buonimultiuso
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'buonimultiuso', importo: context.importoPagato.buonimultiuso })
        addNota(context, { tipoRiga: 'buonimultiuso', nota: 'Buoni multiuso', prezzo: parseFloat(context.importoPagato.buonimultiuso).toFixed(2) })
/*         for(let b of context.buoniMultiuso) {
          addNota(context, { tipoRiga: 'descrizione', nota: `Utilizzo (${b.buono.usoAttuale} euro) buono nr. ${b.codice}` })
        } */
        context.buonoMultiusoUtilizzo = context.buonoMultiuso.buono.utilizzi.length // posizione in cui viene salvato (per aggiornamento numero scontrino)
        for(let b of context.buoniMultiuso) {
          const uso = {
            data: new Date(),
            importo: +parseFloat(b.buono.usoAttuale).toFixed(2)
          }

          b.buono.utilizzi.push(uso)
        }
      }
      let importoBuoniMonouso = 0
      if (context.importoPagato.buonimonouso > 0) {
        importoBuoniMonouso = context.importoPagato.buonimonouso
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'buonimonouso', importo: context.importoPagato.buonimonouso })
        addNota(context, { tipoRiga: 'buonimonouso', nota: 'Buoni monouso', prezzo: parseFloat(context.importoPagato.buonimonouso).toFixed(2) })
        addNota(context, { tipoRiga: 'descrizione', nota: 'Utilizzo buono nr. ' + context.buonoMonouso.codice })
        const uso = {
          data: new Date(),
          importo: parseFloat(context.importoPagato.buonimonouso).toFixed(2) // va saldato tutto il buono ?
        }
        context.buonoMonousoUtilizzo = context.buonoMonouso.buono.utilizzi.length // posizione in cui viene salvato (per aggiornamento numero scontrino)
        context.buonoMonouso.buono.utilizzi.push(uso)
      }
      let importoFidelityCard = 0
      if (context.importoPagato.fidelitycard > 0) {
        importoFidelityCard = context.importoPagato.fidelitycard
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'fidelitycard', importo: context.importoPagato.fidelitycard })
        addNota(context, { tipoRiga: 'fidelitycard', nota: 'Fidelity card', prezzo: parseFloat(context.importoPagato.fidelitycard).toFixed(2) })
        addNota(context, { tipoRiga: 'descrizione', nota: 'Utilizzo card nr. ' + context.fidelityCard.codice })
        const uso = {
          data: new Date(),
          importo: parseFloat(context.importoPagato.fidelitycard).toFixed(2)
        }
        context.fidelityCardUtilizzo = context.fidelityCard.buono.utilizzi.length // posizione in cui viene salvato (per aggiornamento numero scontrino)
        context.fidelityCard.buono.utilizzi.push(uso)
      }

      if (context.importoPagato.contanti > 0) {
        context.infoChiusuraScontrino.pagamenti.push({ tipo: 'contanti', importo: +context.importoPagato.contanti.toFixed(2) })
        addNota(context, { tipoRiga: 'contanti', nota: 'Pagamento contante', prezzo: parseFloat(context.importoPagato.contanti).toFixed(2) })
      }

      addNota(context, { tipoRiga: 'separatore', nota: '', prezzo: '' })

      if (context.resto != null) { // verificare se solo > 0
        context.infoChiusuraScontrino.resto = context.resto
        addNota(context, { tipoRiga: 'resto', nota: 'Resto', prezzo: parseFloat(context.resto).toFixed(2) })
      } else {
        context.infoChiusuraScontrino.resto = null
      }
      const importoPagato = totaleComplessivo - importoBuoni - importoBuoniMonouso - importoFidelityCard
      //context.infoChiusuraScontrino.totale = parseFloat(context.totaleScontrino) // verificare se testo
      // addNota(context, { tipoRiga: 'totale', nota: 'Importo pagato', prezzo: parseFloat(context.totaleScontrino).toFixed(2) })

      context.infoChiusuraScontrino.totale = importoPagato

      // addNota(context, { tipoRiga: 'totale', nota: 'Importo pagato', prezzo: importoPagato.toFixed(2) }) //  Verificare
      let payload = null
      if (context.impostazioni.operatori.scontrino.abilitato) {
        const tipoInfo = context.impostazioni.operatori.scontrino.tipoInfo || 'descrizione'
        const op = fnGetNomeOperatore(context, tipoInfo)
        if (op) {
          payload = { operatore : `${context.impostazioni.operatori.scontrino.messaggio || 'Siete stati serviti da'}: ${op}` }
        }
      }

      if (context.righeCarrello.some(x => x.ssnn)) {
        payload = payload || {}
        payload.ssnn = {
          legenda: _.get(context.impostazioni, 'ssnn.legendaStampante', [])
        }
      }

      if (context.noteDiCoda) {
        payload = payload || {}
        payload.noteDiCoda = context.noteDiCoda
/*         for (let nota of context.noteDiCoda) {
          addNota(context, { tipoRiga: 'descrizione', nota: nota })
        } */
        context.noteDiCoda = []
      }
      if (context.coupon) {
        payload = payload || {}
        payload.noteDiCoda = payload.noteDiCoda || []
        const infoCoupon = _.get(context.impostazioni, 'fidelityCard.coupon.infoScontrino')
        if (infoCoupon) {
          payload.noteDiCoda.push(infoCoupon.apertura || '')
          payload.noteDiCoda.push((infoCoupon.codice || '').replace('#CODICE#', context.coupon.codice))
          payload.noteDiCoda.push(infoCoupon.chiusura || '')
        }

      }

      if (context.fidelityCard) {
        const impCard = _.get(context.impostazioni, 'fidelityCard.scontrino', null)
        payload = payload || {}
        if (impCard && impCard.infoFidelity) {
/*           const infoPuntiGen = await fidelity.infoRaccoltaPunti(context.fidelityCard._id, context.totaleScontrino)
          context.fidelityCard.infoPunti = infoPuntiGen */

          const infoPunti = context.fidelityCard.infoPunti
          payload.fidelity = {
            numero: `${impCard.labelNumeroCarta || 'Carta'}: ${context.fidelityCard._id}`
          }
          if (impCard.saldoPunti) {
            payload.fidelity.saldo = `${impCard.labelSaldo || 'Tot. punti'}: ${infoPunti.totale || '---'}`
          }
          if (impCard.saldoCompleto) {
            payload.fidelity.puntiPrecedenti = `${impCard.labelPrecedenti || 'Pt. precedenti'}: ${infoPunti.puntiPrecedenti || '---'}`
            payload.fidelity.puntiScontrino = `${impCard.labelCorrenti || 'Pt. scontrino'}: ${infoPunti.puntiScontrino || '---'}`
          }
          payload.fidelity.titolo = impCard.titolo
          if (context.fidelityCard.offerteAttive) {
            payload.fidelity.tipoOfferta = `${impCard.labelOffertaAttiva || 'Off.'}: ${context.fidelityCard.offerteAttive.descrizione}`
          }
          context.infoChiusuraScontrino.fidelity = { ...context.fidelityCard.infoPunti, card: context.fidelityCard._id }
        }
      } else {
        context.infoChiusuraScontrino.fidelity = null
      }
      addNota(context, { tipoRiga: 'totale', nota: 'Importo pagato', prezzo: +(context.totaleScontrino - totaleNonFiscale).toFixed(2), payload })

      if (context.tipoDocumento !== 'scontrino') {
        addNota(context, { tipoRiga: 'separatore', nota: '', prezzo: '' })
        if (context.tipoDocumento === 'scontrinoSegueFattura') {
          addNota(context, { tipoRiga: 'descrizione', nota: _.get(context.impostazioni, 'documenti.scontrinoSegueFattura.descrizione', 'Segue fattura') })
        } else {
          addNota(context, { tipoRiga: 'descrizione', nota: `Emessa ${context.tipoDocumentoDesc}: ${context.documento.codice}` })
        }
        // context.infoChiusuraScontrino.documento = context.documento
        // eventuale payload per numero documento su scontrino (spostato su crea_movimenti_magazzino)
      }
    },
    infoProdotto: assign({
      displayValue: (context,event) => parseFloat(context.prodotto.prezzo).toFixed(2),
      displayCodice: (context, event) => context.prodotto.ricercaTC.toLowerCase(),
      displayDescrizione: (context, event) => context.prodotto.descrizione,
      richiestaPrezzo: () => false
    }),
    infoReparto: assign({
      displayCodice: (context, event) => context.reparto.codice,
      displayDescrizione: (context, event) => context.reparto.descrizione
    }),
    // *** riprende scontrino aperto ********************
    riapreScontrinoAperto: (context, event) => {
      const scontrino = event.data
      context.idScontrino = scontrino._id
      context.righeCarrello = [...scontrino.righe]
    },
    // *** stampa scontrino non stampato ********************
    stampaScontrinoNonStampato: (context, event) => {
      const scontrino = event.data
      context.scontrinoRecupero = scontrino
      context.righeCarrello = [...scontrino.righe]
      context.infoChiusuraScontrino = scontrino.pagamento
    },
    // *** recupera scontrino ********************
    recuperaScontrino: (context, event) => {
      context.recuperoInCorso = true
      const scontrino = event.data
      context.scontrinoRecupero = scontrino
      // per eventuale ristampa scontrino regalo
      context.barcodeUltimoScontrino = scontrino.fiscale.ricerca
      context.titoloScontrino = `RECUPERO SCONTRINO N. ${scontrino.fiscale.ricerca.documento}`
      context.confermaAnnulloVisibile = true
      context.righeParcheggiate = [...context.righeCarrello]
      const usabili = []
      for (let i = 0; i < scontrino.righe.length; i++) {
        let riga = scontrino.righe[i]
        if (!(['prodotto', 'reparto'].includes(riga.tipo) && parseFloat(riga.quantita || 0) < 0)) {
          usabili.push(riga)
        }
      }
      // context.righeCarrello = [...scontrino.righe]
      context.righeCarrello = [...usabili]
    },
    // *** reso merce ****************************
    caricaScontrino: (context, event) => {
      // context.tipoDocumentoDesc = "RESO MERCE" // Problema se parcheggio scontrino per reso ?
      const scontrino = event.data
      if (scontrino.fiscale && scontrino.fiscale.annullamento && scontrino.fiscale.annullamento) {
        context.confirmToast = `Attenzione !!!\nScontrino annullato.\nReso non possibile !`
        context.confirmToastObj = { cancelText: '' , confirmText: 'OK', beepSound: 'games' }
        return
      }
      context.resoInCorso = true
      context.resoFiscale = scontrino.fiscale
      context.resoFiscale.idScontrino = scontrino._id

      const az = String(scontrino.fiscale.azzeramento).padStart(4,0)
      const nr = String(scontrino.fiscale.numeroScontrino).padStart(4,0)
      const documento = `${az}-${nr}`
      context.barcodeUltimoScontrino = { code: scontrino.fiscale.ricerca, documento }
      context.titoloScontrino = `RESO DA SCONTRINO N. ${documento}`
      // ***************** toglie sconti, abbuoni e abbuoni su totale *****************
      let riga = scontrino.righe.find(x => x.tipo === 'abbuonoSub')
      const abbuonoSub = riga ? parseFloat(riga.prezzo) : 0
      riga = scontrino.righe.find(x => x.tipo === 'scontoSub')
      const scontoSub = riga ? parseFloat(riga.prezzo) : 0
      riga = scontrino.righe.find(x => x.tipo === 'arrotondaDifetto')
      let arrotonda = (riga ? parseFloat(riga.prezzo) : 0)
      riga = scontrino.righe.find(x => x.tipo === 'arrotondaEccesso')
      arrotonda = arrotonda + (riga ? parseFloat(riga.prezzo) : 0)

      riga = scontrino.righe.find(x => x.tipo === 'subtotale')
      const subtotale = riga ? parseFloat(riga.prezzo) : 0
      let abbuonoPerEuro = subtotale > 0 ? (abbuonoSub + scontoSub + arrotonda) / subtotale : 0

      let sconto = 0
      let abbuono = 0
      for (let i = scontrino.righe.length - 1; i >= 0; i--) {
        riga = scontrino.righe[i]
        if (['prodotto', 'reparto'].includes(riga.tipo) && parseFloat(riga.quantita || 0) > 0) {
          if (riga.sconto) {
            sconto += parseFloat(riga.sconto.prezzo)
            delete riga.sconto
          }
          if (riga.abbuono) {
            abbuono += parseFloat(riga.abbuono.prezzo)
            delete riga.abbuono
          }
          let qta = parseFloat(riga.quantita)
          let prezzo = parseFloat(riga.prezzo) + sconto + abbuono
          prezzo = prezzo * (1 + abbuonoPerEuro)
          let prezzoUnitario = prezzo / qta
          riga.prezzo = prezzo.toFixed(2)
          riga.prezzoUnitario = prezzoUnitario.toFixed(2)
          sconto = 0
          abbuono = 0
          riga.rigaCodice = uuidv4()
        }
      }
      // ************************************************************************************
      // deve verificare che il prodotto possa essere reso (es no intimo, no cibo, etc...)
      let rendibili = scontrino.righe.filter(x => ['prodotto', 'reparto'].includes(x.tipo) && x.resoConsentito !== false && parseFloat(x.quantita || 0) > 0)
      const resiPrecedenti = scontrino.resi
      // verifica eventuali quantità già rese
      if (resiPrecedenti) {
        const righeRese = _.flatMap(resiPrecedenti, ({ righe }) => righe );
        righeRese.forEach(rr => {
          const riga = rendibili.find(el => el.codice === rr.codice)
          riga.quantita = parseFloat(riga.quantita) +  parseFloat(rr.quantita)
          riga.prezzo = (parseFloat(riga.prezzo) + parseFloat(rr.prezzo)).toFixed(2)
          if (riga.quantita === 0) {
            const nascondi = context.impostazioni.resi.nascondi_resi_quantita_zero
            if (nascondi) {
              rendibili = rendibili.filter(item => item !== riga)
            }
          }
        })
      }
      context.righeParcheggiate = [...context.righeCarrello]
      context.righeCarrello = [...rendibili]
      // context.rigaCarrello = riga
    },
    rendiItem: (context, event) => {
      let riga = { ...context.righeCarrello[context.rigaSelezionata] }
      /*
        Se ho specificato la quantità imposta qta reso
        Se qta reso impostata e display = 0 cancella reso
        Se display vuoto e no qta reso, mette tutto a reso
      */
      if ((context.displayValue === '0' || context.displayValue === '') && riga.quantitaReso) {
        // elimino il reso.
        delete riga.quantitaReso
      } else {
        const qtaReso = parseInt(context.displayValue) || riga.quantita
        riga.quantitaReso = qtaReso
      }
      context.righeCarrello[context.rigaSelezionata] = riga
      context.displayValue = ''
      context.confermaResoVisibile = context.righeCarrello.some(x => x.quantitaReso)

/*       context.rigaSelezionata = null,
      context.rendiItemVisible = false,
      context.scontrinoTick = context.scontrinoTick + 1 */

    },
    resoCompleto: (context, event) => {
      var righe = context.righeCarrello
      for (var i=0; i < righe.length; i++) {
        let riga = context.righeCarrello[i]
        if (riga.quantita > 0) {
          riga.quantitaReso = riga.quantita
        }
      }
      context.displayValue = ''
      context.confermaResoVisibile = context.righeCarrello.some(x => x.quantitaReso)
/*       var righe = context.righeCarrello
      for (var i=0; i<righe.length; i++) {
        let riga = righe[i]
        if (riga.quantita > 0) {
          riga.quantitaReso = riga.quantita
        }
      } */
/*       context.righe.forEach(riga => {
        if (riga.quantita > 0) {
          riga.quantitaReso = riga.quantita
        }
      }) */
/*       context.righeCarrello = [...righe]
      context.displayValue = ''
      context.confermaResoVisibile = context.righeCarrello.some(x => x.quantitaReso)
      context.scontrinoTick++ */
    },
    confermaReso: (context, event) => {
      context.righeReso = [...context.righeCarrello]
      context.righeCarrello = []
      context.righeCarrello = [...context.righeParcheggiate]
      let totaleReso = 0
      context.righeReso.filter(x => x.quantitaReso).forEach(riga => {
        let rigaReso = riga
        rigaReso.quantita = -riga.quantitaReso
        rigaReso.prezzo = (parseFloat(rigaReso.prezzoUnitario) * rigaReso.quantita).toFixed(2),
        delete rigaReso.quantitaReso
        rigaReso.payload = { fiscale: context.resoFiscale }
        // rigaReso.dataReso = new Date()
        context.righeCarrello.push(rigaReso)
        totaleReso += -parseFloat(rigaReso.prezzo)
      })
      // Aggiunge pagamento "Sconto a Pagare" ...
      context.importoPagato.scontoapagare = totaleReso
      context.attributes.partialAccount ||= {};
      context.attributes.partialAccount['scontoapagare'] = context.importoPagato.scontoapagare.toFixed(2)

      // riprendere eventuali righe parcheggiate (gestione esplicita parcheggio... ??)
      context.righeParcheggiate = []
      context.resoInCorso = false
      context.confermaResoVisibile = false
      context.isRigheReso = context.righeReso.length > 0
    },
    // *** modifica ****************************
    modificaPrezzoRiga: (context, event) => {
      let riga = context.righeCarrello[context.rigaSelezionata];
      let pzUnitario = parseFloat(context.displayValue)/100;
      let qta = riga.quantita
      riga.prezzo = (pzUnitario * qta).toFixed(2);
      riga.prezzoUnitario = pzUnitario.toFixed(2);
      context.righeCarrello[context.rigaSelezionata] = riga;
    },
    modificaQuantitaRiga: (context, event) => {
      let riga = context.righeCarrello[context.rigaSelezionata];
      let pzUnitario = riga.prezzoUnitario;
      let qta = parseFloat(context.displayValue);
      riga.prezzo = (pzUnitario * qta).toFixed(2);
      riga.quantita = qta;
      context.righeCarrello[context.rigaSelezionata] = riga;
    },
    modificaValoriRiga: (context, event) => {
      // chiamata se ho quantità e/o prezzo imposto e seleziono la riga
      if (context.quantita !== 1 || context.prezzoImposto !== '') {
        let riga = context.righeCarrello[context.rigaSelezionata];
        let pzUnitario = context.prezzoImposto || riga.prezzoUnitario
        riga.prezzoUnitario = pzUnitario
        let qta = parseFloat(context.quantita !== 1 ? parseFloat(context.quantita) : riga.quantita);
        riga.prezzo = (pzUnitario * qta).toFixed(2);
        riga.quantita = qta;
        context.righeCarrello[context.rigaSelezionata] = riga;
      }
    },
    cancellaRiga: async (context, event) => {
      if (context.rigaSelezionata >= 1000) { // sconto su riga
        const rigaProdotto = context.rigaSelezionata - 1000
        if (context.righeCarrello[rigaProdotto].abbuono) {
          delete context.righeCarrello[rigaProdotto].abbuono
        }
        if (context.righeCarrello[rigaProdotto].sconto) {
          delete context.righeCarrello[rigaProdotto].sconto
        }
        console.log('richiesta aggiornamento riga', rigaProdotto)
        await scontrini.updateRigaScontrino(context.idScontrino, rigaProdotto, context.righeCarrello[rigaProdotto])

        return
      }
      let rigaCodice = context.righeCarrello[context.rigaSelezionata].rigaCodice
      if (context.righeCarrello[context.rigaSelezionata].tipo === 'subtotale') {
        context.righeCarrello.splice(context.rigaSelezionata - 1, 2) // toglie subtotale e separatore
        // deve togliere eventuali sconti su subtotale
        const righeSconti = context.righeCarrello.filter(x => ['scontoSub', 'abbuonoSub'].includes(x.tipo))
        context.righeCarrello = context.righeCarrello.filter(x => !['scontoSub', 'abbuonoSub'].includes(x.tipo))
        context.isSubTotaleCalcolato = false
        if (righeSconti.length > 0) {
          for (let r of righeSconti) {
            console.log('richiesta cancellazione riga', r.rigaCodice)
            await scontrini.deleteRigaScontrinoByCode(context.idScontrino, r.rigaCodice) // richiama direttamente altrimenti rimangono nel db
          }
        }
      } else {
        context.righeCarrello.splice(context.rigaSelezionata, 1)
      }
      if (rigaCodice) {
        console.log('richiesta cancellazione riga', rigaCodice, extIdScontrino)
        await scontrini.deleteRigaScontrinoByCode(context.idScontrino, rigaCodice)
      }
    },
    // *****************************************
/*     calcolaResto: (context, event) => {
      let contante = parseFloat(context.displayValue)/100;
      let totaleScontrino = parseFloat(context.totaleScontrino);
      console.log('contante', contante)
      console.log('totaleScontrino', totaleScontrino)
      if (contante > totaleScontrino) {
        context.resto = contante - totaleScontrino;
      } else if (contante === totaleScontrino) {
        context.resto = 0;
      } else {
        context.resto = null;
      }
    }, */
    calcolaResto: (context, event) => {
      let contante = parseFloat(context.displayValue)/100;
      let totaleScontrino = parseFloat(context.totaleScontrino);
      console.log('contante', contante)
      console.log('totaleScontrino', totaleScontrino)
      if (contante > totaleScontrino) {
        context.resto = +(contante - totaleScontrino).toFixed(2);
      } else if (contante === totaleScontrino) {
        context.resto = 0;
      } else {
        context.resto = null;
      }
    },
// **************************************************************************
// ********************** Gestione scontrino ********************************
// **************************************************************************
  ritornoDaStampaScontrino: (context, event) => {
    console.log('ritorno da stampa scontrino')
    let salvaSuErrore = (event.type === 'PRINTER_SAVE_RECEIPT')
    context.stampaEffettuata = false
    // deve salvare lo scontrino ritornato per effettuare i movimenti
    if (salvaSuErrore) {
      // scontrinoStampato è tornato nella routine degli errori
      context.scontrinoStampato.stato = "non stampato"
    } else {
      context.scontrinoStampato = event.data.scontrino
    }

    // Verifica se è stato utilizzato un buono multiuso per il pagamento
    // TODO: Verificare se e come viene utilizzato il valore "uso"
    if (context.buonoMultiuso && context.buonoMultiusoUtilizzo >= 0) {
      debugger
      let uso = context.buonoMultiuso.buono.utilizzi[context.buonoMultiusoUtilizzo]
      if (!salvaSuErrore) {
        uso.fiscale = {
          idScontrino: event.data.idScontrino,
          barcode: event.data.barcode
        }
      }
    }
    // Verifica se è stato utilizzato un buono monouso per il pagamento
    if (context.buonoMonouso && context.buonoMonousoUtilizzo >= 0) {
      let uso = context.buonoMonouso.buono.utilizzi[context.buonoMonousoUtilizzo]
      if (!salvaSuErrore) {
        uso.fiscale = {
          idScontrino: event.data.idScontrino,
          barcode: event.data.barcode
        }
      }
    }
    // Verifica se è stato utilizzata una prepagata per il pagamento
    if (context.fidelityCard && context.fidelityCardUtilizzo >= 0) {
      if (context.fidelityCard.buono) {
        let uso = context.fidelityCard.buono.utilizzi[context.fidelityCardUtilizzo]
        if (!salvaSuErrore) {
          uso.fiscale = {
            idScontrino: event.data.idScontrino,
            barcode: event.data.barcode
          }
        }
      }
      if (context.fidelityCard.raccoltaPunti) {

      }
    }
    if (context.coupon) {
      let uso = context.coupon.coupon.utilizzi[0]
      if (!salvaSuErrore) {
        uso.fiscale = {
          idScontrino: event.data.idScontrino,
          barcode: event.data.barcode
        }
      }
    }
    // Se richiedi utente ad ogni scontrino, toglie l'utente attuale
    if (context.impostazioni.operatori.obbligatorio && context.impostazioni.operatori.singoloScontrino) {
      context.operatore.accesso = null
    }
  },
// **************************************************************************
    editContext: (context, event) => {
      if (event.payload) {
        if (event.reference) { // riporta anche i riferimenti agli oggetti, perche' usando _merge questi vengono persi
          dot.copy(event.reference.source, event.reference.dest, event.payload, context)
        } else {
          context = _.merge(context, event.payload)
        }
      }
    },
    apriModale: (context, event) => {
      if (event.value === 'Planning') {
        if (context.modali.indexOf('Planning') >= 0) {
          if (context.nuovoAppuntamento && context.nuovoAppuntamento.modaleAppuntamento) {
          }
          else {
            context.modalePlanning && context.modalePlanning.espandiModale()
          }
          return
        }
      }

      context.modali.push(event.value)
      if (event.payload) {
        context = _.merge(context, event.payload)
      }
    },
    apriPopup: (context, event) => {
      if (typeof event.value === 'string') {
        const pp = context.popups.find(x => x.type === event.value)
        if (pp) {
          pp.show = true
        }
      } else {
        const pp = context.popups.find(x => x.type === event.value.name)
        if (event.value.propertyName) {
          context[event.value.propertyName] = event.value.propertyValue
        }
        if (pp) {
          pp.show = true
        }
      }
    },
    defaultReadout: assign({
      displayValue: () => "0",
      displayDescrizione: () => ""
      // displayMessage: () => ""
    }),

    defaultNegativeReadout: assign({
      displayValue: () => "-0."
    }),

    appendNumBeforeDecimal: assign({
      displayValue: (context, event) =>
        context.displayValue.slice(0, -1) + event.value + "."
    }),

    appendNumAfterDecimal: assign({
      displayValue: (context, event) => context.displayValue + event.value
    }),

    setNegativeReadoutNum: assign({
      displayValue: (context, event) => "-" + event.value + "."
    }),

    startNegativeNumber: assign({
      displayValue: () => "-"
    }),

    recordOperator: assign({
      operand1: context => context.displayValue,
      operator: (_, event) => event.operator
    }),

    setOperator: assign({
      operator: ({ operator }) => operator
    }),

    computePercentage: assign({
      displayValue: context => context.displayValue / 100
    }),

    compute: assign({
      displayValue: ({ operand1, operand2, operator }) => {
        const result = doMath(operand1, operand2, operator);
        console.log(
          `doing calculation ${operand1} ${operator} ${operand2} = ${result}`
        );
        return result;
      }
    }),

    storeResultAsOperand1: assign({
      operand1: context => context.displayValue
    }),

    storeResultAsOperand2: assign({
      operand2: context => context.displayValue
    }),

    divideByZeroAlert() {
      // have to put the alert in setTimeout because action is executed on event, before the transition to next state happens
      // this alert is supposed to happend on transition
      // setTimeout allows time for other state transition (to 'alert' state) to happen before showing the alert
      // probably a better way to do it. like entry or exit actions
      setTimeout(() => {
        alert("Cannot divide by zero!");
        this.transition("OK");
      }, 0);
    },
    clearParziale: (context) => clearParzialeFunc(context),
/*     clearParziale: assign({
      displayValue: () => "0",
      displayDescrizione: () => '',
      displayMessage: () => '',
      prodotto: () => null,
      reparto: () => null,
      quantita: () => 1,
      quantitaModificata: () => false,
      operand1: () => null,
      richiestaPrezzo: () => false,
      prezzoImposto: () => '',
      attesaCodiceLotteria: () => false,
      attesaCodiceScontrino: () => false,
      confermaChiusuraConBuono: () => false,
      resetBuffer: (context) => context.resetBuffer + 1
      // attributes: () => {} // verificare
      // isSubTotaleCalcolato: () => false,
      // rigaSelezionata: () => null
    }), */
    reset: (context) => {
      resetValues(context)
      if (context.impostazioni.cassa.layout.startPage) {
        for (let sp of Object.keys(context.impostazioni.cassa.layout.startPage))
        context.pageList[sp] = context.impostazioni.cassa.layout.startPage[sp]
      }
    },
   /*  reset: assign({
      displayValue: () => "0",
      displayDescrizione: () => '',
      displayMessage: () => '',
      operand1: () => null,
      operand2: () => null,
      operator: () => null,
      quantita: () => 1,
      quantitaModificata: () => false,
      page: () => 1,
      pageList: () => { return { tasti: 1, tasti2: 1 }},
      righeCarrello: () => [],
      rigaCarrello: () => null,
      scontrinoStampato: () => null,
      idScontrino: () => '',
      prodotto: () => null,
      reparto: () => null,
      richiestaPrezzo: () => false,
      prezzoImposto: () => '',
      totaleScontrino: () => '',
      itemsScontrino: () => '',
      isSubTotaleCalcolato: () => false,
      rigaSelezionata: () => null,
      scontrinoTick: () => 0,
      attributiTick: () => 0,
      cliente: () => null,
      giaPagato: () => 0,
      giaPagatoTotale: () => 0,
      daPagareTotale: () => 0,
      chiusuraPossibile: () => false,
      stampaEffettuata: () => false,
      resto: () => null,
      tipoDocumento: () => 'scontrino',
      tipoDocumentoDesc: () => 'Scontrino',
      attesaCodiceLotteria: () => false,
      attesaCodiceScontrino: () => false,
      barcodeUltimoScontrino: () => '',
      displayCodice: () => '',
      resoInCorso: () => false,
      resoFiscale: () => {},
      righeReso: () => [],
      righeParcheggiate: () => [],
      rendiItemVisible: () => false,
      recuperoInCorso: () => false,
      confermaResoVisibile: () => false,
      titoloScontrino: () => '',
      confermaAnnulloVisibile: () => false,
      scontrinoRecupero: () => null,
      scontrinoAnnullamento: () => null,
      targetDoneScontrini: () => '',
      arrotondamentoPresente: () => false,
      buonoMultiuso: () => null,
      buonoMultiusoUtilizzo: () => null,
      buonoMonouso: () => null,
      buonoMonousoUtilizzo: () => null,
      descrizioneBuono: () => '',
      buonoCaricato: () => false,
      confermaChiusuraConBuono: () => false,
      fidelityCard: () => null,
      fidelityCardDaAssociare: () => false,
      fidelityCardUtilizzo: () => null,
      messaggioToast: () => '',
      confirmToast: () => '',
      fidelityComandi: () => null,
      pagaAppuntamentoInCorso: () => 0,
      displayFocus: () => 0,
      resetBuffer: () => 0,
      scontrinoEspanso: () => false,
      reloadPage: () =>  false,
      barcodePerGenerico: () => '',
      listino: (context) => context.impostazioni.listini.base
      // bloccoInserimentoCodici: () => false
      // importoPagato: () => {}, // fatto in liena
      // attributes: () => {} // fatto in linea
    }), */
    clearDescrizione: assign ({
      displayDescrizione: () => ''
    })
  }
}
)

// Usata per impostare dei parametri di test senza modificare la macchina principale
export const testDashboardMachine = dashboardMachine.withContext({
  displayValue: "0",
  displayDescrizione: 'descrizione',
  listino: "1", // da impostazioni
  cliente: 'nome del cliente',
  clientePiva: 'piva',
  clienteCredito: 'cr',
  clienteScontoPerc: 'sc',
  clientePunti: 'pt',
  totaleScontrino: '',
  quantita: 1,
  quantitaModificata: false,
  quantitaIsPeso: false,
  prezzoImposto: '12.45',
  displayCodice: 'B014',
  ultimoProdottoIsCodice: true,
  page: 1,
  righeCarrello: [{ quantita: 1, descrizione: 'prodotto 1', prezzo: '11,34'}, { quantita: 2, descrizione: 'prodotto 2', prezzo: '12,34'}],
});

// Prossimi stati
// console.log(initialState.nextEvents);

/*
## Persisting State
  const jsonState = JSON.stringify(currentState);

  // Example: persisting to localStorage
  try {
    localStorage.setItem('app-state', jsonState);
  } catch (e) {
    // unable to save to localStorage
  }
----------------------------
  import { State, interpret } from 'xstate';
  import { myMachine } from '../path/to/myMachine';

  // Retrieving the state definition from localStorage, if localStorage is empty use machine initial state
  const stateDefinition = JSON.parse(localStorage.getItem('app-state')) || myMachine.initialState

  // Use State.create() to restore state from a plain object
  const previousState = State.create(stateDefinition);

  // Use machine.resolveState() to resolve the state definition to a new State instance relative to the machine
  const resolvedState = myMachine.resolveState(previousState);

  -----------------------------------------------

  Utilizzare per messaggi relativi allo stato --> riportare una riga di stato (nascosta da impostazioni)
      meta: {
        message: 'Loading...'
      }


  Wildcard event descriptors ("*")
*/

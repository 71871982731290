<template>
  <van-cell-group>
  </van-cell-group>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    sendToMachine(actionType, payload) {
      const event = {
        type: actionType,
        value: payload
      }
      this.$emit('send-event', event)
    }
  }
}
</script>

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupCercaDocumento main-popup-cerca-documento"
      @open="onOpen"
      @click-overlay="closePopup"
      >
      <h2 class="text-center">{{titolo}}</h2>
      <popup-list-result class="result-popup-cerca-documento"/>
      <van-button type="default" @click="onAggiorna">Aggiorna</van-button>
    </van-popup>
</template>

<script>
// import _ from 'lodash'
import moment from 'moment'
import documenti from '@/services/documentiService'
import PopupListResult from '@/components/popup/ListResult'
import { dynamicSortMultiple } from '@/components/helpers'
export default {
  components: {
    PopupListResult
  },
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      activeTab: 'popup',
      activeParent: 'documenti',
      activeField: 'documento',
      titolo: 'Seleziona il DDT di cui effettuare il riscontro',
      tipiDocumento: ['ddtFornitore'] // TODO: Passare eventualmente nella chiamata
    }
  },
  mounted() {
    this.onOpen()
  },
  computed: {
    listResult() {
      return this.$store.getters.listResult
    }
  },
  watch: {
    async show(value) {
      if (value) {
        await this.getElencoDocumenti(this.tipiDocumento)
      }
    },
    listResult(payload) {
      if (payload.parent !== this.activeParent || payload.tab !== this.activeTab || !payload.result) return
      console.log(payload)
      const event = {
        type: 'USA_SELEZIONE',
        value: 'RISCONTRO_DDT',
        payload: {
          code: payload.result.codice
        }
      }
      this.$emit('send-event', event)
      // debugger
      // if (payload.parent !== this.activeParent || payload.tab !== this.activeTab || !payload.result) return
      // const item = payload.result

      /*       this.prodottoItem = item
      this.$store.commit('prodotti/SET_PRODOTTO', this.prodottoItem)
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      console.log(item)
      this.aggiungiAlCarrello(item.codice || item.codiceProdotto) */
    }
  },
  methods: {
    async onAggiorna() {
      await this.getElencoDocumenti(this.tipiDocumento)
    },
    getStilePopup() {
      var cont = document.querySelector('.tab-popup-cerca-documento')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    async getElencoDocumenti(tipi) {
      const filtri = {
        tipoDocumento: tipi
        // TODO: Eventuali date
      }
      const options = {
        page: 1,
        itemsPerPage: 50
      }
      const data = await documenti.getFilteredDocumentHeaders(filtri, options)
      let docs = data && data.total_rows > 0 ? data.rows : []
      if (docs.length > 0) {
        docs = docs.sort(dynamicSortMultiple('-_id'))
        const lista = docs.map(item => {
          return {
            codice: item._id,
            titolo: `${item._id} - ${moment(item.data).format('DD/MM/YYYY')}`,
            label: `${item.anagrafica.fornitore.descrizione} - doc. ${item.info?.numeroDocumento || ''}`,
            value: item.righe?.length || ''
          }
        })
        this.$store.commit('SET_SOURCE_POPUP_LIST_RESULT', {
          list: lista,
          icon: 'records',
          classes: {
            titleClass: 'prodotti-lr-title',
            labelClass: 'prodotti-lr-label',
            valueClass: 'prodotti-lr-value'
          }
        })
      } else {
        console.log('getElencoDocumenti noresult')
        this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      }
    },
    onFocus(event) {
      // console.log('onfocus')
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.activeField = event.currentTarget.name
      /*       const valore = String(this[event.currentTarget.name])
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore) */
    },
    async onSubmit(values) {
      // this.clearFields()
    },
    onOpen() {
      // this.clearSearchInput()
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      /*       this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT') */
      this.$store.commit('SET_EXTERNAL_TAB', this.activeParent)
      this.$store.commit('SET_INTERNAL_TAB', this.activeTab)
    },
    /*     clearSearchInput() {
      this.prodotto = ''
    },
    clearFields() {
      this.clearSearchInput()
    }, */
    closePopup() {
      this.$emit('close')
      this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '')
    }
    /*     aggiungiAlCarrello(codice) {
      const event = {
        type: 'PRODOTTO_CERCA',
        value: {
          code: codice,
          tipoAliquota: 'banco'
        }
      }
      this.$emit('send-event', event)
    } */
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .text-center {
    text-align: center;
  }
/*   .result-popup-cerca-documento {
    margin-top: 70px;
  }
 */
/*   .swipe-cell-button {
    height: 100%;
  }
  .van-search .van-cell {
    padding: 10px 16px;
  }

  .van-search--show-action {
      padding-right: 12px;
  } */

  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupCercaDocumento, .main-popup-cerca-documento {
      width: 85%;
      height: 100%;
    }
  }
  @media all and (min-width:1024px) {
    .popupCercaDocumento, .main-popup-cerca-documento {
      width: 65%;
      height: 100%;
    }
  }
  @media all and (min-width:1400px) {
    .main-popup-cerca-documento {
      width: 40%;
      height: 100%;
    }
  }
</style>

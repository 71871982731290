<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" :campi="campi"
    :config="config"
  ></modale-form>
</template>

<script>
import ModaleForm from '../ModaleForm'
import clienti from '@/services/clientiService.js'
import configurazioni from '@/services/configurazioniService.js'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      clienteInModifica: null,
      titoloModale: 'Cerca Cliente',
      campi: [],
      config: { hideBtnSalva: true, hideScrollbar: true }
    }
  },
  async created() {
    const configModale = await configurazioni.getConfigurazione('display_elementi', 'elementi')

    if (configModale && configModale.clienti) {
      this.config = configModale.clienti.ricerca ? configModale.clienti.ricerca.modale || {} : {}
    }

    this.campi = [{
      campo: 'test',
      tipo: 'ricerca',
      label: 'Cerca Cliente',
      placeHolder: 'Cliente',
      validations: [],
      ricerca: {
        ricercaMethod: this.getElencoClientiMethod(),
        descrizione: this.getDescrizioneElementoRicerca(),
        bottoni: [{
          testo: 'Dettaglio',
          onClick: this.getDettagliClienteBtnClicked()
        }, {
          testo: 'Modifica',
          onClick: this.getModificaClienteBtnClicked()
        }, {
          testo: 'Seleziona',
          onClick: this.getSelezionaClienteBtnClicked()
        }, {
          testo: 'Fidelity',
          onClick: this.getFidelityClienteBtnClicked()
        }]
      }
    }]
  },
  methods: {
    annulla() {
      this.$emit('close')
    },
    getElencoClientiMethod() {
      var method = async (testo) => {
        var result = await clienti.searchClienti(testo)
        return result ? result.docs || [] : []
      }
      return method
    },
    getDescrizioneElementoRicerca() {
      return (item) => {
        return item.codice + ' - ' + item.ragioneSociale
      }
    },
    getDettagliClienteBtnClicked() {
      var self = this
      return (item) => {
        var event
        if (this.config.usaVersioneBackoffice) {
          event = {
            type: 'DISPLAY_BACKOFFICE',
            value: {
              contesto: 'dettaglioCliente',
              idCliente: item._id
            }
          }
        } else {
          event = {
            type: 'APRI_MODALE',
            value: 'DettaglioCliente',
            payload: {
              nuovoCliente: { cliente: item }
            }
          }
        }
        self.$emit('close')
        self.$emit('send-event', event)
      }
    },
    getModificaClienteBtnClicked() {
      var self = this
      return (item) => {
        console.log('APRI CLIENTE')
        console.log(item)
        var event = {
          type: 'APRI_MODALE',
          value: item.infoRidotte ? 'NuovoClienteFidelity' : 'NuovoCliente',
          payload: { // #### Nicola
            nuovoCliente: { clienteInModifica: item }
          }
        }
        self.$emit('close')
        self.$emit('send-event', event)
      }
    },
    getModificaClienteBtnClickedOld() {
      var self = this
      return (item) => {
        var event = {
          type: 'APRI_MODALE',
          value: 'NuovoCliente',
          // delay: 600,
          payload: { // #### Nicola
            nuovoCliente: { clienteInModifica: item }
          }
        }
        // self.context.nuovoCliente = { clienteInModifica: item } // Context è una props ed è in sola lettura. Non è corretto modificarla. Ho aggiunto payload all'evento
        // self.$emit('send-event-delay', event)
        self.$emit('close')
        self.$emit('send-event', event)
      }
    },
    getSelezionaClienteBtnClicked() {
      var self = this
      return (item) => {
        var obj = {
          codice: item.codice,
          descrizione: item.ragioneSociale,
          label: ' ',
          titolo: item.codice + ' - ' + item.ragioneSociale,
          value: ''
        }
        const event = {
          type: 'SELEZIONA_CLIENTE',
          value: obj
        }
        self.$emit('send-event', event)
        self.$store.commit('clienti/SET_CLIENTE', obj)
        setTimeout(() => {
          self.$emit('close')
        }, 100)
      }
    },
    getFidelityClienteBtnClicked() {
      var self = this
      return (item) => {
        console.log('CLIENTE_FIDELITY')
        console.log(item)
        var event = {
          type: 'CLIENTE_FIDELITY',
          value: item,
          payload: {
            nuovoCliente: { clienteInModifica: item }
          }
        }
        self.$emit('close')
        self.$emit('send-event', event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <van-popup :value="popVisible()" :closeable="false" :close-on-click-overlay="false" class="popup-accesso" @open="onOpen">
    <van-field v-model="pinValue" type="password" :label="isBloccoCassa ? 'Pin sblocco' : 'Pin accesso'" @input="verificaUtente" class="field-accesso" @focus="onFocus"/>
    <numeric-keyboard class="keyboard-accesso" enterText="OK" :keyboardClass="'kb_accesso'"/>
  </van-popup>
</template>

<script>
import _ from 'lodash'
import configurazioni from '@/services/configurazioniService'
import NumericKeyboard from '@/components/popup/NumericKeyboard'

export default {
  components: {
    NumericKeyboard
  },
  props: {
    context: Object
  },
  data() {
    return {
      show: false,
      pinValue: '',
      elencoOperatori: [],
      activeField: 'pinValue'
    }
  },
  created() {
    this.debounceAccesso = _.debounce(this.verificaUtente, 500)
  },
  mounted() {
    this.elencoOperatori = configurazioni.getOperatori('accesso')
    this.onOpen()
  },
  computed: {
    kbInput () {
      const res = this.$store.getters.popupKeyboardInput
      return res.key
    },
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    },
    isBloccoCassa() {
      return this.context && this.context.bloccaCassa
    }
  },
  watch: {
    kbInput(value) {
      if (this.show) {
        const variabile = this.activeField
        const valore = String(value)
        this[variabile] = valore
        this.handleKey(valore)
      }
    },
    kbKeyPress(value) {
      if (this.show) {
        if (value === '{esci}') {
          this.debounceAccesso()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    },
    kbCounter(value) {
      if (this.show) {
        if (this.kbKeyPress === '{esci}') {
          this.debounceAccesso()
        } else {
          this.handleKey(this.kbInput)
        }
      }
    }
  },
  methods: {
    handleKey(valore) {
      this.pinValue = valore
      this.debounceAccesso()
    },
    onOpen() {
      this.pinValue = ''
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD')
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
    },
    onFocus(event) {
      this.$store.commit('CLEAR_SOURCE_POPUP_LIST_RESULT')
      this.activeField = event.currentTarget.name
      const valore = String(this[event.currentTarget.name])
      console.log('valore', valore)
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', valore)
    },
    popVisible() {
      let show = false
      if (this.context && this.context.impostazioni && this.context.operatore && this.context.impostazioni.operatori) {
        show = this.context.impostazioni.operatori.obbligatorio && !this.context.operatore.accesso
      }
      if (this.context && this.context.bloccaCassa) {
        show = true
      }
      if (this.context.richiediPin) {
        show = true
      }
      this.show = show
      return show
    },
    async verificaUtente() {
      // cerca utente in base a pin (non è la password !!)
      // Per velocità potrei leggere gli operatori con i relativi pin, per sicurezza deve essere il server a verificare
      // senza ritornare l'elenco dei pin !!

      if (!this.pinValue) return
      if (this.context.bloccaCassa) {
        if (this.pinValue === this.context.impostazioni.cassa.pinSblocco) {
          const event = {
            type: 'BLOCCA_CASSA',
            value: false
          }
          this.$emit('send-event', event)
          return
        }
      }
      var utente = await configurazioni.getOperatore(this.pinValue)
      if (utente) {
        utente.livello = utente.livello || 'user'
        const event = {
          type: 'SELEZIONA_OPERATORE',
          value: {
            tipo: 'accesso',
            item: utente
            /*  item: {
              nome: utente.nome,
              codice: utente.codice,
              livello: utente.livello || 'user'
            } */
          }
        }
        this.$emit('send-event', event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  /* impostare in base a media query */

  .popup-accesso {
    width: 18vw;
    height: 41vh;
  }

  @media all and (max-width: 1400px) {
    .popup-accesso {
      width: 23vw;
      height: 50vh;
    }
  }

  @media all and (max-width: 1024px) {
    .popup-accesso {
      width: 35vw;
      height: 50vh;
    }
  }

  .field-accesso {
    margin-bottom: 10px;
  }
</style>

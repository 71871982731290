'use strict'
import moment from 'moment'

const infoStampante = function(printerStatus) {
  if (!printerStatus || printerStatus.error || (printerStatus.response && printerStatus.response.error)) {
    const errorMessage = printerStatus.error ? printerStatus.error.message : 'Dispositivo non trovato'
    const errorCode = printerStatus.error ? printerStatus.error.code : '--'
    return {
      matricola: '--',
      data: '--',
      firmware: '--',
      liberoDGFE: '--',
      modo: '--',
      stato: '--',
      lastZ: '--',
      lastDocF: '--',
      errore: errorMessage,
      errCode: errorCode,
      filePendenti: '--',
      oldFile: '',
      lowDGFE: false
    }
  } else {
    const request = printerStatus.response
    return {
      matricola: printerStatus.matricola,
      data: moment(printerStatus.data).format('DD/MM/yyyy HH:mm'),
      firmware: printerStatus.firmware,
      liberoDGFE: printerStatus.spazioDGFE,
      modo: request.ECRStatus ? request.ECRStatus.mode : 'REG',
      stato: printerStatus ? printerStatus.filePendenti.inattiva ? 'Inattiva' : 'Attiva' : '',
      lastZ: request.lastZ,
      lastDocF: request.lastDocF,
      errore: '',
      errCode: '',
      filePendenti: `${printerStatus.filePendenti.numero} / ${printerStatus.filePendenti.fileTotali}`,
      oldFile: printerStatus.dataFilePiuVecchio || '',
      lowDGFE: parseInt(printerStatus.spazioDGFE.replace('%', '')) <= 10
    }
  }
}

const mostraAggiornaOra = function(printerStatus) {
  if (printerStatus && printerStatus.data) {
    const diff = moment().diff(moment(printerStatus.data), 'm')
    return Math.abs(diff) > 2
  }
  return false
}

const mostraCambioStato = function(infoStampante) {
  return infoStampante && infoStampante.stato === 'Inattiva'
}

const mostraCambioModo = function(infoStampante) {
  return infoStampante && infoStampante.errCode === 'STATUS_ERROR'
}

const passaModoReg = function() {

}

const aggiornaOraPossibile = function(infoStampante) {
  return infoStampante.response.lastDocF === '0'
}

export default {
  infoStampante,
  mostraAggiornaOra,
  mostraCambioStato,
  mostraCambioModo,
  passaModoReg,
  aggiornaOraPossibile
}

<template>
    <van-popup
      :value="show"
      position="left"
      :close-on-click-overlay="true"
      class="popupFunzioni main-popup-funzioni"
      @opened="openPopup"
      @click-overlay="closePopup"
      >
      <van-tabs v-model="activeName" @change="onChangeTab" :lazy-render="false" class="tab-popup-funzioni">
        <van-tab title="Clienti" name="clienti">
          <clienti :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="clienti"/>
        </van-tab>
        <van-tab title="Prodotti" name="prodotti">
          <prodotti :context="context" :skInput="activeName === 'prodotti' ? skInput : ''" @send-event="sendEventToParent" @close-popup="closePopup" ref="prodotti"/>
        </van-tab>
        <van-tab title="Movimenti" name="movimenti">
          <movimenti :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="movimenti"/>
        </van-tab>
        <van-tab title="Documenti" name="documenti">
          <documenti :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="documenti"/>
        </van-tab>
        <van-tab title="Ordini" name="ordini">
          <ordini :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="ordini"/>
        </van-tab>
        <van-tab title="Statistiche" name="statistiche">
          <statistiche :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="statistiche"/>
        </van-tab>
        <van-tab title="Utility" name="utility">
          <utility :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="utility"/>
        </van-tab>
        <van-tab title="Settings" name="settings">
          <settings :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="settings"/>
        </van-tab>
      </van-tabs>
      <popup-list-result class="result-popup-funzioni"/>
      <popup-json-editor class="jsoneditor-popup-funzioni"/>
      <simple-popup-keyboard class="keyboard-popup-funzioni" :enterText="$store.getters.enterKeyTitlePopupKeyboard" :keyboardClass="'kb_funzioni'" />
      <div class="command-popup-funzioni">
        <van-button @click="tornaAllaCassa" type="info">Torna alla Cassa</van-button>
      </div>
    </van-popup>
</template>

<script>
/* eslint-disable */
import Clienti from './clienti/clienti'
import Prodotti from './prodotti/prodotti'
import Movimenti from './movimenti/movimenti'
import Documenti from './documenti/documenti'
import Ordini from './ordini/ordini'
import Statistiche from './statistiche/statistiche'
import Utility from './utility/utility'
import Settings from './settings/settings'
import SimplePopupKeyboard from '@/components/popup/SimpleKeyboard'
import PopupListResult from '@/components/popup/ListResult'
import PopupJsonEditor from '@/components/popup/JsonEditor'
import { tornaAllaCassa } from './helpers/tornaAllaCassa.js'

export default {
  components: {
    Clienti,
    Prodotti,
    Movimenti,
    Documenti,
    Ordini,
    Statistiche,
    Utility,
    Settings,
    SimplePopupKeyboard,
    PopupListResult,
    PopupJsonEditor
  },
  mixins: [tornaAllaCassa],
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      moduli: ['clienti', 'prodotti', 'movimenti', 'documenti', 'ordini', 'statistiche'],
      activeName: 'clienti',
      skInput: ''
    }
  },
  mounted() {
    // this.$store.commit('SET_EXTERNAL_TAB', this.activeName)
  },
  computed: {
    kbKeyPress() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.key
    },
    kbCounter() {
      const res = this.$store.getters.keyPressPopupKeyboard
      return res.count
    }
  },
  watch: {
    kbKeyPress(value) {
      if (value === '{esci}' && this.show) {
        this.closePopup()
      }
    },
    kbCounter(value) {
      if (this.kbKeyPress === '{esci}' && this.show) {
        this.closePopup()
      }
    }
  },
  methods: {
    onChangeTab(name) {
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT')
      this.$store.commit('SET_EXTERNAL_TAB', name)
      // imposta anche la tab attiva del componente tabs interno
      this.setInternalTab()
      this.moduli.forEach(modulo => {
        this.childOpen(modulo)
      })
      console.log('onChangeTab esterna', name, this.activeName)
    },
    setInternalTab() {
      const refM = this.$refs[this.activeName]
      if (refM) {
        const intName = refM.activeName
        this.$store.commit('SET_INTERNAL_TAB', intName)
      }
    },
    closePopup() {
      // this.$store.commit('HIDE_POPUP_KEYBOARD')
      // this.$store.commit('HIDE_POPUP_LIST_RESULT')
      this.$emit('close')
    },
    openPopup() {
      this.$store.commit('CLEAR_KEY_PRESS_POPUP_KEYBOARD') // TODO: Verificare
      this.$store.commit('CLEAR_POPUP_KEYBOARD_INPUT') // TODO: Verificare

      this.$store.commit('SET_EXTERNAL_TAB', this.activeName)
      this.setInternalTab()

      // this.$store.commit('SHOW_POPUP_KEYBOARD')
      // this.$store.commit('SHOW_POPUP_LIST_RESULT')
      this.moduli.forEach(modulo => {
        this.childOpen(modulo)
      })
    },
    childOpen(nome) {
      console.log('childOpen esterno:', nome)
      const refM = this.$refs[nome]
      if (refM.onOpen) {
        refM.onOpen()
      }
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../popup.scss";
  @import "./style.scss";

  .swipe-cell-button {
    height: 100%;
  }

/*   [name = PopupFunzioni] .van-tabs {
    margin-top: 30px;
  } */
  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .popupFunzioni, .main-popup-funzioni {
      width: 95%;
      height: 100%;
    }
  }
  @media all and (min-width:1024px) {
    .popupFunzioni, .main-popup-funzioni {
      width: 95%;
      height: 100%;
    }
  }
  @media all and (min-width:1400px) {
/*     .popupFunzioni{
      width: 35%;
      height: 100%;
    } */
    .main-popup-funzioni {
      width: 80%;
      height: 100%;
    }
  }
</style>

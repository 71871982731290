<template>
  <div ref="cnt" :style="slotPosition" class="planningSlot" :class="cmpAspect" @click="clickApt">
    <div v-if="apt">
      <div class="areaNuovoApt" v-if="!modalitaSelezione && this.aspect.includes('occupato-1')" :style="{ height: (cntHeight -2)+ 'px' }" ></div>
      <div class="cliente" @click.stop="clickCliente">{{apt.cliente.ragioneSociale}}</div>
      <template v-if="apt.stato === 'occupato'">
        <div v-for="(pr, index) in apt.prodotti" :key="index">
          {{pr.descrizione}}
        </div>
      </template>
    </div>
    <div v-else class="slotNoApt">
      <div>{{ testo }}</div>
    </div>
    <div v-if="aspect === 'intestazione' && btnScorriSinistra" class="btnScorriAgenda btnLeft van-icon van-icon-arrow-left" @click.stop="scorriAgenda(-1)"></div>
    <div v-if="aspect === 'intestazione' && btnScorriDestra" class="btnScorriAgenda btnRight  van-icon van-icon-arrow" @click.stop="scorriAgenda(+1)"></div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      cntHeight: 0
    }
  },
  props: {
    apt: Object,
    agenda: String,
    orario: String,
    lavoro: Number,
    attesa: Number,
    testo: String,
    aspect: String,
    zIndex: Number,
    row: { type: Number, required: true },
    col: { type: Number, required: true },
    row_span: { type: Number, default: 1 },
    col_span: { type: Number, default: 1 },
    btnScorriSinistra: Boolean,
    btnScorriDestra: Boolean,
    modalitaSelezione: Boolean,
    aptSelezionato: Object
  },
  computed: {
    slotPosition() {
      const area = `grid-area: ${this.row} / ${this.col} / span ${this.row_span} / span ${this.col_span};` + (this.zIndex ? `z-index: ${this.zIndex};` : '')
      let color = ''
      if (this.row !== 1 && this.col !== 1 && this.apt) {
        const lavoro = Math.round((this.apt.lavoro / this.apt.span) * 100)
        const attesa = 100 - lavoro
        const colori = ['2196f3', 'ff9800', '4caf50', 'af4c7d']
        const indice = parseInt(this.aspect.slice(-1)) || 1
        const coloreLavoro = colori[indice - 1]
        const colorePausa = coloreLavoro + '80' // trasparenza 50%
        color = `background: linear-gradient(to bottom, #${coloreLavoro} ${lavoro}%, #${colorePausa} ${attesa}%);`
      }
      return area + color
    },
    cmpAspect() {
      return this.aspect + ((this.apt && this.apt.item.stato === 'chiuso') ? ' ' + 'chiuso' : '') +
        (this.aptSelezionato && this.apt && this.aptSelezionato.item._id === this.apt.item._id ? ' aptSelezionato' : '')
    },
    getNuovoAptAreaHeight() {
      return 'height:' + this.cntHeight + 'px'
    }
  },
  watch: {
    aspect() {
      var self = this
      setTimeout(() => {
        self.cntHeight = self.$refs.cnt.offsetHeight
      }, 150)
    }
  },
  methods: {
    async clickApt(e) {
      // console.log(this.apt.item._id, this.apt.item.prodotti.length)
      if (this.aspect === 'orario') {
        return
      }
      if (this.modalitaSelezione) {
        this.$emit('sposta-apt', { apt: this.apt, orario: this.getOrarioClickPoint(e, true), agenda: this.agenda })
        return
      }

      var appuntamento
      let isNuovo = true
      if (e.target.classList.contains('areaNuovoApt')) {
        appuntamento = {
          agenda: this.agenda,
          orarioInizio: moment(this.getOrarioClickPoint(e), 'DD/MM/YYYY HH:mm').toISOString()
        }
      } else if (this.col === 1) {
        appuntamento = {
          orarioInizio: moment(this.orario, 'DD/MM/YYYY HH:mm').toISOString()
        }
      } else if (this.row === 1) {
        appuntamento = {
          agenda: this.agenda,
          orarioInizio: moment(this.orario, 'DD/MM/YYYY HH:mm').toISOString()
        }
      } else if (this.apt) {
        const apt = this.apt
        appuntamento = apt.item // verificare
        isNuovo = false
      } else {
        appuntamento = {
          agenda: this.agenda,
          orarioInizio: moment(this.orario, 'DD/MM/YYYY HH:mm').toISOString()
        }
      }
      appuntamento.data = moment(this.orario, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
      this.$store.commit('appuntamenti/SET_APPUNTAMENTO', { payload: appuntamento, nuovo: isNuovo })
      this.apriAppuntamento({ appuntamento: appuntamento, nuovo: isNuovo })
    },
    clickCliente() {
      this.$emit('seleziona-apt', this.apt)
    },
    async clickApt_old() {
      // Se col = 1 imposto l'orario e scelgo l'agenda
      // se row = 1 imposto l'agenda e scelgo l'orario
      // if (this.row === 1 || this.col === 1) return
      if (this.col === 1) {
        this.setCliente(null) // verificare... perdo il cliente se selezionato da pagina principale
        this.setAppuntamento({
          orarioInizio: moment(this.orario, 'DD/MM/YYYY HH:mm').toISOString()
        })
      } else if (this.row === 1) {
        this.setCliente(null) // verificare... perdo il cliente se selezionato da pagina principale
        this.setAppuntamento({
          agenda: this.agenda,
          orarioInizio: moment(this.orario, 'DD/MM/YYYY HH:mm').toISOString()
        })
      } else if (this.apt) {
        const apt = this.apt
        this.setAppuntamento(apt.item)
      } else {
        this.setCliente(null) // verificare... perdo il cliente se selezionato da pagina principale
        this.setAppuntamento({
          agenda: this.agenda,
          orarioInizio: moment(this.orario, 'DD/MM/YYYY HH:mm').toISOString()
        })
      }
      this.apriAppuntamento()
    },
    setCliente(cliente) {
      const event = {
        type: 'SELEZIONA_CLIENTE',
        value: cliente
      }
      this.$emit('send-event', event)
    },
    setAppuntamento(appuntamento) {
      const event = {
        type: 'SELEZIONA_APPUNTAMENTO',
        value: appuntamento
      }
      this.$emit('send-event', event)
    },
    apriAppuntamentoOld() {
      const event = {
        type: 'APRI_POPUP',
        value: 'Appuntamento'
      }
      this.$emit('send-event', event)
    },
    apriAppuntamento(objApt) {
      this.$emit('apri-appuntamento', objApt)
      /* var ev = {
        type: 'EDIT_CONTEXT',
        appuntamento: {
          elVue: this.currTasto,
          modelItem: this.currTasto.ts,
          zona: this.currZona.zona,
          modaleConfig: modaleConfig,
          configZona: configZona,
          configLayout: this.configLayout
        }
      }
      this.$emit('send-event', ev)

      const event = {
        type: 'APRI_MODALE',
        value: 'Appuntamento'
      }
      this.$emit('send-event', event) */
    },
    scorriAgenda(count) {
      this.$emit('scorri-agenda', count)
    },
    getOrarioClickPoint(e, checkParent) {
      var par = e.currentTarget.parentElement
      var slotHeight = par.getElementsByClassName('planningSlot orario')[1].offsetHeight
      var y = !checkParent || e.target.parentElement.classList.contains('mainPlanning') ? e.offsetY
        : e.target.getBoundingClientRect().top - e.currentTarget.getBoundingClientRect().top
      var slotsToAdd = parseInt(y / slotHeight)
      var newOrario = this.apt ? moment(this.orario, 'DD/MM/YYYY HH:mm').add(15 * slotsToAdd, 'minutes').format('DD/MM/YYYY HH:mm') : this.orario
      return newOrario
    }
  }
}
</script>

<style lang="scss" scoped>
  .planningSlot {
    background-color: burlywood;
  }

  .cliente {
    float:right; /* se vale la pena ... */
  }

  .btnScorriAgenda {
    position:absolute;
    width: 3vw;
    height:30px;
    top: 0;
    transform: unset !important;
    text-align: center;
    font-size: 20px;
    padding-top: 4px;
  }

  .btnScorriAgenda.btnLeft{
    left: 0;
  }

  .btnScorriAgenda.btnRight{
    right: 0;
  }

  .intestazione, .orario {
    position: relative;
    /* & div { */
    & .slotNoApt {
      text-align: center;
      margin: 0;
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .intestazione {
    background-color: #dfe0e1;
    border: 1px solid #dfe0e1;
    color: #677280;
  }
  .orario {
    background-color: #f3f4f5;
    border: 1px solid #dfe0e1;
    color: #677280;
  }

  .planning-libero {
    background-color: #ffffff;
    border: 1px solid #eeeeee;
  }

  .planning {
    margin-right: 10px;

    border-color:white;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 7px;
    box-sizing: border-box;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;

    &.occupato-1 {
      background-color: #2196f3;
      // width: calc(100% - 2px);
      width: calc(100% - 33px);
      margin-left: 33px;
    }
    &.occupato-2 {
      background-color: #ff9800;
      margin-left: 40px;
      width: calc(100% - 42px);
      // margin-left: 15px;
      // width: calc(100% - 17px);
      z-index: 1;
    }
    &.occupato-3 {
      background-color: #4caf50;
      margin-left: 50px;
      width: calc(100% - 52px);
      // margin-left: 30px;
      // width: calc(100% - 32px); /*  margine a sx per non sovrapporsi al primo, margine a dx per non sovrapporsi al secondo */
      z-index: 2;
    }
    &.occupato-4 {  /* blocco di destra nel caso di apt corrispondenti */
      background-color: #af4c7d;
      margin-left: 60px;
      width: calc(100% - 62px);
      // margin-left: 45px;
      // width: calc(100% - 47px);
      z-index: 3;
    }
    &.chiuso {
      background: none !important;
      background-color: #acacac !important;
    }
  }

  /* non usati se mattonelle sovrapposte */
  .planning-lavoro {
    background-color: #de9b87;
  }
  .planning-attesa {
    background-color: #d8de87;
  }

  .aptSelezionato {
    border-width: 5px;
    animation: changeColorAptSelezionato 0.8s infinite alternate;
  }

  @keyframes changeColorAptSelezionato{
    0%{
        border-color: #f1f1f1;
    }
    100% {
        border-color: #ff0202;
    }
  }

  .areaNuovoApt {
    position: absolute;
    background-color: white;
    width: 32px;
    margin-left: -42px;
    margin-top: -7px;
    border: 1px solid #eeeeee;
    border-radius: 6px;
  }

  // .areaNuovoApt {
  //   position: absolute;
  //   background-color: white;
  //   width: 40px;
  //   opacity: 0.3;
  //   margin-left: -7px;
  //   margin-top: -7px;
  // }

</style>

'use strict'
// const service = process.env.VUE_APP_service_scontrini || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)
var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_scontrini || process.env.VUE_APP_service_scontrini
})()

async function cicleSync() {
  try {
    const response = await fetch(`${service}/scontrini/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getRunMode() {
  try {
    const response = await fetch(`${service}/scontrini/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'SCONTRINI',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function getAllReceipts() {
  const response = await fetch(`${service}/scontrini`)
  return await response.json()
}

async function elencoScontriniPerData(dataDa, dataA, limit) {
  const data = {
    dataDa,
    dataA,
    limit
  }
  const response = await fetch(`${service}/scontrini/data`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function nuovoScontrino(postazione) {
  console.log('[nuovoScontrino] creazione scontrino')
  var data = {
    postazione: postazione
  }

  const response = await fetch(`${service}/scontrini/create`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data)
  })
  return await response.json()
/*   const response = await fetch(`${service}/scontrini/create`, { method: 'POST' })
  const json = await response.json()
  console.log('[nuovoScontrino] json:', json)
  return json */
}

async function nuovoScontrinoConRighe(righe, postazione) {
  var data = {
    righe,
    postazione: postazione
  }
  const response = await fetch(`${service}/scontrini/create`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function addRigaScontrino(idScontrino, riga) {
  const response = await fetch(`${service}/scontrini/${idScontrino}/add`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(riga)
  })
  return await response.json()
}

async function addRigheScontrino(idScontrino, righe) {
  const response = await fetch(`${service}/scontrini/${idScontrino}/add/rows`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(righe)
  })
  return await response.json()
}

async function updateRigaScontrino(idScontrino, idRiga, riga) {
  const response = await fetch(`${service}/scontrini/${idScontrino}/update/${idRiga}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(riga)
  })
  return await response.json()
}

async function updateRigheScontrino(idScontrino, righe) {
  const response = await fetch(`${service}/scontrini/${idScontrino}/update/rows`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(righe)
  })
  return await response.json()
}

async function deleteRigaScontrino(idScontrino, idRiga) {
  console.log('deleteRigaScontrino', idRiga)
  const response = await fetch(`${service}/scontrini/${idScontrino}/delete/${idRiga}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function deleteRigaScontrinoByCode(idScontrino, codiceRiga) {
  console.log('deleteRigaScontrinoByCode', codiceRiga)
  const response = await fetch(`${service}/scontrini/${idScontrino}/delete/code/${codiceRiga}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function addTotaleScontrino(idScontrino, righeScontrino, infoChiusuraScontrino) {
  // in base al contenuto di infoChiusuraScontrino richiamo più volte la add riga ...

  await updateRigheScontrino(idScontrino, righeScontrino)
/*   debugger
  let rigaDaAggiungere = infoChiusuraScontrino.resto ? 4 : 2
  while (rigaDaAggiungere) {
    await addRigaScontrino(idScontrino, righeScontrino[righeScontrino.length - rigaDaAggiungere])
    rigaDaAggiungere--
  } */
}

async function cancellaScontrino(idScontrino) {
  const response = await fetch(`${service}/scontrini/${idScontrino}/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function aggiornaFiscaleScontrino(idScontrino, info, infoChiusura) {
  // Aggiungere anche eventuali arrotondamenti ?
  const data = {
    fiscale: info,
    pagamento: { pagamenti: infoChiusura.pagamenti, totale: infoChiusura.totale, resto: infoChiusura.resto },
    stato: infoChiusura.stato
    // statoPagamento: ....  // TODO gestire per credito o fattura ...
  }
  if (infoChiusura.fidelity) {
    data.fidelity = infoChiusura.fidelity
  }
  if (infoChiusura.documento) {
    data.documento = infoChiusura.documento
  }
  if (infoChiusura.cashmatic) {
    data.cashmatic = infoChiusura.cashmatic
  }
  const response = await fetch(`${service}/scontrini/${idScontrino}/fiscale`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function aggiornaDocumentoScontrino(idScontrino, documento, nonFiscali) {
  const data = {
    documento,
    nonFiscali
  }

  const response = await fetch(`${service}/scontrini/${idScontrino}/documento`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function getScontrino(barcode) {
  const response = await fetch(`${service}/scontrini/barcode/${barcode}`)
  return await response.json()
}

async function getScontrinoById(id) {
  const response = await fetch(`${service}/scontrini/${id}`)
  return await response.json()
}

async function resiSuScontrino(printerResi) {
  const idScontrino = printerResi.righe[0].payload.fiscale.idScontrino // ID scontrino Origine
  var righe = printerResi.righe
  righe.forEach(riga => {
    delete riga.payload
  })
  const reso = {
    fiscale: printerResi.fiscale,
    righe
  }
  const response = await fetch(`${service}/scontrini/${idScontrino}/reso`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reso)
  })
  return await response.json()
}

async function aggiornaAnnullamentoFiscaleSuOrigine(idScontrino, info) {
  console.log('aggiornaAnnullamentoFiscaleSuOrigine', idScontrino, info)
  const response = await fetch(`${service}/scontrini/${idScontrino}/annullamento`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(info)
  })
  return await response.json()
}

// verificare prima di utilizzare... non testato
async function duplicaContenutoScontrino(idScontrino) {
  const response = await fetch(`${service}/scontrini/${idScontrino}/duplica`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

async function getParcheggiati() {
  const response = await fetch(`${service}/scontrini/parking`)
  return await response.json()
}

async function updateScontrino(idScontrino, dati) {
  const response = await fetch(`${service}/scontrini/${idScontrino}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dati)
  })
  return await response.json()
}

async function updateScontrinoChangeDate(idScontrino, dati, istante) {
  const data = {
    dati,
    istante
  }
  const response = await fetch(`${service}/scontrini/${idScontrino}/change`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function annullaParcheggio(idScontrino) {
  const response = await fetch(`${service}/scontrini/${idScontrino}/parking/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
  return await response.json()
}

export default {
  cicleSync,
  getRunMode,
  getAllReceipts,
  elencoScontriniPerData,
  addRigaScontrino,
  addRigheScontrino,
  updateScontrino,
  updateScontrinoChangeDate,
  updateRigaScontrino,
  updateRigheScontrino,
  deleteRigaScontrino,
  deleteRigaScontrinoByCode,
  addTotaleScontrino,
  nuovoScontrino,
  cancellaScontrino,
  aggiornaFiscaleScontrino,
  aggiornaDocumentoScontrino,
  getScontrino,
  getScontrinoById,
  resiSuScontrino,
  aggiornaAnnullamentoFiscaleSuOrigine,
  duplicaContenutoScontrino,
  nuovoScontrinoConRighe,
  getParcheggiati,
  annullaParcheggio
}

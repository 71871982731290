<template>
  <div>
    <van-popup :value="show" :closeable="false" :close-on-click-overlay="true" class="popup-park" @open="onOpen" @close="close" @click-overlay="close">
      <van-tabs v-model="activeName" @change="onChangeTab" :lazy-render="false" class="tab-modale-park"  type="card">
        <van-tab v-for="zona in zone" :key= "zona.codice" :title="zoneName(zona, triggerRefresh)" :name="zona.codice">
          <div class="main-park">
            <parking
              :context="context"
              :tasti="zona.posizioni"
              :trigger="triggerRefresh"
              @apri-dettaglio="apriDettaglio"
              @send-event="sendEvent"
              @refresh="refreshPark"
            >
            </parking>
          </div>
        </van-tab>
      </van-tabs>
    </van-popup>
    <van-popup :value="showDettaglio" :closeable="false" :close-on-click-overlay="true" class="popup-dettaglio" @close="chiudiDettaglio" @click-overlay="chiudiDettaglio">
      <template v-if="payload">
        <van-cell-group inset :title="payload.tipo.descrizione">
          <van-cell title="Cliente:" :value="payload.cliente.codice + ' - ' + payload.cliente.ragioneSociale" />
          <van-cell title="Data consegna:" :value="payload.dataConsegna | date"/>
          <van-cell title="Note" :value="payload.note" autosize rows="2"/>
        </van-cell-group>
      </template>
      <van-cell-group class="popup-detail-group" inset :title="'Dettaglio conto: ' + descrizioneDettaglio">
        <van-cell v-for="r in righeDettaglio" :key="r.codice" :title="r.descrizione" :value="r.quantita" />
      </van-cell-group>
      <div class="bottom-popup-wrapper">
        <van-row type="flex" justify="space-around">
          <van-button type="primary" plain span="9" @click="apriConto" class="bottom-popup">Apri conto</van-button>
          <van-button type="danger" plain span="9" @click="chiudiDettaglio" class="bottom-popup">Annulla</van-button>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Parking from './parking.vue'
import configurazioni from '@/services/configurazioniService'
import scontrini from '@/services/scontriniService'

export default {
  components: {
    Parking
  },
  props: {
    context: Object
  },
  data() {
    return {
      show: true,
      activeName: null,
      zone: [],
      parcheggiati: [],
      comandi: {
        forzaAperura: false
      },
      showDettaglio: false,
      contoDettaglio: null,
      righeDettaglio: [],
      payload: null,
      triggerRefresh: 1,
      descrizioneDettaglio: ''
    }
  },
  async mounted() {
    this.descrizioneDettaglio = ''
    var result = await configurazioni.getConfigurazione('parcheggio', 'zone')
    if (result.length > 0) {
      this.zone = result
      await this.aggiornaPosizioni()
    } else {
      await this.popolaParcheggio()
    }
    this.onOpen()
  },
  computed: {
    testoNotaGenerica() {
      return this.context.testoNotaGenerica || ''
    }
  },
  watch: {
    async testoNotaGenerica(value) {
      var result = await configurazioni.getConfigurazione('parcheggio', 'zone')
      if (result.length > 0) {
        this.zone = result
        await this.refreshPark()
      }
    }
  },
  methods: {
    async refreshPark() {
      await this.aggiornaPosizioni()
      await this.$nextTick()
      this.triggerRefresh++
    },
    async aggiornaPosizioni() {
      this.parcheggiati = await scontrini.getParcheggiati()
      this.impostaStati()
      const contoAperto = this.parcheggiati.map(x => x.parcheggio).find(x => x.stato === 'aperto')
      if (contoAperto) {
        this.activeName = contoAperto.zona
      }
    },
    zoneName(zona, triggerRefresh) {
      const numConti = zona.posizioni.filter(x => x.payload.stato !== '').length
      return triggerRefresh && `${zona.descrizione}${numConti > 0 ? ' (' + numConti + ')' : ''}`
    },
    impostaStati() {
      for (const item of this.parcheggiati) {
        const zona = item.parcheggio.zona
        const p = item.parcheggio.posizione
        const el = this.zone.find(x => x.codice === zona).posizioni.find(x => x.codice === p)
        if (el) {
          el.payload.stato = item.parcheggio.stato
          el.payload.conto = item._id
        }
      }
    },
    apriDettaglio(conto, descrizione) {
      this.contoDettaglio = conto
      const el = this.parcheggiati.find(x => x._id === conto)
      this.righeDettaglio = el.righe
      this.payload = el.parcheggio.payload
      this.descrizioneDettaglio = descrizione
      this.showDettaglio = true
    },
    chiudiDettaglio() {
      this.showDettaglio = false
    },
    apriConto() {
      const event = {
        type: 'PARCHEGGIO',
        payload: {
          command: 'apre',
          conto: this.contoDettaglio
        }
      }
      this.$emit('send-event', event)
      this.showDettaglio = false
    },
    async popolaParcheggio() {
      const numeroZone = this.context.impostazioni.parcheggio.numeroZone
      const elementiRiga = this.context.impostazioni.parcheggio.default.elementiRiga
      const elementiColonna = this.context.impostazioni.parcheggio.default.elementiColonna
      this.zone = []
      for (let s = 1; s <= numeroZone; s++) {
        const zona = {
          codice: `${s}`,
          descrizione: `S ${s}`,
          posizioni: []
        }
        for (let r = 1; r <= elementiRiga; r++) {
          for (let c = 1; c <= elementiColonna; c++) {
            zona.posizioni.push({
              row: r,
              col: c,
              row_span: 1,
              col_span: 1,
              aspect: '',
              title: `${s}P ${r}-${c}`,
              title2th: '',
              codice: `${s}.${r}.${c}`,
              payload: {
                numero: '',
                stato: '',
                colore: '',
                conto: '',
                data: null
              }
            })
          }
        }
        this.zone.push(zona)
      }
      let conf = await configurazioni.getConfigurazione('parcheggio')
      if (!conf) {
        conf = {
          _id: 'parcheggio',
          zone: this.zone
        }
      } else {
        conf.zone = this.zone
      }
      await configurazioni.creaConfigurazione(conf)
    },
    onOpen() {
    },
    onChangeTab() {
    },
    sendEvent(event) {
      this.$emit('send-event', event)
      /*       setTimeout(async () => {
        await this.aggiornaPosizioni()
        this.triggerUpdate++
      }, 500) */
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" >
  @import "./style.scss";
</style>

<template>
  <div class="servizi-bottom" :class="{ 'dettaglio-espanso': showDettaglio }">
    <hr v-if="!showDettaglio"/>
    <h3 class="servizi-info-center">Stato servizi: {{postazione}}</h3>
    <led v-for="(stato, idx) in statoServizi" :key="idx" :status="stato"/>
    <div class="servizi-dettaglio" @click="onDettaglio">{{ showDettaglio ? 'Chiudi dettaglio' : 'Dettaglio' }}</div>
    <div v-if="showDettaglio" style="clear:both;">
      <dettaglio v-for="(stato, idx) in statoServizi" :key="stato.modulo" :status="stato" :header="idx === 0"/>
    </div>
  </div>
</template>

<script>
import configurazioni from '@/services/configurazioniService.js'
import Led from './led.vue'
import Dettaglio from './dettaglio.vue'
export default {
  components: {
    Led,
    Dettaglio
  },
  data() {
    return {
      showDettaglio: false
    }
  },
  computed: {
    statoServizi() {
      return this.$store.getters.serviceStatus
    },
    postazione() {
      return configurazioni.getPostazione()
    }
  },
  methods: {
    onDettaglio() {
      this.showDettaglio = !this.showDettaglio
      this.$emit('dettaglio', this.showDettaglio)
    }
  }
}
</script>

<style lang="scss">
  .servizi-bottom {
    bottom: 0;
    position: absolute;
    width: 100%;
    min-height: 150px;
  }

  .dettaglio-espanso {
    height: 100%;
  }

  .servizi-info-center {
    text-align: center;
    width: 100%;
  }

  .servizi-dettaglio  {
    bottom: 9px;
    position: absolute;
    right: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
</style>

'use strict'

// const service = process.env.VUE_APP_service_appointments || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)

var config, service
(async () => {
  const response = await fetch(process.env.BASE_URL + 'config.json')
  config = await response.json()
  service = config.service_appointments || process.env.VUE_APP_service_appointments
})()

async function cicleSync() {
  try {
    const response = await fetch(`${service}/appointments/cicleSync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    return null
  }
}

async function getRunMode() {
  try {
    const response = await fetch(`${service}/appointments/mode`)
    const result = await response.json()
    if (result.status === 404) {
      return defaultRunMode()
    }
    return result
  } catch {
    return defaultRunMode()
  }
}

function defaultRunMode() {
  return {
    modulo: 'APPUNTAMENTI',
    locale: '---',
    remote: '---',
    versione: '---',
    health: '---'
  }
}

async function getAppuntamenti(data) {
  const response = await fetch(`${service}/appointments/search?dataUx=${data.valueOf()}`) // convert in millisecondi...
  return await response.json()
}

async function getAppuntamentiCliente(cliente) {
  const response = await fetch(`${service}/appointments/cliente?cliente=${cliente}`)
  return await response.json()
}

async function getAppuntamento(appuntamentoId) {
  const response = await fetch(`${service}/appointments/${appuntamentoId}`)
  return await response.json()
}

async function creaAppuntamento(appuntamento) {
  const response = await fetch(`${service}/appointments/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(appuntamento)
  })
  return await response.json()
}

async function modificaAppuntamento(appuntamentoId, appuntamento) {
  const response = await fetch(`${service}/appointments/${appuntamentoId}/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(appuntamento)
  })
  return await response.json()
}

async function cancellaAppuntamento(appuntamentoId) {
  const response = await fetch(`${service}/appointments/${appuntamentoId}/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
    // body: JSON.stringify(appuntamento)
  })
  return await response.json()
}

async function aggiornaInfoAcquisto(appuntamentoId, infoAcquisto) {
  const response = await fetch(`${service}/appointments/${appuntamentoId}/update/acquisto`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(infoAcquisto)
  })
  return await response.json()
}

export default {
  cicleSync,
  getRunMode,
  getAppuntamenti,
  getAppuntamentiCliente,
  getAppuntamento,
  creaAppuntamento,
  modificaAppuntamento,
  cancellaAppuntamento,
  aggiornaInfoAcquisto
}

<template>
  <div class="main">
    <gruppo-edit v-for="(gr, index) in gruppiTastiEdit" :key="index"
      :context="context"
      :zona="gr.zona"
      :tasti="gr.tasti"
      :paginaVisualizzata="paginaVisualizzata"
      @send-event="sendEventToParent"
      @select-zona="onSelectZona"
      @select-tasto="onSelectTasto"
    />
    <scontrino-semplificato ref="scontrino" :context="context" @send-event="sendEventToParent"/>
    <div class="pannelloEditTasti" ref="pannello" v-if="showPannello">
      <div v-if="!modalitaAddProdotto">
        <div class="cntEditTasto" v-if="currTasto && tastoVisibileInPagina"> <!-- mettere questo v-if sulla/e div che contiene i btn di spostamento, dimensione e rimozione tasto -->
          <div class="cntConfigTasti" v-if="currTasto && !bloccoConfigTasto" >
            <div class="cntTastiMovimento">
              <span></span>
              <button class="btnConfiguratoreTasti" @click="onClickUp"><van-icon name="arrow-up" /></button>
              <span></span>
              <button class="btnConfiguratoreTasti" @click="onClickLeft"><van-icon name="arrow-left" /></button>
              <div v-if="currTasto">
                <button class="btnConfiguratoreTasti" @click="onClickConfigTasto"><van-icon name="setting-o" /></button>
              </div>
              <button class="btnConfiguratoreTasti" @click="onClickRight"><van-icon name="arrow" /></button>
              <span></span>
              <button class="btnConfiguratoreTasti" @click="onClickDown"><van-icon name="arrow-down" /></button>
              <span></span>
            </div>
            <div class="cntTastiDimensioni">
              <button class="btnConfiguratoreTasti minus" @click="onClickDecreaseWidth"><van-icon name="minus" /></button>
              <span>LARGHEZZA</span>
              <button class="btnConfiguratoreTasti plus" @click="onClickIncreaseWidth"><van-icon name="plus" /></button>
              <button class="btnConfiguratoreTasti minus" @click="onClickDecreaseHeight"><van-icon name="minus" /></button>
              <span>ALTEZZA</span>
              <button class="btnConfiguratoreTasti plus" @click="onClickIncreaseHeight"><van-icon name="plus" /></button>
            </div>
            <div class="topLeft" v-if="currZona && !bloccoAddTasto">
              <button class="btnConfiguratoreTasti" @click="onClickAddTasto"><van-icon name="plus" /></button>
            </div>
            <div class="bloccoExtra1" v-if="currZona && currTasto && !bloccoAddTasto" style="position: absolute; left:90px; top: 30px">
              <button class="btnConfiguratoreTasti" @click="onClickDuplicaTasto">DUPLICA</button>
            </div>
            <div class="bloccoExtra2" v-if="currZona && currTasto && !bloccoAddTasto" style="position: absolute;top: 30px;left: 318px;">
              <button class="btnConfiguratoreTasti" @click="modalitaAddProdotto = true">AGGIUNTA RAPIDA</button>
            </div>
            <div class="topRight">
              <button class="btnConfiguratoreTasti" @click="onClickDeleteTasto"><van-icon name="delete-o" /></button>
            </div>
          </div>
        </div>
        <div class="cntEditTasto" v-if="currTasto && !tastoVisibileInPagina">
          <span>Il tasto selezionato sta a pagina {{ currTasto.ts.page }}: </span>
          <button class="btnConfiguratoreSpostaInPagina" @click="onClickSpostaInPagina" v-if="!bloccoConfigTasto">SPOSTA IN QUESTA PAGINA</button>
        </div>
        <div class="cntBloccoDown">
          <div class="topLeft" v-if="currZona && !currTasto && !bloccoAddTasto" style="position: absolute;top: 85px;left: 6px;">
              <button class="btnConfiguratoreTasti" @click="onClickAddTasto"><van-icon name="plus" /></button>
          </div>
          <div class="" v-if="currZona && !currTasto && !bloccoAddTasto" style="position: absolute;top: 85px;left: 136px;">
              <button class="btnConfiguratoreTasti white" @click="modalitaAddProdotto = true">Aggiunta rapida</button>
          </div>
          <div>
            <span>Pagina:</span>
            <select @change="onCambioPagina" >
              <option v-for="(p, index) in elencoPagine" :key="index">{{ p }}</option>
            </select>
          </div>
          <div>
            <span>No collisioni</span>
            <van-switch class="modalSwitch" v-model="impedisciCollisioni" :active-color="'#4caf50'" />
          </div>
          <!-- <div>
            Forza tasti hidden
            <van-switch class="modalSwitch" v-model="forzaTastiHidden" :active-color="'#4caf50'" />
          </div> -->
          <div v-if="currTasto && currTasto.ts.hiddenTestCondition">
            <span>Forza hidden</span>
            <van-switch class="modalSwitch" v-model="currTasto.ts.forzaTastoHidden" :active-color="'#4caf50'" />
          </div>
          <div>
            <button class="btnConfiguratoreTasti reprise" @click="onRipristinaForzature">Ripristina Forzature</button>
          </div>
          <div>
            <button class="btnConfiguratoreTasti white" @click="onAnnulla">Annulla</button>
          </div>
          <div>
            <button class="btnConfiguratoreTasti green" @click="onSalva">Salva</button>
          </div>

        </div>
      </div>
      <div class="cntModalitaAddProdotto" v-else>
        <div class="testoAggiuntaRapida">Aggiunta rapida</div>
        <div class="topLeft" >
            <button class="btnConfiguratoreTasti" @click="modalitaAddProdotto = false"><van-icon name="revoke" /></button>
            <button class="btnConfiguratoreTasti" v-if="currZona" @click="onApriRicercaProdotto"><van-icon name="search" /></button>
        </div>
        <div class="topRight">
            <button class="btnConfiguratoreTasti" v-if="currZona" @click="onAddProdottoRapido"><van-icon name="add-o" /></button>
        </div>
        <div v-if="currZona" class="zonaAddProdottoRapido">
          <div class="cntTastiDimensioni">
            <button class="btnConfiguratoreTasti minus" @click="onClickDecreaseWidth"><van-icon name="minus" /></button>
            <span>LARGHEZZA: {{ datiAddRapido.width }}</span>
            <button class="btnConfiguratoreTasti plus" @click="onClickIncreaseWidth"><van-icon name="plus" /></button>
            <!-- <button class="btnConfiguratoreTasti minus" @click="datiAddRapido.width > 1 ? datiAddRapido.width-- : ''"><van-icon name="minus" /></button>
            <span>LARGHEZZA: {{ datiAddRapido.width }}</span>
            <button class="btnConfiguratoreTasti plus" @click="datiAddRapido.width < 25 ? datiAddRapido.width++ : ''"><van-icon name="plus" /></button> -->
            <button class="btnConfiguratoreTasti minus" @click="onClickDecreaseHeight"><van-icon name="minus" /></button>
            <span>ALTEZZA: {{ datiAddRapido.height }}</span>
            <button class="btnConfiguratoreTasti plus" @click="onClickIncreaseHeight"><van-icon name="plus" /></button>
            <!-- <button class="btnConfiguratoreTasti minus" @click="datiAddRapido.height > 1 ? datiAddRapido.height-- : ''"><van-icon name="minus" /></button>
            <span>ALTEZZA: {{ datiAddRapido.height }}</span>
            <button class="btnConfiguratoreTasti plus" @click="datiAddRapido.height < 25 ? datiAddRapido.height++ : ''"><van-icon name="plus" /></button> -->
          </div>
          <div class="zonaTestiProdotto">
            <select-field :item="datiAddRapido.selectAspettoConfig" ref="selectAspetto"></select-field>
            <br>
            Codice <input v-model="datiAddRapido.codiceProdotto" placeholder="Codice Prodotto.." />
            Testo <input v-model="datiAddRapido.testo"  placeholder="Descrizione Prodotto.."/>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import GruppoEdit from '@/components/gruppoTasti/editorNew/GruppoEdit.vue'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import ScontrinoSemplificato from '@/components/scontrino/ScontrinoSemplificato.vue'
import configurazioni from '@/services/configurazioniService'
import { cloneObject, ruoloPresente } from '@/components/helpers'
import SelectField from '@/components/modale/ModaleForm/components/AutocompleteField'
var colori = require('./modelli/colori.json')

export default {
  components: {
    GruppoEdit,
    ScontrinoSemplificato,
    SelectField
  },
  props: {
    context: Object,
    gruppiTasti: Array,
    parametriLayout: Object,
    zona: String
  },
  data() {
    return {
      currTasto: null,
      currZona: null,
      configLayout: {},
      paginaVisualizzata: 1,
      showPannello: true,
      impedisciCollisioni: true,
      modalitaAddProdotto: false,
      gruppiTastiInit: [],
      gruppiTastiEdit: [],
      item: null,
      itemSelected: null,
      tastiLocali: [],
      trigger: 1,
      triggerEdit: 1,
      modello: {
        row: 1,
        col: 1,
        row_span: 1,
        col_span: 1,
        aspect: '',
        title: 'Nuovo Tasto',
        title2th: '',
        action: {},
        posClick: null
      },
      datiAddRapido: {
        width: 2,
        height: 2,
        aspetto: '',
        selectAspettoConfig: {},
        codiceProdotto: '',
        testo: ''
      }
    }
  },
  async mounted() {
    var result = await configurazioni.getConfigurazione('gruppi_tasti', this.context.layoutTasti)
    this.configLayout = result.config && !Array.isArray(result.config) ? result.config : {}

    this.gruppiTastiInit = cloneObject(this.gruppiTasti)
    this.gruppiTastiEdit = cloneObject(this.gruppiTasti)
    this.setPanelPosition()
    colori = colori.sort((a, b) => a.descrizione > b.descrizione ? 1 : -1)

    this.datiAddRapido.selectAspettoConfig = { campo: 'colore', label: 'Aspetto', saveFormat: 'codice', dataSource: colori, placeHolder: 'Digitare Colore..', extraOptionLabel: this.coloreExtraOptionLabel, blurOnSelect: true, onUpdateValue: this.onUpdateColore, valore: this.datiAddRapido.aspetto || '' }
  },
  computed: {
    elencoPagine() {
      var pagine = []
      for (var i = 0; i < this.gruppiTastiEdit.length; i++) {
        var tasti = this.gruppiTastiEdit[i].tasti
        for (var j = 0; j < tasti.length; j++) {
          var p = tasti[j].page
          if (Array.isArray(p)) {
            for (var z = 0; z < p.length; z++) {
              pagine.indexOf(p[z]) < 0 && pagine.push(p[z])
            }
          } else if (!isNaN(p)) {
            pagine.indexOf(p) < 0 && pagine.push(p)
          }
        }
      }
      return pagine
    },
    tastoVisibileInPagina() {
      if (!this.currTasto) {
        return false
      }
      var p = this.currTasto.ts.page
      if (!p || p === this.paginaVisualizzata || (Array.isArray(p) && p.indexOf(this.paginaVisualizzata) >= 0)) {
        return true
      }
      return false
    },
    bloccoAddTasto() {
      return this.checkBlocchi(['bloccoTotale', 'bloccoAggiunta'])
    },
    bloccoConfigTasto() {
      return this.checkBlocchi(['bloccoTotale', 'bloccoModifica'])
    }
  },
  methods: {
    checkBlocchi(blocchi) { // bloccoTotale, bloccoAggiunta, bloccoModifica, bloccoAzione
      var blocco = false
      var ruolo = this.context.operatore && this.context.operatore.accesso ? this.context.operatore.accesso.ruoloEditor || '' : '' // 'amministratore'
      var i = 0
      var b

      blocchi = !Array.isArray(blocchi) ? [(blocchi || '')] : blocchi

      // check singolo tasto
      if (this.currTasto && this.currTasto.ts.bloccoConfig) {
        // check se ruolo presente
        for (i = 0; i < blocchi.length; i++) {
          b = blocchi[i]
          blocco = this.currTasto.ts.bloccoConfig[b] ? ruoloPresente(ruolo, this.currTasto.ts.bloccoConfig[b].ruoli) : false
          if (blocco) {
            break
          }
        }
      }

      // poi check su zona
      if (!blocco && this.currZona) {
        var configZona = this.gruppiTastiInit.find(obj => obj.zona === this.currZona.zona)
        if (configZona && configZona.bloccoConfig) {
          for (i = 0; i < blocchi.length; i++) {
            b = blocchi[i]
            blocco = configZona.bloccoConfig[b] ? ruoloPresente(ruolo, configZona.bloccoConfig[b].ruoli) : false
            if (blocco) {
              break
            }
          }
        }
      }

      // infine check su impostazioni esterne
      if (!blocco && this.configLayout && this.configLayout.bloccoConfig) {
        for (i = 0; i < blocchi.length; i++) {
          b = blocchi[i]
          blocco = this.configLayout.bloccoConfig[b] ? ruoloPresente(ruolo, this.configLayout.bloccoConfig[b].ruoli) : false
          if (blocco) {
            break
          }
        }
      }

      return blocco
    },
    setPanelPosition() {
      var scontrino = this.$refs.scontrino.$el
      var pannello = this.$refs.pannello
      var w = scontrino.offsetWidth
      var h = scontrino.offsetHeight
      pannello.style.width = (w - 20) + 'px'
      pannello.style.height = (h - 20) + 'px'
      var sLeft = scontrino.offsetLeft + scontrino.offsetWidth / 2 - pannello.offsetWidth / 2
      var sTop = scontrino.offsetTop + scontrino.offsetHeight / 2 - pannello.offsetHeight / 2
      pannello.style.left = sLeft + 'px'
      pannello.style.top = sTop + 'px'
    },
    tastoSelected(item, show, codiceTasto) {
      this.showEditTasto = show
      this.item = show ? item : null
      this.itemSelected = show ? item.codiceTasto : null
      this.triggerEdit++
    },
    getTitleVariable(title) {
      if (!title.startsWith('@')) {
        return title
      }
      const variable = title.substring(1)
      return this.getContextValue(variable)
    },
    getAttributeValue(attribute) {
      return _.get(this.context.attributes, attribute) || ''
    },
    getContextValue(variable) {
      if (!variable) return ''
      if (variable && variable.startsWith('#')) {
        return variable.substring(1)
      }
      if (variable && variable.startsWith('€')) {
        const value = _.get(this.context, variable.substring(1))
        return value ? value.toFixed(2) : ''
      }
      if (variable && variable.startsWith('@')) {
        const operands = variable.substring(1).split(' eq ')
        if (operands.length === 2) {
          return String(_.get(this.context, operands[0])) === operands[1]
        } else {
          return false
        }
      }
      // console.log(variable, _.get(this.context, variable))
      return _.get(this.context, variable) || ''
    },
    getAspectIfCondition(itemTest, item, force) {
      if (!itemTest) return ''
      const index = this.getAspectTestCondition(itemTest, force).index
      if (index !== -1) {
        const values = item.split(',')
        return values.length > index ? values[index] : ''
      } else {
        return ''
      }
    },
    getAspectTestCondition(itemTest, force) {
      if (!itemTest) return { index: -1, value: false }
      // if (force) return { index: -1, value: true }

      const tests = itemTest.split(',')
      for (let index = 0; index < tests.length; index++) {
        let varName = tests[index]
        let neg = false
        if (varName.startsWith('!')) {
          varName = varName.substring(1)
          neg = true
        }
        let cond = this.getContextValue(varName) === true
        cond = cond || force
        if (neg ? !cond : cond) {
          return { index, value: true }
        }
      }
      return { index: -1, value: false }
    },
    onCambioPagina(e) {
      this.paginaVisualizzata = parseInt(e.target.value)
      // var pagina = parseInt(e.target.value)
      // const event = {
      //   type: 'PRODOTTI_PAGINA',
      //   value: {
      //     page: pagina,
      //     zona: this.currZona.zona,
      //     toggle: false
      //   }
      // }
      // this.sendEventToParent(event)
    },
    onSelectTasto(tastoVue, zonaVue) {
      this.selectZona(zonaVue)
      this.selectTasto(tastoVue)
    },
    onSelectZona(zonaVue) {
      this.selectZona(zonaVue)
      if (this.currTasto && this.currTasto.$parent !== zonaVue) {
        this.deselectTasto()
      }
    },
    selectZona(zonaVue) {
      if (this.currZona === zonaVue) {
        return
      }
      this.deselectCurrZona()
      this.currZona = zonaVue
      this.currZona.select()
    },
    deselectCurrZona() {
      if (!this.currZona) {
        return
      }
      this.currZona.deselect()
      this.currZona = null
    },
    selectTasto(tastoVue) {
      if (this.currTasto === tastoVue) {
        return
      }
      this.deselectTasto()
      this.currTasto = tastoVue
      this.currTasto.select()
      if (this.modalitaAddProdotto) {
        this.datiAddRapido.width = this.currTasto.ts.col_span
        this.datiAddRapido.height = this.currTasto.ts.row_span
      }
    },
    deselectTasto() {
      if (!this.currTasto) {
        return
      }
      this.currTasto.deselect()
      this.currTasto = null
    },
    getCurrZonaSize() {
      if (this.currZona) {
        var configZona = this.gruppiTastiInit.find(obj => obj.zona === this.currZona.zona)
        return {
          rows: configZona.rows || 23,
          columns: configZona.columns || 15
        }
      }
      return {
        rows: 0,
        columns: 0
      }
    },
    onCambioFunzionalita(funzionalita) {
      const tasto = this.tastiLocali.find(x => x.codiceTasto === funzionalita.codice)
      tasto.action = funzionalita.action
      this.trigger++
    },
    findFreeSpot(width, height) {
      var zonaSize = this.getCurrZonaSize()
      var maxX = zonaSize.columns
      var maxY = zonaSize.rows

      for (var y = 1; y <= maxY - height + 1; y++) {
        for (var x = 1; x <= maxX - width + 1; x++) {
          if (this.isAreaFree(x, y, width, height)) {
            return { x: x, y: y }
          }
        }
      }
      return null
    },
    isAreaFree(x, y, width, height, excludedTasto) {
      var zona = this.currZona.zona
      var page = this.paginaVisualizzata
      var tastiPerZona = this.gruppiTastiEdit.find((obj) => obj.zona === zona)
      var tastiPerPagina = tastiPerZona.tasti.filter((obj) => !obj.page || obj.page === page || (Array.isArray(obj.page) && obj.page.includes(page)))

      var zonaSize = this.getCurrZonaSize()
      var maxX = zonaSize.columns
      var maxY = zonaSize.rows
      // var maxX = 15
      // var maxY = 23
      if (x + width > maxX + 1 || y + height > maxY + 1) {
        return false
      }

      if (!this.impedisciCollisioni) {
        return true
      }

      for (var z = 0; z < tastiPerPagina.length; z++) {
        var t = tastiPerPagina[z]
        // if (excludedTasto && excludedTasto.ts.codiceTasto === t.codiceTasto) {
        if (excludedTasto && excludedTasto.ts === t) {
          continue
        }
        for (var i = x; i < x + width; i++) {
          for (var j = y; j < y + height; j++) {
            if ((t.col <= i && i <= t.col + t.col_span - 1) && (t.row <= j && j <= t.row + t.row_span - 1)) {
              return false
            }
          }
        }
      }
      return true
    },
    onClickLeft() {
      if (!this.currTasto) {
        return
      }
      var ts = this.currTasto.ts
      var minX = 0
      for (var i = ts.col - 1; i > minX; i--) {
        var areaFree = this.isAreaFree(i, ts.row, ts.col_span, ts.row_span, this.currTasto)
        if (areaFree) {
          ts.col = i
          break
        }
      }
    },
    onClickRight() {
      if (!this.currTasto) {
        return
      }
      var ts = this.currTasto.ts
      var zonaSize = this.getCurrZonaSize()
      var maxX = zonaSize.columns
      // var maxX = 15
      for (var i = ts.col + 1; i <= maxX; i++) {
        var areaFree = this.isAreaFree(i, ts.row, ts.col_span, ts.row_span, this.currTasto)
        if (areaFree) {
          ts.col = i
          break
        }
      }
    },
    onClickUp() {
      if (!this.currTasto) {
        return
      }
      var ts = this.currTasto.ts
      var minY = 0
      for (var i = ts.row - 1; i > minY; i--) {
        var areaFree = this.isAreaFree(ts.col, i, ts.col_span, ts.row_span, this.currTasto)
        if (areaFree) {
          ts.row = i
          break
        }
      }
    },
    onClickDown() {
      if (!this.currTasto) {
        return
      }
      var ts = this.currTasto.ts
      var zonaSize = this.getCurrZonaSize()
      var maxY = zonaSize.rows
      // var maxY = 23
      for (var i = ts.row + 1; i <= maxY; i++) {
        var areaFree = this.isAreaFree(ts.col, i, ts.col_span, ts.row_span, this.currTasto)
        if (areaFree) {
          ts.row = i
          break
        }
      }
    },
    onClickIncreaseWidth() {
      if (!this.currTasto) {
        this.modalitaAddProdotto && this.datiAddRapido.width < 30 && this.datiAddRapido.width++
        return
      }
      var ts = this.currTasto.ts
      if (this.isAreaFree(ts.col, ts.row, ts.col_span + 1, ts.row_span, this.currTasto)) {
        ts.col_span++
        this.modalitaAddProdotto && (this.datiAddRapido.width = ts.col_span)
      }
    },
    onClickDecreaseWidth() {
      if (!this.currTasto) {
        this.modalitaAddProdotto && this.datiAddRapido.width > 1 && this.datiAddRapido.width--
        return
      }
      var ts = this.currTasto.ts
      if (ts.col_span > 1 && this.isAreaFree(ts.col, ts.row, ts.col_span - 1, ts.row_span, this.currTasto)) {
        ts.col_span--
        this.modalitaAddProdotto && (this.datiAddRapido.width = ts.col_span)
      }
    },
    onClickIncreaseHeight() {
      if (!this.currTasto) {
        this.modalitaAddProdotto && this.datiAddRapido.height < 30 && this.datiAddRapido.height++
        return
      }
      var ts = this.currTasto.ts
      if (this.isAreaFree(ts.col, ts.row, ts.col_span, ts.row_span + 1, this.currTasto)) {
        ts.row_span++
        this.modalitaAddProdotto && (this.datiAddRapido.height = ts.row_span)
      }
    },
    onClickDecreaseHeight() {
      if (!this.currTasto) {
        this.modalitaAddProdotto && this.datiAddRapido.height > 1 && this.datiAddRapido.height--
        return
      }
      var ts = this.currTasto.ts
      if (ts.row_span > 1 && this.isAreaFree(ts.col, ts.row, ts.col_span, ts.row_span - 1, this.currTasto)) {
        ts.row_span--
        this.modalitaAddProdotto && (this.datiAddRapido.height = ts.row_span)
      }
    },
    onClickDeleteTasto() {
      var tastiPerZona = this.gruppiTastiEdit.find((obj) => obj.zona === this.currZona.zona)
      var tasti = tastiPerZona.tasti
      for (var i = 0; i < tasti.length; i++) {
        if (tasti[i] === this.currTasto.ts) {
          this.deselectTasto()
          tasti.splice(i, 1)
          break
        }
      }
    },
    onClickDuplicaTasto() {
      if (!this.currZona) {
        return
      }
      var duplicato = cloneObject(this.currTasto.ts)
      var freeSpot = this.findFreeSpot(duplicato.col_span, duplicato.row_span)
      if (!freeSpot) {
        duplicato.row_span = 1
        duplicato.col_span = 1
        freeSpot = this.findFreeSpot(1, 1)
      }
      if (freeSpot) {
        this.deselectTasto()
        var tastiPerZona = this.gruppiTastiEdit.find((obj) => obj.zona === this.currZona.zona)
        var tasti = tastiPerZona.tasti
        var cod = uuidv4()
        duplicato.row = freeSpot.y
        duplicato.col = freeSpot.x
        duplicato.title += ' - Copia'
        duplicato.codiceTasto = cod
        tasti.push(duplicato)
        var self = this
        setTimeout(() => {
          var tastiVue = self.currZona.$children
          for (var i = 0; i < tastiVue.length; i++) {
            if (tastiVue[i] && tastiVue[i].ts && tastiVue[i].ts.codiceTasto === cod) {
              self.selectTasto(tastiVue[i])
              break
            }
          }
        }, 100)
      }
    },
    onClickConfigTasto() {
      var paramGruppo = this.gruppiTastiInit.find((obj) => obj.zona === this.currZona.zona).parametri || {}
      var modaleConfig = { disableEsciOnClick: true, transparentBackground: true, updateModel: true, elencoPagine: this.elencoPagine }

      paramGruppo.modalLeft && (modaleConfig.modalLeft = true)
      paramGruppo.modalRight && (modaleConfig.modalRight = true)

      this.currZona.zona === 'tasti' ? modaleConfig.modalLeft = true : modaleConfig.modalRight = true

      modaleConfig.hiddenConditions = this.parametriLayout ? this.parametriLayout.hiddenTestConditions || [] : []

      // this.context.debug.tastoEdit = {
      //   elVue: this.currTasto,
      //   modelItem: this.currTasto.ts,
      //   zona: this.currZona.zona,
      //   modaleConfig: modaleConfig
      // }
      var configZona = this.gruppiTastiInit.find(obj => obj.zona === this.currZona.zona)

      var ev = {
        type: 'EDIT_CONTEXT',
        reference: {
          source: 'tastoEdit',
          dest: 'debug.tastoEdit'
        },
        payload: {
          tastoEdit: {
            elVue: this.currTasto,
            modelItem: this.currTasto.ts,
            zona: this.currZona.zona,
            modaleConfig: modaleConfig,
            configZona: configZona,
            configLayout: this.configLayout
          }
        }
      }
      this.$emit('send-event', ev)

      const event = {
        type: 'APRI_MODALE',
        value: 'SetupTasti'
      }
      this.sendEventToParent(event)
    },
    onClickSpostaInPagina() {
      if (!this.currTasto) {
        return
      }
      var p = this.currTasto.ts.page
      if (!isNaN(p)) {
        this.currTasto.ts.page = this.paginaVisualizzata
      } else if (Array.isArray(p)) {
        p.push(this.paginaVisualizzata)
      }
    },
    onClickAddTasto() {
      if (!this.currZona) {
        return
      }
      var freeSpot = this.findFreeSpot(1, 1)
      if (freeSpot) {
        this.deselectTasto()
        var tastiPerZona = this.gruppiTastiEdit.find((obj) => obj.zona === this.currZona.zona)
        var tasti = tastiPerZona.tasti
        var cod = uuidv4()
        tasti.push({ col: freeSpot.x, row: freeSpot.y, col_span: 1, row_span: 1, page: this.paginaVisualizzata, title: 'Nuovo Tasto', title2th: '', codiceTasto: cod, aspect: '', action: {} })
        var self = this
        setTimeout(() => {
          var tastiVue = self.currZona.$children
          for (var i = 0; i < tastiVue.length; i++) {
            if (tastiVue[i] && tastiVue[i].ts && tastiVue[i].ts.codiceTasto === cod) {
              self.selectTasto(tastiVue[i])
              break
            }
          }
        }, 100)
      }
    },
    onRipristinaForzature() {
      for (var i = 0; i < this.gruppiTastiEdit.length; i++) {
        if (!this.gruppiTastiEdit[i].zona.startsWith('tasti')) {
          continue
        }
        var tasti = this.gruppiTastiEdit[i].tasti

        for (var j = 0; j < tasti.length; j++) {
          if (tasti[j].forzaTastoHidden) {
            tasti[j].forzaTastoHidden = false
          }
          delete tasti[j].forzaTastoHidden
        }
      }
    },
    async onAnnulla() {
      var that = this
      if (JSON.stringify(this.gruppiTastiEdit) !== JSON.stringify(this.gruppiTastiInit)) {
        await this.$dialog.confirm({ title: 'Annullamento modifiche', message: 'Confermi l\'annullamento di tutte le modifiche effettuate ?' })
          .then(async () => {
            that.removeContextSetupTasti()
          }).catch(() => {
          })
      } else {
        that.removeContextSetupTasti()
      }
    },
    async onSalva() {
      await this.$dialog.confirm({ title: 'Salvataggio dati', message: 'Confermi salvataggio layout attuale ?' })
      this.onRipristinaForzature()
      for (var i = 0; i < this.gruppiTastiEdit.length; i++) {
        var g = this.gruppiTastiEdit[i]
        if (!g.zona.startsWith('tasti')) {
          continue
        }
        await configurazioni.salvaGruppoTasti(this.context.layoutTasti, g.zona, g.tasti)
      }
      // this.removeContextSetupTasti() // invece che uscire, ricarico la pagina
      this.$toast('Configurazione layout modificata con successo', { type: 'success' })

      var that = this
      setTimeout(() => {
        const event = {
          type: 'FUNZIONE_RAPIDA_CONFIRM',
          value: {
            funzione: 'RICARICA_PAGINA',
            confirm: true
          }
        }
        that.$emit('send-event', event)
      }, 1000)
    },
    removeContextSetupTasti() {
      const event = {
        type: 'EDIT_CONTEXT',
        payload: {
          debug: {
            setupTasti: false
          }
        }
      }
      this.$emit('send-event', event)
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    },
    coloreExtraOptionLabel(props) {
      var col = props && props.option ? props.option.codice || '' : ''
      return '<div style="width:6vh;height:2.5vh;margin-left:1vh;border-radius: 1vh;border: 2px solid black;" class="' + col + '"></div></div>'
    },
    onAddProdottoRapido() {
      if (!this.currZona) {
        return
      }
      var d = this.datiAddRapido
      if (!d.testo || !d.codiceProdotto) {
        this.$toast('Impostare un codice e un testo', { type: 'success' })
        return
      }
      var freeSpot = this.findFreeSpot(d.width, d.height)
      if (freeSpot) {
        this.deselectTasto()
        var tastiPerZona = this.gruppiTastiEdit.find((obj) => obj.zona === this.currZona.zona)
        var tasti = tastiPerZona.tasti
        var cod = uuidv4()
        var aspetto = this.$refs.selectAspetto.getDati() || ''
        var tasto = { col: freeSpot.x, row: freeSpot.y, col_span: d.width, row_span: d.height, page: this.paginaVisualizzata, title: d.testo, title2th: '', codiceTasto: cod, aspect: aspetto || '', action: { type: 'PRODOTTO', payload: { code: d.codiceProdotto, tipoAliquota: 'banco' } } }
        tasti.push(tasto)
        var self = this
        setTimeout(() => {
          var tastiVue = self.currZona.$children
          for (var i = 0; i < tastiVue.length; i++) {
            if (tastiVue[i] && tastiVue[i].ts && tastiVue[i].ts.codiceTasto === cod) {
              self.selectTasto(tastiVue[i])
              break
            }
          }
        }, 100)
      } else {
        this.$toast('Impossibile aggiungere il tasto con le dimensioni impostate', { type: 'success' })
      }
    },
    onApriRicercaProdotto() {
      var ev = {
        type: 'EDIT_CONTEXT',
        reference: {
          source: 'configuratoreTasti',
          dest: 'debug.configuratoreTasti'
        },
        payload: {
          configuratoreTasti: this
        }
      }
      this.$emit('send-event', ev)

      const event = {
        type: 'APRI_MODALE',
        value: 'RicercaArticoloSetupTasti'
      }
      this.sendEventToParent(event)
    },
    setProdotto(codice, testo) {
      this.datiAddRapido.codiceProdotto = codice
      this.datiAddRapido.testo = testo
    },
    aggiungiProdotto(codice, testo) {
      this.datiAddRapido.codiceProdotto = codice
      this.datiAddRapido.testo = testo
      this.onAddProdottoRapido()
    },
    onUpdateColore(value) {
      this.datiAddRapido.aspetto = value || ''
    }
  }
}
</script>

<style scoped lang="scss">
.pannelloEditTasti{
  position: absolute;
  // width: 400px;
  // height: 300px;
  background: linear-gradient(0deg, rgba(201,201,201,1) 0%, rgba(236,236,236,1) 100%);
}

.switchCollisioni {
  margin-left: 1vh;
  font-size: 3vh;
}

.btnConfiguratoreTasti {
  width: 7vh;
  height: 7vh;
  border-radius: 1vh;
  border: 2px solid black;
  background-color: #cfcfcf;
}

.cntTastiMovimento .btnConfiguratoreTasti {
  width: 6vh;
  height: 6vh;
  border-radius: 1vh;
  border: 0px;
  background-color: #1a3048;
  color: #ffffff;
  font-size: 1.7em;
}

.cntTastiDimensioni .btnConfiguratoreTasti {
  width: 4vh;
  height: 8vh;
  border: 0px;
  background-color: #1a3048;
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
}

.cntTastiDimensioni .btnConfiguratoreTasti.minus {
  border-top-left-radius: 1vh;
  border-bottom-left-radius: 1vh;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.cntTastiDimensioni .btnConfiguratoreTasti.plus {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 1vh;
  border-bottom-right-radius: 1vh;
}

.cntTastiDimensioni span {
  background: #ffffff;
  height: 8vh;
  width: 12.7vh;
  margin-left: -2.8vh;
  font-size: 0.8em;
  line-height: 8vh;
  text-align: center;
  font-weight: bold;
}

.topLeft .btnConfiguratoreTasti, .topRight .btnConfiguratoreTasti {
  width: 7vh;
  height: 7vh;
  border: 0;
  background-color: transparent;
  color: #232323;
}

.topRight .btnConfiguratoreTasti {
  font-size: 2.3em;
  padding-top: 0.2em;
  margin-right: 0.1em;
}

.topLeft .btnConfiguratoreTasti {
  font-size: 2em;
}

.btnConfiguratoreSpostaInPagina {
  border-radius: 1vh;
  border: 2px solid black;
  background-color: #cfcfcf;
}

.cntTastiMovimento {
    display: grid;
    width: 20vh;
    height: 20vh;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 33% 33% 33%;
}

.cntTastiDimensioni {
    display: grid;
    width: 24vh;
    height: 22vh;
    grid-template-columns: 29% 42% 29%;
    grid-template-rows: 50% 50%;
    margin-top: 2vh;
    margin-left: 2vh;
}

.cntConfigTasti {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 4vh;
  margin-bottom: 2vh;
}

.topRight {
  position: absolute;
  top: 6px;
  right: 6px;
}

.topLeft {
  position: absolute;
  top: 6px;
  left: 6px;
}

.cntBloccoDown {
  display: flex;
  justify-content: space-around;
  align-items: center;
  vertical-align: -webkit-baseline-middle;
}

.cntBloccoDown span {
  font-size: 0.7em;
  display: block;
  color: #666666;
  margin-bottom: 0.6em;
}

.cntBloccoDown button {
  font-size: 0.7em;
  width: 9vh;
  margin-left: 0.2vh;
}

.cntBloccoDown .white {
  background: #ffffff;
  border-color: #ffffff;
}

.cntBloccoDown .green {
  background: #339900;
  border-color: #339900;
  color: #ffffff;
}

.cntBloccoDown .reprise {
  background: #eaeaea;
  border-color: #c0c0c0;
}

.cntBloccoDown select {
  width: 6vh;
  height: 4vh;
  margin-bottom: 0.6vh;
  margin-top: 0.1vh;
}

.bloccoExtra1 button, .bloccoExtra2 button {
  top: 6px;
  font-size: 0.7em;
  width: 9vh;
  height: 6vh;
  margin-left: 0.2vh;
}

.testoAggiuntaRapida {
  text-align: center;
  font-size: 1.5vw;
  margin-top: 20px;
  margin-bottom: 20px;
}

.zonaAddProdottoRapido {
  display: flex;
  .zonaTestiProdotto {
    width: 70%;
  }
}

</style>

'use strict'
import _ from 'lodash'
import moment from 'moment'
import configurazioni from '@/services/configurazioniService.js'
import router from '@/router'

const stampaReport = async (context, event) => {
  const payload = event.value
  const nomeReport = payload.nomeReport
  const stampaDef = payload.stampaDefault
  const filtroPostazione = !!payload.filtroPostazione
  const stampe = await configurazioni.getConfigurazione('report', 'report')
  const stampa = stampe.find(x => x.nomeReport === nomeReport)
  if (!stampa) return

  let start = 'ddd'
  let stop = 'aaa'
  const dataDa = payload.dataDa
  if (dataDa && dataDa.startsWith('#data')) {
    // Sostituisce il valore della data attuale e verifica presenza offset
    // Es. #data-7#
    // const appo = dataDa.slice(5)
    const offsetDa = dataDa.slice(5).slice(0, -1)
    start = moment().add(offsetDa || 0, 'days').format('YYYYMMDD')
  }
  const dataA = payload.dataA
  if (dataA && dataA.startsWith('#data')) {
    // Sostituisce il valore della data attuale e verifica presenza offset
    // Es. #data-7#
    // const appo = dataA.slice(5)
    const offsetA = dataA.slice(5).slice(0, -1)
    stop = moment().add(offsetA || 0, 'days').format('YYYYMMDD')
  }
  console.log(dataDa, dataA)

  let parDefault = _.get(stampa, 'stampeDefault.' + stampaDef, '')
  if (parDefault && filtroPostazione) {
    const text = Buffer.from(parDefault, 'base64').toString('ascii')
    const parametri = JSON.parse(text)
    const chiave = parametri.filtri.find(x => x.chiave === 'postazione')
    const postazione = configurazioni.getPostazione()
    if (chiave) {
      chiave.valori = [configurazioni.getPostazione()]
    }
    _.set(parametri, 'intestazione.titolo_01_sx', postazione)

    const str = JSON.stringify(parametri)
    const buff = Buffer.from(str, 'utf-8')
    parDefault = buff.toString('base64')
  }
  const pathReportPrefix = process.env.VUE_APP_service_reporting || ''
  const source = `${pathReportPrefix}/reporting/pdfm/${nomeReport}/${start}/${stop}?par=${parDefault}`
  router.push({ name: 'Pdf', params: { source: source } })
}

/* const preparaRichiesta = async (tipo) => {
  const nomeReport = this.report.nomeReport
  let nonCompleti = false
  this.report.filtri.forEach(el => {
    // if (el.required && !this.filtri[el.model]) {
    if (el.required && !el.value) {
      nonCompleti = true
    }
  })
  if (nonCompleti) {
    this.datiNonCompleti = true
    return ''
  } else {
    const start = this.filtri.dataDa ? moment(this.filtri.dataDa).format('YYYYMMDD') : 'ddd' // nel caso il report non li prevedesse
    // TODO: Sistemare. Aggiungere un giorno non è corretto. Andrebbe usato endOf('day'), ma vanno rivisti tutti i report che utilizzano il filtro
    const stop = this.filtri.dataA ? moment(this.filtri.dataA).add(1, 'days').format('YYYYMMDD') : 'aaa'

    const filtriAggiuntivi = []
    let filtriCustom = null
    const esclusi = ['mostraArticoli','includiGiacenzaPositiva','includiGiacenzaNegativa', 'includiGiacenzaNulla', 'valorizzaPrezzo', 'tipoApplicazione']
    this.report.filtri.filter(x =>
      !['dataDa', 'dataA'].includes(x.model)
      && (x.model && !x.model.startsWith('rag-') && !x.model.startsWith('new-')
      && !esclusi.includes(x.model))).forEach(el => {
      var ff
      if (typeof this.filtri[el.model] === 'boolean') {
        ff = this.filtri[el.model]
      } else {
        ff = _.pickBy(this.filtri[el.model])
      }
      if (this.filtri[el.model] && ff) {
        if (typeof this.filtri[el.model] === 'boolean') {
          filtriAggiuntivi.push({ chiave: el.model, valori: ff, applicazione: el.applicazione })
        } else {
          if (el.filtroRecord) {
            filtriCustom = {
              selector: el.model,
              value: Object.values(ff)
            }
            if (el.command) {
              filtriCustom.command = el.command
            }
          }
          filtriAggiuntivi.push({ chiave: el.model, valori: Object.values(ff), applicazione: el.applicazione })
        }
      } else {
        filtriAggiuntivi.push({ chiave: el.model, valori: [], applicazione: el.applicazione }) // serve il tipo applicazione per eventuale raggruppamento
      }
    })
    const raggruppamenti = []
    const newPage = []

    Object.keys(this.filtri).filter(x => x.startsWith('rag-')).forEach(el => {
      console.log('-->', el)
      const chiave = el.substring(4)
      if (chiave === 'data') {
        raggruppamenti.push({ chiave: 'data', applicazione: 'movimenti' })
      } else if (chiave === 'codice') {

        raggruppamenti.push({ chiave: 'codice', applicazione: 'prodotti' }) // verificare
      } else {
        const filtro = this.report.filtri.find(x => x.model === chiave)
        raggruppamenti.push({ chiave: filtro.model, applicazione: filtro.applicazione, stampa: filtro.modelPrint || filtro.model })
      }
    })
    Object.keys(this.filtri).filter(x => x.startsWith('new-')).forEach(el => {
      console.log('-->', el)
      const chiave = el.substring(4)
      if (chiave === 'data') {
        newPage.push({ chiave: 'data', applicazione: 'movimenti' })
      } else {
        const filtro = this.report.filtri.find(x => x.model === chiave)
        newPage.push({ chiave: filtro.model, applicazione: filtro.applicazione, stampa: filtro.modelPrint || filtro.model })
      }
    })
    // TODO: Andrebbe definito un payload generico invece di separare tutte le variabili ...
    const articoli = this.filtri.mostraArticoli ? this.filtri.mostraArticoli : false
    const giacenza = {
      positiva: this.filtri ? this.filtri.includiGiacenzaPositiva : false,
      nulla: this.filtri ? this.filtri.includiGiacenzaNulla : false,
      negativa: this.filtri ? this.filtri.includiGiacenzaNegativa: false
    }
    const valorizza = this.filtri && this.filtri.valorizzaPrezzo === 1 ? 'vendita' : 'acquisto'
    const tipoApplicazione = this.filtri ? this.filtri.tipoApplicazione : 0

    let impostazioni = null
    if (this.report.impostazioni) {
      if (typeof this.report.impostazioni === 'string') {
        const conf = this.report.impostazioni.split('.')
        if (conf && conf.length === 2) {
          impostazioni = await configurazioni.getConfigurazione(conf[0], conf[1])
        } else {
          impostazioni = this.report.impostazioni // TODO: Verificare.
        }
      } else {
        impostazioni = this.report.impostazioni // TODO: Verificare
      }
    }
    const parametri = {
      // TODO--> togliere la sezione intestazione. Vanno aggiornati tutti i database dei clienti
      intestazione: {
        titolo: this.report.descrizione,
        titolo_01_sx: this.report.titolo_01_sx,
        titolo_02_sx: this.report.titolo_02_sx,
        titolo_01_cx: this.report.titolo_01_cx
      },
      caricaLoghi: this.report.caricaLoghi || false,
      reportHeader: this.report.reportHeader || null,
      pageHeader: this.report.pageHeader || null,
      pageFooter: this.report.pageFooter || null,
      reportFooter: this.report.reportFooter || null,
      articoli: articoli,
      filtri: filtriAggiuntivi,
      filtriCustom: filtriCustom,
      gruppi: raggruppamenti,
      newPage: newPage,
      impostazioni: impostazioni,
      giacenza: giacenza,
      valorizza,
      tipoApplicazione,
      dettaglio: this.report.dettaglio
    }

    const str = JSON.stringify(parametri)
    // const source = `${this.pathReportPrefix}/reporting/pdfm/${nomeReport}/${start}/${stop}?par=${btoa(str)}`
    const buff = Buffer.from(str, "utf-8")
    const source = `${this.pathReportPrefix}/reporting/${tipo || 'pdfm'}/${nomeReport}/${start}/${stop}?par=${buff.toString('base64')}`
    // this.$router.push({ name: 'Pdf', params: { source: source } })
    return source
  }
} */

export default {
  stampaReport
}

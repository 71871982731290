<template>
  <van-popup
    :value="show"
    position="right"
    :close-on-click-overlay="true"
    :closeable="true"
    close-icon="close"
    close-icon-position="top-left"
    :class="'PopupFidelity'"
    @click-close-icon="closePopup"
    @click-overlay="closePopup"
    @open="popupOpen"
    >
    <van-tabs v-model="activeName" sticky @rendered="onRenderedTab" @change="onRenderedTab">
      <van-tab v-if="fidelityCaricata" title="Dettaglio" name="dettaglio">
        <dettaglio v-if="associata" :context="context" @no-card="noCard"/>
        <!-- <associa-cliente v-if="!associata" :cliente="context.cliente" @send-event="sendEventToParent"/> -->
        <van-cell
          v-if="fidelityCaricata"
          :border="true"
          title="Annulla selezione card"
          icon="coupon"
          size="large"
          @click="noCard"
        ></van-cell>
      </van-tab>
      <van-tab :title="fidelityCaricata ? 'Modifica' : 'Nuova'" name="modifica">
        <modifica :context="context" @send-event="sendEventToParent" @close-popup="closePopup" ref="modifica"/>
      </van-tab>
      <van-tab title="Card" name="cerca">
        <ricerca :refresh="refresh" :context="context"  @send-event="sendEventToParent" @change-tab="changeTab" ref="cerca"/>
      </van-tab>
      <van-tab title="Clienti" name="clienti" v-if="!associata">
        <clienti :context="context" @send-event="sendEventToParent"/>
      </van-tab>
    </van-tabs>
  </van-popup>
</template>

<script>
import Ricerca from './ricerca'
import Dettaglio from './dettaglio'
import Modifica from './modifica'
import Clienti from './clienti'
/* import AssociaCliente from './elementi/associaCliente' */

export default {
  components: {
    Ricerca,
    Dettaglio,
    Modifica,
    Clienti
    /*     AssociaCliente */
  },
  props: {
    show: Boolean,
    context: Object
  },
  data() {
    return {
      refresh: 0,
      mostraBuono: true,
      ricerca: false,
      showTastiera: false,
      numberValue: '',
      maxLen: 0,
      buoni: [],
      locCard: null,
      prefisso: '',
      activeName: '',
      lastModulo: '',
      moduli: ['modifica', 'cerca'],
      activeTab: 'popup',
      activeParent: 'fidelity'
    }
  },
  computed: {
    card() {
      return this.context.fidelityCard || this.locCard || {}
    },
    fidelityCaricata() {
      return this.card.codice // || ....
    },
    associata() {
      return this.fidelityCaricata && this.card.associato
    }
  },
  methods: {
    noCard() {
      const event = {
        type: 'FIDELITY',
        value: {
          item: null
        }
      }
      this.$emit('send-event', event)
      this.locCard = null
    },
    async popupOpen() {
      if (this.fidelityCaricata) {
        this.activeName = 'dettaglio'
      }
      this.refresh++
      await this.$nextTick()
      this.onRenderedTab('modifica')
      /*       this.$store.commit('SET_EXTERNAL_TAB', this.activeParent)
      this.$store.commit('SET_INTERNAL_TAB', this.activeTab) */
    },
    onRenderedTab(name, title) {
      // Lancia delle procedure all'apertura (es inizializzazione variabili)
      // if (this.lastModulo !== name && this.moduli.includes(name)) {
      for (const m of this.moduli) {
        console.log(m)
        const refM = this.$refs[m]
        if (refM && refM.onOpen) {
          refM.onOpen()
        }
      }
      // }
      this.lastModulo = name
    },
    changeTab(tabname) {
      console.log('tabname', tabname)
      this.activeName = tabname
      setTimeout(() => {
        this.onRenderedTab('modifica')
      }, 1000)
    },
    closePopup() {
      this.$emit('close')
      /*       this.$store.commit('SET_EXTERNAL_TAB', '')
      this.$store.commit('SET_INTERNAL_TAB', '') */
    },
    sendEventToParent(event) {
      this.$emit('send-event', event)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../popup.scss";
  @import "./style.scss";

  .swipe-cell-button {
    height: 100%;
  }

  [name = PopupFidelity] {
    padding-top: 60px;
  }

  .buono-title {
    margin-right: 10px;;
  }
  @media all and (min-width:480px) {}
  @media all and (min-width:768px) {
    .PopupFidelity {
      width: 45%;
      height: 95%;
    }
  }
  @media all and (min-width:1024px) {
    .PopupFidelity {
      width: 45%;
      height: 95%;
    }
  }
  @media all and (min-width:1400px) {
    .PopupFidelity {
      width: 30%;
      height: 95%;
    }
  }

</style>

<template>
  <modale-form ref="form" :titolo="titoloModale" @close="annulla" @salva="salva" :campi="campi" :config="config" ></modale-form>
</template>

<script>
import ModaleForm from '../ModaleForm'
import clienti from '@/services/clientiService.js'
import configurazioni from '@/services/configurazioniService.js'
import { cloneObject } from '@/components/helpers'
import _ from 'lodash'
// import * as dot from 'dot-object'
import moment from 'moment'
import appuntamentiService from '@/services/appuntamentiService.js'

export default {
  components: {
    ModaleForm
  },
  props: {
    context: Object
  },
  data() {
    return {
      cliente: null,
      campi: [],
      agende: [],
      config: {
        hideBtnSalva: true,
        hideScrollbar: false,
        disableEsciOnClick: true,
        hideModale: false,
        keyboardClass: 'keyboard-dettaglio-cliente',
        hideTastiera: true
      }
    }
  },
  created() {
    this.cliente = this.context.nuovoCliente.cliente
  },
  async mounted() {
    /* const configModale = await configurazioni.getConfigurazione('display_elementi', 'elementi')
    if (configModale && configModale.clienti) {
      if (this.context.nuovoCliente && this.context.nuovoCliente.clienteInModifica) {
        const campi = configModale.clienti.modifica ? configModale.clienti.modifica.campi || [] : []
        this.config = configModale.clienti.modifica ? configModale.clienti.modifica.modale || {} : {}

        var cliente = await clienti.getCliente(this.context.nuovoCliente.clienteInModifica.codice)
        this.clienteInModifica = cliente

        // prepara dati iniziali
        for (var i = 0; i < campi.length; i++) {
          var c = campi[i]
          if (c.model) {
            var val = dot.pick(c.model, this.clienteInModifica)
            c.valore = val
          }
        }
        this.campi = campi
      } else {
        this.campi = configModale.clienti.nuovo ? configModale.clienti.nuovo.campi || [] : []
        this.config = configModale.clienti.nuovo ? configModale.clienti.nuovo.modale || {} : {}
      }
    } */
    const plannings = await configurazioni.getConfigurazione('agende')
    this.agende = plannings ? plannings.agende : []

    // var dataApt = moment('2022-10-21')
    // var apts = await appuntamentiService.getAppuntamenti(dataApt)
    var apts = await appuntamentiService.getAppuntamentiCliente(this.cliente.codice)
    var dsApts = []
    for (var j = 0; j < apts.length; j++) {
      // var desc = moment(apts[j].orarioInizio).format('DD/MM/YYYY HH:mm') + ' - ' + apts[j].prodotti.map(obj => obj.descrizione + ' X ' + obj.quantita).join(',')
      // dsApts.push({ descrizione: desc, item: apts[j] })
      var label = moment(apts[j].orarioInizio).format('DD/MM/YYYY HH:mm')
      var desc = apts[j].prodotti.map(obj => obj.descrizione + ' X ' + obj.quantita).join(',')
      dsApts.push({ label: label, descrizione: desc, item: apts[j] })
    }
    var natGiur = await this.getValoreDataSource(this.cliente.amministrativi.naturaGiuridica, 'clienti_nature_giuridiche.natureGiuridiche')

    var dsDati = [
      { label: 'Ragione Sociale', descrizione: this.cliente.ragioneSociale },
      { label: 'Natura Giuridica', descrizione: natGiur ? natGiur.descrizione || '-' : '-' },
      { label: 'Codice Fiscale', descrizione: this.cliente.amministrativi.codiceFiscale || '-' },
      { label: 'Partita Iva', descrizione: this.cliente.amministrativi.partitaIva || '-' },
      { label: 'Provincia', descrizione: this.cliente.recapiti[0].provincia || '-' },
      { label: 'Comune', descrizione: this.cliente.recapiti[0].comune || '-' },
      { label: 'Indirizzo', descrizione: this.cliente.recapiti[0].indirizzo || '-' },
      { label: 'CAP', descrizione: this.cliente.amministrativi.cap || '-' },
      { label: 'Telefono', descrizione: this.cliente.recapiti[0].telefono || '-' },
      { label: 'Cellulare', descrizione: this.cliente.recapiti[0].cellulare || '-' },
      { label: 'Email', descrizione: this.cliente.recapiti[0].email || '-' },
      { label: 'Pec', descrizione: this.cliente.recapiti[0].pec || '-' },
      { label: 'Nome Titolare', descrizione: this.cliente.contatti[0].nome || '-' },
      { label: 'Cognome Titolare', descrizione: this.cliente.contatti[0].cognome || '-' },
      { label: 'Data di Nascita', descrizione: this.cliente.dataNascita || '-' }
    ]

    var campi = [
      { campo: 'btnModifica', tipo: 'button', label: 'Modifica Cliente', classe: '', onUpdateValue: this.onModificaCliente },
      { campo: 'listaDati', tipo: 'infoList', titolo: 'Dati Cliente', abilitaAccordion: true, dataSource: dsDati, expanded: true },
      { campo: 'listaAppuntamenti', tipo: 'infoList', titolo: 'Elenco appuntamenti', abilitaAccordion: true, dataSource: dsApts, bottoni: [{ testo: 'MODIFICA', onClick: this.onModificaApt }] }
    ]

    this.campi = campi
  },
  computed: {
    titoloModale() {
      return 'Dettaglio cliente: ' + this.cliente.ragioneSociale
    }
  },
  methods: {
    annulla() {
      const event = {
        type: 'EDIT_CONTEXT',
        payload: {
          nuovoCliente: null
        }
      }
      this.$emit('send-event', event)
      this.$emit('close')
    },
    async salva() {
      var form = this.$refs.form
      var err = form.valida()
      if (err && err.length) {
        return
      }
      var dati = form.getDatiDotNotation()
      console.log(dati)
      if (this.clienteInModifica) { // modifica cliente
        var datiCliente = cloneObject(this.clienteInModifica)
        _.merge(datiCliente, dati)
        console.log(datiCliente)
        const result = await clienti.modificaCliente(datiCliente)
        console.log(result)
        // // verificare se errori di creazione
        this.selezionaCliente(datiCliente)
        this.$toast('Cliente modificato con successo', { type: 'success' })
      } else { // nuovo cliente
        var codice = await this.creaCodiceCliente(dati.ragioneSociale)
        dati.codice = codice
        const result = await clienti.creaCliente(dati)
        console.log(result)
        // verificare se errori di creazione
        this.selezionaCliente(dati)
        this.$toast('Cliente creato con successo', { type: 'success' })
      }
      var self = this
      setTimeout(() => {
        self.annulla()
      }, 200)
    },
    selezionaCliente(dati) {
      var obj = {
        codice: dati.codice,
        descrizione: dati.ragioneSociale,
        label: ' ',
        titolo: dati.codice + ' - ' + dati.ragioneSociale,
        value: ''
      }
      const event = {
        type: 'SELEZIONA_CLIENTE',
        value: obj
      }
      this.$emit('send-event', event)
      this.$store.commit('clienti/SET_CLIENTE', obj)
    },
    riduciModale() {
      this.config.hideModale = true
    },
    espandiModale() {
      this.config.hideModale = false
    },
    async refreshContent() {
      var dataApt = moment('2022-10-21')
      var apts = await appuntamentiService.getAppuntamenti(dataApt)
      var dsApts = []
      for (var j = 0; j < apts.length; j++) {
        var desc = moment(apts[j].orarioInizio).format('DD/MM/YYYY HH:mm') + ' - ' + apts[j].prodotti.map(obj => obj.descrizione + ' X ' + obj.quantita).join(',')
        dsApts.push({ descrizione: desc, item: apts[j] })
      }

      var campo = this.campi.find((obj) => obj.campo === 'listaAppuntamenti')
      campo.dataSource = dsApts
    },
    onModificaApt(objApt) {
      this.riduciModale()
      var ev = {
        type: 'EDIT_CONTEXT',
        payload: {
          nuovoAppuntamento: {
            agende: this.agende,
            dati: { appuntamento: objApt.item, nuovo: false },
            modalePlanning: this,
            modaleConfig: {
              apertoDaPlanning: true,
              showBtnSposta: true
            }
          }
        }
      }
      this.$emit('send-event', ev)

      const event = {
        type: 'APRI_MODALE',
        value: 'Appuntamento'
      }
      this.$emit('send-event', event)
    },
    onModificaCliente() {
      console.log(this.cliente)
      var event = {
        type: 'APRI_MODALE',
        value: this.cliente.infoRidotte ? 'NuovoClienteFidelity' : 'NuovoCliente',
        payload: {
          nuovoCliente: { clienteInModifica: this.cliente }
        }
      }
      this.$emit('close')
      this.$emit('send-event', event)
    },
    async getValoreDataSource(codice, configurazione) {
      var configName = configurazione.split('.')
      var datiConfig = await configurazioni.getConfigurazione(configName[0], configName[1])
      return datiConfig.find(obj => obj.codice === codice) || null
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

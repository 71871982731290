<template>
  <div class="modalField" :class="{ hasFocus: hasFocus }" ref="cnt">
    <!-- <div v-if="componente.tipo == 'text'" >
      <text-field :item="componente" ref="component" @on-focus="onFocus" @on-blur="onBlur" @update-value="onUpdateValue" ></text-field>
    </div>
    <div v-else-if="componente.tipo == 'select'" >
      <autocomplete-field :item="componente" ref="component" @on-focus="onFocus" @on-blur="onBlur" @cascade-filter="onCascadeFilter" @update-value="onUpdateValue"></autocomplete-field>
    </div>
    <div v-else-if="componente.tipo == 'calendar'" >
      <date-picker-field :item="componente" ref="component" @on-focus="onFocus" @on-blur="onBlur" @update-value="onUpdateValue"></date-picker-field>
    </div>
    <div v-else-if="componente.tipo == 'multiselect'" >
      <multiselect-field :item="componente" ref="component" @on-focus="onFocus" @on-blur="onBlur" @update-value="onUpdateValue"></multiselect-field>
    </div>
    <div v-else-if="componente.tipo == 'switch'" >
      <switch-field :item="componente" ref="component" @on-focus="onFocus" @on-blur="onBlur" @update-value="onUpdateValue"></switch-field>
    </div>
    <div v-else-if="componente.tipo == 'ricerca'" >
      <ricerca-field :item="componente" ref="component" @on-focus="onFocus" @on-blur="onBlur" @update-value="onUpdateValue"></ricerca-field>
    </div>
    <div v-else-if="componente.tipo == 'numeric'" >
      <numeric-field :item="componente" ref="component" @on-focus="onFocus" @on-blur="onBlur" @update-value="onUpdateValue"></numeric-field>
    </div>
    <div v-else-if="componente.tipo == 'intestazione'" >
      <intestazione-field :item="componente" ref="component" @on-focus="onFocus" @on-blur="onBlur"></intestazione-field>
    </div> -->

    <component :is="componente.tipo + '-field'" :item="componente" ref="component" @on-focus="onFocus" @on-blur="onBlur" @cascade-filter="onCascadeFilter"  @update-value="onUpdateValue"></component>
  </div>
</template>

<script>
import TextField from './components/TextField'
// import AutocompleteField from './components/AutocompleteField'
import SelectField from './components/AutocompleteField'
// import DatePickerField from './components/DatePickerField'
import CalendarField from './components/DatePickerField'
import TimePickerField from './components/TimePickerField'
import MultiselectField from './components/MultiselectField'
import SwitchField from './components/SwitchField'
import RicercaField from './components/RicercaField'
import InfoListField from './components/InfoListField'
import NumericField from './components/NumericField'
import IntestazioneField from './components/IntestazioneField'
import DettaglioField from './components/DettaglioField'
import ServiziAgendaField from './components/ServiziAgendaField'
import ButtonField from './components/ButtonField'
import TWEEN from 'tween.js'
import * as dot from 'dot-object'

// DEBUG: 02395190545
export default {
  components: {
    TextField,
    // AutocompleteField,
    SelectField,
    // DatePickerField,
    CalendarField,
    TimePickerField,
    MultiselectField,
    SwitchField,
    RicercaField,
    InfoListField,
    NumericField,
    IntestazioneField,
    DettaglioField,
    ServiziAgendaField,
    ButtonField
  },
  props: {
    componente: Object,
    modelItem: Object,
    config: Object
  },
  data() {
    return {
      hasFocus: false
    }
  },
  mounted() {
    // this.$refs.ragioneSociale.focus()
  },
  watch: {
  },
  methods: {
    afterInit() {
      var c = this.$refs.component
      c && c.afterInit && c.afterInit()
    },
    onFocus(event) {
      this.hasFocus = true
      this.$emit('on-focus', this)
    },
    onBlur(event) {
      this.hasFocus = false
      this.$emit('on-blur', this)
      // this.centerField()
    },
    setFocus() {
      var c = this.$refs.component
      this.hasFocus = true
      c && c.setFocus && c.setFocus()
      this.centerField()
    },
    removeFocus() {
      var c = this.$refs.component
      this.hasFocus = false
      c && c.removeFocus && c.removeFocus()
    },
    onKeyInput(value, daTastieraFisica) {
      var c = this.$refs.component
      c && c.onKeyInput && c.onKeyInput(value, daTastieraFisica)
    },
    onCascadeFilter(obj) {
      this.$emit('cascade-filter', obj)
    },
    filtraElementi(campo, valore) {
      var c = this.$refs.component
      c && c.filtraElementi && c.filtraElementi(campo, valore)
    },
    valida() {
      var c = this.$refs.component
      return c && c.valida ? c.valida() : [] // ritorna lista errori
    },
    getDati() {
      var c = this.$refs.component
      return c && c.getDati ? c.getDati() : null
    },
    setValore(valore) {
      var c = this.$refs.component
      c && c.setValore && c.setValore(valore)
    },
    centerField() {
      var modalBody = this.$refs.cnt.parentNode
      var bodyOffset = modalBody.offsetTop
      var componentOffset = this.$refs.cnt.offsetTop - bodyOffset + ((this.$refs.component.customOffset && this.$refs.component.customOffset()) || 0)
      var currScroll = modalBody.scrollTop
      var destScroll = componentOffset - 100

      var scrollTop = { x: currScroll }
      new TWEEN.Tween(scrollTop)
        .to({ x: destScroll }, 250)
        .onUpdate(function() {
          modalBody.scrollTop = this.x
        })
        .start()

      var animate = time => {
        requestAnimationFrame(animate)
        TWEEN.update(time)
      }

      requestAnimationFrame(animate)
    },
    onUpdateValue(value) {
      if (this.config.updateModel && this.modelItem) {
        if (this.componente.model) {
          dot.set(this.componente.model, value, this.modelItem)
        } else {
          this.modelItem[this.componente.campo] = value
        }
      }
      this.componente.onUpdateValue && this.componente.onUpdateValue(value)
    },
    callMethod(methodName, parameters) { // metodo generico da richiamare nel componente dall'esterno ( esempio addServizio del planning )
      var c = this.$refs.component
      return c && c[methodName] ? c[methodName](parameters) : undefined
    }
  }
}
</script>

<style lang="scss">

</style>

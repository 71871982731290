<template>
  <div class="popup-keyboard" ref="popupNumericKeyboard">
    <div :class='keyboardClass'></div>
  </div>
</template>

<script>
// https://hodgef.com/simple-keyboard/documentation
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

export default {
  name: 'NumericKeyboard',
  props: {
    keyboardClass: {
      default: 'numeric-keyboard',
      type: String
    },
    extInput: {
      type: String
    },
    enterText: {
      type: String,
      default: 'SCEGLI'
    }
  },
  data: () => ({
    keyboard: null
  }),
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        default: ['1 2 3', '4 5 6', '7 8 9', '{shift} 0 _', '{bksp}'],
        shift: ['! / #', '$ % ^', '& * (', '{shift} ) +', '{bksp}']
      },
      mergeDisplay: true,
      display: {
        '{bksp}': 'CANCELLA',
        '{esci}': this.enterText,
        '{space}': 'SPAZIO',
        '{simple_shift}': 'SHIFT'
      },
      layoutName: 'default',
      // layoutName: 'mobile',
      buttonTheme: [
        {
          class: 'hg-theme-default hg-layout-numeric numeric-theme',
          buttons: '{esci}'
        }
      ]
    })
  },
  methods: {
    onChange(input) {
      this.$store.commit('SET_POPUP_KEYBOARD_INPUT', input)
      this.$emit('onChange', input)
    },
    onKeyPress(button) {
      this.$store.commit('SET_KEY_PRESS_POPUP_KEYBOARD', button)
      this.$emit('onKeyPress', button)
      if (button === '{shift}' || button === '{lock}') this.handleShift()
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName
      const shiftToggle = currentLayout === 'default' ? 'shift' : 'default'

      this.keyboard.setOptions({
        layoutName: shiftToggle
      })
    }
  },
  computed: {
    storeInput () {
      return this.$store.getters.popupKeyboardInput.key
    },
    changeVisibility() {
      return this.$store.getters.showPopupKeyboard
    }
  },
  watch: {
    storeInput(value) {
      this.keyboard.setInput(value)
    },
    extInput(input) {
      this.keyboard.setInput(input)
    },
    enterText(value) {
      this.keyboard.setOptions({ display: { '{esci}': value } })
    },
    changeVisibility(value) {
      if (value) {
        const height = this.$refs.popupNumericKeyboard.offsetTop// vedi padding classe relativa
        this.$store.commit('SET_POPUP_KEYBOARD_HEIGHT', height)
      }
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>

<style lang="scss"> /* Non deve essere scoped altrimenti non "esce" dal componente */

  .numeric-theme {
    max-width: 320px;
    margin: 0 auto;
  }

  .numeric-theme + .simple-keyboard-preview {
    max-width: 320px;
  }

  .hg-theme-default.numeric-theme
    .hg-button.hg-standardBtn.hg-button-at {
    max-width: none;
  }
  $key_height: 55px;
  $pk_height: 300px; /* Non calcola su compilato. Impostare a mano in base alla mediaquery : calc( 5 * $key_height + 25px); */

  /*   .popup-keyboard {
    & .hg-theme-default .hg-button {
      height: $key_height;
    }
  }

  .tasto-scegli {
    background-color: #339900 !important;
    color: #ffffff !important;
    font-weight: bold;
  } */
</style>

<template>
  <van-cell-group >
    <van-collapse v-model="activeNames" @change="changeItem">
      <van-collapse-item title="Configurazioni">
        <van-cell v-for="item in elencoConfigurazioni" :key="item" :title="item" is-link @click="seleziona(item)" />
      </van-collapse-item>
    </van-collapse>
  </van-cell-group>
</template>

<script>
import configurazioni from '@/services/configurazioniService.js'

export default {
  data() {
    return {
      activeNames: [],
      elencoConfigurazioni: []
    }
  },
  async mounted() {
    const elenco = await configurazioni.getElencoConfigurazioni()
    if (elenco) {
      this.elencoConfigurazioni = elenco.map(x => x.id).sort()
    }
  },
  watch: {
    async salvataggio(value) {
      if (value) {
        const dati = this.$store.getters['settings/jsonContent']
        await configurazioni.modificaConfigurazione(dati)
        this.$store.commit('settings/SET_SALVATAGGIO_RICHIESTO', false)
      }
    }
  },
  computed: {
    salvataggio() {
      return this.$store.getters['settings/salvataggioRichiesto']
    }
  },
  methods: {
    async seleziona(nome) {
      const dati = await configurazioni.getConfigurazione(nome)
      this.$store.commit('settings/SET_JSON_EDITOR', dati)
      this.$store.commit('settings/SET_IN_MODIFICA', nome)
      this.$store.commit('settings/SET_READ_ONLY', false)
      this.$store.commit('settings/SHOW_JSON_EDITOR')
    },
    changeItem() {
      this.$store.commit('settings/HIDE_JSON_EDITOR')
    }
  }
}
</script>
